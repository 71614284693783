import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import socketIOClient from "socket.io-client";
import { Select, Spin, TreeSelect, Modal, Table } from "antd";
import FormZ from "../../../components/FormZ";

import { languagesArray } from "../../../store/language/languageSlice";
import {
  sendNotify,
  getOptions,
  convertQueryParams,
  fetchApi,
  capitalizeFirstLetter,
  masterFileDownload,
} from "../../../helper";
import MasterFileCreation from "../../../helper/translations/MasterFileCreation.json";
const { confirm } = Modal;
const socket = socketIOClient(
  process.env.REACT_APP_API_URL.split("/api", 1)[0]
);

export default function Client({
  clientClicked,
  setClientClicked,
  setselectedPage,
  selectedDate,
}) {
  const { language } = useSelector((state) => state.language);

  const { data, isLoading } = useSelector((state) => state.country);
  const [formData, setFormData] = useState({});
  const [feeCategoryArray, setFeeCategoryArray] = useState([]);
  const [feeSubCategoryArray, setFeeSubCategoryArray] = useState([]);
  const [clientlist, setClientlist] = useState([]);
  const [selctedLanguage, setSelctedLanguage] = useState("");
  const [duplicateRecordsView, setDuplicateRecordsView] = useState(false);
  const [duplicate, setDuplicate] = useState([]);

  const formRef = useRef();
  let selectedObj = selectedDate ? selectedDate : {};

  useEffect(() => {
    let index = languagesArray.find((tc) => tc.value == language);
    setSelctedLanguage(index.value);
  }, [language]);

  let m =
    MasterFileCreation.client[selctedLanguage] || MasterFileCreation.client.en;
  let ma =
    MasterFileCreation.masterFile[selctedLanguage] ||
    MasterFileCreation.masterFile.en;

  useEffect(() => {
    if (!selectedDate) {
      updateMastercode();
      configureSocket();
    }
    handleRefresh();
  }, []);
  useEffect(() => {
    selectedDateOnSet();
  }, [selectedObj]);

  const selectedDateOnSet = async () => {
    if (selectedObj._id) {
      const data = {
        ...selectedObj,
        feeCat: selectedObj.feeCat ?? null,
        feeType: selectedObj.feeType ?? [],
        subCat: selectedObj.subCat ?? [],
      };
      setFormData(data);
      if (data?.feeCat && data?.feeType) {
        await getFeeSubCategory(data.feeCat, data.feeType);
      }
    }
  };
  const getFeeSubCategory = async (catId, type) => {
    if (catId) {
      let queryParams = { catId: catId };
      let query = convertQueryParams(queryParams);
      let obj = {
        method: "GET",
        url: `/feeCategory/sub/?${query}`,
      };

      let subData = await fetchApi(obj).then((res) => {
        return res?.data?.data;
      });

      let sublist = subData
        ? subData?.map((d) => {
            let obj = {
              value: d?._id,
              label: d?.catType,
            };
            return obj;
          })
        : [];
      setFeeSubCategoryArray(sublist);
    }
  };
  const configureSocket = () => {
    // socket.on("connection", () => {
    //   console.log(" Connected backend");
    // });
    socket.on("counter", (counter) => {
      if (counter.update === true) {
        updateMastercode();
      }
    });
    return () => {
      disconnectSocket();
    };
  };

  const disconnectSocket = () => {
    socket.off("counter");
  };
  const handleRefresh = async () => {
    let payload = {
      method: "GET",
      url: `/feeCategory?_start=0&_limit=1000`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res?.data?.rows;
    });
    setFeeCategoryArray(data);
    const fetchData = async (url) => {
      const payload = { method: "GET", url };
      return fetchApi(payload).then((res) => res?.data?.rows);
    };
    const clientParams = convertQueryParams({
      isAll: true,
      masterType: "introducer-client",
      options: true,
    });
    const clientlist = await fetchData(`/client?${clientParams}`);
    setClientlist(clientlist);
  };
  const updateMastercode = async () => {
    let payload = {
      method: "GET",
      url: `/admin/masterCode?name=client`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res?.data?.data;
    });
    let originalString = data?.seq;
    let numericPart = originalString?.match(/\d+$/)[0];
    let incrementedNumeric = parseInt(numericPart, 10) + 1;
    let newNumericPart = String(incrementedNumeric)?.padStart(
      numericPart?.length,
      "0"
    );
    let newString = originalString?.replace(/\d+$/, newNumericPart);
    setFormData((prev) => ({
      ...prev,
      code: newString,
      type: "client",
      status: "prospect",
      createdAt: new Date(),
    }));
  };
  let list = feeCategoryArray?.map((d) => {
    let obj = {
      value: d?._id,
      label: d?.name,
    };
    return obj;
  });

  useEffect(() => {
    if (clientClicked) {
      onSubmit(selectedDate?._id);
    }
  }, [clientClicked]);

  const onSubmit = (selectedCatId) => {
    const valid = formRef.current.validForm();
    if (!valid) {
      sendNotify("error", "Some fields are missing!");
      return;
    }
    const options = formRef.current.getPayload();

    if (!options?.subCat?.length) {
      delete options.subCat;
    }
    if (!options?.feeCat?.length) {
      delete options.feeCat;
    }
    if (!options?.feeType) {
      delete options.feeType;
    }

    options.masterType = "client";
    options.nic = "CL";
    const payload = {
      method: selectedCatId ? "PUT" : "POST",
      url: selectedCatId ? `/client/update/${selectedCatId}` : `/client`,
      data: options,
    };

    fetchApi(payload)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          sendNotify("success", res?.message);
          if (!selectedCatId) {
            setTimeout(() => {
              window.location.href = `/app/master-search?action=client`;
            }, 1000);
          }
        } else if (res.status == 208) {
          sendNotify("error", res?.message);
          setDuplicate(res?.data);
          showConfirm();
        }
        setClientClicked(false);
      })
      .catch((error) => {
        sendNotify("error", error?.message);
        setClientClicked(false);
      });
  };
  const showConfirm = () => {
    confirm({
      title: <h5>Duplicate records</h5>,
      content: <p>Do you want to view or download the duplicate records?</p>,
      className: "custom-confirm",
      centered: true,
      okText: "OK",
      onOk() {
        duplicateRecordsOnclick();
      },
      onCancel() {},
    });
  };
  const tableColumnsDuplicate = [
    {
      title: "MasterFile Code",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.code - b.code,
    },
    {
      title: "MasterFile Name",
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: "Masterfile Type",
      dataIndex: "masterType",
      filterType: "text",
      sorter: (a, b) => a.type - b.type,
    },
    {
      title: "Incorporation Date",
      dataIndex: "incorporationDate",
      filterType: "date",
      sorter: (a, b) => a.incorporationDate - b.incorporationDate,
    },
    {
      title: "Incorporation Number",
      dataIndex: "incorporationNumber",
      filterType: "text",
      sorter: (a, b) => a.incorporationNumber - b.incorporationNumber,
    },
    {
      title: "Incorporation Country",
      dataIndex: "incorporationCountry",
      filterType: "text",
      sorter: (a, b) => a.nationality - b.nationality,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      filterType: "date",
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
  ];

  const onExportClick = async () => {
    const headersMap = {
      individual: [
        { header: "MasterFile Code", name: "code", required: true },
        { header: "Title", name: "title", required: true },
        { header: "First Name", name: "firstName", required: true },
        { header: "Middle Name", name: "middleName", required: true },
        { header: "Last Name", name: "lastName", required: true },
        { header: "Date of Birth", name: "DOB", required: true },
        { header: "Country of Birth", name: "COB", required: true },
        { header: "Nationality", name: "nationality", required: true },
        { header: "Tax Info Number", name: "taxInfo", required: true },
        { header: "Address", name: "address", required: true },
        { header: "Contact Number", name: "contactNo", required: true },
        { header: "Email Address", name: "email", required: true },
        { header: "Risk Level", name: "riskLevel", required: true },
        { header: "Pep", name: "pep", required: true },
        { header: "Sanction", name: "sanction", required: true },
      ],
    };
    const headersArray = headersMap["CorporateEntity"] || headersMap.individual;

    const capitalizeRows = (rows) => {
      return rows?.map((row) => ({
        ...row,
        type: row.type
          ? capitalizeFirstLetter(row.type)
          : capitalizeFirstLetter(row.masterType),
      }));
    };

    let tableData = capitalizeRows(duplicate);

    if (tableData) {
      let exportName = "CorporateEntity";

      masterFileDownload(
        tableData,
        `${exportName}-${new Date()}`,
        headersArray
      );
    }
  };
  const duplicateRecordsOnclick = () => {
    setDuplicateRecordsView(true);
  };
  if (setselectedPage) {
    setselectedPage("client");
  }

  let formSchema = [
    {
      name: "code",
      value: "",
      type: "text",
      label: m.code,
      placeholder: m.EnterclientCode,
      disabled: true,
    },
    {
      name: "name",
      value: "",
      type: "text",
      label: m.name,
      placeholder: m.EnterClientName,
      required: true,
    },
    {
      name: "type",
      value: "",
      type: "select",
      label: m.type,
      placeholder: m.EnterClientType,
      options: [
        {
          value: "client",
          label: ma.Client,
        },
        {
          value: "introducer",
          label: ma.IntroducerClient,
        },
        {
          value: "endUser",
          label: ma.EndUserClient,
        },
      ],
      disabled: true,
      required: true,
    },
    {
      name: "status",
      value: "",
      type: "select",
      label: m.status,
      placeholder: m.EnterClientStatus,
      options: [
        {
          value: "prospect",
          label: "Prospect",
        },
        {
          value: "active",
          label: "Active",
        },
        {
          value: "inactive",
          label: "Inactive",
        },
        {
          value: "closing",
          label: "Closing",
        },
        {
          value: "closed",
          label: "Closed",
        },
        {
          value: "dissolved",
          label: "Dissolved",
        },
      ],
      disabled: selectedDate ? false : true,
    },
    {
      name: "pCode",
      value: "",
      type: "select",
      label: m.pCode,
      options: getOptions(clientlist),
      placeholder: m.EnterParentClientCode,
    },

    {
      name: "relationshipManager",
      value: "",
      type: "text",
      label: m.relationshipManager,
      placeholder: m.EnterRelationshipManagerName,
    },
    {
      name: "administrator",
      value: "",
      type: "text",
      label: m.administrator,
      placeholder: m.EnterAdministratorName,
    },
    {
      name: "adminManager",
      value: "",
      type: "text",
      label: m.adminManager,
      placeholder: m.EnterAdminManagerName,
    },
    {
      name: "jurisdiction",
      value: "",
      type: "select",
      label: m.jurisdiction,
      placeholder: m.Selectjurisdiction,
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
      required: true,
    },
    {
      name: "feeCat",
      value: "",
      type: "select",
      label: m.feeCat,
      placeholder: m.SelectFeeScheduleName,
      options: getOptions(list),
      loading: isLoading,
      //disabled: true,
    },
    {
      name: "feeType",
      value: "",
      type: "select",
      label: m.feeType,
      placeholder: m.SelectTeeType,
      mode: "multiple",

      // disabled: true,
      options: [
        {
          value: "Pre-Paid",
          label: m.PrePaid,
        },
        {
          value: "Post-Paid",
          label: m.PostPaid,
        },
      ],
    },
    {
      name: "subCat",
      value: null,
      type: "select",
      label: m.subCat,
      placeholder: m.SelectFeeSubCategory,
      mode: "multiple",
      //   disabled: true,
      inputClass: "ptb-1",
      allowClear: true,
      //options: []
    },
    // {
    //   name: "name",
    //   value: "",
    //   type: "text",
    //   label: "Name",
    //   placeholder: "Enter name",
    // },
    {
      name: "email",
      value: "",
      type: "email",
      label: m.email,
      placeholder: m.EnterEmailAddress,
      //required: true,
    },
    {
      name: "contactNo",
      value: "",
      type: "number",
      label: m.contactNo,
      placeholder: m.EnterContactNumber,
      customClass: "hide-arrows",
      //required: true,
    },
    {
      name: "createdAt",
      value: "",
      type: "date",
      label: m.createdAt,
      placeholder: m.SelectDate,
      disabled: true,
    },
  ];

  const handleOnChange = async (value) => {
    let data = {
      ...selectedObj,
      feeCat: selectedObj.feeCat ?? null,
      feeType: selectedObj.feeType ?? [],
      subCat: selectedObj.subCat ?? [],
      ...value,
    };

    let { feeCat, feeType } = value;
    if (feeCat && feeType) {
      let params = { catId: feeCat };
      let query = convertQueryParams(params);
      let payload = {
        method: "GET",
        url: `/feeCategory/sub/?${query}`,
      };
      let data = await fetchApi(payload).then((res) => {
        return res.data.data;
      });

      let sublist = data
        ? data?.map((d) => {
            let obj = {
              value: d?._id,
              label: d?.catType,
            };
            return obj;
          })
        : [];
      setFeeSubCategoryArray(sublist);
    }

    setFormData(data);
  };

  return (
    <div className="custom-form">
      <h2 className="sub-title mb-4">
        {`${ma.MasterFile} ${!selectedDate ? ma.Creation : ma.Update}: ${
          ma.Client
        }`}
      </h2>
      <FormZ
        formSchema={formSchema}
        ref={formRef}
        onChange={handleOnChange}
        formData={formData}
        formOptions={{ subCat: feeSubCategoryArray }}
        formClass="row col-md-9 mb-3 mb-md-4 gy-3 gx-4"
        childClass="col-md-4 mb-0"
        labelClass="mb-1"
        inputClass="w-100"
      />
      <Modal
        title={"Duplicate Records"}
        className="custom-modal"
        open={duplicateRecordsView}
        width={1000}
        onOk={onExportClick}
        okText="Download"
        onCancel={() => setDuplicateRecordsView(false)}
      >
        <div>
          <Table
            className="custom-table"
            columns={tableColumnsDuplicate}
            dataSource={duplicate}
            pagination={false}
          />
        </div>
      </Modal>
      {/* <div className="d-flex gap-3">
                <button className='primary-btn'>Analyze</button>
                <button className='outline-btn'>Web Search</button>
            </div> */}
    </div>
  );
}
