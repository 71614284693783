import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, Spin, TreeSelect, Modal, Table } from "antd";
import { Link } from "react-router-dom";
import FormZ from "./FormZ";
import CorporateReport from "./CorporateReport";
import TableSortArrows from "./tableSortArrows";
import { languagesArray } from "../store/language/languageSlice";
import useRiskAssessment from "../hooks/useRiskAssessment";

import { getCorporateEntity } from "../store/corporateEntity/corporateEntitySlice";

import socketIOClient from "socket.io-client";

import {
  sendNotify,
  getOptions,
  getQuery,
  fetchApi,
  timeDiff,
  convertQueryParams,
  capitalizeFirstLetter,
  masterFileDownload,
} from "../helper";
import MasterFileCreation from "../helper/translations/MasterFileCreation.json";

import countries from "../helper/countries.json";
import moment from "moment/moment";
import html2pdf from "html2pdf.js";
import EntityRAF from "./EntityRAF";

const { SHOW_PARENT } = TreeSelect;
const { confirm } = Modal;
const socket = socketIOClient(
  process.env.REACT_APP_API_URL.split("/api", 1)[0]
);

export default function CorporateEntity(props) {
  let {
    actionShow,
    fileCreation,
    setCopEntityClicked,
    setselectedPage,
    copEntityClicked,
    selectedDateId,
  } = props;
  const dispatch = useDispatch();
  let query = getQuery();
  const [countriesOption, setCountriesOption] = useState([]);
  const [value, setValue] = useState([]);
  const [sanction, setSanction] = useState("Y");
  const [masterId, setMasterId] = useState({});
  const [latDoc, setLatDoc] = useState("");
  const [searchId, setSearchId] = useState("");
  const [sanctionText, setSanctionText] = useState("");
  const [loading, setLoading] = useState(false);
  const { data, isLoading } = useSelector((state) => state.country);
  const { language } = useSelector((state) => state.language);
  let { isLogged, userDetails } = useSelector((state) => state.auth);

  const corporateEntity = useSelector((state) => state.corporateEntity);
  const [nameCheck, setNameCheck] = useState("");
  const [openRes, setOpenRes] = useState(false);
  const [openDocument, setOpenDocument] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [openAnalysisResult, setOpenAnalysisResult] = useState(false);
  const [selectedCatId, setSelectedCatId] = useState("");
  const [webSearch, setWebSearch] = useState(false);

  const [webSearchReport, setWebSearchReport] = useState({});

  const [schema1, setSchema1] = useState({});
  const [schema2, setSchema2] = useState({});
  const [schema3, setSchema3] = useState({});
  const [tableData, setTableData] = useState([]);
  const [entitylist, setEntitylist] = useState([]);
  const [corplist, setCorplist] = useState([]);
  const [corpNamelist, setCorpNamelist] = useState([]);
  const [doc, setDoc] = useState("");
  const [selctedLanguage, setSelctedLanguage] = useState("");
  const [duplicateRecordsView, setDuplicateRecordsView] = useState(false);
  const [duplicate, setDuplicate] = useState([]);
  const [analyzing, setAnalyzing] = useState(false);
  const [webSearching, setWebSearching] = useState(false);

  const [findId, setFindId] = useState(null);
  useEffect(() => {
    let index = languagesArray.find((tc) => tc.value == language);
    setSelctedLanguage(index.value);
  }, [language]);
  const [countryRiskFromData, setCountryRiskFromData] = useState({});
  const [entityDetailFormData, setEntityDetailFromData] = useState({});
  const [overallRiskFromData, setOverallRiskFromData] = useState({});
  const entityDetailsOnChange = async (value, name) => {
    setEntityDetailFromData(value);
  };
  const countryRiskDtaOnChange = async (value, name) => {
    setCountryRiskFromData(value);
  };
  const overallRiskDataOnChange = async (value, name) => {
    setOverallRiskFromData(value);
  };
  const {
    isRiskAssesmentLoading,
    data: riskAssessmentData,
    isError,
    success,
    fetchRiskAssessmentData,
    saveData,
    resetFormSuccess,
  } = useRiskAssessment();

  let m =
    MasterFileCreation.corporateEntity[selctedLanguage] ||
    MasterFileCreation.corporateEntity.en;
  let ma =
    MasterFileCreation.masterFile[selctedLanguage] ||
    MasterFileCreation.masterFile.en;

  useEffect(() => {
    dispatch(getCorporateEntity({ _start: 0, _limit: 100 }));
    convertToOptions();

    setSanction(fileCreation ? "N" : "Y");
    if (!selectedDateId) {
      updateMastercode();
      configureSocket();
    } else {
      selectedDates();
    }
    handleRefresh();
  }, []);

  useEffect(() => {}, [corporateEntity]);

  const handleRefresh = async () => {
    const fetchData = async (url) => {
      const payload = { method: "GET", url };
      return fetchApi(payload).then((res) => res?.data?.rows);
    };

    const params = convertQueryParams({
      isAll: true,
      masterType: "cop-entity",
      options: true,
    });
    const paramsName = convertQueryParams({
      isAll: true,
      masterType: "cop-entity",
      exportAll: true,
    });

    const corpEntity = await fetchData(`/client?${params}`);
    const corpEntityName = await fetchData(`/client?${paramsName}`);

    let namelist = corpEntityName
      ? corpEntityName?.map((d) => {
          let obj = {
            value: d?._id,
            label: d?.name,
          };
          return obj;
        })
      : [];
    setCorpNamelist(namelist);
    setCorplist(corpEntity);
  };

  const onChangeHandler = (setIdentifierState, event) => {
    setIdentifierState(event.target.value);
  };

  const configureSocket = () => {
    socket.on("counter", (counter) => {
      if (counter.update === true) {
        updateMastercode();
      }
    });
    return () => socket.off("counter");
  };

  const selectedDates = async () => {
    let id = selectedDateId ? selectedDateId : query.id;
    let payload = {
      method: "GET",
      url: `/cop-entity?_start=0&_limit=1&id=${id}`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res?.data?.rows[0];
    });

    const tableArray = data?.searchReport?.map((c, index) => {
      return c.reportDoc.map((e, index) => {
        let obj = {
          id: index + 1,
          date: timeDiff(e.updatedBy.date, "DD/MM/YYYY - hh:mm a"),
          raf: e.url,
          analysisReport: e.document,
          updatedBy: e.updatedBy.name,
        };
        return obj;
      });
    });

    setTableData(tableArray);
    let val,
      val2,
      val3 = {};
    if (data) {
      setSelectedCatId(data._id);
      setValue(data?.busGeography);
      val = {
        _id: data?._id,
        code: data?.code,
        corpInfoNo: data?.corpInfoNo,
        name: data?.name,
        type: data?.type,
        incorporationDate: data?.incorporationDate,
        incorporationNumber: data?.incorporationNumber,
        incorporationCountry: data?.incorporationCountry,
        busNature: data?.busNature,
        busGeography: data?.busGeography,
        busDesc: data?.busDesc,
        relatedEntityCode: data?.relatedEntityCode,
        createdAt: data?.createdAt,
      };
      val2 = {
        residentialAddress: data?.residentialAddress,
        serviceAddress: data?.serviceAddress,
        line_1: data?.details?.address?.line_1,
        line_2: data?.details?.address?.line_2,
        line_3: data?.details?.address?.line_3,
        zipCode: data?.details?.address?.zipCode,
        country: data?.details?.address?.country,
      };
      val3 = {
        riskRating: data?.details?.riskRating,
        pep: data?.details?.pep,
        sanction: data?.details?.sanction,
        sanctionText: data?.details?.sanctionText,
      };
    }
    setSanctionText(data?.details?.sanctionText);
    setSchema1(val);
    setSchema2(val2);
    setSchema3(val3);
  };
  const webSearchonClick = async () => {
    if (schema1.incorporationNumber && schema1.name) {
      setWebSearching(true);
      let payload = {
        method: "GET",
        url: `/nameCheck/searchCorporateReport?companyName=${schema1.name}`,
      };
      let data = await fetchApi(payload).then((res) => {
        return res?.data?.data;
      });
      setWebSearchReport(data);
      setWebSearch(true);
      setWebSearching(false);
    } else {
      sendNotify("error", "Please enter at least one name");
    }
  };
  const searchChange = (value) => {
    let ctr = {};
    ctr.id = value;
    findOneEntity(ctr);
    setFindId(value);
    if (value) {
      fetchRiskAssessmentData(value);
    }
  };
  const findOneEntity = async (value) => {
    setLoading(true);
    let ctr = {};
    if (value.code) {
      if (value.code.startsWith("COR")) {
        ctr.code = value.code;
      } else {
        ctr.id = value.code;
      }
    } else {
      ctr = value;
    }

    let params = convertQueryParams(ctr);
    let payload = {
      method: "GET",
      url: `/cop-entity?${params}`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res?.data?.rows?.[0];
    });

    updateLiveurl(data);

    let val,
      val2,
      val3 = {};
    if (data) {
      setSelectedCatId(data._id);
      setValue(data?.busGeography);
      val = {
        _id: data?._id,
        code: data?.code,
        corpInfoNo: data?.corpInfoNo,
        name: data?.name,
        type: data?.type,
        incorporationDate: data?.incorporationDate,
        incorporationNumber: data?.incorporationNumber,
        incorporationCountry: data?.incorporationCountry,
        busNature: data?.busNature,
        busGeography: data?.busGeography,
        busDesc: data?.busDesc,
        relatedEntityCode: data?.relatedEntityCode,
        createdAt: data?.createdAt,
        address: `${data?.details?.address?.line_1 || ""}\n${
          data?.details?.address?.line_2 || ""
        }\n${data?.details?.address?.line_3 || ""}\n${
          data?.details?.address?.zipCode || ""
        }`.trim(),
      };
      val2 = {
        residentialAddress: data?.residentialAddress,
        serviceAddress: data?.serviceAddress,
        line_1: data?.details?.address?.line_1,
        line_2: data?.details?.address?.line_2,
        line_3: data?.details?.address?.line_3,
        zipCode: data?.details?.address?.zipCode,
        country: data?.details?.address?.country,
      };
      val3 = {
        riskRating: data?.details?.riskRating,
        pep: data?.details?.pep,
        sanction: data?.details?.sanction,
        sanctionText: data?.details?.sanctionText,
      };
    }
    setSanctionText(data?.details?.sanctionText);
    setSchema1(val);
    setSchema2(val2);
    setSchema3(val3);
    setEntityDetailFromData(val);
    setLoading(false);
  };

  const codeOnChange = async (value, fname) => {
    if (!setselectedPage) {
      let { code, name } = value;

      //   if (fname === "code") {
      //     findOneEntity({ code: code });
      //   }
      //   if (fname === "name") {
      //     findOneEntity({ name: name });
      //   }
    }
  };

  const fetchLiveUrl = async (url) => {
    try {
      const data = { url: url };
      const obj = {
        method: "POST",
        url: `/file/get`,
        data: data,
      };

      return await fetchApi(obj);
    } catch (error) {
      throw error;
    }
  };

  const analysisResult = async (url) => {
    const res = await fetchLiveUrl(url);

    setLatDoc(res?.data?.base64Url);
  };

  const updateLiveurl = async (data) => {
    const searchReport = data?.searchReport || [];
    const processedData = await Promise.all(
      searchReport.map(async (c) => {
        const processedReportDoc = await Promise.all(
          c.reportDoc.map(async (e, index) => {
            try {
              const date = timeDiff(e.updatedBy.date, "DD/MM/YYYY - hh:mm a");
              const res = await fetchLiveUrl(e.url);
              analysisResult(e.url);

              return {
                id: index + 1,
                date: date,
                raf: res.data,
                url: e.url,
                analysisReport: e.document,
                updatedBy: e.updatedBy.name,
                liveUrl: res.data,
              };
            } catch (error) {
              return null;
            }
          })
        );
        return processedReportDoc.filter((doc) => doc !== null);
      })
    );

    setTableData(processedData);
  };
  const onCancelWebSearch = () => {
    setWebSearchReport({});
    setWebSearch(false);
  };

  const storeReport = async () => {
    if (searchId) {
      let payload = {
        method: "GET",
        url: `/nameCheck/storeReport/${searchId}`,
      };
      let data = await fetchApi(payload).then((res) => {
        return res?.data?.data;
      });

      if (data) {
        const specificDate = moment(Date.now()).format("YYYY-MM-DD:HH:mm:ss");
        const options = {
          margin: 0.5,
          filename: `CorporateEntityAnalysisReport-${specificDate}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { dpi: 192, letterRendering: true },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
          pagebreak: { mode: ["avoid-all", "css", "legacy"] },
        };

        try {
          let doc = {};
          const pdfBlob = await html2pdf()
            .from(data)
            .set(options)
            .outputPdf("blob");
          var bodyFormData = new FormData();
          bodyFormData.append("file", pdfBlob);
          bodyFormData.append("folder", "test");

          const payload = {
            method: "POST",
            url: `/file`,
            data: bodyFormData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };

          const response = await fetchApi(payload)
            .then((res) => {
              doc["name"] = schema1.code;
              doc["code"] = schema1.code;
              doc["masterId"] = schema1._id;
              doc["masterType"] = "corporateEntity";
              doc["reportDoc"] = {
                url: res?.data.url,
                document: payload?.data?.filename,
                size: pdfBlob.size,
                type: "pdf",
              };
            })
            .catch((error) => {
              setLoading(false);
              sendNotify("error", error?.message);
            });
          let reportPayload = {
            method: "POST",
            url: `/searchReport`,
            data: doc,
          };

          fetchApi(reportPayload)
            .then((res) => {
              sendNotify("success", res?.message);
            })
            .catch((error) => {
              sendNotify("error", error?.message);
            });
        } catch (error) {
          sendNotify("error", error?.message);
        }
      } else {
      }
    }
  };
  const checkCorporateEntityValidity = () => {
    let formRefValid = formRef?.current?.validForm();

    if (
      formRef?.current?.validForm() &&
      riskFormRef?.current?.validForm() &&
      adsFormRef?.current?.validForm()
    ) {
      setAnalyzing(true);
      let options1 = formRef?.current?.getPayload();
      let options2 = adsFormRef?.current?.getPayload();
      let options3 = riskFormRef?.current?.getPayload();

      let data = {
        code: options1.code,
        corpInfoNo: options1.corpInfoNo,
        name: options1.name,
        type: options1.type,
        incorporationDate: options1.incorporationDate,
        incorporationNumber: options1.incorporationNumber,
        incorporationCountry: options1.incorporationCountry,
        busNature: options1.busNature,
        busGeography: options1.busGeography,
        busDesc: options1.busDesc,
        relatedEntityCode: options1.relatedEntityCode,
        createdAt: options1.createdAt,
        residentialAddress: options2.residentialAddress,
        serviceAddress: options2.serviceAddress,
        details: {
          address: {
            line_1: options2.line_1,
            line_2: options2.line_2,
            line_3: options2.line_3,
            zipCode: options2.zipCode,
            country: options2.country,
          },
          riskRating: options3.riskRating,
          pep: options3.pep,
          sanction: sanction,
          sanctionText: sanctionText,
        },
      };
      data.masterType = "CorporateEntity";
      let payload = {
        method: "POST",
        url: `/nameCheck/corporate/search-initiate`,
        data: data,
      };

      fetchApi(payload)
        .then((res) => {
          setAnalyzing(false);
          if (res?.data?.data && res?.data?.success) {
            sendNotify("success", res?.message);
            setNameCheck(res?.data?.data);
            setSearchId(res?.data?.searchId);
            setOpenRes(true);
          } else {
            sendNotify("error", res?.message);
          }
        })
        .catch((error) => {
          setAnalyzing(false);
          sendNotify("error", error?.message);
        });
    } else {
      sendNotify("error", "Please enter at least one name");
    }
  };

  const updateMastercode = async () => {
    const fetchData = async (url) => {
      const payload = { method: "GET", url };
      return fetchApi(payload).then((res) => res?.data?.rows);
    };
    if (setselectedPage) {
      let payload = {
        method: "GET",
        url: `/admin/masterCode?name=cop-entity`,
      };
      let data = await fetchApi(payload).then((res) => {
        return res?.data?.data;
      });
      let originalString = data?.seq;
      let numericPart = originalString?.match(/\d+$/)[0];
      let incrementedNumeric = parseInt(numericPart, 10) + 1;
      let newNumericPart = String(incrementedNumeric)?.padStart(
        numericPart?.length,
        "0"
      );
      let newString = originalString?.replace(/\d+$/, newNumericPart);
      const entityParams = convertQueryParams({
        isAll: true,
        masterType: "entity",
        options: true,
      });
      let entitylist = await fetchData(`/client?${entityParams}`);
      setEntitylist(entitylist);

      setSchema1((prev) => ({
        ...prev,
        code: newString ? newString : "COR00001",
        status: "active",
        createdAt: new Date(),
      }));
    }
  };
  const convertToOptions = () => {
    let options = [
      {
        title: "Worldwide",
        value: "0",
        key: "0",
        children: [],
      },
    ];

    Object.keys(countries).forEach(function (key, index) {
      let continents = {
        title: key,
        value: `0-${index}`,
        key: `0-${index}`,
        children: [],
      };
      countries[key].forEach((country, i) => {
        let cat = {
          title: country?.name,
          value: `0-${index}-${i}`,
          key: `0-${index}-${i}`,
        };
        continents.children.push(cat);
      });

      options[0]?.children.push(continents);
    });

    setCountriesOption(options);
  };

  const onChange = (newValue) => {
    let options = formRef.current.getPayload();

    //  const formRef = useRef();
    //  const adsFormRef = useRef();
    //  const riskFormRef = useRef();

    options.busGeography = newValue;
    setValue(newValue);
    setSchema1(options);
  };

  const tProps = {
    treeData: countriesOption,
    value,
    onChange,
    className: "custom-select w-100 ptb-1",
    treeCheckable: true,
    showSearch: false,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: "Select geography of business",
    allowClear: true,
  };

  const formRef = useRef();
  const adsFormRef = useRef();
  const riskFormRef = useRef();

  const handleChange = (value) => {
    setSanction(value);
  };

  const entity_risk_columns = [
    {
      title: "Entity Risk",
      dataIndex: "name",
    },
    {
      title: "Score",
      dataIndex: "score",
    },
  ];

  let filterSchema = [
    {
      customClass: "col-md-12",
      label: "",
      customElement: (
        <div className="search-bar d-flex align-items-center gap-3 p-3 bg-light shadow-sm rounded">
          <h2 className="primary-title">Find</h2>
          <Select
            className="custom-select w-100"
            placeholder={m.code || "Select Code"}
            value={findId}
            onChange={(value) => searchChange(value)}
            options={corplist}
            showSearch={true}
            allowClear={true}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
          />
          <Select
            className="custom-select w-100"
            placeholder={m.name}
            value={findId}
            onChange={(value) => searchChange(value)}
            options={corpNamelist}
            showSearch={true}
            allowClear={true}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
          />
        </div>
      ),
    },
  ];

  let tableColumnsDuplicate = [
    {
      title: "MasterfileCode",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.corporateEntityCode - b.corporateEntityCode,
    },
    {
      title: "MasterfileName",
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.corporateEntityName - b.corporateEntityName,
    },
    {
      title: "MasterfileType",
      dataIndex: "masterType",
      sorter: (a, b) => a.corporateEntityType - b.corporateEntityType,
    },
    {
      title: "Incorporation Date",
      dataIndex: "incorporationDate",
      filterType: "date",
      sorter: (a, b) => a.incorporationDate - b.incorporationDate,
    },
    {
      title: "Incorporation Number",
      dataIndex: "incorporationNumber",
      filterType: "text",
      sorter: (a, b) => a.incorporationNumber - b.incorporationNumber,
    },
    {
      title: "incorporation Country",
      dataIndex: "incorporationCountry",
      sorter: (a, b) => a.countryOfIncorporation - b.countryOfIncorporation,
    },

    {
      title: "BusDesc",
      dataIndex: "busDesc",
      filterType: "text",
      sorter: (a, b) => a.natureOfBusiness - b.natureOfBusiness,
    },
    {
      title: "CorpInfoNo",
      dataIndex: "corpInfoNo",
      filterType: "text",
      sorter: (a, b) => a.taxInfoNumber - b.taxInfoNumber,
    },
    {
      title: "Address",
      dataIndex: "address",
      filterType: "text",
      sorter: (a, b) => a.address - b.address,
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      filterType: "text",
      sorter: (a, b) => a.contactNumber - b.contactNumber,
    },
    {
      title: "Email Address",
      dataIndex: "emailAddress",
      filterType: "text",
      sorter: (a, b) => a.emailAddress - b.emailAddress,
    },
  ];
  let formSchema = [
    {
      name: "code",
      value: "",
      type: setselectedPage ? "text" : "select",
      label: m.code,
      placeholder: m.EnterCorporateCode,
      disabled: true,
      //options: getOptions(corporateEntity?.data?.rows),
      loading: isLoading,
      // showSearch: true,
    },
    {
      name: "name",
      value: "",
      type: "text",
      label: m.name,
      placeholder: m.EnterCorporateEntityName,
      required: true,
      disabled: selectedDateId
        ? false
        : true ||
          userDetails.role == "admin" ||
          userDetails.role == "super-admin"
        ? false
        : true,

      // showSearch: setselectedPage ? false : true,
    },
    {
      name: "type",
      value: "",
      type: "select",
      label: m.type,
      placeholder: m.EnterCorporateEntityType,
      options: [
        {
          value: "company",
          label: "Company",
        },
        {
          value: "BVIBC",
          label: "BVIBC",
        },
        {
          value: "trust",
          label: "Trust",
        },
        {
          value: "foundation",
          label: "Foundation",
        },
        {
          value: "general-partnership",
          label: "General Partnership",
        },
        {
          value: "limited-partnership",
          label: "Limited Partnership",
        },
      ],
    },
    {
      name: "status",
      value: "",
      type: "select",
      label: m.status,
      placeholder: m.SelectCorporateEntityStatus,
      options: [
        {
          value: "active",
          label: "Active",
        },
        {
          value: "inactive",
          label: "Inactive",
        },
        {
          value: "closing",
          label: "Closing",
        },
        {
          value: "closed",
          label: "Closed",
        },
        {
          value: "dissolved",
          label: "Dissolved",
        },
        {
          value: "transferringOut",
          label: "Transferring Out",
        },
        {
          value: "prospect",
          label: "Prospect",
        },
        {
          value: "transferringIn",
          label: "Transferring In",
        },
        {
          value: "liquidated",
          label: "Liquidated",
        },
        {
          value: "struckOff",
          label: "Struck Off",
        },
      ],
      disabled: selectedDateId ? false : true,
    },
    {
      name: "incorporationDate",
      value: "",
      type: "date",
      label: m.incorporationDate,
      placeholder: m.format,
      upcoming: true,
      // format: "DD/MM/YYYY",
      //required: true,
    },
    {
      name: "incorporationNumber",
      value: "",
      type: "text",
      label: m.incorporationNumber,
      placeholder: m.EnterIncorporationNumber,
      //  required: true,
    },
    {
      name: "incorporationCountry",
      value: "",
      type: "select",
      label: m.incorporationCountry,
      placeholder: m.SelectCountryOfIncorporation,
      showSearch: true,
      options: getOptions(data),
      loading: isLoading,
      // required: true,
    },
    {
      label: m.GeographyOfBusiness,
      customElement: <TreeSelect {...tProps} placement="topLeft" />,
      // required: true,
    },
    {
      name: "busNature",
      value: "",
      type: "select",
      label: m.busNature,
      placeholder: m.SelectNatureOfBusiness,
      showSearch: true,
      options: [
        {
          value: "manufacturing",
          label: "Manufacturing",
        },
        {
          value: "retail",
          label: "Retail",
        },
        {
          value: "wholesale",
          label: "Wholesale",
        },
        {
          value: "services",
          label: "Services",
        },
        {
          value: "agriculture",
          label: "Agriculture",
        },
        {
          value: "realEstate",
          label: "Real Estate",
        },
        {
          value: "construction",
          label: "Construction",
        },
        {
          value: "transportation",
          label: "Transportation",
        },
        {
          value: "healthcare",
          label: "Healthcare",
        },
        {
          value: "education",
          label: "Education",
        },
        {
          value: "financialServices",
          label: "Financial Services",
        },
        {
          value: "informationTechnology",
          label: "Information Technology",
        },
        {
          value: "entertainment",
          label: "Entertainment",
        },
        {
          value: "hospitality",
          label: "Hospitality",
        },
        {
          value: "utilities",
          label: "Utilities",
        },
        {
          value: "miningAndDrilling",
          label: "Mining and Drilling",
        },
        {
          value: "rAndD",
          label: "R&D",
        },
        {
          value: "nonProfit",
          label: "Non-Profit",
        },
        {
          value: "eCommerce",
          label: "E-commerce",
        },
        {
          value: "freelancing",
          label: "Freelancing",
        },
        {
          value: "gambling",
          label: "Gambling",
        },
        {
          value: "other",
          label: "Other",
        },
      ],
      // required: true,
    },
    {
      name: "busDesc",
      value: "",
      type: "text",
      label: m.busDesc,
      placeholder: m.EnterDescriptionOfYourBusiness,
      // required: true,
    },
    {
      name: "corpInfoNo",
      value: "",
      type: "text",
      label: m.corpInfoNo,
      placeholder: m.EnterCorporateTaxInfoNumber,
    },
    // 06-11-2024
    // {
    //   name: "relatedEntityCode",
    //   value: "",
    //   type: "select",
    //   label: "Related Entity Code",
    //   placeholder: "Enter related entity code",
    //   options: getOptions(entitylist),
    //   mode: "multiple",
    //   inputClass: "ptb-1",
    //   allowClear: true,
    //   // options: [
    //   //   {
    //   //     value: "E203",
    //   //     label: "E203",
    //   //   },
    //   //   {
    //   //     value: "E204",
    //   //     label: "E204",
    //   //   },
    //   //   {
    //   //     value: "E205",
    //   //     label: "E205",
    //   //   },
    //   // ],
    // },
    {
      name: "createdAt",
      value: "",
      type: "date",
      label: m.createdAt,
      placeholder: m.SelectDate,
    },
    {
      label: m.OrganizationStructure,
      customElement: (
        <Link
          to={`/app/organization-structure?id=`} //${query.id ?? selectedId}
          className="primary-btn "
        >
          <i class="far fa-folder-tree"></i>
          {m.Structure}
        </Link>
      ),
    },
  ];

  let adsFormSchema = [
    {
      name: "line_1",
      value: "",
      type: "text",
      label: m.line_1,
      placeholder: m.EnterAddress,
      // required: true,
    },
    {
      name: "line_2",
      value: "",
      type: "text",
      label: m.line_2,
      placeholder: m.EnterAddress,
      // required: true,
    },
    {
      name: "line_3",
      value: "",
      type: "text",
      label: m.line_3,
      placeholder: m.EnterAddress,
      // required: true,
    },
    {
      name: "country",
      value: "",
      type: "select",
      label: m.country,
      placeholder: m.SelectCountry,
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
      labelClass: "mb-1",
      customClass: "col-md-3 flex-column align-items-start",
      // required: true,
    },
    {
      name: "zipCode",
      value: "",
      type: "number",
      label: m.zipCode,
      labelClass: "mb-1",
      placeholder: m.EnterPost_zipCode,
      customClass: "col-md-3 flex-column align-items-start",
      // required: true,
    },
  ];

  let riskFormSchema = [
    {
      name: "riskRating",
      value: "",
      type: "select",
      label: m.riskRating,
      placeholder: m.SelectRiskLevel,
      options: [
        {
          value: "low",
          label: "Low",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "high",
          label: "High",
        },
      ],
    },
    {
      name: "listed",
      value: "",
      type: "select",
      label: m.listed,
      placeholder: m.SelectListedOrRegulated,
      options: [
        {
          value: "Y",
          label: "Yes",
        },
        {
          value: "N",
          label: "No",
        },
      ],
    },
    {
      name: "market",
      value: "",
      type: "text",
      label: m.market,
      placeholder: m.EnterMarket,
      // required: true,
    },
    {
      customClass: sanction == "Y" ? "col-md-3" : "col-lg-1",
      label: m.Sanction,
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${sanction == "Y" ? "w-50" : "w-100"}`}
            placeholder={m.SelectSanction}
            value={sanction}
            onChange={handleChange}
            disabled={fileCreation}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {sanction == "Y" && (
            <input
              type="text"
              className="w-100"
              value={sanctionText}
              onChange={onChangeHandler.bind(null, setSanctionText)}
              placeholder={m.EnterSanction}
            />
          )}
        </div>
      ),
    },
    {
      customElement: fileCreation ? (
        <div></div>
      ) : (
        // <button className="primary-btn mb-15">Confirm</button>
        <div className="d-flex gap-3 mb-1">
          <button
            className="primary-btn outline-btn"
            disabled={latDoc ? false : true}
            onClick={() => setOpenHistory(true)}
          >
            {m.History}
          </button>
          <button
            className="primary-btn"
            disabled={selectedCatId == "" ? true : false}
            onClick={() => saveOnClick()}
          >
            {m.Confirm}
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (copEntityClicked) {
      onSubmit();
    }
  }, [copEntityClicked]);

  const setdocumeny = (value) => {
    setDoc(value);
    setOpenDocument(true);
  };
  const saveOnClick = () => {
    onSubmit(selectedCatId);
  };

  const onSubmit = (selectedCatId) => {
    if (
      formRef.current.validForm() ||
      riskFormRef.current.validForm() ||
      adsFormRef.current.validForm()
    ) {
      let options1 = formRef.current.getPayload();
      let options2 = adsFormRef.current.getPayload();
      let options3 = riskFormRef.current.getPayload();
      let data = {
        code: options1.code,
        name: options1.name,
        type: options1.type,
        incorporationDate: options1.incorporationDate,
        incorporationNumber: options1.incorporationNumber,
        incorporationCountry: options1.incorporationCountry,
        busGeography: value,
        busNature: options1.busNature,
        busDesc: options1.busDesc,
        corpInfoNo: options1.corpInfoNo,
        relatedEntityCode: options1.relatedEntityCode,
        details: {
          address: {
            line_1: options2.line_1,
            line_2: options2.line_2,
            line_3: options2.line_3,
            zipCode: options2.zipCode,
            country: options2.country,
          },
          riskRating: options3.riskRating,
          listed: options3.listed,
          sanction: sanction,
          sanctionText: sanctionText,
        },
        createdAt: options1.createdAt,
      };

      data.masterType = "cop-entity";
      data.nic = "COR";

      let payload = {};
      if (selectedCatId || selectedDateId) {
        let id = selectedCatId ?? selectedDateId;
        payload.method = "PUT";
        payload.url = `/cop-entity/update/${id}`;
        payload.data = data;

        fetchApi(payload)
          .then((res) => {
            if (query?.id) {
              selectedDates();
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }
            sendNotify("success", res?.message);
          })
          .catch((error) => {
            sendNotify("error", error?.message);
          });
      } else {
        let url = `/app/master-search?action=corporateEntity`;
        payload.method = "POST";
        payload.url = `/cop-entity`;
        payload.data = data;
        fetchApi(payload)
          .then((res) => {
            if (res.status == 200 || res.status == 201) {
              sendNotify("success", res?.message);
            } else if (res.status == 208) {
              sendNotify("error", res?.message);
              setDuplicate(res?.data);
              showConfirm();
            }

            setCopEntityClicked(false);
            // setTimeout(() => {
            //   window.location.href = url;
            // }, 1000);
          })
          .catch((error) => {
            sendNotify("error", error?.message);
            setCopEntityClicked(false);
          });
      }
    } else {
      sendNotify("error", "Please enter at least one name");
    }
  };
  const onExportClick = async () => {
    const headersMap = {
      individual: [
        { header: "MasterFile Code", name: "code", required: true },
        { header: "Title", name: "title", required: true },
        { header: "First Name", name: "firstName", required: true },
        { header: "Middle Name", name: "middleName", required: true },
        { header: "Last Name", name: "lastName", required: true },
        { header: "Date of Birth", name: "DOB", required: true },
        { header: "Country of Birth", name: "COB", required: true },
        { header: "Nationality", name: "nationality", required: true },
        { header: "Tax Info Number", name: "taxInfo", required: true },
        { header: "Address", name: "address", required: true },
        { header: "Contact Number", name: "contactNo", required: true },
        { header: "Email Address", name: "email", required: true },
        { header: "Risk Level", name: "riskLevel", required: true },
        { header: "Pep", name: "pep", required: true },
        { header: "Sanction", name: "sanction", required: true },
      ],
    };
    const headersArray = headersMap["CorporateEntity"] || headersMap.individual;

    const capitalizeRows = (rows) => {
      return rows?.map((row) => ({
        ...row,
        type: row.type
          ? capitalizeFirstLetter(row.type)
          : capitalizeFirstLetter(row.masterType),
      }));
    };

    let tableData = capitalizeRows(duplicate);

    if (tableData) {
      let exportName = "CorporateEntity";

      masterFileDownload(
        tableData,
        `${exportName}-${new Date()}`,
        headersArray
      );
    }
  };
  const showConfirm = () => {
    confirm({
      title: <h5>Duplicate records</h5>,
      content: <p>Do you want to view or download the duplicate records?</p>,
      className: "custom-confirm",
      centered: true,
      okText: "OK",
      onOk() {
        duplicateRecordsOnclick();
      },
      onCancel() {},
    });
  };

  const duplicateRecordsOnclick = () => {
    setDuplicateRecordsView(true);
  };

  if (setselectedPage) {
    setselectedPage("corporate-entity");
  }
  const tableColumns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: m.Date,
      dataIndex: "date",
      sorter: (a, b) => a.date - b.date,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: m.RAF,
      dataIndex: "raf",
      sorter: (a, b) => a.raf - b.raf,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { raf }) => {
        return (
          <button className="outline-btn py-1" onClick={() => setdocumeny(raf)}>
            View
          </button>
        );
      },
    },
    {
      title: m.AnalysisReport,
      dataIndex: "analysisReport",
      sorter: (a, b) => a.analysisReport - b.analysisReport,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: m.UpdatedBy,
      dataIndex: "updatedBy",
      sorter: (a, b) => a.updatedBy - b.updatedBy,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  const [openRAF, setOpenRAF] = useState(null);
  const handleCloseRAF = () => {
    setOpenRAF(false);
  };

  useEffect(() => {
    if (!openRAF) {
      handleCloseRAF();
    }
  }, [openRAF]);

  return (
    <Spin spinning={loading}>
      {!fileCreation ? (
        <FormZ
          formSchema={filterSchema}
          formClass="custom-form row col-md-9 mb-3 mb-md-4 gy-3 gx-4"
          childClass="col-md-4 mb-0"
          labelClass="mb-1"
          inputClass="w-100"
        />
      ) : null}
      <div className="custom-form">
        <FormZ
          formSchema={formSchema}
          formData={schema1}
          onChange={codeOnChange}
          ref={formRef}
          formOptions={{ code: corplist, name: corpNamelist }}
          formClass="row col-md-9 mb-3 mb-md-4 gy-3 gx-4"
          childClass="col-md-4 mb-0"
          labelClass="mb-1"
          inputClass="w-100"
        />
        <FormZ
          formSchema={adsFormSchema}
          formData={schema2}
          ref={adsFormRef}
          formClass="custom-form row"
          childClass="form-row mb-4"
          labelClass="me-3 mb-0"
          inputClass="w-100"
        />
        <FormZ
          formSchema={riskFormSchema}
          ref={riskFormRef}
          formData={schema3}
          formClass="custom-form row mb-md-4 gy-3 align-items-end"
          childClass="col-md-3 mb-0"
          labelClass="mb-1"
          inputClass="w-100"
          disabled={fileCreation ? true : false}
        />
        {actionShow && (
          <div>
            <div className="d-flex flex-wrap gap-3 mt-3 mb-5 mb-md-0">
              <button
                className="primary-btn"
                disabled={!selectedCatId || analyzing}
                onClick={() =>
                  checkCorporateEntityValidity("reservation/confirmation")
                }
              >
                {analyzing && <i className="far fa-spinner-third fa-spin"></i>}
                {m.Analyze}
              </button>
              <button
                className="outline-btn"
                disabled={!selectedCatId || webSearching}
                onClick={() => webSearchonClick()}
              >
                {webSearching && (
                  <i className="far fa-spinner-third fa-spin"></i>
                )}
                {m.WebSearch}
              </button>
              <button
                className="outline-btn"
                disabled={!selectedCatId || latDoc ? false : true}
                onClick={() => setOpenAnalysisResult(true)}
              >
                {m.AnalysisResult}
              </button>
              {/* <button className="outline-btn">Store Result</button> */}
            </div>
            <div className="d-flex flex-wrap gap-3 mt-3">
              <button
                className="primary-btn"
                disabled={!selectedCatId}
                onClick={() => setOpenRAF(true)}
              >
                {m.RAF}
              </button>
              {openRAF && (
                <Modal
                  title=" Risk Assessment Form - Entity"
                  className="custom-modal"
                  open={openRAF}
                  onCancel={handleCloseRAF}
                  width={1300}
                  centered
                  footer={null}
                >
                  <EntityRAF
                    entityDetailFormData={entityDetailFormData}
                    entityDetailsOnChange={entityDetailsOnChange}
                    countryRiskFromData={countryRiskFromData}
                    countryRiskDtaOnChange={countryRiskDtaOnChange}
                    overallRiskFromData={overallRiskFromData}
                    overallRiskDataOnChange={overallRiskDataOnChange}
                    isCooperate={true}
                  />
                </Modal>
              )}
              <button className="outline-btn">{m.ApproveRAF}</button>
              <button className="outline-btn">{m.StoreRAF}</button>
            </div>
          </div>
        )}
        <Modal
          title={m.History}
          className="custom-modal"
          open={openHistory}
          width={800}
          onOk={() => setOpenHistory(false)}
          okText={m.Submit}
          onCancel={() => setOpenHistory(false)}
        >
          <div>
            <Table
              className="custom-table"
              columns={tableColumns}
              dataSource={tableData[0]}
              pagination={false}
            />
          </div>
        </Modal>

        <Modal
          title={m.RafDocument}
          className="custom-modal"
          open={openDocument}
          width={1000}
          okText={m.Okay}
          onOk={() => setOpenDocument(false)}
          onCancel={() => setOpenDocument(false)}
        >
          <div className="document-view">
            <iframe
              src={`data:application/pdf;base64,${doc}`}
              frameBorder="0"
              height="100%"
              width="100%"
            ></iframe>
          </div>
        </Modal>
        <Modal
          title={m.AnalysisResult}
          className="custom-modal"
          open={openAnalysisResult}
          width={800}
          onOk={() => setOpenAnalysisResult(false)}
          okText={m.Submit}
          onCancel={() => setOpenAnalysisResult(false)}
        >
          <div className="document-view">
            <iframe
              src={`data:application/pdf;base64,${latDoc}`}
              frameBorder="0"
              height="100%"
              width="100%"
            ></iframe>
          </div>
          {/* <div>
          <Table
            className="custom-table"
            columns={tableColumns}
            dataSource={tableData[0]}
            pagination={false}
          />
        </div> */}
        </Modal>
        <Modal
          title={m.CorporateEntityReport}
          className="custom-modal"
          open={openRes}
          width={1000}
          height={700}
          onOk={() => setOpenRes(false)}
          onCancel={() => setOpenRes(false)}
          footer={false}
        >
          <div className="document-view">
            <iframe
              title="HTML Content"
              width="100%"
              height="100%"
              srcDoc={nameCheck}
              frameBorder="0"
            ></iframe>
          </div>

          <button
            className="primary-btn"
            onClick={() => storeReport("nameCheck")}
          >
            {m.StoreReport}
          </button>
        </Modal>
        <Modal
          title={m.CorporateWebSearchReport}
          className="custom-modal"
          open={webSearch}
          width={1000}
          onOk={false}
          onCancel={onCancelWebSearch}
          footer={false}
        >
          <>
            {webSearchReport && (
              <CorporateReport
                webSearchReport={webSearchReport}
                selectedCorporateEntity={schema1.name}
              />
            )}
          </>
        </Modal>

        <Modal
          title={"Duplicate Records"}
          className="custom-modal"
          open={duplicateRecordsView}
          width={1000}
          onOk={onExportClick}
          okText="Download"
          onCancel={() => setDuplicateRecordsView(false)}
        >
          <div>
            <Table
              className="custom-table"
              columns={tableColumnsDuplicate}
              dataSource={duplicate}
              pagination={false}
            />
          </div>
        </Modal>
      </div>
    </Spin>
  );
}
