import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
import { Select, DatePicker, Modal, Popover, Spin, Switch } from "antd";
import dayjs from "dayjs";
import moment from "moment/moment";

import AddSignature from "../components/AddSignature";
import SignatureItem from "../components/SignatureItem";
import RejectTask from "../components/RejectTask";
import EditableTable from "../components/EditableTable";
import CustomizableTable from "../components/CustomizableTable";
import html2pdf from "html2pdf.js";
import {
  generateClientNameDataMap,
  generateAddressMap,
  filterAndSortData,
  mapDirectorDetails,
  mapShareholderDetails,
  extractShareTypeAndInvId,
} from "../helper/coi_helper";

import {
  sendNotify,
  downloadFile,
  fileToBase64,
  dateOptions,
  monthOptions,
  getQuery,
  fetchApi,
} from "../helper";
import { createEmailTemplateParams } from "../helper/emailTemplateHelpers";
import COI_PDF from "../assets/pdf/certificate-of-incumbency.pdf";

const Controls = (props) => {
  const { zoomIn, zoomOut, resetTransform, centerView } = useControls();

  const downloadPDF = () => {
    if (props.signature.length > 0) {
      props.generatePdf();
    } else {
      sendNotify(
        "error",
        "Signature missing. Please provide a valid signature to continue."
      );
    }
  };

  return (
    <div className="control-tools d-flex gap-3">
      <button onClick={() => zoomIn()}>
        <i className="far fa-search-plus"></i>
      </button>
      <button onClick={() => zoomOut()}>
        <i className="far fa-search-minus"></i>
      </button>
      <button onClick={downloadPDF}>
        <i className="far fa-arrow-to-bottom"></i>
      </button>
      {/* <button onClick={() => resetTransform()}>
        <i className="fal fa-times-circle"></i>
      </button>
      <button onClick={() => centerView()}>
        <i className="fal fa-arrows"></i>
      </button> */}
    </div>
  );
};

export default function CoiRequest() {
  const pdfRef = useRef();
  let query = getQuery();
  const formRef = useRef();
  const signFormRef = useRef();
  const signImgRef = useRef();

  let signPad = {};

  const [showCOI, setShowCOI] = useState(true);
  const [signModal, setSignModal] = useState(false);
  const [createSignModal, setCreateSignModal] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [signing, setSigning] = useState(false);
  const [signImgError, setSignImgError] = useState(false);
  const [signImgLoading, setSignImgLoading] = useState(false);
  const [newSign, setNewSign] = useState(null);
  const [previewSign, setPreviewSign] = useState("");

  const { userDetails } = useSelector((state) => state.auth);

  const location = useLocation();
  const entity = location.state?.entity;

  const registeredAgentName = userDetails.otherDetails.companyName;
  const registeredAgentEmail = userDetails.parentEmail;
  const registeredAgentAddress = userDetails.otherDetails.address;
  let withRegisterOfCharges = true;
  let selectedEmailCategory = query.category;

  const [serviceData, setServiceData] = useState(null);
  const [value, setvalue] = useState(false);
  const [copEntityCategories, setCopEntityCategories] = useState({});
  const [individualCategories, setIndividualCategories] = useState({});
  const [shareholderData, setShareholderData] = useState([]);
  const [directorData, setDirectorData] = useState([]);
  const [uboData, setUboData] = useState([]);
  const [
    copEntityDetailsForEmailTemplate,
    setCopEntityDetailsForEmailTemplate,
  ] = useState({});
  const [
    individualDetailsForEmailTemplate,
    setIndividualDetailsForEmailTemplate,
  ] = useState({});
  const [emailCategory, setEmailCategory] = useState("");
  const [registerOfCharges, setRegisterOfCharges] = useState([]);
  const [emailParams, setEmailParams] = useState(null);
  const [loading, setLoading] = useState(true);
  const [inputTbleAuthIndiData, setInputTblAuthIndiData] = useState([]);
  const [signatures, setSignatures] = useState([]);
  const [recieverMail, setRecieverMail] = useState([]);

  const [isTableVisible, setIsTableVisible] = useState(true);
  const [directorDetailsForForCustomTable, setDirectorDetailsForCustomTable] =
    useState({});
  const [
    shareholderDetailsForCustomTable,
    setShareholderDetailsForCustomTable,
  ] = useState({});

  const toggleTableVisibility = (checked) => {
    setIsTableVisible(checked);
    setEmailParams((prevParams) => ({
      ...prevParams,
      registerOfCharges: checked ? registerOfCharges : null,
    }));
  };

  const generateCOI = () => {
    if (formRef.current.validForm()) {
      let data = formRef.current.getPayload();
      setShowCOI(true);
    } else {
      sendNotify("error", "Some fields are missing!");
    }
  };

  const handleOk = () => {
    setSignModal(false);
  };
  const handleCancel = () => {
    setSignModal(false);
  };

  const labels = [
    {
      key: 1,
      label: (
        <>
          <i className="far fa-pen"></i> DRAW
        </>
      ),
    },
    {
      key: 2,
      label: (
        <>
          <i className="far fa-arrow-to-top"></i> UPLOAD
        </>
      ),
    },
  ];

  const generatePdf = () => {
    const element = pdfRef.current.cloneNode(true);
    const unwantedElements = element.querySelectorAll(".new-sign");
    unwantedElements.forEach((el) => el.remove());
    const elementsToHide = [
      ".sign-delete-btn",
      ".add-row-button",
      ".button-group",
    ];
    const hiddenElements = [];

    elementsToHide.forEach((selector) => {
      const elements = element.querySelectorAll(selector);
      elements.forEach((el) => {
        hiddenElements.push(el);
        el.style.display = "none";
      });
    });

    const opt = {
      margin: 0.5,
      filename: "certificate-of-incumbency.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 4, logging: true, dpi: 192, letterRendering: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf()
      .from(element)
      .set(opt)
      .save()
      .then(() => {
        hiddenElements.forEach((btn) => (btn.style.display = "block"));
      });

    return opt;
  };

  const onTabChange = (value) => {
    clearSign();
    setActiveTab(value);
  };

  const clearSign = () => {
    if (activeTab == 1) {
      signPad.clear();
      setSigning(false);
    } else {
      setPreviewSign("");
    }
    setNewSign(null);
  };

  const saveSign = () => {
    let sign = signPad.toDataURL();
    setNewSign(sign);
  };

  const onImgUpload = (e) => {
    setSignImgError(false);
    let file = e.target.files[0];
    if (file) {
      if (file?.size > 5242880) {
        setSignImgError(true);
        sendNotify("error", "Image is too big!, Upload below 5MB file.");
      } else {
        let type = file?.name.substring(file?.name.lastIndexOf(".") + 1);
        var regex = new RegExp("(.*?)(png|jpg|jpeg|svg)$");
        if (regex.test(type)) {
          setSignImgLoading(true);
          setPreviewSign(URL.createObjectURL(file));
          fileToBase64(file)
            .then((data) => {
              setNewSign(data);
              sendNotify("success", "Image uploaded successfully.");
              setSignImgLoading(false);
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        } else {
          setSignImgError(true);
          sendNotify(
            "error",
            "Only PNG, JPG, JPEG, or SVG images are supported."
          );
        }
      }
    }
  };

  const registreOfChargescolumns = [
    {
      title: "Description of Charge",
      dataIndex: "descriptionOfCharge",
      width: "10%",
      editable: true,
    },
    {
      title: "Liability Secured",
      dataIndex: "liabilitySecured",
      width: "10%",
      editable: true,
    },
    {
      title: "Property Charged",
      dataIndex: "propertyCharged",
      width: "10%",
      editable: true,
    },
    {
      title: "Trustee or Chargee",
      dataIndex: "trusteeOrChargee",
      width: "10%",
      editable: true,
    },
    {
      title: "Holder of Charge",
      dataIndex: "holderOfCharge",
      width: "10%",
      editable: true,
    },
    {
      title: "Prohibitions or Restrictions",
      dataIndex: "prohibitionOrRestrictions",
      width: "10%",
      editable: true,
    },
    {
      title: "Date of Discharge",
      dataIndex: "dateOfDischarge",
      width: "10%",
      editable: true,
    },
    { title: "Status", dataIndex: "status", width: "10%", editable: true },
  ];

  const directorsColumns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "auto",
      editable: true,
      isDefault: true,
    },
    {
      title: "Date Of Appointment",
      dataIndex: "dateOfAppointment",
      width: "auto",
      editable: true,
      isDefault: true,
    },
    {
      title: "Address",
      dataIndex: "address",
      width: "auto",
      editable: true,
      isDefault: false,
    },
    {
      title: "Director Type",
      dataIndex: "directorType",
      width: "auto",
      editable: true,
      isDefault: false,
    },
  ];

  const ShareholderColumns = [
    {
      title: "Name",
      dataIndex: "senderMasterName",
      width: "auto",
      editable: true,
      isDefault: true,
    },
    {
      title: "Number of Shares",
      dataIndex: "noShares",
      width: "auto",
      editable: true,
      isDefault: true,
    },
    {
      title: "Shareholder Type",
      dataIndex: "shareholderType",
      width: "auto",
      editable: true,
      isDefault: false,
    },
    {
      title: "Share Type",
      dataIndex: "shareType",
      width: "auto",
      editable: true,
      isDefault: false,
    },
    {
      title: "Share Certificate Number",
      dataIndex: "shareNumber",
      width: "auto",
      editable: true,
      isDefault: false,
    },
    {
      title: "Par Value",
      dataIndex: "parValue",
      width: "auto",
      editable: true,
      isDefault: false,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    handleRefresh();
  }, []);

  useEffect(() => {
    if (signatures.length > 0) {
      collectSignaturesData();
    }
  }, [signatures]);

  useEffect(() => {
    if (recieverMail.length > 0) {
      handleSendEmail();
    }
  }, [recieverMail]);

  const handleRefresh = async () => {
    if (!query) return;
    setLoading(true);
    const fetchData = async (url) => {
      let payload = { method: "GET", url };
      return fetchApi(payload).then((res) => res?.data?.rows || []);
    };

    const serviceData = query?.id
      ? await fetchData(`/serviceRequest?id=${query.id}`)
      : [];

    const entityId = query?.id
      ? serviceData[0]?.entityId
      : query?.entityId
      ? query?.entityId
      : query?.id;

    try {
      setLoading(true);

      setEmailCategory(selectedEmailCategory);
      let registerOfChargesDetails;
      if (withRegisterOfCharges) {
        registerOfChargesDetails = await fetchData(
          `/registerOfCharges?entityId=${entityId}&all=true`
        );

        setRegisterOfCharges(registerOfChargesDetails);
      }

      const [
        clientData,
        individualDetails,
        relatedEntityDetails,
        copEntityDetails,
        historyData,
      ] = await Promise.all([
        fetchData(`/client?isAll=true&exportAll=true`),
        fetchData(`/individualDetails?enId=${entityId}&all=true`),
        fetchData(`/relatedEntityDetails?enId=${entityId}&all=true`),
        fetchData(`/copEntityDetails?enId=${entityId}&all=true`),
        fetchData(`/transaction?enId=${entityId}`),
      ]);

      const allData = [
        ...individualDetails,
        ...relatedEntityDetails,
        ...copEntityDetails,
      ];

      const filteredData = filterAndSortData(allData);

      const shareholderTypeandInv = extractShareTypeAndInvId(allData);

      const clientDataMap = generateClientNameDataMap(clientData);
      const addressMap = generateAddressMap(clientData);

      const directorDetails = mapDirectorDetails(
        filteredData,
        clientDataMap,
        addressMap
      );

      const clientIdToCodeMap = new Map(
        clientData.map((item) => [item._id, item.code])
      );

      const clientNameDataMap = new Map(
        clientData.map((item) => [item._id, item.name])
      );

      const shareholderDetails = mapShareholderDetails(
        historyData,
        clientIdToCodeMap,
        clientNameDataMap,
        shareholderTypeandInv
      );

      setCopEntityDetailsForEmailTemplate(copEntityDetails);
      setIndividualDetailsForEmailTemplate(individualDetails);
      setDirectorDetailsForCustomTable(directorDetails);
      setShareholderDetailsForCustomTable(shareholderDetails);

      const processDocuments = (docs) => {
        const categories = {
          directors: [],
          shareholders: [],
          ubo: [],
        };

        docs.forEach((doc) => {
          if (doc?.checkedList?.directors || doc?.checkedList?.reserve)
            categories.directors.push(doc);
          if (doc?.checkedList?.nominee || doc?.checkedList?.shareholders)
            categories.shareholders.push(doc);
          if (doc?.checkedList?.ubo) categories.ubo.push(doc);
        });

        return categories;
      };

      const processCategory = async (categoryDocs, entityType) => {
        const allData = {
          allDirectors: [],
          allShareholders: [],
          allUbo: [],
        };

        const mapToObj = (oneDoc, index, type) => ({
          key: index,
          id: oneDoc._id,
          type,
          name:
            type === "individual"
              ? `${oneDoc?.individualArray?.firstName} ${oneDoc?.individualArray?.middleName} ${oneDoc?.individualArray?.lastName}`
              : oneDoc.copEntityName,
          total: oneDoc.totalShares ? oneDoc.noOfShares : "",
          percentage: oneDoc.ownershipPer
            ? `${parseFloat(oneDoc.ownershipPer).toFixed(2)}%`
            : null,
          date: oneDoc.appointment
            ? moment(oneDoc.appointment).format("DD-MMM-YYYY")
            : null,
        });

        const { directors, shareholders, ubo } = categoryDocs;

        return allData;
      };

      const individual = processDocuments(individualDetails);
      const copEntity = processDocuments(copEntityDetails);

      const individualData = await processCategory(individual, "individual");
      const copEntityData = await processCategory(copEntity, "copEntity");
      const { allDirectors, allShareholders, allUbo } = {
        allDirectors: [
          ...individualData.allDirectors,
          ...copEntityData.allDirectors,
        ],
        allShareholders: [
          ...individualData.allShareholders,
          ...copEntityData.allShareholders,
        ],
        allUbo: [...individualData.allUbo, ...copEntityData.allUbo],
      };

      setServiceData(serviceData[0]);
      setIndividualCategories(individual);
      setCopEntityCategories(copEntity);
      setDirectorData(allDirectors);
      setShareholderData(allShareholders);
      setUboData(allUbo);

      const params = createEmailTemplateParams(
        registeredAgentName,
        registeredAgentAddress,
        entity,
        individualDetails,
        copEntityDetails,
        selectedEmailCategory,
        registerOfChargesDetails,
        value,
        recieverMail,
        directorDetails.map((director) => ({
          name: director.name,
          dateOfAppointment: director.dateOfAppointment,
        })),
        shareholderDetails.map((shareholder) => ({
          senderMasterName: shareholder.senderMasterName,
          noShares: shareholder.noShares,
        })),
        serviceData[0]?.code ?? ''
      );
      setEmailParams(params);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSavedRegisterOfCharges = (updatedData) => {
    setEmailParams((prevParams) => ({
      ...prevParams,
      registerOfCharges: updatedData,
    }));
  };

  const handleSavedDirectors = (updatedData) => {
    setEmailParams((prevParams) => ({
      ...prevParams,
      directors: updatedData,
    }));
  };

  const handleSavedShareholders = (updatedData) => {
    setEmailParams((prevParams) => ({
      ...prevParams,
      shareholders: updatedData,
    }));
  };

  const cleardata = () => {
    setvalue(false);
  };

  const addSignature = (newSignature) => {
    if (signatures.length < 4) {
      setSignatures([...signatures, newSignature]);
    }
    setSignModal(false);
  };

  const deleteSignature = (index) => {
    const updatedSignatures = signatures.filter((_, i) => i !== index);
    setSignatures(updatedSignatures);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmailParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  const handleSelectChange = (value, name) => {
    setEmailParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  const handleDateChange = (date, dateString, filedName) => {
    const year = date ? date.year() : null;
    setEmailParams((prevParams) => ({
      ...prevParams,
      [filedName]: year,
    }));
  };

  const handleSendEmail = async () => {
    setLoading(true);
    const registeredAgentName = userDetails.otherDetails.companyName;
    const registeredAgentAddress = userDetails.otherDetails.address;

    const payload = {
      method: "POST",
      url: `/general-email/send-email`,
      data: emailParams,
    };
    const res = await fetchApi(payload)
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          sendNotify("success", res?.message);
        } else {
          setLoading(false);
          sendNotify("error", res?.error?.response?.data?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        sendNotify("error", error?.message);
      });
    return res;
  };

  const collectSignaturesData = () => {
    const signatureData = signatures.map((signature) => ({
      url: signature.url,
      fullName: signature.fullName,
      jobTitle: signature.jobTitle,
      registeredAgent: emailParams?.registeredAgentName,
    }));

    setEmailParams((prevParams) => ({
      ...prevParams,
      authorizedSignatory: signatureData,
    }));
  };

  const fetchAnalystEmails = async () => {
    setLoading(true);
    try {
      const payload = { method: "GET", url: "/auth?_start=0&_limit=50" };
      const response = await fetchApi(payload);

      const analysts = response?.data?.data || [];
      const analystEmails = analysts
        .filter((analyst) => analyst.role === "analyst")
        .map((analyst) => analyst.mail);
      setLoading(false);
      return analystEmails;
    } catch (error) {
      console.error("Error fetching analysts:", error);
      return [];
    }
  };

  const renderSubOptions = () => (
    <div
      className="certificate-links"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <button
        className="primary-btn"
        onClick={async () => {
          const emails = [entity?.primaryContact[0]?.data?.email] || [""];
          setEmailParams((prevParams) => ({
            ...prevParams,
            recieverEmail: emails,
          }));
          setRecieverMail(emails);
        }}
      >
        Send to Client
      </button>
      <button
        className="primary-btn"
        onClick={async () => {
          const emails = await fetchAnalystEmails();
          setEmailParams((prevParams) => ({
            ...prevParams,
            recieverEmail: emails,
          }));
          setRecieverMail(emails);
        }}
      >
        Send to Analayst
      </button>
    </div>
  );

  return (
    <div>
      <div className="page-header">
        <Link to={"/app/master-search"}>
          <h1 className="d-flex align-items-center gap-2">
            <i className="fal fa-long-arrow-left"></i> Certificate of Incumbency
          </h1>
        </Link>
        <div className="d-flex gap-3">
          <RejectTask id={query?.id} reason={serviceData?.rejectReason} />
          <button
            className="primary-btn"
            onClick={async () => {
              const emails = [registeredAgentEmail];
              setEmailParams((prevParams) => ({
                ...prevParams,
                recieverEmail: emails,
              }));
              setRecieverMail(emails);
            }}
          >
            Send to RA
          </button>
          <button
            className="primary-btn"
            disabled={!(signatures.length > 0)}
            onClick={async () => {
              const emails = [entity?.primaryContact[0]?.data?.email ?? ""] || [
                "",
              ];
              setEmailParams((prevParams) => ({
                ...prevParams,
                recieverEmail: emails,
              }));
              setRecieverMail(emails);
            }}
          >
            Send to Client
          </button>
        </div>
        {/* <div className="d-flex gap-3">
          <Popover
            destroyOnClose={false}
            className="custom-popover"
            placement="top"
            content={signatures.length > 0 ? renderSubOptions() : null}
          >
            <button
              className="primary-btn"
              aria-label="Generate Certificate of Incumbency"
              disabled={!(signatures.length > 0)}
            >
              Execute and Send to client
            </button>
          </Popover>
        </div> */}
      </div>
      <Spin spinning={loading}>
        {emailParams ? (
          <div className="pdf-zoom-wrapper mt-4">
            <TransformWrapper
              initialScale={1}
              centerOnInit
              panning={{ excluded: ["input"] }}
              doubleClick={{ excluded: ["input"] }}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <div>
                  <Controls generatePdf={generatePdf} signature={signatures} />
                  <TransformComponent wrapperClass="w-100 pdf-transform">
                    <div className="custom-pdf-wrapper" ref={pdfRef}>
                      <h1>CERTIFICATE OF INCUMBENCY</h1>
                      <p>
                        WE, {emailParams.registeredAgentName}, REGISTERED AGENT
                        of &nbsp;
                        <b>{emailParams.companyName}</b>&nbsp;("the company"), a
                        company existing under the laws of the British Virgin
                        Islands, hereby
                      </p>
                      <hr />
                      <h5>CERTIFY:</h5>
                      <ol>
                        <li>
                          {emailCategory === "re-registered" ? (
                            <>
                              That the Company was duly incorporated on the
                              &nbsp;
                              <b>{emailParams.incorporationDate}</b>
                              &nbsp;{" "}
                              <b>
                                day of {emailParams.incorporationMonth}, &nbsp;
                                {emailParams.incorporationYear}
                              </b>
                              &nbsp; under{" "}
                              <b>
                                The Companies Act (Cap 243) with CAP No.
                                <input
                                  name="capNo"
                                  type="text"
                                  placeholder="Sample Entity"
                                  className="pdf-input"
                                  style={{ width: 100 }}
                                  onChange={handleInputChange}
                                />
                                .
                              </b>
                              <span style={{ color: "red" }}>
                                {" "}
                                Effective &nbsp;
                                <Select
                                  className="custom-select pdf-select"
                                  defaultValue={emailParams.effectiveDate}
                                  placeholder="Select"
                                  options={dateOptions}
                                  onChange={(value) =>
                                    handleSelectChange(value, "effectiveDate")
                                  }
                                  style={{ width: 60 }}
                                />
                                &nbsp; <b>day of</b> &nbsp;
                                <Select
                                  className="custom-select pdf-select"
                                  defaultValue={emailParams.effectiveMonth}
                                  placeholder="Select"
                                  options={monthOptions}
                                  style={{ width: 100 }}
                                  onChange={(value) =>
                                    handleSelectChange(value, "effectiveMonth")
                                  }
                                />
                                , &nbsp;
                                <DatePicker
                                  className="custom-datepicker pdf-datepicker"
                                  picker="year"
                                  defaultValue={dayjs().startOf(
                                    `${emailParams.effectiveYear}`
                                  )}
                                  style={{ width: 80 }}
                                  onChange={(date, dateString) =>
                                    handleDateChange(
                                      date,
                                      dateString,
                                      "effectiveYear"
                                    )
                                  }
                                />
                              </span>
                              <span style={{ color: "red" }}>
                                {" "}
                                the company was re-registered under the BVI
                                Business Companies Act 2004 with BVI BC No.
                                {emailParams.companyNumber}
                              </span>
                              &nbsp;as is recorded in the Register of Companies
                              of the British Virgin Islands and is currently in
                              good standing.
                            </>
                          ) : emailCategory === "standard" ? (
                            <>
                              That the Company was duly incorporated on the
                              &nbsp;
                              <b>{emailParams.incorporationDate}</b>
                              &nbsp;{" "}
                              <b>
                                day of {emailParams.incorporationMonth}, &nbsp;
                                {emailParams.incorporationYear}
                              </b>
                              &nbsp; under{" "}
                              <span>
                                BVI BC No. {emailParams.companyNumber}
                              </span>
                              <span>
                                {" "}
                                as is recorded in the Register of Companies of
                                the British Virgin Islands and is currently in
                                good standing.{" "}
                              </span>
                            </>
                          ) : emailCategory === "continued" ? (
                            <>
                              That the Company was duly incorporated on the
                              &nbsp;
                              <b>{emailParams.incorporationDate}</b>
                              &nbsp;{" "}
                              <b>
                                day of {emailParams.incorporationMonth}, &nbsp;
                                {emailParams.incorporationYear}
                              </b>
                              &nbsp; and
                              <span style={{ color: "red" }}>
                                {" "}
                                Continued under the BVI Business Companies Act,
                                2004 on the
                                <b>
                                  <Select
                                    className="custom-select pdf-select"
                                    defaultValue={emailParams.continueDate}
                                    placeholder="Select"
                                    options={dateOptions}
                                    onChange={(value) =>
                                      handleSelectChange(value, "continueDate")
                                    }
                                    style={{ width: 60 }}
                                  />
                                  &nbsp; <b>day of</b> &nbsp;
                                  <Select
                                    className="custom-select pdf-select"
                                    defaultValue={emailParams.continueMonth}
                                    placeholder="Select"
                                    options={monthOptions}
                                    style={{ width: 100 }}
                                    onChange={(value) =>
                                      handleSelectChange(value, "continueMonth")
                                    }
                                  />
                                  , &nbsp;
                                  <DatePicker
                                    className="custom-datepicker pdf-datepicker"
                                    picker="year"
                                    defaultValue={dayjs().startOf(
                                      `${emailParams.continueYear}`
                                    )}
                                    style={{ width: 80 }}
                                    onChange={(date, dateString) =>
                                      handleDateChange(
                                        date,
                                        dateString,
                                        "continueYear"
                                      )
                                    }
                                  />
                                </b>
                                under BVI BC No. {emailParams.companyNumber}
                              </span>
                              <span>
                                {" "}
                                in the Register of Companies of the British
                                Virgin Islands and is currently in good
                                standing.{" "}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                        </li>
                        <li>
                          That the Company's Registered Agent is&nbsp;
                          <b>{emailParams.registeredAgentName}</b>
                          &nbsp;whose physical address is located at
                          <input
                            type="text"
                            placeholder="Enter Address"
                            className="pdf-input w-100"
                            name="registeredAgentAddress"
                            defaultValue={emailParams.registeredAgentAddress}
                            onChange={handleInputChange}
                          />
                        </li>
                        <li>
                          That the Company's Registered Office is located at
                          <input
                            type="text"
                            placeholder="Enter Address"
                            className="pdf-input w-100"
                            name="companyAddress"
                            defaultValue={emailParams.companyAddress}
                            onChange={handleInputChange}
                          />
                        </li>
                        <li>
                          That insofar as we are aware, there are no legal,
                          winding up, arbitration or administrative proceedings
                          pending or threatened against the Company and that no
                          Receiver has been appointed over its assets.
                        </li>
                        {/* <li>
                          The entries in the Company’s Register of Charges held at its Registered Office are as follows.
                          <EditableTable columns={registreOfChargescolumns} initialData={registerOfCharges} onSave={handleSavedRegisterOfCharges}></EditableTable>
                        </li> */}
                        <br></br>
                        <div>
                          <div>
                            <span>Register of Charges: </span>
                            <Switch
                              className="custom-switch"
                              onChange={toggleTableVisibility}
                              checked={isTableVisible}
                            />
                          </div>
                          <br></br>
                          {isTableVisible && ( // Conditionally render table based on the toggle
                            <li>
                              The entries in the Company’s Register of Charges
                              held at its Registered Office are as follows.
                              <EditableTable
                                columns={registreOfChargescolumns}
                                initialData={registerOfCharges}
                                onSave={handleSavedRegisterOfCharges}
                              />
                              {!registerOfCharges ||
                              registerOfCharges.length === 0 ? (
                                <>
                                  <strong style={{ fontSize: "15px" }}>
                                    No charge entries available
                                  </strong>
                                  <br /> <br />
                                </>
                              ) : null}
                            </li>
                          )}
                        </div>
                        <li>
                          That insofar as we are aware there are no measures
                          taken or about to be taken, to have the Company
                          dissolved or otherwise removed from the Register of
                          Companies.
                        </li>
                        <li>
                          That the Company's <b>Director(s) </b> at the date
                          hereof is/are:{" "}
                          <CustomizableTable
                            columns={directorsColumns}
                            initialData={directorDetailsForForCustomTable}
                            onSave={handleSavedDirectors}
                            showActionButton={true}
                            shownshowRowActions={false}
                          ></CustomizableTable>
                        </li>
                        <li>
                          That the Company's <b>Shareholder(s)</b> at the date
                          hereof is/are: <br /> &emsp;
                          <CustomizableTable
                            columns={ShareholderColumns}
                            initialData={shareholderDetailsForCustomTable}
                            onSave={handleSavedShareholders}
                            showActionButton={true}
                            showRowActions={false}
                          ></CustomizableTable>
                          &emsp;
                        </li>
                      </ol>
                      <p>
                        <b>IN WITNESS WHEREOF</b>, we have hereunto affixed our
                        signature this {emailParams.signatureDate}&nbsp;day
                        of&nbsp;{emailParams.signatureMonth},
                        {emailParams.signatureYear}.
                      </p>

                      <div className="signatures-container">
                        {signatures.map((signature, index) => (
                          <SignatureItem
                            key={index}
                            signature={signature}
                            index={index}
                            deleteSignature={deleteSignature}
                            registeredAgent={emailParams?.registeredAgentName}
                          />
                        ))}
                        {signatures.length < 4 && (
                          <div className="signature-content new-sign">
                            <div className="add-signature-badge">
                              Add Signature
                            </div>
                            <div
                              className="signature-wrapper"
                              onClick={() => setSignModal(true)}
                            >
                              <span className="icon">
                                <i className="fal fa-signature"></i>
                              </span>
                              <label className="custom-label req">
                                Click here to sign
                              </label>
                            </div>
                            <hr />
                            <p>
                              By: <br />
                              Designation: <br />
                              Authorized Signatory:{" "}
                              <b>{emailParams?.registeredAgentName ?? ""}</b>
                              <br /> Registered Agent
                            </p>
                          </div>
                        )}
                      </div>
                      <br />
                      <br />
                    </div>
                  </TransformComponent>
                </div>
              )}
            </TransformWrapper>
          </div>
        ) : (
          <></>
        )}
      </Spin>
      <AddSignature
        open={signModal}
        setvalue={addSignature}
        onOk={() => setSignModal(false)}
        onCancel={() => setSignModal(false)}
      />
    </div>
  );
}
