import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Popover, TreeSelect, Table, Spin, Tag } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import html2pdf from "html2pdf.js";
import { getIndividualDetails } from "../../store/individualDetails/individualDetailsSlice";
import { getCopEntityDetails } from "../../store/copEntityDetails/copEntityDetailsSlice";
import { getRelatedEntityDetails } from "../../store/relatedEntityDetails/relatedEntityDetailsSlice";
import { getFilingSetup } from "../../store/masterSetup/masterSetupSlice";

import { getEntity } from "../../store/entity/entitySlice";
import countries from "../../helper/countries.json";

import {
  getQuery,
  capitalizeFirstLetter,
  convertQueryParams,
  fetchApi,
} from "../../helper";
const { SHOW_PARENT } = TreeSelect;
export default function EntityOverview(props) {
  let { iframe } = props;
  let query = getQuery();
  const pdfRef = useRef();
  const dispatch = useDispatch();
  const entity = useSelector((state) => state.entity);
  const individualDetails = useSelector((state) => state.individualDetails);
  const copEntityDetails = useSelector((state) => state.copEntityDetails);
  const masterSetup = useSelector((state) => state.masterSetup);
  const relatedEntityDetails = useSelector(
    (state) => state.relatedEntityDetails
  );

  const [countriesOption, setCountriesOption] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [shareRegistryData, setShareRegistryData] = useState([]);
  const [shareRegistryCount, setShareRegistryCount] = useState(0);
  const [RODData, setRODData] = useState([]);
  const [RODCount, setRODCount] = useState(0);

  const [formData, setFormData] = useState({});
  const [customContact, setCustomContact] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [cddInformation, setCDDinformation] = useState({});
  const [locReg, setlocReg] = useState({});
  const [locFinancial, setLocFinancial] = useState({});
  const [customFinancial, setCustomFinancial] = useState([]);
  const [reminderCount, setReminderCount] = useState([0]);
  const [bossFilingCount, setBossFilingCount] = useState([0]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [value, setValue] = useState([]);

  const [clientData, setClientData] = useState([]);
  const [queryParams, setQueryParams] = useState({
    _start: 0,
    _limit: 5,
    all: true,
  });
  useEffect(() => {
    dispatch(getEntity({ id: query.id }));
    let param = { ...queryParams };
    param.enId = query.id;
    dispatch(getFilingSetup({}));
    dispatch(getIndividualDetails(param));
    dispatch(getCopEntityDetails(param));
    dispatch(getRelatedEntityDetails(param));
    convertToOptions();
  }, []);

  useEffect(() => {
    if (
      entity &&
      individualDetails &&
      copEntityDetails &&
      masterSetup &&
      relatedEntityDetails
    ) {
      handleRefresh();
      getRegistersData();
      getDataHandleRefresh();
    }
  }, [
    entity,
    individualDetails,
    copEntityDetails,
    masterSetup,
    relatedEntityDetails,
  ]);

  const tProps = {
    treeData: countriesOption,
    value,
    className: "custom-select w-100 ptb-1",
    treeCheckable: true,
    showSearch: false,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: "Select geography of business",
    allowClear: true,
    disabled: true,
  };
  const fetchData = async (url) => {
    const payload = { method: "GET", url };
    const response = await fetchApi(payload);
    setShareRegistryCount(response?.data?.totalCount);
    return response?.data?.rows || [];
  };
  const handleRefresh = async () => {
    try {
      let params = { enId: query.id, _start: 0, _limit: 5 }; //all: true

      const clientParams = convertQueryParams({
        isAll: true,
        exportAll: true,
      });
      const clientData = await fetchData(`/client?${clientParams}`);
      let namelist = clientData
        ? clientData?.map((d) => {
            let obj = {
              value: d._id,

              label: d?.name
                ? d?.name
                : ` ${
                    d?.firstName && d?.lastName
                      ? `${d?.firstName} ${
                          d?.middleName && d?.middleName !== "."
                            ? d?.middleName + " "
                            : ""
                        }${d?.lastName}`
                      : ""
                  }`,
            };
            return obj;
          })
        : [];
      const clientDataMap = new Map(
        clientData.map((item) => [item._id, item.code])
      );
      const clientNameDataMap = new Map(
        namelist.map((item) => [item.value, item.label])
      );

      setClientData(clientData);
      const queryString = convertQueryParams(params);
      const historyData = await fetchData(`/transaction?${queryString}`);

      const transformedRows = [];

      historyData.forEach((row) => {
        const { senderForm = [], receiverForm = [], allocationForm = [] } = row;

        senderForm.forEach((formItem) => {
          transformedRows.push({
            ...row,
            senderMasterId: clientDataMap.get(formItem?.masterId),
            senderMasterName: clientNameDataMap.get(formItem?.masterId),
            shareNumber: formItem?.certificateNumber,
            noShares: formItem?.numberOfShares,
            sharesHeld: formItem?.shareCapital,
            parValue: formItem?.parValue,
            ceasedDate: formItem?.ceasedDate,
            effectiveDate: formItem?.effectiveDate,
            totalShares: formItem?.totalShares,
            percentage: formItem?.percentage,
            from: formItem?.from,
            to: formItem?.to,
            shareType: formItem?.shareType,
            combinedForm: {
              sender: senderForm,
              receiver: receiverForm,
              allocation: allocationForm,
            },
            formType: "senderForm",
          });
        });

        receiverForm.forEach((formItem) => {
          transformedRows.push({
            ...row,
            senderMasterId: clientDataMap.get(formItem?.masterId),
            senderMasterName: clientNameDataMap.get(formItem?.masterId),
            shareNumber: formItem?.certificateNumber,
            noShares: formItem?.numberOfShares,
            sharesHeld: formItem?.shareCapital,
            parValue: formItem?.parValue,
            ceasedDate: formItem?.ceasedDate,
            effectiveDate: formItem?.effectiveDate,
            totalShares: formItem?.totalShares,
            percentage: formItem?.percentage,
            from: formItem?.from,
            to: formItem?.to,
            shareType: formItem?.shareType,
            combinedForm: {
              sender: senderForm,
              receiver: receiverForm,
              allocation: allocationForm,
            },
            formType: "receiverForm",
          });
        });

        allocationForm.forEach((formItem) => {
          transformedRows.push({
            ...row,
            senderMasterId: clientDataMap.get(formItem?.masterId),
            senderMasterName: clientNameDataMap.get(formItem?.masterId),
            shareNumber: formItem?.certificateNumber,
            noShares: formItem?.numberOfShares,
            sharesHeld: formItem?.shareCapital,
            parValue: formItem?.parValue,
            ceasedDate: formItem?.ceasedDate,
            effectiveDate: formItem?.effectiveDate,
            totalShares: formItem?.totalShares,
            percentage: formItem?.percentage,
            comment: formItem?.comment,
            currency: formItem?.currency,
            from: formItem?.from,
            to: formItem?.to,
            shareType: formItem?.shareType,
            combinedForm: {
              sender: senderForm,
              receiver: receiverForm,
              allocation: allocationForm,
            },
            formType: "allocationForm",
          });
        });
      });

      setShareRegistryData(transformedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getRegistersData = async () => {
    try {
      const [
        clientData,
        individualDetails,
        relatedEntityDetails,
        copEntityDetails,
      ] = await Promise.all([
        fetchData(
          `/client?${convertQueryParams({ isAll: true, exportAll: true })}`
        ),
        fetchData(
          `/individualDetails?${convertQueryParams({
            all: true,
            enId: query.id,
          })}`
        ),
        fetchData(
          `/relatedEntityDetails?${convertQueryParams({
            all: true,
            enId: query.id,
          })}`
        ),
        fetchData(
          `/copEntityDetails?${convertQueryParams({
            all: true,
            enId: query.id,
          })}`
        ),
      ]);

      const allData = [
        ...individualDetails,
        ...relatedEntityDetails,
        ...copEntityDetails,
      ];
      let selectedTabs = ["directors", "reserve", "president", "secretary"];
      const filteredData = allData
        .filter((item) => selectedTabs.some((type) => item.checkedList[type]))
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        .map((item) => ({
          ...item,
          status:
            !item.resignation || new Date() < new Date(item.resignation)
              ? "Active"
              : "Inactive",
        }));

      const getLabel = (obj) => {
        if (obj.reserve) return "Reserve directors";
        if (obj.directors) return "Directors";
        if (obj.president) return "President";
        if (obj.secretary) return "Secretary";
        return null;
      };

      setRODCount(filteredData.length);
      const formatAddress = (details) => {
        if (!details?.address) return "Address not available";

        const { line_1, line_2, line_3, zipCode, country } = details.address;

        return [line_1, line_2, line_3, zipCode, country]
          .filter(Boolean)
          .join(", ");
      };
      const clientDataMap = new Map(
        clientData.map((d) => [
          d._id,
          d.name ||
            `${d.firstName || ""} ${
              d.middleName && d.middleName !== "." ? d.middleName + " " : ""
            }${d.lastName || ""}`.trim(),
        ])
      );

      const addressMap = new Map(
        clientData.map((a) => {
          const serviceAddr = a?.serviceAddress ?? "N/A";
          const residentialAddr = a?.residentialAddress ?? "N/A";
          const copAddr = formatAddress(a?.details?.address) ?? "N/A";
          const entityAddr = formatAddress(a?.registers?.address) ?? "N/A";

          return [
            a._id,
            {
              individual: `Service Address: ${serviceAddr} , Residential Address: ${residentialAddr}`,
              corporate: `Address: ${copAddr}`,
              entity: `Address: ${entityAddr}`,
            },
          ];
        })
      );

      const data = filteredData.map((row) => {
        const masterId = row.invId || row.copId || row.relEntity;
        const masterType = row.invId
          ? "Individual"
          : row.copId
          ? "Corporate Entity"
          : "Related Entity";
        const addressData = addressMap.get(masterId) || {};

        return {
          _id: row._id,
          masterFileName: clientDataMap.get(masterId),
          data: row,
          masterType,
          resignation: row.resignation
            ? moment(row.resignation).format("YYYY-MM-DD")
            : "-",
          appointment: row.appointment
            ? moment(row.appointment).format("YYYY-MM-DD")
            : "-",
          status: row.status,
          shareTypes: getLabel(row.checkedList),
          comment: row.comment,
          address: row.invId
            ? addressData.individual
            : row.copId
            ? addressData.corporate
            : addressData.entity,
        };
      });

      setRODData(data);
    } catch (error) {}
  };
  const convertToOptions = () => {
    let options = [
      {
        title: "Worldwide",
        value: "0",
        key: "0",
        children: [],
      },
    ];

    Object.keys(countries).forEach(function (key, index) {
      let continents = {
        title: key,
        value: `0-${index}`,
        key: `0-${index}`,
        children: [],
      };
      countries[key].forEach((country, i) => {
        let cat = {
          title: country?.name,
          value: `0-${index}-${i}`,
          key: `0-${index}-${i}`,
        };
        continents.children.push(cat);
      });

      options[0]?.children.push(continents);
    });

    setCountriesOption(options);
  };
  const getDataHandleRefresh = async () => {
    if (entity?.data?.rows) {
      setFormData(entity?.data?.rows[0]);
      setSelectedId(entity?.data?.rows[0]._id);
      setCustomContact(entity?.data?.rows[0]?.primaryContact ?? {});
      setlocReg(entity?.data?.rows[0]?.registers?.address ?? {});
      setLocFinancial(entity?.data?.rows[0]?.financialRecords?.address ?? {});
      setCustomFinancial(
        entity?.data?.rows[0]?.financialRecords?.details ?? {}
      );
      setCDDinformation(entity?.data?.rows[0]?.cddInformation ?? {});
      setValue(entity?.data?.rows[0]?.cddInformation?.busGeography);
      setReminderCount(entity?.data?.rows[0]?.cddInformation?.reminderDate);
      setBossFilingCount(entity?.data?.rows[0]?.cddInformation?.filingDate);
      if (entity?.data?.rows[0]?.cddInformation?.riskRating) {
        let riskRating = capitalizeFirstLetter(
          entity?.data?.rows[0]?.cddInformation?.riskRating
        );
        let data = masterSetup?.data?.rows.filter(
          (e) => e?.riskLevel == riskRating
        );
      }
    }
  };
  const getPagination = (current, pageSize) => {
    setTableLoading(true);
    let params = { ...queryParams };
    params._start = (current - 1) * pageSize;
    params._limit = pageSize;
    params.enId = query.id;
    dispatch(getIndividualDetails(params));
    setQueryParams(params);
    setTableLoading(false);
  };
  const getShareRegistryPagination = async (current, pageSize) => {
    let params = {
      _start: (current - 1) * pageSize,
      _limit: pageSize,
      enId: query.id,
    };

    const queryString = convertQueryParams(params);
    const payload = {
      method: "GET",
      url: `/transaction?${queryString}`,
    };

    const historyData = await fetchApi(payload).then((res) => {
      setShareRegistryCount(res?.data?.totalCount || 0);
      return res?.data?.rows || [];
    });
    const clientDataMap = new Map(
      clientData.map((item) => [item.value, item.label])
    );

    let namelist = clientData
      ? clientData?.map((d) => {
          let obj = {
            value: d._id,

            label: d?.name
              ? d?.name
              : ` ${
                  d?.firstName && d?.lastName
                    ? `${d?.firstName} ${
                        d?.middleName && d?.middleName !== "."
                          ? d?.middleName + " "
                          : ""
                      }${d?.lastName}`
                    : ""
                }`,
          };
          return obj;
        })
      : [];
    const clientNameDataMap = new Map(
      namelist.map((item) => [item.value, item.label])
    );
    const transformedResponse = {
      rows: historyData.map((row) => ({
        ...row,
        senderMasterId: row.senderForm?.length
          ? clientDataMap.get(row.senderForm[0]?.masterId)
          : row.allocationForm?.length
          ? clientDataMap.get(row.allocationForm[0]?.masterId)
          : null,
        senderMasterName: row.senderForm?.length
          ? clientNameDataMap.get(row.senderForm[0]?.masterId)
          : row.allocationForm?.length
          ? clientNameDataMap.get(row.allocationForm[0]?.masterId)
          : null,
        shareNumber: row.senderForm?.length
          ? row.senderForm[0]?.certificateNumber
          : row.receiverForm?.length
          ? row.receiverForm[0]?.certificateNumber
          : row.allocationForm?.length
          ? row.allocationForm[0]?.certificateNumber
          : null,
        from: row.senderForm?.length
          ? row.senderForm[0]?.from
          : row.receiverForm?.length
          ? row.receiverForm[0]?.from
          : row.allocationForm?.length
          ? row.allocationForm[0]?.from
          : null,
        to: row.senderForm?.length
          ? row.senderForm[0]?.to
          : row.receiverForm?.length
          ? row.receiverForm[0]?.to
          : row.allocationForm?.length
          ? row.allocationForm[0]?.to
          : null,
        noShares: row.senderForm?.length
          ? row.senderForm[0]?.numberOfShares
          : row.receiverForm?.length
          ? row.receiverForm[0]?.numberOfShares
          : row.allocationForm?.length
          ? row.allocationForm[0]?.numberOfShares
          : null,

        sharesHeld: row.senderForm?.length
          ? row.senderForm[0]?.shareCapital
          : row.receiverForm?.length
          ? row.receiverForm[0]?.shareCapital
          : row.allocationForm?.length
          ? row.allocationForm[0]?.shareCapital
          : null,

        parValue: row.senderForm?.length
          ? row.senderForm[0]?.parValue
          : row.receiverForm?.length
          ? row.receiverForm[0]?.parValue
          : row.allocationForm?.length
          ? row.allocationForm[0]?.parValue
          : null,

        ceasedDate: row.senderForm?.length
          ? row.senderForm[0]?.ceasedDate
          : row.receiverForm?.length
          ? row.receiverForm[0]?.ceasedDate
          : row.allocationForm?.length
          ? row.allocationForm[0]?.ceasedDate
          : "-",

        effectiveDate: row.senderForm?.length
          ? row.senderForm[0]?.effectiveDate
          : row.receiverForm?.length
          ? row.receiverForm[0]?.effectiveDate
          : row.allocationForm?.length
          ? row.allocationForm[0]?.effectiveDate
          : null,

        totalShares: row.senderForm?.length
          ? row.senderForm[0]?.totalShares
          : row.receiverForm?.length
          ? row.receiverForm[0]?.totalShares
          : row.allocationForm?.length
          ? row.allocationForm[0]?.totalShares
          : null,

        percentage: row.senderForm?.length
          ? row.senderForm[0]?.percentage
          : row.receiverForm?.length
          ? row.receiverForm[0]?.percentage
          : row.allocationForm?.length
          ? row.allocationForm[0]?.percentage
          : null,

        comment: row.allocationForm?.length
          ? row.allocationForm[0]?.comment
          : null,

        currency: row.allocationForm?.length
          ? row.allocationForm[0]?.currency
          : null,

        shareType: row.senderForm?.length
          ? row.senderForm[0]?.shareType
          : row.receiverForm?.length
          ? row.receiverForm[0]?.shareType
          : row.allocationForm?.length
          ? row.allocationForm[0]?.shareType
          : null,

        combinedForm: {
          sender: row.senderForm || [],
          receiver: row.receiverForm || [],
          allocation: row.allocationForm || [],
        },
      })),
    };
    setShareRegistryData(transformedResponse.rows);

    setQueryParams(params);
  };

  const RODCcolumns = [
    {
      title: "Full Name",
      dataIndex: "masterFileName",
    },

    {
      title: "MasterFile Type",
      dataIndex: "masterType",
      sorter: (a, b) => a.masterType.localeCompare(b.masterType),
    },
    {
      title: "Address",
      render: (_, { address }, index) => (
        <div className="d-flex gap-2 align-items-center justify-content-end">
          <Popover
            className="custom-popover"
            placement="rightBottom"
            content={
              <p className="mb-0">
                <div className="container mt-4">
                  <div className="card p-3 shadow-sm">
                    <h6 className="fw-bold">User Details</h6>
                    <p>
                      <strong>{address}</strong>
                    </p>
                  </div>
                </div>
              </p>
            }
          >
            <button className="action-btn">
              {" "}
              <i className="fas fa-map-marker-alt"></i>{" "}
            </button>
          </Popover>
        </div>
      ),
    },

    {
      title: "Officer Type",
      dataIndex: "shareTypes",
      sorter: (a, b) => a.shareTypes.localeCompare(b.shareTypes),
    },
    {
      title: "Date Of Appointment",
      dataIndex: "appointment",
      sorter: (a, b) =>
        moment(a.appointment).unix() - moment(b.appointment).unix(),
    },

    {
      title: "Notes",
      render: (_, { _id, masterType, comment, data }, index) => (
        <div className="d-flex gap-2 align-items-center justify-content-end">
          <Popover
            className="custom-popover"
            placement="rightBottom"
            content={<p className="mb-0">{comment}</p>}
          >
            <button className="primary-btn">
              {" "}
              <i className="fal fa-eye"></i>
            </button>
          </Popover>
        </div>
      ),
    },
  ];
  const ShareRegistrycolumns = [
    {
      title: "Share Certificate Number",
      dataIndex: "shareNumber",
      sorter: (a, b) => a.shareNumber - b.shareNumber,

      render: (_, { subType, shareNumber }) => (
        <>
          {[shareNumber].map((n) => {
            let color = n.length > 5 ? "geekblue" : "green";
            if (subType === "Direct Entry") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={n}>
                #{n.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "MasterFile Code",
      dataIndex: "senderMasterId",
      sorter: (a, b) => (a.senderMasterId || 0) - (b.senderMasterId || 0),
    },
    {
      title: "Full Name",
      dataIndex: "senderMasterName",
      sorter: (a, b) => (a.senderMasterName || 0) - (b.senderMasterName || 0),
    },

    {
      title: "No.of Shares Held",
      dataIndex: "noShares",
      sorter: (a, b) => a.noShares - b.noShares,
    },

    {
      title: "Shareholder Type",
      dataIndex: "subType",
      sorter: (a, b) => a.shareType - b.shareType,

      render: (_, { subType }) => (
        <>
          {[subType].map((tag) => {
            let color = tag.length > 5 ? "geekblue" : "green";
            if (tag === "Direct Entry") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Share Type",
      dataIndex: "shareType",
      sorter: (a, b) => a.shareType - b.shareType,
    },

    {
      title: "Effective Date",
      dataIndex: "createdAt",
      sorter: (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      render: (_, { createdAt }) => {
        return dayjs(createdAt).format("YYYY-MMM-DD");
      },
    },

    {
      title: "Comment",
      render: (_, { _id, comment }, index) => (
        <Popover
          className="custom-popover"
          placement="rightBottom"
          content={<p className="mb-0">{comment}</p>}
        >
          <button className="primary-btn">View</button>
        </Popover>
      ),
    },
  ];

  const individualcolumns = [
    {
      title: "MasterFile Code",
      dataIndex: "individualCode",
      sorter: (a, b) => a.individualCode - b.individualCode,
    },
    {
      title: "MasterFile Name",
      dataIndex: "individualName",
      sorter: (a, b) => a.individualName - b.individualName,
    },
    {
      title: "Designation",
      dataIndex: "shareType",
      sorter: (a, b) => a.shareType - b.shareType,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      format: "YYYY-MM-DD",
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
  ];

  const copEntitycolumns = [
    {
      title: "MasterFile Code",
      dataIndex: "copEntityCode",
      sorter: (a, b) => a.copEntityCode - b.copEntityCode,
    },
    {
      title: "MasterFile Name",
      dataIndex: "copEntityName",
      sorter: (a, b) => a.copEntityName - b.copEntityName,
    },
    {
      title: "Designation",
      dataIndex: "shareType",
      sorter: (a, b) => a.shareType - b.shareType,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      format: "YYYY-MM-DD",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
    },
  ];
  const entitycolumns = [
    {
      title: "MasterFile Code",
      dataIndex: "entityCode",
      sorter: (a, b) => a.relatedEntityCode - b.relatedEntityCode,
    },
    {
      title: "MasterFile Name",
      dataIndex: "entityName",
      sorter: (a, b) => a.relatedEntityName - b.relatedEntityName,
    },
    {
      title: "Designation",
      dataIndex: "shareType",
      sorter: (a, b) => a.shareType - b.shareType,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      format: "YYYY-MM-DD",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
    },
  ];
  const categories = [
    { name: "Standard Company", value: "standard" },
    { name: "Continued Company", value: "continued" },
    { name: "Re-registered Company", value: "re-registered" },
  ];

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleChangeCategory = () => {
    setSelectedCategory(null);
  };

  const renderSubOptions = () => (
    <div className="certificate-links">
      <p>
        Selected Category:{" "}
        <strong>
          {categories.find((cat) => cat.value === selectedCategory)?.name}
        </strong>
      </p>
      <Link
        to={`/app/certificate-of-incumbency?entityId=${
          query.id
        }&category=${selectedCategory}&withCharges=true&id=${
          query?.coiServiceRequestRef ?? ""
        }`}
        state={{ entity: entity?.data?.rows[0] }}
      >
        With register of charges
      </Link>
      <Link
        to={`/app/certificate-of-incumbency?entityId=${
          query.id
        }&category=${selectedCategory}&withCharges=false&id=${
          query?.coiServiceRequestRef ?? ""
        }`}
        state={{ entity: entity?.data?.rows[0] }}
      >
        Without register of charges
      </Link>
      <Link
        to={`/app/coi-request?entityId=${
          query.id
        }&category=${selectedCategory}&customCOI=true&id=${
          query?.coiServiceRequestRef ?? ""
        }`}
        state={{ entity: entity?.data?.rows[0] }}
      >
        Custom COI
      </Link>

      <button className="change-category-btn" onClick={handleChangeCategory}>
        Change Category
      </button>
    </div>
  );

  const renderMainOptions = () => (
    <div className="main-categories">
      {categories.map((category) => (
        <button
          key={category.value}
          onClick={() => handleCategoryClick(category.value)}
          className="main-category-btn"
        >
          {category.name}
        </button>
      ))}
    </div>
  );

  const [openRAF, setOpenRAF] = useState(null);
  const handleCloseRAF = () => {
    setOpenRAF(false);
  };

  useEffect(() => {
    if (!openRAF) {
      handleCloseRAF();
    }
  }, [openRAF]);

  const handleDownloadPDF = () => {
    const element = pdfRef.current;
    const opt = {
      margin: 0.5,
      filename: "EntityOverview.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "a3", orientation: "portrait" },
    };

    html2pdf().set(opt).from(element).save();
  };

  return (
    <div>
      {!iframe && (
        <div className="page-header">
          <h1 className="page-title mb-3 mb-md-0">Entity Overview</h1>

          <button onClick={handleDownloadPDF} className="primary-btn">
            Export
          </button>
        </div>
      )}
      <div id="pdf-content" className="page-content pdf-style " ref={pdfRef}>
        <Link
          to={`/app/organization-structure?id=${query.id ?? selectedId}`}
          className="primary-btn ms-auto"
        >
          <i class="far fa-folder-tree"></i>
          Organization Structure
        </Link>
        <div className="row">
          <h4 className="primary-title mb-4">Entity</h4>
          <div className="row overview-listing border-box mb-5">
            {formData?.code && (
              <div className="col-3 mb-4">
                <label htmlFor="">Entity Code:</label>
                <p className="box-style">{formData?.code}</p>
              </div>
            )}
            {formData?.name && (
              <div className="col-3 mb-4 ">
                <label htmlFor="">Entity Name:</label>
                <p className="box-style">{formData.name}</p>
              </div>
            )}
            {formData?.type && (
              <div className="col-3 mb-4">
                <label htmlFor="">Entity Type:</label>
                <p className="box-style">{formData.type}</p>
              </div>
            )}
            {formData?.status && (
              <div className="col-3 mb-4">
                <label htmlFor="">Entity Status:</label>
                <p className="box-style">{formData.status}</p>
              </div>
            )}
            {formData?.clientName && (
              <div className="col-3 mb-4">
                <label htmlFor="">Client Masterfile Name:</label>
                <p className="box-style">{formData.clientName}</p>
              </div>
            )}
            {formData?.clientCode && (
              <div className="col-3 mb-4">
                <label htmlFor="">Client Code:</label>
                <p className="box-style">{formData.clientCode}</p>
              </div>
            )}
            {formData?.clientType && (
              <div className="col-3 mb-4">
                <label htmlFor="">Client Type:</label>
                <p className="box-style">{formData.clientType}</p>
              </div>
            )}
            {formData?.clientStatus && (
              <div className="col-3 mb-4">
                <label htmlFor="">Client Status:</label>
                <p className="box-style">{formData.clientStatus}</p>
              </div>
            )}
            {formData?.pClientName && (
              <div className="col-3 mb-4">
                <label htmlFor="">Parent Client Name:</label>
                <p className="box-style">{formData.pClientName}</p>
              </div>
            )}
            {formData?.pClientCode && (
              <div className="col-3 mb-4">
                <label htmlFor="">Parent Client Code:</label>
                <p className="box-style">{formData.pClientCode}</p>
              </div>
            )}
            {formData?.pClientStatus && (
              <div className="col-3 mb-4">
                <label htmlFor="">Parent Client Status:</label>
                <p className="box-style">{formData.pClientStatus}</p>
              </div>
            )}

            {
              <div className="col-3 mb-4">
                <label htmlFor="">Incorporation Number:</label>
                <p className="box-style">{formData?.incorporationNumber}</p>
              </div>
            }
            {formData?.busGeography?.length && (
              <div className="col-3 mb-4">
                <label htmlFor="">Jurisdiction:</label>
                <p className="box-style">{}</p>
              </div>
            )}
            {
              <div className="col-3 mb-4">
                <label htmlFor="">Incorporated Date:</label>
                <p className="box-style">
                  {moment(formData.incorporationDate).format("DD-MMM-YYYY")}
                </p>
              </div>
            }
            {formData?.group && (
              <div className="col-3 mb-4">
                <label htmlFor="">Entity Grouping:</label>
                <p className="box-style">Random</p>
              </div>
            )}
            {formData?.relatedEntities?.length ? (
              <div className="col-3 mb-4">
                <label htmlFor="">Related Entities:</label>
                <p className="box-style">{formData.relatedEntities}</p>
              </div>
            ) : null}
            {formData?.relationshipManager?.length ? (
              <div className="col-3 mb-4">
                <label htmlFor="">Relationship Manager:</label>
                <p className="box-style">{formData.relationshipManager}</p>
              </div>
            ) : null}
            {formData?.administrator?.length ? (
              <div className="col-3 mb-4">
                <label htmlFor="">Administrator:</label>
                <p className="box-style">{formData.administrator}</p>
              </div>
            ) : null}
            {formData?.administrator?.length ? (
              <div className="col-3 mb-4">
                <label htmlFor="">Administrator:</label>
                <p className="box-style">{formData.administrator}</p>
              </div>
            ) : null}
            {formData?.YearOfEndDate ? (
              <div className="col-3 mb-4">
                <label htmlFor="">Year Of End Date:</label>
                <p className="box-style">
                  {moment(formData.YearOfEndDate).format("DD-MMM-YYYY")}
                </p>
              </div>
            ) : null}
            {formData?.registeredOfficeaddress ? (
              <div className="col-3 mb-4">
                <label htmlFor="">Registered office Address:</label>
                <p className="box-style">{formData.registeredOfficeaddress}</p>
              </div>
            ) : null}
            {formData?.registeredAgentaddress ? (
              <div className="col-3 mb-4">
                <label htmlFor="">Registered Agent Address:</label>
                <p className="box-style">{formData.registeredAgentaddress}</p>
              </div>
            ) : null}
          </div>
          <h4 className="primary-title  mb-4">{`Primary Contact (${customContact?.length})`}</h4>

          <div className="row overview-listing border-box mb-5">
            {customContact?.map((a, i) => {
              return (
                <>
                  <div className="col-3 mb-4">
                    <label htmlFor="">Primary Contact Name:</label>
                    <p className="box-style">{a.data?.name}</p>
                  </div>
                  <div className="col-3 mb-4">
                    <label htmlFor="">Primary Contact Email:</label>
                    <p className="box-style">{a.data?.email}</p>
                  </div>
                  <div className="col-3 mb-4">
                    <label htmlFor="">Primary Contact Number:</label>
                    <p className="box-style">{a.data?.mobile}</p>
                  </div>
                </>
              );
            })}
          </div>
          <h4 className="primary-title mb-4">Location of Registers</h4>

          <div className="row overview-listing border-box mb-5">
            <div className="col-3 mb-4">
              <label htmlFor="">Address Line 01:</label>
              <p className="box-style">{locReg.line_1}</p>
            </div>
            <div className="col-3 mb-4">
              <label htmlFor="">Address Line 02:</label>
              <p className="box-style">{locReg.line_2}</p>
            </div>
            <div className="col-3 mb-4">
              <label htmlFor="">Address Line 03:</label>
              <p className="box-style">{locReg.line_3}</p>
            </div>
          </div>

          <h4 className="primary-title mb-4">
            {" "}
            Location of Financial Records{" "}
          </h4>

          <div className="border-box">
            <div className="row overview-listing  mb-5">
              <div className="col-3 mb-4">
                <label htmlFor="">Address Line 01:</label>
                <p className="box-style">{locFinancial.line_1}</p>
              </div>
              <div className="col-3 mb-4">
                <label htmlFor="">Address Line 02:</label>
                <p className="box-style">{locFinancial.line_2}</p>
              </div>
              <div className="col-3 mb-4">
                <label htmlFor="">Address Line 03:</label>
                <p className="box-style">{locFinancial.line_3}</p>
              </div>
            </div>

            <h4 className="primary-title mb-4">
              {" "}
              {`Financial Record Holder (${customFinancial?.length})`}{" "}
            </h4>
            {customFinancial?.map((a, i) => {
              return (
                <div className="row overview-listing border-box mb-5">
                  <div className="col-md-3 mb-4">
                    <label htmlFor="">Title</label>
                    <p className="box-style">{a.data?.title}</p>
                  </div>
                  <div className="col-md-3 mb-4">
                    <label htmlFor="">Name of Financial Record Holder</label>
                    <p className="box-style">{a.data.recordHolder}</p>
                  </div>
                  <div className="col-md-3 mb-4">
                    <label htmlFor="">Email:</label>
                    <p className="box-style">{a.data.email}</p>
                  </div>
                  <div className="col-md-3 mb-4">
                    <label htmlFor="">Primary Contact Number:</label>
                    <p className="box-style">{a.data.mobile}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <h4 className="primary-title mt-4 mb-2">
          Individuals ({individualDetails?.data?.rows?.length})
        </h4>

        <Spin spinning={tableLoading}>
          <Table
            className="custom-table"
            columns={individualcolumns}
            dataSource={individualDetails?.data?.rows}
            pagination={{
              className: "custom-pagination",
              defaultCurrent: 1,
              pageSize: queryParams?._limit,
              total: individualDetails?.data?.totalCount,
              onChange: getPagination,
            }}
          />
        </Spin>
        <h4 className="primary-title mb-2">
          Corporate Entity ({copEntityDetails?.data?.rows?.length})
        </h4>

        <Spin spinning={tableLoading}>
          <Table
            className="custom-table"
            columns={copEntitycolumns}
            dataSource={copEntityDetails?.data?.rows}
            pagination={{
              className: "custom-pagination",
              defaultCurrent: 1,
              pageSize: queryParams?._limit,
              total: copEntityDetails?.data?.totalCount,
              onChange: getPagination,
            }}
          />
        </Spin>
        <h4 className="primary-title mb-2">
          Related Entity ({relatedEntityDetails?.data?.rows?.length})
        </h4>

        <Spin spinning={tableLoading}>
          <Table
            className="custom-table"
            columns={entitycolumns}
            dataSource={relatedEntityDetails?.data?.rows}
            pagination={{
              className: "custom-pagination",
              defaultCurrent: 1,
              pageSize: queryParams?._limit,
              total: relatedEntityDetails?.data?.totalCount,
              onChange: getPagination,
            }}
          />
        </Spin>
        <h4 className="primary-title mb-2">Share Registry</h4>
        <Spin spinning={tableLoading}>
          <Table
            className="custom-table"
            columns={ShareRegistrycolumns}
            dataSource={shareRegistryData}
            pagination={{
              className: "custom-pagination",
              defaultCurrent: 1,
              pageSize: queryParams?._limit,
              total: shareRegistryCount,
              onChange: getShareRegistryPagination,
            }}
          />
        </Spin>
        <h4 className="primary-title mb-2">Registers</h4>
        <Spin spinning={tableLoading}>
          <Table
            className="custom-table mb-3"
            columns={RODCcolumns}
            dataSource={RODData}
            rowKey={(r) => r._id}
            pagination={{
              className: "custom-pagination",
              defaultCurrent: 1,
              pageSize: 5,
              total: RODCount,
            }}
          />
        </Spin>
        <h4 className="primary-title mt-4 mb-4">CDD Information</h4>
        <div className="border-box">
          <div className="overview-listing">
            <div className="mb-4">
              <label htmlFor="" className="me-3">
                Source of Wealth
              </label>
              <p className="box-style"> {cddInformation.wealth}</p>
            </div>
            <div className="mb-4">
              <label htmlFor="" className="me-3">
                Source of Funds
              </label>
              <p className="box-style">{cddInformation.funds}</p>
            </div>
            <div className="mb-4">
              <label htmlFor="" className="me-3">
                Purpose of Business
              </label>
              <p className="box-style">{cddInformation.business}</p>
            </div>
            <div className="row">
              {value ? (
                <div className="col-md-3">
                  <div className="mb-4">
                    <label htmlFor="" className="me-3 mb-1">
                      Geography of Business
                    </label>
                    <p className="box-style">
                      <TreeSelect {...tProps} placement="topLeft" />
                    </p>
                  </div>
                </div>
              ) : null}
              <div className="col-md-3 mb-4">
                <label htmlFor="" className="me-3 mb-1">
                  Business Activity
                </label>
                <p className="box-style">
                  {capitalizeFirstLetter(cddInformation.activity)}
                </p>
              </div>
              <div className="col-md-3 mb-4">
                <label htmlFor="" className="me-3 mb-1">
                  Description of Business
                </label>
                <p className="box-style">
                  {capitalizeFirstLetter(cddInformation.description)}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="mb-4">
                  <label htmlFor="" className="mb-1">
                    Entity Last Reviewed Date
                  </label>
                  <p className="box-style">
                    {moment(cddInformation.lastReviewed).format("DD-MMM-YYYY")}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-4">
                  <label htmlFor="" className="mb-1">
                    Entity Next Review Date
                  </label>
                  <p className="box-style">
                    {moment(cddInformation.nextReviewed).format("DD-MMM-YYYY")}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-4">
                  <label htmlFor="" className="mb-1">
                    Overall Entity Risk Level
                  </label>
                  <p className="box-style">
                    {capitalizeFirstLetter(cddInformation.riskRating)}
                  </p>
                </div>
              </div>
            </div>
            <div className="row"></div>
            <div className="row">
              {reminderCount &&
                reminderCount.map((a, i) => {
                  return (
                    <div className="col-md-3">
                      <div className="mb-4">
                        <label htmlFor="" className="mb-1">
                          Reminder {i + 1} Date
                        </label>
                        <p className="box-style">
                          {moment(a.data).format("DD-MMM-YYYY")}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="row">
              {bossFilingCount &&
                bossFilingCount.map((a, i) => {
                  return (
                    <div className="col-md-3">
                      <div className="mb-4">
                        <label htmlFor="" className="mb-1">
                          Boss Filing {i + 1} Date
                        </label>
                        <p className="box-style">
                          {moment(a.data).format("DD-MMM-YYYY")}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {!iframe && (
          <div className="d-flex mt-4 gap-3">
            <Popover
              className="custom-popover"
              placement="top"
              content={
                selectedCategory ? renderSubOptions() : renderMainOptions()
              }
            >
              <button
                className="primary-btn"
                aria-label="Generate Certificate of Incumbency"
              >
                Generate COI
              </button>
            </Popover>
            <button className="outline-btn">Request COGS</button>
          </div>
        )}
      </div>
    </div>
  );
}
