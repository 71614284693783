import React, { useState, useEffect } from "react";
import { Table, Tag, Modal, Select } from "antd";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";

import SampleDoc from "../assets/pdf/sample.pdf";

import {
  downloadFile,
  downloadBase64File,
  formatBytes,
  Each,
  focusOn,
  fetchApi,
  customPagination,
  downloadAsCSV,
  sendNotify,
} from "../helper";
import { getOnboarding } from "../store/onboarding/onboardingSlice";

import UploadZ from "../components/UploadZ";
import TableSortArrows from "../components/tableSortArrows";
import EntityRAF from "../components/EntityRAF";

export default function MLRO() {
  const dispatch = useDispatch();
  const [queryParams, setQueryParams] = useState({
    _start: 0,
    _limit: 10,
    taskStatus: "lead",
  });
  const onboarding = useSelector((state) => state.onboarding);
  let { isLogged, userDetails } = useSelector((state) => state.auth);

  const [requestData, setRequestData] = useState(null);
  const [docModal, setDocModal] = useState(false);
  const [viewDocName, setViewDocName] = useState("");
  const [showRejectReason, setShowRejectReason] = useState(false);
  const [countryRiskFromData, setCountryRiskFromData] = useState({});
  const [entityDetailFormData, setEntityDetailFromData] = useState({});
  const [overallRiskFromData, setOverallRiskFromData] = useState({});
  const [documents, setDocuments] = useState({});
  const [data, setData] = useState([]);
  const [selectRows, setSelectRows] = useState([]);
  const [comments, setComments] = useState({});
  const [selectStatus, setSelectStatus] = useState([]);
  useEffect(() => {
    dispatch(getOnboarding(queryParams));
  }, []);
  useEffect(() => {
    updateData();
  }, [onboarding]);

  const entityDetailsOnChange = async (value, name) => {
    setEntityDetailFromData(value);
  };
  const countryRiskDtaOnChange = async (value, name) => {
    setCountryRiskFromData(value);
  };
  const overallRiskDataOnChange = async (value, name) => {
    setOverallRiskFromData(value);
  };
  const getComments = (data, key) => {
    let obj = { ...comments };
    obj[key] = data;
    setComments(obj);
  };

  const updateData = async () => {
    if (onboarding?.data?.rows?.length) {
      let cat = onboarding?.data?.rows;
      let data = cat.map((a, index) => {
        let obj = {
          key: index,
          id: a._id,
          ref: a.code,
          // name: "Task #1",
          pid: a.pid,
          type: a.type,
          status: a.status,
          createdAt: dayjs(a.createdAt).format("DD/MM/YYYY - hh:mm A"),
          createdBy: a?.updatedBy?.name ?? "",
          entityName: a?.relationshipManager?.entityName,
          //entityCode: "9012",
          requestorID: a?.clientDetails?.code,
          reviewStatus: a.reviewStatus,
          comments: a.comments,
          processor: "",
          requestedBy: a?.requestedBy?.name,
        };
        return obj;
      });
      setData(data);
    }
  };
  const [openRAF, setOpenRAF] = useState(false);
  const handleCloseRAF = () => {
    console.log("clicked cancel button");
    setOpenRAF(false);
  };

  const columns = [
    {
      title: "Task ref",
      dataIndex: "ref",
      sorter: (a, b) => a.name - b.name,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Task Type",
      dataIndex: "type",
      sorter: (a, b) => a.type - b.type,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Approval Status",
      dataIndex: "reviewStatus",
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { reviewStatus }) => (
        <Select
          className="custom-select"
          placeholder="Select type"
          value={reviewStatus}
          style={{
            width: 160,
          }}
          disabled
          options={[
            {
              value: "approved",
              label: "Approved",
            },
            {
              value: "rejected",
              label: "Rejected",
            },
          ]}
        />
      ),
    },
    {
      title: "Comments",
      dataIndex: "comments",
      sorter: (a, b) => a.comments - b.comments,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, data) => (
        <div className="custom-form">
          <input
            type="text"
            name="comments"
            className="w-100"
            id="comments"
            defaultValue={data.comments}
            disabled
            placeholder="Enter comment"
          />
        </div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Created By",
      dataIndex: "requestedBy",
      sorter: (a, b) => a.requestedBy.name - b.requestedBy.name,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,

      render: (_, { requestedBy }) => (
        <>
          {[requestedBy].map((tag) => {
            console.log(tag, "waersdtyfui");

            let color = requestedBy.length > 5 ? "geekblue" : "green";

            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Entity Name",
      dataIndex: "entityName",
      key: "entityName",
      sorter: (a, b) => a.entityName - b.entityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      key: "entityCode",
      sorter: (a, b) => a.entityCode - b.entityCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // {
    //   title: "Requestor ID",
    //   dataIndex: "requestorID",
    //   sorter: (a, b) => a.requestorID - b.requestorID,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    // },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status, id }) => (
        <Select
          className="custom-select"
          placeholder="Select type"
          //  value={status}
          style={{
            width: 160,
          }}
          options={[
            {
              value: "in-progress",
              label: "In Progress",
            },
            {
              value: "completed",
              label: "Completed",
            },
            {
              value: "pending-info",
              label: "Pending Info",
            },
          ]}
          onChange={(e) => statusOnChange(e, id)}
          defaultValue={status}
        />
      ),
    },
    {
      title: "Reviewer",
      dataIndex: "processor",
      sorter: (a, b) => a.processor - b.processor,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // {
    //   title: "RAF",
    //   dataIndex: "ref",
    //   sorter: (a, b) => a.ref - b.ref,
    //  sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    //   render: (_, data) => (
    //     <button className="transparent-btn">
    //       <i className="far fa-arrow-to-bottom"></i>
    //     </button>
    //   ),
    // },
    // {
    //   title: "Signed RAF",
    //   dataIndex: "signedRAF",
    //   sorter: (a, b) => a.signedRAF - b.signedRAF,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    //   render: (_, data) => (
    //     <div className="table-upload">
    //       <label
    //         htmlFor="doc"
    //         className="action-btn outline-btn"
    //         onClick={() => setOpenRAF(true)}
    //       >
    //         Execute
    //       </label>
    //     </div>
    //   ),
    // },
    {
      title: "Approved By",
      dataIndex: "approvedBy",
      sorter: (a, b) => a.approvedBy - b.approvedBy,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Approved At",
      dataIndex: "approvedAt",
      sorter: (a, b) => a.approvedAt - b.approvedAt,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Actions",
      fixed: "right",
      width: 180,
      render: (_, data) => (
        <button onClick={() => viewRequest(data)} className="action-btn">
          View Request &nbsp; <i className="far fa-long-arrow-right me-0"></i>
        </button>
      ),
    },
  ];
  const renderRAFModal = () => {
    return (
      openRAF && (
        <Modal
          title="Risk Assessment Form - Entity"
          className="custom-modal"
          open={openRAF}
          onCancel={handleCloseRAF}
          width={1300}
          centered
          footer={null}
        >
          <EntityRAF
            entityDetailFormData={entityDetailFormData}
            entityDetailsOnChange={entityDetailsOnChange}
            countryRiskFromData={countryRiskFromData}
            countryRiskDtaOnChange={countryRiskDtaOnChange}
            overallRiskFromData={overallRiskFromData}
            overallRiskDataOnChange={overallRiskDataOnChange}
            isCooperate={false}
          />
        </Modal>
      )
    );
  };

  // const data = [
  //   {
  //     key: "1",
  //     id: "1",
  //     name: "Task #1",
  //     type: "Active",
  //     status: "approved",
  //     createdAt: "07/11/2023 04:56PM",
  //     createdBy: "John Doe",
  //     entityName: "Trust",
  //     entityCode: "9012",
  //     requestorID: "2343",
  //     reviewStatus: "completed",
  //     approvedAt: "08/11/2023 04:56PM",
  //     processor: "Hari",
  //     approvedBy: "Guru",
  //   },
  //   {
  //     key: "2",
  //     id: "2",
  //     name: "Task #2",
  //     type: "Active",
  //     status: "rejected",
  //     createdAt: "07/11/2023 04:56PM",
  //     createdBy: "John Doe",
  //     entityName: "Trust",
  //     entityCode: "9012",
  //     requestorID: "2343",
  //     reviewStatus: "pending-info",
  //     processor: "Hari",
  //     approvedAt: "08/11/2023 04:56PM",
  //     approvedBy: "Guru",
  //   },
  //   {
  //     key: "3",
  //     id: "3",
  //     name: "Task #3",
  //     type: "Active",
  //     status: "approved",
  //     createdAt: "07/11/2023 04:56PM",
  //     createdBy: "John Doe",
  //     entityName: "Trust",
  //     entityCode: "9012",
  //     requestorID: "2343",
  //     reviewStatus: "in-progress",
  //     processor: "Hari",
  //     approvedAt: "08/11/2023 04:56PM",
  //     approvedBy: "Guru",
  //   },
  // ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const viewRequest = (data) => {
    let finded = onboarding?.data?.rows.find((a) => a._id == data.id);
    console.log(finded, "qefrgstyjyktiy");
    if (finded) {
      if (finded.individual) {
      }
      if (finded.corporate) {
      }
      if (finded.relatedEntity) {
      }
      if (finded.statutory) {
      }
      if (finded.documents) {
        console.log(finded.documents, "wsdtyfuio");
      }
    }

    setRequestData(data);
    setDocuments(finded);
  };

  const fetchLiveUrl = async (url) => {
    console.log(url, "data-1");
    try {
      const data = { url: url };
      const obj = {
        method: "POST",
        url: `/file/get`,
        data: data,
      };

      return await fetchApi(obj);
    } catch (error) {
      throw error;
    }
  };
  const downloadDocument = async (url, name) => {
    let data = await fetchLiveUrl(url);
    downloadBase64File(data?.data?.base64Url, name, data?.data?.contentType);
  };

  const viewDocument = (docName) => {
    setViewDocName(docName);
    setDocModal(true);
  };

  const handleOk = () => {
    setDocModal(false);
    setViewDocName("");
  };
  const handleCancel = () => {
    setDocModal(false);
    setViewDocName("");
  };

  const DownloadDoc = () => {
    downloadFile(SampleDoc, "sample_doc.pdf");
  };
  const updateStatusOnClick = async (value) => {
    if (value) {
      setShowRejectReason(true);
    } else {
      setShowRejectReason(false);
    }
  };
  const updateStatus = async () => {
    let data = {
      reviewStatus: showRejectReason ? "Rejected" : "Approved",
      comments:
        comments?.comments ??
        `This task was approved by ${userDetails?.userName?.replace(
          /\b\w/g,
          (l) => l.toUpperCase()
        )} - ${dayjs(new Date()).format("DD/MM/YYYY - hh:mm A")}`,
    };

    let updatePayload = {
      method: "PUT",
      url: `/onboarding/update/${documents._id}`,
      data,
    };

    await fetchApi(updatePayload)
      .then((res) => {
        sendNotify("success", res?.message);
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
    dispatch(getOnboarding(queryParams));
  };
  const statusOnChange = (value, id) => {
    setSelectStatus((prevStatus) => {
      let updatedStatus = [...prevStatus];
      let index = updatedStatus.findIndex((item) => item.id === id);

      if (index >= 0) {
        updatedStatus[index].status = value;
      } else {
        updatedStatus.push({ id, status: value });
      }

      return updatedStatus;
    });
  };

  const submitOnClick = async () => {
    try {
      await Promise.all(
        selectStatus.map(async (a) => {
          let updatePayload = {
            method: "PUT",
            url: `/onboarding/update/${a.id}`,
            data: { status: a.status },
          };

          return fetchApi(updatePayload)
            .then((res) => {
              dispatch(getOnboarding(queryParams));
              sendNotify("success", res?.message);
            })
            .catch((error) => {
              sendNotify("error", error?.message);
            });
        })
      );
    } catch (error) {
      sendNotify("error", error?.message);
    }
  };

  function downloadData() {
    if (selectRows?.length != 0) {
      let download = selectRows.map((item, i) => {
        return {
          "Task Ref": item?.ref,
          "Task Type": item?.type,
          "Created Date and Time": item?.createdAt,
          "Created By": item?.createdBy,
          "Entity Name": item?.entityName ?? "N/A",
          "Requestor ID": item?.requestorID ?? "N/A",
          "Review Status": item?.reviewStatus ?? "N/A",
          "Approval Status":
            item?.status == "O"
              ? "On Going"
              : item?.status == "A"
              ? "Approved"
              : item?.status == "R"
              ? "Rejected"
              : "N/A",
          MLRO: item?.processor,
        };
      });
      downloadAsCSV(download, "Lead/Manager Approver Dashboard");
    }
  }

  return (
    <div>
      <div className="page-header">
        <h1>MLRO Dashboard</h1>
      </div>
      <div className="page-content">
        <div className="mb-3 d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center">
          <h2 className="primary-title mb-3 mb-md-0">MLRO Approval Status</h2>
          <div className="d-flex gap-3 flex-column flex-md-row">
            <button
              className="outline-btn"
              onClick={downloadData}
              disabled={selectRows?.length === 0}
            >
              <i className="far fa-arrow-to-bottom"></i> Download Selected
            </button>
            {/* <Link className="outline-btn" to={"/app/new-incorporation"}>
              <i className="far fa-paper-plane"></i> Send Feedback
            </Link> */}
            <button className="primary-btn" onClick={submitOnClick}>
              Submit Feedback
            </button>
          </div>
        </div>
        <Table
          className="custom-table"
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: 3000 }}
        />
        {renderRAFModal()}
        {requestData && (
          <div className="mt-4">
            <h2 className="primary-title mb-3">Approval of MLRO</h2>
            <h4 className="sub-title mb-2">Task Name: {requestData?.type}</h4>
            <h4 className="sub-title">
              {" "}
              Current Status:{" "}
              <span
                className={
                  requestData?.reviewStatus == "Approved"
                    ? "text-green"
                    : "text-red"
                }
              >
                {requestData?.reviewStatus == "Approved"
                  ? "Approved"
                  : "Rejected"}
              </span>
            </h4>
            <div className="custom-form mt-4">
              <div className="row">
                {/* <div className="col-md-3">
                <label htmlFor="" className="me-3">
                  Reviewed AML and STAT Pack
                </label>
                <UploadZ file={documents} downloadOnly={true} />
              </div> */}
                {documents?.documents?.length ? (
                  <Each
                    array={documents.documents}
                    render={(item) => (
                      <div className="col-md-3">
                        <label className="custom-checkbox mb-3">
                          {item.name}
                        </label>
                        <UploadZ
                          file={item}
                          downloadOnly={true}
                          downloadDocument={downloadDocument}
                        />
                      </div>
                    )}
                  />
                ) : (
                  <></>
                )}
                {documents?.statutory?.length ? (
                  <Each
                    array={documents.statutory}
                    render={(item) => (
                      <div className="col-md-3">
                        <label className="custom-checkbox mb-3">
                          {item.name}
                        </label>
                        <UploadZ file={item} downloadOnly={true} />
                      </div>
                    )}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="d-flex gap-3 my-4">
                <button
                  className="outline-btn green-btn"
                  onClick={() => updateStatusOnClick(false)}
                  disabled={!showRejectReason}
                >
                  Approve
                </button>
                <button
                  className="outline-btn red-btn"
                  onClick={() => updateStatusOnClick(true)}
                  disabled={showRejectReason}
                >
                  Reject
                </button>
              </div>
              {showRejectReason && (
                <div>
                  <label htmlFor="" className="me-3">
                    Reason for Rejection
                  </label>
                  <textarea
                    name=""
                    id=""
                    className="w-100"
                    placeholder="Enter a description..."
                    rows="5"
                    onChange={(e) => getComments(e.target.value, "comments")}
                  ></textarea>
                </div>
              )}
              <button
                className="primary-btn"
                onClick={() => updateStatus()}
                disabled={false}
              >
                Submit
              </button>
            </div>
          </div>
        )}
        <Modal
          title={viewDocName}
          className="custom-modal"
          open={docModal}
          width={1000}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="document-view">
            <iframe
              src={SampleDoc}
              frameBorder="0"
              height="100%"
              width="100%"
            ></iframe>
          </div>
        </Modal>
      </div>
    </div>
  );
}
