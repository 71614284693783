import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { Table, Switch, Popover, Checkbox, Spin, Tabs, Modal } from "antd";
import moment from "moment/moment";
import FormZ from "../../../components/FormZ";
import {
  fetchApi,
  convertQueryParams,
  sendNotify,
  customPagination,
  getQuery,
  downloadAsCSV,
} from "../../../helper";
import TabZ from "../../../components/TabZ";
import InProgressIcon from "../../../assets/img/in-progress-icon.svg";
import ApprovedIcon from "../../../assets/img/approved-icon.svg";
import TableSortArrows from "../../../components/tableSortArrows";
import TagZ from "../../../components/TagZ";

import { languagesArray } from "../../../store/language/languageSlice";
import masterSearch from "../../../helper/translations/master-search.json";

export default function RegisterOfDirectors(props) {
  const { language } = useSelector((state) => state.language);

  const [selectedTabs, setSelectedTabs] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [registers, setRegisters] = useState({});
  const [registerOfDirectorsShow, setRegisterOfDirectorsShow] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [commentModal, setCommentModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [searchText, setSearchText] = useState("");
  const [allDataArray, setAllDataArray] = useState([]);
  const [tabs, setTabs] = useState("Active");
  let { id } = props;
  let query = getQuery();
  const [selctedLanguage, setSelctedLanguage] = useState("");
  useEffect(() => {
    let index = languagesArray.find((tc) => tc.value == language);
    setSelctedLanguage(index.value);
  }, [language]);
  let t = masterSearch.other[selctedLanguage] || masterSearch.other.en;

  const commentFormRef = useRef();

  let commentFormSchema = [
    {
      name: "comment",
      value: "",
      type: "textarea",
      label: "Comment",
      placeholder: "Enter here...",
      customClass: "col-md-10",
      required: true,
    },
  ];
  const fetchData = async (url) => {
    try {
      const response = await fetchApi({ method: "GET", url });
      return response?.data?.rows || [];
    } catch (error) {
      console.error(`Error fetching ${url}:`, error);
      return [];
    }
  };
  const handleCheckboxChange = (checkedValues) => {
    setSelectedTabs(checkedValues);
    handleRefresh(checkedValues, "Active");
  };
  const handleRefresh = async (selectedTabs, tabs) => {
    if (registerOfDirectorsShow) {
      setLoading(true);
      try {
        const [
          clientData,
          individualDetails,
          relatedEntityDetails,
          copEntityDetails,
        ] = await Promise.all([
          fetchData(
            `/client?${convertQueryParams({ isAll: true, exportAll: true })}`
          ),
          fetchData(
            `/individualDetails?${convertQueryParams({
              all: true,
              enId: id ? id : query.id,
            })}`
          ),
          fetchData(
            `/relatedEntityDetails?${convertQueryParams({
              all: true,
              enId: id ? id : query.id,
            })}`
          ),
          fetchData(
            `/copEntityDetails?${convertQueryParams({
              all: true,
              enId: id ? id : query.id,
            })}`
          ),
        ]);

        const allData = [
          ...individualDetails,
          ...relatedEntityDetails,
          ...copEntityDetails,
        ];

        const filteredData = allData
          .filter((item) => selectedTabs.some((type) => item.checkedList[type]))
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          .map((item) => ({
            ...item,
            status:
              !item.resignation || new Date() < new Date(item.resignation)
                ? "Active"
                : "Inactive",
          }));

        const getLabel = (obj) => {
          if (obj.reserve) return "Reserve directors";
          if (obj.directors) return "Directors";
          if (obj.president) return "President";
          if (obj.secretary) return "Secretary";
          return null;
        };
        let registers = {
          reserve: [],
          directors: [],
          president: [],
          secretary: [],
        };

        allData.forEach((a) => {
          if (a.checkedList.directors) {
            registers.directors.push(a);
          }
          if (a.checkedList.reserve) {
            registers.reserve.push(a);
          }
          if (a.checkedList.president) {
            registers.president.push(a);
          }
          if (a.checkedList.secretary) {
            registers.secretary.push(a);
          }
        });

        setRegisters(registers);
        setTotalCount(filteredData.length);
        const formatAddress = (details) => {
          if (!details?.address) return "Address not available";

          const { line_1, line_2, line_3, zipCode, country } = details.address;

          return [line_1, line_2, line_3, zipCode, country]
            .filter(Boolean)
            .join(", ");
        };
        const clientDataMap = new Map(
          clientData.map((d) => [
            d._id,
            d.name ||
              `${d.firstName || ""} ${
                d.middleName && d.middleName !== "." ? d.middleName + " " : ""
              }${d.lastName || ""}`.trim(),
          ])
        );

        const addressMap = new Map(
          clientData.map((a) => {
            const serviceAddr = a?.serviceAddress ?? "N/A";
            const residentialAddr = a?.residentialAddress ?? "N/A";
            const copAddr = formatAddress(a?.details?.address) ?? "N/A";
            const entityAddr = formatAddress(a?.registers?.address) ?? "N/A";

            return [
              a._id,
              {
                individual: `Service Address: ${serviceAddr} , Residential Address: ${residentialAddr}`,
                corporate: `Address: ${copAddr}`,
                entity: `Address: ${entityAddr}`,
              },
            ];
          })
        );

        const data = filteredData.map((row) => {
          const masterId = row.invId || row.copId || row.relEntity;
          const masterType = row.invId
            ? "Individual"
            : row.copId
            ? "Corporate Entity"
            : "Related Entity";
          const addressData = addressMap.get(masterId) || {};

          return {
            _id: row._id,
            masterFileName: clientDataMap.get(masterId),
            data: row,
            masterType,
            resignation: row.resignation
              ? moment(row.resignation).format("YYYY-MM-DD")
              : "-",
            appointment: row.appointment
              ? moment(row.appointment).format("YYYY-MM-DD")
              : "-",
            status: row.status,
            shareTypes: getLabel(row.checkedList),
            comment: row.comment,
            address: row.invId
              ? addressData.individual
              : row.copId
              ? addressData.corporate
              : addressData.entity,
          };
        });

        setLoading(false);
        setTableData(data);
        setAllDataArray(data);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleSearch = (value) => {
    setSearchText(value?.toLowerCase() || "");

    const filtered = allDataArray.filter(
      (item) =>
        (item.masterFileName?.toLowerCase() || "").includes(
          value?.toLowerCase() || ""
        ) ||
        (item.masterType?.toLowerCase() || "").includes(
          value?.toLowerCase() || ""
        ) ||
        (item.address?.toLowerCase() || "").includes(value?.toLowerCase() || "")
    );

    setTableData(filtered);
  };

  const handleApiPut = async (url, data) => {
    try {
      const response = await fetchApi({ method: "PUT", url, data });
      if (response.status == 200) {
        handleRefresh(selectedTabs, tabs);
        setCommentModal(false);
        sendNotify("success", "Update Successfully");
      } else {
        sendNotify("error", "Error in Update");
      }
      return response;
    } catch (error) {
      sendNotify("error", error?.message);
      throw error;
    }
  };
  const commentOnClick = async (masterType, value, action) => {
    setSelectedData({ masterType, ...value });
    if (action == "edit") {
      setFormData({ comment: value.comment });
    } else {
      setFormData({});
    }
    setCommentModal(true);
  };
  const RODCcolumns = [
    {
      title: "Full Name",
      dataIndex: "masterFileName",
    },

    {
      title: "MasterFile Type",
      dataIndex: "masterType",
      sorter: (a, b) => a.masterType.localeCompare(b.masterType),
    },
    {
      title: "Address",
      render: (_, { address }, index) => (
        <div className="d-flex gap-2 align-items-center justify-content-end">
          <Popover
            className="custom-popover"
            placement="rightBottom"
            content={
              <p className="mb-0">
                <div className="container mt-4">
                  <div className="card p-3 shadow-sm">
                    <h6 className="fw-bold">User Details</h6>
                    <p>
                      <strong>{address}</strong>
                    </p>
                  </div>
                </div>
              </p>
            }
          >
            <button className="action-btn">
              {" "}
              <i className="fas fa-map-marker-alt"></i>{" "}
            </button>
          </Popover>
        </div>
      ),
    },

    {
      title: "Officer Type",
      dataIndex: "shareTypes",
      sorter: (a, b) => a.shareTypes.localeCompare(b.shareTypes),
    },
    {
      title: "Date Of Appointment",
      dataIndex: "appointment",
      sorter: (a, b) =>
        moment(a.appointment).unix() - moment(b.appointment).unix(),
    },
    {
      title: "Date Of Resignation",
      dataIndex: "resignation",
      sorter: (a, b) =>
        moment(a.appointment).unix() - moment(b.appointment).unix(),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={status == "Active" ? "approved" : "rejected"}
          statusText={status?.charAt(0)?.toUpperCase() + status?.slice(1)} //
        />
      ),
    },
    {
      title: "Notes",
      render: (_, { _id, masterType, comment, data }, index) => (
        <div className="d-flex gap-2 align-items-center justify-content-end">
          {comment ? (
            <button
              className="action-btn"
              onClick={() => commentOnClick(masterType, data, "edit")}
            >
              <i className="far fa-pencil"></i>
            </button>
          ) : (
            <button
              className="primary-btn"
              onClick={() => commentOnClick(masterType, data, "add")}
            >
              <i className="fal fa-plus-circle"></i>
            </button>
          )}
          <Popover
            className="custom-popover"
            placement="rightBottom"
            content={<p className="mb-0">{comment}</p>}
          >
            <button className="primary-btn">
              {" "}
              <i className="fal fa-eye"></i>
            </button>
          </Popover>
        </div>
      ),
    },
  ];
  const saveData = async () => {
    let commentFormSchema = commentFormRef.current.getPayload();
    let data = { comment: commentFormSchema.comment, enId: selectedData.enId };
    if (selectedData.masterType == "Individual") {
      data.invId = selectedData.invId;
      await handleApiPut(`/individualDetails/update/${selectedData._id}`, data);
    } else if (selectedData.masterType == "Corporate Entity") {
      data.copId = selectedData.copId;

      await handleApiPut(`/copEntityDetails/update/${selectedData._id}`, data);
    } else if (selectedData.masterType == "Related Entity") {
      data.relEntity = selectedData.relEntity;

      await handleApiPut(
        `/relatedEntityDetails/update/${selectedData._id}`,
        data
      );
    }
  };
  const handleCancel = async () => {
    setCommentModal(false);
    setSelectedData({});
  };
  const openRegisterOfDirectorsModal = (value) => {
    setRegisterOfDirectorsShow(value);
  };
  const onExportClick = () => {
    downloadAsCSV(tableData, "Register-Of-Directors");
  };

  const labels = [
    {
      key: "Active",
      label: (
        <>
          <img src={ApprovedIcon} alt="" /> {t.active}
        </>
      ),
    },
    {
      key: "Inactive",
      label: (
        <>
          <img src={InProgressIcon} alt="" /> {t.inactive}
        </>
      ),
    },
  ];
  const getProgress = async (value) => {
    if (value == "Active") {
    } else {
    }

    setTabs(value);
  };
  return (
    <>
      <div className="d-flex flex-wrap py-4 gap-3 align-items-center">
        <Switch
          className="custom-switch"
          onChange={(value) => openRegisterOfDirectorsModal(value)}
        />
        <h4 className="primary-title">Registers</h4>
        {registerOfDirectorsShow && (
          <div className="w-100">
            <div className="row">
              <div className="custom-form col-md-8">
                <input
                  type="text"
                  placeholder="Search MasterFile: Name, Type, Address"
                  value={searchText}
                  onChange={(e) => handleSearch(e.target.value)}
                  className="border p-2 w-full rounded"
                />
              </div>
              <div className="custom-form col-md-4">
                <div className="d-flex gap-3">
                  <button
                    className="primary-btn mb-15"
                    onClick={() => handleRefresh(selectedTabs, tabs)}
                  >
                    <i className="fas fa-sync-alt"></i> Reload
                  </button>
                  <button className="primary-btn" onClick={onExportClick}>
                    <i className="fas fa-file-export"></i> Export
                  </button>
                </div>
              </div>
              <div className="d-flex gap-3 flex-column flex-md-row align-items-md-center align-items-start mt-4 mb-2">
                <TabZ
                  className="custom-checkbox"
                  labels={labels}
                  defaultActive={"Active"}
                  customClass={"table-tabs"}
                  onChange={getProgress}
                />

                <Checkbox.Group
                  className="custom-checkbox"
                  options={[
                    {
                      label: registers?.directors?.length
                        ? `Directors (${registers?.directors?.length})`
                        : "Directors",
                      value: "directors",
                    },
                    {
                      label: registers?.reserve?.length
                        ? `Reserve Directors (${registers?.reserve?.length})`
                        : "Reserve Directors",
                      value: "reserve",
                    },
                    {
                      label: registers?.president?.length
                        ? `President (${registers?.president?.length})`
                        : "President",
                      value: "president",
                    },
                    {
                      label: registers?.secretary?.length
                        ? `Secretary (${registers?.secretary?.length})`
                        : "Secretary",
                      value: "secretary",
                    },
                  ]}
                  value={selectedTabs}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {registerOfDirectorsShow ? (
        <div>
          <Spin spinning={loading}>
            <div className="border-box">
              {" "}
              <Table
                className="custom-table-light mt-md-1 mb-3"
                columns={RODCcolumns}
                dataSource={tableData}
                rowKey={(r) => r._id}
                scroll={{ x: 1000 }}
                pagination={{
                  className: "custom-pagination",
                  defaultCurrent: 1,
                  pageSize: 5,
                  total: totalCount,
                  itemRender: customPagination,
                }}
              />
            </div>
          </Spin>
          <Modal
            title={
              <>
                <h6>Add Comment</h6>
              </>
            }
            open={commentModal}
            className="custom-modal-2"
            onOk={saveData}
            onCancel={handleCancel}
            width={700}
          >
            <FormZ
              ref={commentFormRef}
              formSchema={commentFormSchema}
              formData={formData}
              formClass="custom-form"
            />
          </Modal>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
