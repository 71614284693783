import { Outlet, useNavigate, useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import routes from "./routes";
import AccessDenied from "../pages/access-denied";

const PrivateRoute = () => {
  let { isLogged, userDetails } = useSelector((state) => state.auth);
  let location = useLocation();

  console.log(userDetails, "userDetails");
  const accessList = [
    "In-House Translation",
    "Manual Translation",
    "In-House OCR",
    "Manual OCR",
    "Storage",
    "OCR",
    "RAF",
    "Web Search",
    "Risk Analysis",
    "CDD Screen",
    "Document Review",
    "New Incorporation Name Check",
    "MLRO Sign Off Dashboard",
    "New Business Onboard",
    "Send to Approval",
    "Incorporation Request",
    "Active Requests",
    "Incorporation Confirmation",
    "Incorporation Feedback",
    "Incorporation Pack",
    "Master Search",
    "MLRO Sign Off",
    "New Incorporation",
    "Pending Information",
    "Post Incorp",
    "Pre-Incorp Supported Documents",
    "Entity Invoicing",
    "Entity Overview - Update",
    "Entity Overview - List",
    "Director Screen",
    "Shareholder",
    "Trust",
    "Partnership",
    "General Partnership",
    "Limited Partnership",
    "Parent Client",
    "Client",
    "Crypto KYC",
    "Finance",
    "Individual Analysis",
    "My Portfolio",
  ];

  let map = routes
    .filter((f) => f.type === "private")
    .map((a, index) => {
      let obj = {};
      obj.sno = index + 1;
      obj.name = a.name;
      obj.type = a.type;
      obj.isacc = accessList.includes(a.name);
      return obj;
    });
  let newf = map.filter((ff) => ff.isacc === true);
  console.log(newf);
  let path = location.pathname.split("/")[2];
  let access = routes
    ?.find((r) => r.path.split("/")[0] === path)
    ?.permissions?.find((r) => r === userDetails?.role)
    ? true
    : false;

  return isLogged ? (
    access ? (
      <Outlet />
    ) : (
      <AccessDenied />
    )
  ) : (
    <Navigate to={"/login"} state={{ from: location }} replace />
  );
};

export default PrivateRoute;
