import { useRef } from "react";
import { Modal } from "antd";

import FormZ from "./FormZ";

export default function ContactSupport({ open, onOk, onCancel }) {
  const contactFormRef = useRef();

  let contactFormSchema = [
    {
      name: "clientCode",
      value: "",
      type: "text",
      label: "Masterfile Code",
      placeholder: "Enter masterfile code",
      required: true,
    },
    {
      name: "clientName",
      value: "",
      type: "text",
      label: "Masterfile Name",
      placeholder: "Enter masterfile name",
    },
    {
      name: "information",
      value: "",
      type: "textarea",
      label: "More Information",
      placeholder: "Leave a comment...",
    },
  ];

  return (
    <div>
      <Modal
        title={"Contact Support"}
        className="custom-modal-2"
        open={open}
        width={600}
        onOk={onOk}
        okText="Submit"
        onCancel={onCancel}
      >
        <FormZ
          ref={contactFormRef}
          formSchema={contactFormSchema}
          formClass="custom-form"
        />
      </Modal>
    </div>
  );
}
