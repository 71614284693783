import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { getNotifications } from "../store/notifications/notificationsSlice";
import { useDispatch, useSelector } from "react-redux";
import socketIOClient from "socket.io-client";
import {
  chatTime,
  eachChatTextTime,
  timeDiff,
  createEmptyArray,
} from "../helper";
import { Tabs, DatePicker, Select, Spin } from "antd";
import NoDataImg from "../assets/img/no-notifications.jpg";
import {
  sendNotify,
  getOptions,
  fetchApi,
  generateRandom,
  getQuery,
} from "../helper";
import TabZ from "../components/TabZ";

const { RangePicker } = DatePicker;

const socket = socketIOClient(
  process.env.REACT_APP_API_URL.split("/api", 1)[0]
);

let message = [
  {
    _id: "1",
    chat_from: "660ab12ca70b3f60ca416068",
    type: "alert",
    chat_to: "660ba854b81a8f195df17206",
    title: "New inspection assigned: QW0001 Pepsi Asi",
    chat_msg:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Quisquam, blanditiis!",
    createdAt: "2024-04-03T18:37:03.679+00:00",
    updatedAt: "2024-04-03T18:37:03.679+00:00",
  },
  {
    _id: "2",
    chat_from: "660ab12ca70b3f60ca416068",
    type: "notifications",
    chat_to: "660ba854b81a8f195df17206",
    title: "New inspection assigned: QW0002 Pepsi Asi",
    chat_msg:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Quisquam, blanditiis! ",
    createdAt: "2024-04-03T19:29:30.422+00:00",
    updatedAt: "2024-04-03T19:29:30.422+00:00",
  },
  {
    _id: "3",
    chat_from: "660ab12ca70b3f60ca416068",
    type: "notifications",
    chat_to: "660ba854b81a8f195df17206",
    title: "New inspection assigned: QW0003 Pepsi Asi",
    chat_msg:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Quisquam, blanditiis! ",
    createdAt: "2024-04-01T19:18:52.704+00:00",
    updatedAt: "2024-04-01T19:18:52.704+00:00",
  },
  {
    _id: "4",
    chat_from: "660ab12ca70b3f60ca416068",
    type: "alert",
    chat_to: "660ba854b81a8f195df17206",
    title: "New inspection assigned: QW0004 Pepsi Asi",
    chat_msg:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Quisquam, blanditiis! ",
    createdAt: "2024-04-02T19:18:52.704+00:00",
    updatedAt: "2024-04-02T19:18:52.704+00:00",
  },
  {
    _id: "5",
    chat_from: "660ab12ca70b3f60ca416068",
    type: "notifications",
    chat_to: "660ba854b81a8f195df17206",
    title: "New inspection assigned: QW0005 Pepsi Asi",
    chat_msg:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Quisquam, blanditiis! ",
    createdAt: "2024-04-02T19:18:52.704+00:00",
    updatedAt: "2024-04-02T19:18:52.704+00:00",
  },
  {
    _id: "6",
    chat_from: "660ab12ca70b3f60ca416068",
    type: "alert",
    chat_to: "660ba854b81a8f195df17206",
    title: "New inspection assigned: QW0006 Pepsi Asi",
    chat_msg:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Quisquam, blanditiis! ",
    createdAt: "2024-04-02T19:18:52.704+00:00",
    updatedAt: "2024-04-02T19:18:52.704+00:00",
  },
  {
    _id: "6",
    chat_from: "660ab12ca70b3f60ca416068",
    type: "notifications",
    chat_to: "660ba854b81a8f195df17206",
    title: "New inspection assigned: QW0006 Pepsi Asi",
    chat_msg:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Quisquam, blanditiis! ",
    createdAt: "2024-04-02T19:18:52.704+00:00",
    updatedAt: "2024-04-02T19:18:52.704+00:00",
  },
];

export default function Notification() {
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector((state) => state.notification);
  let { isLogged, userDetails } = useSelector((state) => state.auth);
  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 25 });

  const [search, setSearch] = useState("");
  const [action, setAction] = useState("all");
  const [actionLoading, setActionLoading] = useState(false);

  const [messages, setMessages] = useState([]);
  const [masterArray, setMasterArray] = useState([]);
  const [selectedMasterId, setSelectedMasterId] = useState("all");

  const [selectedCatIds, setSelectedCatIds] = useState([]);
  const [tab, setTab] = useState("");

  useEffect(() => {
    dispatch(getNotifications(queryParams));
  }, []);

  useEffect(() => {
    setMessages(data?.rows);
  }, [data]);

  socket.on("notification", (notification) => {
    if (notification.userId === userDetails._id) {
      dispatch(getNotifications(queryParams));
    }
  });

  const handleChange = async (value) => {
    if (value == "all") {
      let params = { ...queryParams };
      setSelectedMasterId("all");
      delete params.masterId;
      dispatch(getNotifications(params));
    } else {
      setActionLoading(true);

      let url = "";

      switch (value) {
        case "entity":
          url = "/entity";
          break;
        case "individual":
          url = "/individual";
          break;
        case "corporateEntity":
          url = "/cop-entity";
          break;
        default:
          url = `/client?_start=0&_limit=1000&masterType=${value}`;
          break;
      }

      let payload = {
        method: "GET",
        url,
      };

      let data = await fetchApi(payload).then((res) => {
        setActionLoading(false);
        return res.data.rows;
      });

      let list = data.map((d) => {
        let obj = {
          value: d?._id,
          label: d?.code,
        };
        return obj;
      });

      setMasterArray(getOptions(list));
    }
    setAction(value);
  };

  const deleteNotify = (id) => {
    let array = [...selectedCatIds];
    let index = array.indexOf(id);
    if (index >= 0) {
      array.splice(index, 1);
    } else {
      array.push(id);
    }
    let dataArry = messages.filter((m) => !array.includes(m._id));
    setMessages(dataArry);
    setSelectedCatIds(array);
  };
  let groupedMessagesArray = [];

  if (messages) {
    groupedMessagesArray = Object.entries(
      messages.reduce((acc, message) => {
        const date = message.createdAt.split("T")[0];
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(message);
        return acc;
      }, {})
    ).map(([date, messages]) => ({ date, messages }));

    groupedMessagesArray.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA;
    });
  }

  const labels = [
    {
      key: 1,
      label: (
        <>
          <i className="far fa-mail-bulk"></i> All Notification
        </>
      ),
    },
    {
      key: 2,
      label: (
        <>
          <i className="far fa-envelope"></i> Unread
        </>
      ),
    },
    {
      key: 3,
      label: (
        <>
          <i className="far fa-envelope-open-text"></i> Read
        </>
      ),
    },
  ];

  const onChange = (tab) => {
    let params = { ...queryParams };

    if (tab === 1) {
      dispatch(getNotifications(params));
    } else if (tab === 2) {
      params.read = "W";
      dispatch(getNotifications(params));
    } else {
      params.read = "Y";
      dispatch(getNotifications(params));
    }
    setTab(tab);
  };
  const searchOnChange = (search) => {
    let params = { ...queryParams };
    params.search = search;
    dispatch(getNotifications(params));
    setSearch(search);
  };
  const handleDateChange = (value, dateString) => {
    let params = { ...queryParams };
    if (value) {
      params.dateRange = dateString;
      dispatch(getNotifications(params));
    } else {
      delete params.dateRange;
      dispatch(getNotifications(params));
    }

    // const data2 = Moment(today).format('YYYY-MM-DD')
    // let startDate = date;
  };

  const getMasterId = async (value) => {
    let params = { ...queryParams };
    params.type = action;
    params.masterId = value;
    dispatch(getNotifications(params));
    setSelectedMasterId(value);
  };

  return (
    <div>
      <div className="page-header">
        <h1>Notifications</h1>
      </div>
      <div className="page-content">
        <div className="custom-form d-flex justify-content-between align-items-center mb-3 mb-md-4">
          <div className="row w-100">
            <div className="col-md-4">
              <label htmlFor="">Search</label>
              <div className="search-box">
                <input
                  type="text"
                  value={search}
                  onChange={(e) => searchOnChange(e.target.value)}
                  placeholder="Search"
                />
                <span className="search-icon">
                  <i className="fal fa-search"></i>
                </span>
              </div>
            </div>
            <div className="col-md-2">
              <label htmlFor="">Filter by role</label>
              <Select
                onChange={handleChange}
                value={action}
                loading={actionLoading}
                className="custom-select w-100"
                options={[
                  {
                    value: "all",
                    label: "All",
                  },
                  {
                    value: "client",
                    label: "Client",
                  },
                  {
                    value: "introducer-client",
                    label: "Introducer Client",
                  },
                  {
                    value: "parent-client",
                    label: "Parent Client",
                  },
                  {
                    value: "end-user",
                    label: "End User Client",
                  },
                  {
                    value: "entity",
                    label: "Entity",
                  },
                  {
                    value: "individual",
                    label: "Individual",
                  },
                  {
                    value: "corporateEntity",
                    label: "Corporate Entity",
                  },
                ]}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="" className="text-capitalize">
                Select {action != "all" ? action.replaceAll("-", " ") : ""}
              </label>
              <Select
                disabled={action == "all"}
                value={selectedMasterId}
                onChange={getMasterId}
                className="custom-select w-100"
                options={[
                  {
                    value: "all",
                    label: "All",
                  },
                  ...masterArray,
                ]}
              />
            </div>
            {/* <div className="col-md-2">
            <label htmlFor="">Filter</label>
            <Select
              value="all"
              className="custom-select w-100"
              options={[
                {
                  value: "all",
                  label: "All",
                },
                {
                  value: "001",
                  label: "ET001",
                },
                {
                  value: "002",
                  label: "ET002",
                },
              ]}
            />
          </div> */}
            <div className="col-md-4">
              <label htmlFor="">Filter by date</label>
              <RangePicker
                onChange={handleDateChange}
                className="custom-datepicker mb-2 w-100"
              />
            </div>
          </div>
        </div>
        {/* <Tabs
        className="custom-tabs"
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
      /> */}
        <TabZ labels={labels} defaultActive={1} onChange={onChange} />
        <Spin spinning={isLoading}>
          {groupedMessagesArray && groupedMessagesArray.length > 0 ? (
            <div className="notification-page mt-1">
              {groupedMessagesArray?.map((m, index) => {
                const specificDate = moment(m.date).format("YYYY-MM-DD");
                const today = moment().format("YYYY-MM-DD");
                // let createdDate = moment(new Date()).utc().format();
                let yesterday = moment(today).add(-1, "d").format("YYYY-MM-DD");
                const inDays =
                  today == specificDate
                    ? "Today"
                    : yesterday == specificDate
                    ? "Yesterday"
                    : specificDate; // = today.diff(specificDate, 'days');

                return (
                  <div>
                    <h6 className="time-line">{inDays}</h6>
                    <div className="notification-wrap">
                      {m.messages.map((m, index) => {
                        if (
                          m.from == userDetails._id ||
                          m.to == userDetails._id
                        ) {
                          return (
                            <Link
                              to={m.link}
                              className={`notification-box ${
                                m.read_status == "W" ? "new" : ""
                              } ${m.type == "alert" && "import"}`}
                            >
                              <div className="notification-content pt-0">
                                <div className="d-flex align-items-center gap-3 me-3">
                                  <h6>{m.title}</h6>
                                  <p>{m.notify}</p>
                                </div>

                                <div className="d-flex align-items-center gap-3 ms-3">
                                  <p className="notification-time">
                                    {timeDiff(m.createdAt)}
                                  </p>
                                  <Link
                                    className="notification-delete"
                                    title="Delete Notification"
                                    onClick={() => deleteNotify(m._id)}
                                  >
                                    Clear
                                  </Link>
                                </div>
                              </div>
                            </Link>
                          );
                        }
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="no-notification">
              <img src={NoDataImg} alt="" />
              <h4>No Notification Yet</h4>
            </div>
          )}
        </Spin>
      </div>
    </div>
  );
}
