import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import socketIOClient from "socket.io-client";

import ChatBox from "../../components/ChatBox";
import {
  getUnreadMessageCount,
  getContacts,
  getUserContacts,
  getAllContacts,
} from "../../store/messages/messagesSlice";
let data = {
  users: [
    {
      _id: 1,
      name: "Alice",
      img: "https://randomuser.me/api/portraits/women/1.jpg",
      status: "Online",
      messages: [
        {
          type: "received",
          message: "Hey, how are you?",
          senderImg: "https://randomuser.me/api/portraits/women/1.jpg",
        },
        {
          type: "sent",
          message: "I'm good, thanks! You?",
          senderImg: "https://randomuser.me/api/portraits/women/2.jpg",
        },
      ],
    },
    {
      _id: 2,
      name: "Bob",
      img: "https://randomuser.me/api/portraits/men/1.jpg",
      status: "Offline",
      messages: [
        {
          type: "sent",
          message: "Are we still on for tomorrow?",
          senderImg: "https://randomuser.me/api/portraits/women/2.jpg",
        },
        {
          type: "received",
          message: "Yes, see you then!",
          senderImg: "https://randomuser.me/api/portraits/men/1.jpg",
        },
      ],
    },
    {
      _id: 3,
      name: "Bob",
      img: "https://randomuser.me/api/portraits/women/3.jpg",
      status: "Offline",
      messages: [
        {
          type: "sent",
          message: "Are we still on for tomorrow?",
          senderImg: "https://randomuser.me/api/portraits/women/2.jpg",
        },
        {
          type: "received",
          message: "Yes, see you then!",
          senderImg: "https://randomuser.me/api/portraits/men/3.jpg",
        },
      ],
    },
    {
      _id: 4,
      name: "Bob",
      img: "https://randomuser.me/api/portraits/men/1.jpg",
      status: "Offline",
      messages: [
        {
          type: "sent",
          message: "Are we still on for tomorrow?",
          senderImg: "https://randomuser.me/api/portraits/women/2.jpg",
        },
        {
          type: "received",
          message: "Yes, see you then!",
          senderImg: "https://randomuser.me/api/portraits/men/1.jpg",
        },
      ],
    },
    {
      _id: 5,
      name: "Alice",
      img: "https://randomuser.me/api/portraits/women/1.jpg",
      status: "Online",
      messages: [
        {
          type: "received",
          message: "Hey, how are you?",
          senderImg: "https://randomuser.me/api/portraits/women/1.jpg",
        },
        {
          type: "sent",
          message: "I'm good, thanks! You?",
          senderImg: "https://randomuser.me/api/portraits/women/2.jpg",
        },
      ],
    },
    {
      _id: 6,
      name: "Bob",
      img: "https://randomuser.me/api/portraits/men/1.jpg",
      status: "Offline",
      messages: [
        {
          type: "sent",
          message: "Are we still on for tomorrow?",
          senderImg: "https://randomuser.me/api/portraits/women/2.jpg",
        },
        {
          type: "received",
          message: "Yes, see you then!",
          senderImg: "https://randomuser.me/api/portraits/men/1.jpg",
        },
      ],
    },
    {
      _id: 7,
      name: "Bob",
      img: "https://randomuser.me/api/portraits/women/3.jpg",
      status: "Offline",
      messages: [
        {
          type: "sent",
          message: "Are we still on for tomorrow?",
          senderImg: "https://randomuser.me/api/portraits/women/2.jpg",
        },
        {
          type: "received",
          message: "Yes, see you then!",
          senderImg: "https://randomuser.me/api/portraits/men/3.jpg",
        },
      ],
    },
    {
      _id: 8,
      name: "Bob",
      img: "https://randomuser.me/api/portraits/men/1.jpg",
      status: "Offline",
      messages: [
        {
          type: "sent",
          message: "Are we still on for tomorrow?",
          senderImg: "https://randomuser.me/api/portraits/women/2.jpg",
        },
        {
          type: "received",
          message: "Yes, see you then!",
          senderImg: "https://randomuser.me/api/portraits/men/1.jpg",
        },
      ],
    },
  ],
  groups: [
    {
      _id: 11,
      name: "Friends",
      img: "https://randomuser.me/api/portraits/women/1.jpg",
      members: ["Alice", "Bob", "Charlie"],
      messages: [
        {
          type: "received",
          message: "Let's hang out this weekend!",
          senderImg: "https://randomuser.me/api/portraits/women/1.jpg",
        },
        {
          type: "sent",
          message: "Sounds great!",
          senderImg: "https://randomuser.me/api/portraits/women/2.jpg",
        },
      ],
    },
    {
      _id: 12,
      name: "Work",
      members: ["Alice", "Bob"],
      img: "https://randomuser.me/api/portraits/women/1.jpg",
      messages: [
        {
          type: "sent",
          message: "Don't forget the meeting tomorrow!",
          senderImg: "https://randomuser.me/api/portraits/women/2.jpg",
        },
        {
          type: "received",
          message: "I'll be there!",
          senderImg: "https://randomuser.me/api/portraits/women/1.jpg",
        },
      ],
    },
    {
      _id: 13,
      name: "Work",
      members: ["Alice", "Bob"],
      img: "https://randomuser.me/api/portraits/women/1.jpg",
      messages: [
        {
          type: "sent",
          message: "Don't forget the meeting tomorrow!",
          senderImg: "https://randomuser.me/api/portraits/women/2.jpg",
        },
        {
          type: "received",
          message: "I'll be there!",
          senderImg: "https://randomuser.me/api/portraits/women/1.jpg",
        },
      ],
    },
    {
      _id: 14,
      name: "Friends",
      img: "https://randomuser.me/api/portraits/women/1.jpg",
      members: ["Alice", "Bob", "Charlie"],
      messages: [
        {
          type: "received",
          message: "Let's hang out this weekend!",
          senderImg: "https://randomuser.me/api/portraits/women/1.jpg",
        },
        {
          type: "sent",
          message: "Sounds great!",
          senderImg: "https://randomuser.me/api/portraits/women/2.jpg",
        },
      ],
    },
    {
      _id: 15,
      name: "Work",
      members: ["Alice", "Bob"],
      img: "https://randomuser.me/api/portraits/women/1.jpg",
      messages: [
        {
          type: "sent",
          message: "Don't forget the meeting tomorrow!",
          senderImg: "https://randomuser.me/api/portraits/women/2.jpg",
        },
        {
          type: "received",
          message: "I'll be there!",
          senderImg: "https://randomuser.me/api/portraits/women/1.jpg",
        },
      ],
    },
    {
      _id: 16,
      name: "Work",
      members: ["Alice", "Bob"],
      img: "https://randomuser.me/api/portraits/women/1.jpg",
      messages: [
        {
          type: "sent",
          message: "Don't forget the meeting tomorrow!",
          senderImg: "https://randomuser.me/api/portraits/women/2.jpg",
        },
        {
          type: "received",
          message: "I'll be there!",
          senderImg: "https://randomuser.me/api/portraits/women/1.jpg",
        },
      ],
    },
  ],
  activeChat: {
    _id: 1,
    name: "Alice",
    img: "https://randomuser.me/api/portraits/women/1.jpg",
    messages: [
      {
        type: "received",
        message: "Hey, how are you?",
        senderImg: "https://randomuser.me/api/portraits/women/1.jpg",
      },
      {
        type: "sent",
        message: "I'm good, thanks! You?",
        senderImg: "https://randomuser.me/api/portraits/women/2.jpg",
      },
    ],
  },
};
const socket = socketIOClient(
  process.env.REACT_APP_API_URL.split("/api", 1)[0]
);
const App = () => {
  const dispatch = useDispatch();
  let { isLogged, userDetails } = useSelector((state) => state.auth);

  const { unreadMessageCount, contacts, userContacts, allContacts, isLoading } =
    useSelector((state) => state.messages);

  const [activeChat, setActiveChat] = useState({});
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState(data.groups);
  const addNewMessage = (chatId, message) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { type: "sent", message, chatId },
    ]);
  };

  useEffect(() => {
    dispatch(getUnreadMessageCount({}));
    dispatch(getContacts({}));
    dispatch(getUserContacts({}));
    dispatch(getAllContacts({}));
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [contacts, unreadMessageCount, userContacts, allContacts]);

  useEffect(() => {
    configureSocket();
  }, [addNewMessage]);

  const configureSocket = () => {
    socket.on("connection", () => {
      console.log(" Connected backend");
    });
    socket.on("message", (message) => {
      if (message.from === userDetails._id || message.to === userDetails._id) {
        console.log(message, "5q3w65drtfyiu");
        dispatch(getContacts());
        dispatch(getUserContacts({}));
        dispatch(getUnreadMessageCount());
      }
    });
  };

  const handleRefresh = async () => {
    setUsers(contacts?.data);
    console.log(allContacts, "4q53wdfyho");
    setActiveChat(userContacts?.data?.[0]);
  };

  return (
    <>
      {!isLoading ? (
        <div className="App">
          <ChatBox
            users={users}
            groups={groups}
            activeChat={activeChat}
            allContacts={allContacts}
            setChatActive={setActiveChat}
            addNewMessage={addNewMessage}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default App;
