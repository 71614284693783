import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Select, Steps } from "antd";

import { generateRandom, workflowChart } from "../../helper";

import Client from "./components/client";
import IntroducerClient from "./components/introducer-client";
import ParentClient from "./components/parent-client";
import EndUserClient from "./components/end-user-client";
import Entity from "./components/entity";
import Individual from "../../components/IndividualAnalysis";
import CorporateEntity from "../../components/CorporateEntity";

export default function Creation() {
  const MFTOptions = [
    {
      value: "introducer-client",
      label: "Introducer Client",
    },
    {
      value: "end-user",
      label: "End User Client",
    },
    {
      value: "parent-client",
      label: "Parent Client",
    },
    {
      value: "client",
      label: "Client",
    },
    {
      value: "entity",
      label: "Entity",
    },
    {
      value: "individual",
      label: "Individual",
    },
    {
      value: "corporate-entity",
      label: "Corporate Entity",
    },
  ];

  const [selectedMFT, setSelectedMFT] = useState(null);
  const [clientClicked, setClientClicked] = useState(false);
  const [parentClicked, setParentClicked] = useState(false);
  const [endUserClicked, setEndUserClicked] = useState(false);
  const [entityClicked, setEntityClicked] = useState(false);
  const [copEntityClicked, setCopEntityClicked] = useState(false);
  const [individualClicked, setIndividualClicked] = useState(false);
  const [introducerClientClicked, setIntroducerClientClicked] = useState(false);
  const [selectedPage, setselectedPage] = useState("");
  const [refNumber, setRefNumber] = useState("");
  const [items, setItems] = useState([]);
  const handleButtonClick = () => {
    if (selectedPage == "client") {
      setClientClicked(true);
    } else if (selectedPage == "introducer-client") {
      setIntroducerClientClicked(true);
    } else if (selectedPage == "parent-client") {
      setParentClicked(true);
    } else if (selectedPage == "end-user-client") {
      setEndUserClicked(true);
    } else if (selectedPage == "entity") {
      setEntityClicked(true);
    } else if (selectedPage == "corporate-entity") {
      setCopEntityClicked(true);
    } else if (selectedPage == "individual") {
      setIndividualClicked(true);
    }
  };
  const getMFT = (value) => {
    let unix = generateRandom().toUpperCase();

    if (value == "client") {
      setRefNumber(`CL${unix}`);
    } else if (value == "introducer-client") {
      setRefNumber(`IC${unix}`);
    } else if (value == "individual") {
      setRefNumber(`IN${unix}`);
    } else if (value == "entity") {
      setRefNumber(`EN${unix}`);
    } else if (value == "end-user") {
      setRefNumber(`EU${unix}`);
    } else if (value == "parent-client") {
      setRefNumber(`PC${unix}`);
    } else if (value == "corporate-entity") {
      setRefNumber(`CO${unix}`);
    }
    setSelectedMFT(value);
  };
  let listing = [
    {
      id: 1,
      title: "",
      description: "Name Check",
      subTitle: "Name Check",
      link: "/app/incorporation",
    },
    {
      id: 2,
      title: "",
      description: "Incorporation",
      subTitle: "Incorporation",
      link: "/app/relationship-manager",
    },
    {
      id: 3,
      title: "Name Check",
      description: "3",
      subTitle: "3",
      link: "/app/incorporation",
    },
    {
      id: 4,
      title: "Name Check",
      description: "4",
      subTitle: "4",
      link: "/app/incorporation",
    },
    {
      id: 5,
      title: "Name Check",
      description: "5",
      subTitle: "5",
      link: "/app/incorporation",
    },
    {
      id: 6,
      title: "Name Check",
      description: "6",
      subTitle: "6",
      link: "/app/incorporation",
    },
    {
      id: 7,
      title: "Name Check",
      description: "7",
      subTitle: "7",
      link: "/app/incorporation",
    },
    {
      id: 8,
      title: "Name Check",
      description: "8",
      subTitle: "8",
      link: "/app/incorporation",
    },
  ];

  useEffect(() => {
    const newItems = listing.map((item) => ({
      title: item.title,
      description: <a href={item.link}>{item.description}</a>,
      subTitle: item.subTitle,
    }));

    setItems(newItems);
  }, []);
  return (
    <div>
      {/* {workflowChart(items, 1)} */}
      <div className="page-header">
        <h1>Master File Creation</h1>
        {/* <Link to={"/app/pre-incorp-resolution"}> */}
        <div onClick={handleButtonClick} className="primary-btn">
          Create
        </div>
        {/* </Link> */}
      </div>
      <div className="page-content">
        <div className="custom-form">
          {/* <h2 className="primary-title mb-4">Master File Type</h2> */}
          <div className="mb-4">
            <label htmlFor="" className="me-3 mb-1">
              Master File Type
            </label>
            <Select
              className="custom-select mw-300"
              placeholder="Select any"
              onChange={getMFT}
              options={MFTOptions}
            />
          </div>
        </div>
        {selectedMFT == "client" && (
          <Client
            clientClicked={clientClicked}
            refCode={refNumber}
            setselectedPage={setselectedPage}
            setClientClicked={setClientClicked}
          />
        )}
        {selectedMFT == "introducer-client" && (
          <IntroducerClient
            introducerClientClicked={introducerClientClicked}
            refCode={refNumber}
            setselectedPage={setselectedPage}
            setIntroducerClientClicked={setIntroducerClientClicked}
          />
        )}
        {selectedMFT == "parent-client" && (
          <ParentClient
            parentClicked={parentClicked}
            setselectedPage={setselectedPage}
            refCode={refNumber}
            setParentClicked={setParentClicked}
          />
        )}
        {selectedMFT == "end-user" && (
          <EndUserClient
            endUserClicked={endUserClicked}
            setselectedPage={setselectedPage}
            refCode={refNumber}
            setEndUserClicked={setEndUserClicked}
          />
        )}
        {selectedMFT == "entity" && (
          <Entity
            entityClicked={entityClicked}
            setselectedPage={setselectedPage}
            refCode={refNumber}
            setEntityClicked={setEntityClicked}
          />
        )}
        {selectedMFT == "individual" && (
          <div>
            <h2 className="sub-title mb-4">Master File Creation: Individual</h2>
            <Individual
              fileCreation={true}
              individualClicked={individualClicked}
              setselectedPage={setselectedPage}
              refCode={refNumber}
              setIndividualClicked={setIndividualClicked}
            />
          </div>
        )}
        {selectedMFT == "corporate-entity" && (
          <div>
            <h2 className="sub-title mb-4">
              Master File Creation: Corporate Entity
            </h2>
            <CorporateEntity
              fileCreation={true}
              copEntityClicked={copEntityClicked}
              setselectedPage={setselectedPage}
              refCode={refNumber}
              setCopEntityClicked={setCopEntityClicked}
            />
          </div>
        )}
      </div>
    </div>
  );
}
