import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Select, Input, DatePicker } from "antd";
import { useSelector } from "react-redux";

import Jurisdiction from "../../components/Jurisdiction";
import FormZ from "../../components/FormZ";

import { sendNotify, getOptions } from "../../helper";

const { Option } = Select;

export default function Crypto() {
  const { data, isLoading } = useSelector((state) => state.country);

  const cryptoFormRef = useRef();

  const [transactionType, setTransactionType] = useState("usd");
  const [transactionSymbol, setTransactionSymbol] = useState("$");

  const getTransactionChanges = (value) => {
    let symbol = "";
    setTransactionType(value);
    if (value == "usd") {
      symbol = "$";
    }
    if (value == "inr") {
      symbol = "₹";
    }
    setTransactionSymbol(symbol);
  };

  const transactionSelect = (
    <Select defaultValue="usd" onChange={getTransactionChanges}>
      <Option value="usd">USD</Option>
      <Option value="inr">INR</Option>
    </Select>
  );

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  let cryptoFormSchema = [
    {
      name: "cryptoType",
      value: "",
      type: "select",
      label: "Crypto Type",
      placeholder: "Select type",
      options: [],
    },
    {
      name: "origin",
      value: "",
      type: "text",
      label: "Origin",
      placeholder: "Enter origin",
    },
    {
      name: "jurisdiction",
      value: "",
      type: "select",
      label: "Jurisdiction",
      placeholder: "Select jurisdiction",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
    },
    {
      name: "customerRisk",
      value: "",
      type: "select",
      label: "Customer Risk Assessment",
      placeholder: "Select customer risk",
      options: [
        {
          value: "low",
          label: "Low",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "high",
          label: "High",
        },
      ],
    },
    {
      customElement: (
        <>
          <label htmlFor="" className="mb-1">
            Transaction Threshold
          </label>
          <Input
            classNames={"custom-input"}
            addonBefore={transactionSymbol}
            suffix={<i className="fal fa-question-circle"></i>}
            addonAfter={transactionSelect}
            defaultValue="1,000.00"
          />
        </>
      ),
    },
    {
      name: "FinCEN Registration",
      value: "",
      type: "text",
      label: "FinCEN Registration",
      placeholder: "Enter FinCEN Registration",
    },
    {
      name: "cryptoKYC",
      value: "",
      type: "text",
      label: "Crypto KYC",
      placeholder: "Enter crypto KYC",
    },
    {
      name: "ssn",
      value: "",
      type: "text",
      label: "SSN",
      placeholder: "Enter SSN",
    },
    {
      name: "FinCEN Number",
      value: "",
      type: "text",
      label: "FinCEN Number",
      placeholder: "Enter FinCEN Number",
    },

    {
      name: "Buyer List",
      value: "",
      type: "select",
      label: "Buyer List",
      placeholder: "Select Buyer List",
      options: [],
    },
    {
      name: "Seller List",
      value: "",
      type: "select",
      label: "Seller List",
      placeholder: "Select Seller List",
      options: [],
    },
    {
      name: "OverallRiskLevel",
      value: "",
      type: "select",
      label: "Overall Risk Level",
      placeholder: "Select overall risk level",
      options: [
        {
          value: "low",
          label: "Low",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "high",
          label: "High",
        },
      ],
    },
    {
      name: "Legal Name",
      value: "",
      type: "text",
      label: "Legal Name",
      placeholder: "Enter Legal Name",
    },
    {
      name: "dateOfBirth",
      value: "",
      type: "date",
      label: "Date of Birth",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "NICNumber",
      value: "",
      type: "number",
      label: "NIC Number",
      placeholder: "Enter NIC Number",
    },
    {
      name: "originator",
      value: "",
      type: "text",
      label: "Originator",
      placeholder: "Enter Originator",
    },
    {
      name: "Beneficiary",
      value: "",
      type: "text",
      label: "Beneficiary",
      placeholder: "Enter Beneficiary",
    },
    {
      name: "address",
      value: "",
      type: "text",
      label: "Address",
      placeholder: "Enter Address",
    },
  ];

  return (
    <div>
      <div className="page-header">
        <h1 className="page-title mb-3 mv-md-0">Crypto TM</h1>
        <Link to={"/app/incorporation-pack"} className="primary-btn">
          Complete Assessment
        </Link>
      </div>
      <div className="page-content">
        <div className="custom-form">
          <h4 className="primary-title mb-4">Director</h4>
          <FormZ
            ref={cryptoFormRef}
            formSchema={cryptoFormSchema}
            formClass="row col-md-9 mb-4 mb-md-5 gy-3"
            childClass="col-md-4 mb-0"
            labelClass="mb-1"
            inputClass="w-100"
          />
          <div className="d-flex gap-4 my-4 my-md-5 flex-column flex-md-row">
            <button className="outline-btn w-sm-100 justify-content-center">
              Report Suspicious Activity
            </button>
            <button className="primary-btn w-sm-100 justify-content-center">
              Complete Onboarding
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
