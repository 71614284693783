import React, { useState, useEffect, useRef } from "react";
import { Select, Modal, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import FormZ from "../components/FormZ";
import Authenticator from "../components/authenticator";

import {
  getRandomColor,
  convertQueryParams,
  fetchApi,
  sendNotify,
} from "../helper";

export default function ProfilePicture() {
  const dispatch = useDispatch();
  const profileFormRef = useRef();
  const resetFormRef = useRef();
  let { isLogged, userDetails } = useSelector((state) => state.auth);
  const [customError, setCustomError] = useState({});
  const [formData, setFormData] = useState({});
  const [passValid, setPassValid] = useState({});
  const [profileUrl, setProfileUrl] = useState("");
  const [profile, setProfile] = useState("");
  const [viewAuthmodal, setViewAuthModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [logoColor, setLogoColor] = useState("");
  const [logoText, setlogoText] = useState("");
  const [authValue, setAuthValue] = useState({});
  useEffect(() => {
    getUserData();
  }, [userDetails]);

  const getUserData = async () => {
    let logoColor = getRandomColor();
    let params = { id: userDetails._id };
    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/admin/get?${query}`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res?.data?.rows[0];
    });
    let formData = {
      email: data?.email,
      username: data?.userName,
      phoneNumber: data?.phoneNumber,
      firstName: data?.firstName,
      lastName: data?.lastName,
      profileDp: data?.profileDp,
      f2aEnable: data?.f2aEnable,
    };
    let logo =
      data?.firstName[0].toUpperCase() + data?.lastName[0].toUpperCase();
    setlogoText(logo);

    setLogoColor(data?.logoColor ? data?.logoColor : logoColor);

    const res = await fetchLiveUrl(data?.profileDp);

    setProfileUrl(data?.profileDp ? res.data.base64Url : "");
    setUserData(formData);
  };
  const fetchLiveUrl = async (url) => {
    if (url) {
      console.log(url, "hnfghjdghjdt");
      try {
        const data = { url: url };
        const obj = {
          method: "POST",
          url: `/file/get`,
          data: data,
        };
        return await fetchApi(obj);
      } catch (error) {
        throw error;
      }
    }
  };
  let profileFormSchema = [
    {
      name: "email",
      value: "",
      type: "email",
      label: "Email",
      placeholder: "Enter email address",
      disabled: true,
      customClass: "col-md-10",
    },
    {
      name: "username",
      value: "",
      type: "text",
      label: "Username",
      placeholder: "Enter Username",
      disabled: true,
    },
    {
      name: "phoneNumber",
      value: "",
      type: "type",
      label: "Phone Number",
      placeholder: "Enter phone number",
      //   customClass: "hide-arrows",
      disabled: true,
    },
    {
      name: "firstName",
      value: "",
      type: "text",
      label: "First Name",
      placeholder: "Enter first name",
      required: true,
    },
    {
      name: "lastName",
      value: "",
      type: "text",
      label: "Last Name",
      placeholder: "Enter last name",
      required: true,
    },
  ];

  let resetFormSchema = [
    {
      name: "oldPassword",
      value: "",
      type: "password",
      label: "Current Password",
      placeholder: "Enter password",
      required: true,
      autoComplete: false,
      validation: true,
    },
    {
      customClass: "col-md-7",
      customElement: <div>&nbsp;</div>,
    },
    {
      name: "password",
      value: "",
      type: "password",
      label: "New Password",
      placeholder: "Enter password",
      required: true,
      autoComplete: false,
      validation: true,
    },
    {
      customClass: "col-md-12",
      customElement: (
        <ul
          className={`pass-validation ${formData["password"] ? "pass-in" : ""}`}
        >
          <li className={passValid["length"] ? "valid" : "not-valid"}>
            <i
              className={`fas ${
                passValid["length"] ? "fa-check-circle" : "fa-times-circle"
              }`}
            ></i>
            Password is more than 8 characters
          </li>
          <li className={passValid["upper"] ? "valid" : "not-valid"}>
            <i
              className={`fas ${
                passValid["upper"] ? "fa-check-circle" : "fa-times-circle"
              }`}
            ></i>
            Upper and lowercase letters
          </li>
          <li className={passValid["number"] ? "valid" : "not-valid"}>
            <i
              className={`fas ${
                passValid["number"] ? "fa-check-circle" : "fa-times-circle"
              }`}
            ></i>
            At least one number
          </li>
          <li className={passValid["special"] ? "valid" : "not-valid"}>
            <i
              className={`fas ${
                passValid["special"] ? "fa-check-circle" : "fa-times-circle"
              }`}
            ></i>
            At least one special character
          </li>
        </ul>
      ),
    },
    {
      name: "confirmPassword",
      value: "",
      type: "password",
      label: "Confirm Password",
      validation: false,
      placeholder: "Enter confirm password",
      required: true,
    },
  ];

  const getFormData = (data, field) => {
    let { password } = data;
    let valid = { ...passValid };
    let error = { ...customError };

    valid["upper"] = /[A-Z]/?.test(password);
    valid["number"] = /[0-9]/?.test(password);
    valid["special"] = /[(@!#\$%\^\&*\)\(+=._-]/?.test(password);
    valid["length"] = password?.length > 8;

    setCustomError(error);
    setPassValid(valid);
    setFormData(data);
  };

  const getPhoto = (e) => {
    let file = e.target.files[0];
    if (file) {
      let url = URL.createObjectURL(file);

      var bodyFormData = new FormData();
      bodyFormData.append("file", file);
      bodyFormData.append("folder", "test");
      let payload = {
        method: "POST",
        url: `/file`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      fetchApi(payload)
        .then((res) => {
          setProfileUrl(res.data.url);
        })
        .catch((error) => {
          sendNotify("error", error?.message);
        });
      // setProfileUrl(url);
    }
  };

  const authModalOnClick = async () => {
    setViewAuthModal(true);
  };

  const handleCancel = async () => {
    setAuthValue({});
    setViewAuthModal(false);
  };
  const handleOk = async () => {
    let obj = {};
    obj.f2aEnable = authValue.f2aEnable;
    obj.qrCode = authValue.qrCode;
    obj.secretKey = authValue.secretKey;

    const payload = {
      method: "POST",
      url: `/admin/updateProfile`,
      data: obj,
    };
    fetchApi(payload)
      .then((res) => {
        if (res.status == 200) {
          sendNotify("success", res?.message);
        } else {
          sendNotify("error", "Try again");
        }
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
    setAuthValue({});
    getUserData();
    setViewAuthModal(false);
  };
  const disableOnClick = async () => {
    let obj = {};
    obj.f2aEnable = false;
    obj.qrCode = "";
    obj.secretKey = "";

    const payload = {
      method: "POST",
      url: `/admin/updateProfile`,
      data: obj,
    };
    fetchApi(payload)
      .then((res) => {
        if (res.status == 200) {
          sendNotify("success", res?.message);
        } else {
          sendNotify("error", "Try again");
        }
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
    getUserData();
    setAuthValue({});
    setViewAuthModal(false);
  };
  const saveData = async (value) => {
    let profileObj = profileFormRef.current.getPayload();
    let resetObj = resetFormRef.current.getPayload();

    let obj = {};
    if (value == "profile") {
      obj.firstName = profileObj.firstName;
      obj.lastName = profileObj.lastName;
      obj.email = profileObj.email;
      obj.username = profileObj.username;
      obj.logoColor = logoColor;
      obj.profileDp = profileUrl;
    } else {
      obj.email = profileObj.email;
      obj.oldPassword = resetObj.oldPassword;
      obj.password = resetObj.password;
    }

    const payload = {
      method: "POST",
      url:
        value == "profile" ? `/admin/updateProfile` : `/admin/changePassword`,
      data: obj,
    };

    fetchApi(payload)
      .then((res) => {
        if (res.status == 200) {
          sendNotify("success", res?.message);
        } else {
          sendNotify("error", "Password is incorrect try again");
        }
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
    getUserData();
  };

  return (
    <div>
      <div className="page-header">
        <div>
          <h1 className="mb-1">Profile Settings</h1>
          <p>Manage your NorthLark Galactic profile.</p>
        </div>
      </div>
      <div className="page-content">
        <div className="row">
          <div className="custom-form col-md-8">
            <div className="mb-4">
              <label htmlFor="" className="mb-4">
                Profile Picture
              </label>
              <label className="profile-upload" htmlFor="profile">
                {profileUrl ? (
                  <img
                    className="profile-image"
                    src={`data:image/png;base64, ${profileUrl}`}
                    alt=""
                  />
                ) : (
                  <h4
                    className="temp-profile"
                    style={{ backgroundColor: logoColor }}
                  >
                    {logoText}
                  </h4>
                )}
                <input
                  type="file"
                  name="profile"
                  id="profile"
                  hidden
                  onChange={getPhoto}
                />
                <div className="hover-icon">
                  <i className="fal fa-camera-alt"></i>
                </div>
              </label>
            </div>
            <FormZ
              formClass="row gy-3 mb-4"
              childClass="col-md-5 mb-0"
              labelClass="mb-1"
              inputClass="w-100"
              formSchema={profileFormSchema}
              formData={userData}
              ref={profileFormRef}
            />
            <button
              className="primary-btn mt-4"
              onClick={() => saveData("profile")}
            >
              Save
            </button>
            <hr />
            <h6 className="form-title mb-1">Reset Password</h6>
            <p className="description">Change your password.</p>
            <FormZ
              formClass="row gy-3 mt-2 mb-4"
              childClass="col-md-5 mb-0"
              formSchema={resetFormSchema}
              ref={resetFormRef}
              formData={formData}
              onChange={getFormData}
              customValidation={customError}
              labelClass="mb-1"
              inputClass="w-100"
            />
            <button
              className="primary-btn mt-4"
              onClick={() => saveData("pass")}
            >
              Save
            </button>
            <hr />
            <h6 className="form-title mb-1 mt-4">2-Factor Authentication</h6>
            <p className="description">Setup 2FA.</p>

            {userData.f2aEnable ? (
              <button className="primary-btn mt-4" onClick={disableOnClick}>
                Disable
              </button>
            ) : (
              <button className="primary-btn mt-4" onClick={authModalOnClick}>
                Enable
              </button>
            )}
          </div>
        </div>
      </div>
      <Modal
        title={"2-Factor Authentication"}
        className="custom-modal"
        open={viewAuthmodal}
        width={500}
        onOk={handleOk}
        onCancel={handleCancel}
        //okButtonProps={{ disabled: !authValue?.verification }}
      >
        <div>
          <Authenticator
            setAuthValue={setAuthValue}
            authValue={authValue}
            userId={userDetails._id}
          />
        </div>
      </Modal>
    </div>
  );
}
