import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Modal, Checkbox } from "antd";

import FormZ from "../components/FormZ";
import TableSortArrows from "../components/tableSortArrows";

import { Each, copyThat, sendNotify, fetchApi } from "../helper";

import { themeColors, toggleThemeColor } from "../store/theme/themeSlice";

export default function Account() {
  const dispatch = useDispatch();

  const { userDetails } = useSelector((state) => state.auth);
  const { themeColor } = useSelector((state) => state.theme);

  const companyFormRef = useRef();
  const clientFormRef = useRef();
  const [formData, setFormData] = useState([]);
  let companyFormSchema = [
    {
      name: "companyName",
      value: "",
      type: "text",
      label: "Company Name",
      placeholder: "Enter Company name",
      required: true,
    },
  ];

  let clientFormSchema = [
    {
      name: "clientCode",
      value: "",
      type: "text",
      label: "Masterfile Code",
      placeholder: "Enter client code",
      required: true,
    },
    {
      name: "clientName",
      value: "",
      type: "text",
      label: "Masterfile Name",
      placeholder: "Enter slug",
      required: true,
    },
  ];

  const baseUrl = `${new URL(window.location.href).host}/`; //"northlark.galactic/";

  const [clientModal, setClientModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedColor, setSelectedColor] = useState(0);
  const [slugUrl, setSlugUrl] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    let role = userDetails?.role;
    if (role == "super-admin" || role == "admin") {
      setIsAdmin(true);
    }
    if (userDetails?.otherDetails) {
      let data = userDetails?.otherDetails;
      setFormData({ companyName: data.companyName });
      setSlugUrl(data.slug);
      setSelectedColor(data.theme);
    }
  }, [userDetails]);

  useEffect(() => {
    let index = themeColors.findIndex((tc) => tc == themeColor);
    setSelectedColor(index);
  }, [themeColor]);

  const handleOk = () => {
    setClientModal(false);
  };
  const handleCancel = () => {
    setClientModal(false);
  };

  const clientColumn = [
    {
      title: "Masterfile Code",
      dataIndex: "clientCode",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Name",
      dataIndex: "clientName",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  const clientData = [
    {
      clientCode: "0001",
      clientName: "Sample Client",
    },
    {
      clientCode: "0002",
      clientName: "Sample Client",
    },
    {
      clientCode: "0003",
      clientName: "Sample Client",
    },
    {
      clientCode: "0004",
      clientName: "Sample Client",
    },
  ];

  const changeThemeColor = (index) => {
    setSelectedColor(index);
    dispatch(toggleThemeColor(index));
  };

  const copyUrl = () => {
    copyThat("https://" + baseUrl + slugUrl);
    setIsCopied(true);
  };

  const onChangeHandler = (setIdentifierState, event) => {
    setIdentifierState(event.target.value);
  };

  const getOnUpdate = async (value) => {
    setFormData(value);
  };
  const saveData = async () => {
    let obj = companyFormRef.current.getPayload();
    obj.slug = slugUrl;
    obj.theme = themeColor.color;

    const payload = {
      method: "POST",
      url: `/admin/updateTheme`,
      data: obj,
    };

    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message);
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };
  return (
    <div>
      <div className="page-header w-100">
        <div>
          <h1 className="mb-1">Account</h1>
          <p>Manage your NorthLark Galactic account.</p>
        </div>
      </div>
      <div className="page-content">
        <div className="row custom-form">
          <div className="col-md-8">
            <div className="mb-4">
              <FormZ
                formClass="mb-4"
                childClass="col-md-8"
                ref={companyFormRef}
                onChange={getOnUpdate}
                formData={formData}
                formSchema={companyFormSchema}
                labelClass="mb-1"
                inputClass="w-100"
              />
              <div className="col-md-8">
                <label htmlFor="" className="custom-label req">
                  Slug
                </label>
                <div className="input-after-button">
                  <div
                    className="input-placeholder w-100"
                    data-placeholder={baseUrl}
                  >
                    <input
                      type="text"
                      placeholder="Enter slug"
                      value={slugUrl}
                      onChange={onChangeHandler.bind(null, setSlugUrl)}
                    />
                  </div>
                  <button className="primary-btn" onClick={copyUrl}>
                    {isCopied ? (
                      <>
                        <i className="fal fa-check-circle"></i> Copied&nbsp;
                      </>
                    ) : (
                      <>
                        <i className="fal fa-paste"></i> Copy&nbsp;URL
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>

            <hr />
            {!isAdmin ? (
              <div className="mt-4">
                <h6 className="form-title mb-1">Clients</h6>
                <p className="description">
                  Add clients to your NorthLark Galactic account.
                </p>
                <Table
                  className="custom-table my-3"
                  columns={clientColumn}
                  dataSource={clientData}
                  pagination={false}
                />
                <button
                  className="primary-btn"
                  onClick={() => setClientModal(true)}
                >
                  <i className="fal fa-plus"></i>
                  Add Client
                </button>
              </div>
            ) : (
              <div className="mt-4">
                <h6 className="form-title mb-3">System Theme</h6>
                <div className="system-theme">
                  <Each
                    array={themeColors}
                    render={(item, index) => (
                      <span
                        className={`${item?.color} ${
                          index == selectedColor ? "selected" : ""
                        }`}
                        onClick={() => changeThemeColor(index)}
                      ></span>
                    )}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {isAdmin ? (
          <div>
            {" "}
            &nbsp; &nbsp;{" "}
            <button className="mr-2 primary-btn" onClick={saveData}>
              Save
            </button>
          </div>
        ) : null}
      </div>

      <Modal
        title={"Add Client"}
        open={clientModal}
        className="custom-modal-2"
        onOk={handleOk}
        okText="Add"
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <FormZ
          ref={clientFormRef}
          formSchema={clientFormSchema}
          labelClass="mb-1"
          inputClass="w-100"
        />
      </Modal>
    </div>
  );
}
