import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import socketIOClient from "socket.io-client";
import { Popover } from "antd";

import { getNewNotifications } from "../store/newNotifications/newNotificationsSlice";
import { chatTime, eachChatTextTime, timeDiff } from "../helper";
import Logo from "../assets/img/logo-icon.png";
import NoDataImg from "../assets/img/no-notifications.jpg";
import AvatarImg from "../assets/img/notify-avatar.png";

import TabZ from "./TabZ";

const socket = socketIOClient(
  process.env.REACT_APP_API_URL.split("/api", 1)[0]
);

export default function NotificationBtn() {
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector((state) => state.newNotification);
  let { isLogged, userDetails } = useSelector((state) => state.auth);
  const [notificationArray, setNotificationArray] = useState([]);

  useEffect(() => {
    dispatch(getNewNotifications({ read: "W" }));
    configureSocket();
  }, []);

  useEffect(() => {
    setNotificationArray(data?.rows);
  }, [data]);

  const configureSocket = () => {
    socket.on("connection", () => {
      console.log(" Connected backend");
    });
    socket.on("message", (message) => {
      console.log(message, "5q3w65drtfyiu");
      newMessage(message);
      // if (message.chat_to === employer.employerId) {
      //     getContacts();
      //     dispatch(getUnreadMessageCount());
      // }
    });
    socket.on("notification", (notification) => {
      if (notification.userId === userDetails._id) {
        dispatch(getNewNotifications({ read: "W" }));
        checkUserNotification(notification);
      }
    });

    socket.on("updateTheme", (updateTheme) => {
      let id = updateTheme.id.map((a) => a).includes(userDetails._id);
      if (id) {
        checkUserupdatedTheme(updateTheme.body);
      }
    });
    const checkUserupdatedTheme = (notification) => {
      const notifyContent = {
        title: notification.companyName,
        body: "We are excited to announce the release of our new system theme. Enjoy a fresh look and enhanced user experience.",
        icon: Logo,
        link: "/app/account",
      };
      pushNotification(notifyContent);
      //  };
    };

    const newMessage = (message) => {
      const notifyContent = {
        title: "Notification from Galactic!",
        body: `You got a new message from ${message.name}`,
        icon: Logo,
        link: `app/chat-box`,
      };
      console.log(notifyContent, "4edrgyhj");
      pushNotification(notifyContent);
    };
    const checkUserNotification = (notification) => {
      const notifyContent = {
        title: "Notification from Galactic!",
        body: "You got a new message from company",
        icon: Logo,
        link: notification.link,
      };
      pushNotification(notifyContent);
    };
    const pushNotification = (notifyContent) => {
      console.log(notifyContent, "aSDfghjkl;;fdg");
      if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
      } else if (Notification.permission === "granted") {
        var messageNotify = new Notification(notifyContent.title, {
          body: notifyContent.body,
          icon: notifyContent.icon,
        });
        messageNotify.onclick = () => window.open(notifyContent.link);
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            var messageNotify = new Notification(notifyContent.title, {
              body: notifyContent.body,
              icon: notifyContent.icon,
              color: "#2095f2",
              sound: "default",
            });
            messageNotify.onclick = () => window.open(notifyContent.link);
          }
        });
      }
    };
    return () => {
      disconnectSocket();
    };
  };
  const disconnectSocket = () => {
    socket.off("connection");
    socket.off("message");
    socket.off("notification");
    socket.off("updateTheme");
  };
  const text = (
    <div className="notification-header">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-2">
          <h6>Notifications</h6>
          <span className="count">{data?.count}</span>
        </div>
        <button className="close-btn">
          <i className="fal fa-times"></i>
        </button>
      </div>
    </div>
  );

  const labels = [
    {
      key: 1,
      label: "NEW",
    },
    {
      key: 2,
      label: "CLEARED",
    },
  ];

  const onTabChange = (key) => {
    console.log(key);
  };

  const content = (
    <div className="notification-content">
      <div className="d-flex justify-content-between align-items-center mb-2 px-3">
        <TabZ
          labels={labels}
          defaultActive="1"
          customClass="sm"
          onChange={onTabChange}
        />
        <button className="secondary-btn py-1">Clear All</button>
      </div>
      <div className="notification-wrap">
        {notificationArray && notificationArray.length > 0 ? (
          notificationArray.map((m, index) => {
            if (index < 5) {
              return (
                <Link
                  to={"/app/notifications"}
                  className="notification-link"
                  key={index}
                >
                  <div
                    className={`notification-item unread ${
                      m.type == "alert" && "import"
                    }`}
                  >
                    <img
                      className="notification-avatar"
                      src={AvatarImg}
                      alt=""
                    />
                    <div className="notification-text">
                      <h6>{m.title}</h6>
                      <p>
                        You have received feedback from the registry for 2 new
                        incorporation requests
                      </p>
                    </div>
                    <p className="notification-time my-2">
                      12:48PM
                      {/* {timeDiff(m.createdAt, "hh:mm a - DD/MM/YYYY")} */}
                    </p>
                  </div>
                </Link>
              );
            }
          })
        ) : (
          <div className="no-notification">
            <img src={NoDataImg} alt="" />
            <h4>No Notification Yet</h4>
          </div>
        )}
      </div>
      <div className="notification-footer">
        <Link to={"/app/notifications"} className="primary-btn ms-auto py-2">
          View All
        </Link>
      </div>
    </div>
  );

  return (
    <Popover className="notification-popover" title={text} content={content}>
      <button className="notification-btn">
        <i className="fal fa-bell"></i>
        {data?.count > 0 && (
          <span className="notification-count">{data?.count}</span>
        )}
      </button>
    </Popover>
  );
}
