import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Spin } from "antd";
import UploadZ from "../components/UploadZ";
import { sendNotify, getQuery, fetchApi } from "../helper";

const { confirm } = Modal;

export default function CertificateOfGoodStanding() {
  const query = getQuery();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [completedStep, setCompletedStep] = useState(0);
  const [uploadError, setUploadError] = useState(false);
  const [certificate, setCertificate] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  let local = typeof window !== "undefined" ? localStorage : null;
  useEffect(() => {
    const storedToken = localStorage.getItem("accessToken");
    if (storedToken) setAccessToken(storedToken);
    handleRefresh();
  }, []);

  const handleRefresh = () => {
    setLoading(true);
    const payload = {
      method: "get",
      url: `/serviceRequest?id=${query?.id}`,
    };

    fetchApi(payload)
      .then((res) => {
        setData(res?.data?.rows?.[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
        sendNotify("error", "Failed to load data");
      });
  };

  const storeDocument = (file) => {
    try {
      console.log(file);
      if (file) {
        setUploadError(false);
        setCertificate(file);
        sendNotify("success", "Certificate uploaded successfully");
      } else {
        setUploadError(true);
        sendNotify("error", "File upload failed. Please try again.");
      }
    } catch (e) {
      console.log(e, "sdfxcnbm,");
    }
  };
  let subject = "test";
  let text = "test-";
  const sendEmail = async () => {
    console.log(certificate, "esurdyti");
    if (subject && accessToken && text) {
      let files = [certificate.file];
      const formData = new FormData();
      formData.append("to", data?.entityArray?.email);
      formData.append("subject", subject);
      formData.append("body", text);
      formData.append("accessToken", accessToken);
      files.forEach((file) => {
        formData.append("attachments", file);
      });

      const payload = {
        method: "POST",
        url: `/email/send-email`,
        data: formData,
        headers: {
          authorizationout: local.accessToken,
          "Content-Type": "multipart/form-data",
        },
      };

      try {
        const res = await fetchApi(payload).then((response) => {
          if (response.error.response.status == 500) {
            sendNotify(
              "error",
              response.error.response.data || "Failed to send email"
            );
          } else {
            sendNotify("success", response);
          }
        });
      } catch (error) {
        sendNotify("error", error?.message || "Failed to send email");
      }
    } else {
      const errors = [];

      if (!subject) errors.push("Subject is required");
      if (!accessToken) errors.push("Your authentication token is expired");
      if (!text) errors.push("Email body is required");

      if (errors.length > 0) {
        const errorMessage = errors.join(", ");
        sendNotify("error", errorMessage);
      }
    }
  };

  const sendToClient = () => {
    console.log(certificate, "drfhjk");
    if (certificate) {
      setUploadError(false);
      confirm({
        title: <h5>Send to Client</h5>,
        icon: (
          <div className="modal-icon">
            <i className="fas fa-question-circle color-primary"></i>
          </div>
        ),
        content: (
          <p>Are you sure you want to send this document to the client?</p>
        ),
        open: false,
        className: "custom-confirm",
        centered: true,
        closable: true,
        okText: "Send",
        onOk() {
          sendEmail();
          sendNotify("success", "Document successfully sent to client");
        },
        onCancel() {
          console.log("Cancelled sending document");
        },
      });
    } else {
      setUploadError(true);
      sendNotify("error", "Upload Certificate of Good Standing");
    }
  };

  const requestCOGSonClick = async () => {
    setCompletedStep(1);
  };

  const mailboxOnClick = async () => {
    setCompletedStep(2);
  };

  return (
    <div>
      <Spin spinning={loading}>
        <div className="page-header">
          <Link to={"/app/active-requests"}>
            <h1 className="d-flex align-items-center gap-2">
              <i className="fal fa-long-arrow-left"></i> Certificate of Good
              Standing
            </h1>
          </Link>
          <Link
            to={`/app/entity-overview/list?id=${data?.entityArray?._id}`}
            className="outline-btn"
          >
            Refer Entity Overview
          </Link>
        </div>
        <div className="page-content py-0">
          <div className="task-row row align-items-center py-5">
            <div className="col-md-4">
              <h6>1. Request a Certificate of Good Standing</h6>
            </div>
            <div className="col-md-4">
              <div className="d-flex gap-2">
                <button
                  className="primary-btn"
                  disabled={completedStep >= 1}
                  onClick={requestCOGSonClick}
                >
                  Request COGS
                </button>
                {completedStep >= 1 && (
                  <div className="completed-icon">
                    <i className="fas fa-check-circle"></i>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="task-row row align-items-center py-5">
            <div className="col-md-4">
              <h6>2. Check your mailbox for a response</h6>
            </div>
            <div className="col-md-4">
              {/* <Link to={`/app/mail?mailId=${data?.entityArray?.email}`}> */}
              <button
                className="primary-btn"
                disabled={completedStep === 0}
                onClick={mailboxOnClick}
              >
                Check Mailbox
              </button>
              {/* </Link> */}
            </div>
          </div>
          <div className="task-row row py-5">
            <div className="col-md-4">
              <h6>3. Upload Certificate of Good Standing</h6>
              <div className="w-75 mt-4 ms-4">
                <UploadZ
                  customError={uploadError}
                  onStoreFile={storeDocument}
                />
              </div>
            </div>
            <div className="col-md-4">
              <button
                className="primary-btn"
                disabled={completedStep < 2}
                onClick={sendToClient}
              >
                Send to Client
              </button>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}
