import React, { useState, useEffect, useRef } from "react";
import { Table, Tag, Select, Modal, DatePicker, Input } from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import TagZ from "../components/TagZ";
import TableSortArrows from "../components/tableSortArrows";

import { customPagination, downloadAsCSV } from "../helper";

import { getServiceInvoicing } from "../store/serviceInvoicing/serviceInvoicingSlice";
import { getAnnualInvoicing } from "../store/annualInvoicing/annualInvoicingSlice";

export default function ServiceInvoice() {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const serviceInvoicing = useSelector((state) => state.serviceInvoicing);
  const annualInvoicing = useSelector((state) => state.annualInvoicing);

  const [query, setQuery] = useState({
    service: {
      _limit: 10,
      _start: 0,
    },
    annual: {
      _limit: 10,
      _start: 0,
    },
  });
  const [table, setTable] = useState({
    service: {
      data: [],
      total: 0,
    },
    annual: {
      data: [],
      total: 0,
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div className="custom-form p-3" onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          className="mb-2 w-100"
        />
        <div className="d-flex gap-2 align-items-center">
          <button
            className="primary-btn w-100 justify-content-center py-2"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          >
            Search
          </button>
          <button
            onClick={() => clearFilters && handleReset(clearFilters)}
            className="outline-btn py-2"
          >
            Reset
          </button>
          {/* <button
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                        className='outline-btn'
                    >
                        Filter
                    </button> */}
          <button
            onClick={() => {
              close();
            }}
            className="outline-btn red-btn py-2"
          >
            Close
          </button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => <i className="far fa-search"></i>,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const serviceColumns = [
    {
      title: "Entity Name",
      dataIndex: "entityName",
      sorter: (a, b) => a.entityName - b.entityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      ...getColumnSearchProps("entityName"),
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      sorter: (a, b) => a.entityCode - b.entityCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      ...getColumnSearchProps("entityCode"),
    },
    {
      title: "Entity Status",
      dataIndex: "entityStatus",
      sorter: (a, b) => a.entityStatus - b.entityStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={status == "Y" ? "approved" : "rejected"}
          statusText={status == "Y" ? "Active" : "Inactive"}
        />
      ),
    },
    {
      title: "Incorp Number",
      dataIndex: "incorpNumber",
      sorter: (a, b) => a.incorpNumber - b.incorpNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorporation Date",
      dataIndex: "incorpDate",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Service Req Date",
      dataIndex: "serviceReqDate",
      sorter: (a, b) => a.serviceReqDate - b.serviceReqDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Service Type",
      dataIndex: "serviceType",
      sorter: (a, b) => a.serviceType - b.serviceType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Service  Code",
      dataIndex: "serviceCode",
      sorter: (a, b) => a.serviceCode - b.serviceCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Value $",
      dataIndex: "serviceFee",
      sorter: (a, b) => a.serviceFee - b.serviceFee,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Bank reference",
      dataIndex: "bankRef",
      sorter: (a, b) => a.bankRef - b.bankRef,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Billing Category",
      dataIndex: "billingCategory",
      sorter: (a, b) => a.billingCategory - b.billingCategory,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Invoice number",
      dataIndex: "invoiceNumber",
      sorter: (a, b) => a.invoiceNumber - b.invoiceNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      sorter: (a, b) => a.dueDate - b.dueDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Remittance Date",
      dataIndex: "remittanceDate",
      sorter: (a, b) => a.remittanceDate - b.remittanceDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Receipt Status",
      dataIndex: "receiptStatus",
      sorter: (a, b) => a.receiptStatus - b.receiptStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { receiptStatus }) => (
        <TagZ
          status={receiptStatus == "Y" ? "approved" : "in-progress"}
          statusText={receiptStatus == "Y" ? "Settled" : "Pending"}
        />
      ),
    },
    {
      title: "Service Status",
      dataIndex: "serviceStatus",
      sorter: (a, b) => a.serviceStatus - b.serviceStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { serviceStatus }) => (
        <TagZ
          status={serviceStatus == "Y" ? "approved" : "in-progress"}
          statusText={serviceStatus == "Y" ? "Completed" : "Pending"}
        />
      ),
    },
    {
      title: "Service Closed Date",
      dataIndex: "serviceClosedDate",
      sorter: (a, b) => a.serviceClosedDate - b.serviceClosedDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, { id }) => (
        <div className="d-flex align-items-center gap-2">
          <button className="action-btn">Update</button>
        </div>
      ),
    },
  ];

  const annualColumns = [
    {
      title: "Entity Name",
      dataIndex: "entityName",
      sorter: (a, b) => a.entityName - b.entityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      ...getColumnSearchProps("entityName"),
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      sorter: (a, b) => a.entityCode - b.entityCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      ...getColumnSearchProps("entityCode"),
    },
    {
      title: "Entity Status",
      dataIndex: "entityStatus",
      sorter: (a, b) => a.entityStatus - b.entityStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={status == "Y" ? "approved" : "rejected"}
          statusText={status == "Y" ? "Active" : "Inactive"}
        />
      ),
    },
    {
      title: "Masterfile Name",
      dataIndex: "clientName",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Client Code",
      dataIndex: "clientCode",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Client Type",
      dataIndex: "clientType",
      sorter: (a, b) => a.clientType - b.clientType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Fee Schedule",
      dataIndex: "feeSchedule",
      sorter: (a, b) => a.feeSchedule - b.feeSchedule,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Service Fee Type",
      dataIndex: "serviceFeeType",
      sorter: (a, b) => a.serviceFeeType - b.serviceFeeType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Invoice Type",
      dataIndex: "invoiceType",
      sorter: (a, b) => a.invoiceType - b.invoiceType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Amount (USD)",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Invoice number",
      dataIndex: "invoiceNumber",
      sorter: (a, b) => a.invoiceNumber - b.invoiceNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Due date",
      dataIndex: "dueDate",
      sorter: (a, b) => a.dueDate - b.dueDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Penalty (USD)",
      dataIndex: "penalty",
      sorter: (a, b) => a.penalty - b.penalty,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Date",
      dataIndex: "incorpDate",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Batch",
      dataIndex: "incorpBatch",
      sorter: (a, b) => a.incorpBatch - b.incorpBatch,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Status",
      dataIndex: "serviceStatus",
      sorter: (a, b) => a.serviceStatus - b.serviceStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Settlement date",
      dataIndex: "settlementDate",
      sorter: (a, b) => a.settlementDate - b.settlementDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Bank reference",
      dataIndex: "bankRef",
      sorter: (a, b) => a.bankRef - b.bankRef,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
      sorter: (a, b) => a.updatedBy - b.updatedBy,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Updated on",
      dataIndex: "updatedOn",
      sorter: (a, b) => a.updatedOn - b.updatedOn,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, { id }) => (
        <div className="d-flex align-items-center gap-2">
          <button className="action-btn">Update</button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getServiceInvoicing(query["service"]));
  }, [query["service"]]);

  useEffect(() => {
    dispatch(getAnnualInvoicing(query["annual"]));
  }, [query["annual"]]);

  useEffect(() => {
    let obj = { ...table };
    obj["service"]["total"] = serviceInvoicing?.data?.totalCount;
    getData(serviceInvoicing?.data?.rows, "service");

    setTable(obj);
  }, [serviceInvoicing]);

  useEffect(() => {
    let obj = { ...table };
    obj["annual"]["total"] = annualInvoicing?.data?.totalCount;
    getData(annualInvoicing?.data?.rows, "annual");

    setTable(obj);
  }, [annualInvoicing]);

  function serviceTable(data) {
    let overall = [];
    data.map((s, i) => {
      let obj = {
        key: i,
        entityName: s?.entityName ?? "N/A",
        entityCode: s?.entityCode ?? "N/A",
        entityStatus: s?.status ?? "N/A",
        incorpNumber: s?.incorpNumber ?? "N/A",
        incorpDate: s?.entityArray?.incorporationDate
          ? moment(s?.entityArray?.incorporationDate).format("DD-MM-YYYY - LT")
          : "N/A",
        serviceReqDate: s?.serviceReqDate
          ? moment(s?.serviceReqDate).format("DD-MM-YYYY - LT")
          : "N/A",
        serviceType: s?.serviceType ?? "N/A",
        serviceCode: s?.serviceCode ?? "N/A",
        serviceFee: s?.serviceFee ?? "N/A",
        bankRef: s?.bankRef ?? "N/A",
        billingCategory: s?.billingCategory ?? "N/A",
        invoiceNumber: s?.invoiceNumber ?? "N/A",
        dueDate: s?.dueDate
          ? moment(s?.dueDate).format("DD-MM-YYYY - LT")
          : "N/A",
        remittanceDate: s?.remittanceDate
          ? moment(s?.remittanceDate).format("DD-MM-YYYY - LT")
          : "N/A",
        receiptStatus: s?.receiptStatus ?? "N/A",
        serviceStatus: s?.serviceStatus ?? "N/A",
        serviceClosedDate: s?.serviceClosedDate
          ? moment(s?.serviceClosedDate).format("DD-MM-YYYY - LT")
          : "N/A",
      };

      overall.push(obj);
    });
    return overall;
  }

  function annualTable(data) {
    let overall = [];
    data.map((s, i) => {
      let obj = {
        key: i,
        entityName: s?.entityName ?? "N/A",
        entityCode: s?.entityCode ?? "N/A",
        entityStatus: s?.status ?? "N/A",
        clientName: s?.clientName ?? "N/A",
        clientCode: s?.clientCode ?? "N/A",
        clientType: s?.entityArray?.clientType ?? "N/A",
        feeSchedule: s?.feeSchedule ?? "N/A",
        serviceFeeType: s?.serviceFeeType ?? "N/A",
        invoiceType: s?.invoiceType ?? "N/A",
        serviceFee: s?.serviceFee ?? "N/A",
        invoiceNumber: s?.invoiceNumber ?? "N/A",
        dueDate: s?.dueDate
          ? moment(s?.dueDate).format("DD-MM-YYYY - LT")
          : "N/A",
        penalty: s?.penalty ?? "N/A",
        incorpDate: s?.entityArray?.incorporationDate
          ? moment(s?.entityArray?.incorporationDate).format("DD-MM-YYYY - LT")
          : "N/A",
        incorpBatch: s?.incorpBatch ?? "N/A",
        serviceStatus: s?.serviceStatus ?? "N/A",
        settlementDate: s?.settlementDate
          ? moment(s?.settlementDate).format("DD-MM-YYYY - LT")
          : "N/A",
        bankRef: s?.bankRef ?? "N/A",
        updatedBy: s?.updatedBy ?? "N/A",
        updatedOn: s?.updatedOn ?? "N/A",
      };

      overall.push(obj);
    });
    return overall;
  }

  async function getData(rows, key) {
    let obj = { ...table };
    obj[key]["loading"] = true;

    if (rows?.length > 0) {
      let data = key == "service" ? serviceTable(rows) : annualTable(rows);

      obj[key]["loading"] = false;
      obj[key]["data"] = data;
    }
    setTable(obj);
  }

  const fundsColumns = [
    {
      title: "Date of Receipt",
      dataIndex: "dateOfReceipt",
      sorter: (a, b) => a.dateOfReceipt - b.dateOfReceipt,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Amount (USD)",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Bank reference",
      dataIndex: "bankReference",
      sorter: (a, b) => a.bankReference - b.bankReference,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, { id }) => (
        <div className="d-flex align-items-center gap-2">
          <button className="action-btn">Update</button>
        </div>
      ),
    },
  ];

  const fundsData = [
    {
      key: 1,
      dateOfReceipt: "19/07/2023 - 10:00AM",
      amount: "$ 100.00",
      bankReference: "HDFC",
    },
    {
      key: 2,
      dateOfReceipt: "21/07/2023 - 02:00PM",
      amount: "$ 70.00",
      bankReference: "HDFC",
    },
    {
      key: 3,
      dateOfReceipt: "23/07/2023 - 05:00PM",
      amount: "$ 90.00",
      bankReference: "HDFC",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const onPagination = (page, pageSize, key) => {
    let obj = { ...query };
    obj[key] = {
      _limit: pageSize,
      _start: (page - 1) * pageSize,
    };
    setQuery(obj);
  };

  const onExportClick = (fileName, key) => {
    const exportData = table[key]["data"].map((item) => {
      let obj =
        key == "service"
          ? {
              "Entity Name": item?.entityName,
              "Entity Code": item?.entityCode,
              "Entity Status": item?.entityStatus,
              "Incorp Number": item?.incorpNumber,
              "Incorporation Date": item?.incorpDate,
              "Service Req Date": item?.serviceReqDate,
              "Service Type": item?.serviceType,
              "Service  Code": item?.serviceCode,
              "Value $": item?.serviceFee,
              "Bank reference": item?.bankRef,
              "Billing Category": item?.billingCategory,
              "Invoice number": item?.invoiceNumber,
              "Due Date": item?.dueDate,
              "Remittance Date": item?.remittanceDate,
              "Receipt Status": item?.receiptStatus,
              "Service Status": item?.serviceStatus,
              "Service Closed Date": item?.serviceClosedDate,
            }
          : {
              "Entity Name": item?.entityName,
              "Entity Code": item?.entityCode,
              "Entity Status": item?.entityStatus,
              "Masterfile Name": item?.clientName,
              "Client Code": item?.clientCode,
              "Client Type": item?.clientType,
              "Fee Schedule": item?.feeSchedule,
              "Service Fee Type": item?.serviceFeeType,
              "Invoice Type": item?.invoiceType,
              "Amount (USD)": item?.serviceFee,
              "Invoice number": item?.invoiceNumber,
              "Due date": item?.dueDate,
              "Penalty (USD)": item?.penalty,
              "Incorp Date": item?.incorpDate,
              "Incorp Batch": item?.incorpBatch,
              Status: item?.serviceStatus,
              "Settlement date": item?.settlementDate,
              "Bank reference": item?.bankRef,
              "Updated By": item?.updatedBy,
              "Updated on": item?.updatedOn,
            };
      return obj;
    });
    downloadAsCSV(exportData, fileName);
  };

  return (
    <div>
      <div className="page-header">
        <h1>Entity Invoicing</h1>
      </div>
      <div className="page-content">
        <div className="d-flex mt-3 justify-content-between flex-column flex-md-row align-items-md-center align-items-start mb-3">
          <h2 className="primary-title mb-3 mb-md-0">Service Invoicing</h2>
          <button
            className="primary-btn"
            onClick={() => onExportClick("service-invoice", "service")}
          >
            Bulk Export
          </button>
        </div>
        <Table
          className="custom-table"
          rowSelection={{ type: "checkbox", ...rowSelection }}
          loading={serviceInvoicing?.isLoading}
          columns={serviceColumns}
          dataSource={table["service"]["data"]}
          scroll={{ x: 2600 }}
          pagination={{
            className: "custom-pagination",
            itemRender: customPagination,
            defaultCurrent: 1,
            total: table["service"]["total"],
            onChange: (page, pageSize) =>
              onPagination(page, pageSize, "service"),
          }}
        />
        <div className="d-flex mt-3 justify-content-between flex-column flex-md-row align-items-md-center align-items-start mb-3">
          <h2 className="primary-title mb-3 mb-md-0">Un-allocated Funds</h2>
          <button className="primary-btn">Bulk Export</button>
        </div>
        <Table
          className="custom-table"
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={fundsColumns}
          dataSource={fundsData}
          pagination={{
            className: "custom-pagination",
            itemRender: customPagination,
          }}
        />
        <div className="d-flex mt-3 justify-content-between flex-column flex-md-row align-items-md-center align-items-start mb-3">
          <h2 className="primary-title mb-3 mb-md-0">Annual Invoicing</h2>
          <button
            className="primary-btn"
            onClick={() => onExportClick("annual-invoice", "annual")}
          >
            Bulk Export
          </button>
        </div>
        <Table
          className="custom-table"
          rowSelection={{ type: "checkbox", ...rowSelection }}
          loading={annualInvoicing?.isLoading}
          columns={annualColumns}
          dataSource={table["annual"]["data"]}
          scroll={{ x: 2800 }}
          pagination={{
            className: "custom-pagination",
            itemRender: customPagination,
            defaultCurrent: 1,
            total: table["annual"]["total"],
            onChange: (page, pageSize) =>
              onPagination(page, pageSize, "annual"),
          }}
        />
        <button className="primary-btn">Update</button>
      </div>
    </div>
  );
}
