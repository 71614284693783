import { useState, useEffect } from "react";
import { Table } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { percentageOf, customPagination } from "../helper";

import InProgressIcon from "../assets/img/in-progress-icon.svg";
import ApprovedIcon from "../assets/img/approved-icon.svg";
import RejectedIcon from "../assets/img/rejected-icon.svg";
import NewIcon from "../assets/img/new-icon.svg";

import TagZ from "../components/TagZ";
import TabZ from "../components/TabZ";
import TableSortArrows from "../components/tableSortArrows";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function PeriodicReview() {
  const [periodicData, setPeriodicData] = useState([]);

  useEffect(() => {
    setPeriodicData(data);
  }, []);

  const onChange = (key) => {
    let arr = [];
    if (key == 1) {
      arr = data;
    } else if (key == 2) {
      arr = data.filter((val) => val.riskLevel == "High");
    } else if (key == 3) {
      arr = data.filter((val) => val.riskLevel == "Medium");
    } else if (key == 4) {
      arr = data.filter((val) => val.riskLevel == "Low");
    }

    setPeriodicData(arr);
  };

  const pieData = {
    labels: ["High Risk", "Medium Risk", "Low Risk"],
    datasets: [
      {
        label: "Periodic Review",
        data: [20, 10, 30],
        backgroundColor: ["#db0015", "#ff9b00", "#ffef00"],
        borderColor: ["#db0015", "#ff9b00", "#ffef00"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        labels: {
          font: {
            family: "Inter",
          },
        },
      },
      datalabels: {
        color: "#FFF",
        font: {
          family: "Inter",
          weight: "600",
          size: 16,
        },
        textStrokeColor: "#000",
        textStrokeWidth: 2,
        formatter: (value, context) => {
          let val = percentageOf(value, 60) + "%";
          // let val = `${percentageOf(value, 60)}% (${value})`;
          return val;
        },
      },
    },
  };

  const labels = [
    {
      key: 1,
      label: (
        <>
          <img src={NewIcon} alt="" /> All Periodic
        </>
      ),
    },
    {
      key: 2,
      label: (
        <>
          <img src={RejectedIcon} alt="" />
          High Risk
        </>
      ),
    },
    {
      key: 3,
      label: (
        <>
          <img src={InProgressIcon} alt="" />
          Medium Risk
        </>
      ),
    },
    {
      key: 4,
      label: (
        <>
          <img src={ApprovedIcon} alt="" />
          Low Risk
        </>
      ),
    },
  ];

  const columns = [
    {
      title: "Parent Client Name",
      dataIndex: "parentClientName",
      sorter: (a, b) => a.parentClientName - b.parentClientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Code",
      dataIndex: "parentClientCode",
      sorter: (a, b) => a.parentClientCode - b.parentClientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Name",
      dataIndex: "entityName",
      sorter: (a, b) => a.entityName - b.entityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      sorter: (a, b) => a.entityCode - b.entityCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Client Code",
      dataIndex: "clientCode",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorporation Number",
      dataIndex: "incorporationNumber",
      sorter: (a, b) => a.incorporationNumber - b.incorporationNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorporated Date",
      dataIndex: "incorporatedDate",
      sorter: (a, b) => a.incorporatedDate - b.incorporatedDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Risk Level",
      dataIndex: "riskLevel",
      sorter: (a, b) => a.riskLevel - b.riskLevel,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { riskLevel }) => {
        let status;
        switch (riskLevel) {
          case "Low":
            status = "approved";
            break;
          case "Medium":
            status = "in-progress";
            break;
          case "High":
            status = "rejected";
            break;
        }
        return <TagZ status={status} statusText={riskLevel} />;
      },
    },
    {
      title: "Last Reviewed By",
      dataIndex: "lastReviewedBy",
      sorter: (a, b) => a.lastReviewedBy - b.lastReviewedBy,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Last Reviewed Date",
      dataIndex: "lastReviewedDate",
      sorter: (a, b) => a.lastReviewedDate - b.lastReviewedDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Next Review Date",
      dataIndex: "nextReviewDate",
      sorter: (a, b) => a.nextReviewDate - b.nextReviewDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Remaining Count Of Days",
      dataIndex: "remainingCountOfDays",
      sorter: (a, b) => a.remainingCountOfDays - b.remainingCountOfDays,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  const data = [
    {
      key: 0,
      id: "0",
      entityName: "Sample",
      entityCode: "9023",
      clientName: "COI",
      clientCode: "839",
      incorporationNumber: "001",
      incorporatedDate: "15/04/2023",
      parentClientName: "Hari",
      parentClientCode: "632",
      riskLevel: "High",
      lastReviewedBy: "Guru",
      lastReviewedDate: "20/06/2024",
      nextReviewDate: "23/04/2023",
      remainingCountOfDays: "20",
    },
    {
      key: 1,
      id: "1",
      entityName: "Sample",
      entityCode: "9023",
      clientName: "COI",
      clientCode: "839",
      incorporationNumber: "002",
      incorporatedDate: "16/04/2023",
      parentClientName: "Hari",
      parentClientCode: "632",
      riskLevel: "High",
      lastReviewedBy: "Guru",
      lastReviewedDate: "20/06/2024",
      nextReviewDate: "23/04/2023",
      remainingCountOfDays: "20",
    },
    {
      key: 2,
      id: "2",
      entityName: "Sample",
      entityCode: "9023",
      clientName: "COI",
      clientCode: "839",
      incorporationNumber: "002",
      incorporatedDate: "16/04/2023",
      parentClientName: "Hari",
      parentClientCode: "632",
      riskLevel: "High",
      lastReviewedBy: "Guru",
      lastReviewedDate: "20/06/2024",
      nextReviewDate: "23/04/2023",
      remainingCountOfDays: "20",
    },
    {
      key: 4,
      id: "4",
      entityName: "Sample",
      entityCode: "9023",
      clientName: "COI",
      clientCode: "839",
      incorporationNumber: "002",
      incorporatedDate: "16/04/2023",
      parentClientName: "Hari",
      parentClientCode: "632",
      riskLevel: "Medium",
      lastReviewedBy: "23/04/2023",
      lastReviewedDate: "20/06/2024",
      nextReviewDate: "23/04/2023",
      remainingCountOfDays: "20",
    },
    {
      key: 5,
      id: "5",
      entityName: "Sample",
      entityCode: "9023",
      clientName: "COI",
      clientCode: "839",
      incorporationNumber: "002",
      incorporatedDate: "16/04/2023",
      parentClientName: "Hari",
      parentClientCode: "632",
      riskLevel: "Medium",
      lastReviewedBy: "23/04/2023",
      lastReviewedDate: "20/06/2024",
      nextReviewDate: "23/04/2023",
      remainingCountOfDays: "20",
    },
    {
      key: 6,
      id: "6",
      entityName: "Sample",
      entityCode: "9023",
      clientName: "COI",
      clientCode: "839",
      incorporationNumber: "002",
      incorporatedDate: "16/04/2023",
      parentClientName: "Hari",
      parentClientCode: "632",
      riskLevel: "Medium",
      lastReviewedBy: "23/04/2023",
      lastReviewedDate: "20/06/2024",
      nextReviewDate: "23/04/2023",
      remainingCountOfDays: "20",
    },
    {
      key: 7,
      id: "7",
      entityName: "Sample",
      entityCode: "9023",
      clientName: "COI",
      clientCode: "839",
      incorporationNumber: "002",
      incorporatedDate: "16/04/2023",
      parentClientName: "Hari",
      parentClientCode: "632",
      riskLevel: "Medium",
      lastReviewedBy: "23/04/2023",
      lastReviewedDate: "20/06/2024",
      nextReviewDate: "23/04/2023",
      remainingCountOfDays: "20",
    },
    {
      key: 8,
      id: "8",
      entityName: "Sample",
      entityCode: "9023",
      clientName: "COI",
      clientCode: "839",
      incorporationNumber: "002",
      incorporatedDate: "16/04/2023",
      parentClientName: "Hari",
      parentClientCode: "632",
      riskLevel: "Medium",
      lastReviewedBy: "23/04/2023",
      lastReviewedDate: "20/06/2024",
      nextReviewDate: "23/04/2023",
      remainingCountOfDays: "20",
    },
    {
      key: 9,
      id: "9",
      entityName: "Sample",
      entityCode: "9023",
      clientName: "COI",
      clientCode: "839",
      incorporationNumber: "002",
      incorporatedDate: "16/04/2023",
      parentClientName: "Hari",
      parentClientCode: "632",
      riskLevel: "Low",
      lastReviewedBy: "23/04/2023",
      lastReviewedDate: "20/06/2024",
      nextReviewDate: "23/04/2023",
      remainingCountOfDays: "20",
    },
    {
      key: 10,
      id: "10",
      entityName: "Sample",
      entityCode: "9023",
      clientName: "COI",
      clientCode: "839",
      incorporationNumber: "002",
      incorporatedDate: "16/04/2023",
      parentClientName: "Hari",
      parentClientCode: "632",
      riskLevel: "Low",
      lastReviewedBy: "23/04/2023",
      lastReviewedDate: "20/06/2024",
      nextReviewDate: "23/04/2023",
      remainingCountOfDays: "20",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div>
      <div className="page-header">
        <h1>Periodic Review</h1>
        <button className="primary-btn">Bulk Export</button>
      </div>
      <div className="page-content">
        <div className="mb-3">
          <div className="w-25 mx-auto">
            <Pie data={pieData} plugins={[ChartDataLabels]} options={options} />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            {/* <Tabs
          className="custom-tabs"
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        /> */}
            <TabZ labels={labels} defaultActive="1" onChange={onChange} />
            <button className="outline-btn">Export</button>
          </div>
        </div>
        <Table
          className="custom-table mb-4"
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={columns}
          dataSource={periodicData}
          scroll={{ x: 2000 }}
          pagination={{
            className: "custom-pagination",
            total: 100,
            pageSize: 10,
            itemRender: customPagination,
          }}
        />
      </div>
    </div>
  );
}
