import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import { Table, Tag, Select, Modal, Checkbox } from "antd";

import TagZ from "../components/TagZ";
import TableSortArrows from "../components/tableSortArrows";

import {
  downloadAsCSV,
  getQuery,
  fetchApi,
  convertQueryParams,
  customPagination,
} from "../helper";

export default function MyPortfolio() {
  const dispatch = useDispatch();
  const [resubmitModal, setResubmitModal] = useState(false);
  const [selectedType, setSelectedType] = useState([]);
  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const columns = [
    {
      title: "Parent Client Name",
      dataIndex: "parentClientName",
      sorter: (a, b) => a.parentClientName - b.parentClientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Code",
      dataIndex: "parentClientCode",
      sorter: (a, b) => a.parentClientCode - b.parentClientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Name",
      dataIndex: "clientName",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Client Code",
      dataIndex: "clientCode",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Client Type",
      dataIndex: "clientType",
      sorter: (a, b) => a.clientType - b.clientType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Name",
      dataIndex: "entityName",
      sorter: (a, b) => a.entityName - b.entityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Status",
      dataIndex: "entityStatus",
      sorter: (a, b) => a.entityStatus - b.entityStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 200,
      render: (_, { status }) => (
        <Select
          className="custom-select"
          placeholder="Select Status"
          value={status}
          style={{
            width: 160,
          }}
          options={[
            {
              value: "active",
              label: "Active",
            },
            {
              value: "inactive",
              label: "Inactive",
            },
            {
              value: "closing",
              label: "Closing",
            },
            {
              value: "closed",
              label: "Closed",
            },

            {
              value: "transferringOut",
              label: "Transferring Out",
            },
            {
              value: "prospect",
              label: "Prospect",
            },
            {
              value: "transferringIn",
              label: "Transferring In",
            },
            {
              value: "liquidated",
              label: "Liquidated",
            },
            {
              value: "struckOff",
              label: "Struck Off",
            },
            {
              value: "resigned",
              label: "Resigned",
            },
            {
              value: "continuationOut",
              label: "Continuation Out",
            },
            {
              value: "continuationIn",
              label: "Continuation In",
            },
            {
              value: "transferringIn",
              label: "Transferring In",
            },
            {
              value: "transferringOut",
              label: "Transferring Out",
            },
          ]}
        />
      ),
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      sorter: (a, b) => a.entityCode - b.entityCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Incorp Date",
      dataIndex: "incorpDate",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Overview",
      dataIndex: "overview",
      sorter: (a, b) => a.overview - b.overview,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { id }) => (
        <Link to={`/app/entity-overview/list`} className="action-btn icon-btn">
          <i className="fal fa-eye"></i>
        </Link>
      ),
    },
    {
      title: "Composite Risk Level",
      dataIndex: "compositeRiskLevel",
      width: 180,
      sorter: (a, b) => a.compositeRiskLevel - b.compositeRiskLevel,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { id }) => <TagZ status={"approved"} statusText={"Low"} />,
    },
    {
      title: "Renewal Batch",
      dataIndex: "renewalBatch",
      sorter: (a, b) => a.renewalBatch - b.renewalBatch,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Renewal Due Date",
      dataIndex: "renewalDueDate",
      sorter: (a, b) => a.renewalDueDate - b.renewalDueDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "New Task Request",
      dataIndex: "newTaskRequest",
      width: 300,
      sorter: (a, b) => a.newTaskRequest - b.newTaskRequest,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { key }) => (
        <Select
          className="custom-select"
          style={{ width: 250 }}
          placeholder="Select"
          onChange={(value) => getSelectType(value, key)}
          options={postIncorpType}
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 100,
      render: (_, { key }) => (
        <Link
          to={`/app/post-incorp?service=${selectedType[key]}`}
          className={`action-btn ${
            !selectedType[key] ? "action-disabled" : ""
          }`}
        >
          Submit
        </Link>
      ),
    },
  ];

  const data = [
    {
      _id: "670a1f7149ab7f59aa36dea9",
      code: "EN00293",
      name: "dfyhdgy",
      masterType: "entity",
      type: "BVIBC",
      clientName: "udtjutyfkhethetyhhfgjytujryujry",
      clientId: "663b7eb779aaca7c509a7604",
      relatedEntities: ["663b44d62aa98a0f6799a0fa", "66430782ffa22c52248cefe7"],
      verified: false,
      clientType: "introducer",
      incorporationDate: "2024-09-30T18:30:00.000Z",
      incorporationNumber: "tyurt",
      feeCat: "670a185549ab7f59aa36cd8f",
      subCat: "670a185549ab7f59aa36cdcd",
      feeType: "Post-Paid",
      authorisedShares: 56745,
      cddInformation: {
        busGeography: [],
        reminderDate: [],
        filingDate: [],
      },
      financialRecords: {
        details: [],
      },
      administrator: "gchjfh",
      email: "jdghj@gmail.com",
      contactNo: "473756",
      status: "prospect",
      YearOfEndDate: "2024-10-29T18:30:00.000Z",
      isDelete: false,
      requestedBy: {
        userId: "660e432cb18380a8d90c7140",
        name: "admin",
        email: "jayarajsingaram1@gmail.com",
      },
      createdAt: "12-Oct 2024 12:34 pm",
      updatedAt: "2024-10-12T07:04:17.220Z",
      primaryContact: [],
      history: [],
      __v: 0,
      createdBy: "admin",
    },
    {
      key: 0,
      id: "1",
      entityName: "Sample", //name
      entityType: "Trust", //type
      entityCode: "9023", //code
      rmName: "Sample-1",
      clientName: "COI",
      clientCode: "839", //clientId
      clientType: "End User Client",
      parentClientName: "Hari",
      parentClientCode: "632",
      renewalBatch: "1st Half",
      jurisdiction: "India",
      incorpDate: "23/04/2023", //incorporationDate
      renewalDueDate: "20/06/2024",
    },
    {
      key: 1,
      id: "2",
      entityName: "Sample",
      entityType: "Trust",
      entityCode: "9023",
      rmName: "Sample",
      clientName: "COI",
      clientCode: "839",
      clientType: "End User Client",
      parentClientName: "Hari",
      parentClientCode: "632",
      renewalBatch: "1st Half",
      jurisdiction: "India",
      incorpDate: "23/04/2023",
      renewalDueDate: "20/06/2024",
    },
    {
      key: 2,
      id: "3",
      entityName: "Sample",
      entityType: "Trust",
      entityCode: "9023",
      rmName: "Sample",
      clientName: "COI",
      clientCode: "839",
      clientType: "End User Client",
      parentClientName: "Hari",
      parentClientCode: "632",
      renewalBatch: "2nd Half",
      jurisdiction: "India",
      incorpDate: "23/04/2023",
      renewalDueDate: "20/06/2024",
    },
  ];
  useEffect(() => {
    handleRefresh();
  }, []);
  const handleRefresh = async () => {
    let params = {
      ...queryParams,
      isAll: true,
      masterType: "entity",
    };

    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/client?${query}`,
    };

    let data = await fetchApi(payload).then((res) => res?.data);

    setTotalCount(data.totalCount);
    setTableData(data.rows);
    setQueryParams(params);
  };
  console.log(tableData, "aSDGFHJKL");
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const postIncorpType = [
    {
      value: "COI",
      label: "Certificate of Incumbency (COI)",
    },
    {
      value: "COGS",
      label: "Certificate of Good Standing (COGS)",
    },
    {
      value: "ROD",
      label: "Register of Directors",
    },
    {
      value: "SR",
      label: "Share Registry",
    },
    {
      value: "COD",
      label: "Change Of Director (COD)",
    },
    {
      value: "COSH",
      label: "Change of Shareholder (COSH)",
    },
    {
      value: "COA",
      label: "Change of Client / Administration (COA)",
    },
    {
      value: "COC",
      label: "Change of Contact Point (COC)",
    },
    {
      value: "TO",
      label: "Transfer Out (TO)",
    },
    {
      value: "CO",
      label: "Continuation Out (CO)",
    },
    {
      value: "AMAA",
      label: "Alteration to the Memorandum & Articles of Association",
    },
    {
      value: "ARMA",
      label: "Amended and Restated Memorandum & Articles",
    },
    { value: "Apostilles", label: "Apostilles" },
    {
      value: "CGS",
      label: "Certificate of Good Standing",
    },
    {
      value: "RCAD",
      label: "Registry Certified Copy of Any Document",
    },
    {
      value: "RUD",
      label: "Registry Uncertified or Duplicate Copy of Any Document",
    },
    {
      value: "RPEC",
      label: "Registry Physical Copy of any Electronic Certificate",
    },
    { value: "CI", label: "Certificate of Incumbency" },
    {
      value: "CCPD",
      label: "CCP Certified or Duplicate copies of documents",
    },
    {
      value: "ARD",
      label: "Amendments to Register of Directors",
    },
    {
      value: "ARM",
      label:
        "Amendments to Register of Members & Issuing New Share Certificate(s)",
    },
    { value: "Continuations", label: "Continuations" },
    { value: "DR", label: "Drafting Resolutions" },
    {
      value: "FRMC",
      label: "Filing Register of Mortgage & Charges",
    },
    {
      value: "FAR",
      label: "Filing Appointment of RO/RA",
    },
    { value: "FTO", label: "Filing Transfer Out" },
    { value: "IA", label: "Indemnity Agreements" },
    { value: "Notarizations", label: "Notarizations" },
    { value: "PA", label: "Powers of Attorney" },
    {
      value: "RSC",
      label: "Reactivation of struckoff companies",
    },
    {
      value: "RNR",
      label: "Registration of Notice of Resignation as Registered Agent",
    },
    {
      value: "FRRA",
      label: "Filing notice of Recission as Registered Agent",
    },
    {
      value: "RFC",
      label: "Registration as a Foreign Company in the BVI",
    },
    {
      value: "FNFC",
      label:
        "Filing Notice of Foreign Company Ceasing to Do Business in the BVI",
    },
    {
      value: "EFF",
      label: "Expedite Fee for Fast Track Due Diligence and Incorporation",
    },
  ];

  const getSelectType = (value, key) => {
    let arr = [...selectedType];
    arr[key] = value;
    setSelectedType(arr);
  };

  const previousRequestsColumns = [
    {
      title: "Entity Name",
      dataIndex: "entityName",
      sorter: (a, b) => a.entityName - b.entityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      sorter: (a, b) => a.entityCode - b.entityCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Status",
      dataIndex: "entityStatus",
      sorter: (a, b) => a.entityStatus - b.entityStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 200,
      render: (_, { status }) => (
        <Select
          className="custom-select"
          placeholder="Select Status"
          value={status}
          style={{
            width: 160,
          }}
          options={[
            {
              value: "active",
              label: "Active",
            },
            {
              value: "inactive",
              label: "Inactive",
            },
            {
              value: "closing",
              label: "Closing",
            },
            {
              value: "closed",
              label: "Closed",
            },

            {
              value: "transferringOut",
              label: "Transferring Out",
            },
            {
              value: "prospect",
              label: "Prospect",
            },
            {
              value: "transferringIn",
              label: "Transferring In",
            },
            {
              value: "liquidated",
              label: "Liquidated",
            },
            {
              value: "struckOff",
              label: "Struck Off",
            },
            {
              value: "resigned",
              label: "Resigned",
            },
            {
              value: "continuationOut",
              label: "Continuation Out",
            },
            {
              value: "continuationIn",
              label: "Continuation In",
            },
          ]}
        />
      ),
    },
    {
      title: "Task Type",
      dataIndex: "taskType",
      sorter: (a, b) => a.taskType - b.taskType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Fee",
      dataIndex: "fee",
      sorter: (a, b) => a.fee - b.fee,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Request Date",
      dataIndex: "requestDate",
      width: 180,
      sorter: (a, b) => a.requestDate - b.requestDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Completion Date",
      dataIndex: "completionDate",
      sorter: (a, b) => a.completionDate - b.completionDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      sorter: (a, b) => a.paymentStatus - b.paymentStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { paymentStatus }) => (
        <TagZ
          status={paymentStatus == "Y" ? "approved" : "in-progress"}
          statusText={paymentStatus == "Y" ? "Paid" : "Pending"}
        />
      ),
    },
  ];

  const previousRequestsData = [
    {
      key: 0,
      id: "1",
      entityName: "Sample",
      entityCode: "9023",
      taskType: "Sample",
      fee: "COI",
      requestDate: "20/06/2024",
      completionDate: "23/04/2023",
      paymentStatus: "Y",
    },
    {
      key: 1,
      id: "2",
      entityName: "Sample",
      entityCode: "9023",
      taskType: "Sample",
      fee: "COI",
      requestDate: "20/06/2024",
      completionDate: "23/04/2023",
      paymentStatus: "Y",
    },
    {
      key: 2,
      id: "3",
      entityName: "Sample",
      entityCode: "9023",
      taskType: "Sample",
      fee: "COI",
      requestDate: "20/06/2024",
      completionDate: "23/04/2023",
      paymentStatus: "N",
    },
  ];

  return (
    <div>
      <div className="page-header">
        <h1>My Portfolio – Client</h1>
      </div>

      <div className="page-content">
        <button className="primary-btn ms-auto mb-3">Bulk Submit</button>
        <Table
          className="custom-table mb-4"
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={columns}
          dataSource={data}
          scroll={{ x: 2500 }}
        />
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2 className="primary-title mb-0">Previous Requests</h2>
          <button className="primary-btn">Bulk Submit</button>
        </div>
        <Table
          className="custom-table"
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={previousRequestsColumns}
          dataSource={previousRequestsData}
          pagination={{
            className: "custom-pagination",
            itemRender: customPagination,
          }}
          // scroll={{ x: 2200 }}
        />
      </div>
    </div>
  );
}
