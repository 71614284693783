import React, { useState, useEffect } from "react";
import { Table, Tag, Select, Modal, DatePicker } from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { downloadAsCSV, customPagination, fetchApi } from "../helper";
import TagZ from "../components/TagZ";
import TableSortArrows from "../components/tableSortArrows";

import { getAnnualReturn } from "../store/annualFilings/annualFilingsSlice";
import { getBossFilings } from "../store/bossFiling/bossFilingsSlice";
import { getRodFilings } from "../store/rodFiling/rodFilingsSlice";

export default function AnnualFilings() {
  const dispatch = useDispatch();

  const annualFilings = useSelector((state) => state.annualFilings);
  const bossFilings = useSelector((state) => state.bossFilings);
  const rodFilings = useSelector((state) => state.rodFilings);

  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [query, setQuery] = useState({
    annual: {
      _limit: 10,
      _start: 0,
    },
    boss: {
      _limit: 10,
      _start: 0,
    },
    rod: {
      _limit: 10,
      _start: 0,
    },
  });
  const [table, setTable] = useState({
    annual: {
      data: [],
      total: 0,
    },
    boss: {
      data: [],
      total: 0,
    },
    rod: {
      data: [],
      total: 0,
    },
  });
  const [modalData, setModalData] = useState({});

  const annualColumns = [
    {
      title: "Entity Name",
      dataIndex: "entityName",
      sorter: (a, b) => a.entityName.localeCompare(b.entityName),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      sorter: (a, b) => a.entityCode.localeCompare(b.entityCode),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Status",
      dataIndex: "entityStatus",
      sorter: (a, b) => a.entityStatus.localeCompare(b.entityStatus),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Date",
      dataIndex: "incorpDate",
      sorter: (a, b) => a.incorpDate.localeCompare(b.incorpDate),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorporation Number",
      dataIndex: "incorporationNumber",
      sorter: (a, b) =>
        a.incorporationNumber.localeCompare(b.incorporationNumber),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Batch",
      dataIndex: "incorpBatch",
      sorter: (a, b) => a.incorpBatch.localeCompare(b.incorpBatch),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Name",
      dataIndex: "masterfileName",
      sorter: (a, b) => a.masterfileName.localeCompare(b.masterfileName),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Code",
      dataIndex: "masterfileCode",
      sorter: (a, b) => a.masterfileCode.localeCompare(b.masterfileCode),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Name",
      dataIndex: "parentClientName",
      sorter: (a, b) => a.parentClientName.localeCompare(b.parentClientName),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Code",
      dataIndex: "parentClientCode",
      sorter: (a, b) => a.parentClientCode.localeCompare(b.parentClientCode),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Filing type",
      dataIndex: "filingType",
      sorter: (a, b) => a.filingType.localeCompare(b.filingType),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Count Of Reminders",
      dataIndex: "countOfReminders",
      sorter: (a, b) => a.countOfReminders.localeCompare(b.countOfReminders),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Deadline Breach",
      dataIndex: "deadlineBreach",
      sorter: (a, b) => a.deadlineBreach.localeCompare(b.deadlineBreach),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { deadlineBreach }) => (
        <TagZ
          status={deadlineBreach == "Y" ? "approved" : "rejected"}
          statusText={deadlineBreach == "Y" ? "Yes" : "No"}
        />
      ),
    },
    {
      title: "Filing Status",
      dataIndex: "filingStatus",
      sorter: (a, b) => a.filingStatus.localeCompare(b.filingStatus),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { filingStatus }) => (
        <TagZ
          status={filingStatus == "Y" ? "approved" : "rejected"}
          statusText={filingStatus == "Y" ? "Yes" : "No"}
        />
      ),
    },
    {
      title: "FSC Notification Status",
      dataIndex: "fscStatus",
      sorter: (a, b) => a.fscStatus.localeCompare(b.fscStatus),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { fscStatus }) => (
        <TagZ
          status={fscStatus == "Y" ? "approved" : "in-progress"}
          statusText={fscStatus == "Y" ? "Submitted" : "Pending"}
        />
      ),
    },
    {
      title: "View FSC Update",
      render: (_, { key }) => (
        <button
          className="action-btn icon-btn"
          onClick={() => openModal(key, "annual")}
        >
          <i className="fal fa-eye"></i>
        </button>
      ),
    },
  ];

  const bossColumns = [
    {
      title: "Entity Name",
      dataIndex: "entityName",
      sorter: (a, b) => a.entityName.localeCompare(b.entityName),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      sorter: (a, b) => a.entityCode.localeCompare(b.entityCode),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Status",
      dataIndex: "entityStatus",
      sorter: (a, b) => a.entityStatus.localeCompare(b.entityStatus),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Date",
      dataIndex: "incorpDate",
      sorter: (a, b) => a.incorpDate.localeCompare(b.incorpDate),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorporation Number",
      dataIndex: "incorporationNumber",
      sorter: (a, b) =>
        a.incorporationNumber.localeCompare(b.incorporationNumber),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Batch",
      dataIndex: "incorpBatch",
      sorter: (a, b) => a.incorpBatch.localeCompare(b.incorpBatch),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Name",
      dataIndex: "masterfileName",
      sorter: (a, b) => a.masterfileName.localeCompare(b.masterfileName),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Code",
      dataIndex: "masterfileCode",
      sorter: (a, b) => a.masterfileCode.localeCompare(b.masterfileCode),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Name",
      dataIndex: "parentClientName",
      sorter: (a, b) => a.parentClientName.localeCompare(b.parentClientName),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Code",
      dataIndex: "parentClientCode",
      sorter: (a, b) => a.parentClientCode.localeCompare(b.parentClientCode),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Filing type",
      dataIndex: "filingType",
      sorter: (a, b) => a.filingType.localeCompare(b.filingType),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Count Of Reminders",
      dataIndex: "countOfReminders",
      sorter: (a, b) => a.countOfReminders.localeCompare(b.countOfReminders),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Deadline Breach",
      dataIndex: "deadlineBreach",
      sorter: (a, b) => a.deadlineBreach.localeCompare(b.deadlineBreach),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { deadlineBreach }) => (
        <TagZ
          status={deadlineBreach == "Y" ? "approved" : "rejected"}
          statusText={deadlineBreach == "Y" ? "Yes" : "No"}
        />
      ),
    },
    {
      title: "Filing Status",
      dataIndex: "filingStatus",
      sorter: (a, b) => a.filingStatus.localeCompare(b.filingStatus),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { filingStatus }) => (
        <TagZ
          status={filingStatus == "Y" ? "approved" : "rejected"}
          statusText={filingStatus == "Y" ? "Yes" : "No"}
        />
      ),
    },
    {
      title: "FSC Notification Status",
      dataIndex: "fscStatus",
      sorter: (a, b) => a.fscStatus.localeCompare(b.fscStatus),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { fscStatus }) => (
        <TagZ
          status={fscStatus == "Y" ? "approved" : "in-progress"}
          statusText={fscStatus == "Y" ? "Submitted" : "Pending"}
        />
      ),
    },
    {
      title: "View FSC Update",
      render: (_, { key }) => (
        <button
          className="action-btn icon-btn"
          onClick={() => openModal(key, "boss")}
        >
          <i className="fal fa-eye"></i>
        </button>
      ),
    },
  ];

  const rodColumns = [
    {
      title: "Entity Name",
      dataIndex: "entityName",
      sorter: (a, b) => a.entityName.localeCompare(b.entityName),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      sorter: (a, b) => a.entityCode.localeCompare(b.entityCode),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Status",
      dataIndex: "entityStatus",
      sorter: (a, b) => a.entityStatus.localeCompare(b.entityStatus),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Date",
      dataIndex: "incorpDate",
      sorter: (a, b) => a.incorpDate.localeCompare(b.incorpDate),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorporation Number",
      dataIndex: "incorporationNumber",
      sorter: (a, b) =>
        a.incorporationNumber.localeCompare(b.incorporationNumber),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Batch",
      dataIndex: "incorpBatch",
      sorter: (a, b) => a.incorpBatch.localeCompare(b.incorpBatch),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Name",
      dataIndex: "masterfileName",
      sorter: (a, b) => a.masterfileName.localeCompare(b.masterfileName),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Code",
      dataIndex: "masterfileCode",
      sorter: (a, b) => a.masterfileCode.localeCompare(b.masterfileCode),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Name",
      dataIndex: "parentClientName",
      sorter: (a, b) => a.parentClientName.localeCompare(b.parentClientName),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Code",
      dataIndex: "parentClientCode",
      sorter: (a, b) => a.parentClientCode.localeCompare(b.parentClientCode),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Filing type",
      dataIndex: "filingType",
      sorter: (a, b) => a.filingType.localeCompare(b.filingType),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Count Of Reminders",
      dataIndex: "countOfReminders",
      sorter: (a, b) => a.countOfReminders.localeCompare(b.countOfReminders),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Deadline Breach",
      dataIndex: "deadlineBreach",
      sorter: (a, b) => a.deadlineBreach.localeCompare(b.deadlineBreach),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { deadlineBreach }) => (
        <TagZ
          status={deadlineBreach == "Y" ? "approved" : "rejected"}
          statusText={deadlineBreach == "Y" ? "Yes" : "No"}
        />
      ),
    },
    {
      title: "Filing Status",
      dataIndex: "filingStatus",
      sorter: (a, b) => a.filingStatus.localeCompare(b.filingStatus),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { filingStatus }) => (
        <TagZ
          status={filingStatus == "Y" ? "approved" : "rejected"}
          statusText={filingStatus == "Y" ? "Yes" : "No"}
        />
      ),
    },
    {
      title: "FSC Notification Status",
      dataIndex: "fscStatus",
      sorter: (a, b) => a.fscStatus.localeCompare(b.fscStatus),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { fscStatus }) => (
        <TagZ
          status={fscStatus == "Y" ? "approved" : "in-progress"}
          statusText={fscStatus == "Y" ? "Submitted" : "Pending"}
        />
      ),
    },
    {
      title: "View FSC Update",
      render: (_, { key }) => (
        <button
          className="action-btn icon-btn"
          onClick={() => openModal(key, "rod")}
        >
          <i className="fal fa-eye"></i>
        </button>
      ),
    },
    {
      title: "History",
      render: (_, data) => (
        <button
          className="action-btn icon-btn"
          onClick={() => setOpenHistory(true)}
        >
          <i className="fal fa-eye"></i>
        </button>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getAnnualReturn(query["annual"]));
  }, [query["annual"]]);

  useEffect(() => {
    dispatch(getBossFilings(query["boss"]));
  }, [query["boss"]]);

  useEffect(() => {
    dispatch(getRodFilings(query["rod"]));
  }, [query["rod"]]);

  useEffect(() => {
    let obj = { ...table };
    obj["annual"]["total"] = annualFilings?.data?.totalCount;
    getData(annualFilings?.data?.rows, "annual");

    setTable(obj);
  }, [annualFilings]);

  useEffect(() => {
    let obj = { ...table };
    obj["boss"]["total"] = bossFilings?.data?.totalCount;
    getData(bossFilings?.data?.rows, "boss");

    setTable(obj);
  }, [bossFilings]);

  useEffect(() => {
    let obj = { ...table };
    obj["rod"]["total"] = rodFilings?.data?.totalCount;
    getData(rodFilings?.data?.rows, "rod");

    setTable(obj);
  }, [rodFilings]);

  function formatTable(data) {
    let overall = [];
    data.map((s, i) => {
      let obj = {
        key: i,
        entityName: s?.name ?? "N/A",
        entityCode: s?.code ?? "N/A",
        entityStatus: s?.status ?? "N/A",
        incorpDate: s?.cddInformation?.nextReviewed
          ? moment(s?.cddInformation?.nextReviewed).format("DD-MM-YYYY")
          : "N/A",
        incorporationNumber: s?.incorporationNumber ?? "N/A",
        incorpBatch: s?.incorpBatch ?? "N/A",
        masterfileName: s?.name ?? "N/A",
        masterfileCode: s?.code ?? "N/A",
        parentClientName: s?.clientName ?? "N/A",
        parentClientCode: s?.clientCode ?? "N/A",
        filingType: s?.filingType ?? "N/A",
        countOfReminders: "N/A",
        deadlineBreach: s?.deadlineBreach ?? "N",
        filingStatus: s?.filingStatus ?? "N",
        fscStatus: "N/A",
      };

      overall.push(obj);
    });
    return overall;
  }

  async function getData(rows, key) {
    let obj = { ...table };
    obj[key]["loading"] = true;

    if (rows?.length > 0) {
      let data = await formatTable(rows);

      obj[key]["loading"] = false;
      obj[key]["data"] = data;
    }
    setTable(obj);
  }

  const onExportClick = (fileName, key) => {
    const exportData = table[key]["data"].map((item) => {
      let obj = {
        "Entity Name": item.entityName,
        "Entity Code": item.entityCode,
        "Incorp Date": item.incorpDate,
        "Incorporation Number": item.incorporationNumber,
        "Incorp Batch": item.incorpBatch,
        "Masterfile Name": item.masterfileName,
        "Masterfile Code": item.masterfileCode,
        "ParentClient Name": item.parentClientName,
        "ParentClient Code": item.parentClientCode,
        "Filing Type": item.filingType,
        "Count of Reminders": item.countOfReminders,
        "Deadline Breach": item.deadlineBreach,
        "Filing Status": item.filingStatus,
        "FSC Status": item.fscStatus,
      };
      return obj;
    });
    downloadAsCSV(exportData, fileName);
  };

  const tableColumns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id.localeCompare(b.id),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date.localeCompare(b.date),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Analysis Report",
      dataIndex: "analysisReport",
      sorter: (a, b) => a.analysisReport.localeCompare(b.analysisReport),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
      sorter: (a, b) => a.updatedBy.localeCompare(b.updatedBy),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  const tableData2 = [
    {
      id: "001",
      date: "12/04/2024 - 03:24PM",
      raf: "",
      analysisReport: "Sample",
      updatedBy: "Guru",
    },
    {
      id: "002",
      date: "11/04/2024 - 02:53PM",
      raf: "",
      analysisReport: "Sample",
      updatedBy: "Guru",
    },
    {
      id: "003",
      date: "10/04/2024 - 10:01AM",
      raf: "",
      analysisReport: "Sample",
      updatedBy: "Guru",
    },
    {
      id: "004",
      date: "10/04/2024 - 09:24AM",
      raf: "",
      analysisReport: "Sample",
      updatedBy: "Guru",
    },
    {
      id: "005",
      date: "05/04/2024 - 11:12AM",
      raf: "",
      analysisReport: "Sample",
      updatedBy: "Guru",
    },
  ];

  const onPagination = (page, pageSize, key) => {
    let obj = { ...query };
    obj[key] = {
      _limit: pageSize,
      _start: (page - 1) * pageSize,
    };
    setQuery(obj);
  };

  function openModal(id, key) {
    setModalData(table[key]?.data?.[id]);
    setToggleUpdate(true);
  }

  return (
    <div>
      <div className="page-header">
        <h1>Filing</h1>
      </div>
      <div className="page-content">
        <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-3">
          <h6 className="primary-title mb-0">Annual Return Filing</h6>
          <button
            className="primary-btn"
            onClick={() => onExportClick("filling-report", "annual")}
          >
            <i className="fal fa-file-download"></i> Export
          </button>
        </div>
        <Table
          className="custom-table mb-3"
          loading={annualFilings?.isLoading}
          columns={annualColumns}
          dataSource={table["annual"]["data"]}
          scroll={{ x: 2400 }}
          pagination={{
            className: "custom-pagination",
            itemRender: customPagination,
            defaultCurrent: 1,
            total: table["annual"]["total"],
            onChange: (page, pageSize) =>
              onPagination(page, pageSize, "annual"),
          }}
        />
        <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-3">
          <h6 className="primary-title mb-0">Boss Filing Summary</h6>
          <button
            className="primary-btn"
            onClick={() => onExportClick("boss-filling-summary", "boss")}
          >
            <i className="fal fa-file-download"></i> Export
          </button>
        </div>
        <Table
          className="custom-table mb-3"
          loading={bossFilings?.isLoading}
          columns={bossColumns}
          dataSource={table["boss"]["data"]}
          scroll={{ x: 2400 }}
          pagination={{
            className: "custom-pagination",
            itemRender: customPagination,
            defaultCurrent: 1,
            total: table["boss"]["total"],
            onChange: (page, pageSize) => onPagination(page, pageSize, "boss"),
          }}
        />
        <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-3">
          <h6 className="primary-title mb-0">ROD Filing</h6>
          <button
            className="primary-btn"
            onClick={() => onExportClick("rod-filling", "rod")}
          >
            <i className="fal fa-file-download"></i> Export
          </button>
        </div>
        <Table
          className="custom-table mb-3"
          loading={rodFilings?.isLoading}
          columns={rodColumns}
          dataSource={table["rod"]["data"]}
          scroll={{ x: 2400 }}
          pagination={{
            className: "custom-pagination",
            itemRender: customPagination,
            defaultCurrent: 1,
            total: table["rod"]["total"],
            onChange: (page, pageSize) => onPagination(page, pageSize, "rod"),
          }}
        />
        <Modal
          title={"FSC Update"}
          className="custom-modal"
          open={toggleUpdate}
          width={1000}
          onOk={() => setToggleUpdate(false)}
          okText="Okay"
          onCancel={() => setToggleUpdate(false)}
        >
          <div className="row overview-listing mb-2">
            <div className="col-md-4">
              <label htmlFor="">Entity Name:</label>
              <p className="box-style">{modalData?.entityName}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="">Entity Code:</label>
              <p className="box-style">{modalData?.entityCode}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="">Incorp Date:</label>
              <p className="box-style">{modalData?.incorpDate}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="">Incorporation Number:</label>
              <p className="box-style">{modalData?.incorporationNumber}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="">Incorp Batch:</label>
              <p className="box-style">{modalData?.incorpBatch}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="">Masterfile Name:</label>
              <p className="box-style">{modalData?.masterfileName}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="">Masterfile Code:</label>
              <p className="box-style">{modalData?.masterfileCode}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="">ParentClient Name:</label>
              <p className="box-style">{modalData?.parentClientName}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="">ParentClient Code:</label>
              <p className="box-style">{modalData?.parentClientCode}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="">Filing Type:</label>
              <p className="box-style">{modalData?.filingType}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="">Count of Reminders:</label>
              <p className="box-style">{modalData?.countOfReminders}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="">Deadline Breach:</label>
              <p className="box-style">{modalData?.deadlineBreach}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="">Filing Status:</label>
              <p className="box-style">{modalData?.filingStatus}</p>
            </div>
            <div className="col-md-4">
              <label htmlFor="">FSC Status:</label>
              <p className="box-style">{modalData?.fscStatus}</p>
            </div>
          </div>
        </Modal>
        <Modal
          title={"History"}
          className="custom-modal"
          open={openHistory}
          width={800}
          onOk={() => setOpenHistory(false)}
          okText="Submit"
          onCancel={() => setOpenHistory(false)}
        >
          <div>
            <Table
              className="custom-table"
              columns={tableColumns}
              dataSource={tableData2}
              pagination={false}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
}
