import React, { useState, useEffect, useRef } from "react";
import { Modal } from "antd";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../../store/user/userSlice";
import { customPagination, fetchApi, sendNotify, getQuery } from "../../helper";
import FormZ from "../../components/FormZ";
import { getManageRoles } from "../../store/manageRoles/manageRolesSlice";

const { confirm, error } = Modal;

export default function Crud() {
  let query = getQuery();
  let { id } = useParams();
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const manageRoles = useSelector((state) => state.manageRoles);
  const user = useSelector((state) => state.user);
  const [profileData, setProfileData] = useState({});
  const [roleData, setRoleData] = useState({});
  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });
  const [status, setStatus] = useState("Inactive");
  const [roles, setRoles] = useState([]);
  const profileFormRef = useRef();
  const roleFormRef = useRef();

  useEffect(() => {
    dispatch(getManageRoles(queryParams));
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getUser({ _start: 0, _limit: 1, id: id }));
      setEditMode(true);
    }
  }, [id]);

  useEffect(() => {
    handleRefresh();
  }, [manageRoles, user]);

  const SelectComponent = (title) => {
    const roleList = [
      { label: "Entity Point of Contact", value: "point-of-contact" },
      { label: "Analyst", value: "analyst" },
      { label: "Admin", value: "admin" },
      { label: "MLRO", value: "mlro" },
      { label: "Lead / Manager", value: "lead" },
      { label: "End User Client", value: "end-user" },
      { label: "Introducer Client", value: "introducer-client" },
      { label: "Client", value: "client" },
    ];
    const roleTitle = roleList.find((a) => a.value === title);
    return roleTitle ? roleTitle.label : "Unknown Role";
  };
  const handleRefresh = async () => {
    if (manageRoles?.data?.rows) {
      let list = manageRoles.data.rows.map((d) => {
        let obj = {
          value: `${d?._id},${d?.title}`,
          label: SelectComponent(d?.title),
        };
        return obj;
      });
      setRoles(list);
    }

    if (id && user && user?.data?.data) {
      let data = user?.data?.data?.[0];
      let profileObj = {
        username: data?.name,
        email: data?.mail,
        phoneNumber: data?.number,
        lastName: data?.lastName,
        firstName: data?.firstName,
      };
      setProfileData(profileObj);
      setRoleData({
        userRole: `${data.typeId},${query.role}`,
        status: data.status,
      });
      updateisReq();
      console.log(user?.data?.data?.[0], "gdsdxhfcvkjblnl");
    }
  };
  // const roles = [
  //   {
  //     value: "Processor",
  //     label: "Processor",
  //   },
  //   {
  //     value: "Analyst",
  //     label: "Analyst",
  //   },
  //   {
  //     value: "Admin",
  //     label: "Admin",
  //   },
  //   {
  //     value: "MLRO",
  //     label: "MLRO",
  //   },
  //   {
  //     value: "Lead",
  //     label: "Lead",
  //   },
  // ];

  let profileFormSchema = [
    {
      name: "email",
      value: "",
      type: "email",
      label: "Email",
      placeholder: "Enter email address",
      customClass: "col-md-10",
      required: true,
      disabled: editMode,
    },
    {
      name: "username",
      value: "",
      type: "text",
      label: "Username",
      placeholder: "Enter username",
      required: true,
      disabled: editMode,
    },
    {
      name: "phoneNumber",
      value: "",
      type: "type",
      label: "Phone Number",
      placeholder: "Enter phone number",
      customClass: "hide-arrows",
      required: true,
      disabled: editMode,
    },
    {
      name: "firstName",
      value: "",
      type: "text",
      label: "First Name",
      placeholder: "Enter first name",
      required: true,
      disabled: editMode,
    },
    {
      name: "lastName",
      value: "",
      type: "text",
      label: "Last Name",
      placeholder: "Enter last name",
      required: true,
      disabled: editMode,
    },
  ];

  let roleFormSchema = [
    {
      name: "userRole",
      value: null,
      type: "select",
      options: roles,
      label: "User Role",
      placeholder: "Select user role",
      required: true,
      disabled: editMode,
    },
    {
      name: "status",
      value: "",
      type: "select",
      options: [
        {
          value: "Active",
          label: "Active",
        },
        {
          value: "Inactive",
          label: "Inactive",
        },
        {
          value: "Hold",
          label: "Hold",
        },
      ],

      label: "Access",
      placeholder: "Select Action",
      //required: true,
      disabled: !editMode,
    },
  ];

  const confirmResetPass = () => {
    confirm({
      icon: (
        <div className="modal-icon">
          <i className="fas fa-lock"></i>
        </div>
      ),
      title: <h5>Reset Password</h5>,
      content: (
        <p>
          Are you sure you want to reset this user’s password? This action
          cannot be undone.
        </p>
      ),
      className: "custom-confirm",
      centered: true,
      okText: "Reset Password",
      closable: true,
      onOk() {
        sendmail();
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };
  const sendmail = () => {
    let obj = {
      email: user?.data?.data?.[0].mail,
    };
    let payload = {
      method: "post",
      url: "/admin/sendreset-request",
      data: obj,
    };
    fetchApi(payload);
  };
  const confirmDelete = () => {
    error({
      title: <h5>Delete User</h5>,
      content: (
        <p>
          Are you sure you want to delete this user? All associated data will be
          permanently removed.
        </p>
      ),
      className: "custom-confirm",
      centered: true,
      okText: "Delete",
      okButtonProps: { className: "danger-btn" },
      closable: true,
      onOk() {
        // logout();

        onSubmit("Delete");
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };
  const onSubmit = async (value) => {
    await saveData(value);
  };
  const saveData = async (value) => {
    const validRole = roleFormRef.current.validForm();
    const validProfile = profileFormRef.current.validForm();
    if (!validRole || !validProfile) {
      sendNotify("error", "Some fields are missing!");
      return;
    }

    const roleObj = roleFormRef.current.getPayload();
    const profileObj = profileFormRef.current.getPayload();
    let parts = roleObj?.userRole?.split(",");

    let obj = {
      name: profileObj.username,
      mail: profileObj.email,
      pass: `${profileObj.username}@${parts[1]}`,
      role: parts[1],
      number: profileObj.phoneNumber,
      lastName: profileObj.lastName,
      firstName: profileObj.firstName,
      status:
        value === "Delete"
          ? "Delete"
          : status === "Active"
          ? "Active"
          : roleObj?.status || "Inactive",
      typeId: parts[0],
      isReq: false,
    };

    const payload = {
      method: id ? "PUT" : "POST",
      url: id ? `/auth/update/${id}` : `/auth`,
      data: obj,
    };
    fetchApi(payload)
      .then((res) => {
        if (res?.status == 200 || res?.status == 201) {
          sendNotify("success", res?.message);
          dispatch(getUser({ _start: 0, _limit: 1, id: id }));
          dispatch(getManageRoles(queryParams));
          roleFormRef.current.clearForm();
          profileFormRef.current.clearForm();
          handleRefresh();
        } else {
          sendNotify("error", res?.message);
        }
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };
  const updateisReq = async () => {
    if (user?.data?.data?.[0]?.isReq == false) {
      if (query && query?.isReq) {
        const payload = {
          method: "GET",
          url: `/auth/readUpdate/${id}`,
        };
        fetchApi(payload)
          .then((res) => {
            if (res?.status == 200 || res?.status == 201) {
              dispatch(getUser({ _start: 0, _limit: 1, id: id }));
            } else {
              sendNotify("error", res?.message);
            }
          })
          .catch((error) => {
            sendNotify("error", error?.message);
          });
      }
    }
  };
  return (
    <div>
      <div className="page-header">
        <div>
          <h1 className="mb-1">{editMode ? "Edit" : "Add"} User</h1>
          <p className="mb-0">
            {editMode ? "Edit" : "Add"} a new user profile.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="row">
          <div className="custom-form col-md-8">
            <FormZ
              formClass="row gy-4 mb-4"
              childClass="col-md-5 mb-0"
              labelClass="mb-1"
              inputClass="w-100"
              formData={profileData}
              formSchema={profileFormSchema}
              ref={profileFormRef}
            />
            <hr />
            <div className="my-4">
              <h6 className="form-title mb-1">Access Control</h6>
              <p className="description">Define user role and access level.</p>

              <div className="mt-4">
                <FormZ
                  formClass="row gy-4 mb-4"
                  childClass="col-md-5 mb-0"
                  labelClass="mb-1"
                  inputClass="w-100"
                  formData={roleData}
                  formSchema={roleFormSchema}
                  ref={roleFormRef}
                />
                <div className="col-md-12 mt-4">
                  {editMode ? (
                    <button className="danger-btn" onClick={onSubmit}>
                      Change Access
                    </button>
                  ) : (
                    <button
                      className="success-btn"
                      onClick={() => setStatus("Active")}
                    >
                      Grant Access
                    </button>
                  )}
                </div>
              </div>
            </div>
            <hr />
            {!editMode && (
              <div className="d-flex justify-content-end gap-3 mt-4">
                <button className="secondary-btn">Cancel</button>
                <button className="primary-btn" onClick={onSubmit}>
                  Add User
                </button>
              </div>
            )}
            {editMode && (
              <div className="my-4">
                <h6 className="form-title mb-1">Reset Password</h6>
                <p className="description mb-4">
                  If a user has forgotten their password or needs a reset due to
                  security protocols.
                </p>
                <button
                  className="primary-btn"
                  disabled={user?.data?.data?.[0].isReq ? false : true}
                  onClick={confirmResetPass}
                >
                  Reset password
                </button>
              </div>
            )}
            <hr />
            {editMode && (
              <div className="my-4">
                <h6 className="form-title mb-1">Delete User</h6>
                <p className="description mb-4">
                  Remove user and all their related data from the system.
                </p>
                <button className="danger-btn" onClick={confirmDelete}>
                  Delete User
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
