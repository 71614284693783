import React, { useState, useEffect, useRef } from "react";
import { Table, Tag, Select, Modal, DatePicker, Checkbox } from "antd";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

import FormZ from "../../components/FormZ";
import TableSortArrows from "../../components/tableSortArrows";

import {
  Each,
  getOptions,
  sendNotify,
  fetchApi,
  downloadAsCSV,
  isArrayEmpty,
  convertQueryParams,
} from "../../helper";

export default function FeeScheduleCreate() {
  const [editMode, setEditMode] = useState(false);
  const [clientArray, setClientArray] = useState([]);
  const [isDiscounted, setIsDiscounted] = useState(false);
  const [isStandard, setIsStandard] = useState(false);
  const [formData, setFormData] = useState({});
  useEffect(() => {
    getClient();
  }, []);
  const getClient = async () => {
    const fetchData = async (url) => {
      const payload = { method: "GET", url };
      return fetchApi(payload).then((res) => res.data.rows);
    };

    const params = convertQueryParams({
      isAll: true,
      alltype: ["client", "introducerClient", "end-user"],
      options: true,
    });
    const clientlist = await fetchData(`/client?${params}`);
    console.log(clientlist, "dgyjdtukmt");

    setClientArray(clientlist);
  };
  const [checkedList, setCheckedList] = useState({
    standard: {
      checked: false,
      child: { up: false, above: false },
    },
    discounted: {
      checked: false,
      child: { up: false, above: false },
    },
  });

  const searchFormRef = useRef();

  let searchFormSchema = [
    {
      name: "clientCode",
      value: "",
      type: "select",
      label: "End User/Introducer Client",
      placeholder: "Select end user client",
      showSearch: true,
      //options: clientArray,
    },
    {
      name: "feeScheduleName",
      value: "",
      type: "text",
      label: "Fee Schedule Name",
      placeholder: "Select schedule name",
    },
    {
      name: "paymentCategory",
      value: "",
      type: "select",
      label: "Payment Category",
      placeholder: "Select payment category",
      options: [
        {
          value: "Pre-Paid",
          label: "Pre-paid",
        },
        {
          value: "Post-Paid",
          label: "Post-paid",
        },
      ],
    },
  ];

  const [shares, setShares] = useState([
    {
      task: "Incorporation",
      type: "Pre-incrop",
      code: "0001",
      standardUpTo: 1600.0,
      standardAbove: 2400.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Government fee ($550 + $100) 0r ($1350 + $100)",
      type: "Pre-incrop",
      code: "0002",
      standardUpTo: 650.0,
      standardAbove: 1450.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Registered Office Registered Agent fee",
      type: "Pre-incrop",
      code: "0003",
      standardUpTo: 375.0,
      standardAbove: 375.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Filing Register of Directors",
      type: "Pre-incrop",
      code: "0004",
      standardUpTo: 175.0,
      standardAbove: 175.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Filing BOSS",
      type: "Pre-incrop",
      code: "0005",
      standardUpTo: 175.0,
      standardAbove: 175.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Compliance / Administrative and Sundry Charges",
      type: "Pre-incrop",
      code: "0006",
      standardUpTo: 225.0,
      standardAbove: 225.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Optional Charges",
      type: "Pre-incrop",
      code: "0007",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Courier charges (Variable -destination/weight sensitive)",
      type: "Pre-incrop",
      code: "0008",
      standardUpTo: 200.0,
      standardAbove: 200.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Physical Copy of Certificate of Incorporation",
      type: "Pre-incrop",
      code: "0009",
      standardUpTo: 50.0,
      standardAbove: 50.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Corporate Seal",
      type: "Pre-incrop",
      code: "0010",
      standardUpTo: 70.0,
      standardAbove: 70.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Government licence fee",
      type: "Pre-incrop",
      code: "0011",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Registered Office Registered Agent fee",
      type: "Pre-incrop",
      code: "0012",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Economic Substance Administration & Filing Fees",
      type: "Pre-incrop",
      code: "0013",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Annual Return Administration & Maintenance Fees",
      type: "Pre-incrop",
      code: "0014",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Compliance/Administrative & Sundry charges",
      type: "Pre-incrop",
      code: "0015",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },

    {
      task: "Government fee- Limited Partnerships",
      type: "Pre-incrop",
      code: "0016",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "CCP Service Fee- Limited Partnerships",
      type: "Pre-incrop",
      code: "0017",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Registered Office Registered Agent fee",
      type: "Pre-incrop",
      code: "0018",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Post Incorporation Services",
      type: "Pre-incrop",
      code: "0019",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Alteration to the Memorandum & Articles of Association",
      type: "Pre-incrop",
      code: "0020",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Apostilles",
      type: "Pre-incrop",
      code: "0021",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Certificate of Good Standing",
      type: "Pre-incrop",
      code: "0022",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Registry Certified Copy of Any Document",
      type: "Pre-incrop",
      code: "0023",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Registry Uncertified or Duplicate Copy of Any Document",
      type: "Pre-incrop",
      code: "0024",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Registry Physical Copy of any Electronic Certificate",
      type: "Pre-incrop",
      code: "0025",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Certificate of Incumbency",
      type: "Pre-incrop",
      code: "0026",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "CCP Certified or Duplicate copies of documents",
      type: "Pre-incrop",
      code: "0027",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Amendments to Register of Directors",
      type: "Pre-incrop",
      code: "0028",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Amendments to Register of Members & Issuing New Share",
      type: "Pre-incrop",
      code: "0029",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Certificate(s)",
      type: "Pre-incrop",
      code: "0030",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },

    {
      task: "Continuations",
      type: "Pre-incrop",
      code: "0031",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Drafting Resolutions",
      type: "Pre-incrop",
      code: "0032",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Filing Register of Mortgage & Charges",
      type: "Pre-incrop",
      code: "0033",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },

    {
      task: "Filing Appointment of RO/RA",
      type: "Pre-incrop",
      code: "0034",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Filing Transfer Out",
      type: "Pre-incrop",
      code: "0035",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Indemnity Agreements",
      type: "Pre-incrop",
      code: "0036",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Notarizations",
      type: "Pre-incrop",
      code: "0037",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },

    {
      task: "Powers of Attorney",
      type: "Pre-incrop",
      code: "0038",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Reactivation of struckoff companies",
      type: "Pre-incrop",
      code: "0039",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Registration of Notice of Resignation as Registered Agent",
      type: "Pre-incrop",
      code: "0040",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Filing notice of Recission as Registered Agent",
      type: "Pre-incrop",
      code: "0041",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Registration as a Foreign Company in the BVI",
      type: "Pre-incrop",
      code: "0042",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Filing Notice of Foreign Company Ceasing to Do Business in the BVI",
      type: "Pre-incrop",
      code: "0043",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Expedite Fee for Fast Track Due Diligence and Incorporation",
      type: "Pre-incrop",
      code: "0044",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
  ]);
  const handleOnChange = async (value) => {
    setFormData(value);
  };
  const getShareChange = (index, key, value) => {
    let arr = [...shares];
    arr[index][key] = value;
    setShares(arr);
  };

  const getChecked = (checked, key, child) => {
    if (key == "standard") {
      setIsDiscounted(!isDiscounted);
    } else if (key == "discounted") {
      setIsStandard(!isStandard);
    }
    let obj = { ...checkedList };
    if (child) {
      obj[key]["child"][child] = checked;
    } else {
      obj[key]["checked"] = checked;
      if (!checked) {
        obj[key]["child"]["up"] = false;
        obj[key]["child"]["above"] = false;
      }
    }
    setCheckedList(obj);
  };

  const columns = [
    {
      title: "Request Type",
      dataIndex: "reqType",
      sorter: (a, b) => a.reqType - b.reqType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 130,
    },
    {
      title: "Task",
      dataIndex: "task",
      sorter: (a, b) => a.task - b.task,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 160,
    },
    {
      title: "Service Code",
      dataIndex: "serviceCode",
      sorter: (a, b) => a.serviceCode - b.serviceCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 160,
    },
    {
      title: (
        <Checkbox
          className="custom-checkbox"
          checked={checkedList["standard"]["checked"]}
          onChange={(e) => getChecked(e.target.checked, "standard")}
          disabled={isStandard}
        >
          Standard Fee
        </Checkbox>
      ),
      children: [
        {
          title: (
            <div className="text-center">
              <Checkbox
                disabled={!checkedList["standard"]["checked"]}
                className="custom-checkbox"
                checked={checkedList["standard"]["child"]["up"]}
                onChange={(e) => getChecked(e.target.checked, "standard", "up")}
              >
                Up to 50,000 Shares
              </Checkbox>
            </div>
          ),
          dataIndex: "standardUpTo",
          key: "standardUpTo",
          render: (_, { id, standardUpTo }) => (
            <div className="custom-form">
              {checkedList["standard"]["child"]["up"] ? (
                <input
                  type="number"
                  className="w-75"
                  value={shares[id].standardUpTo}
                  onChange={(e) =>
                    getShareChange(id, "standardUpTo", e.target.value)
                  }
                />
              ) : (
                <div></div>
              )}
            </div>
          ),
        },
        {
          title: (
            <div className="text-center">
              <Checkbox
                disabled={!checkedList["standard"]["checked"]}
                className="custom-checkbox"
                checked={checkedList["standard"]["child"]["above"]}
                onChange={(e) =>
                  getChecked(e.target.checked, "standard", "above")
                }
              >
                Above 50,000 Shares
              </Checkbox>
            </div>
          ),
          dataIndex: "standardAbove",
          key: "standardAbove",
          render: (_, { id, standardAbove }) => (
            <div className="custom-form">
              {checkedList["standard"]["child"]["above"] ? (
                <input
                  type="number"
                  className="w-75"
                  value={shares[id].standardAbove}
                  onChange={(e) =>
                    getShareChange(id, "standardAbove", e.target.value)
                  }
                />
              ) : (
                <div></div>
              )}
            </div>
          ),
        },
      ],
    },
    {
      title: (
        <Checkbox
          className="custom-checkbox"
          checked={checkedList["discounted"]["checked"]}
          onChange={(e) => getChecked(e.target.checked, "discounted")}
          disabled={isDiscounted}
        >
          Discounted Fee
        </Checkbox>
      ),
      children: [
        {
          title: (
            <div className="text-center">
              <Checkbox
                disabled={!checkedList["discounted"]["checked"]}
                className="custom-checkbox"
                checked={checkedList["discounted"]["child"]["up"]}
                onChange={(e) =>
                  getChecked(e.target.checked, "discounted", "up")
                }
              >
                Up to 50,000 Shares
              </Checkbox>
            </div>
          ),
          dataIndex: "discountedUpTo",
          key: "discountedUpTo",
          render: (_, { id, discountedUpTo }) => (
            <div className="custom-form">
              {checkedList["discounted"]["child"]["up"] ? (
                <input
                  type="number"
                  className="w-75"
                  value={shares[id].discountedUpTo}
                  onChange={(e) =>
                    getShareChange(id, "discountedUpTo", e.target.value)
                  }
                />
              ) : (
                <div></div>
              )}
            </div>
          ),
        },
        {
          title: (
            <div className="text-center">
              <Checkbox
                disabled={!checkedList["discounted"]["checked"]}
                className="custom-checkbox"
                checked={checkedList["discounted"]["child"]["above"]}
                onChange={(e) =>
                  getChecked(e.target.checked, "discounted", "above")
                }
              >
                Above 50,000 Shares
              </Checkbox>
            </div>
          ),
          dataIndex: "discountedAbove",
          key: "discountedAbove",
          render: (_, { id, discountedAbove }) => (
            <div className="custom-form">
              {checkedList["discounted"]["child"]["above"] ? (
                <input
                  type="number"
                  className="w-75"
                  value={shares[id].discountedAbove}
                  onChange={(e) =>
                    getShareChange(id, "discountedAbove", e.target.value)
                  }
                />
              ) : (
                <div></div>
              )}
            </div>
          ),
        },
      ],
    },
  ];

  const data = [
    {
      key: "1",
      id: 0,
      reqType: "Pre-incrop",
      task: "Incorporation of a company",
      serviceCode: "0001",
      ...shares[0],
    },
    {
      key: "2",
      id: 1,
      reqType: "Post-incrop",
      task: "Certificate of Incumbency",
      serviceCode: "0002",
      ...shares[1],
    },
    {
      key: "3",
      id: 2,
      reqType: "Post-incrop",
      task: "Good Standing",
      serviceCode: "0003",
      ...shares[2],
    },
    {
      key: "4",
      id: 3,
      reqType: "Post-incrop",
      task: "Change of Director",
      serviceCode: "0004",
      ...shares[3],
    },
    {
      key: "5",
      id: 4,
      reqType: "Post-incrop",
      task: "Change of Shareholders",
      serviceCode: "0005",
      ...shares[4],
    },
    {
      key: "6",
      id: 5,
      reqType: "Post-incrop",
      task: "Alteration to M&A",
      serviceCode: "0006",
      ...shares[5],
    },
    {
      key: "7",
      id: 6,
      reqType: "Post-incrop",
      task: "M&A Name Change",
      serviceCode: "0007",
      ...shares[6],
    },
    {
      key: "8",
      id: 7,
      reqType: "Post-incrop",
      task: "Notarization of a documents",
      serviceCode: "0008",
      ...shares[7],
    },
    {
      key: "9",
      id: 8,
      reqType: "Post-incrop",
      task: "Apostille of a document",
      serviceCode: "0009",
      ...shares[8],
    },
    {
      key: "10",
      id: 9,
      reqType: "Post-incrop",
      task: "Drafting Resolution",
      serviceCode: "0010",
      ...shares[9],
    },
    {
      key: "11",
      id: 10,
      reqType: "Post-incrop",
      task: "Request to provide Corporate documents certified",
      serviceCode: "0011",
      ...shares[10],
    },
    {
      key: "12",
      id: 11,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "0012",
      ...shares[11],
    },

    {
      key: "13",
      id: 12,
      reqType: "Post-incrop",
      task: "Good Standing",
      serviceCode: "0013",
      ...shares[12],
    },
    {
      key: "14",
      id: 13,
      reqType: "Post-incrop",
      task: "Change of Director",
      serviceCode: "0014",
      ...shares[13],
    },
    {
      key: "15",
      id: 14,
      reqType: "Post-incrop",
      task: "Change of Shareholders",
      serviceCode: "0015",
      ...shares[14],
    },
    {
      key: "16",
      id: 15,
      reqType: "Post-incrop",
      task: "Alteration to M&A",
      serviceCode: "0016",
      ...shares[15],
    },
    {
      key: "17",
      id: 16,
      reqType: "Post-incrop",
      task: "M&A Name Change",
      serviceCode: "0017",
      ...shares[16],
    },
    {
      key: "18",
      id: 17,
      reqType: "Post-incrop",
      task: "Notarization of a documents",
      serviceCode: "0018",
      ...shares[17],
    },
    {
      key: "19",
      id: 18,
      reqType: "Post-incrop",
      task: "Apostille of a document",
      serviceCode: "0019",
      ...shares[18],
    },
    {
      key: "20",
      id: 19,
      reqType: "Post-incrop",
      task: "Drafting Resolution",
      serviceCode: "0020",
      ...shares[19],
    },
    {
      key: "21",
      id: 20,
      reqType: "Post-incrop",
      task: "Request to provide Corporate documents certified",
      serviceCode: "0021",
      ...shares[20],
    },
    {
      key: "22",
      id: 21,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "0022",
      ...shares[21],
    },
    {
      key: "23",
      id: 22,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "0023",
      ...shares[22],
    },

    {
      key: "24",
      id: 23,
      reqType: "Post-incrop",
      task: "Good Standing",
      serviceCode: "0024",
      ...shares[23],
    },
    {
      key: "25",
      id: 24,
      reqType: "Post-incrop",
      task: "Change of Director",
      serviceCode: "0025",
      ...shares[24],
    },
    {
      key: "26",
      id: 25,
      reqType: "Post-incrop",
      task: "Change of Shareholders",
      serviceCode: "0026",
      ...shares[25],
    },
    {
      key: "27",
      id: 26,
      reqType: "Post-incrop",
      task: "Alteration to M&A",
      serviceCode: "0027",
      ...shares[26],
    },
    {
      key: "28",
      id: 27,
      reqType: "Post-incrop",
      task: "M&A Name Change",
      serviceCode: "0028",
      ...shares[27],
    },
    {
      key: "29",
      id: 28,
      reqType: "Post-incrop",
      task: "Notarization of a documents",
      serviceCode: "0029",
      ...shares[28],
    },
    {
      key: "30",
      id: 29,
      reqType: "Post-incrop",
      task: "Apostille of a document",
      serviceCode: "0030",
      ...shares[29],
    },
    {
      key: "31",
      id: 30,
      reqType: "Post-incrop",
      task: "Drafting Resolution",
      serviceCode: "0031",
      ...shares[30],
    },
    {
      key: "32",
      id: 31,
      reqType: "Post-incrop",
      task: "Request to provide Corporate documents certified",
      serviceCode: "0032",
      ...shares[31],
    },
    {
      key: "33",
      id: 32,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "0033",
      ...shares[32],
    },
    {
      key: "34",
      id: 33,
      reqType: "Post-incrop",
      task: "Request to provide Corporate documents certified",
      serviceCode: "0034",
      ...shares[33],
    },
    {
      key: "35",
      id: 34,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "0035",
      ...shares[34],
    },
    {
      key: "36",
      id: 35,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "0036",
      ...shares[35],
    },
    {
      key: "37",
      id: 36,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "0037",
      ...shares[36],
    },
    {
      key: "38",
      id: 37,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "0038",
      ...shares[37],
    },
    {
      key: "39",
      id: 38,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "0039",
      ...shares[38],
    },
    {
      key: "40",
      id: 39,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "0040",
      ...shares[39],
    },
    {
      key: "41",
      id: 40,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "0041",
      ...shares[40],
    },
    {
      key: "42",
      id: 41,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "0042",
      ...shares[41],
    },
    {
      key: "43",
      id: 42,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "0043",
      ...shares[42],
    },
    {
      key: "44",
      id: 43,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "0044",
      ...shares[43],
    },
  ];

  const onSubmit = () => {
    if (searchFormRef.current.validForm()) {
      let options = searchFormRef.current.getPayload();

      let oldArray = [
        {
          key: "1",
          id: 0,
          reqType: "Pre-incrop",
          task: "Incorporation of a company",
          serviceCode: "0001",
          ...shares[0],
        },
        {
          key: "2",
          id: 1,
          reqType: "Post-incrop",
          task: "Certificate of Incumbency",
          serviceCode: "0002",
          ...shares[1],
        },
        {
          key: "3",
          id: 2,
          reqType: "Post-incrop",
          task: "Good Standing",
          serviceCode: "0003",
          ...shares[2],
        },
        {
          key: "4",
          id: 3,
          reqType: "Post-incrop",
          task: "Change of Director",
          serviceCode: "0004",
          ...shares[3],
        },
        {
          key: "5",
          id: 4,
          reqType: "Post-incrop",
          task: "Change of Shareholders",
          serviceCode: "0005",
          ...shares[4],
        },
        {
          key: "6",
          id: 5,
          reqType: "Post-incrop",
          task: "Alteration to M&A",
          serviceCode: "0006",
          ...shares[5],
        },
        {
          key: "7",
          id: 6,
          reqType: "Post-incrop",
          task: "M&A Name Change",
          serviceCode: "0007",
          ...shares[6],
        },
        {
          key: "8",
          id: 7,
          reqType: "Post-incrop",
          task: "Notarization of a documents",
          serviceCode: "0008",
          ...shares[7],
        },
        {
          key: "9",
          id: 8,
          reqType: "Post-incrop",
          task: "Apostille of a document",
          serviceCode: "0009",
          ...shares[8],
        },
        {
          key: "10",
          id: 9,
          reqType: "Post-incrop",
          task: "Drafting Resolution",
          serviceCode: "0010",
          ...shares[9],
        },
        {
          key: "11",
          id: 10,
          reqType: "Post-incrop",
          task: "Request to provide Corporate documents certified",
          serviceCode: "0011",
          ...shares[10],
        },
        {
          key: "12",
          id: 11,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "0012",
          ...shares[11],
        },

        {
          key: "13",
          id: 12,
          reqType: "Post-incrop",
          task: "Good Standing",
          serviceCode: "0013",
          ...shares[12],
        },
        {
          key: "14",
          id: 13,
          reqType: "Post-incrop",
          task: "Change of Director",
          serviceCode: "0014",
          ...shares[13],
        },
        {
          key: "15",
          id: 14,
          reqType: "Post-incrop",
          task: "Change of Shareholders",
          serviceCode: "0015",
          ...shares[14],
        },
        {
          key: "16",
          id: 15,
          reqType: "Post-incrop",
          task: "Alteration to M&A",
          serviceCode: "0016",
          ...shares[15],
        },
        {
          key: "17",
          id: 16,
          reqType: "Post-incrop",
          task: "M&A Name Change",
          serviceCode: "0017",
          ...shares[16],
        },
        {
          key: "18",
          id: 17,
          reqType: "Post-incrop",
          task: "Notarization of a documents",
          serviceCode: "0018",
          ...shares[17],
        },
        {
          key: "19",
          id: 18,
          reqType: "Post-incrop",
          task: "Apostille of a document",
          serviceCode: "0019",
          ...shares[18],
        },
        {
          key: "20",
          id: 19,
          reqType: "Post-incrop",
          task: "Drafting Resolution",
          serviceCode: "0020",
          ...shares[19],
        },
        {
          key: "21",
          id: 20,
          reqType: "Post-incrop",
          task: "Request to provide Corporate documents certified",
          serviceCode: "0021",
          ...shares[20],
        },
        {
          key: "22",
          id: 21,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "0022",
          ...shares[21],
        },
        {
          key: "23",
          id: 22,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "0023",
          ...shares[22],
        },

        {
          key: "24",
          id: 23,
          reqType: "Post-incrop",
          task: "Good Standing",
          serviceCode: "0024",
          ...shares[23],
        },
        {
          key: "25",
          id: 24,
          reqType: "Post-incrop",
          task: "Change of Director",
          serviceCode: "0025",
          ...shares[24],
        },
        {
          key: "26",
          id: 25,
          reqType: "Post-incrop",
          task: "Change of Shareholders",
          serviceCode: "0026",
          ...shares[25],
        },
        {
          key: "27",
          id: 26,
          reqType: "Post-incrop",
          task: "Alteration to M&A",
          serviceCode: "0027",
          ...shares[26],
        },
        {
          key: "28",
          id: 27,
          reqType: "Post-incrop",
          task: "M&A Name Change",
          serviceCode: "0028",
          ...shares[27],
        },
        {
          key: "29",
          id: 28,
          reqType: "Post-incrop",
          task: "Notarization of a documents",
          serviceCode: "0029",
          ...shares[28],
        },
        {
          key: "30",
          id: 29,
          reqType: "Post-incrop",
          task: "Apostille of a document",
          serviceCode: "0030",
          ...shares[29],
        },
        {
          key: "31",
          id: 30,
          reqType: "Post-incrop",
          task: "Drafting Resolution",
          serviceCode: "0031",
          ...shares[30],
        },
        {
          key: "32",
          id: 31,
          reqType: "Post-incrop",
          task: "Request to provide Corporate documents certified",
          serviceCode: "0032",
          ...shares[31],
        },
        {
          key: "33",
          id: 32,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "0033",
          ...shares[32],
        },
        {
          key: "34",
          id: 33,
          reqType: "Post-incrop",
          task: "Request to provide Corporate documents certified",
          serviceCode: "0034",
          ...shares[33],
        },
        {
          key: "35",
          id: 34,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "0035",
          ...shares[34],
        },
        {
          key: "36",
          id: 35,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "0036",
          ...shares[35],
        },
        {
          key: "37",
          id: 36,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "0037",
          ...shares[36],
        },
        {
          key: "38",
          id: 37,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "0038",
          ...shares[37],
        },
        {
          key: "39",
          id: 38,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "0039",
          ...shares[38],
        },
        {
          key: "40",
          id: 39,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "0040",
          ...shares[39],
        },
        {
          key: "41",
          id: 40,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "0041",
          ...shares[40],
        },
        {
          key: "42",
          id: 41,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "0042",
          ...shares[41],
        },
        {
          key: "43",
          id: 42,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "0043",
          ...shares[42],
        },
        {
          key: "44",
          id: 43,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "0044",
          ...shares[43],
        },
      ];
      let isSplus = false;
      let updateType = [];
      shares.forEach((newObj, newIndex) => {
        const oldObj = oldArray[newIndex];
        const changedKeys = [];
        for (const key in newObj) {
          if (newObj[key] !== oldObj[key] && oldObj[key] < newObj[key]) {
            changedKeys.push(key);
          }
        }

        if (changedKeys.length > 0) {
          isSplus = true;
          if (!newObj.sPlus) {
            newObj.sPlus = [];
          }
          newObj.sPlus.push({
            column: changedKeys,
            value: true,
          });
        }
        updateType.push(changedKeys);
      });

      let filteredArray = [];
      let seenArrays = new Set();
      updateType.forEach((array, index) => {
        const sortedArray = array.slice().sort();
        if (array.length > 0 && !seenArrays.has(sortedArray.join(","))) {
          filteredArray = sortedArray;
          seenArrays.add(sortedArray.join(","));
        }
      });
      let obj = {
        clientId: options.clientCode,
        name: options.feeScheduleName,
        requestType: options.paymentCategory,
        fee: shares,
        isSplus: isSplus,
        standard: checkedList.standard,
        discounted: checkedList.discounted,
        changedSplus: filteredArray,
      };

      let payload = {
        method: "POST",
        url: `/feeCategory`,
        data: obj,
      };
      let url = `/app/fee-schedule`;

      fetchApi(payload)
        .then((res) => {
          sendNotify("success", res?.message ? res?.message : "success");
          setTimeout(() => {
            window.location.href = url;
          }, 500);
        })
        .catch((error) => {
          sendNotify("error", error?.message);
        });
    } else {
      sendNotify("error", "Please enter at least one name");
    }
  };
  return (
    <div>
      <div className="page-header">
        <h1>Create Fee Schedule</h1>
        <Link to={"/app/fee-schedule"} className="primary-btn">
          <i className="fal fa-backward"></i> Back to list
        </Link>
      </div>
      <div className="page-content">
        <FormZ
          formClass="row custom-form mb-2 mb-md-5 align-items-end gy-3"
          childClass="col-md-2 mb-0"
          labelClass="mb-1"
          inputClass="w-100"
          formData={formData}
          formOptions={{ clientCode: clientArray }}
          onChange={handleOnChange}
          formSchema={searchFormSchema}
          ref={searchFormRef}
        />
        <div className="table-box">
          <Table
            className="custom-table"
            columns={columns}
            dataSource={data}
            scroll={{
              y: 240,
            }}
            pagination={false}
          />
          <div className="d-flex justify-content-end align-items-center mt-md-4">
            <button className="primary-btn px-4" onClick={() => onSubmit()}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
