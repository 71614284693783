import React, { useState, useEffect, useRef } from "react";
import { Table } from "antd";
import moment from "moment/moment";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
import html2pdf from "html2pdf.js";
import Logo from "../assets/img/logo.png";
import AddSignature from "../components/AddSignature";
const Controls = (props) => {
  const { zoomIn, zoomOut, resetTransform, centerView } = useControls();

  const downloadPDF = () => {
    props.generatePdf();

    //downloadFile(COI_PDF, "certificate-of-incumbency");
  };

  return (
    <div className="control-tools d-flex gap-3">
      <button onClick={() => zoomIn()}>
        <i className="far fa-search-plus"></i>
      </button>
      <button onClick={() => zoomOut()}>
        <i className="far fa-search-minus"></i>
      </button>
      <button onClick={downloadPDF}>
        <i className="far fa-arrow-to-bottom"></i>
      </button>
      {/* <button onClick={() => resetTransform()}>
        <i className="fal fa-times-circle"></i>
      </button>
      <button onClick={() => centerView()}>
        <i className="fal fa-arrows"></i>
      </button> */}
    </div>
  );
};

export default function CertificateOfIncumbency(props) {
  const [signModal, setSignModal] = useState(false);
  const [value, setvalue] = useState(false);
  const [report, setReport] = useState([]);
  const pdfRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    handleRefresh();
  }, [props]);

  const handleRefresh = async () => {
    let data = [];
    if (props.webSearchReport) {
      data = props.webSearchReport.map((report, index) => {
        const registration = report.attributes.registration;
        const entity = report.attributes.entity;
        return {
          key: index,
          sno: index + 1,
          name: entity.legalName.name,
          lelStatus: registration.status,
          jurisdiction: entity.jurisdiction,
          entityStatus: entity.status,
        };
      });
    }

    setReport(data);
  };

  const directorCol = [
    {
      title: "No",
      dataIndex: "sno",
    },
    {
      title: "Company Name",
      dataIndex: "name",
    },
    {
      title: "Country",
      dataIndex: "jurisdiction",
    },
    {
      title: "LEI Status",
      dataIndex: "lelStatus",
    },
    {
      title: "Entity Status",
      dataIndex: "entityStatus",
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const generatePdf = () => {
    const element = pdfRef.current;
    const specificDate = moment(Date.now()).format("YYYY-MM-DD:HH:mm:ss");
    const opt = {
      margin: 0.5,
      filename: `${props.selectedCorporateEntity}-${specificDate}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 4, logging: true, dpi: 192, letterRendering: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    html2pdf().from(element).set(opt).save();
    return opt;
  };
  // {jai -Certificate of Incumbency}
  return (
    <div>
      <div className="page-content">
        <div className="pdf-zoom-wrapper">
          <TransformWrapper initialScale={1} centerOnInit>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <div>
                <Controls
                  generatePdf={generatePdf}
                  signature={`data:image/png;base64, ${value.url}`}
                />
                <TransformComponent wrapperClass="w-100 pdf-transform">
                  <div className="custom-pdf-wrapper" ref={pdfRef}>
                    <div className="header-container">
                      <div className="header-left">
                        <img src={Logo} alt="North Lark" className="pdf-logo" />
                      </div>
                      <div className="header-center">
                        <h2>
                          NorthLark{" "}
                          <span className="header-text">Galactic</span> Search
                          Report
                        </h2>
                      </div>
                      <div className="header-right">
                        <span>{moment(Date.now()).format("DD-MMM-YYYY")}</span>
                      </div>
                    </div>
                    <div className="text-center">
                      <span className="custom-badge-light">
                        {props.selectedCorporateEntity}
                      </span>
                    </div>
                    <hr />
                    <Table
                      className="pdf-table"
                      columns={directorCol}
                      rowSelection={{ type: "checkbox", ...rowSelection }}
                      dataSource={report}
                      pagination={false}
                    />
                  </div>
                </TransformComponent>
              </div>
            )}
          </TransformWrapper>
        </div>
      </div>
      <AddSignature
        open={signModal}
        setvalue={setvalue}
        onOk={() => setSignModal(false)}
        onCancel={() => setSignModal(false)}
      />
    </div>
  );
}
