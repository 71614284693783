import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Popover } from "antd";
import moment from "moment/moment";
import { customPagination } from "../../helper";
import { getUser } from "../../store/user/userSlice";

import TableSortArrows from "../../components/tableSortArrows";
import TagZ from "../../components/TagZ";

export default function UserManagement() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getUser(queryParams));
  }, []);
  const roleList = [
    { label: "Entity Point of Contact", value: "point-of-contact" },
    { label: "Analyst", value: "analyst" },
    { label: "Admin", value: "admin" },
    { label: "MLRO", value: "mlro" },
    { label: "Lead / Manager", value: "lead" },
    { label: "End User Client", value: "end-user" },
    { label: "Introducer Client", value: "introducer-client" },
    { label: "Client", value: "client" },
  ];
  const SelectComponent = (role, title) => {
    const roleTitle = role.find((a) => a.value === title);
    return roleTitle ? roleTitle.label : "Unknown Role";
  };
  const columns = [
    {
      title: "",
      dataIndex: "isReq",
      render: (_, { isReq }) => (
        <span className={`table-new ${isReq ? "show" : ""}`}></span>
      ),
    },
    {
      title: "Username",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,

      render: (_, { name, _id, role }) => (
        <Link to={`/app/edit-user/${_id}?role=${role}`} className="table-link">
          {name}
        </Link>
      ),
    },
    {
      title: "Name",
      dataIndex: "firstName",
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Email Address",
      dataIndex: "mail",
      sorter: (a, b) => a.mail.localeCompare(b.mail),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Phone Number",
      dataIndex: "number",
      sorter: (a, b) => a.number.localeCompare(b.number),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "User Role",
      dataIndex: "role",
      sorter: (a, b) => a.role.localeCompare(b.role),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { role }) => {
        return (
          <div className={`role-tag ${role?.toUpperCase()}`}>
            {SelectComponent(roleList, role)}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => {
        return (
          <TagZ
            status={
              status == "Active"
                ? "approved"
                : status == "Hold"
                ? "in-progress"
                : "rejected"
            }
            statusText={status}
          />
        );
      },
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      filterType: "date",
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      key: "createdAt",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (text) => moment(text).format("YYYY-MM-DD hh:mm A"),
    },
  ];
  useEffect(() => {
    handleRefresh();
  }, [user]);

  const handleRefresh = async () => {
    if (user?.data?.data) {
      setData(user?.data?.data);
    }
  };
  //   Processor
  //   Analyst
  //   Admin
  //   MLRO
  //   Lead

  // const data = [
  //   {
  //     name: "John Doe",
  //     username: "johndoe",
  //     email: "johndoe@company.com",
  //     number: "+1 (420) 9027 6393",
  //     role: "Processor",
  //     status: true,
  //   },
  //   {
  //     name: "John Doe",
  //     username: "johndoe",
  //     email: "johndoe@company.com",
  //     number: "+1 (420) 9027 6393",
  //     role: "Analyst",
  //     status: true,
  //   },
  //   {
  //     name: "John Doe",
  //     username: "johndoe",
  //     email: "johndoe@company.com",
  //     number: "+1 (420) 9027 6393",
  //     role: "Admin",
  //     status: false,
  //   },
  //   {
  //     name: "John Doe",
  //     username: "johndoe",
  //     email: "johndoe@company.com",
  //     number: "+1 (420) 9027 6393",
  //     role: "MLRO",
  //     status: true,
  //   },
  //   {
  //     name: "John Doe",
  //     username: "johndoe",
  //     email: "johndoe@company.com",
  //     number: "+1 (420) 9027 6393",
  //     role: "Lead",
  //     status: false,
  //   },
  // ];
  const getPagination = (current, pageSize) => {
    let params = { ...queryParams };
    params._start = (current - 1) * pageSize;
    dispatch(getUser(params));
    setQueryParams(params);
  };
  return (
    <div>
      <div className="page-header">
        <h1>User Management</h1>
        <div className="d-flex align-item-center gap-3">
          <Link to={"/app/manage-roles"} className="outline-btn">
            Manage User Roles
          </Link>
          <Link to={"/app/add-user"} className="primary-btn">
            <i className="fal fa-plus"></i> Add User
          </Link>
        </div>
      </div>
      <div className="page-content">
        <Table
          className="custom-table"
          columns={columns}
          dataSource={data}
          rowKey={"_id"}
          pagination={{
            className: "custom-pagination",
            defaultCurrent: 1,
            pageSize: queryParams?._limit,
            total: user?.data?.totalCount,
            onChange: getPagination,
            itemRender: customPagination,
          }}
        />
      </div>
    </div>
  );
}
