import React from "react";
import { Link } from "react-router-dom";

export default function AdditionalScreens() {
  return (
    <div>
      <div className="page-header">
        <h1>Additional Screens</h1>
        <Link to={"/app/"} className="primary-btn">
          Initiate Request
        </Link>
      </div>
      <div className="page-content">
        <div className="d-flex flex-column gap-4">
          <Link to={"/app/additional-screens/cdd"} className="primary-btn">
            CDD Screen
          </Link>
          <Link to={"/app/additional-screens/client"} className="primary-btn">
            Client Screen
          </Link>
          <Link
            to={"/app/additional-screens/shareholder"}
            className="primary-btn"
          >
            Shareholder Screen
          </Link>
          <Link to={"/app/additional-screens/director"} className="primary-btn">
            Director Screen
          </Link>
          <Link to={"/app/additional-screens/crypto"} className="primary-btn">
            Crypto TM Screen
          </Link>
        </div>
      </div>
    </div>
  );
}
