import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../assets/img/logo-icon.png";
import { languagesArray } from "../store/language/languageSlice";
import homeData from "../helper/translations/home.json";

export default function Home() {
  let { isLogged, userDetails } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.language);
  const [selctedLanguage, setSelctedLanguage] = useState("");
  useEffect(() => {
    let index = languagesArray.find((tc) => tc.value == language);
    setSelctedLanguage(index.value);
  }, [language]);
  let t = homeData.translations[selctedLanguage] || homeData.translations.en;
  return (
    <div className="page-content">
      {userDetails?.otherDetails?.companyName ? (
        <h1 className="page-title">
          {t.titlePrefix} -{" "}
          <span>{userDetails?.otherDetails?.companyName}</span>
        </h1>
      ) : null}
      <div className="welcome-section mt-4 mt-md-0">
        <div className="w-md-50 text-center">
          <img className="logo" src={Logo} alt="" />
          <h2>
            {t.welcome}, {userDetails?.userName || t.guest}
          </h2>
          <p>{t.description}</p>
        </div>
      </div>
    </div>
  );
}
