import { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Each } from "../helper";

import FormZ from "../components/FormZ";
import UploadZ from "../components/UploadZ";
import {
  sendNotify,
  getOptions,
  fetchApi,
  generateRandom,
  convertQueryParams,
  getQuery,
} from "../helper";

export default function NewIncorporation() {
  const [uploadPack, setUploadPack] = useState([1, 2, 3, 4, 5, 6, 7, 8]);
  const [upload, setUpload] = useState([]);
  const [documents, setDocuments] = useState({ uploadPack: [] });
  const [clearDocuments, setClearDocuments] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [formData, setFormData] = useState([]);
  const [options, setOptions] = useState([]);
  const [feeCategorylist, setFeeCategorylist] = useState([]);
  const [feeSubCategoryArray, setFeeSubCategoryArray] = useState([]);
  const [entitylist, setEntitylist] = useState([]);
  const [clientlist, setClientlist] = useState([]);
  const [entityObjArray, setEntityObjArray] = useState([]);
  const [entityCode, setEntityCode] = useState("");
  const formRef = useRef();

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    let payload = {
      method: "GET",
      url: `/feeCategory?_start=0&_limit=1000`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res.data.rows;
    });

    let params = {};
    params.isAll = true;
    params.masterType = "client";
    let query = convertQueryParams(params);
    let ctr = {
      method: "GET",
      url: `/client?${query}`,
    };

    let enParams = {};
    enParams.isAll = true;
    enParams.masterType = "entity";
    let enQuery = convertQueryParams(enParams);
    let ctr_1 = {
      method: "GET",
      url: `/client?${enQuery}`,
    };
    let clientdata = await fetchApi(ctr).then((res) => {
      return res?.data.rows;
    });
    let entitydata = await fetchApi(ctr_1).then((res) => {
      return res?.data.rows;
    });

    let entitylist = entitydata
      ? entitydata?.map((d) => {
          let obj = {
            value: d?._id,
            label: d?.name,
          };
          return obj;
        })
      : [];
    let clientlist = clientdata
      ? clientdata?.map((d) => {
          let obj = {
            value: d?._id,
            label: d?.name,
          };
          return obj;
        })
      : [];
    let list = data.map((d) => {
      let obj = {
        value: d?._id,
        label: d?.name,
      };
      return obj;
    });
    setClientlist(clientlist);
    setEntitylist(entitylist);
    setFeeCategorylist(list);
  };

  const entityOnChange = async (value) => {
    let params = { code: value };
    params.isAll = true;
    params.masterType = "entity";

    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/client?${query}`,
    };

    let data = await fetchApi(payload).then((res) => {
      return res.data.rows;
    });

    let list = data.map((d) => {
      let obj = {
        value: d?._id,
        label: d?.code,
      };
      return obj;
    });
    setEntityObjArray(list);
  };

  let formSchema = [
    {
      name: "entityId",
      value: "",
      type: "select",
      label: "Entity Code",
      showSearch: true,
      placeholder: "Enter entity code",
      required: true,
    },
    {
      name: "entityName",
      value: "",
      type: "text",
      label: "Entity Name",
      placeholder: "Enter entity name",
      disabled: true,
    },
    {
      name: "entityType",
      value: "",
      type: "select",
      label: "Entity Type",
      placeholder: "Enter entity type",
      disabled: true,
      options: [
        {
          value: "BVIBC",
          label: "BVIBC",
        },
        {
          value: "trust",
          label: "Trust",
        },
        {
          value: "foundation",
          label: "Foundation",
        },
        {
          value: "general-partnership",
          label: "General Partnership",
        },
        {
          value: "limited-partnership",
          label: "Limited Partnership",
        },
      ],
    },
    {
      name: "feeCat",
      value: "",
      type: "select",
      label: "Fee Schedule Name",
      placeholder: "Select Fee Schedule Name",
      // options: getOptions(feeCategorylist),
      // disabled:list true,
      disabled: true,
    },
    {
      name: "feeType",
      value: "",
      type: "select",
      label: "Fee Type",
      placeholder: "Select fee type",
      options: [
        {
          value: "Pre-Paid",
          label: "Pre-paid",
        },
        {
          value: "Post-Paid",
          label: "Post-paid",
        },
      ],
      disabled: true,
    },
    {
      name: "subCat",
      value: "",
      type: "select",
      label: "Fee Sub Category",
      placeholder: "Select fee sub-category",
      mode: "multiple",
      disabled: true,
      inputClass: "ptb-1",
      allowClear: true,
    },
    // {
    //   name: "feeSchedule",
    //   value: "",
    //   type: "text",
    //   label: "Fee Schedule",
    //   placeholder: "Enter fee schedule",
    //   required: true,
    // },
    {
      name: "clientName",
      value: "",
      disabled: true,
      type: "text",
      label: "Masterfile Name",
      placeholder: "Enter masterfile name",
      required: true,
    },
    {
      name: "clientCode",
      value: "",
      type: "text",
      label: "Masterfile Code",
      placeholder: "Enter masterfile code",
      disabled: true,
    },
    {
      name: "clientType",
      value: "",
      type: "select",
      label: "Client Type",
      placeholder: "Enter client type",
      disabled: true,
      options: [
        {
          value: "introducerClient",
          label: "Introducer Client",
        },
        {
          value: "endUserClient",
          label: "MasterFile Client",
        },
      ],
    },
    {
      name: "parentClientCode",
      value: "",
      type: "text",
      label: "Parent Client Code",
      placeholder: "Enter parent client code",
      disabled: true,
    },
    {
      name: "parentClientName",
      value: "",
      type: "text",
      label: "Parent Client Name",
      placeholder: "Enter parent client name",
    },
    // asked vinoth
    // {
    //   name: "rmName",
    //   value: "",
    //   type: "text",
    //   label: "RM Name",
    //   placeholder: "Enter rm name",
    // },
    // {
    //   name: "rmCode",
    //   value: "",
    //   type: "text",
    //   label: "RM Code",
    //   placeholder: "Enter rm code",
    // },
    {
      name: "approvedBy",
      value: "",
      type: "text",
      label: "Approved by Name",
      disabled: true,
      placeholder: "Enter approved by name",
    },
  ];
  const addNewUpload = () => {
    const newArr = [...upload];
    let newName = `Enter document name ${newArr.length + 1}`;
    const nameExists = newArr.includes(newName);

    if (nameExists) {
      let count = 1;
      let uniq = `Enter document name ${count}`;

      while (newArr.includes(uniq)) {
        count++;
        uniq = `Enter document name ${count}`;
      }
      newName = uniq;
    }
    newArr.push(newName);

    setUpload(newArr);
  };
  const getLabelName = (value, index) => {
    let arr = [...upload];
    arr[index] = value;
    setUpload(arr);
  };
  // function storeDocuments(file) {
  //   let doc = [...documents];
  //   doc.push(file);
  //   setDocuments(doc);
  // }
  function storeDocuments(file) {
    let doc = { ...documents };

    doc["uploadPack"].push(file);

    setDocuments(doc);
  }
  function removeDocument(docName) {
    let allDoc = { ...documents };
    let doc = allDoc["uploadPack"];
    let index = doc.findIndex((d) => d.document == docName);
    if (index != -1) {
      doc.splice(index, 1);
      allDoc["uploadPack"] = doc;
      setDocuments(allDoc);
    }
  }
  const removeUpload = (index, docName) => {
    let arr = [...upload];
    arr.splice(index, 1);
    removeDocument(docName);
    setUpload(arr);
  };

  const getOnUpdate = async (value) => {
    let formDateObj = { ...formData, ...value };
    // setFormData(value);
    let { entityType, feeCat, feeType, entityId } = value;

    let arr = [];
    if ("BVIBC" == entityType) {
      arr = [
        {
          value: "1",
          label: "Label 1",
        },
        {
          value: "2",
          label: "Label 2",
        },
      ];
    } else {
      arr = [
        {
          value: "1",
          label: "Option 1",
        },
        {
          value: "2",
          label: "Option 2",
        },
      ];
    }

    setOptions(arr);
    if (entityId) {
      let params = { id: entityId };
      params.isAll = true;
      params.masterType = "entity";

      let query = convertQueryParams(params);
      let payload = {
        method: "GET",
        url: `/client?${query}`,
      };

      let data = await fetchApi(payload).then((res) => {
        return res.data.rows[0];
      });

      if (data) {
        formDateObj.entityId = data._id;
        formDateObj.entitycode = data.code;
        formDateObj.entityName = data.name;
        formDateObj.entityType = data.type;
        formDateObj.feeCat = data.feeCat;
        formDateObj.feeType = data.feeType;
        formDateObj.subCat = data.subCat;
        formDateObj.clientName = data.clientName;
        formDateObj.clientCode = data.clientCode;
        formDateObj.clientType = data.clientType;
        formDateObj.parentClientCode = "";
        formDateObj.parentClientName = "";
        formDateObj.approvedBy = "";
        setEntityCode(data.code);
      }
    }
    if (feeCat && feeType) {
      let params = { catId: feeCat, type: feeType };
      let query = convertQueryParams(params);
      let payload = {
        method: "GET",
        url: `/feeCategory/sub/?${query}`,
      };
      let data = await fetchApi(payload).then((res) => {
        return res.data.data;
      });

      let sublist = data
        ? data?.map((d) => {
            let obj = {
              value: d?._id,
              label: d?.catType,
            };
            return obj;
          })
        : [];
      setFeeSubCategoryArray(sublist);
    }
    setFormData(formDateObj);
  };

  const handleSubmit = () => {
    setSubmitLoading(true);
    let valid = formRef.current.validForm();
    if (!valid) {
      setSubmitLoading(false);
      sendNotify("error", "Some fields are missing!");
      return;
    }
    let data = {};
    let obj = formRef.current.getPayload();
    data = { ...obj, ...documents };
    data.entityCode = entityCode;
    let payload = {
      method: "POST",
      url: `/incorpSubmission`,
      data,
    };

    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message);
        setSubmitLoading(false);
        formRef.current.clearForm();
        setClearDocuments(true);
        obj = null;
        //  setDocuments({ uploadPack: [] });
      })
      .catch((error) => {
        sendNotify("error", error?.message);
        setSubmitLoading(false);
      });

    setClearDocuments(false);
  };

  return (
    <div>
      <div className="page-header">
        <h1>New Incorporation</h1>
        <div className="d-flex gap-3 flex-column flex-md-row">
          {/* <button className="outline-btn" onClick={handleSubmit}>
            <i className="fal fa-plus"></i> Add New Upload Pack
          </button> */}

          <button
            className={`outline-btn ${submitLoading ? "btn-loading" : ""}`}
            onClick={handleSubmit}
          >
            {submitLoading ? (
              <i className="far fa-spinner-third fa-spin"></i>
            ) : (
              ""
            )}
            <i className="fal fa-plus"></i> Add New Upload Pack
          </button>

          <Link
            to={"/app/incorporation-feedback"}
            className="primary-btn"
            onClick={handleSubmit}
          >
            Store and Submit
          </Link>
        </div>
      </div>
      <div className="page-content">
        <div className="custom-form">
          <FormZ
            formSchema={formSchema}
            ref={formRef}
            formClass="row mb-4 mb-md-5 gy-3 gx-4"
            onChange={getOnUpdate}
            onKeyUp={(e) => entityOnChange(e.target.value)}
            formData={formData}
            formOptions={{
              entityId: entityObjArray,
              clientType: options,
              feeCat: feeCategorylist,
              subCat: feeSubCategoryArray,
            }}
            childClass="col-md-3 mb-0"
            labelClass="mb-1"
            inputClass="w-100"
          />
          <h4 className="primary-title mb-4">Upload Pack</h4>
          {/* <div className="row gy-2 gx-4">
            <Each
              array={uploadPack}
              render={(item) => (
                <div className="col-md-3">
                  <label htmlFor={item}>0{item}</label>
                  <UploadZ onStoreFile={storeDocuments} fileName={item} />
                </div>
              )}
            />


          </div> */}
          <div className="row gy-2 gx-4">
            <Each
              array={upload}
              render={(item, i) => (
                <div className="col-md-3">
                  <div className="position-relative">
                    <div className="d-flex justify-content-between align-items-center mb-1px">
                      <label
                        contentEditable={true}
                        key={i}
                        id={i}
                        onBlur={(e) => getLabelName(e.target.innerHTML, i)}
                        className="mb-0"
                        suppressContentEditableWarning={true}
                      >
                        {item}
                      </label>
                      <button
                        className="del-upload-btn"
                        onClick={() => removeUpload(i, item)}
                      >
                        <i className="far fa-trash-alt"></i>
                      </button>
                    </div>
                    <UploadZ
                      onStoreFile={storeDocuments}
                      onDeleteFile={removeDocument}
                      clearUpload={clearDocuments}
                      file={documents["uploadPack"].find(
                        (d) => d?.document == item
                      )}
                      fileName={item}
                    />
                  </div>
                </div>
              )}
            />
            <div className="col-md-3 mb-4">
              <label htmlFor="">&nbsp;</label>
              <div className="add-new-upload" onClick={addNewUpload}>
                <span className="icon">
                  <i className="fal fa-plus-circle"></i>
                </span>
                <h6>Add New Upload</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
