import { useState, useEffect } from "react";
import { Modal, Table, Switch, Popover } from "antd";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";

import { getPreIncorpRequests } from "../store/preIncorpRequests/preIncorpSlice";
import { getPostIncorpRequests } from "../store/postIncorpRequests/postIncorpSlice";
import TableFilterZ from "../components/TableFilterZ";
import { showCount, customPagination, downloadFile } from "../helper";
import InProgressIcon from "../assets/img/in-progress-icon.svg";
import ApprovedIcon from "../assets/img/approved-icon.svg";
import searchFilter from "../components/searchFilter";
import TagZ from "../components/TagZ";
import TabZ from "../components/TabZ";
import TableSortArrows from "../components/tableSortArrows";

import AssigneeImg from "../assets/img/notify-avatar.png";
import SampleDoc from "../assets/pdf/sample.pdf";

const Controls = () => {
  const { zoomIn, zoomOut, resetTransform, centerView } = useControls();

  const downloadPDF = () => {
    downloadFile(SampleDoc, "certificate-of-incumbency");
  };

  return (
    <div className="control-tools d-flex gap-3">
      <button onClick={() => zoomIn()}>
        <i className="far fa-search-plus"></i>
      </button>
      <button onClick={() => zoomOut()}>
        <i className="far fa-search-minus"></i>
      </button>
      <button onClick={downloadPDF}>
        <i className="far fa-arrow-to-bottom"></i>
      </button>
      {/* <button onClick={() => resetTransform()}>
        <i className="fal fa-times-circle"></i>
      </button>
      <button onClick={() => centerView()}>
        <i className="fal fa-arrows"></i>
      </button> */}
    </div>
  );
};

export default function ActiveRequests() {
  const preIncorp = useSelector((state) => state.preIncorp);
  const postIncorp = useSelector((state) => state.postIncorp);

  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });
  const [preData, setPreData] = useState([]);
  const [postData, setPostData] = useState([]);
  const [docModal, setDocModal] = useState(false);
  const [viewDocName, setViewDocName] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPreIncorpRequests(queryParams));
    dispatch(getPostIncorpRequests(queryParams));
  }, []);

  useEffect(() => {
    setPreData(preIncorp?.data?.rows);
    setPostData(postIncorp?.data?.rows);
  }, [preIncorp, postIncorp]);

  const handleSearch = (searchFilters) => {
    let query = { ...queryParams, ...searchFilters };
    dispatch(getPreIncorpRequests(query));
    setQueryParams(query);
  };

  const handleReset = (key) => {
    let query = { ...queryParams };
    delete query[key];
    dispatch(getPreIncorpRequests(query));
    setQueryParams(query);
  };

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const viewDocument = (docName) => {
    setViewDocName(docName);
    setDocModal(true);
  };

  const handleOk = () => {
    setDocModal(false);
    setViewDocName("");
  };
  const handleCancel = () => {
    setDocModal(false);
    setViewDocName("");
  };

  let assigneeList = (
    <div className="assignee-list">
      <div className="assignee-item">
        <img src={AssigneeImg} alt="" />
        <p>John Doe</p>
      </div>
      <div className="assignee-item">
        <img src={AssigneeImg} alt="" />
        <p>John Doe</p>
      </div>
      <div className="assignee-item">
        <img src={AssigneeImg} alt="" />
        <p>John Doe</p>
      </div>
      <div className="assignee-item">
        <img src={AssigneeImg} alt="" />
        <p>John Doe</p>
      </div>
      <div className="assignee-item">
        <img src={AssigneeImg} alt="" />
        <p>John Doe</p>
      </div>
      <div className="assignee-item">
        <img src={AssigneeImg} alt="" />
        <p>John Doe</p>
      </div>
    </div>
  );

  const preColumns = [
    {
      title: "Request Ref",
      dataIndex: "refNumber",
      sorter: (a, b) => a.refNumber - b.refNumber,
      filterType: "text",
      ...searchFilter({
        dataIndex: "refNumber",
        inputType: "text",
        queryParams,
        handleSearch,
        handleReset,
      }),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Requestor Name",
      dataIndex: "requestedBy",
      filterType: "text",
      sorter: (a, b) => a.requestedBy - b.requestedBy,
      ...searchFilter({
        dataIndex: "requestedBy",
        inputType: "text",
        queryParams,
        handleSearch,
        handleReset,
      }),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // {
    //   title: "Entity Name",
    //   dataIndex: "entityName",
    //   sorter: (a, b) => a.entityName - b.entityName,
    // },
    {
      title: "Masterfile Name",
      dataIndex: "clientName",
      filterType: "text",
      sorter: (a, b) => a.clientName - b.clientName,
      ...searchFilter({
        dataIndex: "clientName",
        inputType: "text",
        queryParams,
        handleSearch,
        handleReset,
      }),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Status",
      dataIndex: "status",
      filterType: "select",
      filterOptions: [
        {
          value: "approved",
          label: "Approved",
        },
        {
          value: "in-progress",
          label: "In Progress",
        },
      ],
      sorter: (a, b) => a.status - b.status,
      ...searchFilter({
        dataIndex: "status",
        inputType: "select",
        options: [
          {
            label: "Pending",
            value: "Pending",
          },
          {
            label: "Submitted",
            value: "Submitted",
          },
        ],
        queryParams,
        handleSearch,
        handleReset,
      }),
      render: (_, { status }) => (
        <TagZ
          status={status == "Submitted" ? "approved" : "in-progress"}
          statusText={status == "Submitted" ? "Submitted" : "Pending"}
        />
      ),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Request type",
      dataIndex: "requestType",
      filterType: "select",
      filterOptions: [
        {
          value: "approved",
          label: "Approved",
        },
        {
          value: "in-progress",
          label: "In Progress",
        },
      ],
      sorter: (a, b) => a.requestType - b.requestType,
      ...searchFilter({
        dataIndex: "requestType",
        inputType: "text",
        queryParams,
        handleSearch,
        handleReset,
      }),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // {
    //   title: "Pending received",
    //   dataIndex: "received",
    //   sorter: (a, b) => a.received - b.received,
    // },
    {
      title: "Resubmission Status",
      dataIndex: "resubmission",
      filterType: "select",
      filterOptions: [
        {
          value: "approved",
          label: "Approved",
        },
        {
          value: "in-progress",
          label: "In Progress",
        },
      ],
      sorter: (a, b) => a.resubmission - b.resubmission,
      // ...searchFilter({
      //   dataIndex: "resubmission",
      //   inputType: "text",
      //   options: [
      //     {
      //       label: "Pending",
      //       value: "Pending",
      //     },
      //     {
      //       label: "Submitted",
      //       value: "Submitted",
      //     },
      //   ],
      //   queryParams,
      //   handleSearch,
      //   handleReset,
      // }),
      render: (_, { resubmission }) => (
        <TagZ
          status={resubmission == "Submitted" ? "resubmitted" : "in-progress"}
          statusText={resubmission == "Submitted" ? "Resubmitted" : "Pending"}
        />
        // <Tag
        //   icon={<i className="fad fa-dot-circle me-1"></i>}
        //   color={
        //     resubmission == "Submitted"
        //       ? "green"
        //       : resubmission == "Pending"
        //       ? "gold"
        //       : resubmission == "In Progress"
        //       ? "processing"
        //       : "processing"
        //   }
        // >
        //   {resubmission}
        // </Tag>
      ),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Origin Date",
      dataIndex: "createdAt",
      filterType: "date",
      sorter: (a, b) => a.createdAt - b.createdAt,
      ...searchFilter({
        dataIndex: "requestedBy",
        inputType: "text",
        queryParams,
        handleSearch,
        handleReset,
      }),
      render: (_, { createdAt }) => {
        return dayjs(createdAt).format("DD/MM/YYYY - hh:mm A");
      },
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Assignee 1",
      dataIndex: "assignee1",
      sorter: (a, b) => a.assignee1 - b.assignee1,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { assignee }) => {
        if (assignee) {
          return (
            <div className="assignee">
              <span className="position-relative">
                <button className="remove-btn">
                  <i className="fas fa-times-circle"></i>
                </button>
                <img src={AssigneeImg} alt="" />
              </span>
              <p>John Doe</p>
            </div>
          );
        } else {
          return (
            <Popover
              content={assigneeList}
              trigger="click"
              className="custom-popover"
            >
              <div className="assignee">
                <i className="fal fa-plus-circle"></i> <p>Add Assignee</p>
              </div>
            </Popover>
          );
        }
      },
    },
    {
      title: "Assignee 2",
      dataIndex: "assignee2",
      sorter: (a, b) => a.assignee2 - b.assignee2,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { assignee }) => {
        if (assignee) {
          return (
            <div className="assignee">
              <span className="position-relative">
                <button className="remove-btn">
                  <i className="fas fa-times-circle"></i>
                </button>
                <img src={AssigneeImg} alt="" />
              </span>
              <p>John Doe</p>
            </div>
          );
        } else {
          return (
            <Popover
              content={assigneeList}
              trigger="click"
              className="custom-popover"
            >
              <div className="assignee">
                <i className="fal fa-plus-circle"></i> <p>Add Assignee</p>
              </div>
            </Popover>
          );
        }
      },
    },
    {
      title: "Actions",
      render: (_, { _id, requestType }) => (
        <div className="d-flex align-items-center gap-2">
          <button className="action-btn">Assign</button>
          <button className="action-btn">Re-assign</button>
          <Link
            to={
              requestType == "Name Check"
                ? `/app/onboarding-team/edit/${_id}`
                : "/app/onboarding-team/download"
            }
            className="action-btn"
          >
            {requestType == "Name Check" ? "Name Check" : "View Request"} &nbsp;{" "}
            <i className="far fa-long-arrow-right me-0"></i>
          </Link>
        </div>
      ),
    },
  ];
  const postColumns = [
    {
      title: "Request Ref",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.code - b.code,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { code, pageLink }) => (
        <Link className="table-link" to={"/app/" + pageLink}>
          {code}
        </Link>
      ),
    },
    {
      title: "Requestor Name",
      dataIndex: "requestorName",
      filterType: "text",
      sorter: (a, b) => a.requestorName - b.requestorName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Request type",
      dataIndex: "serviceType",
      filterType: "select",
      filterOptions: [
        {
          value: "approved",
          label: "Approved",
        },
        {
          value: "in-progress",
          label: "In Progress",
        },
      ],
      sorter: (a, b) => a.type - b.type,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      filterType: "select",
      filterOptions: [
        {
          value: "approved",
          label: "Approved",
        },
        {
          value: "in-progress",
          label: "In Progress",
        },
      ],
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={status == "Y" ? "approved" : "in-progress"}
          statusText={status == "Y" ? "Submitted" : "Pending"}
        />
      ),
    },
    // {
    //   title: "Pending received",
    //   dataIndex: "received",
    //   sorter: (a, b) => a.received - b.received,
    // },
    {
      title: "Resubmission Status",
      dataIndex: "resubmission",
      filterType: "select",
      filterOptions: [
        {
          value: "approved",
          label: "Approved",
        },
        {
          value: "in-progress",
          label: "In Progress",
        },
      ],
      sorter: (a, b) => a.resubmission - b.resubmission,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { resubmission }) => (
        <TagZ
          status={resubmission == "Y" ? "resubmitted" : "in-progress"}
          statusText={resubmission == "Y" ? "Resubmitted" : "Pending"}
        />
      ),
    },
    {
      title: "Origin Date",
      dataIndex: "createdAt",
      filterType: "date",
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Name",
      dataIndex: "entityName",
      filterType: "text",
      sorter: (a, b) => a.entityName - b.entityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // {
    //   title: "Entity Code",
    //   dataIndex: "entityCode",
    //   sorter: (a, b) => a.entityCode - b.entityCode,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    // },
    {
      title: "Masterfile Name",
      dataIndex: "clientName",
      filterType: "text",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Code",
      dataIndex: "clientCode",
      filterType: "text",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Approved By",
      dataIndex: "approvedBy",
      filterType: "text",
      sorter: (a, b) => a.approvedBy - b.approvedBy,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Approved Date & Time",
      dataIndex: "approvedDateTime",
      sorter: (a, b) => a.approvedDateTime - b.approvedDateTime,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 220,
    },
    {
      title: "Approved Status",
      dataIndex: "approvedStatus",
      filterType: "select",
      filterOptions: [
        {
          value: "approved",
          label: "Approved",
        },
        {
          value: "in-progress",
          label: "In Progress",
        },
      ],
      sorter: (a, b) => a.approvedStatus - b.approvedStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { approvedStatus }) => (
        <TagZ
          status={approvedStatus == "Y" ? "resubmitted" : "in-progress"}
          statusText={approvedStatus == "Y" ? "Resubmitted" : "Pending"}
        />
      ),
    },
    {
      title: "Update By",
      dataIndex: "updateBy",
      filterType: "text",
      sorter: (a, b) => a.updateBy - b.updateBy,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Verified",
      dataIndex: "verified",
      sorter: (a, b) => a.verified - b.verified,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { verified }) => (
        <div className="d-flex gap-2">
          <Switch
            checked={verified}
            className="custom-switch"
            checkedChildren="Yes"
            unCheckedChildren="No"
          />
        </div>
      ),
    },
    {
      title: "Assignee 1",
      dataIndex: "assignee1",
      sorter: (a, b) => a.assignee1 - b.assignee1,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { assignee }) => {
        if (assignee) {
          return (
            <div className="assignee">
              <span className="position-relative">
                <button className="remove-btn">
                  <i className="fas fa-times-circle"></i>
                </button>
                <img src={AssigneeImg} alt="" />
              </span>
              <p>John Doe</p>
            </div>
          );
        } else {
          return (
            <Popover
              content={assigneeList}
              trigger="click"
              className="custom-popover"
            >
              <div className="assignee">
                <i className="fal fa-plus-circle"></i> <p>Add Assignee</p>
              </div>
            </Popover>
          );
        }
      },
    },
    {
      title: "Assignee 2",
      dataIndex: "assignee2",
      sorter: (a, b) => a.assignee2 - b.assignee2,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { assignee }) => {
        if (assignee) {
          return (
            <div className="assignee">
              <span className="position-relative">
                <button className="remove-btn">
                  <i className="fas fa-times-circle"></i>
                </button>
                <img src={AssigneeImg} alt="" />
              </span>
              <p>John Doe</p>
            </div>
          );
        } else {
          return (
            <Popover
              content={assigneeList}
              trigger="click"
              className="custom-popover"
            >
              <div className="assignee">
                <i className="fal fa-plus-circle"></i> <p>Add Assignee</p>
              </div>
            </Popover>
          );
        }
      },
    },
    {
      title: "Actions",
      render: (_, { _id, type, link }) => (
        <div className="d-flex align-items-center gap-2">
          <button className="action-btn">Assign</button>
          <button className="action-btn">Re-assign</button>
          <Link
            // to={`/app/onboarding-team/download/${_id}?type=${type}`}
            to={`/${link}`}
            className="action-btn"
          >
            View Request &nbsp; <i className="far fa-long-arrow-right me-0"></i>
          </Link>
        </div>
      ),
    },
  ];

  // const preData = [
  //   {
  //     key: "1",
  //     id: 1,
  //     ref: "Sample",
  //     name: "Request #1",
  //     entityName: "E571",
  //     clientName: "C783",
  //     type: "Name Check",
  //     status: "Y",
  //     received: "Sample",
  //     resubmission: "N",
  //     originDate: "08/10/2023 02:33PM",
  //   },
  //   {
  //     key: "2",
  //     id: 2,
  //     ref: "Sample",
  //     name: "Request #2",
  //     entityName: "E571",
  //     clientName: "C783",
  //     type: "Sample",
  //     status: "Y",
  //     received: "Sample",
  //     resubmission: "Y",
  //     originDate: "03/11/2023 04:31PM",
  //   },
  //   {
  //     key: "3",
  //     id: 3,
  //     ref: "Sample",
  //     name: "Request #3",
  //     entityName: "E571",
  //     clientName: "C783",
  //     type: "Sample",
  //     status: "N",
  //     received: "Sample",
  //     resubmission: "N",
  //     originDate: "10/11/2023 06:11PM",
  //   },
  // ];
  const staticPostData = [
    {
      key: "1",
      id: "1",
      code: "RFOJCWZVHSSLWU858ERD",
      requestorName: "Client 1",
      type: "Certificate of Good Standing",
      status: "N",
      received: "Sample",
      resubmission: "N",
      createdAt: "07/11/2023 04:56PM",
      entityName: "Trust",
      entityCode: "E783",
      verified: true,
      clientName: "Sample",
      clientCode: "C038",
      approvedBy: "Guru",
      approvedDateTime: "12/12/2023 03:12PM",
      approvedStatus: "pending",
      updateBy: "Hari",
      pageLink: "certificate-of-good-standing",
    },
    {
      key: "2",
      id: "2",
      code: "RFOJCWZVHSSLWU85D63D",
      requestorName: "Client 1",
      type: "Change of Director",
      status: "N",
      received: "Sample",
      resubmission: "N",
      createdAt: "06/11/2023 06:08PM",
      entityName: "Trust",
      entityCode: "E783",
      verified: false,
      clientName: "Sample",
      clientCode: "C038",
      approvedBy: "Guru",
      approvedDateTime: "12/12/2023 03:12PM",
      approvedStatus: "pending",
      updateBy: "Hari",
      pageLink: "change-of-director",
    },
    {
      key: "3",
      id: "3",
      code: "RFOJCWZVHSSLWU85JKDC",
      requestorName: "Client 1",
      type: "Change of Shareholder",
      status: "Y",
      received: "Sample",
      resubmission: "Y",
      createdAt: "06/11/2023 02:23PM",
      entityName: "Trust",
      entityCode: "E783",
      verified: true,
      clientName: "Sample",
      clientCode: "C038",
      approvedBy: "Guru",
      approvedDateTime: "12/12/2023 03:12PM",
      approvedStatus: "pending",
      updateBy: "Hari",
      pageLink: "change-of-shareholder",
    },
    {
      key: "4",
      id: "4",
      code: "RFOJCWZVHSSLWFRY2346",
      requestorName: "Client 1",
      type: "Alteration to M&AA ",
      status: "N",
      received: "Sample",
      resubmission: "Y",
      createdAt: "08/11/2023 06:08PM",
      entityName: "Trust",
      entityCode: "E783",
      verified: false,
      clientName: "Sample",
      clientCode: "C038",
      approvedBy: "Guru",
      approvedDateTime: "12/12/2023 03:12PM",
      approvedStatus: "pending",
      updateBy: "Hari",
      pageLink: "alteration-to-maa",
    },
    {
      key: "5",
      id: "5",
      code: "RFOJCWZVHSSLWFRY2346",
      requestorName: "Client 1",
      type: "M&AA Name Change",
      status: "Y",
      received: "Sample",
      resubmission: "Y",
      createdAt: "10/11/2023 02:23PM",
      entityName: "Trust",
      entityCode: "E783",
      verified: false,
      clientName: "Sample",
      clientCode: "C038",
      approvedBy: "Guru",
      approvedDateTime: "12/12/2023 03:12PM",
      approvedStatus: "pending",
      updateBy: "Hari",
      pageLink: "maa-name-change",
    },
    {
      key: "6",
      id: "6",
      code: "RFOJCWZVHJASDF332FVD",
      requestorName: "Client 1",
      type: "Notarization of Documents",
      status: "Y",
      received: "Sample",
      resubmission: "Y",
      createdAt: "10/11/2023 02:23PM",
      entityName: "Trust",
      entityCode: "E783",
      verified: false,
      clientName: "Sample",
      clientCode: "C038",
      approvedBy: "Guru",
      approvedDateTime: "12/12/2023 03:12PM",
      approvedStatus: "pending",
      updateBy: "Hari",
      pageLink: "notarization-of-documents",
    },
    {
      key: "7",
      id: "7",
      code: "RFOJCWZVHJASDUJDI23JJ",
      requestorName: "Client 1",
      type: "Apostille of Documents",
      status: "Y",
      received: "Sample",
      resubmission: "Y",
      createdAt: "10/11/2023 02:23PM",
      entityName: "Trust",
      entityCode: "E783",
      verified: false,
      clientName: "Sample",
      clientCode: "C038",
      approvedBy: "Guru",
      approvedDateTime: "12/12/2023 03:12PM",
      approvedStatus: "pending",
      updateBy: "Hari",
      pageLink: "apostille-of-documents",
    },
    {
      key: "8",
      id: "8",
      code: "RFOJCWZVHJASDYUEOED3",
      requestorName: "Client 1",
      type: "Drafting Resolutions",
      status: "Y",
      received: "Sample",
      resubmission: "Y",
      createdAt: "10/11/2023 02:23PM",
      entityName: "Trust",
      entityCode: "E783",
      verified: false,
      clientName: "Sample",
      clientCode: "C038",
      approvedBy: "Guru",
      approvedDateTime: "12/12/2023 03:12PM",
      approvedStatus: "pending",
      updateBy: "Hari",
      pageLink: "drafting-resolutions",
    },
    {
      key: "9",
      id: "9",
      code: "RFOJCWZVHJASDUSAF234",
      requestorName: "Client 1",
      type: "Corporate Documents",
      status: "Y",
      received: "Sample",
      resubmission: "Y",
      createdAt: "10/11/2023 02:23PM",
      entityName: "Trust",
      entityCode: "E783",
      verified: false,
      clientName: "Sample",
      clientCode: "C038",
      approvedBy: "Guru",
      approvedDateTime: "12/12/2023 03:12PM",
      approvedStatus: "pending",
      updateBy: "Hari",
      pageLink: "corporate-documents",
    },
    {
      key: "10",
      id: "10",
      code: "RFOJCWZVHJASDUUICEW3",
      requestorName: "Client 1",
      type: "Other",
      status: "Y",
      received: "Sample",
      resubmission: "Y",
      createdAt: "10/11/2023 02:23PM",
      entityName: "Trust",
      entityCode: "E783",
      verified: false,
      clientName: "Sample",
      clientCode: "C038",
      approvedBy: "Guru",
      approvedDateTime: "12/12/2023 03:12PM",
      approvedStatus: "pending",
      updateBy: "Hari",
      pageLink: "other",
    },
    {
      key: "11",
      id: "11",
      code: "RFOJCWZVHJASDUBN323D",
      requestorName: "Client 1",
      type: "Transfer Out",
      status: "Y",
      received: "Sample",
      resubmission: "Y",
      createdAt: "10/11/2023 02:23PM",
      entityName: "Trust",
      entityCode: "E783",
      verified: false,
      clientName: "Sample",
      clientCode: "C038",
      approvedBy: "Guru",
      approvedDateTime: "12/12/2023 03:12PM",
      approvedStatus: "pending",
      updateBy: "Hari",
      pageLink: "transfer-out",
    },
    {
      key: "12",
      id: "12",
      code: "RFOJCWZVHJASDUYURC34",
      requestorName: "Client 1",
      type: "Solvent Liquidation",
      status: "Y",
      received: "Sample",
      resubmission: "Y",
      createdAt: "10/11/2023 02:23PM",
      entityName: "Trust",
      entityCode: "E783",
      verified: false,
      clientName: "Sample",
      clientCode: "C038",
      approvedBy: "Guru",
      approvedDateTime: "12/12/2023 03:12PM",
      approvedStatus: "pending",
      updateBy: "Hari",
      pageLink: "solvent-liquidation",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const getPrePagination = (current, pageSize) => {
    let params = { ...queryParams };
    params._start = (current - 1) * pageSize;
    dispatch(getPreIncorpRequests(params));
    setQueryParams(params);
  };
  const getPostPagination = (current, pageSize) => {
    let params = { ...queryParams };
    params._start = (current - 1) * pageSize;
    dispatch(getPostIncorpRequests(params));
    setQueryParams(params);
  };
  const labels = [
    {
      key: 1,
      label: (
        <>
          <img src={ApprovedIcon} alt="" /> Completed
        </>
      ),
    },
    {
      key: 2,
      label: (
        <>
          <img src={InProgressIcon} alt="" /> In Progress
        </>
      ),
    },
  ];
  const getFilter = (value) => {
    let params = { ...queryParams };
    const filters = encodeURIComponent(JSON.stringify(value));
    params.filters = filters;
    dispatch(getPreIncorpRequests(params));
    setQueryParams(params);
  };
  const getPostFilter = (value) => {
    let params = { ...queryParams };
    const filters = encodeURIComponent(JSON.stringify(value));
    params.filters = filters;
    dispatch(getPostIncorpRequests(params));
    setQueryParams(params);
  };

  return (
    <div>
      <div className="page-header">
        <h1>Active Requests</h1>
        <Link to={"/app/onboarding-team/download"} className="primary-btn">
          Submit
        </Link>
      </div>
      <div className="page-content">
        <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-3">
          <h4 className="primary-title text-capitalize mb-3 mb-md-0">
            Pre-Incorp Requests ({showCount(preIncorp?.data?.totalCount)})
          </h4>
          <div className="d-flex flex-column flex-md-row align-items-start gap-3">
            <button className="outline-btn">
              <i className="far fa-arrow-to-bottom"></i> Download Selected
            </button>
            <button className="outline-btn">
              <i className="far fa-check-double"></i> Assign Selected
            </button>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-4">
            {/* <TabZ
            labels={labels}
            defaultActive={"2"}
            customClass={"table-tabs"}
          /> */}
            <TableFilterZ tableCol={preColumns} onChange={getFilter} />
          </div>
        </div>
        <Table
          rowKey={"_id"}
          className="custom-table mb-3"
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={preColumns}
          dataSource={preData}
          scroll={{ x: 2000 }}
          loading={preIncorp?.isLoading}
          pagination={{
            className: "custom-pagination",
            defaultCurrent: 1,
            pageSize: queryParams?._limit,
            total: preIncorp?.data?.totalCount,
            onChange: getPrePagination,
            itemRender: customPagination,
          }}
        />

        <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-3">
          <h4 className="primary-title text-capitalize mb-3 mb-md-0">
            Post-Incorp Requests ({showCount(postIncorp?.data?.totalCount)})
          </h4>
          <div className="d-flex flex-column flex-md-row align-items-start gap-3">
            <button className="outline-btn">
              <i className="far fa-arrow-to-bottom"></i> Download Selected
            </button>
            <button className="outline-btn">
              <i className="far fa-check-double"></i> Assign Selected
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-end mb-3">
          <TableFilterZ tableCol={postColumns} onChange={getPostFilter} />
        </div>
        <Table
          className="custom-table"
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={postColumns}
          dataSource={postIncorp?.data?.rows}
          scroll={{ x: 3000 }}
          loading={postIncorp?.isLoading}
          pagination={{
            className: "custom-pagination",
            defaultCurrent: 1,
            pageSize: queryParams?._limit,
            total: postIncorp?.data?.totalCount,
            onChange: getPostPagination,
            itemRender: customPagination,
          }}
        />
      </div>
      <Modal
        title={viewDocName}
        className="custom-modal-2"
        open={docModal}
        width={1000}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <div className="pdf-zoom-wrapper">
          <TransformWrapper initialScale={1} centerOnInit>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <div>
                <Controls />
                <TransformComponent wrapperClass="w-100 pdf-transform">
                  <div className="custom-pdf-wrapper">
                    <div className="document-view">
                      <iframe
                        src={SampleDoc}
                        frameBorder="0"
                        height="100%"
                        width="100%"
                      ></iframe>
                    </div>
                  </div>
                </TransformComponent>
              </div>
            )}
          </TransformWrapper>
        </div>
      </Modal>
    </div>
  );
}
