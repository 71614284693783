import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Breadcrumb, Select } from "antd";

import { checkLogged } from "../../store/auth/authSlice";
import { sendNotify, fetchApi, convertQueryParams } from "../../helper";
import NotificationBtn from "../../components/NotificationBtn";
import ClientAvatar from "../../assets/img/user-avatar2.png";
import overAll from "../../helper/translations/overAll.json";

import {
  languagesArray,
  toggleLanguages,
} from "../../store/language/languageSlice";

const { confirm } = Modal;

export default function Navbar() {
  const dispatch = useDispatch();
  let userDetails = useSelector((state) => state.auth.userDetails);
  const { language } = useSelector((state) => state.language);
  const [userData, setUserData] = useState({});
  const [profileUrl, setProfileUrl] = useState("");
  const [logoColor, setLogoColor] = useState("");
  const [logoText, setlogoText] = useState("");
  const [selctedLanguage, setSelctedLanguage] = useState("");

  useEffect(() => {
    let ctr = localStorage.getItem("languages") ?? "en";
    dispatch(toggleLanguages(ctr));
  }, []);

  useEffect(() => {
    let index = languagesArray.find((tc) => tc.value == language);
    setSelctedLanguage(index.value);
    getUserData();
  }, [userDetails, language]);

  let t = overAll[selctedLanguage] || overAll.en;

  const getUserData = async () => {
    let params = { id: userDetails._id };
    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/admin/get?${query}`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res?.data?.rows[0];
    });

    setUserData(data);
    const res = await fetchLiveUrl(data?.profileDp);
    setProfileUrl(data?.profileDp ? res.data : "");
    let logo =
      data?.firstName[0].toUpperCase() + data?.lastName[0].toUpperCase();
    setlogoText(logo);

    setLogoColor(data?.logoColor ? data?.logoColor : logoColor);
  };

  const fetchLiveUrl = async (url) => {
    if (url) {
      try {
        const data = { url: url };
        const obj = {
          method: "POST",
          url: `/file/get`,
          data: data,
        };
        return await fetchApi(obj);
      } catch (error) {
        console.error("Error fetching live URL:", error);
        throw error;
      }
    }
  };

  const confirmLogout = () => {
    confirm({
      title: <h5>{t.ConfirmLogout}</h5>,
      content: <p>{t.LogoutMessage}</p>,
      className: "custom-confirm",
      centered: true,
      okText: t.LogOut,
      onOk() {
        logout();
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  const logout = () => {
    sendNotify("success", "Logout successfully");
    localStorage.clear(process.env.REACT_APP_JWT_TOKEN);
    dispatch(checkLogged());
  };

  const handleLanguageChange = (value) => {
    setSelctedLanguage(value);
    dispatch(toggleLanguages(value));
  };
  return (
    <nav className="navbar">
      <div className="w-100 d-flex align-items-center justify-content-end gap-4">
        <Link to={"/app/chat-box"} className="nav-icon-link">
          <i class="far fa-comment"></i>
        </Link>
        <Link to={"/app/mail"} className="nav-icon-link">
          <i className="fal fa-envelope"></i>
        </Link>
        <NotificationBtn />
        <div className="profile-wrap">
          <div className="profile-toggle">
            <i className="far fa-chevron-down"></i>

            {profileUrl ? (
              <img src={`data:image/png;base64, ${profileUrl}`} alt="" />
            ) : (
              <h4
                className="temp-profile"
                style={{ backgroundColor: logoColor }}
              >
                {logoText}
              </h4>
            )}
          </div>
          <ul className="profile-dropdown">
            <Link to={"/app/profile-settings"} className="nav-icon-link">
              <li>{t.Settings}</li>
            </Link>
            <li onClick={confirmLogout}>{t.LogOut}</li>
          </ul>
        </div>

        <div className="d-flex gap-3 align-items-center">
          <Select
            className="custom-select w-30 ptb-1"
            style={{ width: 200 }}
            placeholder="Select Language"
            onChange={handleLanguageChange}
            options={languagesArray}
            value={selctedLanguage}
            showSearch
          />
        </div>
      </div>
    </nav>
  );
}
