import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox, Modal } from "antd";
import JSZip from "jszip";
import FormZ from "../components/FormZ";
import { getPreIncorpMaterials } from "../store/preIncorpMaterials/preIncorpMaterialsSlice";

import SampleDoc from "../assets/pdf/sample.pdf";

import {
  Each,
  downloadFile,
  formatBytes,
  sendNotify,
  fetchApi,
  downloadBase64File,
} from "../helper";
import UploadZ from "../components/UploadZ";
import { elements } from "chart.js";

export default function PreIncorpResolution() {
  const dispatch = useDispatch();
  const formRef = useRef();

  const preIncorpMaterials = useSelector((state) => state.preIncorpMaterials);

  const [docModal, setDocModal] = useState(false);
  const [viewDoc, setViewDoc] = useState(null);
  const [viewDocName, setViewDocName] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCatIds, setSelectedCatIds] = useState([]);
  const [viewmodal, setViewModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [document, setDocument] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [documents, setDocuments] = useState([]);

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  useEffect(() => {
    dispatch(getPreIncorpMaterials({}));
  }, []);
  useEffect(() => {
    handleRefresh();
  }, [preIncorpMaterials]);
  const viewDocument = (doc, docName) => {
    setViewDoc(doc);
    setViewDocName(docName);
    setDocModal(true);
  };
  const handleRefresh = async () => {
    let data = preIncorpMaterials?.data?.rows?.map((a) => {
      let obj = {
        id: a._id,
        url: a.url,
        name: a.name,
        size: a.size,
        type: a.type,
        base64: "",
      };
      return obj;
    });
    setDocuments(data);
  };
  const handleOk = () => {
    setDocModal(false);
    setViewDocName("");
  };
  const handleCancel = () => {
    setDocModal(false);
    setViewModal(false);
    setViewDocName("");
  };
  let formSchema = [
    {
      name: "name",
      value: "",
      type: "text",
      label: "Document Name",
      placeholder: "Enter document name",
      required: true,
    },

    {
      name: "position",
      value: "",
      type: "number",
      label: "Position",
      placeholder: "Enter position number",
      customClass: "hide-arrows",
      required: true,
    },
  ];

  const onSelectAll = (e) => {
    let array = [];
    if (e.target.checked) {
      array = documents.map((h) => h.id);
    }
    setSelectedCatIds(array);
    setSelectAll(e.target.checked);
  };

  const onSelectOne = (id) => {
    let array = [...selectedCatIds];
    let array1 = documents;
    let index = array.indexOf(id);
    if (index >= 0) {
      array.splice(index, 1);
    } else {
      array.push(id);
    }
    if (array.length === array1.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    setSelectedCatIds(array);
  };

  const DownloadDoc = async () => {
    let selectedIdsArr = [...selectedCatIds];
    const selectedDocuments = documents.filter((document) =>
      selectedIdsArr.includes(document.id)
    );
    const folderName = "Pre-Incorp Supported Documents";
    const zipContent = [];

    const fetchDataFromDataURL = async (dataURL) => {
      const response = await fetch(dataURL);
      return await response.blob();
    };

    for (const document of selectedDocuments) {
      let ondoc = await fetchLiveUrl(document.url);

      let file = `data:${ondoc.data.type};base64,${ondoc.data.base64Url}`;
      console.log(file, "AWSYTUIO;P");
      const fileContentBlob = await fetchDataFromDataURL(file);
      const fileContentArrayBuffer = await fileContentBlob.arrayBuffer();
      const fileContentBase64 = btoa(
        String.fromCharCode(...new Uint8Array(fileContentArrayBuffer))
      );
      const fileName = `${folderName}/${document.name}.${document.type}`;
      zipContent.push({ fileName, fileContentBase64 });
    }

    const zip = new JSZip();
    zipContent.forEach(({ fileName, fileContentBase64 }) => {
      zip.file(fileName, fileContentBase64, { base64: true });
    });

    zip.generateAsync({ type: "blob" }).then((content) => {
      const zipUrl = URL.createObjectURL(content);
      const zipLink = document.createElement("a");
      zipLink.href = zipUrl;
      zipLink.download = `${folderName}.zip`;
      zipLink.click();
    });
  };

  // const documents = [
  //   {
  //     id: 1,
  //     url: SampleDoc,
  //     name: "Inaugural Resolution",
  //     size: 293333,
  //     type: "pdf",
  //     base64: "",
  //   },
  //   {
  //     id: 2,
  //     url: SampleDoc,
  //     name: "Appointment of First Director",
  //     size: 293333,
  //     type: "pdf",
  //     base64: "",
  //   },
  //   {
  //     id: 3,
  //     url: SampleDoc,
  //     name: "Resolution of Share Allotment",
  //     size: 293333,
  //     type: "pdf",
  //     base64: "",
  //   },
  //   {
  //     id: 4,
  //     url: SampleDoc,
  //     name: "Corporate Seal Draft",
  //     size: 293333,
  //     type: "pdf",
  //     base64: "",
  //   },
  //   {
  //     id: 5,
  //     url: SampleDoc,
  //     name: "Directors Consent Letter",
  //     size: 293333,
  //     type: "pdf",
  //     base64: "",
  //   },
  //   {
  //     id: 6,
  //     url: SampleDoc,
  //     name: "Customized M&AA",
  //     size: 293333,
  //     type: "pdf",
  //     base64: "",
  //   },
  //   {
  //     id: 7,
  //     url: SampleDoc,
  //     name: "UBO Source of Funds Declaration",
  //     size: 293333,
  //     type: "pdf",
  //     base64: "",
  //   },
  //   {
  //     id: 8,
  //     url: SampleDoc,
  //     name: "Incorporation Application Form",
  //     size: 293333,
  //     type: "pdf",
  //     base64: "",
  //   },
  //   {
  //     id: 9,
  //     url: SampleDoc,
  //     name: "AML Verification Guidelines",
  //     size: 293333,
  //     type: "pdf",
  //     base64: "",
  //   },
  //   {
  //     id: 10,
  //     url: SampleDoc,
  //     name: "Transfer-In Form for BVIBC",
  //     size: 293333,
  //     type: "pdf",
  //     base64: "",
  //   },
  //   {
  //     id: 11,
  //     url: SampleDoc,
  //     name: "Written Confirmations-Virtual Assets",
  //     size: 293333,
  //     type: "pdf",
  //     base64: "",
  //   },
  //   {
  //     id: 12,
  //     url: SampleDoc,
  //     name: "Terms Of Business Agreement",
  //     size: 293333,
  //     type: "pdf",
  //     base64: "",
  //   },
  // ];
  const openModalOnClick = () => {
    setViewModal(true);
  };

  const fetchLiveUrl = async (url) => {
    try {
      const data = { url: url };
      const obj = {
        method: "POST",
        url: `/file/get`,
        data: data,
      };

      return await fetchApi(obj);
    } catch (error) {
      throw error;
    }
  };
  const downloadDocument = async (url, name) => {
    let data = await fetchLiveUrl(url);

    downloadBase64File(data?.data?.base64Url, `${name}.${data?.data?.type}`);
  };
  const saveOnClick = () => {
    saveData(selectedId);
  };
  const saveData = async (selectedId) => {
    let formValue = formRef.current.getPayload();
    let data = document;
    data.name = formValue.name;
    data.position = formValue.position;
    console.log(data, "werytuyiuo");

    const payload = {
      method: "POST",
      url: selectedId
        ? `/preIncorpMaterialsupdate/${selectedId}`
        : `/preIncorpMaterials`,
      data: data,
    };

    fetchApi(payload)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          sendNotify("success", res?.message);
          formRef.current.clearForm();
          setFormData({});
          setViewModal(false);
        } else {
          sendNotify("error", res?.message);
        }
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };

  function storeDocuments(file) {
    setDocument(file);
  }

  const handleOnChange = async (value) => {
    setFormData(value);
  };

  return (
    <div>
      <div className="page-header">
        <h1>Pre-Incorp Supported Documents</h1>
        {/* <Link to={"/app/incorporation-request"} className="primary-btn">
          Export
        </Link> */}{" "}
        <button className="primary-btn" onClick={openModalOnClick}>
          Add Documents
        </button>
      </div>
      <div className="page-content">
        <p className="head-label mb-4">Select Documents to Export :</p>
        <div className="d-flex gap-3 align-items-start align-items-md-center flex-column flex-md-row mb-4">
          <Checkbox onChange={onSelectAll} className="custom-checkbox">
            Select All
          </Checkbox>
          <button
            className="outline-btn"
            onClick={() => {
              if (selectedCatIds.length > 0) {
                DownloadDoc();
              } else {
                sendNotify("error", "Please Select a Document");
              }
            }}
          >
            <i className="far fa-arrow-to-bottom"></i> Download Selected
          </button>
        </div>
        <div className="row mb-4 gap-ms-2">
          <Each
            array={documents}
            render={(item) => (
              <div className="col-md-3">
                <Checkbox
                  // onChange={onChange}
                  // checked={selectAll}
                  onClick={() => onSelectOne(item.id)}
                  checked={selectedCatIds.indexOf(item.id) >= 0}
                  className="custom-checkbox mb-2"
                >
                  {item.name}
                </Checkbox>
                <UploadZ
                  file={item}
                  downloadOnly={true}
                  downloadDocument={downloadDocument}
                />
              </div>
            )}
          />
        </div>
        <Modal
          title={viewDocName}
          className="custom-modal"
          open={docModal}
          width={1000}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="document-view">
            <iframe
              src={viewDoc}
              frameBorder="0"
              height="100%"
              width="100%"
            ></iframe>
          </div>
        </Modal>
        <Modal
          title={"Pre-Incorp Materials"}
          className="custom-modal"
          open={viewmodal}
          width={900}
          onOk={saveOnClick}
          onCancel={handleCancel}
        >
          <FormZ
            formClass="row gy-3 mb-4"
            childClass="col-md-5 mb-0"
            labelClass="mb-1"
            inputClass="w-100"
            formSchema={formSchema}
            onChange={handleOnChange}
            formData={formData}
            ref={formRef}
          />
          <div className="row">
            <div className="col-md-5">
              <UploadZ onStoreFile={storeDocuments} fileName={formData.name} />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
