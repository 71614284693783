import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchApi, convertQueryParams } from "../../helper";

export const getUnreadMessageCount = createAsyncThunk(
  "messages/getUnreadMessageCount",
  async (params) => {
    const query = convertQueryParams(params);
    const payload = {
      method: "get",
      url: `/message/getUnreadMessageCount?${query}`,
    };
    const res = await fetchApi(payload).then((res) => res.data);
    return res;
  }
);

export const getContacts = createAsyncThunk(
  "messages/getContacts",
  async (params) => {
    const query = convertQueryParams(params);
    const payload = {
      method: "get",
      url: `/message/getContacts?${query}`,
    };
    const res = await fetchApi(payload).then((res) => res.data);
    return res;
  }
);

export const getUserContacts = createAsyncThunk(
  "messages/getUserContacts",
  async (params) => {
    const query = convertQueryParams(params);
    const payload = {
      method: "get",
      url: `/message/getContactsUser?${query}`,
    };
    const res = await fetchApi(payload).then((res) => res.data);
    return res;
  }
);

export const getAllContacts = createAsyncThunk(
  "messages/getAllContacts",
  async (params) => {
    const query = convertQueryParams(params);
    const payload = {
      method: "get",
      url: `/message/getAllContacts?${query}`,
    };
    const res = await fetchApi(payload).then((res) => res.data);
    return res;
  }
);

const messageSlice = createSlice({
  name: "message",
  initialState: {
    unreadMessageCount: {
      isLoading: false,
      data: null,
      isError: false,
    },
    contacts: {
      isLoading: false,
      data: null,
      isError: false,
    },
    userContacts: {
      isLoading: false,
      data: null,
      isError: false,
    },
    allContacts: {
      isLoading: false,
      data: null,
      isError: false,
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUnreadMessageCount.pending, (state) => {
      state.unreadMessageCount.isLoading = true;
    });
    builder.addCase(getUnreadMessageCount.fulfilled, (state, action) => {
      state.unreadMessageCount.isLoading = false;
      state.unreadMessageCount.data = action.payload;
    });
    builder.addCase(getUnreadMessageCount.rejected, (state, action) => {
      state.unreadMessageCount.isLoading = false;
      state.unreadMessageCount.isError = true;
      console.error("Error fetching unread message count:", action.payload);
    });

    builder.addCase(getContacts.pending, (state) => {
      state.contacts.isLoading = true;
    });
    builder.addCase(getContacts.fulfilled, (state, action) => {
      state.contacts.isLoading = false;
      state.contacts.data = action.payload;
    });
    builder.addCase(getContacts.rejected, (state, action) => {
      state.contacts.isLoading = false;
      state.contacts.isError = true;
      console.error("Error fetching contacts:", action.payload);
    });

    builder.addCase(getUserContacts.pending, (state) => {
      state.userContacts.isLoading = true;
    });
    builder.addCase(getUserContacts.fulfilled, (state, action) => {
      state.userContacts.isLoading = false;
      state.userContacts.data = action.payload;
    });
    builder.addCase(getUserContacts.rejected, (state, action) => {
      state.userContacts.isLoading = false;
      state.userContacts.isError = true;
      console.error("Error fetching user contacts:", action.payload);
    });

    builder.addCase(getAllContacts.pending, (state) => {
      state.allContacts.isLoading = true;
    });
    builder.addCase(getAllContacts.fulfilled, (state, action) => {
      state.allContacts.isLoading = false;
      state.allContacts.data = action.payload;
    });
    builder.addCase(getAllContacts.rejected, (state, action) => {
      state.allContacts.isLoading = false;
      state.allContacts.isError = true;
      console.error("Error fetching user contacts:", action.payload);
    });
  },
});

export default messageSlice.reducer;
