import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip, Spin, Modal, Pagination } from "antd";
import socketIOClient from "socket.io-client";
import { getEmail } from "../../store/email/emailSlice";

import SampleDoc from "../../assets/pdf/sample.pdf";

import Logo from "../../assets/img/logo-icon.png";
import { PublicClientApplication } from "@azure/msal-browser";
import {
  fetchApi,
  sendNotify,
  convertQueryParams,
  customPagination,
  timeDiff,
  getQuery,
} from "../../helper";
import moment from "moment";

const socket = socketIOClient(
  process.env.REACT_APP_API_URL.split("/api", 1)[0]
);

const msalConfig = {
  auth: {
    clientId: "ccc3be67-f9b4-4d62-87d2-edc00158adc1",
    authority:
      "https://login.microsoftonline.com/6b59b15f-3b7e-4192-a75e-8205f2d464df",
    redirectUri: "http://localhost:3000/",
    // redirectUri: "https://dev-galactic.northlark.com/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};
const loginRequest = {
  scopes: ["openid", "profile", "email", "Mail.Read"],
};

export default function Mail() {
  const email = useSelector((state) => state.email);
  const query = getQuery();
  const lastChat = useRef(null);
  const [to, setTo] = useState("");
  const [cc, setCc] = useState("");
  const [bcc, setBcc] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [pageSizeTotal, setPageSizeTotal] = useState(10);
  const [emails, setEmails] = useState([]);
  const [tab, setTab] = useState("Inbox");
  const [page, setPage] = useState(1);
  const [attachmentsModal, setAttachmentsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [newEmails, setNewEmails] = useState(0);
  const [selectedMail, setSelectedMail] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [accessUser, setAccessUser] = useState({});
  const [accessToken, setAccessToken] = useState(null);
  const [app, setApp] = useState(null);
  const [initialized, setInitialized] = useState(false);

  const [queryParams, setQueryParams] = useState({
    _start: 0,
    _limit: 10,
    type: "inbox",
  });

  const dispatch = useDispatch();
  let local = typeof window !== "undefined" ? localStorage : null;
  useEffect(() => {
    configureSocket();
    const storedToken = localStorage.getItem("accessToken");
    if (storedToken) setAccessToken(storedToken);
  }, []);

  useEffect(() => {
    const param = { ...queryParams };
    if (query?.mailId) {
      param.mailId = query?.mailId;
    }
    dispatch(getEmail(param));
  }, [dispatch, queryParams]);

  useEffect(() => {
    getuser();
    if (email.data) {
      setEmails(email.data.mails);
      setTotalCount(email.data.totalCount);
    }
  }, [email]);

  const handleRefresh = async () => {};

  const configureSocket = () => {
    let param = { ...queryParams };
    socket.on("new-email", ({ numNewMsgs }) => {
      setNewEmails(numNewMsgs);
      notifyUser(numNewMsgs);
      dispatch(getEmail(param));
    });
    socket.on("device-code", (numNewMsgs) => {
      setNewEmails(numNewMsgs);
      notifyUser(numNewMsgs);
      dispatch(getEmail(param));
    });

    return () => {
      socket.off("new-email");
      socket.off("device-code");
    };
  };
  const notifyUser = (notification) => {
    const notifyContent = {
      title: "Notification from Galactic mail!",
      body: "You got a new mail",
      icon: Logo,
      link: notification.link,
    };

    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      const messageNotify = new Notification(notifyContent.title, {
        body: notifyContent.body,
        icon: notifyContent.icon,
      });
      messageNotify.onclick = () => window.open(notifyContent.link);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          const messageNotify = new Notification(notifyContent.title, {
            body: notifyContent.body,
            icon: notifyContent.icon,
          });
          messageNotify.onclick = () => window.open(notifyContent.link);
        }
      });
    }
  };
  useEffect(() => {
    initializeMSAL();
  }, []);

  const initializeMSAL = async () => {
    try {
      let msalApp = { ...app };
      msalApp = new PublicClientApplication(msalConfig);
      await msalApp.initialize();
      await msalApp.handleRedirectPromise();
      setApp(msalApp);
      setInitialized(true);
    } catch (error) {
      sendNotify("error", error?.message);
    }
  };

  const login = async () => {
    try {
      if (!initialized || !app) {
        return;
      }
      const loginResponse = await app.loginPopup(loginRequest);
      const tokenResponse = await app.acquireTokenSilent({
        ...loginRequest,
        account: loginResponse.account,
      });
      let token = tokenResponse.accessToken;
      localStorage.setItem("accessToken", token);
      setAccessToken(tokenResponse.accessToken);
      let param = { ...queryParams };
      dispatch(getEmail(param));
    } catch (error) {
      sendNotify("error", error?.message);
    }
  };
  const logout = async () => {
    app.logout();
    localStorage.removeItem("accessToken");
    setTimeout(() => {
      window.location.href = `/app/mail`;
    }, 1000);
  };

  const sendEmail = async () => {
    if (to && subject && accessToken && text) {
      const formData = new FormData();
      formData.append("to", to);
      formData.append("cc", cc);
      formData.append("bcc", bcc);
      formData.append("subject", subject);
      formData.append("body", text);
      formData.append("accessToken", accessToken);
      attachments.forEach((file) => {
        formData.append("attachments", file);
      });

      const payload = {
        method: "POST",
        url: `/email/send-email`,
        data: formData,
        headers: {
          authorizationout: local.accessToken,
          "Content-Type": "multipart/form-data",
        },
      };

      try {
        const res = await fetchApi(payload).then((response) => {
          if (response.error.response.status == 500) {
            sendNotify(
              "error",
              response.error.response.data || "Failed to send email"
            );
          } else {
            sendNotify("success", response);
            clearValueOnClick();
          }
        });
      } catch (error) {
        sendNotify("error", error?.message || "Failed to send email");
      }
    } else {
      const errors = [];

      if (!to) errors.push("To email address is required");
      if (!subject) errors.push("Subject is required");
      if (!accessToken) errors.push("Your authentication token is expired");
      if (!text) errors.push("Email body is required");

      if (errors.length > 0) {
        const errorMessage = errors.join(", ");
        sendNotify("error", errorMessage);
      }
    }
  };
  const getuser = async () => {
    try {
      let payload = {
        method: "GET",
        url: `https://graph.microsoft.com/v1.0/me`,
        headers: {
          Authorization: `Bearer ${local.accessToken}`,
        },
      };
      let data = await fetchApi(payload)
        .then((res) => {
          if (res.error) {
            localStorage.removeItem("accessToken");
          } else {
            return res;
          }
        })
        .catch((error) => {
          sendNotify("error", error?.message);
        });
      if (data) {
        setAccessUser(data);
      }
    } catch (error) {
      console.error(
        "Error fetching user info:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setAttachments((prevAttachments) => [...prevAttachments, ...newFiles]);
  };

  const removeFile = (indexToRemove) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter((_, index) => index !== indexToRemove)
    );
  };

  const changeTab = async (value) => {
    setLoading(true);
    let type;

    if (value === "Inbox") {
      type = "inbox";
    } else if (value === "Sent Mails") {
      type = "sent";
    } else if (value === "Drafts") {
      type = "drafts";
    } else {
      type = "deleted";
      setLoading(false);
    }

    if (type) {
      let param = { ...queryParams };
      param.type = type;
      dispatch(getEmail(param));
      setLoading(false);
    }

    setTab(value);
  };

  const handleOk = () => {
    setAttachmentsModal(false);
  };
  const handleCancel = () => {
    setAttachments([]);
    setAttachmentsModal(false);
  };
  const attachmentModalOnClick = async () => {
    setAttachmentsModal(true);
  };

  const clearValueOnClick = async () => {
    setTo("");
    setCc("");
    setBcc("");
    setSubject("");
    setText("");
    setAttachments([]);
  };
  const onSelectEmail = async (id) => {
    if (local.accessToken) {
      let payload = {
        method: "GET",
        url: `/email/emails/${id}`,
        headers: {
          authorizationout: local.accessToken,
        },
      };
      let data = await fetchApi(payload).then((res) => {
        return res;
      });
      setSelectedId(id);
      setSelectedMail(data);
    }
  };

  const getPagination = async (current, pageSize) => {
    let type = "";
    if (tab === "Inbox") {
      type = "inbox";
    } else if (tab === "Sent Mails") {
      type = "sent";
    } else if (tab === "Drafts") {
      type = "drafts";
    } else {
      type = "deleted";
      setLoading(false);
    }

    let param = { ...queryParams };
    param._start = (current - 1) * pageSize;
    param.type = type;

    dispatch(getEmail(param));
    setLoading(false);
    setQueryParams(param);
  };

  const pageSizeChange = async (page, pageSize) => {
    let type = "";
    if (tab === "Inbox") {
      type = "inbox";
    } else if (tab === "Sent Mails") {
      type = "sent";
    } else if (tab === "Drafts") {
      type = "drafts";
    } else {
      type = "deleted";
      setLoading(false);
    }
    let ctr = {};
    ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
    ctr._limit = pageSize;
    ctr.type = type;
    dispatch(getEmail(ctr));

    lastChat.current?.scrollIntoView({
      behavior: "auto",
      block: "end",
    });
    setPage(page);
    setPageSizeTotal(pageSize);
  };

  const downloadAttachment = async (attachment) => {
    try {
      let payload = {
        method: "GET",
        url: `https://graph.microsoft.com/v1.0/me/messages/${selectedId}/attachments/${attachment.id}/$value`,
        headers: {
          Authorization: `Bearer ${local.accessToken}`,
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      };
      let response = await fetchApi(payload).then((res) => {
        return res;
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", attachment.name);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading attachment:", error);
    }
  };

  const getInitials = (name) => {
    const nameParts = name ? name.split(" ") : "";
    const firstNameInitial = nameParts[0]?.charAt(0)?.toUpperCase() || "";
    const lastNameInitial = nameParts[1]?.charAt(0)?.toUpperCase() || "";
    return firstNameInitial + lastNameInitial;
  };

  const generateImage = (name, index) => {
    const initials = getInitials(name);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = 100;
    canvas.height = 100;

    const radius = 57;
    ctx.fillStyle = "#595ca6";
    drawRoundedRect(ctx, 0, 0, canvas.width, canvas.height, radius);
    ctx.fill();

    ctx.font = "bold 40px Arial";
    ctx.fillStyle = "#fff";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    ctx.fillText(initials, canvas.width / 2, canvas.height / 2);

    return canvas.toDataURL("image/png");
  };

  const drawRoundedRect = (ctx, x, y, width, height, radius) => {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
  };
  return (
    <div>
      <div className="d-flex justify-content-end me-3 mt-1">
        {accessUser?.givenName ? (
          <button className="primary-btn outline-btn" onClick={logout}>
            Log Out
          </button>
        ) : null}
      </div>
      {accessToken ? (
        <div className="mail-box p-4">
          <div className="mail-box-menu">
            <div className="mail-author">
              <img
                src={generateImage(accessUser && accessUser?.givenName)}
                title={accessUser?.givenName}
              />
              <div>
                <h6>{accessUser.givenName}</h6>
                <p>{accessUser.mail}</p>
              </div>
            </div>
            <button
              className="compose-mail-btn"
              onClick={() => changeTab("Compose Mail")}
            >
              Compose Mail{" "}
            </button>
            <ul>
              <li
                className={tab == "Inbox" ? `active` : ""}
                onClick={() => changeTab("Inbox")}
              >
                <i className="far fa-inbox"></i>
                <span>Inbox</span>
              </li>
              <li
                className={tab == "Sent Mails" ? `active` : ""}
                onClick={() => changeTab("Sent Mails")}
              >
                <i className="far fa-paper-plane"></i>
                <span>Sent Mails</span>
              </li>
              <li
                className={tab == "Drafts" ? `active` : ""}
                onClick={() => changeTab("Drafts")}
              >
                <i className="far fa-file-edit"></i>
                <span>Drafts</span>
              </li>
              <li
                className={tab == "Deleted" ? `active` : ""}
                onClick={() => changeTab("Deleted")}
              >
                <i className="far fa-trash"></i>
                <span>Deleted</span>
              </li>
            </ul>
          </div>

          {tab !== "Compose Mail" ? (
            <div className="mail-box-list">
              <label>{`${tab} - ${totalCount}`}</label>

              <div className="mail-box-list-scroll" ref={lastChat}>
                {!loading ? (
                  emails.map((e, index) => {
                    let url = generateImage(
                      e?.from?.emailAddress?.name || "Unknown",
                      index
                    );
                    return (
                      <div
                        key={e.id}
                        className={`mail-box-item ${e.read ? "" : "unread"}${
                          selectedId == e.seqno ? "opened" : ""
                        }`}
                        onClick={() => onSelectEmail(e.id)}
                      >
                        {url && <img src={url} alt="Initials Logo" />}

                        <div className="ps-3">
                          <p>{`${moment(e.receivedDateTime).format(
                            "DD-MMM-YYYY"
                          )}`}</p>
                          <h4>{e.from?.emailAddress?.address || "Unknown"}</h4>
                          <p>
                            To:{" "}
                            {e.toRecipients
                              .map(
                                (recipient) => recipient?.emailAddress?.address
                              )
                              .join(", ")}
                          </p>
                          <h6>{e.subject}</h6>
                          {e.ccRecipients.length > 0 && (
                            <p>
                              CC:{" "}
                              {e.ccRecipients
                                .map(
                                  (recipient) =>
                                    recipient?.emailAddress?.address
                                )
                                .join(", ")}
                            </p>
                          )}
                          {e.bccRecipients.length > 0 && (
                            <p>
                              BCC:{" "}
                              {e.bccRecipients
                                .map(
                                  (recipient) =>
                                    recipient?.emailAddress?.address
                                )
                                .join(", ")}
                            </p>
                          )}
                          <p>{e.bodyPreview}</p>
                          {e.hasAttachments && <p>Attachments: Yes</p>}
                        </div>
                        <div className="text-end w-100">
                          <span className="time">
                            {timeDiff(e.receivedDateTime)}
                          </span>
                          <span className="attach-icon mt-2">
                            <i className="fal fa-paperclip"></i>
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <Spin />
                )}
                {!loading && (
                  <>
                    <div>
                      <Pagination
                        total={totalCount}
                        className="custom-pagination"
                        itemRender={customPagination}
                        defaultCurrent={1}
                        current={page}
                        defaultPageSize={10}
                        pageSize={pageSizeTotal}
                        pageSizeOptions={[
                          "5",
                          "10",
                          "25",
                          "50",
                          "100",
                          "200",
                          "500",
                        ]}
                        onChange={pageSizeChange}
                        showSizeChanger={true}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : null}

          {tab == "Compose Mail" ? (
            <div className="mail-box-content mail-box-list-scroll">
              <h2 className="subject-text">Send Email</h2>
              <div className="mail-box-content-item">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="another">
                    <div className="row ps-3 px-2 mt-2">
                      <div className="col-md-3">
                        <button className="primary-btn" onClick={sendEmail}>
                          Send
                        </button>
                      </div>
                      <div className="col-md-7"></div>
                      <div className="col-md-2">
                        <button
                          className="primary-btn"
                          onClick={() => attachmentModalOnClick()}
                        >
                          <i className="fas fa-paperclip"></i>
                        </button>
                      </div>

                      <div className="col-md-6 mt-2">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="To"
                          value={to}
                          onChange={(e) => setTo(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Cc"
                          value={cc}
                          onChange={(e) => setCc(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Bcc"
                          value={bcc}
                          onChange={(e) => setBcc(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Subject"
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                        />
                      </div>
                      <div className="col-md-12 mt-2">
                        <textarea
                          cols="40"
                          rows="15"
                          className="form-control"
                          placeholder="Text"
                          value={text}
                          onChange={(e) => setText(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : selectedId ? (
            <div className="mail-box-content mail-box-list-scroll">
              <h2 className="subject-text">{selectedMail.subject}</h2>
              <div className="mail-box-content-item">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="another">
                    <img
                      src={generateImage(
                        selectedMail?.from?.emailAddress?.name
                      )}
                      alt=""
                    />
                    <div className="ps-3">
                      <Tooltip
                        title={selectedMail?.from?.emailAddress?.address}
                      >
                        <h4>{selectedMail?.from?.emailAddress?.name}</h4>
                      </Tooltip>
                      <h6>
                        <span>From:</span>
                        {selectedMail?.from?.emailAddress?.address}
                      </h6>
                      <h6>
                        <span>To:</span>{" "}
                        {selectedMail.toRecipients
                          .map((recipient) => recipient?.emailAddress?.address)
                          .join(", ")}
                      </h6>
                      {selectedMail.ccRecipients.length > 0 && (
                        <h6>
                          <span>Cc:</span>
                          {selectedMail.ccRecipients
                            .map(
                              (recipient) => recipient?.emailAddress?.address
                            )
                            .join(", ")}
                        </h6>
                      )}
                      {selectedMail?.ccRecipients?.length > 0 && (
                        <h6>
                          <span>BCC:</span>
                          {selectedMail?.bccRecipients
                            .map(
                              (recipient) => recipient?.emailAddress?.address
                            )
                            .join(", ")}
                        </h6>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="d-flex gap-3">
                      <button className="mail-btn" title="Replay">
                        <i className="far fa-reply"></i>
                      </button>
                      <button className="mail-btn" title="Replay All">
                        <i className="far fa-reply-all"></i>
                      </button>
                      <button className="mail-btn" title="Forward">
                        <i className="far fa-share"></i>
                      </button>
                      <button className="mail-btn delete-btn" title="Delete">
                        <i className="far fa-trash-alt"></i>
                      </button>
                      <button className="mail-btn icon-btn">
                        <i className="far fa-ellipsis-v"></i>
                      </button>
                    </div>
                    <p className="mail-date-time text-end">
                      {moment(selectedMail.createdDateTime).format(
                        "ddd D-M-YYYY h:mm A"
                      )}
                    </p>
                  </div>
                </div>
                <div className="mail-attachments my-4">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    {selectedMail?.attachments?.length ? (
                      <h6>{selectedMail?.attachments?.length} Attachments</h6>
                    ) : null}
                    <div className="d-flex gap-3">
                      <button className="mail-btn">
                        <i className="far fa-chevron-double-down"></i> View All
                      </button>

                      <ul>
                        {selectedMail?.attachments?.map((attachment) => (
                          <li key={attachment.id}>
                            <button
                              className="mail-btn"
                              onClick={() => downloadAttachment(attachment)}
                            >
                              <i className="far fa-arrow-to-bottom"></i>{" "}
                              Download({(attachment.size / 1024).toFixed(2)} )
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="row"></div>
                </div>
                <div className="mail-content px-4">
                  <p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedMail.body.content,
                      }}
                    />
                    {selectedMail?.attachments ? <p>Attachments</p> : null}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="mail-box-content mail-box-list-scroll">
              <div className="mail-box-content-item">
                <h2 className="subject-text"> Select an item to read</h2>
                <span>Nothing is selected</span>
              </div>
            </div>
          )}
          <Modal
            title={"Attachments"}
            className="custom-modal"
            open={attachmentsModal}
            width={800}
            onOk={handleOk}
            okText="OK"
            onCancel={handleCancel}
          >
            <div className="col-md-6 mt-2">
              <label>Add Attachment:</label>
              <input
                className="form-control"
                type="file"
                multiple
                onChange={handleFileChange}
              />
            </div>
            <br />
            {attachments.length > 0 && (
              <div>
                <h4>Attachments</h4>
                <ul>
                  {attachments.map((file, index) => (
                    <li key={index}>
                      {file.name}
                      <button
                        className="del-upload-btn ps-3"
                        type="button"
                        onClick={() => removeFile(index)}
                      >
                        <i className="far fa-trash-alt"></i>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Modal>

          <div></div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
          <button
            className="btn btn-dark d-flex align-items-center justify-content-center p-2"
            onClick={login}
            style={{ width: "250px", borderRadius: "5px" }}
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg"
              alt="Microsoft Logo"
              className="mr-2"
              style={{ width: "20px", height: "20px" }}
            />
            <span style={{ fontWeight: "bold" }}>
              &nbsp; &nbsp;Sign in with Microsoft
            </span>
          </button>
        </div>
      )}
    </div>
  );
}
