import React, { useState, useRef } from "react";
import { Table, Popover, Select } from "antd";
import { Link } from "react-router-dom";

import TabZ from "../../components/TabZ";
import TagZ from "../../components/TagZ";
import TableSortArrows from "../../components/tableSortArrows";

import InProgressIcon from "../../assets/img/in-progress-icon.svg";
import ApprovedIcon from "../../assets/img/approved-icon.svg";

import AssigneeImg from "../../assets/img/notify-avatar.png";

import { downloadAsCSV, customPagination } from "../../helper";
import TableFilterZ from "../../components/TableFilterZ";

export default function OnboardEntity() {
  const [search, setSearch] = useState("");

  let assigneeList = (
    <div className="assignee-list">
      <div className="assignee-item">
        <img src={AssigneeImg} alt="" />
        <p>John Doe</p>
      </div>
      <div className="assignee-item">
        <img src={AssigneeImg} alt="" />
        <p>John Doe</p>
      </div>
      <div className="assignee-item">
        <img src={AssigneeImg} alt="" />
        <p>John Doe</p>
      </div>
      <div className="assignee-item">
        <img src={AssigneeImg} alt="" />
        <p>John Doe</p>
      </div>
      <div className="assignee-item">
        <img src={AssigneeImg} alt="" />
        <p>John Doe</p>
      </div>
      <div className="assignee-item">
        <img src={AssigneeImg} alt="" />
        <p>John Doe</p>
      </div>
    </div>
  );

  const columns = [
    {
      title: "Reference ID",
      dataIndex: "referenceID",
      filterType: "text",
      sorter: (a, b) => a.referenceID - b.referenceID,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Name",
      dataIndex: "entityName",
      filterType: "text",
      sorter: (a, b) => a.entityName - b.entityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Request Type",
      dataIndex: "requestType",
      filterType: "select",
      filterOptions: [
        {
          value: "new-incorporation",
          label: "New Incorporation",
        },
        {
          value: "transfer-in",
          label: "Transfer In",
        },
      ],
      sorter: (a, b) => a.requestType - b.requestType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // View Analyst Only
    // {
    //   title: "Client",
    //   dataIndex: "client",
    //   sorter: (a, b) => a.client - b.client,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    // },
    // {
    //   title: "Assigned to",
    //   dataIndex: "assignee",
    //   sorter: (a, b) => a.assignee - b.assignee,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    //   render: (_, { assignee }) => {
    //     if (assignee) {
    //       return (
    //         <div className="assignee">
    //           <span className="position-relative">
    //             <button className="remove-btn">
    //               <i className="fas fa-times-circle"></i>
    //             </button>
    //             <img src={AssigneeImg} alt="" />
    //           </span>
    //           <p>John Doe</p>
    //         </div>
    //       );
    //     } else {
    //       return (
    //         <Popover
    //           content={assigneeList}
    //           trigger="click"
    //           className="custom-popover"
    //         >
    //           <div className="assignee">
    //             <i className="fal fa-plus-circle"></i> <p>Add Assignee</p>
    //           </div>
    //         </Popover>
    //       );
    //     }
    //   },
    // },
    {
      title: "Submitted",
      dataIndex: "submitted",
      filterType: "date",
      sorter: (a, b) => a.submitted - b.submitted,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Status",
      dataIndex: "status",
      filterType: "select",
      filterOptions: [
        { label: "New", value: "new" },
        { label: "Review", value: "review" },
        { label: "Resubmitted", value: "resubmitted" },
        { label: "In Progress", value: "in-progress" },
        { label: "Rejected", value: "rejected" },
      ],
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => {
        let curr = "";
        switch (status) {
          case "New":
            curr = "new";
            break;
          case "Resubmitted":
            curr = "resubmitted";
            break;
          case "In Progress":
            curr = "in-progress";
            break;
          case "Rejected":
            curr = "rejected";
            break;
        }
        if (status.includes("Review")) {
          curr = "review";
        }
        return <TagZ status={curr} statusText={status} />;
      },
    },
  ];

  const data = [
    {
      key: "1",
      id: "1",
      referenceID: "R00007",
      entityName: "Sample",
      requestType: "New Incorporation",
      client: "Company Name",
      submitted: "12 May 2024, 12.54 PM",
      rmName: "Sample",
      status: "New",
    },
    {
      key: "2",
      id: "2",
      referenceID: "R00006",
      entityName: "Sample",
      requestType: "Transfer In",
      client: "Company Name",
      assignee: "id",
      submitted: "12 May 2024, 12.54 PM",
      rmName: "Sample",
      status: "Resubmitted",
    },
    {
      key: "3",
      id: "3",
      referenceID: "R00005",
      entityName: "Sample",
      requestType: "New Incorporation",
      client: "Company Name",
      assignee: "id",
      submitted: "12 May 2024, 12.54 PM",
      rmName: "Sample",
      status: "In Progress",
    },
    {
      key: "4",
      id: "4",
      referenceID: "R00004",
      entityName: "Sample",
      requestType: "New Incorporation",
      client: "Company Name",
      assignee: "id",
      submitted: "12 May 2024, 12.54 PM",
      rmName: "Sample",
      status: "In Lead Review",
    },
    {
      key: "5",
      id: "5",
      referenceID: "R00003",
      entityName: "Sample",
      requestType: "New Incorporation",
      client: "Company Name",
      assignee: "id",
      submitted: "12 May 2024, 12.54 PM",
      rmName: "Sample",
      status: "In MLRO Review",
    },
    {
      key: "6",
      id: "6",
      referenceID: "R00002",
      entityName: "Sample",
      requestType: "New Incorporation",
      client: "Company Name",
      assignee: "id",
      submitted: "12 May 2024, 12.54 PM",
      rmName: "Sample",
      status: "In MLRO Review",
    },
    {
      key: "7",
      id: "7",
      referenceID: "R00001",
      entityName: "Sample",
      requestType: "New Incorporation",
      client: "Company Name",
      assignee: "id",
      submitted: "12 May 2024, 12.54 PM",
      rmName: "Sample",
      status: "Rejected",
    },
  ];

  const labels = [
    {
      key: 1,
      label: (
        <>
          <img src={ApprovedIcon} alt="" /> Completed
        </>
      ),
    },
    {
      key: 2,
      label: (
        <>
          <img src={InProgressIcon} alt="" /> In Progress
        </>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows
      // );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const getFilter = (filters) => {
    // console.log(filters);
  };

  return (
    <div>
      <div className="page-header">
        <div className="d-flex align-items-center gap-3 custom-form">
          <h1>Onboard&nbsp;Entity</h1>
          <div className="col-md-12">
            <div className="search-box w-100">
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search by reference ID or entity name"
              />
              <span className="search-icon">
                <i className="fal fa-search"></i>
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex gap-3">
          <button className="secondary-btn">Document Guidelines</button>
          <Popover
            className="custom-popover"
            placement="bottomRight"
            content={
              <div className="certificate-links">
                <Link to={"/app/onboard-entity/create"}>New Incorporation</Link>
                <Link to={"/app/onboard-entity"}>Transfer In</Link>
              </div>
            }
          >
            <button className="primary-btn">
              Create New <i className="far fa-angle-down"></i>
            </button>
          </Popover>
        </div>
      </div>
      <div className="page-content">
        <div className="d-flex justify-content-between align-items-center">
          <TabZ
            labels={labels}
            defaultActive={"2"}
            customClass={"table-tabs"}
          />
          <TableFilterZ tableCol={columns} onChange={getFilter} />
        </div>
        <Table
          className="custom-table mt-4 mb-3"
          columns={columns}
          dataSource={data}
          rowSelection={{ type: "checkbox", ...rowSelection }}
          pagination={{
            className: "custom-pagination",
            itemRender: customPagination,
          }}
        />
      </div>
    </div>
  );
}
