import React from "react";
import { Table, Select } from "antd";

import TableSortArrows from "../components/tableSortArrows";

export default function Reminders() {
  const column1 = [
    {
      title: "Risk Level",
      dataIndex: "riskLevel",
      render: (_, { riskLevel }) => (
        <Select
          className="custom-select w-100px ptb-1"
          placeholder="Select risk level"
          defaultValue={riskLevel}
          options={[
            {
              value: "High",
              label: "High",
            },
            {
              value: "Medium",
              label: "Medium",
            },
            {
              value: "Low",
              label: "Low",
            },
          ]}
        />
      ),
    },
    {
      title: "Risk Review Frequency",
      dataIndex: "riskReview",
      render: (_, { riskReview }) => (
        <Select
          className="custom-select w-150px ptb-1"
          placeholder="Select risk level"
          defaultValue={riskReview}
          options={[
            {
              value: "6m",
              label: "6 (Months)",
            },
            {
              value: "1y",
              label: "1 (Year)",
            },
            {
              value: "2y",
              label: "2 (Years)",
            },
          ]}
        />
      ),
    },
    {
      title: "Reminder Count",
      dataIndex: "reminderCount",
      render: (_, { reminderCount }) => (
        <div className="custom-form">
          <input
            type="number"
            className="w-80px"
            defaultValue={reminderCount}
            placeholder="Enter count"
            min={1}
          />
        </div>
      ),
    },
    {
      title: "Reminder Starts from",
      dataIndex: "reminderStartsFrom",
      render: (_, { reminderStartsFrom }) => (
        <div className="custom-form">
          <input
            type="number"
            className="w-80px"
            defaultValue={reminderStartsFrom}
            placeholder="Enter count"
            min={1}
          />
          &nbsp; Months onwards
        </div>
      ),
    },
    {
      title: "Recurring Time",
      dataIndex: "recurringTime",
      render: (_, { recurringTime }) => (
        <div className="custom-form">
          <input
            type="number"
            className="w-80px"
            defaultValue={recurringTime}
            placeholder="Enter count"
            min={1}
          />
        </div>
      ),
    },
    {
      title: "Reminder Frequency",
      dataIndex: "reminderFrequency",
      render: (_, { reminderFrequency }) => (
        <Select
          className="custom-select w-150px ptb-1"
          placeholder="Select risk level"
          defaultValue={reminderFrequency}
          options={[
            {
              value: "Days",
              label: "Days",
            },
            {
              value: "Months",
              label: "Months",
            },
            {
              value: "Years",
              label: "Years",
            },
          ]}
        />
      ),
    },
  ];

  const column2 = [
    {
      title: "Filing Type",
      dataIndex: "filingType",
      sorter: (a, b) => a.filingType.localeCompare(b.filingType),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 400,
      sorter: (a, b) => a.action.localeCompare(b.action),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Deadline",
      dataIndex: "deadline",
      sorter: (a, b) => a.deadline.localeCompare(b.deadline),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { deadline }) => (
        <div className="custom-form">
          <input
            type="text"
            defaultValue={deadline}
            placeholder="Enter deadline"
          />
        </div>
      ),
    },
    {
      title: "Reminder Count",
      dataIndex: "reminderCount",
      sorter: (a, b) => a.reminderCount.localeCompare(b.reminderCount),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Reminder Starts After",
      dataIndex: "reminderStartsAfter",
      sorter: (a, b) =>
        a.reminderStartsAfter.localeCompare(b.reminderStartsAfter),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Recurring Time",
      dataIndex: "recurringTime",
      sorter: (a, b) => a.recurringTime.localeCompare(b.recurringTime),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Reminder Frequency",
      dataIndex: "reminderFrequency",
      sorter: (a, b) => a.reminderFrequency.localeCompare(b.reminderFrequency),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { reminderFrequency }) => (
        <Select
          className="custom-select w-150px ptb-1"
          placeholder="Select risk level"
          defaultValue={reminderFrequency}
          options={[
            {
              value: "Days",
              label: "Days",
            },
            {
              value: "Week",
              label: "Week",
            },
            {
              value: "Months",
              label: "Months",
            },
            {
              value: "Years",
              label: "Years",
            },
          ]}
        />
      ),
    },
    {
      title: "Default Recipients",
      dataIndex: "defaultRecipients",
      sorter: (a, b) => a.defaultRecipients.localeCompare(b.defaultRecipients),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  const data1 = [
    {
      key: 1,
      riskLevel: "High",
      riskReview: "6m",
      reminderCount: 3,
      reminderStartsFrom: 4.5,
      recurringTime: 15,
      reminderFrequency: "Days",
    },
    {
      key: 2,
      riskLevel: "Medium",
      riskReview: "2y",
      reminderCount: 3,
      reminderStartsFrom: 21,
      recurringTime: 1,
      reminderFrequency: "Months",
    },
    {
      key: 3,
      riskLevel: "Low",
      riskReview: "1y",
      reminderCount: 3,
      reminderStartsFrom: 9,
      recurringTime: 1,
      reminderFrequency: "Years",
    },
  ];

  const data2 = [
    {
      key: 1,
      filingType: "ROD",
      action:
        "Every time a change in director happens (New Appointment, Resignation) The updated ROD should be sent to the business partner team for filing on the same day change happens with the most up to date data in a table format.",
      deadline: "30 days from date of change",
      reminderCount: 2,
      reminderStartsAfter: "5/10/15/20 Days of change in directorship",
      recurringTime: 1,
      reminderFrequency: "Week",
      defaultRecipients: "Business Partner authorized individual",
    },
    {
      key: 2,
      filingType: "Shareholder",
      action:
        "Every time a change in shareholders happen (New allocation, Transfer, Redemption). The updated detailed history of Entity to be sent to the business partner team for filing on the same day of change.",
      deadline: "60 days from date of change",
      reminderCount: 2,
      reminderStartsAfter: "15/30/45 Days of change in shareholding",
      recurringTime: 1,
      reminderFrequency: "Week",
      defaultRecipients: "Business Partner authorized individual",
    },
    {
      key: 3,
      filingType: "Annual Return",
      action:
        "To be activated post update of Year End Date. System to generate email reminder.",
      deadline: `9 months from "Year End Date"`,
      reminderCount: "3, 6, 9",
      reminderStartsAfter: "3/6/8 months from Year End Date",
      recurringTime: "1 to 2",
      reminderFrequency: "Month",
      defaultRecipients:
        "Business Partner authorized individual AND Entity point/s of contact",
    },
    {
      key: 4,
      filingType: "Compliance Filing / Periodic Review",
      action:
        "Based on periodic review timelines per risk category (High, Low, Medium)",
      deadline: "Next review date of entity based on risk level",
      reminderCount: "Please refer risk table below",
      reminderStartsAfter: "Please refer risk table below",
      recurringTime: "Please refer risk table below",
      reminderFrequency: "Please refer risk table below",
      defaultRecipients: "MLRO/Manager",
    },
    {
      key: 5,
      filingType: "BOSS/ES",
      action: "TBC",
      deadline: "TBC",
      reminderCount: "TBC",
      reminderStartsAfter: "TBC",
      recurringTime: "TBC",
      reminderFrequency: "TBC",
      defaultRecipients: "Business Partner authorized individual",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div>
      <div className="page-header">
        <h1>Master Setup</h1>
      </div>
      <div className="page-content">
        <div className="d-flex justify-content-between flex-column flex-md-row align-items-md-center align-items-start mb-3">
          <h6 className="primary-title mb-3 mb-md-0">Filing Setup - Master</h6>
          <button className="primary-btn">Save Changes</button>
        </div>
        <Table
          className="custom-table mb-3"
          columns={column1}
          dataSource={data1}
          rowSelection={{ type: "checkbox", ...rowSelection }}
          pagination={false}
        />
        <div className="d-flex justify-content-between flex-column flex-md-row align-items-md-center align-items-start mb-3">
          <h6 className="primary-title mb-3 mb-md-0">Table 2</h6>
          <button className="primary-btn">Save Changes</button>
        </div>
        <Table
          className="custom-table mb-3"
          columns={column2}
          dataSource={data2}
          scroll={{ x: 2000 }}
          rowSelection={{ type: "checkbox", ...rowSelection }}
          pagination={false}
        />
      </div>
    </div>
  );
}
