import React, { useState, useRef } from "react";
import axios from "axios";
import InhouseTranslation from "./components/inhouse-translation";
import ManualTranslation from "./components/manual-translation";

import FormZ from "../../components/FormZ";
import {
  downloadAsCSV,
  getQuery,
  fetchApi,
  sendNotify,
  convertQueryParams,
  customPagination,
} from "../../helper";
export default function DocTranslation() {
  const [activeChild, setActiveChild] = useState(0);
  const formRef = useRef();
  const [formData, setFormData] = useState({});

  const supportedLanguages = {
    af: "Afrikaans",
    sq: "Albanian",
    am: "Amharic",
    ar: "Arabic",
    hy: "Armenian",
    az: "Azerbaijani",
    eu: "Basque",
    be: "Belarusian",
    bn: "Bengali",
    bs: "Bosnian",
    bg: "Bulgarian",
    ca: "Catalan",
    ceb: "Cebuano",
    "zh-CN": "Chinese (Simplified)",
    "zh-TW": "Chinese (Traditional)",
    co: "Corsican",
    hr: "Croatian",
    cs: "Czech",
    da: "Danish",
    nl: "Dutch",
    en: "English",
    eo: "Esperanto",
    et: "Estonian",
    fi: "Finnish",
    fr: "French",
    fy: "Frisian",
    gl: "Galician",
    ka: "Georgian",
    de: "German",
    el: "Greek",
    gu: "Gujarati",
    ht: "Haitian Creole",
    ha: "Hausa",
    haw: "Hawaiian",
    he: "Hebrew",
    hi: "Hindi",
    hmn: "Hmong",
    hu: "Hungarian",
    is: "Icelandic",
    ig: "Igbo",
    id: "Indonesian",
    ga: "Irish",
    it: "Italian",
    ja: "Japanese",
    jv: "Javanese",
    kn: "Kannada",
    kk: "Kazakh",
    km: "Khmer",
    rw: "Kinyarwanda",
    ko: "Korean",
    ku: "Kurdish",
    ky: "Kyrgyz",
    lo: "Lao",
    la: "Latin",
    lv: "Latvian",
    lt: "Lithuanian",
    lb: "Luxembourgish",
    mk: "Macedonian",
    mg: "Malagasy",
    ms: "Malay",
    ml: "Malayalam",
    mt: "Maltese",
    mi: "Maori",
    mr: "Marathi",
    mn: "Mongolian",
    my: "Myanmar (Burmese)",
    ne: "Nepali",
    no: "Norwegian",
    ny: "Nyanja (Chichewa)",
    or: "Odia (Oriya)",
    ps: "Pashto",
    fa: "Persian",
    pl: "Polish",
    pt: "Portuguese",
    pa: "Punjabi",
    ro: "Romanian",
    ru: "Russian",
    sm: "Samoan",
    gd: "Scots Gaelic",
    sr: "Serbian",
    st: "Sesotho",
    sn: "Shona",
    sd: "Sindhi",
    si: "Sinhala (Sinhalese)",
    sk: "Slovak",
    sl: "Slovenian",
    so: "Somali",
    es: "Spanish",
    su: "Sundanese",
    sw: "Swahili",
    sv: "Swedish",
    tl: "Tagalog (Filipino)",
    tg: "Tajik",
    ta: "Tamil",
    tt: "Tatar",
    te: "Telugu",
    th: "Thai",
    tr: "Turkish",
    tk: "Turkmen",
    uk: "Ukrainian",
    ur: "Urdu",
    ug: "Uyghur",
    uz: "Uzbek",
    vi: "Vietnamese",
    cy: "Welsh",
    xh: "Xhosa",
    yi: "Yiddish",
    yo: "Yoruba",
    zu: "Zulu",
  };
  const options = Object.keys(supportedLanguages).map((key) => ({
    value: key,
    label: supportedLanguages[key],
  }));

  let formSchema = [
    {
      name: "langTo",
      value: "",
      type: "select",
      label: "Translative",
      placeholder: "select Language",
      options: options,
      showSearch: true,
    },
    {
      customElement: (
        <button
          className="primary-btn mb-1"
          onClick={() => handleSubmit("text")}
        >
          Submit
        </button>
      ),
      customClass: "col-md-6",
    },
    {
      name: "text",
      value: "",
      type: "textarea",
      rows: "9",
      label: "",
      placeholder: "Enter here...",
      customClass: "col-md-5",
    },

    {
      name: "data",
      value: "",
      type: "textarea",
      label: "",
      placeholder: "",
      rows: "9",
      customClass: "col-md-5",
      disabled: true,
    },
  ];

  const handleSubmit = (value) => {
    let valid = formRef.current.validForm();
    if (!valid) {
      sendNotify("error", "Some fields are missing!");
      return;
    }
    let data = formRef.current.getPayload();
    data.type = value;
    console.log(data);

    let payload = {
      method: "POST",
      url: `/auth/translate`,
      data,
    };

    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message);
        updateFomeData(res.data);
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };
  const updateFomeData = async (value) => {
    let fomeObj = { ...formData };
    let data = formRef.current.getPayload();
    data.data = value;
    setFormData(data);
  };

  const getPhoto = (e) => {
    let file = e.target.files[0];
    if (file) {
      let url = URL.createObjectURL(file);

      var bodyFormData = new FormData();
      bodyFormData.append("file", file);
      bodyFormData.append("type", "file");
      // bodyFormData.append("langTo", "en");
      let payload = {
        method: "POST",
        url: `/auth/translate`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      fetchApi(payload)
        .then((res) => {
          // setProfileUrl(res.data.url);

          updateFomeData(res);
        })
        .catch((error) => {
          sendNotify("error", error?.message);
        });
      // setProfileUrl(url);
    }
  };

  const handleOnChange = async (value) => {};

  const [file, setFile] = useState(null);
  const [response, setResponse] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    try {
      let payload = {
        method: "POST",
        url: `/auth/translateiii/upload`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      fetchApi(payload)
        .then((res) => {
          setResponse(res.data);
        })
        .catch((error) => {
          sendNotify("error", error?.message);
        });
    } catch (error) {
      console.error(error);
      setResponse({ error: "Upload failed" });
    }
  };

  return (
    <div>
      <div className="page-header">
        <h1>Document Translation</h1>
      </div>
      <div className="page-content">
        <div className="row px-0">
          <div className="col-md-3">
            <ul className="custom-dropdown child-first">
              <li>
                <ul className={`child-dropdown show ps-0`}>
                  <li
                    className={activeChild == 0 ? "active" : ""}
                    onClick={() => setActiveChild(0)}
                  >
                    <p>
                      <i className="fal fa-language"></i> Inhouse Translation
                    </p>
                  </li>
                  <li
                    className={activeChild == 1 ? "active" : ""}
                    onClick={() => setActiveChild(1)}
                  >
                    <p>
                      <i className="fal fa-file-signature"></i> Manual External
                      Translation
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-md-9">
            {activeChild == 0 && <InhouseTranslation />}
            {activeChild == 1 && <ManualTranslation />}
          </div>
          <FormZ
            formSchema={formSchema}
            ref={formRef}
            formData={formData}
            onChange={handleOnChange}
            formClass="row mb-3 mb-md-4 gy-3 gx-4 align-items-end"
            childClass="col-md-3 mb-0"
            labelClass="mb-1"
            inputClass="w-100"
          />
          <input
            type="file"
            name="profile"
            id="profile"
            // hidden
            onChange={getPhoto}
          />

          <div>
            <input type="file" onChange={handleFileChange} />
            <button onClick={handleUpload}>Upload</button>
            {response && (
              <div>
                <h3>Result</h3>
                {response.error ? (
                  <p>{response.error}</p>
                ) : (
                  <div>
                    <p>
                      <strong>File Path:</strong> {response.filePath}
                    </p>
                    <a
                      href={`http://localhost:5000/${response.filePath}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download Result
                    </a>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
