import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import socketIOClient from "socket.io-client";
import { Popover } from "antd";

import { getNewNotifications } from "../store/newNotifications/newNotificationsSlice";
import Logo from "../assets/img/logo-icon.png";
import NoDataImg from "../assets/img/no-notifications.jpg";
import AvatarImg from "../assets/img/notify-avatar.png";

import TabZ from "./TabZ";

const socket = socketIOClient(
  process.env.REACT_APP_API_URL.split("/api", 1)[0]
);

export default function NotificationBtn() {
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector((state) => state.newNotification);
  const { userDetails } = useSelector((state) => state.auth);
  const [notificationArray, setNotificationArray] = useState([]);

  useEffect(() => {
    if (userDetails?._id) {
      dispatch(getNewNotifications({ read: "W" }));
      configureSocket();
    }

    return () => {
      //  socket.off("connection");
      socket.off("message");
      socket.off("notification");
      socket.off("updateTheme");
    };
  }, [userDetails]);

  useEffect(() => {
    setNotificationArray(data?.rows || []);
  }, [data]);

  const configureSocket = () => {
    socket.on("connection", () => {
      console.log("Socket connected to backend");
    });

    socket.on("message", (message) => {
      pushNotification({
        title: "Notification from Galactic!",
        body: `You got a new message from ${message.name}`,
        icon: Logo,
        link: "/app/chat-box",
      });
    });

    socket.on("notification", (notification) => {
      if (notification?.userId?.includes(userDetails?._id)) {
        dispatch(getNewNotifications({ read: "W" }));
        pushNotification({
          title: "Notification from Galactic!",
          body: "You got a new message from company",
          icon: Logo,
          link: notification?.link || "/app/notifications",
        });
      }
    });

    socket.on("updateTheme", (updateTheme) => {
      const isUserIncluded = updateTheme?.id?.includes(userDetails?._id);
      if (isUserIncluded) {
        pushNotification({
          title: updateTheme.body?.companyName || "Galactic",
          body: "We are excited to announce the release of our new system theme. Enjoy a fresh look and enhanced user experience.",
          icon: Logo,
          link: "/app/account",
        });
      }
    });
  };

  const pushNotification = ({ title, body, icon, link }) => {
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      const notify = new Notification(title, { body, icon });
      notify.onclick = () => window.open(link, "_blank");
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          const notify = new Notification(title, { body, icon });
          notify.onclick = () => window.open(link, "_blank");
        }
      });
    }
  };

  const tabLabels = [
    { key: 1, label: "NEW" },
    { key: 2, label: "CLEARED" },
  ];

  const text = (
    <div className="notification-header">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-2">
          <h6>Notifications</h6>
          <span className="count">{data?.count || 0}</span>
        </div>
        <button className="close-btn">
          <i className="fal fa-times"></i>
        </button>
      </div>
    </div>
  );

  const content = (
    <div className="notification-content">
      <div className="d-flex justify-content-between align-items-center mb-2 px-3">
        <TabZ
          labels={tabLabels}
          defaultActive="1"
          customClass="sm"
          onChange={(key) => console.log("Tab changed to:", key)}
        />
        <button className="secondary-btn py-1">Clear All</button>
      </div>
      <div className="notification-wrap">
        {notificationArray?.length > 0 ? (
          notificationArray.slice(0, 5).map((m, index) => (
            <Link
              to={m.link || "/app/notifications"}
              className="notification-link"
              key={index}
            >
              <div
                className={`notification-item unread ${
                  m.type === "alert" ? "import" : ""
                }`}
              >
                <img className="notification-avatar" src={AvatarImg} alt="" />
                <div className="notification-text">
                  <h6>{m.title || "Galactic Alert"}</h6>
                  <p>{m.description || "You have a new notification."}</p>
                </div>
                <p className="notification-time my-2">
                  {new Date(m.createdAt).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </p>
              </div>
            </Link>
          ))
        ) : (
          <div className="no-notification">
            <img src={NoDataImg} alt="" />
            <h4>No Notification Yet</h4>
          </div>
        )}
      </div>
      <div className="notification-footer">
        <Link to="/app/notifications" className="primary-btn ms-auto py-2">
          View All
        </Link>
      </div>
    </div>
  );

  return (
    <Popover className="notification-popover" title={text} content={content}>
      <button className="notification-btn">
        <i className="fal fa-bell"></i>
        {data?.count > 0 && (
          <span className="notification-count">{data.count}</span>
        )}
      </button>
    </Popover>
  );
}
