import React, { useState, useRef, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Modal, Spin } from "antd";

import Individual from "./components/individual";
import CorporateKYC from "./components/corporate-kyc";
import RelatedEntityKYC from "./components/relatedEntity-kyc";
import TrustsKYC from "./components/trusts-kyc";

import UploadZ from "../../components/UploadZ";
import FormZ from "../../components/FormZ";
import TabZ from "../../components/TabZ";
import ImageUploadZ from "../../components/ImageUploadZ";

import {
  Each,
  focusOn,
  fetchApi,
  sendNotify,
  convertQueryParams,
  getOptions,
} from "../../helper";

const { success } = Modal;

export default function CreateTransferIn() {
  const [activeTab, setActiveTab] = useState("individual");
  const [documents, setDocuments] = useState({
    basicDoc: [],
    individual: [],
    corporateEntity: [],
    relatedEntity: [],
    incorpDocuments: [],
  });
  const [upload, setUpload] = useState([]);
  const [mode, setMode] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [clearDocuments, setClearDocuments] = useState(false);
  const [clientObjArray, setClientObjArray] = useState([]);
  const [clientArray, setClientArray] = useState([]);
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState({});
  const [objData, setObjData] = useState({});
  const [masterCode, setMasterCode] = useState("");
  useEffect(() => {
    let mode = searchParams.get("mode");
    setMode(mode);
    if (mode == "edit") {
      setActiveTab("trusts");
      focusOn("tab");
    }
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    let params = { isAll: true };

    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/client?${query}`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res?.data?.rows;
    });
    setClientArray(data);

    let list = data.map((d) => {
      let obj = {
        value: d?._id,
        label: d?.code,
      };
      return obj;
    });
    setClientObjArray(list);
  };
  const clientdropDownOnClick = async (value) => {
    setFormData(value);
    let data = { ...objData };
    let { masterId } = value;
    if (data.masterId !== masterId || !data.masterId) {
      if (masterId) {
        let clientData = clientArray.filter((e) => e._id == masterId);
        setMasterCode(clientData?.[0]?.code);
        value["masterName"] = clientData?.[0]?.name;
        // setFormData(value);
      }
    }
    setObjData(value);
  };
  const clientOnChange = async (value) => {
    setFormData(value);
    let params = { isAll: true, code: value };

    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/client?${query}`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res?.data?.rows;
    });
    setClientArray(data);
    let list = data.map((d) => {
      let obj = {
        value: d?._id,
        label: d?.code,
      };
      return obj;
    });
    setClientObjArray(list);
  };

  function storeDocuments(file) {
    let doc = { ...documents };
    doc["basicDoc"].push(file);

    setDocuments(doc);
  }

  function removeDocument(docName) {
    let allDoc = { ...documents };
    let doc = allDoc["basicDoc"];
    let index = doc?.findIndex((d) => d?.document == docName);
    if (index != -1) {
      doc.splice(index, 1);
      allDoc["basicDoc"] = doc;
      setDocuments(allDoc);
    }
  }

  let documentsArr = [
    "Inaugural Resolution",
    "Appointment of First Director",
    "Resolution of Share Allotment",
    "Corporate Seal Draft",
    "Directors Consent Letter",
    "Customized M&AA",
    "UBO Source of Funds Declaration",
    "Incorporation Application Form",
    "AML Verification Guidelines",
    "Transfer-In Form for BVIBC",
    "Written Confirmations-Virtual Assets",
    "Terms Of Business Agreement",
    "Certificate of Incorporation",
    "M&AA",
  ];

  const addNewUpload = () => {
    const newArr = [...upload];
    let newName = `Enter document name ${newArr.length + 1}`;
    const nameExists = newArr.includes(newName);

    if (nameExists) {
      let count = 1;
      let uniq = `Enter document name ${count}`;

      while (newArr.includes(uniq)) {
        count++;
        uniq = `Enter document name ${count}`;
      }

      newName = uniq;
    }

    newArr.push(newName);

    setUpload(newArr);
  };

  const removeUpload = (index, docName) => {
    let arr = [...upload];
    arr.splice(index, 1);
    removeDocument(docName);
    setUpload(arr);
  };

  const formRef = useRef();

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  let formSchema = [
    {
      name: "entityName",
      value: "",
      type: "text",
      label: "Entity Name",
      placeholder: "Enter entity name",
    },
    {
      name: "incorporationNumber",
      value: "",
      type: "text",
      label: "Incorporation Number",
      placeholder: "Enter client name",
    },
    {
      name: "incorporationDate",
      value: "",
      type: "date",
      label: "Incorporation Date",
      placeholder: "Select date",
    },
  ];

  const getLabelName = (value, index) => {
    let arr = [...upload];
    arr[index] = value;
    setUpload(arr);
  };

  const onUploadDoc = (docs, name, key) => {
    let arr = { ...documents };
    let list = [...arr[name]];
    let obj = list.find((l) => l.index == key);
    let index = list.findIndex((l) => l.index == key);

    if (!obj) {
      obj = {};
      obj["index"] = key;
      obj["documents"] = docs;
      list.push(obj);
    } else {
      obj["documents"] = docs;
      list[index] = obj;
    }

    arr[name] = list;

    setDocuments(arr);
  };

  const onNameChange = (value, name, key) => {
    let arr = { ...documents };
    let list = [...arr[name]];
    let obj = list.find((l) => l.index == key);
    let index = list.findIndex((l) => l.index == key);
    if (obj) {
      obj["name"] = value;
      list[index] = obj;
    } else {
      obj = {};
      obj["index"] = key;
      obj["name"] = value;
      list.push(obj);
    }
    arr[name] = list;

    setDocuments(arr);
  };

  const getIncorpDocs = (data) => {
    let arr = { ...documents };
    arr["incorpDocuments"] = data;
    setDocuments(arr);
  };

  const onSubmitHandle = () => {
    success({
      title: <h5>Successfully Submitted</h5>,
      content: <p>Your information has been submitted.</p>,
      className: "custom-confirm",
      centered: true,
      closable: true,
      okText: "Okay",
      onOk() {
        saveData();
      },
      onCancel() {
        console.log("Cancel");
      },
    });

    // setSubmitLoading(true);
    // let data = {};
    // let from = formRef.current.getPayload();
    //
    // data = { ...from, ...documents };
    // data.masterCode = masterCode;
    // let payload = {
    //   method: "POST",
    //   url: `/relationshipManager`,
    //   data,
    // };
    // fetchApi(payload)
    //   .then((res) => {
    //     sendNotify("success", res?.message);
    //     setSubmitLoading(false);
    //     formRef.current.clearForm();
    //     setClearDocuments(true);
    //     setDocuments({
    //       basicDoc: [],
    //       individual: [],
    //       corporateEntity: [],
    //       incorpDocuments: [],
    //     });
    //   })
    //   .catch((error) => {
    //
    //     sendNotify("error", error?.message);
    //     setSubmitLoading(false);
    //   });
    // setClearDocuments(false);
  };

  const saveData = () => {
    setSubmitLoading(true);
    let data = {};
    let from = formRef.current.getPayload();
    data = { ...from, ...documents };
    data.masterCode = masterCode;
    data.type = "Transfer-In";

    let payload = {
      method: "POST",
      url: `/relationshipManager`,
      data,
    };

    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message);

        formRef.current.clearForm();
        setClearDocuments(true);
        setDocuments({
          basicDoc: [],
          individual: [],
          corporateEntity: [],
          relatedEntity: [],
          incorpDocuments: [],
        });

        setTimeout(() => {
          setSubmitLoading(false);
          window.location.href = `/app/active-requests`;
        }, 1000);
      })
      .catch((error) => {
        sendNotify("error", error?.message);
        setSubmitLoading(false);
      });
    setClearDocuments(false);
  };
  const labels = [
    {
      key: 1,
      label: "INDIVIDUAL",
    },
    {
      key: 2,
      label: "CORPORATE ENTITY",
    },
    {
      key: 3,
      label: "RELATED ENTITY",
    },
    {
      key: 4,
      label: "INCORP DOCUMENTS",
    },
  ];

  const onTabChange = (key) => {
    setActiveTab(
      key == 1
        ? "individual"
        : key == 2
        ? "corporateKYC"
        : key == 3
        ? "relatedEntityKYC"
        : "trustsKYC"
    );
  };

  const getImage = (imageData) => {
    console.log(imageData);
  };

  return (
    <div>
      <Spin spinning={submitLoading}>
        <div className="page-header">
          <Link to={"/app/onboard-entity"}>
            <h1 className="d-flex align-items-center gap-2">
              <i className="fal fa-long-arrow-left"></i> Create New Transfer In
            </h1>
          </Link>
          <button
            className={`primary-btn ${submitLoading ? "btn-loading" : ""}`}
            onClick={onSubmitHandle}
          >
            {submitLoading ? (
              <i className="far fa-spinner-third fa-spin"></i>
            ) : (
              ""
            )}
            Submit
          </button>
        </div>
        <div className="page-content">
          {/* <ImageUploadZ
          className={"mb-4"}
          image={imageBase64}
          onImageUpload={getImage}
        /> */}
          <FormZ
            ref={formRef}
            formSchema={formSchema}
            formData={formData}
            formClass="custom-form row mb-4 gy-3"
            childClass="col-md-4 mb-0"
            labelClass="mb-1"
            inputClass="w-100"
            onKeyUp={(e) => clientOnChange(e.target.value)}
            onChange={clientdropDownOnClick}
          />
          <h2 className="sub-title mb-4">
            Pre-Incorporation Supported Documents
          </h2>
          <div className="custom-form">
            <div className="row gy-2 gx-4">
              <Each
                array={documentsArr}
                render={(item) => (
                  <div className="col-md-3">
                    <label htmlFor="inaugural-resolution" className="me-3">
                      {item}
                    </label>
                    <UploadZ
                      onStoreFile={storeDocuments}
                      onDeleteFile={removeDocument}
                      clearUpload={clearDocuments}
                      fileName={item}
                    />
                  </div>
                )}
              />
            </div>
          </div>
          <div className="my-3 my-md-4">
            <TabZ labels={labels} defaultActive={"1"} onChange={onTabChange} />
          </div>
          <div
            className={`hide-content ${
              activeTab == "individual" ? "show" : ""
            }`}
          >
            <Individual
              getName={onNameChange}
              clearUpload={clearDocuments}
              getDocument={onUploadDoc}
            />
          </div>
          <div
            className={`hide-content ${
              activeTab == "corporateKYC" ? "show" : ""
            }`}
          >
            <CorporateKYC
              getName={onNameChange}
              clearUpload={clearDocuments}
              getDocument={onUploadDoc}
            />
          </div>
          <div
            className={`hide-content ${
              activeTab == "relatedEntityKYC" ? "show" : ""
            }`}
          >
            <RelatedEntityKYC
              getName={onNameChange}
              clearUpload={clearDocuments}
              getDocument={onUploadDoc}
            />
          </div>
          <div
            className={`hide-content ${activeTab == "trustsKYC" ? "show" : ""}`}
          >
            <TrustsKYC
              getName={onNameChange}
              clearUpload={clearDocuments}
              getDocument={onUploadDoc}
            />
          </div>
        </div>
      </Spin>
    </div>
  );
}
