import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Modal, Select } from "antd";

import {
  customPagination,
  arrayToOption,
  getOptions,
  convertQueryParams,
  sendNotify,
  fetchApi,
} from "../../helper";
import { getManageRoles } from "../../store/manageRoles/manageRolesSlice";
import TableSortArrows from "../../components/tableSortArrows";
import TagZ from "../../components/TagZ";
import FormZ from "../../components/FormZ";

export default function ManageRoles() {
  const roleFormRef = useRef();
  const dispatch = useDispatch();
  const manageRoles = useSelector((state) => state.manageRoles);
  const [roleModal, setRoleModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState([]);
  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });
  const [formData, setFormData] = useState({});
  const [selectedCatId, setSelectedCatId] = useState("");
  useEffect(() => {
    dispatch(getManageRoles(queryParams));
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [manageRoles]);

  const handleRefresh = async () => {
    if (manageRoles?.data?.rows) {
      setData(manageRoles?.data?.rows);
    }
  };
  const accessList = [
    "In-House Translation",
    "Manual Translation",
    "In-House OCR",
    "Manual OCR",
    "Storage",
    "OCR",
    "RAF",
    "Web Search",
    "Risk Analysis",
    "CDD Screen",
    "Document Review",
    "New Incorporation Name Check",
    "MLRO Sign Off Dashboard",
    "New Business Onboard",
    "Send to Approval",
    "Incorporation Request",
    "Active Requests",
    "Incorporation Confirmation",
    "Incorporation Feedback",
    "Incorporation Pack",
    "Master Search",
    "MLRO Sign Off",
    "New Incorporation",
    "Pending Information",
    "Post Incorp",
    "Pre-Incorp Supported Documents",
    "Entity Invoicing",
    "Entity Overview - Update",
    "Entity Overview - List",
    "Director Screen",
    "Shareholder",
    "Trust",
    "Partnership",
    "General Partnership",
    "Limited Partnership",
    "Parent Client",
    "Client",
    "Crypto KYC",
    "Finance",
    "Individual Analysis",
    "My Portfolio",
  ];
  const status = ["Active", "Inactive", "Hold"];
  const role = [
    { label: "Entity Point of Contact", value: "point-of-contact" },
    { label: "Analyst", value: "analyst" },
    { label: "Admin", value: "admin" },
    { label: "MLRO", value: "mlro" },
    { label: "Lead / Manager", value: "lead" },
    { label: "End User Client", value: "end-user" },
    { label: "Introducer Client", value: "introducer-client" },
    { label: "Client", value: "client" },
  ];

  const userList = [
    "George Adamson",
    "Leslie Alcock",
    "Mick Aston",
    "Richard Atkinson",
    "Edward Russell Ayrton",
    "Churchill Babington",
    "Philip Arthur Barker",
    "Thomas Bateman",
    "James Theodore Bent",
    "Geoffrey Bibby",
    "Howard Carter",
    "Grahame Clark",
    "David Clarke",
    "Barry Cunliffe",
    "Glyn Daniel",
  ];

  const SelectComponent = (role, title) => {
    const roleTitle = role.find((a) => a.value === title);
    return roleTitle ? roleTitle.label : "Unknown Role";
  };
  const columns = [
    {
      title: "Role Name",
      dataIndex: "title",
      sorter: (a, b) => a.title - b.title,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { title, _id }) => (
        <span className="table-link" onClick={() => editModalOnClick(_id)}>
          {SelectComponent(role, title)}
        </span>
      ),
    },
    {
      title: "Number of Users",
      dataIndex: "userCount",
      sorter: (a, b) => a.userCount - b.userCount,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => {
        return (
          <TagZ
            status={
              status == "Active"
                ? "approved"
                : status == "Hold"
                ? "in-progress"
                : "rejected"
            }
            statusText={status}
          />
        );
      },
    },
  ];

  //   Processor
  //   Analyst
  //   Admin
  //   MLRO
  //   Lead

  // const data = [
  //   {
  //     name: "Analyst",
  //     userCount: 16,
  //     createAt: "Jan 12, 2020",
  //     status: true,
  //   },
  //   {
  //     name: "Processor",
  //     userCount: 12,
  //     createAt: "Jan 12, 2020",
  //     status: true,
  //   },
  //   {
  //     name: "Lead",
  //     userCount: 5,
  //     createAt: "Jan 12, 2020",
  //     status: true,
  //   },
  // ];

  let roleFormSchema = [
    {
      name: "title",
      value: "",
      type: "select",
      options: role,
      label: "User Role Name",
      placeholder: "Select user role name",
      required: true,
      disabled: editMode,
    },
    {
      name: "accessList",
      value: [],
      type: "select",
      inputClass: "ptb-1",
      options: arrayToOption(accessList),
      label: "Access List",
      placeholder: "Select access",
      required: true,
      mode: "multiple",
      showSearch: true,
      allowClear: true,
    },
    editMode
      ? {
          name: "status",
          value: [],
          type: "select",
          inputClass: "ptb-1",
          options: arrayToOption(status),
          label: "Status",
          placeholder: "Select Status",
        }
      : {
          label: "",
          customElement: <span></span>,
        },
    // {
    //   name: "userRole",
    //   value: editMode ? ["George Adamson", "Leslie Alcock", "Mick Aston"] : [],
    //   type: "select",
    //   inputClass: "ptb-1",
    //   options: arrayToOption(userList),
    //   label: "User Role",
    //   placeholder: "Select user role",
    //   required: true,
    //   mode: "multiple",
    //   allowClear: true,
    // },
  ];
  const editModalOnClick = async (id) => {
    let params = { ...queryParams };
    let formDataObj = { ...formData };
    params.id = id;
    let query = convertQueryParams(params);
    let ctr = {
      method: "GET",
      url: `/group?${query}`,
    };
    let selectedGroup = await fetchApi(ctr).then((res) => {
      return res?.data.rows[0];
    });
    formDataObj.title = selectedGroup.title;
    formDataObj.accessList = selectedGroup.accessList;
    formDataObj.status = selectedGroup.status;
    setSelectedCatId(selectedGroup._id);

    setFormData(formDataObj);
    openEditModal();
  };
  const openEditModal = () => {
    setEditMode(true);
    setRoleModal(true);
  };

  const closeModal = () => {
    setSelectedCatId("");
    setFormData({ accessList: [], title: "" });
    setRoleModal(false);
    setEditMode(false);
  };
  const onSubmit = async () => {
    saveData(selectedCatId);
  };

  const saveData = async (selectedCatId) => {
    const data = roleFormRef.current.getPayload();
    const payload = {
      method: selectedCatId ? "PUT" : "POST",
      url: selectedCatId ? `/group/update/${selectedCatId}` : `/group`,
      data,
    };
    fetchApi(payload)
      .then((res) => {
        if (res?.status == 200 || res?.status == 201) {
          sendNotify("success", res?.message);
          dispatch(getManageRoles(queryParams));
          closeModal();
          roleFormRef.current.clearForm();
        } else {
          sendNotify("error", res?.message);
        }
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };
  const getPagination = (current, pageSize) => {
    let params = { ...queryParams };
    params._start = (current - 1) * pageSize;
    dispatch(getManageRoles(params));
    setQueryParams(params);
  };
  return (
    <div>
      <div className="page-header">
        <Link to={"/app/user-management"}>
          <h1 className="d-flex align-items-center gap-2">
            <i className="far fa-long-arrow-left"></i> Manage User Roles
          </h1>
        </Link>
        <button className="primary-btn" onClick={() => setRoleModal(true)}>
          <i className="fal fa-plus"></i> Create New
        </button>
      </div>
      <div className="page-content">
        <Table
          className="custom-table"
          columns={columns}
          dataSource={data}
          pagination={{
            className: "custom-pagination",
            defaultCurrent: 1,
            pageSize: queryParams?._limit,
            total: manageRoles?.data?.totalCount,
            onChange: getPagination,
            itemRender: customPagination,
          }}
        />
      </div>
      <Modal
        title={`${editMode ? "Edit" : "Create"} User Role`}
        className="custom-modal-2"
        open={roleModal}
        width={700}
        onOk={closeModal}
        onCancel={closeModal}
        footer={
          <div className="d-flex gap-3 justify-content-end mt-4">
            {editMode ? <button className="danger-btn">Delete</button> : null}
            <button
              className={`primary-btn ${!editMode ? "ms-auto mt-4" : ""} `}
              onClick={onSubmit}
            >
              {!editMode ? "Create" : "Save"}
            </button>
          </div>
        }
      >
        <div>
          <FormZ
            // formClass="row gy-4 mb-4"
            // childClass="col-md-5 mb-0"
            labelClass="mb-1"
            inputClass="w-100"
            formSchema={roleFormSchema}
            ref={roleFormRef}
            formData={formData}
          />
        </div>
      </Modal>
    </div>
  );
}
