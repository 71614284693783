import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Table, Select, Tag, Modal, Col } from "antd";
import { getBusinessOnboard } from "../../store/businessOnboard/businessOnboardSlice";

import { Each, customPagination, convertQueryParams } from "../../helper";

import UploadZ from "../../components/UploadZ";
import TagZ from "../../components/TagZ";
import TableSortArrows from "../../components/tableSortArrows";
import TableFilterZ from "../../components/TableFilterZ";
import TabZ from "../../components/TabZ";
import SampleDoc from "../../assets/pdf/sample.pdf";
import InProgressIcon from "../../assets/img/in-progress-icon.svg";
import ApprovedIcon from "../../assets/img/approved-icon.svg";

export default function ReviewUpload() {
  const businessOnboard = useSelector((state) => state.businessOnboard);
  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });
  const [filters, setFilters] = useState(null);

  const [documents, setDocuments] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [customUpload, setCustomUpload] = useState([]);
  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBusinessOnboard(queryParams));
  }, []);
  useEffect(() => {
    getData();
  }, [businessOnboard]);

  function storeDocuments(file) {
    let doc = [...documents];
    doc.push(file);
    console.log(doc);
    setDocuments(doc);
  }
  const getData = () => {
    if (businessOnboard?.data?.rows) {
      console.log(businessOnboard, "asdfgb");
      setData(businessOnboard?.data?.rows);
    }
  };
  const getFilter = (value) => {
    let params = { ...queryParams };
    const filters = encodeURIComponent(JSON.stringify(value));
    params.filters = filters;
    dispatch(getBusinessOnboard(params));
    setQueryParams(params);
  };
  const columns = [
    {
      title: "Task Code",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.name - b.name,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Task Type",
      dataIndex: "type",
      sorter: (a, b) => a.type - b.type,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Approved By",
      dataIndex: "approvedBy",
      sorter: (a, b) => a.approvedBy - b.approvedBy,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Approved date",
      dataIndex: "approvedDate",
      sorter: (a, b) => a.approvedDate - b.approvedDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Approval Status",
      dataIndex: "status",
      width: 200,
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={
            status == "approved"
              ? "approved"
              : status == "in-progress"
              ? "in-progress"
              : "rejected"
          }
          statusText={status == "approved" ? "Completed" : "Pending"}
        />
        // <Select
        //   className="custom-select"
        //   placeholder="Select type"
        //   value={status}
        //   style={{
        //     width: 160,
        //   }}
        //   options={[
        //     {
        //       value: "in-progress",
        //       label: "In Progress",
        //     },
        //     {
        //       value: "approved",
        //       label: "Approved",
        //     },
        //     {
        //       value: "rejected",
        //       label: "Rejected",
        //     },
        //     {
        //       value: "pending",
        //       label: "Pending",
        //     },
        //   ]}
        // />
      ),
    },
    {
      title: "Created Date and Time",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 200,
    },
    {
      title: "Created By",
      dataIndex: "requesterName",
      sorter: (a, b) => a.requesterName - b.requesterName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Name",
      dataIndex: "entityName",
      key: "entityName",
      sorter: (a, b) => a.entityName - b.entityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      key: "entityCode",
      sorter: (a, b) => a.entityCode - b.entityCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Requestor ID",
      dataIndex: "requestorID",
      sorter: (a, b) => a.requestorID - b.requestorID,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Review Status",
      dataIndex: "reviewStatus",
      sorter: (a, b) => a.reviewStatus - b.reviewStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      // width: 200,
      render: (_, { reviewStatus }) => (
        <TagZ
          status={reviewStatus == "completed" ? "approved" : "in-progress"}
          statusText={reviewStatus == "completed" ? "Completed" : "Pending"}
        />
        // <Select
        //   className="custom-select"
        //   placeholder="Select type"
        //   value={reviewStatus}
        //   style={{
        //     width: 160,
        //   }}
        //   options={[
        //     {
        //       value: "in-progress",
        //       label: "In Progress",
        //     },
        //     {
        //       value: "completed",
        //       label: "Completed",
        //     },
        //     {
        //       value: "pending-info",
        //       label: "Pending Info",
        //     },
        //   ]}
        // />
      ),
    },
    {
      title: "Processor",
      dataIndex: "processor",
      sorter: (a, b) => a.processor - b.processor,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Pack Upload",
      dataIndex: "packUpload",
      sorter: (a, b) => a.packUpload - b.packUpload,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { id }) =>
        id == 2 ? (
          <button
            className="action-btn icon-btn"
            onClick={() => {
              setViewMode(true);
              setUploadModal(true);
            }}
          >
            <i className="far fa-eye"></i>
          </button>
        ) : (
          <button
            className="action-btn icon-btn"
            onClick={() => {
              setViewMode(false);
              setUploadModal(true);
            }}
          >
            <i className="far fa-arrow-to-top"></i>
          </button>
        ),
      // <div className="table-upload">
      //   <label htmlFor="doc" className="outline-btn py-2">
      //     Upload
      //   </label>
      //   <input type="file" name="doc" id="doc" />
      // </div>
    },
    {
      title: "Actions",
      // fixed: "right",
      width: 250,
      render: (_, { id, entityId }) => (
        <div className="d-flex gap-3">
          <button className="action-btn">Store</button>
          <Link
            to={`/app/entity-overview/edit?id=${entityId}`}
            className="action-btn"
          >
            Update
          </Link>
          <Link to={"/app/entity-overview/edit"} className="action-btn">
            Store & Update
          </Link>
        </div>
      ),
    },
  ];
  // const data = [
  //   {
  //     key: "1",
  //     id: "1",
  //     name: "Task #1",
  //     type: "Active",
  //     approvedBy: "Guru",
  //     approvedDate: "10/10/2023",
  //     status: "approved",
  //     createdAt: "07/11/2023 04:56PM",
  //     createdBy: "John Doe",
  //     entityName: "Trust",
  //     entityCode: "9012",
  //     requestorID: "2343",
  //     reviewStatus: "completed",
  //     processor: "Hari",
  //   },
  //   {
  //     key: "2",
  //     id: "2",
  //     name: "Task #2",
  //     type: "Active",
  //     approvedBy: "Guru",
  //     approvedDate: "10/10/2023",
  //     status: "pending",
  //     createdAt: "07/11/2023 04:56PM",
  //     createdBy: "John Doe",
  //     entityName: "Trust",
  //     entityCode: "9012",
  //     requestorID: "2343",
  //     reviewStatus: "pending-info",
  //     processor: "Hari",
  //   },
  //   {
  //     key: "3",
  //     id: "3",
  //     name: "Task #3",
  //     type: "Active",
  //     approvedBy: "Guru",
  //     approvedDate: "10/10/2023",
  //     status: "in-progress",
  //     createdAt: "07/11/2023 04:56PM",
  //     createdBy: "John Doe",
  //     entityName: "Trust",
  //     entityCode: "9012",
  //     requestorID: "2343",
  //     reviewStatus: "in-progress",
  //     processor: "Hari",
  //   },
  // ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const docList = [
    "Certificate of Incorporation",
    "M&AA",
    "Approved RAF",
    "Compliance Pack",
  ];

  const handleOk = () => {
    setUploadModal(false);
  };

  const handleCancel = () => {
    setUploadModal(false);
  };

  const addNewUploadDoc = () => {
    let arr = [...customUpload];
    let index = arr.length + 1;
    arr.push(`Enter Document Name ${index}`);
    setCustomUpload(arr);
  };

  const getLabelName = (value, index) => {
    let arr = [...customUpload];
    arr[index] = value;
    setCustomUpload(arr);
  };

  const removeUploadDoc = (index) => {
    let arr = [...customUpload];
    arr.splice(index, 1);
    setCustomUpload(arr);
  };

  const sampDocuments = {
    url: SampleDoc,
    name: "Sample Document.pdf",
    size: "200 KB",
    type: "pdf",
    base64: "",
  };
  const getPagination = async (current, pageSize) => {
    let params = { ...queryParams };
    const filter = encodeURIComponent(JSON.stringify(filters));
    params._start = (current - 1) * pageSize;
    params._limit = pageSize;
    if (filters) {
      params.filters = filter;
    }
    dispatch(getBusinessOnboard(params));
  };

  const getProgress = async (value) => {
    let params = { ...queryParams };
    params.status = value;
    dispatch(getBusinessOnboard(params));
    setQueryParams(params);
  };
  const labels = [
    {
      key: "Active",
      label: (
        <>
          <img src={ApprovedIcon} alt="" /> Active
        </>
      ),
    },
    {
      key: "Inactive",
      label: (
        <>
          <img src={InProgressIcon} alt="" /> Inactive
        </>
      ),
    },
  ];
  return (
    <div>
      <div className="page-header">
        <h1>New Business Onboard</h1>
        <div className="d-flex gap-3 flex-column flex-md-row">
          <Link to={"/app/storage"} className="outline-btn">
            Store
          </Link>
          {/* <Link to={"/app/entity-overview/edit"} className="primary-btn">
            Send out to storage and Update
          </Link> */}
        </div>
      </div>
      <div className="page-content">
        <div className="d-flex justify-content-between mb-3">
          <TabZ
            labels={labels}
            defaultActive={"Active"}
            customClass={"table-tabs"}
            onChange={getProgress}
          />
          <TableFilterZ tableCol={columns} onChange={getFilter} />
        </div>
        <Table
          className="custom-table"
          loading={businessOnboard?.data?.isLoading}
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={columns}
          rowKey={"_id"}
          dataSource={data}
          scroll={{ x: 2500 }}
          pagination={{
            className: "custom-pagination",
            defaultCurrent: 1,
            pageSize: queryParams?._limit,
            total: businessOnboard?.data?.totalCount,

            onChange: getPagination,
            itemRender: customPagination,
          }}
        />
        {/* <div className="custom-form">
        <h4 className="primary-title mb-4"></h4>
      </div> */}
        <Modal
          title={"Complete Incorporation Process"}
          className="custom-modal"
          open={uploadModal}
          width={1000}
          onOk={handleOk}
          okText="Upload"
          onCancel={handleCancel}
        >
          <div className="row custom-form gy-2 gx-4">
            <Each
              array={docList}
              render={(item) => (
                <div className="col-md-4">
                  <label htmlFor={item}>{item}</label>
                  <UploadZ
                    onStoreFile={storeDocuments}
                    fileName={item}
                    file={viewMode ? sampDocuments : null}
                  />
                </div>
              )}
            />
            {customUpload.length > 0 && (
              <Each
                array={customUpload}
                render={(item, i) => (
                  <div className="col-md-4">
                    <div className="d-flex justify-content-between align-items-center mb-1px">
                      <label
                        contentEditable={true}
                        key={i}
                        id={i}
                        onBlur={(e) => getLabelName(e.target.innerHTML, i)}
                        className="mb-0"
                        suppressContentEditableWarning={true}
                      >
                        {item}
                      </label>
                      <button
                        className="del-upload-btn"
                        onClick={() => removeUploadDoc(i)}
                      >
                        <i className="far fa-trash-alt"></i>
                      </button>
                    </div>
                    <UploadZ onStoreFile={storeDocuments} fileName={item} />
                  </div>
                )}
              />
            )}
            <div className="col-md-4 mb-4">
              <label htmlFor="">&nbsp;</label>
              <div className="add-new-upload" onClick={addNewUploadDoc}>
                <span className="icon">
                  <i className="fal fa-plus-circle"></i>
                </span>
                <h6>Add New Upload</h6>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
