import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRiskAssessmentDataByEntityId,
  saveRiskAssessmentData,
  resetSuccess,
} from "../store/riskAssessmentForm/riskAssessmentFormSlice";
import { sendNotify } from "../helper";

const useRiskAssessment = () => {
  const dispatch = useDispatch();

  const { isLoading, data, isError, success } = useSelector(
    (state) => state.riskAssessment
  );
  const [updating, setUpdating] = useState(false);

  const fetchRiskAssessmentData = useCallback(
    (entityId) => {
      if (entityId) {
        dispatch(fetchRiskAssessmentDataByEntityId({ entityId }));
      }
    },
    [dispatch]
  );

  const saveData = async (formData) => {
    setUpdating(true);
    try {
      await dispatch(saveRiskAssessmentData(formData));
      setUpdating(false);
    } catch (error) {
      setUpdating(false);
    }
    // if (success || isError) {
    //   sendNotify(success ? "success" : "error", success ? "Successfully Saved" : "Error Occurred");
    //   const timer = setTimeout(() => dispatch(resetSuccess()), 100);
    //   return () => clearTimeout(timer);
    // }
  };

  const resetFormSuccess = () => {
    dispatch(resetSuccess());
  };

  // useEffect(() => {
  //   if (success) {
  //     sendNotify("success", "Successfully Saved");
  //     dispatch(resetSuccess());
  //   } else if (isError) {
  //     sendNotify("error", "Error Occurred");
  //     dispatch(resetSuccess());
  //   }
  // }, [success, isError, dispatch]);

  return {
    isLoading,
    data,
    isError,
    success,
    fetchRiskAssessmentData,
    saveData,
    resetFormSuccess,
    updating,
  };
};

export default useRiskAssessment;
