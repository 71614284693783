import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Table, Tag, Select, Modal, DatePicker } from "antd";
import dayjs from "dayjs";
import moment from "moment/moment";
import { useSelector, useDispatch } from "react-redux";
import { downloadAsCSV, customPagination, getQuery } from "../helper";
import { getManualStorage } from "../store/manualStorage/manualStorageSlice";

import FormZ from "../components/FormZ";
import UploadZ from "../components/UploadZ";
import TableSortArrows from "../components/tableSortArrows";

const { RangePicker } = DatePicker;

export default function ManualStorage() {
  let query = getQuery();
  const formRef1 = useRef();
  const formRef2 = useRef();
  const formRef3 = useRef();
  const dispatch = useDispatch();
  const manualStorage = useSelector((state) => state.manualStorage);

  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });
  const [tableData, setTableData] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [cabinetModal, setCabinetModal] = useState(false);
  const [document, setDocument] = useState(null);
  useEffect(() => {
    dispatch(getManualStorage(queryParams));
  }, []);
  useEffect(() => {
    handleRefresh();
  }, [manualStorage]);

  const handleRefresh = async () => {
    if (manualStorage?.data?.rows) {
      //  a.individual
      //   .map((item) => item.doc.map((doc) => doc.file.document).join(", "))
      //   .join(", "),
      let finded = manualStorage?.data?.rows;
      let data = finded.map((a, index) => {
        const documentNames = new Set();

        // const addDocuments = (items) => {
        //   items.forEach((item) => {
        //     item.doc.forEach((doc) => {
        //       documentNames.add(doc.file.document);
        //     });
        //   });
        // };

        const addDocuments = (items) => {
          items?.forEach((item) => {
            item?.documents?.forEach((doc) => {
              documentNames?.add(doc?.document);
            });
          });
        };

        addDocuments(a.individual);
        addDocuments(a.corporate);

        let obj = {
          key: index,
          id: a._id,
          masterfileCode: a?.clientDetails?.code,
          masterfileName: a?.clientDetails?.name,
          masterfileType: a?.clientDetails?.masterType,
          documentName: Array.from(documentNames).join(", "),
          documentDate: a?.createdAt,
          storageDate: a?.createdAt,
          documentType: a.type,
          cabinet: "CB001",
          tags: ["EC02", "EC03"],
          user: a?.createdBy,
        };
        return obj;
      });

      setTableData(data);
    }
  };

  function storeDocuments(file) {
    setDocument(file);
  }

  const columns = [
    {
      title: "Masterfile Code",
      dataIndex: "masterfileCode",
      sorter: (a, b) => a.masterfileCode - b.masterfileCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Name",
      dataIndex: "masterfileName",
      sorter: (a, b) => a.masterfileName - b.masterfileName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Type",
      dataIndex: "masterfileType",
      sorter: (a, b) => a.masterfileType - b.masterfileType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Document Date",
      dataIndex: "documentDate",
      sorter: (a, b) => a.documentDate - b.documentDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Document Name",
      dataIndex: "documentName",
      sorter: (a, b) => a.documentName - b.documentName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 300,
    },
    {
      title: "Document Type",
      dataIndex: "documentType",
      sorter: (a, b) => a.documentType - b.documentType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Cabinet",
      dataIndex: "cabinet",
      sorter: (a, b) => a.cabinet - b.cabinet,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Tagged Masterfile Code",
      dataIndex: "tags",
      width: 200,
      sorter: (a, b) => a.tags - b.tags,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { tags }) => (
        <>
          {tags.map((tag, i) => {
            return (
              <Tag key={i} color={"orange"}>
                {tag}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Storage Date",
      dataIndex: "storageDate",
      sorter: (a, b) => a.storageDate - b.storageDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "User",
      dataIndex: "user",
      sorter: (a, b) => a.user - b.user,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Action",
      dataIndex: "action",
      // fixed: "right",
      width: 100,
      render: (_, { id, masterfileCode }) => (
        <Link
          to={`/app/storage?id=${id}&code=${masterfileCode}`}
          state={{ id }}
          className="action-btn icon-btn"
        >
          <i className="fal fa-eye"></i>
        </Link>
      ),
    },
  ];

  const onExportClick = () => {
    downloadAsCSV(tableData, "master-search-data-export");
  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  let formSchema1 = [
    {
      name: "masterfileCode",
      value: "",
      type: "text",
      label: "Masterfile Code",
      placeholder: "Enter masterfile code",
    },
    {
      name: "masterfileName",
      value: "",
      type: "text",
      label: "Masterfile Name",
      placeholder: "Enter masterfile name",
    },
  ];

  let formSchema2 = [
    {
      name: "documentDate",
      value: "",
      type: "date",
      label: "Document Date",
      placeholder: "Select document date",
    },
    {
      name: "documentName",
      value: "",
      type: "text",
      label: "Document Name",
      placeholder: "Enter document name",
    },
    {
      name: "documentType",
      value: "",
      type: "select",
      label: "Document Type",
      placeholder: "Select document type",
    },
    {
      name: "cabinet",
      value: "",
      type: "select",
      label: "Cabinet",
      placeholder: "Select cabinet",
    },
    {
      name: "tags",
      value: "",
      type: "select",
      label: "Tagged Masterfile Code",
      placeholder: "Select tags",
    },
    {
      name: "storedDate",
      value: "",
      type: "date",
      label: "Stored Date",
      placeholder: "Select stored date",
    },
    {
      name: "userDetails",
      value: "",
      type: "text",
      label: "User Details",
      placeholder: "Select user details",
    },
  ];

  const handleOk = () => {
    setUploadModal(false);
  };
  const handleCancel = () => {
    setUploadModal(false);
  };
  const handleCabinetOk = () => {
    setCabinetModal(false);
  };
  const handleCabinetCancel = () => {
    setCabinetModal(false);
  };
  const getPagination = async (current, pageSize) => {
    let params = { ...queryParams };

    params._start = (current - 1) * pageSize;
    params._limit = pageSize;
    dispatch(getManualStorage(params));
    setQueryParams(params);
  };
  return (
    <div>
      <div className="page-header">
        <h1>Manual Storage</h1>
        <div className="d-flex gap-3">
          <button className="outline-btn" onClick={() => setCabinetModal(true)}>
            <i className="fal fa-file-upload"></i> New Cabinet
          </button>

          <button className="primary-btn" onClick={() => setUploadModal(true)}>
            <i className="fal fa-file-upload"></i> Upload New
          </button>
        </div>
      </div>
      <div className="page-content">
        <h6 className="primary-title mb-1 mb-md-4">Search Storage</h6>
        <div className="custom-form mb-2 mb-md-3">
          <div className="row align-items-end">
            <div className="col-md-2">
              <label htmlFor="">Type of Masterfile</label>
              <Select
                className="custom-select w-100 ptb-1"
                placeholder="Select type"
                options={[
                  {
                    value: "client",
                    label: "Client",
                  },
                  {
                    value: "parent-client",
                    label: "Parent Client",
                  },
                  {
                    value: "entity",
                    label: "Entity",
                  },
                  {
                    value: "individual",
                    label: "Individual",
                  },
                  {
                    value: "corporateEntity",
                    label: "Corporate Entity",
                  },
                ]}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="">Name</label>
              <input
                type="text"
                className="w-100"
                placeholder="Enter name"
                name="name"
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="">Code</label>
              <input
                type="text"
                className="w-100"
                placeholder="Enter code"
                name="code"
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="">Date Stored</label>
              <RangePicker
                disabledDate={disabledDate}
                className="custom-datepicker"
              />
            </div>
            <div className="col-md-2">
              <button className="primary-btn mb-1">
                <i className="fal fa-search"></i> Initiate Search
              </button>
            </div>
          </div>
        </div>
        {/* <div className="d-flex justify-content-end">
        <button className="primary-btn" onClick={onExportClick}>
          <i className="fal fa-file-download"></i> Export
        </button>
      </div> */}
        <Table
          className="custom-table"
          loading={manualStorage?.isLoading}
          columns={columns}
          dataSource={tableData}
          scroll={{ x: 1600 }}
          pagination={{
            className: "custom-pagination",
            itemRender: customPagination,
            onChange: getPagination,
            defaultCurrent: 1,
            pageSize: queryParams?._limit,
            total: manualStorage?.data?.totalCount,
          }}
        />
        <Modal
          title={"Upload New Document"}
          className="custom-modal"
          open={uploadModal}
          width={800}
          onOk={handleOk}
          okText="Upload"
          onCancel={handleCancel}
        >
          <div className="custom-form">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="">Document</label>
                <UploadZ
                  onStoreFile={storeDocuments}
                  fileName={"document"}
                  hideDownload={true}
                />
              </div>
              <div className="col-md-6 pt-2">
                <FormZ formSchema={formSchema1} ref={formRef1} />
              </div>
            </div>
            <FormZ
              formSchema={formSchema2}
              formClass="row"
              childClass="col-md-6"
              ref={formRef2}
            />
          </div>
        </Modal>
        <Modal
          title={"Upload New Document"}
          className="custom-modal"
          open={cabinetModal}
          width={800}
          onOk={handleCabinetOk}
          okText="Upload"
          onCancel={handleCabinetCancel}
        >
          <div className="custom-form">
            {/* <div className="row">
              <div className="col-md-6 pt-2">
                <FormZ formSchema={formSchema1} ref={formRef1} />
              </div>
            </div> */}
            <FormZ
              formSchema={formSchema2}
              formClass="row"
              childClass="col-md-6"
              ref={formRef3}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
}
