import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
import { Select, DatePicker, Modal, Radio } from "antd";
import dayjs from "dayjs";
import SignatureCanvas from "react-signature-canvas";

import FormZ from "../components/FormZ";
import TabZ from "../components/TabZ";

import {
  sendNotify,
  downloadFile,
  fileToBase64,
  dateOptions,
  monthOptions,
} from "../helper";

import Signature1 from "../assets/img/signature-1.png";
import Signature2 from "../assets/img/signature-2.png";
import Signature3 from "../assets/img/signature-3.png";

import NoSignature from "../assets/img/no-signature.png";

import COI_PDF from "../assets/pdf/certificate-of-incumbency.pdf";

const Controls = () => {
  const { zoomIn, zoomOut, resetTransform, centerView } = useControls();

  const downloadPDF = () => {
    downloadFile(COI_PDF, "certificate-of-incumbency");
  };

  return (
    <div className="control-tools d-flex gap-3">
      <button onClick={() => zoomIn()}>
        <i className="far fa-search-plus"></i>
      </button>
      <button onClick={() => zoomOut()}>
        <i className="far fa-search-minus"></i>
      </button>
      <button onClick={downloadPDF}>
        <i className="far fa-arrow-to-bottom"></i>
      </button>
      {/* <button onClick={() => resetTransform()}>
        <i className="fal fa-times-circle"></i>
      </button>
      <button onClick={() => centerView()}>
        <i className="fal fa-arrows"></i>
      </button> */}
    </div>
  );
};

export default function CoiRequest() {
  const formRef = useRef();
  const signFormRef = useRef();
  const signImgRef = useRef();

  let signPad = {};

  let formSchema = [
    {
      name: "certificateTemplate",
      value: null,
      type: "select",
      label: "Certificate Template",
      placeholder: "Select template of COI",
      options: [
        {
          value: 1,
          label: "Template 1",
        },
        {
          value: 2,
          label: "Template 2",
        },
        {
          value: 3,
          label: "Template 3",
        },
      ],
      required: true,
    },
    {
      name: "certificateType",
      value: null,
      type: "select",
      label: "Certificate Type",
      placeholder: "Select type of COI",
      options: [
        {
          value: true,
          label: "With register of charges",
        },
        {
          value: false,
          label: "Without register of charges",
        },
      ],
      required: true,
    },
  ];

  let signFormSchema = [
    {
      name: "fullName",
      value: "",
      type: "text",
      label: "Full Name",
      placeholder: "Enter full name",
      required: true,
    },
    {
      name: "jobTitle",
      value: "",
      type: "text",
      label: "Job Title",
      placeholder: "Enter job title",
      required: true,
    },
  ];

  const [showCOI, setShowCOI] = useState(true);
  const [signModal, setSignModal] = useState(false);
  const [createSignModal, setCreateSignModal] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [signing, setSigning] = useState(false);
  const [signImgError, setSignImgError] = useState(false);
  const [signImgLoading, setSignImgLoading] = useState(false);
  const [newSign, setNewSign] = useState(null);
  const [previewSign, setPreviewSign] = useState("");

  const generateCOI = () => {
    if (formRef.current.validForm()) {
      let data = formRef.current.getPayload();
      setShowCOI(true);
    } else {
      sendNotify("error", "Some fields are missing!");
    }
  };

  const handleOk = () => {
    setSignModal(false);
  };
  const handleCancel = () => {
    setSignModal(false);
  };

  const labels = [
    {
      key: 1,
      label: (
        <>
          <i className="far fa-pen"></i> DRAW
        </>
      ),
    },
    {
      key: 2,
      label: (
        <>
          <i className="far fa-arrow-to-top"></i> UPLOAD
        </>
      ),
    },
  ];

  const onTabChange = (value) => {
    clearSign();
    setActiveTab(value);
  };

  const clearSign = () => {
    if (activeTab == 1) {
      signPad.clear();
      setSigning(false);
    } else {
      setPreviewSign("");
    }
    setNewSign(null);
  };

  const saveSign = () => {
    let sign = signPad.toDataURL();
    setNewSign(sign);
  };

  const onImgUpload = (e) => {
    setSignImgError(false);
    let file = e.target.files[0];
    if (file) {
      if (file?.size > 5242880) {
        setSignImgError(true);
        sendNotify("error", "Image is too big!, Upload below 5MB file.");
      } else {
        let type = file?.name.substring(file?.name.lastIndexOf(".") + 1);
        var regex = new RegExp("(.*?)(png|jpg|jpeg|svg)$");
        if (regex.test(type)) {
          setSignImgLoading(true);
          setPreviewSign(URL.createObjectURL(file));
          fileToBase64(file)
            .then((data) => {
              setNewSign(data);
              sendNotify("success", "Image uploaded successfully.");
              setSignImgLoading(false);
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        } else {
          setSignImgError(true);
          sendNotify(
            "error",
            "Only PNG, JPG, JPEG, or SVG images are supported."
          );
        }
      }
    }
  };
  // {jai -Certificate of Incumbency}
  return (
    <div>
      <div className="page-header">
        <Link to={"/app/master-search"}>
          <h1 className="d-flex align-items-center gap-2">
            <i className="fal fa-long-arrow-left"></i> Certificate of Incumbency
          </h1>
        </Link>
        <div className="d-flex gap-3">
          <button className="outline-btn">Refer Entity Overview</button>
          <button className="danger-btn">Reject</button>
          <button className="primary-btn">Send for Review</button>
        </div>
      </div>
      <div className="page-content">
        <FormZ
          formSchema={formSchema}
          ref={formRef}
          formClass="custom-form row mb-3 gy-3 gx-4"
          childClass="col-md-4 mb-0"
          labelClass="mb-1"
          inputClass="w-100"
        />
        <button className="primary-btn" onClick={generateCOI}>
          Generate COI
        </button>
        {showCOI && (
          <div className="pdf-zoom-wrapper mt-4">
            <TransformWrapper
              initialScale={1}
              centerOnInit
              panning={{ excluded: ["input"] }}
              doubleClick={{ excluded: ["input"] }}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <div>
                  <Controls />
                  <TransformComponent wrapperClass="w-100 pdf-transform">
                    <div className="custom-pdf-wrapper">
                      <h1>CERTIFICATE OF INCUMBENCY</h1>
                      <p>
                        WE, SAMPLE AGENT, REGISTERED AGENT of &nbsp;
                        <input
                          type="text"
                          placeholder="Sample Entity"
                          className="pdf-input"
                          style={{ width: 100 }}
                        />
                        &nbsp; Ltd ("the company"), a company existing under the
                        laws of the British Virgin Islands, hereby
                      </p>
                      <hr />
                      <h5>CERTIFY:</h5>
                      <ol>
                        <li>
                          That the Company was duly incorporated on the &nbsp;
                          <Select
                            className="custom-select pdf-select"
                            defaultValue={12}
                            placeholder="Select"
                            options={dateOptions}
                          />
                          &nbsp; <b>day of</b> &nbsp;
                          <Select
                            className="custom-select pdf-select"
                            defaultValue={"May"}
                            placeholder="Select"
                            options={monthOptions}
                            style={{ width: 100 }}
                          />
                          , &nbsp;
                          <DatePicker
                            className="custom-datepicker pdf-datepicker"
                            picker="year"
                            defaultValue={dayjs("2024", "YYYY")}
                            style={{ width: 80 }}
                          />
                          &nbsp; under BVI BC No &nbsp;
                          <input
                            type="text"
                            placeholder="93028272"
                            className="pdf-input"
                            style={{ width: 120 }}
                          />
                          &nbsp; as is recorded in the Register of Companies of
                          the British Virgin Islands and is currently in good
                          standing.
                        </li>
                        <li>
                          That the Company's Registered Agent is
                          <b>Sample Agent</b>
                          whose physical address is located at 2nd Floor, Sample
                          Building, Main Street, Road Town, Tortola, British
                          Virgin Islands.
                        </li>
                        <li>
                          That the Company's Registered Office is located at
                          <input
                            type="text"
                            placeholder="2nd Floor, Sample Building, Main Street, Road Town, Tortola, British Virgin Islands."
                            className="pdf-input w-100"
                          />
                        </li>
                        <li>
                          That insofar as we are aware, there are no legal,
                          winding up, arbitration or administrative proceedings
                          pending or threatened against the Company and that no
                          Receiver has been appointed over its assets.
                        </li>
                        <li>
                          There are no entries in the Company’s Register of
                          Charges held at its Registered Office.
                        </li>
                        <li>
                          That insofar as we are aware there are no measures
                          taken or about to be taken, to have the Company
                          dissolved or otherwise removed from the Register of
                          Companies.
                        </li>
                        <li>
                          That the Company's <b>Director</b> at the date hereof
                          is:{" "}
                          <input
                            type="text"
                            placeholder="John Doe"
                            className="pdf-input"
                            style={{ width: 80 }}
                          />
                        </li>
                        <li>
                          That the Company's <b>Shareholder</b> at the date
                          hereof is: <br /> &emsp;
                          <input
                            type="text"
                            placeholder="Billy Smith"
                            className="pdf-input"
                            style={{ width: 80 }}
                          />
                          &emsp;
                          <b>- holding</b> &nbsp;
                          <input
                            type="text"
                            placeholder="250"
                            className="pdf-input"
                            style={{ width: 42 }}
                          />
                          &nbsp; shares
                        </li>
                      </ol>
                      <p>
                        <b>IN WITNESS WHEREOF</b>, we have hereunto affixed our
                        signature this
                      </p>
                      <div className="row gy-4 gx-5">
                        <div className="col-md-6">
                          <div>
                            <p className="mb-0">
                              {/* <b>[Signature]</b> */}
                              <div
                                className="signature-wrapper"
                                onClick={() => setSignModal(true)}
                              >
                                <span className="icon">
                                  <i className="fal fa-signature"></i>
                                </span>
                                <label htmlFor="" className="custom-label req">
                                  Click here to sign
                                </label>
                              </div>
                              <hr />
                              By: <b>[Name of Director]</b>
                              <br />
                              Authorized Signatory: <b>SAMPLE AGENT</b>
                              <br />
                              <b>Registered Agent</b>
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div>
                            <p className="mb-0">
                              {/* <b>[Signature]</b> */}
                              <div className="signature-wrapper">
                                <span className="icon">
                                  <i className="fal fa-signature"></i>
                                </span>
                                <label htmlFor="" className="custom-label req">
                                  Click here to sign
                                </label>
                              </div>
                              <hr />
                              By: <b>[Name of Director]</b>
                              <br />
                              Authorized Signatory: <b>SAMPLE AGENT</b>
                              <br />
                              <b>Registered Agent</b>
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div>
                            <p className="mb-0">
                              {/* <b>[Signature]</b> */}
                              <div className="signature-wrapper">
                                <span className="icon">
                                  <i className="fal fa-signature"></i>
                                </span>
                                <label htmlFor="" className="custom-label">
                                  Click here to sign
                                </label>
                              </div>
                              <hr />
                              By: <b>[Name of Director]</b>
                              <br />
                              Authorized Signatory: <b>SAMPLE AGENT</b>
                              <br />
                              <b>Registered Agent</b>
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div>
                            <p className="mb-0">
                              {/* <b>[Signature]</b> */}
                              <div className="signature-wrapper">
                                <span className="icon">
                                  <i className="fal fa-signature"></i>
                                </span>
                                <label htmlFor="" className="custom-label">
                                  Click here to sign
                                </label>
                              </div>
                              <hr />
                              By: <b>[Name of Director]</b>
                              <br />
                              Authorized Signatory: <b>SAMPLE AGENT</b>
                              <br />
                              <b>Registered Agent</b>
                            </p>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      <p>
                        <b>[Date Signed] </b>
                      </p>
                    </div>
                  </TransformComponent>
                </div>
              )}
            </TransformWrapper>
          </div>
        )}
      </div>
      <Modal
        title={
          <>
            <h6>Choose Signature</h6>
            <p>
              Choose a saved signature to use in this document or create a new
              one.
            </p>
          </>
        }
        open={signModal}
        className="custom-modal-2"
        onOk={handleOk}
        onCancel={handleCancel}
        width={700}
        footer={false}
      >
        {true ? (
          <div>
            <div className="signature-option">
              <Radio.Group className="w-100">
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td>
                        <Radio className="custom-radio" value={1}>
                          Steven Pointer
                        </Radio>
                      </td>
                      <td>
                        <label htmlFor="">Director</label>
                      </td>
                      <td>
                        <img src={Signature1} alt="" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Radio className="custom-radio" value={2}>
                          Windy Summers
                        </Radio>
                      </td>
                      <td>
                        <label htmlFor="">Director</label>
                      </td>
                      <td>
                        <img src={Signature2} alt="" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Radio className="custom-radio" value={3}>
                          MaryRose Hightower
                        </Radio>
                      </td>
                      <td>
                        <label htmlFor="">Senior Director</label>
                      </td>
                      <td>
                        <img src={Signature3} alt="" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Radio.Group>
            </div>
            <div className="d-flex justify-content-end gap-3 mt-2">
              <button
                className="outline-btn"
                onClick={() => setCreateSignModal(true)}
              >
                Create New Signature
              </button>
              <button className="primary-btn">Use Signature</button>
            </div>
          </div>
        ) : (
          <div className="no-sign">
            <img src={NoSignature} alt="" />
            <p>It looks like you don’t have any saved signatures yet.</p>
            <button
              className="primary-btn mx-auto mt-3"
              onClick={() => setCreateSignModal(true)}
            >
              Create New Signature
            </button>
          </div>
        )}
      </Modal>
      <Modal
        title={
          <>
            <h6>Create New Signature</h6>
            <p>Draw or upload an image of your signature.</p>
          </>
        }
        open={createSignModal}
        className="custom-modal-2"
        onOk={() => setCreateSignModal(false)}
        okText={"Create Signature"}
        onCancel={() => setCreateSignModal(false)}
        width={500}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div>
          <TabZ
            labels={labels}
            defaultActive={activeTab}
            onChange={onTabChange}
          />
          <div
            className={`signature-wrapper mt-4 ${
              !signing && activeTab == 1 ? "sign-placeholder" : ""
            }`}
          >
            {activeTab == 1 ? (
              <SignatureCanvas
                onBegin={() => setSigning(true)}
                onEnd={saveSign}
                ref={(ref) => {
                  signPad = ref;
                }}
                canvasProps={{ width: 436, height: 120 }}
              />
            ) : previewSign ? (
              <img src={previewSign} className="sign-img" alt="sign" />
            ) : (
              <div className="sing-img-upload">
                <input
                  ref={signImgRef}
                  type="file"
                  name="sign-img"
                  onChange={onImgUpload}
                  accept=".png,.jpg,.jpeg,.svg"
                />
                <span className="icon">
                  {signImgLoading ? (
                    <i className="far fa-spinner-third fa-spin"></i>
                  ) : (
                    <>
                      {signImgError ? (
                        <i className="fal fa-times-circle color-red"></i>
                      ) : (
                        <i className="fal fa-arrow-from-bottom"></i>
                      )}
                    </>
                  )}
                </span>
                <p>PNG, JPG or SVG (max. 5MB)</p>
              </div>
            )}
            {(signing || previewSign) && (
              <button className="clear-btn" onClick={clearSign}>
                <i className="far fa-trash"></i>
              </button>
            )}
          </div>
          <FormZ
            formSchema={signFormSchema}
            ref={signFormRef}
            formClass="custom-form row mt-2 mb-4 gy-3 gx-4"
            childClass="col-md-12 mb-0"
            labelClass="mb-1"
            inputClass="w-100"
          />
        </div>
      </Modal>
    </div>
  );
}
