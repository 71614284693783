import React from "react";
import { Link } from "react-router-dom";

import AccessDeniedImg from "../assets/img/access-denied.png";

export default function AccessDenied() {
  return (
    <div className="page-content access-denied">
      <img src={AccessDeniedImg} alt="access denied" />
      <h1>You don't have authorization to view this page</h1>
      <div className="text-center mt-3">
        <Link to={"/app/home"}>Back to home</Link>
      </div>
    </div>
  );
}
