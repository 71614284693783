import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Checkbox, Spin } from "antd";

import SampleDoc from "../assets/pdf/sample.pdf";

import UploadZ from "../components/UploadZ";

import { sendNotify, getQuery, fetchApi, Each } from "../helper";

const document = [
  {
    url: SampleDoc,
    file: "Resolution of Appointment",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: true,
  },
  {
    url: SampleDoc,
    file: "Resolution of Resignation",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
  {
    url: SampleDoc,
    file: "Letter of Resignation",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
  {
    url: SampleDoc,
    file: "Consent to Act as Director",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
  {
    url: SampleDoc,
    file: "Passport/ID",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
  {
    url: SampleDoc,
    file: "Address Proof",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
  {
    url: SampleDoc,
    file: "CV",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
  {
    url: SampleDoc,
    file: "Reference Letter",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
  {
    url: SampleDoc,
    file: "Other",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
];

export default function ChangeOfDirector() {
  const query = getQuery();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [docs, setDocs] = useState(document);
  const [explainModel, setExplainModel] = useState(false);
  const [rejectModel, setRejectModel] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  let local = typeof window !== "undefined" ? localStorage : null;
  useEffect(() => {
    const storedToken = localStorage.getItem("accessToken");
    if (storedToken) setAccessToken(storedToken);
    handleRefresh();
  }, []);

  const handleOk = () => {
    setExplainModel(false);
    setRejectModel(false);
  };
  const handleCancel = () => {
    setExplainModel(false);
    setRejectModel(false);
  };

  const handleRefresh = () => {
    setLoading(true);
    const payload = {
      method: "get",
      url: `/serviceRequest?id=${query?.id}`,
    };

    fetchApi(payload)
      .then((res) => {
        setData(res?.data?.rows?.[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
        sendNotify("error", "Failed to load data");
      });
  };
  const onChangeApproval = (file, bool) => {
    let arr = [...docs];
    let index = arr.findIndex((doc) => doc.file == file);
    arr[index].isApproved = bool;

    setDocs(arr);
  };

  return (
    <div>
      <div className="page-header">
        <Link to={"/app/active-requests"}>
          <h1 className="d-flex align-items-center gap-2">
            <i className="fal fa-long-arrow-left"></i> Change Of Director
          </h1>
        </Link>
        <div className="d-flex gap-3">
          <button
            className="secondary-btn"
            onClick={() => setExplainModel(true)}
          >
            Request Resubmission
          </button>
          <button className="outline-btn">Save</button>
          <button className="primary-btn">Submit</button>
        </div>
      </div>
      <div className="page-content">
        <div className="custom-form row gy-4 mb-5">
          <div className="col-md-3">
            <label htmlFor="">Entity Code</label>
            <span className="light-badge">38202</span>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Entity Name</label>
            <span className="light-badge">Sample Entity</span>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Client Code</label>
            <span className="light-badge">38202</span>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Client Name</label>
            <span className="light-badge">Sample Client</span>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Submitted</label>
            <span className="light-badge">12 May 2024, 12.54 PM</span>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Assignee</label>
            <span className="light-badge">
              <i className="fal fa-user-circle"></i> John Doe
            </span>
          </div>
        </div>
        <div className="custom-box">
          <span className="light-badge mb-4">
            <i className="fal fa-user-circle"></i> Individual
          </span>
          <div className="custom-form row gy-4 mb-4">
            <div className="col-md-3">
              <label htmlFor="">Full Name</label>
              <span className="light-badge">John Doe</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Email</label>
              <span className="light-badge">johndoe@company.com</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Contact Number</label>
              <span className="light-badge">+1 284-555-1234</span>
            </div>
          </div>
          <div className="row gy-2 gx-4">
            <Each
              array={docs}
              render={(item, index) => (
                <div className="col-md-3">
                  <div>
                    <Checkbox className="custom-checkbox">
                      {item?.file}
                    </Checkbox>
                    <UploadZ
                      file={item}
                      downloadOnly={true}
                      showApprove={true}
                      onFileApprove={onChangeApproval}
                      showTranslation={true}
                      showExtraction={true}
                    />
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </div>
      <Modal
        className="custom-modal-2"
        open={explainModel}
        width={800}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Request Resubmission"
        cancelButtonProps={{ className: "d-none" }}
      >
        <div className="custom-form mt-4 pt-2">
          <h6 className="input-title">Resolution of Appointment</h6>
          <p className="description mb-3">
            Explain why this document needs to be resubmitted.
          </p>
          <textarea
            name=""
            id=""
            className="mb-4"
            placeholder="Leave a comment..."
          ></textarea>

          <h6 className="input-title">Letter of Resignation</h6>
          <p className="description mb-3">
            Explain why this document needs to be resubmitted.
          </p>
          <textarea
            name=""
            id=""
            className="mb-4"
            placeholder="Leave a comment..."
          ></textarea>
        </div>
      </Modal>
      <Modal
        className="custom-modal-2"
        open={rejectModel}
        width={800}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Reject"
        okButtonProps={{ className: "reject-btn" }}
        cancelButtonProps={{ className: "d-none" }}
      >
        <div className="mt-4 pt-2">
          <h6 className="input-title">Reject Request</h6>
          <p className="description mb-3">
            Select reason(s) as to why this request should be rejected.
          </p>
          <Checkbox className="custom-checkbox py-1 font-md">
            Incomplete or inaccurate information provided in the documents
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Insufficient supporting documentation or evidence
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            The requesting entity does not have the legal authority to do so
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Lack of authorization or signature from the appropriate authority
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Failure to pay required fees or charges associated with the request
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Issues with the authenticity or validity of the requesting entity or
            individuals involved
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Suspected fraud or misrepresentation in the application
          </Checkbox>
        </div>
      </Modal>
    </div>
  );
}
