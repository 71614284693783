import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import Logo from "../../assets/img/sidebar-logo.png";
import routes from "../../helper/routes";
import subMenu from "../../helper/translations/subMenu.json";
import mainMenu from "../../helper/translations/mainMenu.json";
import overAll from "../../helper/translations/overAll.json";

import { languagesArray } from "../../store/language/languageSlice";

export default function Sidebar() {
  let details = useSelector((state) => state.auth.userDetails);

  const [menuShow, setMenuShow] = useState(null);

  const { language } = useSelector((state) => state.language);
  const [selctedLanguage, setSelctedLanguage] = useState("");
  useEffect(() => {
    let index = languagesArray.find((tc) => tc.value == language);
    setSelctedLanguage(index.value);
  }, [language]);
  let t = mainMenu.translations[selctedLanguage] || mainMenu.translations.en;
  let o = overAll[selctedLanguage] || overAll.en;

  const translationSubMenu = (key) => {
    let data =
      subMenu.translations[selctedLanguage]?.[key] ||
      subMenu.translations["en"][key];
    return data;
  };

  const parentRoutes = [
    { id: 0, name: t.masterName, icon: "fa-file-plus" },
    { id: 1, name: t.newIncorporation, icon: "fa-newspaper" },
    { id: 2, name: t.MLRO, icon: "fa-ticket-alt" },
    { id: 3, name: t.postIncorporation, icon: "fa-file-import" },
    { id: 4, name: t.financeFilings, icon: "fa-file-invoice-dollar" },
    // { id: 5,  name: "Access Control", icon: "fa-users-cog" },
    { id: 6, name: t.auditsReporting, icon: "fa-chart-pie" },
    { id: 7, name: t.settings, icon: "fa-cogs" },
    { id: 8, name: t.masterSetup, icon: "fa-user-shield" },
  ];

  const toggleMenu = (index) => {
    if (index === menuShow) {
      setMenuShow(null);
    } else {
      setMenuShow(index);
    }
  };

  return (
    <div className={`sidebar`}>
      <div>
        <div className="logo">
          <img src={Logo} alt="North Lark" />
          <p>{o.ProjctTitle}</p>
        </div>
        <ul className="sidebar-list">
          <li className="sidebar-parent">
            <NavLink to={"/app/home"}>
              <div className="sidebar-menu-text d-flex align-items-center gap-3">
                <i className="fal fa-home"></i> {t.home}
              </div>
            </NavLink>
          </li>
          {parentRoutes.map((pr) => {
            const filteredSubRoutes = routes.filter(
              (r) =>
                r.showInMenu &&
                (r.parentId === pr.id || r.duplicate === pr.id) &&
                r.permissions.includes(details?.role)
            );

            if (filteredSubRoutes.length > 0) {
              return (
                <li key={pr.id} className="sidebar-parent">
                  <a className="sidebar-menu" onClick={() => toggleMenu(pr.id)}>
                    <div className="sidebar-menu-text d-flex align-items-center gap-3">
                      <i className={`fal ${pr.icon}`}></i> {pr.name}
                    </div>
                    <i
                      className={`fal fa-chevron-up ${
                        menuShow === pr.id ? "" : "fa-rotate-180"
                      }`}
                    ></i>
                  </a>

                  <ul
                    className={`child-menu ${menuShow === pr.id ? "show" : ""}`}
                  >
                    {filteredSubRoutes.map((route) => (
                      <li key={route.path}>
                        <NavLink to={route.path}>
                          <div className="sidebar-menu-text d-flex align-items-center gap-3">
                            {translationSubMenu(route.name)}
                          </div>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
              );
            }

            return null;
          })}
        </ul>
      </div>
      <ul>
        <li>
          <a className="footer-text">
            <i className="fal fa-question-circle"></i> {t.helpandSupport}
          </a>
        </li>
      </ul>
    </div>
  );
}
