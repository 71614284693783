import IndividualAnalysis from "../components/IndividualAnalysis.jsx";

export default function IndividualAnalysisPage() {
  return (
    <div>
      <div className="page-header">
        <h1>Individual Analysis</h1>
      </div>
      <div className="page-content">
        <IndividualAnalysis actionShow={true} />
      </div>
    </div>
  );
}
