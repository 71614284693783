import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, Modal, TreeSelect, Switch, Checkbox, Table } from "antd";
import dayjs from "dayjs";

import FormZ from "../../../components/FormZ";
import TableSortArrows from "../../../components/tableSortArrows";

import {
  sendNotify,
  getOptions,
  focusOn,
  fetchApi,
  convertQueryParams,
  getQuery,
} from "../../../helper";
import { getOrganizationStructure } from "../../../store/organizationStructure/organizationStructureSlice";
import { getCopEntityDetails } from "../../../store/copEntityDetails/copEntityDetailsSlice";

import countries from "../../../helper/countries.json";

const { SHOW_PARENT } = TreeSelect;

export default function Corporate(props) {
  const senderFormRef = useRef(null);
  const receiverFormRef = useRef(null);
  const allocationFormRef = useRef(null);

  const [corporateEntityShow, setCorporateEntityShow] = useState(false);
  let { isLogged, userDetails } = useSelector((state) => state.auth);
  const [objData, setObjData] = useState({});

  const copEntityDetails = useSelector((state) => state.copEntityDetails);
  const organizationStructure = useSelector(
    (state) => state.organizationStructure
  );
  let query = getQuery();
  const dispatch = useDispatch();
  const [checkedList, setCheckedList] = useState({
    shareholders: false,
    ubo: false,
    nominee: false,
  });
  const [historyShow, setHistoryShow] = useState(false);

  const [countriesOption, setCountriesOption] = useState([]);
  const [value, setValue] = useState([]);
  const [sanction, setSanction] = useState("Y");

  const [directorChecked, setDirectorChecked] = useState(false);
  const [reserveChecked, setReserveChecked] = useState(false);
  const [shareholderChecked, setShareholderChecked] = useState(false);
  const [nomineeChecked, setNomineeChecked] = useState(false);
  const [uboChecked, setUboChecked] = useState(false);
  const [openCopEntity, setOpenCopEntity] = useState(false);

  const [selectedId, setSelectedId] = useState("");
  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 5 });
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [formData, setFormData] = useState({});
  const [copEntityArray, setCopEntityArray] = useState([]);
  const [copEntityName, setCopEntityName] = useState([]);
  const [copEntityData, setCopEntityData] = useState([]);
  const [entityArray, setEntityArray] = useState([]);
  const [schema1, setSchema1] = useState({});
  const [schema2, setSchema2] = useState({});
  const [schema3, setSchema3] = useState({});
  const [shareForm, setShareForm] = useState({
    show: false,
    type: "",
  });

  let { data, isLoading, entityData, id } = props;

  const [forms, setForms] = useState({
    individual: [1],
    directors: [1],
    shareholders: [1],
    ubo: [1],
    financialRecord: [],
    corporateEntity: [1],
  });

  const convertToOptions = () => {
    let options = [
      {
        title: "Worldwide",
        value: "0",
        key: "0",
        children: [],
      },
    ];

    Object.keys(countries).forEach(function (key, index) {
      let continents = {
        title: key,
        value: `0-${index}`,
        key: `0-${index}`,
        children: [],
      };
      countries[key].forEach((country, i) => {
        let cat = {
          title: country?.name,
          value: `0-${index}-${i}`,
          key: `0-${index}-${i}`,
        };
        continents.children.push(cat);
      });

      options[0]?.children.push(continents);
    });

    // console.log(options);
    setCountriesOption(options);
  };

  const onChange = (newValue) => {
    console.log("onChange ", newValue);
    setValue(newValue);
  };
  const corpDeleteOnClick = async (id, index) => {
    setSelectedId(id);
    const details = copEntityDetails.data.rows.find((e) => e._id === id);
    let payload = {};

    payload.method = "DELETE";
    payload.url = `/copEntityDetails?id=${id}`;

    fetchApi(payload)
      .then((res) => {
        const params = { enId: details.enId };
        dispatch(getCopEntityDetails(params));
        sendNotify("success", res?.message);
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };

  const tProps = {
    treeData: countriesOption,
    value,
    onChange,
    className: "custom-select w-100 ptb-1",
    treeCheckable: true,
    showSearch: false,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: "Select geography of business",
    allowClear: true,
  };

  const formRef = useRef();
  const adsFormRef = useRef();
  const riskFormRef = useRef();

  const handleChange = (value) => {
    setSanction(value);
  };

  let adsFormSchema = [
    {
      name: "line_1",
      value: "",
      type: "text",
      label: "Address Line 01",
      placeholder: "Enter address",
      disabled: true,
    },
    {
      name: "line_2",
      value: "",
      type: "text",
      label: "Address Line 02",
      placeholder: "Enter address",
      disabled: true,
    },
    {
      name: "line_3",
      value: "",
      type: "text",
      label: "Address Line 03",
      placeholder: "Enter address",
      disabled: true,
    },
    {
      name: "country",
      value: "",
      type: "select",
      label: "Country",
      placeholder: "Select country",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
      disabled: true,
      labelClass: "mb-1",
      customClass: "col-md-3 flex-column align-items-start",
    },
    {
      name: "zipCode",
      value: "",
      type: "number",
      label: "Post/Zip code",
      labelClass: "mb-1",
      disabled: true,
      placeholder: "Enter post/zip code",
      customClass: "col-md-3 flex-column align-items-start",
    },
  ];

  let riskFormSchema = [
    {
      name: "riskRating",
      value: "",
      type: "select",
      label: "Risk Rating",
      placeholder: "Select risk level",
      disabled: true,
      options: [
        {
          value: "low",
          label: "Low",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "high",
          label: "High",
        },
      ],
    },
    {
      name: "listed",
      value: "",
      type: "select",
      label: "Listed or Regulated",
      placeholder: "Select",
      disabled: true,
      options: [
        {
          value: "Y",
          label: "Yes",
        },
        {
          value: "N",
          label: "No",
        },
      ],
      customClass: "w-150px",
    },
    {
      customClass: sanction == "Y" ? "col-md-3" : "col-lg-1",
      label: "Sanction",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${sanction == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={sanction}
            disabled={true}
            onChange={handleChange}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {sanction == "Y" && (
            <input
              type="text"
              disabled={true}
              className="w-100"
              placeholder="Enter sanction"
            />
          )}
        </div>
      ),
    },
    // {
    //   customElement: fileCreation ? (
    //     <div></div>
    //   ) : (
    //     <button className="primary-btn mb-15">Confirm</button>
    //   ),
    // },
  ];

  const [individualFormSchema, setIndividualFormSchema] = useState([]);

  useEffect(() => {
    setIndividualFormSchema([
      {
        name: "code",
        value: "",
        type: "select",
        label: "Corporate Entity Code",
        placeholder: "Enter corporate entity code",
        allowClear: true,
        //  options: [],
        loading: isLoading,
        showSearch: true,
      },
      {
        name: "name",
        value: "",
        type: "select",
        label: "Corporate Entity Name",
        placeholder: "Enter corporate entity name",
        allowClear: true,
        showSearch: true,
        //   disabled: true,
      },
      {
        name: "type",
        value: "",
        type: "select",
        label: "Corporate Entity Type",
        placeholder: "Enter corporate entity type",
        options: [
          {
            value: "BVIBC",
            label: "BVIBC",
          },
          {
            value: "trust",
            label: "Trust",
          },
          {
            value: "foundation",
            label: "Foundation",
          },
          {
            value: "general-partnership",
            label: "General Partnership",
          },
          {
            value: "limited-partnership",
            label: "Limited Partnership",
          },
        ],
        disabled: true,
      },
      {
        name: "incorporationDate",
        value: "",
        type: "date",
        label: "Incorporation Date",
        placeholder: "Enter Date (DD/MM/YYYY)",
        format: "DD/MM/YYYY",
        disabled: true,
      },
      {
        name: "incorporationNumber",
        value: "",
        type: "text",
        label: "Incorporation Number",
        placeholder: "Enter incorporation number",
        disabled: true,
      },
      {
        name: "incorporationCountry",
        value: "",
        type: "select",
        label: "Country Of Incorporation",
        placeholder: "Select Country Of Incorporation",
        showSearch: true,
        options: getOptions(data),
        loading: isLoading,
        disabled: true,
      },
      {
        label: "Geography of business",
        customElement: (
          <TreeSelect {...tProps} placement="topLeft" disabled={true} />
        ),
      },
      {
        name: "busNature",
        value: "",
        type: "select",
        label: "Nature of Business",
        placeholder: "Select nature of business",
        showSearch: true,
        options: [
          {
            value: "manufacturing",
            label: "Manufacturing",
          },
          {
            value: "retail",
            label: "Retail",
          },
          {
            value: "wholesale",
            label: "Wholesale",
          },
          {
            value: "services",
            label: "Services",
          },
          {
            value: "agriculture",
            label: "Agriculture",
          },
          {
            value: "realEstate",
            label: "Real Estate",
          },
          {
            value: "construction",
            label: "Construction",
          },
          {
            value: "transportation",
            label: "Transportation",
          },
          {
            value: "healthcare",
            label: "Healthcare",
          },
          {
            value: "education",
            label: "Education",
          },
          {
            value: "financialServices",
            label: "Financial Services",
          },
          {
            value: "informationTechnology",
            label: "Information Technology",
          },
          {
            value: "entertainment",
            label: "Entertainment",
          },
          {
            value: "hospitality",
            label: "Hospitality",
          },
          {
            value: "utilities",
            label: "Utilities",
          },
          {
            value: "miningAndDrilling",
            label: "Mining and Drilling",
          },
          {
            value: "rAndD",
            label: "R&D",
          },
          {
            value: "nonProfit",
            label: "Non-Profit",
          },
          {
            value: "eCommerce",
            label: "E-commerce",
          },
          {
            value: "freelancing",
            label: "Freelancing",
          },
          {
            value: "gambling",
            label: "Gambling",
          },
          {
            value: "other",
            label: "Other",
          },
        ],
        disabled: true,
      },
      {
        name: "busDesc",
        value: "",
        type: "text",
        label: "Description of Business",
        placeholder: "Enter description of your business",
        disabled: true,
      },
      {
        name: "corpInfoNo",
        value: "",
        type: "text",
        label: "Corporate Tax Info Number",
        placeholder: "Enter corporate tax info number",
        disabled: true,
      },
      {
        name: "relatedEntityCode",
        value: "",
        type: "select",
        label: "Related Entity Code",
        placeholder: "Enter related entity code",
        options: getOptions(entityArray),
        disabled: true,
        // options: [
        //   {
        //     value: "E203",
        //     label: "E203",
        //   },
        //   {
        //     value: "E204",
        //     label: "E204",
        //   },
        //   {
        //     value: "E205",
        //     label: "E205",
        //   },
        // ],
      },
      // {
      //   name: "dateOfCreation",
      //   value: "",
      //   type: "date",
      //   label: "Date of Creation",
      //   placeholder: "Select date",
      // },
    ]);
  }, [isLoading]);

  useEffect(() => {
    let params = { enId: id ? id : query.id };
    dispatch(getCopEntityDetails(params));
    dispatch(getOrganizationStructure(params));
    handleRefresh();
    convertToOptions();
  }, [id]);
  useEffect(() => {
    if (id || query.id) {
      neworganizationStructure();
    }
  }, [copEntityDetails, organizationStructure]);
  const neworganizationStructure = async () => {
    if (organizationStructure?.data?.rows?.length) {
    } else {
      let obj = {
        master: true,
        enId: id ? id : query.id,
      };
      let payload = {
        method: "POST",
        url: `/organizationStructure`,
        data: obj,
      };

      fetchApi(payload)
        .then((res) => {
          if (res.status == 201 && res?.data?.length) {
            dispatch(getOrganizationStructure(queryParams));
          }
        })
        .catch((error) => {});
    }
  };
  let directorFormSchema = [
    {
      name: "dateAppointment",
      value: "",
      type: "date",
      label: "Date of Appointment",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "dateResignation",
      value: "",
      type: "date",
      label: "Date of Resignation",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
  ];

  let shareholderFormSchema = [
    {
      name: "sharesHeld",
      value: "",
      type: "number",
      label: "No. of Shares Held",
      placeholder: "Enter no. of shares held",
    },
    {
      name: "shareEntry",
      value: null,
      type: "select",
      options: [
        {
          value: "Share Transfer",
          label: "Share Transfer",
        },
        {
          value: "New Allocation",
          label: "New Allocation",
        },
      ],
      label: "Add share entry",
      placeholder: "Select",
    },
  ];

  let uboFormSchema = [
    {
      name: "totalSharesHeld",
      value: "",
      type: "number",
      label: "Total Shares Held",
      placeholder: "Enter total shares held",
    },
    {
      name: "ownershipPercentage",
      value: "",
      type: "text",
      label: "Ownership Percentage",
      placeholder: "Enter ownership percentage",
    },
  ];

  let pocFormSchema = [
    {
      name: "contactDetails",
      value: "9023423423",
      type: "number",
      label: "Contact Details",
      placeholder: "Enter contact details",
    },
    {
      name: "email",
      value: "info@gmail.com",
      type: "email",
      label: "Email Address",
      placeholder: "Enter email address",
    },
  ];

  let nomineeShareholder = [
    {
      name: "nomineeAgreementDate",
      value: dayjs("16-08-2023", "DD/MM/YYYY"),
      type: "date",
      label: "Nominee Agreement Date",
      placeholder: "Enter nominee agreement Date",
    },
    {
      name: "nomineeShareEntry",
      value: null,
      type: "select",
      options: [
        {
          value: "Share Transfer",
          label: "Share Transfer",
        },
        {
          value: "New Allocation",
          label: "New Allocation",
        },
      ],
      label: "Add share entry",
      placeholder: "Select",
    },
  ];

  const columns = [
    {
      title: "Share Certificate Number",
      dataIndex: "shareNumber",
      sorter: (a, b) => a.shareNumber - b.shareNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Number of Shares",
      dataIndex: "noShares",
      sorter: (a, b) => a.noShares - b.noShares,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Share Type",
      dataIndex: "shareType",
      sorter: (a, b) => a.shareType - b.shareType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: (
        <div className="d-flex align-items-center">
          Issued Date&nbsp;<i className="fal fa-question-circle"></i>
        </div>
      ),
      dataIndex: "issuedDate",
      sorter: (a, b) => a.issuedDate - b.issuedDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Total No. of Shares held",
      dataIndex: "sharesHeld",
      sorter: (a, b) => a.sharesHeld - b.sharesHeld,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: (
        <div className="d-flex align-items-center">
          Transfer Date&nbsp;<i className="fal fa-question-circle"></i>
        </div>
      ),
      dataIndex: "transferDate",
      sorter: (a, b) => a.transferDate - b.transferDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Transfer Amount",
      dataIndex: "transferAmount",
      sorter: (a, b) => a.transferAmount - b.transferAmount,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Par value",
      dataIndex: "parValue",
      sorter: (a, b) => a.parValue - b.parValue,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Date Ceased to be a Member",
      dataIndex: "dateCeased",
      sorter: (a, b) => a.dateCeased - b.dateCeased,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Comments",
      dataIndex: "comments",
      sorter: (a, b) => a.comments - b.comments,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  // userDetails.role == "admin" &&
  columns.push({
    title: "",
    render: (_, { _id }, index) => (
      <div className="d-flex gap-2 align-items-center justify-content-end">
        <button className="action-btn delete-btn">
          <i className="far fa-trash-alt"></i>
        </button>
        <button className="action-btn">
          <i className="far fa-pencil"></i>
        </button>
      </div>
    ),
  });

  const tableData = [
    {
      key: "1",
      id: "1",
      shareNumber: 55,
      noShares: 100,
      shareType: "Type 1",
      issuedDate: "11-12-2023",
      sharesHeld: "02",
      transferDate: "12-12-2023",
      transferAmount: 500,
      parValue: 100,
      dateCeased: "Sample",
      comments: "Sample",
    },
    {
      key: "2",
      id: "2",
      shareNumber: 56,
      noShares: 500,
      shareType: "Type 2",
      issuedDate: "13-12-2023",
      sharesHeld: "05",
      transferDate: "14-12-2023",
      transferAmount: 800,
      parValue: 100,
      dateCeased: "Sample",
      comments: "Sample",
    },
    {
      key: "3",
      id: "3",
      shareNumber: 57,
      noShares: 250,
      shareType: "Type 1",
      issuedDate: "15-12-2023",
      sharesHeld: "05",
      transferDate: "17-12-2023",
      transferAmount: 400,
      parValue: 100,
      dateCeased: "Sample",
      comments: "Sample",
    },
    {
      key: "4",
      id: "4",
      shareNumber: 58,
      noShares: 120,
      shareType: "Type 2",
      issuedDate: "18-12-2023",
      sharesHeld: "05",
      transferDate: "19-12-2023",
      transferAmount: 1500,
      parValue: 100,
      dateCeased: "Sample",
      comments: "Sample",
    },
  ];

  const addNewCol = (key, { focus }) => {
    let obj = { ...forms };
    let arr = [...obj[key]];
    arr.push(arr.length + 1);
    obj[key] = arr;
    setForms(obj);
    // console.log(focus);
    if (focus) {
      focusOn(key + (arr.length - 1));
    }
  };

  const removeNewCol = (key, index) => {
    let obj = { ...forms };
    let arr = [...obj[key]];
    arr.splice(index, 1);
    obj[key] = arr;
    setForms(obj);
  };

  function removeArrItem(values, arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let index = 0; index < values.length; index++) {
        if (arr[i]?.name === values[index]?.name) {
          arr.splice(i, 1);
        }
      }
    }
  }

  function updateChecked(key, checked) {
    let obj = { ...checkedList };
    if (key == "nominee" && checked) {
      obj["shareholders"] = false;
    }
    if (key == "shareholders" && checked) {
      obj["nominee"] = false;
    }
    obj[key] = checked;

    setCheckedList(obj);
  }

  const addNewChange = (values, checkStatus, name) => {
    updateChecked("ubo", checkStatus);

    let arr = [...individualFormSchema];
    if (checkStatus) {
      arr.push(...values);
    } else {
      removeArrItem(values, arr);
    }
    setIndividualFormSchema(arr);
  };
  const clearForm = () => {
    setSchema1({});
    setSchema2({});
    setObjData({});
    setSchema3({});
    setCheckedList({
      shareholders: false,
      ubo: false,
      nominee: false,
    });
  };
  const addNewRow = (values, checkStatus, name) => {
    updateChecked(name, checkStatus);
    let arr = [...individualFormSchema];

    if (checkStatus) {
      removeArrItem(values, arr);
    }

    if (name == "directors") {
      if (checkStatus) {
        arr.push(...values);
        setDirectorChecked(true);
        setReserveChecked(false);
      } else {
        removeArrItem(values, arr);
        setDirectorChecked(false);
      }
    }
    if (name == "reserve") {
      if (checkStatus) {
        arr.push(...values);
        setReserveChecked(true);
        setDirectorChecked(false);
      } else {
        removeArrItem(values, arr);
        setReserveChecked(false);
      }
    }
    setIndividualFormSchema(arr);
  };

  const addNewCustom = (values, name, checkStatus) => {
    let arr = [...individualFormSchema];

    updateChecked(name, checkStatus);

    let corporateData = { ...schema1 };

    if (name == "shareholders") {
      if (checkStatus) {
        arr.push(...values);
        setShareholderChecked(true);
        setNomineeChecked(false);
        removeArrItem(nomineeShareholder, arr);
      } else {
        setShareForm({
          show: false,
          type: "",
        });
        setFormData({
          ...corporateData,
          shareEntry: null,
          nomineeShareEntry: null,
        });
        removeArrItem(values, arr);
        setShareholderChecked(false);
      }
    }

    if (name == "nominee") {
      if (checkStatus) {
        arr.push(...values);
        setNomineeChecked(true);
        setShareholderChecked(false);
        removeArrItem(shareholderFormSchema, arr);
      } else {
        setShareForm({
          show: false,
          type: "",
        });
        setFormData({
          ...corporateData,
          shareEntry: null,
          nomineeShareEntry: null,
        });
        removeArrItem(values, arr);
        setNomineeChecked(false);
      }
    }
    setIndividualFormSchema(arr);
  };

  const handleRefresh = async () => {
    const params = {
      isAll: true,
      masterType: "cop-entity",
      //code: details.individualCode
    };
    const query = convertQueryParams(params);
    const payload = {
      method: "GET",
      url: `/client?${query}`,
    };
    const queryParam = convertQueryParams({ _start: 0, _limit: 1000 });
    let entityPayload = {
      method: "get",
      url: `/entity?masterType=entity&${queryParam}`,
    };
    try {
      const clientData = await fetchApi(payload).then(
        (res) => res?.data?.rows || []
      );
      const entityData = await fetchApi(entityPayload).then(
        (res) => res?.data?.rows || []
      );
      setEntityArray(entityData);
      setCopEntityData(clientData);
      setCopEntityArray(
        clientData.map((d) => ({ value: d?.code, label: d?.code }))
      );
      let name = clientData.map((d) => ({
        value: d?.name,
        label: d?.name,
      }));
      setCopEntityName(name);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleNameChange = (name) => {
    let val,
      val2,
      val3 = {};
    const data = copEntityData.find((cop) => cop.name === name);
    console.log(data, "dvzbnfgm");
    if (data) {
      val = {
        _id: data._id,
        code: data.code,
        corpInfoNo: data.corpInfoNo,
        name: data.name,
        type: data.type,
        incorporationDate: data.incorporationDate,
        incorporationNumber: data.incorporationNumber,
        incorporationCountry: data.incorporationCountry,
        busNature: data.busNature,
        busGeography: data.busGeography,
        busDesc: data.busDesc,
        relatedEntityCode: data.relatedEntityCode,
        createdAt: data.createdAt,
        dateAppointment: value.dateAppointment,
        dateResignation: value.dateResignation,
        totalSharesHeld: value.totalSharesHeld,
        ownershipPercentage: value.ownershipPercentage,
        sharesHeld: value.sharesHeld,
      };
      val2 = {
        residentialAddress: data?.residentialAddress,
        serviceAddress: data?.serviceAddress,
        line_1: data?.details?.address?.line_1,
        line_2: data?.details?.address?.line_2,
        line_3: data?.details?.address?.line_3,
        zipCode: data?.details?.address?.zipCode,
        country: data?.details?.address?.country,
      };
      val3 = {
        riskRating: data?.details?.riskRating,
        pep: data?.details?.pep,
        sanction: data?.details?.sanction,
      };
      setSchema1(val);
      setSchema2(val2);
      setSchema3(val3);
    }
  };

  const senderForm = [
    {
      name: "code",
      value: "",
      type: "text",
      label: "Master file Code",
      placeholder: "Enter master file code",
    },
    {
      name: "name",
      value: "",
      type: "text",
      label: "Master file Name",
      placeholder: "Enter master file Name",
    },
    {
      name: "shareCapital",
      value: "",
      type: "text",
      label: "Authorised Share Capital",
      placeholder: "Enter authorised share Capital",
    },
    {
      name: "numberOfShares",
      value: "",
      type: "number",
      label: "Number of shares transferred",
      placeholder: "Enter number of shares transferred",
    },
    {
      name: "from",
      value: "",
      type: "date",
      label: "From",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "to",
      value: "",
      type: "date",
      label: "To",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "shareType",
      type: "select",
      options: [
        {
          value: "Share type 1",
          label: "Share type 1",
        },
        {
          value: "Share type 2",
          label: "Share type 2",
        },
      ],
      label: "Share type",
      placeholder: "Select",
    },
    {
      name: "parValue",
      value: "",
      type: "number",
      label: "Par Value",
      placeholder: "Enter par value",
    },
    {
      name: "effectiveDate",
      value: "",
      type: "date",
      label: "Effective date",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "totalShares",
      value: "",
      type: "number",
      label: "Total Shares held",
      placeholder: "Enter total shares held",
    },
    {
      name: "percentage",
      value: "",
      type: "number",
      label: "Ownership percentage",
      placeholder: "Enter ownership percentage",
    },
    {
      name: "certificateNumber",
      value: "",
      type: "text",
      label: "Certificate Number",
      placeholder: "Enter certificate number",
    },
    {
      name: "dateOfEntry",
      value: "",
      type: "date",
      label: "Date of entry",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
  ];

  const receiverForm = [
    {
      name: "code",
      value: "",
      type: "text",
      label: "Master file Code",
      placeholder: "Enter master file code",
    },
    {
      name: "name",
      value: "",
      type: "text",
      label: "Master file Name",
      placeholder: "Enter master file Name",
    },
    {
      name: "shareCapital",
      value: "",
      type: "text",
      label: "Authorised Share Capital",
      placeholder: "Enter authorised share Capital",
    },
    {
      name: "numberOfShares",
      value: "",
      type: "number",
      label: "Number of shares received",
      placeholder: "Enter Number of shares received",
    },
    {
      name: "from",
      value: "",
      type: "date",
      label: "From",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "to",
      value: "",
      type: "date",
      label: "To",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "effectiveDate",
      value: "",
      type: "date",
      label: "Effective date",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "totalShares",
      value: "",
      type: "number",
      label: "Total Shares held",
      placeholder: "Enter total shares held",
    },
    {
      name: "percentage",
      value: "",
      type: "number",
      label: "Ownership percentage",
      placeholder: "Enter ownership percentage",
    },
    {
      name: "certificateNumber",
      value: "",
      type: "text",
      label: "Certificate Number",
      placeholder: "Enter certificate number",
    },
    {
      name: "dateOfEntry",
      value: "",
      type: "date",
      label: "Date of entry",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
  ];

  const allocationForm = [
    {
      name: "code",
      value: "",
      type: "text",
      label: "Master file Code",
      placeholder: "Enter master file code",
    },
    {
      name: "name",
      value: "",
      type: "text",
      label: "Master file Name",
      placeholder: "Enter master file Name",
    },
    {
      name: "shareCapital",
      value: "",
      type: "text",
      label: "Authorised Share Capital",
      placeholder: "Enter authorised share Capital",
    },
    {
      name: "numberOfShares",
      value: "",
      type: "number",
      label: "Number of shares allocated",
      placeholder: "Enter number of shares allocated",
    },
    {
      name: "from",
      value: "",
      type: "date",
      label: "From",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "to",
      value: "",
      type: "date",
      label: "To",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "shareType",
      type: "select",
      options: [
        {
          value: "Share type 1",
          label: "Share type 1",
        },
        {
          value: "Share type 2",
          label: "Share type 2",
        },
      ],
      label: "Share type",
      placeholder: "Select",
    },
    {
      name: "parValue",
      value: "",
      type: "number",
      label: "Par Value",
      placeholder: "Enter par value",
    },
    {
      name: "effectiveDate",
      value: "",
      type: "date",
      label: "Effective date",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "totalShares",
      value: "",
      type: "number",
      label: "Total Shares held",
      placeholder: "Enter total shares held",
    },
    {
      name: "percentage",
      value: "",
      type: "number",
      label: "Ownership percentage",
      placeholder: "Enter ownership percentage",
    },
    {
      name: "certificateNumber",
      value: "",
      type: "text",
      label: "Certificate Number",
      placeholder: "Enter certificate number",
    },
    {
      name: "dateOfEntry",
      value: "",
      type: "date",
      label: "Date of entry",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
  ];

  const copEntityOnChange = async (value, field) => {
    let val = {},
      val2 = {},
      val3 = {};

    let {
      totalSharesHeld,
      code,
      dateAppointment,
      dateResignation,
      ownershipPercentage,
      sharesHeld,
    } = value;

    let corporateData = { ...schema1 };
    let obj = { ...shareForm };

    if (field == "shareEntry" || field == "nomineeShareEntry") {
      setSchema1({
        ...corporateData,
        shareEntry: value[field],
        nomineeShareEntry: value[field],
      });
      obj["show"] = true;
      if (value[field] == "Share Transfer") {
        obj["type"] = "transfer";
        setShareForm(obj);
      }
      if (value[field] == "New Allocation") {
        obj["type"] = "new";
        setShareForm(obj);
      }
    }

    if (field === "code" && !totalSharesHeld) {
      let ctr = {};
      if (value.code) {
        if (value.code.startsWith("INV")) {
          ctr.code = value.code;
        } else {
          ctr.id = value.code;
        }
      } else {
        ctr = value;
      }

      let params = convertQueryParams(ctr);
      let payload = {
        method: "GET",
        url: `/cop-entity?${params}`,
      };

      let data = await fetchApi(payload).then((res) => res.data.rows[0]);
      if (data) {
        val = {
          _id: data._id,
          code: data.code,
          corpInfoNo: data.corpInfoNo,
          name: data.name,
          type: data.type,
          incorporationDate: data.incorporationDate,
          incorporationNumber: data.incorporationNumber,
          incorporationCountry: data.incorporationCountry,
          busNature: data.busNature,
          busGeography: data.busGeography,
          busDesc: data.busDesc,
          relatedEntityCode: data.relatedEntityCode,
          createdAt: data.createdAt,
          dateAppointment: dateAppointment,
          dateResignation: dateResignation,
          totalSharesHeld: totalSharesHeld,
          ownershipPercentage: ownershipPercentage,
          sharesHeld: sharesHeld,
        };

        val2 = {
          residentialAddress: data?.residentialAddress,
          serviceAddress: data?.serviceAddress,
          line_1: data?.details?.address?.line_1,
          line_2: data?.details?.address?.line_2,
          line_3: data?.details?.address?.line_3,
          zipCode: data?.details?.address?.zipCode,
          country: data?.details?.address?.country,
        };

        val3 = {
          riskRating: data.details.riskRating,
          pep: data.details.pep,
          sanction: data.details.sanction,
        };

        setSchema1(val);
        setSchema2(val2);
        setSchema3(val3);
      }
    }
    if (field === "name") {
      handleNameChange(value.name);
    }

    if (data?.totalSharesHeld !== totalSharesHeld || !data?.totalSharesHeld) {
      if (totalSharesHeld) {
        let percentage =
          (Number(totalSharesHeld) / Number(entityData?.authorisedShares)) *
          100;
        val.ownershipPercentage = `${percentage.toFixed(2)} %`;
        val._id = val?._id ?? "";
      }
    }
    setObjData(value);
  };

  const shareTableColumn = [
    {
      title: "Action type",
      dataIndex: "type",
      sorter: (a, b) => a.type - b.type,
    },
    {
      title: "Master file Code",
      dataIndex: "code",
      sorter: (a, b) => a.code - b.code,
    },
    {
      title: "Master file Name",
      dataIndex: "name",
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: "Authorised Share Capital",
      dataIndex: "shareCapital",
      sorter: (a, b) => a.shareCapital - b.shareCapital,
    },
    {
      title: "From",
      dataIndex: "from",
      sorter: (a, b) => a.from - b.from,
    },
    {
      title: "To",
      dataIndex: "to",
      sorter: (a, b) => a.to - b.to,
    },
    {
      title: "Par Value",
      dataIndex: "parValue",
      sorter: (a, b) => a.parValue - b.parValue,
    },
    {
      title: "Effective date",
      dataIndex: "effectiveDate",
      sorter: (a, b) => a.effectiveDate - b.effectiveDate,
      format: "DD/MM/YYYY",
    },
    {
      title: "Total Shares held",
      dataIndex: "totalShares",
      sorter: (a, b) => a.totalShares - b.totalShares,
    },
    {
      title: "Ownership percentage",
      dataIndex: "percentage",
      sorter: (a, b) => a.percentage - b.percentage,
    },
    {
      title: "Certificate Number",
      dataIndex: "certificateNumber",
      sorter: (a, b) => a.certificateNumber - b.certificateNumber,
    },
    {
      title: "Date of entry",
      dataIndex: "dateOfEntry",
      sorter: (a, b) => a.dateOfEntry - b.dateOfEntry,
    },
    {
      title: "Action",
      render: (_, { _id }, index) => (
        <button className="primary-btn">View</button>
      ),
    },
  ];

  const shareTableData = [
    {
      type: "Share Transfer",
      code: "INV000001",
      name: "Vaheeshan",
      shareCapital: "N/A",
      numberOfShares: 200,
      from: "20-10-2022",
      to: "20-10-2024",
      parValue: "N/A",
      effectiveDate: "20-10-2026",
      totalShares: 800,
      percentage: 20,
      certificateNumber: "FJC038932",
      dateOfEntry: "30-10-2024",
    },
    {
      type: "Share Transfer",
      code: "INV000001",
      name: "Vaheeshan",
      shareCapital: "N/A",
      numberOfShares: 200,
      from: "20-10-2022",
      to: "20-10-2024",
      parValue: "N/A",
      effectiveDate: "20-10-2026",
      totalShares: 800,
      percentage: 20,
      certificateNumber: "FJC038932",
      dateOfEntry: "30-10-2024",
    },
    {
      type: "New Allocation",
      code: "INV000001",
      name: "Vaheeshan",
      shareCapital: "N/A",
      numberOfShares: 200,
      from: "20-10-2022",
      to: "20-10-2024",
      parValue: "N/A",
      effectiveDate: "20-10-2026",
      totalShares: 800,
      percentage: 20,
      certificateNumber: "FJC038932",
      dateOfEntry: "30-10-2024",
    },
  ];

  const copEntityDropDownOnClick = async (value) => {
    let params = {};
    params.isAll = true;
    params.masterType = "cop-entity";
    params.code = value;
    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/client?${query}`,
    };
    let clientData = await fetchApi(payload).then((res) => {
      return res?.data;
    });
    setCopEntityData(clientData.rows);
    let list = clientData.rows.map((d) => {
      let obj = {
        value: d?._id,
        label: d?.code,
      };
      return obj;
    });
    let name = clientData.rows.map((d) => {
      let obj = {
        value: d?.name,
        label: d?.name,
      };
      return obj;
    });
    setCopEntityName(name);
    setCopEntityArray(list);
  };
  const saveOnClick = () => {
    saveData(selectedId);
  };

  const saveData = async () => {
    let options1 = formRef.current.getPayload();
    let options2 = adsFormRef.current.getPayload();
    let options3 = riskFormRef.current.getPayload();
    let cop = copEntityData.find((f) => f.code == options1.code);
    //  let cop = copEntityData.find(
    //    (f) => f.code == options1.code || f._id == options1.code
    //  );
    const prepareData = () => ({
      copId: cop._id,
      enId: entityData._id,
      resignation: options1.dateResignation,
      appointment: options1.dateAppointment,
      noOfShares: options1.sharesHeld || options1.nomineeSharesHeld,
      totalShares: options1.totalSharesHeld,
      ownershipPer: options1.ownershipPercentage,
      agreementDate: options1.nomineeAgreementDate,
      checkedList: checkedList,
    });

    const handleCheckedList = () => {
      const organizationArray = organizationStructure.data.rows.filter(
        (a) => a.pid == null
      );

      const fullName = schema1.name;

      const createObj = (
        role,
        shares,
        percentage = null,
        shareholders = null,
        nominee = null
      ) => {
        const roleData = organizationArray.find((i) => i.role === role);
        return roleData
          ? {
              name: fullName,
              pid: roleData._id,
              color: roleData.color,
              role: "Corporate Entity",
              pType: role,
              shares,
              percentage,
              directShareholder: shareholders,
              nomineeShareholder: nominee,
            }
          : null;
      };

      const objArray = [];
      if (checkedList.directors || checkedList.reserve) {
        objArray.push({
          ...createObj("Director"),
          reserveDirector: checkedList.reserve,
        });
      }
      if (checkedList.shareholders || checkedList.nominee) {
        const sharePercentage = `${Math.round(
          (Number(formData.sharesHeld) / Number(entityData?.authorisedShares)) *
            100
        )}%`;
        objArray.push(
          createObj(
            "Shareholder",
            formData.sharesHeld,
            sharePercentage,
            checkedList.shareholders,
            checkedList.nominee
          )
        );
      }
      if (checkedList.ubo) {
        objArray.push(
          createObj(
            "UBO",
            formData.totalSharesHeld,
            formData.ownershipPercentage
          )
        );
      }

      return objArray.filter(Boolean);
    };

    const handleApiPost = async (url, data) => {
      try {
        const response = await fetchApi({ method: "POST", url, data });
        return response;
      } catch (error) {
        sendNotify("error", error?.message);
        throw error;
      }
    };
    const handleApiPut = async (url, data) => {
      try {
        const response = await fetchApi({ method: "PUT", url, data });
        return response;
      } catch (error) {
        sendNotify("error", error?.message);
        throw error;
      }
    };
    const processRows = async (objArray) => {
      for (const obj of objArray) {
        await handleApiPost(`/organizationStructure`, obj);
      }
    };
    console.log(selectedId, "xrytyuio");

    const data = prepareData();
    if (Object.values(checkedList).some(Boolean)) {
      try {
        const response = selectedId
          ? await handleApiPut(`/copEntityDetails/update/${selectedId}`, data)
          : await handleApiPost(`/copEntityDetails`, data);

        sendNotify("success", response?.message);
        closeModalOnClick();
        if (organizationStructure?.data?.rows?.length) {
          const objArray = handleCheckedList();
          await processRows(objArray);
        } else {
          const params = { enId: id || query.id };
          dispatch(getCopEntityDetails(params));
          dispatch(getOrganizationStructure(params));
        }
      } catch (error) {
        sendNotify("error", error?.message);
      }

      const params = { enId: id || query.id };
      dispatch(getCopEntityDetails(params));
    } else {
      sendNotify("error", "Please enter at least one name");
    }
  };
  const individualEditModalOnClick = async (id, index) => {
    let val,
      val2,
      val3 = {};

    // setSelectedIndex(index + 1);
    setSelectedIndex(copEntityDetails.data.totalCount - index);
    setSelectedId(id);
    const details = copEntityDetails.data.rows.find((e) => e._id === id);
    if (!details) return;
    const { shareholders, ubo, nominee, directors, reserve } =
      details.checkedList;
    const params = {
      isAll: true,
      masterType: "cop-entity",
      id: details.copId,
    };
    const query = convertQueryParams(params);
    const payload = {
      method: "GET",
      url: `/client?${query}`,
    };
    try {
      const clientData = await fetchApi(payload).then(
        (res) => res?.data?.rows || []
      );

      setCopEntityData(clientData);
      setCopEntityArray(
        clientData.map((d) => ({ value: d?._id, label: d?.code }))
      );

      const finded = clientData.find((e) => e._id === details.copEntityId);

      if (finded) {
        val = {
          _id: finded._id,
          code: finded.code,
          corpInfoNo: finded.corpInfoNo,
          name: finded.name,
          type: finded.type,
          incorporationDate: finded.incorporationDate,
          incorporationNumber: finded.incorporationNumber,
          incorporationCountry: finded.incorporationCountry,
          busNature: finded.busNature,
          busGeography: finded.busGeography,
          busDesc: finded.busDesc,
          relatedEntityCode: finded.relatedEntityCode,
          createdAt: finded.createdAt,
          dateAppointment: details.appointment,
          dateResignation: details.resignation,
          totalSharesHeld: details.totalSharesHeld,
          ownershipPercentage: details.ownershipPercentage,
          sharesHeld: finded.sharesHeld,
        };

        val2 = {
          residentialAddress: finded.residentialAddress,
          serviceAddress: finded.serviceAddress,
          line_1: finded.details.address.line_1,
          line_2: finded.details.address.line_2,
          line_3: finded.details.address.line_3,
          zipCode: finded.details.address.zipCode,
          country: finded.details.address.country,
        };

        val3 = {
          riskRating: finded.details.riskRating,
          pep: finded.details.pep,
          sanction: finded.details.sanction,
        };
      }

      setSchema1(val);
      setSchema2(val2);
      setSchema3(val3);
      setObjData({ ...val, ...val2, ...val3 });
      setCheckedList(details.checkedList);
      setUboChecked(ubo);
      setDirectorChecked(directors);
      setReserveChecked(reserve);
      setShareholderChecked(shareholders);
      setNomineeChecked(nominee);
      setOpenCopEntity(true);
      // updateChecked("ubo", ubo);
      // updateChecked("shareholders", true);
      addNewChange("ubo", ubo);
      updateChecked("shareholders", shareholders);
      addNewRow(directorFormSchema, directors, "directors");
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const individualcolumns = [
    {
      title: "MasterFile Code",
      dataIndex: "copEntityCode",
      sorter: (a, b) => a.copEntityCode - b.copEntityCode,
    },
    {
      title: "MasterFile Name",
      dataIndex: "copEntityName",
      sorter: (a, b) => a.copEntityName - b.copEntityName,
    },
    {
      title: "Select Type",
      dataIndex: "shareType",
      sorter: (a, b) => a.shareType - b.shareType,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      format: "DD/MM/YYYY",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
    },
    {
      title: "Delete / Update",
      render: (_, { _id }, index) => (
        <div className="d-flex gap-2 align-items-center justify-content-end">
          <button
            className="action-btn delete-btn"
            onClick={() => corpDeleteOnClick(_id, index)}
          >
            <i className="far fa-trash-alt"></i>
          </button>
          <button
            className="action-btn"
            onClick={() => individualEditModalOnClick(_id, index)}
          >
            <i className="far fa-pencil"></i>
          </button>
        </div>
      ),
    },
  ];
  const corpEntityModalOnClick = () => {
    setSelectedId("");
    setUboChecked(false);
    setDirectorChecked(false);
    setReserveChecked(false);
    setShareholderChecked(false);
    setNomineeChecked(false);
    setOpenCopEntity(true);
  };
  const closeModalOnClick = () => {
    setSelectedId("");
    setObjData({});
    setUboChecked(false);
    setDirectorChecked(false);
    setReserveChecked(false);
    setShareholderChecked(false);
    setNomineeChecked(false);
    setOpenCopEntity(false);
    setFormData({});
    setSchema3({});
    setSchema2({});
    setSchema1({});
    setCheckedList({
      directors: false,
      shareholders: false,
      ubo: false,
      nominee: false,
      reserve: false,
    });
  };

  const getPagination = (current, pageSize) => {
    let params = { ...queryParams };
    params._start = (current - 1) * pageSize;
    params.enId = id ? id : query.id;
    dispatch(getCopEntityDetails(params));
    setQueryParams(params);
  };
  return (
    <div>
      <div className="d-flex py-4 gap-3 align-items-center">
        <Switch
          className="custom-switch"
          onChange={(value) => setCorporateEntityShow(value)}
        />
        <h4 className="primary-title">Corporate Entity</h4>
        {corporateEntityShow && (
          <button
            className="primary-btn ms-auto mb-3"
            onClick={corpEntityModalOnClick}
          >
            <i className="fal fa-plus-circle"></i>Add New
          </button>
        )}
      </div>
      {corporateEntityShow && (
        <Table
          className="custom-table-light mt-md-1 mb-3"
          columns={individualcolumns}
          dataSource={copEntityDetails?.data?.rows}
          //  pagination={false}
          scroll={{ x: 1200 }}
          pagination={{
            className: "custom-pagination",
            defaultCurrent: 1,
            pageSize: queryParams?._limit,
            total: copEntityDetails?.data?.totalCount,
            onChange: getPagination,
          }}
        />
      )}

      <Modal
        title={
          selectedId ? "Edit Corporate Entity " : "Add New Corporate Entity"
        }
        okText={selectedId ? "Update" : "Save"}
        className="custom-modal"
        open={openCopEntity}
        centered={true}
        width={1100}
        onOk={saveOnClick}
        onCancel={closeModalOnClick}
        // footer={false}
      >
        {corporateEntityShow && (
          <div className="mb-md-4">
            {forms.corporateEntity.map((a, i) => {
              return (
                <div key={i} id={`corporateEntity${i}`} className="mb-4">
                  <div className="d-flex gap-3 mb-3 align-items-center">
                    <h3 className="sub-title">
                      Corporate Entity 0{i + 1} &nbsp;
                      <i className="fal fa-question-circle"></i>
                    </h3>
                    {i !== 0 && (
                      <button
                        className="outline-btn red-btn"
                        onClick={() => removeNewCol("corporateEntity", i)}
                      >
                        <i className="fal fa-trash-alt"></i> Remove
                      </button>
                    )}
                    <div className="d-flex gap-3 align-items-center">
                      <Checkbox
                        className="custom-checkbox"
                        checked={directorChecked && !reserveChecked}
                        onChange={(e) =>
                          addNewRow(
                            directorFormSchema,
                            e.target.checked,
                            "directors"
                          )
                        }
                        disabled={JSON.stringify(objData) == "{}"}
                      >
                        Directors
                      </Checkbox>
                      <Checkbox
                        className="custom-checkbox"
                        checked={shareholderChecked && !nomineeChecked}
                        onChange={(e) =>
                          addNewCustom(
                            shareholderFormSchema,
                            "shareholders",
                            e.target.checked
                          )
                        }
                        disabled={JSON.stringify(objData) == "{}"}
                      >
                        Shareholders
                      </Checkbox>
                      <Checkbox
                        className="custom-checkbox"
                        onChange={(e) =>
                          addNewChange(uboFormSchema, e.target.checked)
                        }
                        disabled={JSON.stringify(objData) == "{}"}
                      >
                        UBOs
                      </Checkbox>
                      <Checkbox
                        className="custom-checkbox"
                        checked={nomineeChecked && !shareholderChecked}
                        onChange={(e) =>
                          addNewCustom(
                            nomineeShareholder,
                            "nominee",
                            e.target.checked
                          )
                        }
                        disabled={JSON.stringify(objData) == "{}"}
                      >
                        Nominee Shareholder
                      </Checkbox>
                      <Checkbox
                        className="custom-checkbox"
                        checked={reserveChecked && !directorChecked}
                        onChange={(e) =>
                          addNewRow(
                            directorFormSchema,
                            e.target.checked,
                            "reserve"
                          )
                        }
                        disabled={JSON.stringify(objData) == "{}"}
                      >
                        Reserve Director
                      </Checkbox>
                    </div>
                    <button className="primary-btn" onClick={clearForm}>
                      Clear Form
                    </button>
                  </div>

                  <FormZ
                    formSchema={individualFormSchema}
                    ref={formRef}
                    formData={schema1}
                    formOptions={{ code: copEntityArray, name: copEntityName }}
                    formClass="row custom-form mb-3 mb-md-4 gy-3 gx-4"
                    childClass="col-md-3 mb-0"
                    labelClass="mb-1"
                    inputClass="w-100"
                    onChange={copEntityOnChange}
                    onKeyUp={(e) => copEntityDropDownOnClick(e.target.value)}
                  />
                  <FormZ
                    formSchema={adsFormSchema}
                    formData={schema2}
                    ref={adsFormRef}
                    formClass="custom-form row"
                    childClass="form-row mb-4"
                    labelClass="me-3 mb-0"
                    inputClass="w-100"
                  />
                  <FormZ
                    formSchema={riskFormSchema}
                    formData={schema3}
                    ref={riskFormRef}
                    formClass="custom-form row gy-3 align-items-end"
                    childClass="col-md-3 mb-0"
                    labelClass="mb-1"
                    inputClass="w-100"
                  />
                  <button className="primary-btn mt-4" onClick={saveData}>
                    Update
                  </button>
                </div>
              );
            })}
            {shareForm["show"] && (
              <div>
                {shareForm["type"] == "transfer" ? (
                  <div>
                    <div className="primary-title mb-2">
                      Transferor (Sender)
                    </div>
                    <FormZ
                      ref={senderFormRef}
                      formSchema={senderForm}
                      // formData={formData}
                      formClass="row custom-form mb-3 gy-3 gx-4"
                      childClass="col-md-3 mb-0"
                      labelClass="mb-1"
                      inputClass="w-100"
                    />
                    <div className="primary-title mb-2">
                      Transferee (Receiver)
                    </div>
                    <FormZ
                      ref={receiverFormRef}
                      formSchema={receiverForm}
                      // formData={formData}
                      formClass="row custom-form mb-3 gy-3 gx-4"
                      childClass="col-md-3 mb-0"
                      labelClass="mb-1"
                      inputClass="w-100"
                    />
                  </div>
                ) : (
                  <div>
                    <div className="primary-title mb-2">New Allocation</div>
                    <FormZ
                      ref={allocationFormRef}
                      formSchema={allocationForm}
                      // formData={formData}
                      formClass="row custom-form mb-3 gy-3 gx-4"
                      childClass="col-md-3 mb-0"
                      labelClass="mb-1"
                      inputClass="w-100"
                    />
                  </div>
                )}
                <div className="primary-title mb-2">Detailed History</div>
                <Table
                  className="custom-table-light mt-3 mb-3"
                  columns={shareTableColumn}
                  dataSource={shareTableData}
                  scroll={{ x: 2000 }}
                  pagination={{
                    className: "custom-pagination",
                  }}
                />
              </div>
            )}
            {Object.keys(checkedList).some((k) => checkedList[k]) && (
              <div className="d-flex justify-content-between align-items-center mt-3">
                <h6
                  className="hyper-link mb-0"
                  onClick={() => setHistoryShow(!historyShow)}
                >
                  Detailed History{" "}
                  <i
                    className={`fas fa-chevron-down ${
                      historyShow ? "fa-rotate-180" : ""
                    }`}
                  ></i>
                </h6>
                {historyShow ? (
                  <button className="primary-btn ms-auto">Export</button>
                ) : (
                  <span className="py-2">&nbsp;</span>
                )}
              </div>
            )}
            {historyShow ? (
              <Table
                className="custom-table-light mt-md-1 mb-3"
                columns={columns}
                dataSource={tableData}
                pagination={false}
                scroll={{ x: 1800 }}
              />
            ) : (
              <></>
            )}
          </div>
        )}
      </Modal>
      {/* <div className="d-flex py-4 gap-3 align-items-center">
        <Switch
          className="custom-switch"
          onChange={(value) => setCorporateEntityShow(value)}
        />
        <h4 className="primary-title">Corporate Entity</h4>
        {corporateEntityShow && (
          <button
            className="primary-btn"
            onClick={() => addNewCol("corporateEntity", { focus: true })}
          >
            <i className="fal fa-plus-circle"></i> Add New
          </button>
        )}
      </div> */}
      {/* 
      {corporateEntityShow && (
        <div className="mb-md-4">
          {forms.corporateEntity.map((a, i) => {
            return (
              <div key={i} id={`corporateEntity${i}`} className="mb-4">
                <div className="d-flex gap-3 mb-3 align-items-center">
                  <h3 className="sub-title">
                    Corporate Entity 0{i + 1} &nbsp;
                    <i className="fal fa-question-circle"></i>
                  </h3>
                  {i !== 0 && (
                    <button
                      className="outline-btn red-btn"
                      onClick={() => removeNewCol("corporateEntity", i)}
                    >
                      <i className="fal fa-trash-alt"></i> Remove
                    </button>
                  )}
                  <div className="d-flex gap-3 align-items-center">
                    <Checkbox
                      className="custom-checkbox"
                      checked={directorChecked && !reserveChecked}
                      onChange={(e) =>
                        addNewRow(
                          directorFormSchema,
                          e.target.checked,
                          "directors"
                        )
                      }
                    >
                      Directors
                    </Checkbox>
                    <Checkbox
                      className="custom-checkbox"
                      checked={shareholderChecked && !nomineeChecked}
                      onChange={(e) =>
                        addNewCustom(
                          shareholderFormSchema,
                          "shareholders",
                          e.target.checked
                        )
                      }
                    >
                      Shareholders
                    </Checkbox>
                    <Checkbox
                      className="custom-checkbox"
                      onChange={(e) =>
                        addNewChange(uboFormSchema, e.target.checked)
                      }
                    >
                      UBOs
                    </Checkbox>
                    <Checkbox
                      className="custom-checkbox"
                      checked={nomineeChecked && !shareholderChecked}
                      onChange={(e) =>
                        addNewCustom(
                          nomineeShareholder,
                          "nominee",
                          e.target.checked
                        )
                      }
                    >
                      Nominee Shareholder
                    </Checkbox>
                    <Checkbox
                      className="custom-checkbox"
                      checked={reserveChecked && !directorChecked}
                      onChange={(e) =>
                        addNewRow(
                          directorFormSchema,
                          e.target.checked,
                          "reserve"
                        )
                      }
                    >
                      Reserve Director
                    </Checkbox>
                  </div>
                </div>
                <FormZ
                  formSchema={individualFormSchema}
                  ref={formRef}
                  formData={schema1}
                  formOptions={{ code: copEntityArray }}
                  formClass="row custom-form col-md-9 mb-3 mb-md-4 gy-3 gx-4"
                  childClass="col-md-4 mb-0"
                  labelClass="mb-1"
                  inputClass="w-100"
                  onChange={copEntityOnChange}
                  onKeyUp={(e) => copEntityDropDownOnClick(e.target.value)}

                />
                <FormZ
                  formSchema={adsFormSchema}
                  formData={schema2}
                  ref={adsFormRef}
                  formClass="custom-form row"
                  childClass="form-row mb-4"
                  labelClass="me-3 mb-0"
                  inputClass="w-100"
                />
                <FormZ
                  formSchema={riskFormSchema}
                  formData={schema3}
                  ref={riskFormRef}
                  formClass="custom-form row gy-3 align-items-end"
                  childClass="col-md-3 mb-0"
                  labelClass="mb-1"
                  inputClass="w-100"
                />
                <button className="primary-btn mt-4" onClick={saveData}>Update</button>
              </div>
            );
          })}
          {Object.keys(checkedList).some((k) => checkedList[k]) && (
            <div className="d-flex justify-content-between align-items-center mt-3">
              <h6
                className="hyper-link mb-0"
                onClick={() => setHistoryShow(!historyShow)}
              >
                Detailed History{" "}
                <i
                  className={`fas fa-chevron-down ${historyShow ? "fa-rotate-180" : ""
                    }`}
                ></i>
              </h6>
              {historyShow ? (
                <button className="primary-btn ms-auto">Export</button>
              ) : (
                <span className="py-2">&nbsp;</span>
              )}
            </div>
          )}
          {historyShow ? (
            <Table
              className="custom-table-light mt-md-1 mb-3"
              columns={columns}
              dataSource={tableData}
              pagination={false}
              scroll={{ x: 1800 }}
            />
          ) : (
            <></>
          )}
        </div>
      )} */}
    </div>
  );
}
