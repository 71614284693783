import React, { useState } from "react";
import { Checkbox, Modal } from "antd";
import { Link } from "react-router-dom";
import JSZip from "jszip";
import PDFIcon from "../assets/img/pdf-icon.svg";

import SampleDoc from "../assets/pdf/sample.pdf";
import AMLDoc from "../assets/pdf/aml-verification-guidelines.pdf";
import IncorporationDoc from "../assets/pdf/company-incorporation-application-form.pdf";
import DirectorsConsentDoc from "../assets/pdf/directors-consent-letter.pdf";
import TermsDoc from "../assets/pdf/terms-of-business-agreement.pdf";
import TransferDoc from "../assets/pdf/transfer-in-form-for-bvibc.pdf";
import UBODoc from "../assets/pdf/ubo-source-of-funds-declaration.pdf";
import WrittenConfirmationsDoc from "../assets/pdf/written-confirmations-virtual-assets.pdf";

import { Each, downloadFile, formatBytes } from "../helper";
import UploadZ from "../components/UploadZ";

export default function PreIncorpResolution() {
  const [docModal, setDocModal] = useState(false);
  const [viewDoc, setViewDoc] = useState(null);
  const [viewDocName, setViewDocName] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCatIds, setSelectedCatIds] = useState([]);
  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const viewDocument = (doc, docName) => {
    setViewDoc(doc);
    setViewDocName(docName);
    setDocModal(true);
  };

  const handleOk = () => {
    setDocModal(false);
    setViewDocName("");
  };
  const handleCancel = () => {
    setDocModal(false);
    setViewDocName("");
  };

  const onSelectAll = (e) => {
    let array = [];
    if (e.target.checked) {
      array = documents.map((h) => h.id);
    }
    setSelectedCatIds(array);
    setSelectAll(e.target.checked);
  };

  const onSelectOne = (id) => {
    let array = [...selectedCatIds];
    let array1 = documents;
    let index = array.indexOf(id);
    if (index >= 0) {
      array.splice(index, 1);
    } else {
      array.push(id);
    }
    if (array.length === array1.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    setSelectedCatIds(array);
  };

  const DownloadDoc = async () => {
    let selectedIdsArr = [...selectedCatIds];
    const selectedDocuments = documents.filter((document) =>
      selectedIdsArr.includes(document.id)
    );
    const folderName = "Pre-Incorp Supported Documents";
    const zipContent = [];

    const fetchDataFromDataURL = async (dataURL) => {
      const response = await fetch(dataURL);
      return await response.blob();
    };

    for (const document of selectedDocuments) {
      const fileContentBlob = await fetchDataFromDataURL(document.url);
      const fileContentArrayBuffer = await fileContentBlob.arrayBuffer();
      const fileContentBase64 = btoa(
        String.fromCharCode(...new Uint8Array(fileContentArrayBuffer))
      );
      const fileName = `${folderName}/${document.name}.${document.type}`;
      zipContent.push({ fileName, fileContentBase64 });
    }

    const zip = new JSZip();
    zipContent.forEach(({ fileName, fileContentBase64 }) => {
      zip.file(fileName, fileContentBase64, { base64: true });
    });

    zip.generateAsync({ type: "blob" }).then((content) => {
      const zipUrl = URL.createObjectURL(content);
      const zipLink = document.createElement("a");
      zipLink.href = zipUrl;
      zipLink.download = `${folderName}.zip`;
      zipLink.click();
    });
  };

  const documents = [
    {
      id: 1,
      url: SampleDoc,
      name: "Inaugural Resolution",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      id: 2,
      url: SampleDoc,
      name: "Appointment of First Director",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      id: 3,
      url: SampleDoc,
      name: "Resolution of Share Allotment",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      id: 4,
      url: SampleDoc,
      name: "Corporate Seal Draft",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      id: 5,
      url: SampleDoc,
      name: "Directors Consent Letter",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      id: 6,
      url: SampleDoc,
      name: "Customized M&AA",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      id: 7,
      url: SampleDoc,
      name: "UBO Source of Funds Declaration",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      id: 8,
      url: SampleDoc,
      name: "Incorporation Application Form",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      id: 9,
      url: SampleDoc,
      name: "AML Verification Guidelines",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      id: 10,
      url: SampleDoc,
      name: "Transfer-In Form for BVIBC",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      id: 11,
      url: SampleDoc,
      name: "Written Confirmations-Virtual Assets",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      id: 12,
      url: SampleDoc,
      name: "Terms Of Business Agreement",
      size: 293333,
      type: "pdf",
      base64: "",
    },
  ];

  return (
    <div>
      <div className="page-header">
        <h1>Pre-Incorp Supported Documents</h1>
        <Link to={"/app/relationship-manager"} className="primary-btn">
          Export
        </Link>
      </div>
      <div className="page-content">
        <p className="head-label mb-4">Select Documents to Export :</p>
        <div className="d-flex gap-3 align-items-start align-items-md-center flex-column flex-md-row mb-4">
          <Checkbox onChange={onSelectAll} className="custom-checkbox">
            Select All
          </Checkbox>
          <button className="outline-btn" onClick={DownloadDoc}>
            <i className="far fa-arrow-to-bottom"></i> Download Selected
          </button>
        </div>
        <div className="row mb-4 gap-ms-2">
          <Each
            array={documents}
            render={(item) => (
              <div className="col-md-3">
                <Checkbox
                  // onChange={onChange}
                  // checked={selectAll}
                  onClick={() => onSelectOne(item.id)}
                  checked={selectedCatIds.indexOf(item.id) >= 0}
                  className="custom-checkbox mb-2"
                >
                  {item.name}
                </Checkbox>
                <UploadZ file={item} downloadOnly={true} />
              </div>
            )}
          />
        </div>
        <Modal
          title={viewDocName}
          className="custom-modal"
          open={docModal}
          width={1000}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="document-view">
            <iframe
              src={viewDoc}
              frameBorder="0"
              height="100%"
              width="100%"
            ></iframe>
          </div>
        </Modal>
      </div>
    </div>
  );
}
