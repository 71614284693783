import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchApi, convertQueryParams } from "../../helper";
let local = typeof window !== "undefined" ? localStorage : null;
export const getEmail = createAsyncThunk("getEmail", async (params) => {
  let query = convertQueryParams(params);
  let payload = {
    method: "GET",
    url: `/email/emails?${query}`,
    headers: {
      authorizationout: local.accessToken,
    },
  };

  let res = await fetchApi(payload).then((res) => {
    return res.data;
  });
  return res;
});

const incorpSlice = createSlice({
  name: "email",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getEmail.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getEmail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });

    builder.addCase(getEmail.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export default incorpSlice.reducer;
