import React, { useState, useRef, useEffect } from "react";
import EmojiPicker from "emoji-picker-react";
import { Table, Tag, Select, Modal, Checkbox } from "antd";
import moment from "moment";
import {
  getRandomColor,
  convertQueryParams,
  fetchApi,
  sendNotify,
} from "../helper";

const ChatBox = ({
  allContacts,
  users,
  groups,
  activeChat,
  setChatActive,
  addNewMessage,
}) => {
  let logoColor = getRandomColor();
  const [messageInput, setMessageInput] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [attachedFile, setAttachedFile] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const fileInputRef = useRef(null);

  const handleSendMessage = () => {
    // if (messageInput.trim() || attachedFile) {
    //   const messageData = {
    //     message: messageInput,
    //     file: attachedFile,
    //     type: "sent",
    //     chatId: activeChat?._id,
    //     senderImg: "path/to/sender/image.jpg",
    //   };

    //   // socket.emit("send_message", messageData);

    //   addNewMessage(activeChat?._id, messageData);

    //   setMessageInput("");
    //   setAttachedFile(null);
    // }
    let payload = {
      message: messageInput,
      chatTo: "660e4fbe699e6ece9bebeeb7",
      fromType: "gdsfg",
      toType: "gsd",
    };
  };

  const handleEmojiClick = (emojiObject) => {
    setMessageInput((prevInput) => prevInput + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAttachedFile(file);
    }
  };

  const handleAttachmentClick = () => {
    fileInputRef.current.click();
  };
  const chatOnClick = (user) => {
    setSelectedId(user._id);
    setChatActive(user);
  };
  let arr = [];
  if (activeChat?.messages?.length) {
    arr = converter(activeChat.messages);
  }
  function converter(data) {
    const grouped = data.reduce((acc, e, i) => {
      const messageDay = moment(e.createdAt).format("DD - MM - YYYY");
      if (acc[messageDay])
        return { ...acc, [messageDay]: acc[messageDay].concat([e]) };
      return { ...acc, [messageDay]: [e] };
    }, {});
    const sortedDays = Object.keys(grouped).sort(
      (x, y) => moment(x, "DD-MM-YYYY").unix() - moment(y, "DD-MM-YYYY").unix()
    );
    const items = sortedDays.reduce((acc, date) => {
      const sortedMessages = grouped[date].sort(
        (x, y) => new Date(x.createdAt) - new Date(y.createdAt)
      );
      sortedMessages.unshift({ type: "day-title", date });
      return acc.concat(sortedMessages);
    }, []);
    return items;
  }
  console.log(arr, "wat4ydtyfiohp");
  const userOnChange = (value) => {
    console.log(value, "value");
  };
  return (
    <div className="chatbox-container d-flex">
      <div className="sidepanel bg-light p-3">
        <h6 className="text-muted">User</h6>
        <Select
          className="custom-select w-100 ptb-1"
          placeholder="Select User"
          onChange={userOnChange}
          options={[
            {
              value: "bvibc",
              label: "BVIBC",
            },
            {
              value: "trust",
              label: "Trust",
            },
            {
              value: "generalPartnership",
              label: "General Partnership",
            },
            {
              value: "limitedPartnership",
              label: "Limited Partnership",
            },
            {
              value: "foundation",
              label: "Foundation",
            },
            {
              value: "",
              label: "Blank",
            },
          ]}
        />

        <h6 className="text-muted mt-2">Contacts</h6>
        <ul className="list-group contacts-list">
          {users &&
            users?.map((user) => (
              <li
                key={user?._id}
                className={`list-group-item d-flex align-items-center contact ${
                  activeChat?._id === user?._id ? "active" : ""
                }`}
                onClick={() => chatOnClick(user)}
              >
                {user?.img ? (
                  <img
                    className="contact-img rounded-circle me-2"
                    src={`data:image/png;base64, ${user.img}`}
                    alt={user.userName}
                  />
                ) : (
                  <span className="contact-img rounded-circle me-2">
                    <i class="fas fa-user-alt"></i>
                  </span>
                )}
                <div>
                  <p className="mb-0 fw-bold">{user.userName}</p>
                  <p className="mb-0 text-muted">{user.status}</p>
                </div>
              </li>
            ))}
        </ul>
        <h6 className="text-muted ">Groups</h6>
        <div className="d-flex justify-content-end  gap-3 align-items-center mt-2 mb-2">
          <button className="outline-btn me-2 " onClick={() => alert("")}>
            New Groups
          </button>
        </div>
        <ul className="list-group contacts-list">
          {groups &&
            groups?.map((group) => (
              <li
                key={group.id}
                className={`list-group-item d-flex align-items-center group ${
                  activeChat?._id === group?._id ? "active" : ""
                }`}
                onClick={() => setChatActive(group)}
              >
                <img
                  className="contact-img rounded-circle me-2"
                  src={group.img}
                  alt={group.name}
                />
                <div>
                  <p className="mb-0 fw-bold">{group.name}</p>
                  <p className="mb-0 text-muted">{group.members.join(", ")}</p>
                </div>
              </li>
            ))}
        </ul>
      </div>

      {/* Chat content area */}
      <div className="chat-content flex-fill d-flex flex-column">
        <div className="messages flex-fill p-3 overflow-auto">
          <ul className="message-list list-unstyled">
            {activeChat &&
              activeChat?.messages?.length &&
              activeChat?.messages?.map((msg, index) => (
                <li
                  key={index}
                  className={`message ${
                    msg.type === "sent" ? "sent-bubble" : "received-bubble"
                  } mb-2 d-flex ${
                    msg.type === "sent"
                      ? "justify-content-end"
                      : "justify-content-start"
                  }`}
                >
                  {msg.type === "received" && (
                    <img
                      className="message-img rounded-circle me-2"
                      src={msg.senderImg}
                      alt="sender"
                    />
                  )}
                  <div className="message-bubble">
                    <p className="message-text mb-1">{msg.message}</p>
                    {msg.file && (
                      <a
                        href={URL.createObjectURL(msg.file)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        📎 {msg.file.name}
                      </a>
                    )}
                    <span className="text-muted small">
                      {"20-02-2024   ✔"}
                      {/* {msg.status === "sent" && "✔"}
                    {msg.status === "delivered" && "✔✔"}
                    {msg.status === "read" && "✔✔"} */}
                    </span>
                  </div>

                  {msg.type === "sent" && (
                    <img
                      className="message-img rounded-circle ms-2"
                      src={msg.senderImg}
                      alt="sender"
                    />
                  )}
                </li>
              ))}
          </ul>
        </div>

        <div className="message-input d-flex align-items-center p-2 border-top">
          <button
            className="btn btn-light me-2"
            onClick={handleAttachmentClick}
          >
            📎
          </button>
          <input
            type="file"
            className="d-none"
            ref={fileInputRef}
            onChange={handleFileChange}
          />
          <button
            className="btn btn-light"
            onClick={() => setShowEmojiPicker((prev) => !prev)}
          >
            😊
          </button>
          <input
            type="text"
            className="form-control me-2"
            placeholder="Type a message..."
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
          />

          <button className="btn btn-primary" onClick={handleSendMessage}>
            Send
          </button>
        </div>

        {showEmojiPicker && (
          <div className="emoji-picker position-absolute bottom-0 start-0">
            <EmojiPicker onEmojiClick={handleEmojiClick} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatBox;
