import React, { useState } from "react";
import { Table, Modal } from "antd";
import { Link } from "react-router-dom";

import PDFIcon from "../assets/img/pdf-icon.svg";

import SampleDoc from "../assets/pdf/sample.pdf";

import { downloadFile } from "../helper";

import TableSortArrows from "../components/tableSortArrows";
import TagZ from "../components/TagZ";

export default function IncorporationPack() {
  const [docModal, setDocModal] = useState(false);
  const [viewDocName, setViewDocName] = useState("");

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const viewDocument = (docName) => {
    setViewDocName(docName);
    setDocModal(true);
  };

  const handleOk = () => {
    setDocModal(false);
    setViewDocName("");
  };
  const handleCancel = () => {
    setDocModal(false);
    setViewDocName("");
  };

  const DownloadDoc = () => {
    downloadFile(SampleDoc, "sample_doc.pdf");
  };

  const columns = [
    {
      title: "Entity Name",
      dataIndex: "entityName",
      sorter: (a, b) => a.entityName - b.entityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Type",
      dataIndex: "entityType",
      sorter: (a, b) => a.entityType - b.entityType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      sorter: (a, b) => a.entityCode - b.entityCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Client Code",
      dataIndex: "clientCode",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Client Type",
      dataIndex: "clientType",
      sorter: (a, b) => a.clientType - b.clientType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Risk Level",
      dataIndex: "entityRiskLevel",
      sorter: (a, b) => a.entityRiskLevel - b.entityRiskLevel,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Date",
      dataIndex: "incorpDate",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Number",
      dataIndex: "incorpNumber",
      sorter: (a, b) => a.incorpNumber - b.incorpNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // {
    //     title: 'Risk Status',
    //     dataIndex: 'riskStatus',
    //     sorter: (a, b) => a.riskStatus - b.riskStatus,
    // sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    // },
    {
      title: "Master File Status",
      dataIndex: "masterFileStatus",
      sorter: (a, b) => a.masterFileStatus - b.masterFileStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { masterFileStatus }) => (
        <TagZ
          status={masterFileStatus ? "approved" : "in-progress"}
          statusText={masterFileStatus ? "Active" : "Pending"}
        />
      ),
    },
    {
      title: "RM Name",
      dataIndex: "rmName",
      sorter: (a, b) => a.rmName - b.rmName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Final Incorp pack",
      dataIndex: "finalPack",
      fixed: "right",
      // width: 220,
      render: (_) => (
        <div className="d-flex gap-2 align-items-center">
          <button className="action-btn icon-btn" title="Upload">
            <i className="far fa-file-upload"></i>
          </button>
          <button className="action-btn icon-btn" title="View">
            <i className="far fa-eye"></i>
          </button>
        </div>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      id: "1",
      entityName: "Sample",
      entityType: "Trust",
      entityCode: "9023",
      clientName: "COI",
      clientCode: "163",
      clientType: "Introducer Client",
      entityRiskLevel: "Low",
      incorpDate: "12/05/23",
      incorpNumber: "3725",
      riskStatus: "Low",
      masterFileStatus: "Active",
      rmName: "Sample",
      rmCode: "7234",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div>
      <div className="page-header">
        <h1>Incorporation Pack</h1>
        <Link to={"/app/additional-screens"} className="primary-btn">
          Export Incorporation Pack
        </Link>
      </div>
      <div className="page-content">
        {/* <h6 className="primary-title mb-4">Entity Details</h6> */}
        <Table
          className="custom-table"
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: 2300 }}
        />
        {/* <h6 className='primary-title my-4'>Final Pack from Registry</h6>
            <div className="custom-form">
                <div className="row mb-4">
                    <div className="col-md-3">
                        <label htmlFor="">Final Pack</label>
                        <div className="uploader">
                            <div className="uploaded-file">
                                <img className='file-icon' src={PDFIcon} alt="" />
                                <div className='ms-2'>
                                    <h6>sample_document.pdf</h6>
                                    <p>200 KB</p>
                                </div>
                            </div>
                            <div className="row g-2">
                                <div className="col-8">
                                    <button className='view-btn w-100' onClick={() => viewDocument('Inaugural Resolution')}><i className="far fa-eye"></i> View</button>
                                </div>
                                <div className="col-2">
                                    <button className='download-btn w-100' onClick={DownloadDoc}><i className="far fa-arrow-to-bottom"></i></button>
                                </div>
                                <div className="col-2">
                                    <button className='delete-btn w-100'><i className="far fa-trash-alt"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <label htmlFor="">Tag to User Email</label>
                        <input type="text" className='w-100' placeholder='Enter Email' />
                    </div>
                </div>
            </div> */}
        <Modal
          title={viewDocName}
          className="custom-modal"
          open={docModal}
          width={1000}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="document-view">
            <iframe
              src={SampleDoc}
              frameBorder="0"
              height="100%"
              width="100%"
            ></iframe>
          </div>
        </Modal>
      </div>
    </div>
  );
}
