import React, { useState, useRef } from "react";
import { Select, Checkbox } from "antd";
import UploadZ from "../../../components/UploadZ";
import FormZ from "../../../components/FormZ";
import { fetchApi, Each, focusOn, convertQueryParams } from "../../../helper";

const Review = ({ setIndividualKYC }) => {
  const directorFormRef = useRef([]);
  const [formData, setFormData] = useState({});
  const [checkValue, setCheckValue] = useState([]);
  const [forms, setForms] = useState({
    individuals: [1],
    directors: [1],
    shareholders: [1],
    ubo: [1],
  });
  const [documents, setDocuments] = useState([]);
  const [individualCustom, setIndividualCustom] = useState([]);
  const [individualArray, setIndividualArray] = useState([]);
  const [fileArray, setFileArray] = useState([]);
  const [individualDocs, setIndividualDocs] = useState([
    "Passport / ID",
    "Address Proof",
  ]);

  const directorDocs = [
    "Consent to Act",
    "Resolution of Appointment",
    "Resolution of Resignation",
    "Letter of Resignation",
  ];
  const shareholderDocs = [
    "Resolution of Share Allotment",
    "Resolution of Share Transfer",
  ];
  const uboDocs = ["Resolution of Shareholding", "Source of Wealth/Funds"];
  const nomineeDocs = ["Nominee Agreement"];

  const [sanction, setSanction] = useState("Y");
  const [pep, setPep] = useState("Y");

  const [pepText, setPepText] = useState("");
  const [sanctionText, setSanctionText] = useState("");

  const handleChangePep = (value) => {
    setPep(value);
  };
  const handleChange = (value) => {
    setSanction(value);
  };

  let formSchema = [
    {
      name: "masterId",
      value: "",
      type: "select",
      label: "Masterfile Code",
      placeholder: "Enter masterfile code",
      customClass: "col-md-2",
      showSearch: true,
    },
    {
      name: "title",
      value: "",
      type: "select",
      label: "Salutation",
      placeholder: "Select salutation",
      options: [
        {
          value: "mr",
          label: "Mr",
        },
        {
          value: "mrs",
          label: "Mrs",
        },
        {
          value: "miss",
          label: "Miss",
        },
        {
          value: "ms",
          label: "Ms",
        },
        {
          value: "dr",
          label: "Dr",
        },
        {
          value: "rev",
          label: "Rev",
        },
      ],
      disabled: true,
    },
    {
      name: "fullName",
      value: "",
      type: "text",
      label: "Full Name",
      placeholder: "Enter full name",
      customClass: "col-md-2",
      disabled: true,
    },
    {
      name: "riskLevel",
      value: "low",
      type: "select",
      label: "Risk Level",
      placeholder: "Select risk level",
      options: [
        {
          value: "low",
          label: "Low",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "high",
          label: "High",
        },
      ],
      disabled: true,
    },
    {
      customClass: pep == "Y" ? "col-md-3" : "col-lg-1",
      label: "PEP",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${pep == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={pep}
            onChange={handleChangePep}
            disabled={true}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {pep == "Y" && (
            <input
              type="text"
              value={pepText}
              disabled={true}
              className="w-100"
              placeholder="Enter pep"
            />
          )}
        </div>
      ),
    },
    {
      customClass: sanction == "Y" ? "col-md-3" : "col-lg-1",
      label: "Sanction",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${sanction == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={sanction}
            disabled={true}
            onChange={handleChange}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {sanction == "Y" && (
            <input
              type="text"
              disabled={true}
              value={sanctionText}
              className="w-100"
              placeholder="Enter sanction"
            />
          )}
        </div>
      ),
    },
  ];
  const addNewIndividualDoc = () => {
    setIndividualCustom([
      ...individualCustom,
      `Enter Document Name ${individualCustom.length + 1}`,
    ]);
  };

  const removeIndividualDoc = (index) => {
    setIndividualCustom(individualCustom.filter((_, i) => i !== index));
  };

  const getLabelName = (value, index) => {
    let arr = [...individualCustom];
    arr[index] = value;
    setIndividualCustom(arr);
  };

  const storeDocuments = (file, index) => {
    let doc = [...documents];
    let data = [...fileArray];
    doc.push({ file: file, index: index });
    let update = doc.filter((a) => a.index === index);
    data[index].doc = update;

    if (checkValue) {
      let dd = checkValue && checkValue.filter((a) => a.index == index);
      data[index].checkValue = dd;
    }
    setDocuments(doc);
    setIndividualKYC(data);
  };

  const addNewCol = (key, { focus }) => {
    setForms((prev) => {
      const updatedForms = { ...prev };
      updatedForms[key].push(updatedForms[key].length + 1);
      return updatedForms;
    });
    if (focus) {
      focusOn(key + (forms[key].length - 1));
    }
  };

  const removeNewCol = (key, index) => {
    setForms((prev) => {
      const updatedForms = { ...prev };
      updatedForms[key].splice(index, 1);
      return updatedForms;
    });
  };

  const removeArrItem = (values, arr) => {
    return arr.filter((item) => !values.includes(item));
  };

  const updateStatusEntry = (status, index, property, value) => {
    const entry = status.find((item) => item.index === index);
    if (entry) {
      entry[property] = value;
    } else {
      status.push({ index, [property]: value });
    }
  };

  const handleCheckboxChange = (values, checkStatus, name, index) => {
    let arr = [...individualDocs];
    let status = [...checkValue];
    let val = { [name]: checkStatus, index: index };
    status.push(val);
    const uniqueStatus = new Map();
    status.forEach((entry) => {
      if (!uniqueStatus.has(entry.index)) {
        uniqueStatus.set(entry.index, { index: entry.index });
      }
      uniqueStatus.set(entry.index, {
        ...uniqueStatus.get(entry.index),
        ...entry,
      });
    });
    status = Array.from(uniqueStatus.values());

    if (checkStatus) {
      arr = removeArrItem(values, arr);
    }

    if (name === "directors" || name === "reserve") {
      if (checkStatus) {
        arr.push(...values);
        updateStatusEntry(
          status,
          index,
          name === "directors" ? "reserve" : "directors",
          false
        );
        updateStatusEntry(status, index, name, true);
      } else {
        arr = removeArrItem(values, arr);
        updateStatusEntry(status, index, name, false);
      }
    } else if (name === "shareholders" || name === "nominee") {
      if (checkStatus) {
        arr.push(...values);
        updateStatusEntry(
          status,
          index,
          name === "shareholders" ? "nominee" : "shareholders",
          false
        );
        updateStatusEntry(status, index, name, true);
        arr = removeArrItem(
          name === "shareholders" ? nomineeDocs : shareholderDocs,
          arr
        );
      } else {
        arr = removeArrItem(values, arr);
        updateStatusEntry(status, index, name, false);
      }
    } else {
      if (checkStatus) {
        arr.push(...values);
      } else {
        arr = removeArrItem(values, arr);
      }
    }
    setCheckValue(status);
    setIndividualDocs(arr);
  };

  const handleOnChange = async (value) => {
    let updatedArray = [...fileArray];
    let data = { ...formData, ...value };
    let { masterId } = value;
    if (masterId) {
      let payload = {
        method: "GET",
        url: `/individual?_start=0&_limit=1&id=${masterId}`,
      };
      let individualData = await fetchApi(payload).then(
        (res) => res.data.rows[0]
      );

      if (individualData) {
        data.fullName = individualData.firstName;
        data.riskLevel = individualData.details.riskRating;
        data.title = individualData.title;
        setSanction(individualData.details.sanction);
        setSanctionText(individualData?.details?.sanctionText);
        setPep(individualData?.details?.pep);
        setPepText(individualData?.details?.pepText);
      }
    }
    updatedArray.push(data);
    setFileArray(updatedArray);
    setFormData(data);
  };

  const individualOnChange = async (value) => {
    let params = { code: value };
    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/individual?${query}`,
    };
    let data = await fetchApi(payload).then((res) => res.data.rows);

    let list = data.map((d) => ({
      value: d?._id,
      label: d?.code,
    }));
    setIndividualArray(list);
  };

  return (
    <div>
      <div className="mt-4">
        {forms.individuals.map((_, index) => (
          <div key={index}>
            <div className="d-flex my-4 gap-3 align-items-center">
              <h4 className="primary-title">Individuals</h4>
              {index === 0 ? (
                <button
                  className="primary-btn"
                  onClick={() => addNewCol("individuals", { focus: true })}
                >
                  <i className="fal fa-plus-circle"></i> Add New
                </button>
              ) : (
                <button
                  className="outline-btn red-btn"
                  onClick={() => removeNewCol("individuals", index)}
                >
                  <i className="fal fa-trash-alt"></i> Remove
                </button>
              )}

              <Checkbox
                className="custom-checkbox"
                checked={
                  checkValue && checkValue?.[index]?.directors
                    ? checkValue[index].directors && !checkValue[index].reserve
                    : false
                }
                onChange={(e) =>
                  handleCheckboxChange(
                    directorDocs,
                    e.target.checked,
                    "directors",
                    index
                  )
                }
              >
                Directors
              </Checkbox>
              <Checkbox
                className="custom-checkbox"
                checked={
                  checkValue && checkValue?.[index]?.shareholders
                    ? checkValue[index].shareholders &&
                      !checkValue[index].nominee
                    : false
                }
                onChange={(e) =>
                  handleCheckboxChange(
                    shareholderDocs,
                    e.target.checked,
                    "shareholders",
                    index
                  )
                }
              >
                Shareholders
              </Checkbox>
              <Checkbox
                className="custom-checkbox"
                onChange={(e) =>
                  handleCheckboxChange(uboDocs, e.target.checked, "ubo", index)
                }
              >
                UBOs
              </Checkbox>
              <Checkbox
                className="custom-checkbox"
                checked={
                  checkValue && checkValue?.[index]?.nominee
                    ? checkValue[index].nominee &&
                      !checkValue[index].shareholders
                    : false
                }
                onChange={(e) =>
                  handleCheckboxChange(
                    nomineeDocs,
                    e.target.checked,
                    "nominee",
                    index
                  )
                }
              >
                Nominee Shareholder
              </Checkbox>
              <Checkbox
                className="custom-checkbox"
                checked={
                  checkValue && checkValue?.[index]?.reserve
                    ? !checkValue[index].directors && checkValue[index].reserve
                    : false
                }
                onChange={(e) =>
                  handleCheckboxChange(
                    directorDocs,
                    e.target.checked,
                    "reserve",
                    index
                  )
                }
              >
                Reserve Director
              </Checkbox>
            </div>

            <div className="custom-form my-4" id={`individuals${index}`}>
              <div className="d-flex gap-3 mb-3 align-items-center">
                <h3 className="sub-title">
                  Individual {index + 1} &nbsp;
                  <i className="fal fa-question-circle"></i>
                </h3>
              </div>
              <FormZ
                ref={(el) => (directorFormRef.current[index] = el)}
                formSchema={formSchema}
                onChange={handleOnChange}
                onKeyUp={(e) => individualOnChange(e.target.value)}
                formOptions={{ masterId: individualArray }}
                formData={fileArray[index]}
                formClass="row mt-3 mb-4"
                childClass="col-md-1 mb-0"
                labelClass="mb-1"
                inputClass="w-100"
              />
              <div className="row gy-2 gx-4">
                <Each
                  array={individualDocs}
                  render={(item) => (
                    <div className="col-md-3">
                      <label htmlFor={item}>{item}</label>
                      <UploadZ
                        onStoreFile={(data) => storeDocuments(data, index)}
                        fileName={item}
                      />
                    </div>
                  )}
                />
                {individualCustom.length > 0 && (
                  <Each
                    array={individualCustom}
                    render={(item, i) => (
                      <div className="col-md-3">
                        <div className="d-flex justify-content-between align-items-center mb-1px">
                          <label
                            contentEditable={true}
                            key={i}
                            id={i}
                            onBlur={(e) => getLabelName(e.target.innerHTML, i)}
                            className="mb-0"
                            suppressContentEditableWarning={true}
                          >
                            {item}
                          </label>
                          <button
                            className="del-upload-btn"
                            onClick={() => removeIndividualDoc(i)}
                          >
                            <i className="far fa-trash-alt"></i>
                          </button>
                        </div>
                        <UploadZ
                          onStoreFile={(data) => storeDocuments(data, index)}
                          fileName={item}
                        />
                      </div>
                    )}
                  />
                )}
                <div className="col-md-3 mb-4">
                  <label htmlFor="">&nbsp;</label>
                  <div className="add-new-upload" onClick={addNewIndividualDoc}>
                    <span className="icon">
                      <i className="fal fa-plus-circle"></i>
                    </span>
                    <h6>Add New Upload</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Review;
