import React, { useState, useEffect, useRef } from "react";

import { Select, Modal, Spin, Table, Popover } from "antd";
import { useSelector } from "react-redux";

import {
  fetchApi,
  sendNotify,
  customPagination,
  registerOfCharges,
} from "../../helper";

import { languagesArray } from "../../store/language/languageSlice";

import MasterFileCreation from "../../helper/translations/MasterFileCreation.json";
var fileDownload = require("js-file-download");

export default function Creation() {
  const bulkUploadRef = useRef();

  const { language } = useSelector((state) => state.language);

  const [loader, setLoader] = useState(false);
  const [selectedMFT, setSelectedMFT] = useState(null);
  const [selectedENOption, setSelectedENOption] = useState(null);
  const [selectedIndType, setSelectedIndType] = useState(null);
  const [selectShareEntry, setSelectShareEntry] = useState(null);
  const [selectShareTransfer, setSelectShareTransfer] = useState(null);

  const [showBulkModal, setShowbulkModal] = useState(false);
  const [selctedLanguage, setSelctedLanguage] = useState("");
  const [bulkUploadData, setBulkUploadData] = useState([]);
  const [showBulkUploadData, setShowBulkUploadData] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    let index = languagesArray.find((tc) => tc.value == language);
    setSelctedLanguage(index.value);
  }, [language]);

  let m =
    MasterFileCreation.masterFile[selctedLanguage] ||
    MasterFileCreation.masterFile.en;

  const MFTOptions = [
    {
      value: "introducer-client",
      label: m.IntroducerClient,
    },
    {
      value: "end-user",
      label: m.EndUserClient,
    },
    {
      value: "parent-client",
      label: m.ParentClient,
    },
    {
      value: "client",
      label: m.Client,
    },
    {
      value: "entity",
      label: m.Entity,
    },
    {
      value: "individual",
      label: m.Individual,
    },
    {
      value: "corporate-entity",
      label: m.CorporateEntity,
    },
  ];
  const EntityOptions = [
    {
      value: "primaryContact",
      label: "Primary Contact",
    },
    {
      value: "registers",
      label: "Registered Address",
    },
    {
      value: "cddInformation",
      label: "CDD Information",
    },
    {
      value: "FRA",
      label: "Financial Record Address",
    },
    {
      value: "FRD",
      label: "Financial Record Details",
    },
    {
      value: "ROC",
      label: "Register Of Charges",
    },
    {
      value: "individual-details",
      label: "Individual Details",
    },
  ];

  const individualSub = [
    {
      value: "Director",
      label: "Director",
    },
    {
      value: "Shareholder",
      label: "Shareholder",
    },
    {
      value: "Reserve Director",
      label: "Reserve Director",
    },
    {
      value: "UBO",
      label: "UBO",
    },
    {
      value: "Nominee Shareholder",
      label: "Nominee Shareholder",
    },
    {
      value: "Secretary",
      label: "Secretary",
    },
    {
      value: "President",
      label: "President",
    },
  ];

  const shareEntryOptions = [
    {
      value: "NAJS",
      label: "New Allocation - Joint Survivorship",
    },
    {
      value: "NADE",
      label: "New Allocation - Direct Entry",
    },
    {
      value: "STJS",
      label: "Share Transfer - Joint Survivorship",
    },
    {
      value: "STDE",
      label: "Share Transfer - Direct Entry",
    },
  ];

  const shareTransferOptions = [
    {
      value: "Sender",
      label: "Sender",
    },
    {
      value: "Receiver",
      label: "Receiver",
    },
  ];

  const downloadSampleExcel = async (value) => {
    let payload = {
      method: "GET",
      responseType: "blob",
    };
    let exportFileName = ``;
    if (value == "Master") {
      exportFileName = `${selectedMFT}_Sample.xls`;
      payload.url = `/client/get/excel-file/?type=${selectedMFT}`;
    } else if (value == "share-entry") {
      exportFileName = `${selectShareEntry}_${selectShareTransfer}_Sample.xls`;
      payload.url = `/client/get/excel-file/?type=${selectShareEntry}&sub=${selectShareTransfer}`;
    } else {
      exportFileName = `${selectedENOption}_Sample.xls`;
      payload.url = `/client/get/excel-file/?type=${selectedENOption}&sub=${selectedIndType}`;
    }
    let result = await fetchApi(payload).then((res) => {
      return res;
    });

    fileDownload(result, exportFileName);
  };

  const bulkAddModalOnClick = async () => {
    setShowbulkModal(true);
  };

  const closeBulkModalOnClick = () => {
    setShowBulkUploadData(false);
    setBulkUploadData([]);
    setShowbulkModal(false);
  };

  const bulkUploadOnClick = () => {
    bulkUploadRef.current.click();
  };

  const bulkUploadOnChange = async (e) => {
    let file = e.target.files[0];
    setLoader(true);
    setShowBulkUploadData(false);
    setUploadedFile(e.target.value);
    const bodyFormData = new FormData();
    bodyFormData.append("excel", file);
    let payload = {
      method: "POST",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    payload.url = `/client/read/excel-file/`;

    await fetchApi(payload)
      .then((res) => {
        if (res && res?.xlData && res?.xlData?.length) {
          setBulkUploadData(res?.xlData);
        } else {
          setBulkUploadData([]);
        }
      })
      .catch((error) => {
        console.error(error, "onStoreFile-error");
        sendNotify("error", error.message || "File upload failed.");
      });

    setShowBulkUploadData(true);
    setLoader(false);
  };

  const bulkSaveOnClick = async () => {
    setUploading(true);
    let uploadObj = {};

    uploadObj.data = bulkUploadData;
    uploadObj.masterType = selectedMFT;
    uploadObj["key"] = selectShareEntry ?? selectedENOption;
    uploadObj["sub"] = selectShareTransfer;

    let payload = {
      method: "POST",
      url:
        selectedENOption || selectShareEntry
          ? "entity/updatedManyDetails"
          : selectedMFT == "entity"
          ? "entity/createMany"
          : selectedMFT == "individual"
          ? "individual/createMany"
          : selectedMFT == "corporate-entity"
          ? "cop-entity/createMany"
          : `/client/createmany`,
      data: uploadObj,
    };

    await fetchApi(payload)
      .then((res) => {
        setUploading(false);
        if (res?.status == 200 || res?.status == 201) {
          sendNotify("success", res?.message);
          closeBulkModalOnClick();
          setUploadedFile(null);
        } else {
          sendNotify("error", res?.message);
        }
      })
      .catch((error) => {
        console.error(error, "onStoreFile-error");
        sendNotify("error", error.message || "File upload failed.");
        setUploading(false);
      });
  };

  const getPagination = async (current, pageSize) => {};

  const getMFT = (value) => {
    setSelectedMFT(value);
  };
  const getENOptions = (value) => {
    setSelectedENOption(value);
  };

  const clientColumns = [
    {
      title: "Client Name",
      dataIndex: "Client Name",
    },
    {
      title: "Client Type",
      dataIndex: "Client Type",
    },
    {
      title: "Client Status",
      dataIndex: "Client Status",
    },
    {
      title: "Parent Client Code",
      dataIndex: "Parent Client Code",
    },
    {
      title: "Relationship Manager",
      dataIndex: "Relationship Manager",
    },
    {
      title: "Administrator",
      dataIndex: "Administrator",
    },
    {
      title: "Admin Manager",
      dataIndex: "Admin Manager",
    },
    {
      title: "Jurisdiction",
      dataIndex: "Jurisdiction",
    },
    {
      title: "Fee Schedule Name",
      dataIndex: "Fee Schedule Name",
    },
    {
      title: "Fee Type",
      dataIndex: "Fee Type",
    },

    {
      title: "Fee Sub Category",
      dataIndex: "Fee Sub Category",
    },
    {
      title: "Contact Person's Name",
      dataIndex: "Contact Person's Name",
    },
    {
      title: "Email",
      dataIndex: "Email",
    },
    {
      title: "Contact Number",
      dataIndex: "Contact Number",
    },
  ];

  const entityColumns = [
    {
      title: "Your Reference ID",
      dataIndex: "Your Reference ID",
    },
    {
      title: "MasterFile Type",
      dataIndex: "MasterFile Type",
    },
    {
      title: "Entity Name",
      dataIndex: "Entity Name",
    },
    {
      title: "Entity Type",
      dataIndex: "Entity Type",
    },
    {
      title: "Your Client Reference ID",
      dataIndex: "Your Client Reference ID",
    },
    {
      title: "Incorporation Date",
      dataIndex: "Incorporation Date",
    },
    {
      title: "Incorporation Number",
      dataIndex: "Incorporation Number",
    },
    {
      title: "Incorporation Country",
      dataIndex: "Incorporation Country",
    },
    {
      title: "Fee Category",
      dataIndex: "Fee Category",
    },
    {
      title: "Fee Sub Category",
      dataIndex: "Fee Sub Category",
    },
    {
      title: "Fee Type",
      dataIndex: "Fee Type",
    },
    {
      title: "Authorized Shares",
      dataIndex: "Authorized Shares",
    },
    {
      title: "Relationship Manager",
      dataIndex: "Relationship Manager",
    },
    {
      title: "Administrator",
      dataIndex: "Administrator",
    },
    {
      title: "Admin Manager",
      dataIndex: "Admin Manager",
    },
    {
      title: "Status",
      dataIndex: "Status",
    },
    {
      title: "YearEnd Date",
      dataIndex: "YearEnd Date",
    },
  ];

  const individualColumns = [
    {
      title: "Your Reference ID",
      dataIndex: "Your Reference ID",
    },
    {
      title: "Contact No",
      dataIndex: "Contact No",
    },
    {
      title: "Email",
      dataIndex: "Email",
    },
    {
      title: "Title",
      dataIndex: "Title",
    },
    {
      title: "First Name",
      dataIndex: "First Name",
    },
    {
      title: "Middle Name",
      dataIndex: "Middle Name",
    },
    {
      title: "Last Name",
      dataIndex: "Last Name",
    },
    {
      title: "DOB",
      dataIndex: "DOB",
    },
    {
      title: "COB",
      dataIndex: "COB",
    },
    {
      title: "Nationality",
      dataIndex: "Nationality",
    },
    {
      title: "Passport No",
      dataIndex: "Passport No",
    },
    {
      title: "Master Type",
      dataIndex: "Master Type",
    },
    {
      title: "Passport Issue",
      dataIndex: "Passport Issue",
    },
    {
      title: "Passport Exp",
      dataIndex: "Passport Exp",
    },
    {
      title: "ID No",
      dataIndex: "ID No",
    },
    {
      title: "ID Issue",
      dataIndex: "ID Issue",
    },
    {
      title: "ID Exp",
      dataIndex: "ID Exp",
    },
    {
      title: "Tax Info",
      dataIndex: "Tax Info",
    },
    {
      title: "Associate Business",
      dataIndex: "Associate Business",
    },
    {
      title: "Status",
      dataIndex: "Status",
    },
    {
      title: "Residential Address",
      dataIndex: "Residential Address",
    },
    {
      title: "Service Address",
      dataIndex: "Service Address",
    },
  ];

  const copEntityColumns = [
    {
      title: "Reference Corporate Code",
      dataIndex: "Reference Corporate Code",
    },
    {
      title: "Corporate Name",
      dataIndex: "Corporate Name",
    },
    {
      title: "Master File Type",
      dataIndex: "Master File Type",
    },
    {
      title: "Corporate Entity Type",
      dataIndex: "Corporate Entity Type",
    },
    {
      title: "Incorporation Date",
      dataIndex: "Incorporation Date",
    },
    {
      title: "Incorporation Number",
      dataIndex: "Incorporation Number",
    },
    {
      title: "Incorporation Country",
      dataIndex: "Incorporation Country",
    },
    {
      title: "Business Geography",
      dataIndex: "Business Geography",
    },
    {
      title: "Business Nature",
      dataIndex: "Business Nature",
    },
    {
      title: "Business Description",
      dataIndex: "Business Description",
    },
    {
      title: "Corporate Info No",
      dataIndex: "Corporate Info No",
    },
    {
      title: "Address Line 1",
      dataIndex: "Address Line 1",
    },
    {
      title: "Address Line 2",
      dataIndex: "Address Line 2",
    },
    {
      title: "Address Line 3",
      dataIndex: "Address Line 3",
    },
    {
      title: "Zip Code",
      dataIndex: "Zip Code",
    },
    {
      title: "Country",
      dataIndex: "Country",
    },
    {
      title: "Risk Rating",
      dataIndex: "Risk Rating",
    },
    {
      title: "Listed",
      dataIndex: "Listed",
    },
    {
      title: "Sanction",
      dataIndex: "Sanction",
    },
    {
      title: "Sanction Text",
      dataIndex: "Sanction Text",
    },
    {
      title: "Status",
      dataIndex: "Status",
    },
  ];

  const pcColumns = [
    {
      title: "Entity Reference ID",
      dataIndex: "Entity Reference ID",
    },
    {
      title: "Client Reference ID",
      dataIndex: "Client Reference ID",
    },
    {
      title: "Name",
      dataIndex: "Name",
    },
    {
      title: "Contact No",
      dataIndex: "Contact No",
    },
    {
      title: "Email",
      dataIndex: "Email",
    },
  ];

  const addressColumns = [
    {
      title: "Entity Reference ID",
      dataIndex: "Entity Reference ID",
    },
    {
      title: "Address Line 1",
      dataIndex: "Address Line 1",
    },
    {
      title: "Address Line 2",
      dataIndex: "Address Line 2",
    },
    {
      title: "Address Line 3",
      dataIndex: "Address Line 3",
    },
    {
      title: "Zip Code",
      dataIndex: "Zip Code",
    },
    {
      title: "Country",
      dataIndex: "Country",
    },
  ];

  const cddColumns = [
    {
      title: "Entity Reference ID",
      dataIndex: "Entity Reference ID",
    },
    {
      title: "Source of Wealth",
      dataIndex: "Source of Wealth",
    },
    {
      title: "Source of Funds",
      dataIndex: "Source of Funds",
    },
    {
      title: "Purpose of Business",
      dataIndex: "Purpose of Business",
    },
    {
      title: "Geography of Business",
      dataIndex: "Geography of Business",
    },
    {
      title: "Business Activity",
      dataIndex: "Business Activity",
    },
    {
      title: "Description of Business",
      dataIndex: "Description of Business",
    },
    {
      title: "Entity Last Reviewed Date",
      dataIndex: "Entity Last Reviewed Date",
    },
    {
      title: "Entity Next Reviewed Date",
      dataIndex: "Entity Next Reviewed Date",
    },
    {
      title: "Overall Entity Risk Level",
      dataIndex: "Overall Entity Risk Level",
    },
  ];

  const fraColumns = [
    {
      title: "Entity Reference ID",
      dataIndex: "Entity Reference ID",
    },
    {
      title: "Address Line 1",
      dataIndex: "Address Line 1",
    },
    {
      title: "Address Line 2",
      dataIndex: "Address Line 2",
    },
    {
      title: "Address Line 3",
      dataIndex: "Address Line 3",
    },
    {
      title: "Zip Code",
      dataIndex: "Zip Code",
    },
    {
      title: "Country",
      dataIndex: "Country",
    },
  ];

  const frdColumns = [
    {
      title: "Entity Reference ID",
      dataIndex: "Entity Reference ID",
    },
    {
      title: "Financial Record Code",
      dataIndex: "Financial Record Code",
    },
    {
      title: "Title",
      dataIndex: "Title",
    },
    {
      title: "Record Holder",
      dataIndex: "Record Holder",
    },
    {
      title: "Mobile",
      dataIndex: "Mobile",
    },
    {
      title: "Email",
      dataIndex: "Email",
    },
    {
      title: "Status",
      dataIndex: "Status",
    },
  ];

  const rocColumns = [
    {
      title: "Entity Reference ID",
      dataIndex: "Entity Reference ID",
    },
    {
      title: registerOfCharges.descriptionOfCharge,
      dataIndex: registerOfCharges.descriptionOfCharge,
    },
    {
      title: registerOfCharges.liabilitySecured,
      dataIndex: registerOfCharges.liabilitySecured,
    },
    {
      title: registerOfCharges.propertyCharged,
      dataIndex: registerOfCharges.propertyCharged,
    },
    {
      title: registerOfCharges.trusteeOrChargee,
      dataIndex: registerOfCharges.trusteeOrChargee,
    },
    {
      title: registerOfCharges.holderOfCharge,
      dataIndex: registerOfCharges.holderOfCharge,
    },
    {
      title: registerOfCharges.prohibitionsOrRestrictions,
      dataIndex: registerOfCharges.prohibitionsOrRestrictions,
    },
    {
      title: registerOfCharges.dateOfDischarge,
      dataIndex: registerOfCharges.dateOfDischarge,
    },
  ];

  const defaultColumns = [
    {
      title: "Individual ID",
      dataIndex: "Individual ID",
    },
    {
      title: "Entity Reference ID",
      dataIndex: "Entity Reference ID",
    },
    {
      title: "Director",
      dataIndex: "Director",
    },
    {
      title: "Reserve Director",
      dataIndex: "Reserve Director",
    },
    {
      title: "Shareholder",
      dataIndex: "Shareholder",
    },
    {
      title: "UBO",
      dataIndex: "UBO",
    },
    {
      title: "Nominee Shareholder",
      dataIndex: "Nominee Shareholder",
    },
    {
      title: "Secretary",
      dataIndex: "Secretary",
    },
    {
      title: "President",
      dataIndex: "President",
    },
  ];

  const NAColumns = [
    {
      title: "Individual ID",
      dataIndex: "Individual ID",
    },
    {
      title: "Entity Reference ID",
      dataIndex: "Entity Reference ID",
    },
    {
      title: "Masterfile Code",
      dataIndex: "Masterfile Code",
    },
    {
      title: "Masterfile Name",
      dataIndex: "Masterfile Name",
    },
    {
      title: "Authorized Share Capital",
      dataIndex: "Authorized Share Capital",
    },
    {
      title: "No. of shares allocated",
      dataIndex: "No. of shares allocated",
    },
    {
      title: "From",
      dataIndex: "From",
    },
    {
      title: "To",
      dataIndex: "To",
    },
    {
      title: "Share Type List",
      dataIndex: "Share Type List",
    },

    {
      title: "Currency",
      dataIndex: "Currency",
    },
    {
      title: "Par Val",
      dataIndex: "Par Val",
    },
    {
      title: "Effective date",
      dataIndex: "Effective date",
    },
    {
      title: "Ceased date",
      dataIndex: "Ceased date",
    },
    {
      title: "Total Shares held",
      dataIndex: "Total Shares held",
    },
    {
      title: "Certificate Number",
      dataIndex: "Certificate Number",
    },
    {
      title: "Date of entry",
      dataIndex: "Date of entry",
    },

    {
      title: "Comment",
      dataIndex: "Comment",
    },
    {
      title: "Ownership Percentage",
      dataIndex: "Ownership Percentage",
    },
  ];

  const STSenderColumns = [
    {
      title: "Individual ID",
      dataIndex: "Individual ID",
    },
    {
      title: "Entity Reference ID",
      dataIndex: "Entity Reference ID",
    },
    {
      title: "Masterfile Code",
      dataIndex: "Masterfile Code",
    },
    {
      title: "Masterfile Name",
      dataIndex: "Masterfile Name",
    },
    {
      title: "Authorized Share Capital",
      dataIndex: "Authorized Share Capital",
    },
    {
      title: "No. of shares transferred",
      dataIndex: "No. of shares transferred",
    },
    {
      title: "From",
      dataIndex: "From",
    },
    {
      title: "To",
      dataIndex: "To",
    },
    {
      title: "Share Type List",
      dataIndex: "Share Type List",
    },

    {
      title: "Currency",
      dataIndex: "Currency",
    },
    {
      title: "Par Val",
      dataIndex: "Par Val",
    },
    {
      title: "Effective date",
      dataIndex: "Effective date",
    },
    {
      title: "Ceased date",
      dataIndex: "Ceased date",
    },
    {
      title: "Total Shares held",
      dataIndex: "Total Shares held",
    },
    {
      title: "Certificate Number",
      dataIndex: "Certificate Number",
    },
    {
      title: "Date of entry",
      dataIndex: "Date of entry",
    },

    {
      title: "Comment",
      dataIndex: "Comment",
    },
    {
      title: "Ownership Percentage",
      dataIndex: "Ownership Percentage",
    },
  ];

  const STReceiverColumns = [
    {
      title: "Individual ID",
      dataIndex: "Individual ID",
    },
    {
      title: "Entity Reference ID",
      dataIndex: "Entity Reference ID",
    },
    {
      title: "Masterfile Code",
      dataIndex: "Masterfile Code",
    },
    {
      title: "Masterfile Name",
      dataIndex: "Masterfile Name",
    },
    {
      title: "Authorized Share Capital",
      dataIndex: "Authorized Share Capital",
    },
    {
      title: "No. of shares received",
      dataIndex: "No. of shares received",
    },
    {
      title: "From",
      dataIndex: "From",
    },
    {
      title: "To",
      dataIndex: "To",
    },
    {
      title: "Effective date",
      dataIndex: "Effective date",
    },
    {
      title: "Total Shares held",
      dataIndex: "Total Shares held",
    },
    {
      title: "Certificate Number",
      dataIndex: "Certificate Number",
    },
    {
      title: "Date of entry",
      dataIndex: "Date of entry",
    },
    {
      title: "Ownership Percentage",
      dataIndex: "Ownership Percentage",
    },
  ];

  const [indDetailsColumns, setIndDetailsColumns] = useState(defaultColumns);

  const tableColumn = {
    entity: entityColumns,
    individual: individualColumns,
    "corporate-entity": copEntityColumns,
    primaryContact: pcColumns,
    registers: addressColumns,
    cddInformation: cddColumns,
    FRA: fraColumns,
    FRD: frdColumns,
    ROC: rocColumns,
    "individual-details": indDetailsColumns,
    NAJS: NAColumns,
    NADE: NAColumns,
    STJS: selectShareTransfer == "Sender" ? STSenderColumns : STReceiverColumns,
    STDE: selectShareTransfer == "Sender" ? STSenderColumns : STReceiverColumns,
  };

  const getIndividualType = (value) => {
    let arr = [...value];

    setSelectedIndType(value);

    if (value.includes("Shareholder")) {
      arr.push({
        title: "No. of Shares Held",
        dataIndex: "No. of Shares Held",
      });
    }
    if (value.includes("UBO")) {
      arr.push(
        {
          title: "Total Shares Held",
          dataIndex: "Total Shares Held",
        },
        {
          title: "Ownership Percentage",
          dataIndex: "Ownership Percentage",
        }
      );
    }
    if (value.includes("Nominee Shareholder")) {
      arr.push(
        {
          title: "No. of Shares Held",
          dataIndex: "No. of Shares Held",
        },
        {
          title: "Nominee Agreement Date",
          dataIndex: "Nominee Agreement Date",
        }
      );
    }
    if (
      value.some((value) =>
        ["Director", "Reserve Director", "Secretary", "President"].includes(
          value
        )
      )
    ) {
      arr.push(
        {
          title: "Date of Appointment",
          dataIndex: "Date of Appointment",
        },
        {
          title: "Date of Resignation",
          dataIndex: "Date of Resignation",
        }
      );
    }
    setIndDetailsColumns(arr);
  };

  return (
    <div>
      <div className="page-header">
        <h1>Bulk Upload</h1>
      </div>
      <div className="page-content">
        <h1 className="primary-title mb-3">Master File Upload</h1>
        <div className="custom-form">
          <div className="mb-4">
            <label htmlFor="" className="me-3 mb-1">
              {m.MasterfileType}
            </label>
            <Select
              className="custom-select mw-300"
              placeholder={m.SelectAny}
              onChange={getMFT}
              options={MFTOptions}
            />
          </div>
          {selectedMFT && (
            <div className="d-flex gap-2">
              <button
                onClick={() => downloadSampleExcel("Master")}
                className="primary-btn  justify-content-center py-2"
              >
                <i className="fal fa-file-download"></i> Sample Excel
              </button>
              <button
                onClick={bulkAddModalOnClick}
                className="primary-btn  justify-content-center py-2"
              >
                <i className="fal fa-plus" /> Bulk Upload
              </button>
              <Popover
                content={
                  <div className="popover-content-max">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Culpa fugiat enim repellendus velit eaque optio quidem
                    asperiores assumenda veniam eveniet.
                  </div>
                }
              >
                <button className="outline-btn">
                  <i class="fal fa-question-circle"></i>
                </button>
              </Popover>
            </div>
          )}
          {selectedMFT == "entity" && (
            <>
              <h1 className="primary-title mt-4 mb-3">Entity Details</h1>
              <div className="custom-form row gx-5 mb-3">
                <div className="col-md-3">
                  <label htmlFor="" className="me-3 mb-1">
                    Entity Profile
                  </label>
                  <Select
                    className="custom-select mw-300"
                    placeholder={m.SelectAny}
                    onChange={getENOptions}
                    options={EntityOptions}
                  />
                </div>
                {selectedENOption == "individual-details" && (
                  <div className="col-md-3">
                    <label htmlFor="" className="me-3 mb-1">
                      Individual Type
                    </label>
                    <Select
                      mode="multiple"
                      className="custom-select mw-300 p-small"
                      placeholder={m.SelectAny}
                      value={selectedIndType}
                      onChange={getIndividualType}
                      options={individualSub}
                    />
                  </div>
                )}
              </div>
              {selectedENOption && (
                <div className="d-flex gap-2">
                  <button
                    onClick={() => downloadSampleExcel("sub")}
                    className="primary-btn  justify-content-center py-2"
                  >
                    <i className="fal fa-file-download"></i> Sample Excel
                  </button>

                  <button
                    onClick={bulkAddModalOnClick}
                    className="primary-btn  justify-content-center py-2"
                  >
                    <i className="fal fa-plus" /> Bulk Upload
                  </button>

                  <Popover
                    content={
                      <div className="popover-content-max">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Culpa fugiat enim repellendus velit eaque optio
                        quidem asperiores assumenda veniam eveniet.
                      </div>
                    }
                  >
                    <button className="outline-btn">
                      <i class="fal fa-question-circle"></i>
                    </button>
                  </Popover>
                </div>
              )}
              {selectedENOption == "individual-details" && (
                <div className="custom-form row gx-5 my-4">
                  <div className="col-md-3">
                    <label htmlFor="" className="me-3 mb-1">
                      Add Share Entry
                    </label>
                    <Select
                      className="custom-select mw-300"
                      placeholder={m.SelectAny}
                      value={selectShareEntry}
                      onChange={(value) => setSelectShareEntry(value)}
                      options={shareEntryOptions}
                    />
                  </div>
                  {(selectShareEntry == "STJS" ||
                    selectShareEntry == "STDE") && (
                    <div className="col-md-3">
                      <label htmlFor="" className="me-3 mb-1">
                        Share Transfer Type
                      </label>
                      <Select
                        className="custom-select mw-300"
                        placeholder={m.SelectAny}
                        value={selectShareTransfer}
                        onChange={(value) => setSelectShareTransfer(value)}
                        options={shareTransferOptions}
                      />
                    </div>
                  )}
                </div>
              )}
              {selectShareEntry && (
                <div className="d-flex gap-2">
                  <button
                    disabled={
                      (selectShareEntry == "STJS" && !selectShareTransfer) ||
                      (selectShareEntry == "STDE" && !selectShareTransfer)
                    }
                    onClick={() => downloadSampleExcel("share-entry")}
                    className="primary-btn  justify-content-center py-2"
                  >
                    <i className="fal fa-file-download"></i> Sample Excel
                  </button>

                  <button
                    onClick={bulkAddModalOnClick}
                    className="primary-btn  justify-content-center py-2"
                  >
                    <i className="fal fa-plus" /> Bulk Upload
                  </button>

                  <Popover
                    content={
                      <div className="popover-content-max">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Culpa fugiat enim repellendus velit eaque optio
                        quidem asperiores assumenda veniam eveniet.
                      </div>
                    }
                  >
                    <button className="outline-btn">
                      <i class="fal fa-question-circle"></i>
                    </button>
                  </Popover>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Modal
        className={"custom-modal"}
        visible={showBulkModal}
        okText={"Upload"}
        onCancel={closeBulkModalOnClick}
        title={
          <div className="text-capitalize">
            Add New {selectShareEntry ?? selectedENOption ?? selectedMFT}
          </div>
        }
        width={1300}
        onOk={bulkSaveOnClick}
        okButtonProps={{ loading: uploading }}
      >
        <Spin spinning={loader} tip={"Loading..."}>
          <div className="d-flex justify-content-center w-100 mb-4">
            <div className="d-block mx-auto">
              <button
                className="primary-btn"
                type="button"
                onClick={bulkUploadOnClick}
              >
                {showBulkUploadData ? (
                  <>
                    <i className="far fa-sync"></i> Reupload
                  </>
                ) : (
                  <>
                    <i className="fas fa-upload"></i>
                    Import
                  </>
                )}
              </button>
              <input
                ref={bulkUploadRef}
                type={"file"}
                style={{ display: "none" }}
                value={uploadedFile}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onClick={() => setUploadedFile(null)}
                onChange={bulkUploadOnChange}
              />
            </div>
          </div>
          {showBulkUploadData && (
            <div>
              <Table
                className="custom-table"
                columns={
                  selectShareEntry
                    ? tableColumn[selectShareEntry]
                    : selectedENOption
                    ? tableColumn[selectedENOption]
                    : tableColumn[selectedMFT] ?? clientColumns
                }
                rowKey={"_id"}
                scroll={{
                  x: 2000,
                }}
                dataSource={bulkUploadData}
                pagination={{
                  className: "custom-pagination",
                  defaultCurrent: 1,
                  pageSize: 10,
                  total: bulkUploadData.length,
                  onChange: getPagination,
                  itemRender: customPagination,
                }}
              />
            </div>
          )}
        </Spin>
      </Modal>
    </div>
  );
}
