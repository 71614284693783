import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { Table, Tag, Select, Modal, Checkbox } from "antd";

import TagZ from "../components/TagZ";
import TableSortArrows from "../components/tableSortArrows";

import {
  downloadAsCSV,
  getQuery,
  fetchApi,
  convertQueryParams,
  customPagination,
} from "../helper";

import { getClient } from "../store/client/clientSlice";
import { getPostIncorpRequests } from "../store/postIncorpRequests/postIncorpSlice";

export default function MyPortfolio() {
  const dispatch = useDispatch();

  const client = useSelector((state) => state.client);
  const postIncorp = useSelector((state) => state.postIncorp);

  const [resubmitModal, setResubmitModal] = useState(false);
  const [selectedType, setSelectedType] = useState([]);
  const [query, setQuery] = useState({ _start: 0, _limit: 10 });
  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState("");

  const columns = [
    {
      title: "Masterfile Name",
      dataIndex: "name",
      sorter: (a, b) => a.name - b.name,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Client Code",
      dataIndex: "code",
      sorter: (a, b) => a.code - b.code,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Client Type",
      dataIndex: "type",
      sorter: (a, b) => a.type - b.type,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Overview",
      dataIndex: "overview",
      sorter: (a, b) => a.overview - b.overview,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { _id, masterType }) => (
        <Link
          to={`/app/entity-overview/list?type=${masterType}&id=${_id}`}
          className="action-btn icon-btn"
        >
          <i className="fal fa-eye"></i>
        </Link>
      ),
    },
    {
      title: "EntityCount",
      dataIndex: "entityCount",
      render: (_, { _id, entityCount, masterType, name, code }) => (
        <Link
          to={`/app/client-details/?id=${_id}&masterType=${masterType}&name=${name}&code=${code}`}
          className="action-btn icon-btn"
        >
          <i>{entityCount}</i>
        </Link>
      ),
    },
    {
      title: "Composite Risk Level",
      dataIndex: "compositeRiskLevel",
      width: 180,
      sorter: (a, b) => a.compositeRiskLevel - b.compositeRiskLevel,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { id }) => <TagZ status={"approved"} statusText={"Low"} />,
    },
    {
      title: "Renewal Batch",
      dataIndex: "renewalBatch",
      sorter: (a, b) => a.renewalBatch - b.renewalBatch,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Renewal Due Date",
      dataIndex: "renewalDueDate",
      sorter: (a, b) => a.renewalDueDate - b.renewalDueDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "New Task Request",
      dataIndex: "newTaskRequest",
      width: 300,
      sorter: (a, b) => a.newTaskRequest - b.newTaskRequest,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { key }) => (
        <Select
          className="custom-select"
          style={{ width: 250 }}
          placeholder="Select"
          onChange={(value) => getSelectType(value, key)}
          options={postIncorpType}
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      // fixed: "right",
      // width: 100,
      render: (_, { key }) => (
        <Link
          to={`/app/post-incorp?service=${selectedType[key]}`}
          className={`action-btn ${
            !selectedType[key] ? "action-disabled" : ""
          }`}
        >
          Submit
        </Link>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getClient(query));
    dispatch(getPostIncorpRequests(queryParams));
  }, []);

  useEffect(() => {
    handleRefresh();
  }, []);

  useEffect(() => {
    let result = client?.data?.rows?.map((data, index) => {
      return {
        key: index,
        ...data,
      };
    });
    setData(result);
  }, [client?.data]);

  const handleRefresh = async () => {
    let params = {
      ...queryParams,
      isAll: true,
      masterType: "entity",
    };

    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/client?${query}`,
    };

    let data = await fetchApi(payload).then((res) => res?.data);

    setTotalCount(data?.totalCount);
    setTableData(data?.rows);
    setQueryParams(params);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const postIncorpType = [
    {
      value: "COI",
      label: "Certificate of Incumbency (COI)",
    },
    {
      value: "COGS",
      label: "Certificate of Good Standing (COGS)",
    },
    {
      value: "ROD",
      label: "Register of Directors",
    },
    {
      value: "SR",
      label: "Share Registry",
    },
    {
      value: "COD",
      label: "Change Of Director (COD)",
    },
    {
      value: "COSH",
      label: "Change of Shareholder (COSH)",
    },
    {
      value: "COA",
      label: "Change of Client / Administration (COA)",
    },
    {
      value: "COC",
      label: "Change of Contact Point (COC)",
    },
    {
      value: "TO",
      label: "Transfer Out (TO)",
    },
    {
      value: "CO",
      label: "Continuation Out (CO)",
    },
    {
      value: "AMAA",
      label: "Alteration to the Memorandum & Articles of Association",
    },
    {
      value: "ARMA",
      label: "Amended and Restated Memorandum & Articles",
    },
    { value: "Apostilles", label: "Apostilles" },
    {
      value: "CGS",
      label: "Certificate of Good Standing",
    },
    {
      value: "RCAD",
      label: "Registry Certified Copy of Any Document",
    },
    {
      value: "RUD",
      label: "Registry Uncertified or Duplicate Copy of Any Document",
    },
    {
      value: "RPEC",
      label: "Registry Physical Copy of any Electronic Certificate",
    },
    { value: "CI", label: "Certificate of Incumbency" },
    {
      value: "CCPD",
      label: "CCP Certified or Duplicate copies of documents",
    },
    {
      value: "ARD",
      label: "Amendments to Register of Directors",
    },
    {
      value: "ARM",
      label:
        "Amendments to Register of Members & Issuing New Share Certificate(s)",
    },
    { value: "Continuations", label: "Continuations" },
    { value: "DR", label: "Drafting Resolutions" },
    {
      value: "FRMC",
      label: "Filing Register of Mortgage & Charges",
    },
    {
      value: "FAR",
      label: "Filing Appointment of RO/RA",
    },
    { value: "FTO", label: "Filing Transfer Out" },
    { value: "IA", label: "Indemnity Agreements" },
    { value: "Notarizations", label: "Notarizations" },
    { value: "PA", label: "Powers of Attorney" },
    {
      value: "RSC",
      label: "Reactivation of struckoff companies",
    },
    {
      value: "RNR",
      label: "Registration of Notice of Resignation as Registered Agent",
    },
    {
      value: "FRRA",
      label: "Filing notice of Recission as Registered Agent",
    },
    {
      value: "RFC",
      label: "Registration as a Foreign Company in the BVI",
    },
    {
      value: "FNFC",
      label:
        "Filing Notice of Foreign Company Ceasing to Do Business in the BVI",
    },
    {
      value: "EFF",
      label: "Expedite Fee for Fast Track Due Diligence and Incorporation",
    },
  ];

  const getSelectType = (value, key) => {
    let arr = [...selectedType];
    arr[key] = value;
    setSelectedType(arr);
  };

  const previousRequestsColumns = [
    {
      title: "Entity Name",
      dataIndex: "name",
      sorter: (a, b) => a.name - b.name,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Code",
      dataIndex: "code",
      sorter: (a, b) => a.code - b.code,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Status",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Task Type",
      dataIndex: "serviceType",
      sorter: (a, b) => a.serviceType - b.serviceType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Fee",
      dataIndex: "serviceFee",
      sorter: (a, b) => a.serviceFee - b.serviceFee,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Request Date",
      dataIndex: "createdAt",
      width: 180,
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { createdAt }) => {
        return moment(createdAt).format("DD/MM/YYYY - hh:mm A");
      },
    },
    {
      title: "Completion Date",
      dataIndex: "completionDate",
      sorter: (a, b) => a.completionDate - b.completionDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      sorter: (a, b) => a.paymentStatus - b.paymentStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { paymentStatus }) => (
        <TagZ
          status={paymentStatus == "Y" ? "approved" : "in-progress"}
          statusText={paymentStatus == "Y" ? "Paid" : "Pending"}
        />
      ),
    },
  ];

  const getPostPagination = (current, pageSize) => {
    let params = { ...queryParams };
    params._start = (current - 1) * pageSize;
    params._limit = pageSize;
    dispatch(getPostIncorpRequests(params));
    setQueryParams(params);
  };

  return (
    <div>
      <div className="page-header">
        <h1>My Portfolio – Client</h1>
      </div>

      <div className="page-content">
        <button className="primary-btn ms-auto mb-3">Bulk Submit</button>
        <Table
          className="custom-table mb-4"
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={columns}
          dataSource={data}
          scroll={{ x: 2000 }}
        />
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2 className="primary-title mb-0">Previous Requests</h2>
          <button className="primary-btn">Bulk Submit</button>
        </div>
        <Table
          loading={postIncorp?.isLoading}
          className="custom-table"
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={previousRequestsColumns}
          dataSource={postIncorp?.data?.rows}
          pagination={{
            className: "custom-pagination",
            defaultCurrent: 1,
            pageSize: queryParams?._limit,
            total: postIncorp?.data?.totalCount,
            onChange: getPostPagination,
            itemRender: customPagination,
          }}
          // scroll={{ x: 2200 }}
        />
      </div>
    </div>
  );
}
