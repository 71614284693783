import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Checkbox, Spin } from "antd";

import SampleDoc from "../assets/pdf/sample.pdf";

import UploadZ from "../components/UploadZ";

import { Each, fetchApi, getQuery } from "../helper";

const document = [
  {
    url: SampleDoc,
    file: "Certificate of Incumbency",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: true,
  },
  {
    url: SampleDoc,
    file: "M&AA",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
  {
    url: SampleDoc,
    file: "Certificate of Good Standing",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
];

export default function ApostilleOfDocuments() {
  const [docs, setDocs] = useState(document);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  let query = getQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  function getData() {
    setLoading(true);
    let payload = {
      method: "get",
      url: `/serviceRequest?id=${query?.id}`,
    };

    fetchApi(payload).then((res) => {
      setData(res?.data?.rows?.[0]);
      setLoading(false);
    });
  }

  return (
    <div>
      <div className="page-header">
        <Link to={"/app/active-requests"}>
          <h1 className="d-flex align-items-center gap-2">
            <i className="fal fa-long-arrow-left"></i> Apostille of Documents
          </h1>
        </Link>
        <div className="d-flex gap-3">
          <Link className="outline-btn" to={"/app/mail"}>
            Check Mailbox
          </Link>
          <button className="primary-btn" disabled={true}>
            Send to Client
          </button>
          <button className="primary-btn">Submit</button>
        </div>
      </div>
      <div className="page-content">
        <Spin spinning={loading}>
          <div className="custom-form row gy-4 mb-5">
            <div className="col-md-3">
              <label htmlFor="">Entity Code</label>
              <span className="light-badge">{data?.entityCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Entity Name</label>
              <span className="light-badge">{data?.entityName ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Client Code</label>
              <span className="light-badge">{data?.clientCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Client Name</label>
              <span className="light-badge">{data?.clientCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Documents to be Apostille</label>
              <div className="d-flex gap-2 flex-wrap">
                {data?.documentType?.length > 0 ? (
                  <Each
                    array={data?.documentType}
                    render={(doc) => {
                      return <span className="light-badge">{doc}</span>;
                    }}
                  />
                ) : (
                  <span className="light-badge">N/A</span>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Receive as</label>
              <span className="light-badge">{data?.receiveAs ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Special Notes</label>
              <span className="light-badge">{data?.note ?? "N/A"}</span>
            </div>
          </div>
          <h2 className="form-title mb-4">Apostille Documents</h2>
          <div className="row gy-2 gx-4">
            <Each
              array={docs}
              render={(item, index) => (
                <div className="col-md-3">
                  <div>
                    <Checkbox className="custom-checkbox">
                      {item?.file}
                    </Checkbox>
                    <UploadZ file={item} />
                  </div>
                </div>
              )}
            />
          </div>
        </Spin>
      </div>
    </div>
  );
}
