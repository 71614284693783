import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";

import ContactSupport from "../../components/ContactSupport";

export default function VerificationCode() {
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [contactModal, setContactModal] = useState(false);

  const initialTime = 4 * 60;
  const [timeRemaining, setTimeRemaining] = useState(initialTime);

  useEffect(() => {
    startTimer();
  }, []);

  const minutes = Math.floor((timeRemaining % 3600) / 60);
  const seconds = timeRemaining % 60;

  const startTimer = () => {
    const timerInterval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timerInterval);
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  };

  const resetTimer = () => {
    setTimeRemaining(initialTime);
    startTimer();
  };

  function zeroPad(time) {
    if (time < 10) {
      return `0${time}`;
    } else {
      return time;
    }
  }

  const handleOk = () => {
    setContactModal(false);
  };

  const handleCancel = () => {
    setContactModal(false);
  };

  return (
    <div className="auth-form">
      <p className="auth-note-text mb-4">
        Please enter the 6-digit code sent to john@northlark.com and your phone
        number <span>+94&nbsp;(767)&nbsp;xxx-7890</span>
      </p>
      <div className="input-box mb-4">
        <label htmlFor="">Code</label>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          containerStyle="auth-otp-wrapper"
          renderInput={(props) => (
            <input {...props} placeholder="0" type="number" />
          )}
        />
      </div>
      {minutes + seconds <= 0 ? (
        <p className="auth-timer mb-5">
          Didn’t receive the code?&nbsp;
          <a onClick={resetTimer}>Resend</a>
        </p>
      ) : (
        <p className="auth-timer mb-5">
          Resend code in&nbsp;
          <b>
            {zeroPad(minutes)}:{zeroPad(seconds)}
          </b>
        </p>
      )}
      <button
        className={`primary-btn auth-btn mb-3 ${loading ? "btn-loading" : ""}`}
      >
        {loading ? <i className="far fa-spinner-third fa-spin"></i> : ""}
        Submit
      </button>
      <button className="secondary-btn auth-btn">Back to Log In</button>
      <ContactSupport
        open={contactModal}
        onOk={handleOk}
        onCancel={handleCancel}
      />
    </div>
  );
}
