import React, { useState } from "react";
import { Modal, Checkbox, Table, Select } from "antd";
import { Link } from "react-router-dom";

import PDFIcon from "../assets/img/pdf-icon.svg";

import SampleDoc from "../assets/pdf/sample.pdf";

import { downloadFile } from "../helper";

import TableSortArrows from "../components/tableSortArrows";

export default function IncorporationConfirmation() {
  const [docModal, setDocModal] = useState(false);
  const [viewDocName, setViewDocName] = useState("");

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const viewDocument = (docName) => {
    setViewDocName(docName);
    setDocModal(true);
  };

  const handleOk = () => {
    setDocModal(false);
    setViewDocName("");
  };
  const handleCancel = () => {
    setDocModal(false);
    setViewDocName("");
  };

  const DownloadDoc = () => {
    downloadFile(SampleDoc, "sample_doc.pdf");
  };

  const columns = [
    {
      title: "Entity Name",
      dataIndex: "entityName",
      sorter: (a, b) => a.entityName - b.entityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Type",
      dataIndex: "entityType",
      sorter: (a, b) => a.entityType - b.entityType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      sorter: (a, b) => a.entityCode - b.entityCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp status",
      dataIndex: "incorpStatus",
      sorter: (a, b) => a.incorpStatus - b.incorpStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <Select
          className="custom-select"
          placeholder="Select type"
          value={status}
          style={{
            width: 160,
          }}
          options={[
            {
              value: "approved",
              label: "Approved",
            },
            {
              value: "rejected",
              label: "Rejected",
            },
          ]}
        />
      ),
    },
    {
      title: "RM Name",
      dataIndex: "rmName",
      sorter: (a, b) => a.rmName - b.rmName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Client Code",
      dataIndex: "clientCode",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Client Type",
      dataIndex: "clientType",
      sorter: (a, b) => a.clientType - b.clientType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Reviewed By",
      dataIndex: "reviewedBy",
      sorter: (a, b) => a.reviewedBy - b.reviewedBy,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Approved by",
      dataIndex: "approvedBy",
      sorter: (a, b) => a.approvedBy - b.approvedBy,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Upload RAF",
      render: (_, data) => (
        <div className="table-upload">
          <label htmlFor="doc" className="outline-btn py-1">
            Upload
          </label>
          <input type="file" name="doc" id="doc" />
        </div>
      ),
    },
    {
      title: "Final Pack",
      render: (_, data) => <button className="action-btn">View</button>,
    },
  ];

  const data = [
    {
      key: "1",
      id: "1",
      entityName: "Sample",
      entityType: "Trust",
      entityCode: "9023",
      rmName: "Sample",
      clientName: "COI",
      clientCode: "9034",
      clientType: "Introducer Client",
      reviewedBy: "Hari",
      approvedBy: "Guru",
    },
    {
      key: "2",
      id: "2",
      entityName: "Sample",
      entityType: "Trust",
      entityCode: "9023",
      rmName: "Sample",
      clientName: "COI",
      clientCode: "9034",
      clientType: "Introducer Client",
      reviewedBy: "Hari",
      approvedBy: "Guru",
    },
    {
      key: "3",
      id: "3",
      entityName: "Sample",
      entityType: "Trust",
      entityCode: "9023",
      rmName: "Sample",
      clientName: "COI",
      clientCode: "9034",
      clientType: "Introducer Client",
      reviewedBy: "Hari",
      approvedBy: "Guru",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div>
      <div className="page-header">
        <h1>Incorp Request - Registry</h1>
        <div className="d-flex gap-3 flex-column flex-md-row">
          <button className="outline-btn">Export</button>
          <Link to={"/app/incorporation-feedback"} className="primary-btn">
            Complete and Submit
          </Link>
        </div>
      </div>
      <div className="page-content">
        <Table
          className="custom-table"
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: 1800 }}
        />
        {/* <h6 className='primary-title my-4'>Confirmation of Incorporation</h6> */}
        {/* <div className="">
                <div className="row mb-4">
                    <div className="col-md-3">
                        <Checkbox className='custom-checkbox mb-3'>
                            Final Pack
                        </Checkbox>
                        <div className="uploader">
                            <div className="uploaded-file">
                                <img className='file-icon' src={PDFIcon} alt="" />
                                <div className='ms-2'>
                                    <h6>sample_document.pdf</h6>
                                    <p>200 KB</p>
                                </div>
                            </div>
                            <div className="row g-2">
                                <div className="col-8">
                                    <button className='view-btn w-100' onClick={() => viewDocument('Inaugural Resolution')}><i className="far fa-eye"></i> View</button>
                                </div>
                                <div className="col-2">
                                    <button className='download-btn w-100' title='Export'><i className="far fa-share-square"></i></button>
                                </div>
                                <div className="col-2">
                                    <button className='download-btn' onClick={DownloadDoc}><i className="far fa-arrow-to-bottom"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        <Link
          to={"/app/new-incorporation"}
          className="primary-btn mt-4 mt-md-2"
        >
          <i className="fal fa-plus"></i> New Request
        </Link>

        <Modal
          title={viewDocName}
          className="custom-modal"
          open={docModal}
          width={1000}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="document-view">
            <iframe
              src={SampleDoc}
              frameBorder="0"
              height="100%"
              width="100%"
            ></iframe>
          </div>
        </Modal>
      </div>
    </div>
  );
}
