import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Checkbox } from "antd";

import SampleDoc from "../assets/pdf/sample.pdf";

import UploadZ from "../components/UploadZ";

import { Each } from "../helper";

const document = [
  {
    url: SampleDoc,
    file: "Resolution of Alteration to M&AA",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: true,
  },
  {
    url: SampleDoc,
    file: "M&AA",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
  {
    url: SampleDoc,
    file: "Other",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
];

export default function AlterationToMAA() {
  const [docs, setDocs] = useState(document);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChangeApproval = (file, bool) => {
    let arr = [...docs];
    let index = arr.findIndex((doc) => doc.file == file);
    arr[index].isApproved = bool;
    setDocs(arr);
  };

  return (
    <div>
      <div className="page-header">
        <Link to={"/app/active-requests"}>
          <h1 className="d-flex align-items-center gap-2">
            <i className="fal fa-long-arrow-left"></i> Change Of Director
          </h1>
        </Link>
        <div className="d-flex gap-3">
          <button className="secondary-btn">Request Resubmission</button>
          <button className="outline-btn">Check Mailbox</button>
          <button className="primary-btn" disabled={true}>
            Send to Client
          </button>
          <button className="primary-btn">Submit</button>
        </div>
      </div>
      <div className="page-content">
        <div className="custom-form row gy-4 mb-5">
          <div className="col-md-3">
            <label htmlFor="">Entity Code</label>
            <span className="light-badge">38202</span>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Entity Name</label>
            <span className="light-badge">Sample Entity</span>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Client Code</label>
            <span className="light-badge">38202</span>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Client Name</label>
            <span className="light-badge">Sample Client</span>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Type of Alteration</label>
            <div className="d-flex gap-2">
              <span className="light-badge">Add a clause</span>
              <span className="light-badge">Restate the entire M&AA</span>
            </div>
          </div>
        </div>
        <div className="row gy-2 gx-4">
          <Each
            array={docs}
            render={(item, index) => (
              <div className="col-md-3">
                <div>
                  <Checkbox className="custom-checkbox">{item?.file}</Checkbox>
                  <UploadZ
                    file={item}
                    downloadOnly={true}
                    showApprove={true}
                    onFileApprove={onChangeApproval}
                    showTranslation={true}
                    showExtraction={true}
                  />
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
}
