import React, { useEffect, useState, useRef } from "react";
import { Table, Select, Popover } from "antd";
import { useSelector, useDispatch } from "react-redux";
import TableSortArrows from "../components/tableSortArrows";
import { fetchApi, sendNotify } from "../helper";
import { getMasterSetup } from "../store/masterSetup/masterSetupSlice";

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export default function Reminders() {
  const formRef = useRef();

  const masterSetup = useSelector((state) => state.masterSetup);
  const [data1, setData1] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    let ctr = {};
    dispatch(getMasterSetup(ctr));
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [masterSetup]);

  const column1 = [
    {
      title: "Risk Level",
      dataIndex: "riskLevel",
      render: (_, { _id, riskLevel }) => (
        <Select
          className="custom-select w-100px ptb-1"
          defaultValue={riskLevel || "Medium"}
          onChange={(value) => {
            if (!formRef.current) formRef.current = {};
            if (!formRef.current[_id]) formRef.current[_id] = {};
            formRef.current[_id] = {
              ...formRef.current[_id],
              riskLevel: value,
            };
          }}
          disabled={true}
          options={[
            { value: "High", label: "High" },
            { value: "Medium", label: "Medium" },
            { value: "Low", label: "Low" },
          ]}
        />
      ),
    },
    {
      title: "Risk Review Frequency",
      dataIndex: ["riskReview", "riskDuration"],
      render: (_, { _id, riskReview, riskDuration }) => (
        <div className="custom-form">
          <input
            type="number"
            className="w-80px"
            defaultValue={riskReview || 1}
            min={1}
            placeholder="Enter count"
            onChange={(e) => {
              if (!formRef.current) formRef.current = {};
              if (!formRef.current[_id]) formRef.current[_id] = {};
              formRef.current[_id] = {
                ...formRef.current[_id],
                riskReview: parseInt(e.target.value) || 1,
              };
            }}
          />
          &nbsp;
          <Select
            className="custom-select w-100px ptb-1"
            placeholder="Select duration"
            defaultValue={riskDuration || "months"}
            onChange={(value) => {
              if (!formRef.current) formRef.current = {};
              if (!formRef.current[_id]) formRef.current[_id] = {};
              formRef.current[_id] = {
                ...formRef.current[_id],
                riskDuration: value,
              };
            }}
            options={[
              { value: "days", label: "Days" },
              { value: "week", label: "Week" },
              { value: "months", label: "Months" },
              { value: "years", label: "Years" },
            ]}
          />
        </div>
      ),
    },
    {
      title: "Reminder Count",
      dataIndex: "reminderCount",
      render: (_, { _id, reminderCount }) => (
        <div className="custom-form">
          <input
            type="number"
            className="w-80px"
            defaultValue={reminderCount || 1}
            placeholder="Enter count"
            min={1}
            onChange={(e) => {
              if (!formRef.current) formRef.current = {};
              if (!formRef.current[_id]) formRef.current[_id] = {};
              formRef.current[_id] = {
                ...formRef.current[_id],
                reminderCount: parseInt(e.target.value) || 1,
              };
            }}
          />
        </div>
      ),
    },
    {
      title: "Reminder Starts From",
      dataIndex: "reminderStartsFrom",
      render: (_, { _id, reminderStartsFrom, onwards }) => (
        <div className="custom-form">
          <input
            type="number"
            className="w-80px"
            step="0.1"
            defaultValue={reminderStartsFrom || 1}
            placeholder="Enter count"
            min={1}
            onChange={(e) => {
              if (!formRef.current) formRef.current = {};
              if (!formRef.current[_id]) formRef.current[_id] = {};
              formRef.current[_id] = {
                ...formRef.current[_id],
                reminderStartsFrom: parseFloat(e.target.value) || 1,
              };
            }}
          />
          &nbsp;
          <Select
            className="custom-select w-100px ptb-1"
            placeholder="Select"
            defaultValue={onwards || "months"}
            onChange={(value) => {
              if (!formRef.current) formRef.current = {};
              if (!formRef.current[_id]) formRef.current[_id] = {};
              formRef.current[_id] = {
                ...formRef.current[_id],
                onwards: value,
              };
            }}
            options={[
              { value: "days", label: "Days" },
              { value: "week", label: "Week" },
              { value: "months", label: "Months" },
              { value: "years", label: "Years" },
            ]}
          />
        </div>
      ),
    },
    {
      title: "Recurring Time",
      dataIndex: "recurringTime",
      render: (_, { _id, recurringTime }) => (
        <div className="custom-form">
          <input
            type="number"
            className="w-80px"
            defaultValue={recurringTime || 1}
            placeholder="Enter count"
            min={1}
            onChange={(e) => {
              if (!formRef.current) formRef.current = {};
              if (!formRef.current[_id]) formRef.current[_id] = {};
              formRef.current[_id] = {
                ...formRef.current[_id],
                recurringTime: parseInt(e?.target?.value) || 1,
              };
            }}
          />
        </div>
      ),
    },
    {
      title: "Reminder Frequency",
      dataIndex: "reminderFrequency",
      render: (_, { _id, reminderFrequency }) => (
        <Select
          className="custom-select w-150px ptb-1"
          defaultValue={reminderFrequency || "Days"}
          onChange={(value) => {
            if (!formRef.current) formRef.current = {};
            if (!formRef.current[_id]) formRef.current[_id] = {};
            formRef.current[_id] = {
              ...formRef.current[_id],
              reminderFrequency: value,
            };
          }}
          options={[
            { value: "Days", label: "Days" },
            { value: "Week", label: "Week" },
            { value: "Months", label: "Months" },
            { value: "Years", label: "Years" },
          ]}
        />
      ),
    },
  ];

  const column2 = [
    {
      title: "Filing Type",
      dataIndex: "filingType",
      sorter: (a, b) => a.filingType.localeCompare(b.filingType),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { filingType, action }) => (
        <Popover content={action}>
          <span>{filingType}</span>
        </Popover>
      ),
    },

    {
      title: "Deadline",
      dataIndex: "deadline",
      sorter: (a, b) => a.deadline.localeCompare(b.deadline),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { deadline, deadlineDis }) => (
        <div className="custom-form d-flex gap-3 align-items-center">
          <input
            type="text"
            defaultValue={deadline}
            placeholder="Enter deadline"
            style={{ width: 55 }}
          />
          <span>{deadlineDis}</span>
        </div>
      ),
    },
    {
      title: "Reminder Count",
      dataIndex: "reminderCount",
      sorter: (a, b) => a.reminderCount.localeCompare(b.reminderCount),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Reminder Starts After",
      dataIndex: "reminderStartsAfter",
      sorter: (a, b) =>
        a.reminderStartsAfter.localeCompare(b.reminderStartsAfter),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Recurring Time",
      dataIndex: "recurringTime",
      sorter: (a, b) => a.recurringTime.localeCompare(b.recurringTime),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Reminder Frequency",
      dataIndex: "reminderFrequency",
      sorter: (a, b) => a.reminderFrequency.localeCompare(b.reminderFrequency),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { reminderFrequency }) => (
        <Select
          className="custom-select w-150px ptb-1"
          placeholder="Select risk level"
          defaultValue={reminderFrequency}
          options={[
            {
              value: "Days",
              label: "Days",
            },
            {
              value: "Week",
              label: "Week",
            },
            {
              value: "Months",
              label: "Months",
            },
            {
              value: "Years",
              label: "Years",
            },
          ]}
        />
      ),
    },
    {
      title: "Default Recipients",
      dataIndex: "defaultRecipients",
      sorter: (a, b) => a.defaultRecipients.localeCompare(b.defaultRecipients),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  const handleRefresh = async () => {
    if (masterSetup?.data?.totalCount) {
      setData1(masterSetup?.data?.rows);
    } else {
      handleInsetDate();
    }
  };
  const handleInsetDate = async () => {
    console.log(masterSetup, "ASDFGHJKL;");
    if (!masterSetup?.data?.totalCount) {
      const filingSetup = [
        {
          riskLevel: "High",
          riskDuration: "months",
          riskReview: 6,
          onwards: "months",
          reminderCount: 3,
          reminderStartsFrom: 4.5,
          recurringTime: 15,
          reminderFrequency: "Days",
          position: 1,
        },
        {
          riskLevel: "Medium",
          riskDuration: "years",
          riskReview: 4,
          reminderCount: 3,
          onwards: "months",
          reminderStartsFrom: 21,
          recurringTime: 1,
          reminderFrequency: "Months",
          position: 2,
        },
        {
          riskLevel: "Low",
          riskDuration: "years",
          riskReview: 5,
          reminderCount: 3,
          onwards: "months",
          reminderStartsFrom: 9,
          recurringTime: 1,
          reminderFrequency: "Years",
          position: 3,
        },
      ];
      await asyncForEach(filingSetup, async (oneDoc) => {
        let obj = oneDoc;
        let payload = {
          method: "POST",
          url: `/masterSetup/filingSetup`,
          data: obj,
        };
        fetchApi(payload)
          .then((res) => {})
          .catch((error) => {});
      });
    }
  };
  const data2 = [
    {
      key: 1,
      filingType: "ROD",
      action:
        "Every time a change in director happens (New Appointment, Resignation) \n The updated ROD should be sent to the business partner team for filing on the same day change happens with the most up to date data in a table format.",
      deadline: "30",
      deadlineDis: "days from date of change",
      reminderCount: 2,
      reminderStartsAfter: "5/10/15/20 Days of change in directorship",
      recurringTime: 1,
      reminderFrequency: "Week",
      defaultRecipients: "Business Partner authorized individual",
    },
    {
      key: 2,
      filingType: "Shareholder",
      action:
        "Every time a change in shareholders happen (New allocation, Transfer, Redemption). The updated detailed history of Entity to be sent to the business partner team for filing on the same day of change.",
      deadline: "60",
      deadlineDis: "days from date of change",
      reminderCount: 2,
      reminderStartsAfter: "15/30/45 Days of change in shareholding",
      recurringTime: 1,
      reminderFrequency: "Week",
      defaultRecipients: "Business Partner authorized individual",
    },
    {
      key: 3,
      filingType: "Annual Return",
      action:
        "To be activated post update of Year End Date. System to generate email reminder.",
      deadline: `9`,
      deadlineDis: `months from "Year End Date"`,
      reminderCount: "3, 6, 9",
      reminderStartsAfter: "3/6/8 months from Year End Date",
      recurringTime: "1 to 2",
      reminderFrequency: "Month",
      defaultRecipients:
        "Business Partner authorized individual AND Entity point/s of contact",
    },
    {
      key: 4,
      filingType: "Compliance Filing / Periodic Review",
      action:
        "Based on periodic review timelines per risk category (High, Low, Medium)",
      deadline: "3",
      deadlineDis: "Next review date of entity based on risk level",
      reminderCount: "Please refer risk table below",
      reminderStartsAfter: "Please refer risk table below",
      recurringTime: "Please refer risk table below",
      reminderFrequency: "Please refer risk table below",
      defaultRecipients: "MLRO/Manager",
    },
    {
      key: 5,
      filingType: "BOSS/ES",
      action: "TBC",
      deadline: "TBC",
      reminderCount: "TBC",
      reminderStartsAfter: "TBC",
      recurringTime: "TBC",
      reminderFrequency: "TBC",
      defaultRecipients: "Business Partner authorized individual",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const handleSubmit = () => {
    console.log("Form Values:", formRef.current);

    let obj = {
      // ids: selectedCatIds,
      // isDelete: true,
    };
    let payload = {
      method: "PUT",
      url: `/masterSetup/filingSetup/update`,
      data: formRef.current,
    };
    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message ? res?.message : "success");
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };
  return (
    <div>
      <div className="page-header">
        <h1>Master Setup</h1>
      </div>
      <div className="page-content">
        <div className="d-flex justify-content-between flex-column flex-md-row align-items-md-center align-items-start mb-3">
          <h6 className="primary-title mb-3 mb-md-0">Filing Setup - Master</h6>
          <button className="primary-btn" onClick={handleSubmit}>
            Save Changes
          </button>
        </div>

        <Table
          className="custom-table mb-3"
          columns={column1}
          dataSource={data1}
          rowSelection={{ type: "checkbox", ...rowSelection }}
          pagination={false}
        />

        <div className="d-flex justify-content-between flex-column flex-md-row align-items-md-center align-items-start mb-3">
          <h6 className="primary-title mb-3 mb-md-0">Table 2</h6>
          <button className="primary-btn">Save Changes</button>
        </div>
        <Table
          className="custom-table mb-3"
          columns={column2}
          dataSource={data2}
          scroll={{ x: 2000 }}
          rowSelection={{ type: "checkbox", ...rowSelection }}
          pagination={false}
        />
      </div>
    </div>
  );
}
