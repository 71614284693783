import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Modal, Select, Table, Tag } from "antd";
import dayjs from "dayjs";

import FormZ from "../../components/FormZ";
import TableSortArrows from "../../components/tableSortArrows";

import { fetchApi, sendNotify } from "../../helper";

export default function OnboardingTeam() {
  let { taskId } = useParams();

  const proposedFormRef = useRef();
  const availabilityFormRef = useRef();

  const [taskData, setTaskData] = useState(null);
  const [rejectModal, setRejectModal] = useState(false);
  const [optionStatus, setOptionStatus] = useState({});

  useEffect(() => {
    getTaskData(taskId);
  }, []);

  const getTaskData = (id) => {
    let payload = {
      method: "get",
      url: `/incorporation?_id=${id}`,
    };
    fetchApi(payload)
      .then((res) => {
        let data = res?.data?.rows[0];
        setTaskData(data);
        let options = {};
        Object.keys(data?.option).map((key, index) => {
          options[key] = data?.option[key]?.isValid;
        });
        // console.log(options);
        setOptionStatus(options);
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };

  let proposedFormSchema = [
    {
      name: "option1",
      value: "",
      type: "text",
      label: "Option 01",
      placeholder: "Enter Name",
    },
    {
      name: "option2",
      value: "",
      type: "text",
      label: "Option 02",
      placeholder: "Enter Name",
    },
    {
      name: "option3",
      value: "",
      type: "text",
      label: "Option 03",
      placeholder: "Enter Name",
    },
  ];

  let availabilityFormSchema = [
    {
      name: "option1",
      value: "",
      type: "text",
      label: "Option 01",
      placeholder: "Enter Name",
    },
    {
      name: "option2",
      value: "",
      type: "text",
      label: "Option 02",
      placeholder: "Enter Name",
    },
    {
      name: "option3",
      value: "",
      type: "text",
      label: "Option 03",
      placeholder: "Enter Name",
    },
  ];

  const columns = [
    {
      title: "Requestor Ref",
      dataIndex: "ref",
      sorter: (a, b) => a.ref - b.ref,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Request Date",
      dataIndex: "date",
      sorter: (a, b) => a.date - b.date,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Service Fee Category",
      dataIndex: "serviceFee",
      sorter: (a, b) => a.date - b.date,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Type",
      dataIndex: "entityType",
      sorter: (a, b) => a.entityType - b.entityType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  const data = [
    {
      key: "1",
      id: "1",
      ref: "John Doe",
      date: "20/10/2023",
      serviceFee: "OCR",
      entityType: "Trust",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const showRejectModal = () => {
    setRejectModal(true);
  };
  const handleOk = () => {
    setRejectModal(false);
  };
  const handleCancel = () => {
    setRejectModal(false);
  };

  const sendApprovalMail = (type) => {
    let payload = {
      method: "GET",
      url: `/nameCheck/${type}/${taskId}`,
    };
    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message);
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };

  const getOptionStatus = (key, value) => {
    let obj = { ...optionStatus };
    obj[key] = value;
    console.log(obj);
    setOptionStatus(obj);
  };

  const confirmAvailability = () => {
    let payload = {
      method: "PUT",
      url: `/incorporation/availability/${taskId}`,
      data: optionStatus,
    };
    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message);
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };

  const checkNameValidity = (type) => {
    let payload = {
      method: "PUT",
      url: `incorporation/${type}/${taskId}`,
      data: { confirmation: true },
    };
    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message);
        getTaskData(taskId);
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };

  return (
    <div>
      <div className="page-header">
        <h1>New Incorporation Name Check</h1>
      </div>
      <div className="page-content">
        <h2 className="primary-title mb-4">Name Check Requests</h2>
        {/* <Table className='custom-table' rowSelection={{ type: 'checkbox', ...rowSelection }} columns={columns} dataSource={data} /> */}

        <div className="d-flex gap-md-5 flex-column flex-md-row">
          <p className="head-label">
            <b>Requestor Ref:</b> {taskData?.requestedBy}
          </p>
          <p className="head-label">
            <b>Request Date:</b>{" "}
            {dayjs(taskData?.createdAt).format("DD/MM/YYYY - hh:mm A")}
          </p>
          <p className="head-label">
            <b>Service Fee Category:</b> OCR
          </p>
          <p className="head-label">
            <b>Entity Type:</b> Trust
          </p>
        </div>

        <div className="custom-form">
          <h2 className="sub-title my-4">Proposed Name</h2>
          <FormZ
            formSchema={proposedFormSchema}
            ref={proposedFormRef}
            formData={{
              option1: taskData?.option?.op_1?.name,
              option2: taskData?.option?.op_2?.name,
              option3: taskData?.option?.op_3?.name,
            }}
            formClass="col-md-9"
            childClass="form-row mb-4"
            labelClass="me-3 mb-0"
            inputClass="w-100"
          />
          <div className="d-flex gap-3 flex-column flex-md-row">
            <button
              className="primary-btn"
              disabled={
                taskData?.nameCheck?.approval
                  ? taskData?.nameCheck?.approval
                  : false
              }
              onClick={() => sendApprovalMail("sendNotify")}
            >
              Name Check Approval
            </button>
            <button
              className="outline-btn"
              disabled={taskData?.nameCheck?.confirmation ?? false}
              onClick={() => checkNameValidity("reservation/confirmation")}
            >
              Name Approval Confirmation
            </button>
          </div>

          <div className="row">
            <div className="col-md-9">
              <h2 className="primary-title mt-5 mb-4">Name Availability</h2>
              <div className="form-row gap-3 mb-4">
                <label htmlFor="" className="mb-0">
                  Option 1
                </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  value={taskData?.option?.op_1?.name}
                />
                <Select
                  className="custom-select w-25"
                  placeholder="Select"
                  value={optionStatus?.op_1 ?? null}
                  disabled={taskData?.status == "Submitted"}
                  onChange={(value) => getOptionStatus("op_1", value)}
                  options={[
                    {
                      value: true,
                      label: "Available",
                    },
                    {
                      value: false,
                      label: "Rejected",
                    },
                  ]}
                />
              </div>
              <div className="form-row gap-3 mb-4">
                <label htmlFor="" className="mb-0">
                  Option 2
                </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  value={taskData?.option?.op_2?.name}
                />
                <Select
                  className="custom-select w-25"
                  placeholder="Select"
                  value={optionStatus.op_2 ?? null}
                  disabled={taskData?.status == "Submitted"}
                  onChange={(value) => getOptionStatus("op_2", value)}
                  options={[
                    {
                      value: true,
                      label: "Available",
                    },
                    {
                      value: false,
                      label: "Rejected",
                    },
                  ]}
                />
              </div>
              <div className="form-row gap-3 mb-4">
                <label htmlFor="" className="mb-0">
                  Option 3
                </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  value={taskData?.option?.op_3?.name}
                />
                <Select
                  className="custom-select w-25"
                  placeholder="Select"
                  value={optionStatus.op_3 ?? null}
                  disabled={taskData?.status == "Submitted"}
                  onChange={(value) => getOptionStatus("op_3", value)}
                  options={[
                    {
                      value: true,
                      label: "Available",
                    },
                    {
                      value: false,
                      label: "Rejected",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="col-md-3">
              <h2 className="primary-title mt-5 mb-4">Reservation Type</h2>
              <div className="form-row gap-3 mb-4">
                <Select
                  className="custom-select w-75"
                  disabled={true}
                  placeholder="Select"
                  value={taskData?.option?.op_1?.reservationType}
                  options={[
                    {
                      value: "O",
                      label: "Ordinary Reservation",
                    },
                    {
                      value: "P",
                      label: "Paid Reservation",
                    },
                  ]}
                />
              </div>
              <div className="form-row gap-3 mb-4">
                <Select
                  className="custom-select w-75"
                  disabled={true}
                  placeholder="Select"
                  value={taskData?.option?.op_2?.reservationType}
                  options={[
                    {
                      value: "O",
                      label: "Ordinary Reservation",
                    },
                    {
                      value: "P",
                      label: "Paid Reservation",
                    },
                  ]}
                />
              </div>
              <div className="form-row gap-3 mb-4">
                <Select
                  className="custom-select w-75"
                  disabled={true}
                  placeholder="Select"
                  value={taskData?.option?.op_3?.reservationType}
                  options={[
                    {
                      value: "O",
                      label: "Ordinary Reservation",
                    },
                    {
                      value: "P",
                      label: "Paid Reservation",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="d-flex gap-3">
            <button
              onClick={confirmAvailability}
              className="primary-btn"
              disabled={
                !(Object.keys(optionStatus).length > 2) ||
                taskData?.status == "Submitted"
              }
            >
              Confirm Availability
            </button>
            {/* <button className="outline-btn red-btn" onClick={showRejectModal}>
            Reject
          </button> */}
            <button
              className="outline-btn"
              disabled={
                taskData?.availability?.reservation
                  ? taskData?.availability?.reservation
                  : false
              }
              onClick={() => sendApprovalMail("sendReservation")}
            >
              Name Reservation
            </button>
            <button
              className="outline-btn"
              disabled={
                taskData?.availability?.confirmation
                  ? taskData?.availability?.confirmation
                  : false
              }
              onClick={() => checkNameValidity("availability/confirmation")}
            >
              Reservation Confirmation
            </button>
          </div>
        </div>
      </div>
      <Modal
        title={"Reject"}
        className="custom-modal"
        open={rejectModal}
        onOk={handleOk}
        okText="Reject"
        onCancel={handleCancel}
      >
        <div className="custom-form">
          <label htmlFor="" className="me-3">
            Reason to Reject
          </label>
          <textarea name="" id="" rows="5" className="w-100"></textarea>
        </div>
      </Modal>
    </div>
  );
}
