import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Modal } from "antd";
import { jwtDecode } from "jwt-decode";
// import Logo from "../../assets/img/logo.png";
import FormZ from "../../components/FormZ";
import { sendNotify, fetchApi } from "../../helper";
import { checkLogged } from "../../store/auth/authSlice";
const { success, confirm } = Modal;
export default function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef();

  const [customError, setCustomError] = useState({});
  const [usernameLoading, setUsernameLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const [passValid, setPassValid] = useState({});
  const query = new URLSearchParams(useLocation().search);
  const [timeLeft, setTimeLeft] = useState({});
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const token = query.get("token");

  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUserData(decoded);
        settokandata(decoded);
        calculateTimeLeft(decoded);

        const timer = setInterval(() => {
          calculateTimeLeft(decoded);
        }, 1000);

        return () => clearInterval(timer);
      } catch (error) {
        console.log("Invalid token");
      }
    }
  }, [token]);

  const calculateTimeLeft = (decoded) => {
    if (decoded) {
      const now = new Date().getTime();
      const expirationTime = decoded.exp * 1000;
      const distance = expirationTime - now;
      let timeLeft = {};

      if (distance > 0) {
        timeLeft = {
          days: Math.floor(distance / (1000 * 60 * 60 * 24)),
          hours: Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ),
          minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((distance % (1000 * 60)) / 1000),
        };
      } else {
        timeLeft = { expired: true };
      }

      setTimeLeft(timeLeft);
    }
  };
  const settokandata = (decoded) => {
    let dataObj = { ...formData };
    dataObj.email = decoded.email;
    dataObj.username = decoded.userName;
    setFormData(dataObj);
  };
  const formatTimeLeft = (timeLeft) => {
    if (
      timeLeft.days === 0 &&
      timeLeft.hours === 0 &&
      timeLeft.minutes === 0 &&
      timeLeft.seconds === 0
    ) {
      return "Expired";
    }

    let timeString = "";
    if (timeLeft.days > 0) {
      timeString += `${timeLeft.days}d `;
    }
    if (timeLeft.hours > 0 || timeLeft.days > 0) {
      timeString += `${timeLeft.hours}h `;
    }
    if (timeLeft.minutes > 0 || timeLeft.hours > 0 || timeLeft.days > 0) {
      timeString += `${timeLeft.minutes}m `;
    }
    if (
      timeLeft.seconds >= 0 ||
      timeLeft.minutes > 0 ||
      timeLeft.hours > 0 ||
      timeLeft.days > 0
    ) {
      timeString += `${timeLeft.seconds}s`;
    }

    return timeString.trim();
  };

  let formSchema = [
    {
      name: "email",
      value: "",
      type: "email",
      label: "Email",
      placeholder: "Enter email",
      disabled: true,
    },
    {
      name: "username",
      value: "",
      type: "text",
      label: "Username",
      placeholder: "Enter username",
      disabled: true,
    },
    {
      name: "password",
      value: "",
      type: "password",
      label: "Password",
      placeholder: "Enter password",
      required: true,
      autoComplete: false,
      validation: true,
    },
    {
      customElement: (
        <ul
          className={`pass-validation ${formData["password"] ? "pass-in" : ""}`}
        >
          <li className={passValid["length"] ? "valid" : "not-valid"}>
            <i
              className={`fas ${
                passValid["length"] ? "fa-check-circle" : "fa-times-circle"
              }`}
            ></i>
            Password is more than 8 characters
          </li>
          <li className={passValid["upper"] ? "valid" : "not-valid"}>
            <i
              className={`fas ${
                passValid["upper"] ? "fa-check-circle" : "fa-times-circle"
              }`}
            ></i>
            Upper and lowercase letters
          </li>
          <li className={passValid["number"] ? "valid" : "not-valid"}>
            <i
              className={`fas ${
                passValid["number"] ? "fa-check-circle" : "fa-times-circle"
              }`}
            ></i>
            At least one number
          </li>
          <li className={passValid["special"] ? "valid" : "not-valid"}>
            <i
              className={`fas ${
                passValid["special"] ? "fa-check-circle" : "fa-times-circle"
              }`}
            ></i>
            At least one special character
          </li>
        </ul>
      ),
    },
    {
      name: "confirmPassword",
      value: "",
      type: "password",
      label: "Confirm Password",
      validation: false,
      placeholder: "Enter confirm password",
      required: true,
    },
  ];

  const getFormData = (data, field) => {
    // console.log(data);
    let formObj = { ...formData };
    let { username, password } = data;
    let valid = { ...passValid };
    let error = { ...customError };

    valid["upper"] = /[A-Z]/?.test(password);
    valid["number"] = /[0-9]/?.test(password);
    valid["special"] = /[(@!#\$%\^\&*\)\(+=._-]/?.test(password);
    valid["length"] = password?.length > 8;

    if (field == "username") {
      if (username) {
        if (/[(@!#\$%\^\&*\)\(+=._-]/?.test(username)) {
          error["username"] = "Use only letters (a-z) and numbers (0-9) only";
        } else {
          error["username"] = "";
          setUsernameLoading(true);
          setTimeout(() => {
            setUsernameLoading(false);
          }, 1000);
        }
      } else {
        error["username"] = "Username is required";
      }
    }
    setCustomError(error);
    setPassValid(valid);
    let updatedFormData = { ...formObj, ...data };
    setFormData(updatedFormData);
  };
  const onSubmit = () => {
    let passCheck = Object.values(passValid).every((item) => item);

    if (formRef.current.validForm() && passCheck) {
      // Reset successfully
      const options = formRef.current.getPayload();

      let obj = formRef.current.getPayload();
      obj.token = token;
      let payload = {
        method: "post",
        url: "/admin/signup",
        data: obj,
      };
      fetchApi(payload).then((response) => {
        if (response.status == 200) {
          success({
            title: <h5>Signup successfully</h5>,
            content: (
              <p>
                Your password has been updated successfully. You can now log in
                with your new password.
              </p>
            ),
            className: "custom-confirm",
            centered: true,
            closable: true,
            okText: "Login",
            onOk() {
              navigate("/login");
            },
            onCancel() {
              console.log("Cancel");
            },
          });
        }
      });
    } else {
      if (!passCheck) {
        sendNotify("error", "Invalid password format");
      } else {
        sendNotify("error", "Some fields are missing!");
      }
    }
  };

  //   const login = () => {
  //     let valid = formRef.current.validForm();
  //     if (valid) {
  //       setLoading(true);
  //       let obj = formRef.current.getPayload();
  //       let payload = {
  //         method: "post",
  //         url: "/auth/login",
  //         data: obj,
  //       };
  //       fetchApi(payload)
  //         .then((response) => {
  //           if (response) {
  //             setLoading(false);
  //             if (response.error) {
  //               sendNotify("error", response.error?.response?.data?.message);
  //             } else {
  //               sendNotify("success", "Login successfully");
  //               localStorage.setItem(
  //                 process.env.REACT_APP_JWT_TOKEN,
  //                 JSON.stringify(response?.data?.tokenObj?.accessToken)
  //               );
  //               dispatch(checkLogged());
  //               formRef.current.clearForm();
  //             }
  //           }
  //         })
  //         .catch((error) => ({ error: JSON.stringify(error) }));
  //     } else {
  //       sendNotify("error", "Some fields are missing!");
  //     }
  //   };

  return (
    <>
      {!timeLeft.expired ? (
        <div className="auth-form">
          <div
            className={`alert ${
              timeLeft.expired ? "alert-danger" : "alert-primary"
            } text-center`}
          >
            <i className="fas fa-clock fa-2x mb-2"></i>
            <div className="countdown-timer">
              {timeLeft.expired ? (
                <span>EXPIRED</span>
              ) : (
                <span>{formatTimeLeft(timeLeft)}</span>
              )}
            </div>
          </div>
          <FormZ
            formSchema={formSchema}
            formData={formData}
            ref={formRef}
            onChange={getFormData}
            customValidation={customError}
            labelClass={"mb-0"}
          />
          <button
            className={`primary-btn mt-5 auth-btn ${
              loading ? "btn-loading" : ""
            }`}
            onClick={onSubmit}
          >
            {loading ? <i className="far fa-spinner-third fa-spin"></i> : ""}
            Sign Up
          </button>
        </div>
      ) : (
        <>
          <div className="auth-form">
            <div className={`alert  alert-danger  text-center`}>
              <i className="fas fa-clock fa-2x mb-2"></i>
              <div className="countdown-timer">
                <span>EXPIRED</span>
              </div>
            </div>
            <Link className="primary-btn mt-5 auth-btn" to={"/"}>
              Back
            </Link>
          </div>
        </>
      )}
    </>
  );
}
