import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { Table, Tag, Select, Modal, Radio, Checkbox } from "antd";
import { getClient } from "../store/client/clientSlice";
import { getIntroducerClient } from "../store/introducerClient/introducerClientSlice";
import { getParentClient } from "../store/parentClient/parentClientSlice";
import { getEndUserClient } from "../store/endUserClient/endUserClientSlice";
import { getEntity } from "../store/entity/entitySlice";
import { getCorporateEntity } from "../store/corporateEntity/corporateEntitySlice";
import { getIndividual } from "../store/individual/individualSlice";
import TableFilterZ from "../components/TableFilterZ";
import TabZ from "../components/TabZ";
import InProgressIcon from "../assets/img/in-progress-icon.svg";
import ApprovedIcon from "../assets/img/approved-icon.svg";
import FormZ from "../components/FormZ";
import TagZ from "../components/TagZ";
import TableSortArrows from "../components/tableSortArrows";
import { languagesArray } from "../store/language/languageSlice";

import masterSearch from "../helper/translations/master-search.json";
import {
  downloadAsCSV,
  masterFileDownload,
  getQuery,
  fetchApi,
  convertQueryParams,
  customPagination,
  sendNotify,
  capitalizeFirstLetter,
} from "../helper";
const { confirm } = Modal;

export default function MasterSearch() {
  let query = getQuery();
  const client = useSelector((state) => state.client);
  const parent = useSelector((state) => state.parentClient);
  const endUser = useSelector((state) => state.endUserClient);
  let { isLogged, userDetails } = useSelector((state) => state.auth);
  const entity = useSelector((state) => state.entity);
  const corporateEntity = useSelector((state) => state.corporateEntity);
  const individual = useSelector((state) => state.individual);
  const introducer = useSelector((state) => state.introducerClient);
  const { language } = useSelector((state) => state.language);
  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });

  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [action, setAction] = useState(null);
  const [filters, setFilters] = useState(null);
  const [entityShow, setEntityShow] = useState(false);
  const [totalCount, setTotalCount] = useState("");
  const [isExportModal, setIsExportModal] = useState(false);
  const [dataView, setDataView] = useState("currentData");
  const [exportParams, setExportParams] = useState({});

  const dispatch = useDispatch();

  const [selctedLanguage, setSelctedLanguage] = useState("");
  useEffect(() => {
    let index = languagesArray.find((tc) => tc.value == language);
    setSelctedLanguage(index.value);
  }, [language]);
  let t = masterSearch.other[selctedLanguage] || masterSearch.other.en;
  let o = masterSearch.options[selctedLanguage] || masterSearch.options.en;
  let m =
    masterSearch.masterFile[selctedLanguage] || masterSearch.masterFile.en;
  let e = masterSearch.entity[selctedLanguage] || masterSearch.entity.en;
  let c = masterSearch.client[selctedLanguage] || masterSearch.client.en;
  let p =
    masterSearch.parentClient[selctedLanguage] || masterSearch.parentClient.en;
  let ic =
    masterSearch.introducerClient[selctedLanguage] ||
    masterSearch.introducerClient.en;
  let euc =
    masterSearch.endUserClient[selctedLanguage] ||
    masterSearch.endUserClient.en;
  let ind =
    masterSearch.individual[selctedLanguage] || masterSearch.individual.en;
  let corp =
    masterSearch.corporateEntity[selctedLanguage] ||
    masterSearch.corporateEntity.en;

  useEffect(() => {
    (async () => {
      setLoading(true);
      setAction(query.action ?? null);
      if (action == "client") {
        dispatch(getClient(queryParams));
      } else if (action == "introducerClient") {
        dispatch(getIntroducerClient(queryParams));
      } else if (action == "parent-client") {
        dispatch(getParentClient(queryParams));
      } else if (action == "end-user") {
        dispatch(getEndUserClient(queryParams));
      } else if (action == "entity") {
        dispatch(getEntity(queryParams));
      } else if (action == "corporateEntity") {
        dispatch(getCorporateEntity(queryParams));
      } else if (action == "individual") {
        dispatch(getIndividual(queryParams));
      } else {
        let params = { ...queryParams };
        params.isAll = true;
        let query = convertQueryParams(params);
        let payload = {
          method: "GET",
          url: `/client?${query}`,
        };
        let data = [];
        data = await fetchApi(payload).then((res) => {
          return res?.data;
        });

        const modifiedRows = data?.rows?.map((row) => {
          return {
            ...row,
            type: row.type
              ? capitalizeFirstLetter(row.type)
              : capitalizeFirstLetter(row.masterType),
          };
        });

        setTotalCount(data?.totalCount);
        setTableData(modifiedRows);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    let tColumn = [];
    switch (action) {
      case "client":
        tColumn = clientColumns;
        break;
      case "introducerClient":
        tColumn = introducerClientColumns;
        break;
      case "parent-client":
        tColumn = parentClientColumns;
        break;
      case "end-user":
        tColumn = endUserClientColumns;
        break;
      case "entity":
        tColumn = entityColumns;

        break;
      case "individual":
        tColumn = individualColumns;
        break;
      case "corporateEntity":
        tColumn = corporateEntityColumns;
        break;
      default:
        tColumn = masterFileColumns;
        break;
    }
    setTableColumn(tColumn);
    getData();
  }, [
    client,
    parent,
    endUser,
    entity,
    corporateEntity,
    individual,
    introducer,
    m,
  ]);
  const masterTypeOnChange = (value) => {
    let params = { ...exportParams, ...queryParams };
    params.alltype = value;
    setExportParams(params);
  };
  const getData = async (value) => {
    setLoading(true);
    const capitalizeRows = (rows) => {
      return rows?.map((row) => {
        if (row.masterType !== "corporate-entity") {
          return {
            ...row,
            type: row.type
              ? capitalizeFirstLetter(
                  row.masterType === "parent-client" ||
                    row.masterType === "client" ||
                    row.masterType === "introducer-client" ||
                    row.masterType === "end-user"
                    ? `${row.type} Client`
                    : `${row.type}`
                )
              : capitalizeFirstLetter(`${row.masterType}`),
            DOB: row.DOB ? moment(row.DOB).format("YYYY-MMM-DD") : "-",
            incorporationDate: row.incorporationDate
              ? moment(row.incorporationDate).format("YYYY-MMM-DD")
              : "-",
            serviceFeeCategory:
              row.feeCat && row.feeSubCat
                ? `${row.feeCat},${row.feeSubCat}`
                : row.feeCat && !row.feeSubCat
                ? `${row.feeCat}`
                : "-",
          };
        } else {
          return {
            ...row,
            type: "Corporate Entity",
          };
        }
      });
    };

    if (action === "client") {
      setTableData(capitalizeRows(client?.data?.rows));
    } else if (
      [
        "introducerClient",
        "parent-client",
        "end-user",
        "entity",
        "corporateEntity",
        "individual",
      ].includes(action)
    ) {
      const dataMap = {
        introducerClient: introducer,
        "parent-client": parent,
        "end-user": endUser,
        entity: entity,
        corporateEntity: corporateEntity,
        individual: individual,
      };
      setTableData(capitalizeRows(dataMap[action]?.data?.rows));
    } else {
      let params = { ...queryParams };
      params.isAll = true;
      let query = convertQueryParams(params);
      let payload = {
        method: "GET",
        url: `/client?${query}`,
      };
      let data = await fetchApi(payload).then((res) => res?.data);
      if (data) {
        setTotalCount(data?.totalCount);
        setTableData(capitalizeRows(data?.rows));
      } else {
        setTotalCount(0);
        setTableData([]);
      }
    }
    setLoading(false);
  };

  const handleRefresh = async (value) => {
    let params = { _limit: 10, _start: 0 };

    const actionMap = {
      client: getClient,
      introducerClient: getIntroducerClient,
      "parent-client": getParentClient,
      "end-user": getEndUserClient,
      entity: getEntity,
      corporateEntity: getCorporateEntity,
      individual: getIndividual,
    };

    if (actionMap[value]) {
      dispatch(actionMap[value](params));
    } else {
      setLoading(true);

      let params = { ...queryParams };
      params.isAll = true;
      let query = convertQueryParams(params);
      let payload = {
        method: "GET",
        url: `/client?${query}`,
      };

      let data = await fetchApi(payload).then((res) => res?.data);

      const modifiedRows = data.rows.map((row) => {
        return {
          ...row,
          type: row.type
            ? capitalizeFirstLetter(row.type)
            : capitalizeFirstLetter(row.masterType),
        };
      });

      setTotalCount(data.totalCount);
      setTableData(modifiedRows);
      setLoading(false);
    }

    setAction(value);
  };
  const deleteOnClick = (id, type) => {
    confirm({
      title: <h5>Delete record</h5>,
      content: (
        <p>
          Are you sure you want to delete this record? This action cannot be
          undone.
        </p>
      ),
      className: "custom-confirm",
      centered: true,
      okText: "Delete",
      cancelText: "Cancel",
      onOk() {
        confirmDeleteOnClick(id, type);
      },
      onCancel() {
        console.log("Deletion canceled");
      },
    });
  };

  const confirmDeleteOnClick = (id, type) => {
    let url = ``;
    switch (type) {
      case "client":
        url = `client?id=${id}`;
        break;
      case "introducer-client":
        url = `client?id=${id}`;
        break;
      case "parent-client":
        url = `client?id=${id}`;
        break;
      case "end-user":
        url = `client?id=${id}`;
        break;
      case "entity":
        url = `entity?id=${id}`;

        break;
      case "individual":
        url = `individual?id=${id}`;
        break;
      case "cop-entity":
        url = `cop-entity?id=${id}`;
        break;
      default:
        url = `client?id=${id}`;
        break;
    }

    handleApiDelete(url)
      .then((response) => {
        sendNotify("success", "Record deleted successfully");

        if (action == "client") {
          dispatch(getClient(queryParams));
        } else if (action == "introducerClient") {
          dispatch(getIntroducerClient(queryParams));
        } else if (action == "parent-client") {
          dispatch(getParentClient(queryParams));
        } else if (action == "end-user") {
          dispatch(getEndUserClient(queryParams));
        } else if (action == "entity") {
          dispatch(getEntity(queryParams));
        } else if (action == "corporateEntity") {
          dispatch(getCorporateEntity(queryParams));
        } else if (action == "individual") {
          dispatch(getIndividual(queryParams));
        }
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };
  const handleApiDelete = async (url) => {
    try {
      const response = await fetchApi({ method: "delete", url });
      return response;
    } catch (error) {
      sendNotify("error", error?.message);
      throw error;
    }
  };
  const entityColumns = [
    {
      title: e.EntityName,
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.entityName - b.entityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: e.EntityCode,
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.entityCode - b.entityCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: e.EntityType,
      dataIndex: "type",
      filterType: "text",
      sorter: (a, b) => a.type - b.type,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      // render: (_, { status }) => (
      //     <Select
      //         className="custom-select"
      //         placeholder="Select type"
      //         value={status}
      //         style={{
      //             width: 160
      //         }}
      //         options={[
      //             {
      //                 value: 'bvibc',
      //                 label: 'BVIBC'
      //             },
      //             {
      //                 value: 'trust',
      //                 label: 'Trust'
      //             },
      //             {
      //                 value: 'generalPartnership',
      //                 label: 'General Partnership'
      //             },
      //             {
      //                 value: 'limitedPartnership',
      //                 label: 'Limited Partnership'
      //             },
      //             {
      //                 value: 'foundation',
      //                 label: 'Foundation'
      //             },
      //         ]}
      //     />
      // ),
    },
    {
      title: e.ClientName,
      dataIndex: "clientName",
      filterType: "text",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: e.ClientCode,
      dataIndex: "clientCode",
      filterType: "text",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: e.ClientType,
      dataIndex: "clientType",
      filterType: "text",
      sorter: (a, b) => a.clientType - b.clientType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // {
    //   title: "Parent Client Name",
    //   dataIndex: "parentClientName",
    //   filterType: "text",
    //   sorter: (a, b) => a.parentClientName - b.parentClientName,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    // },
    // {
    //   title: "Parent Client Code",
    //   dataIndex: "parentClientCode",
    //   filterType: "text",
    //   sorter: (a, b) => a.parentClientCode - b.parentClientCode,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    // },
    {
      title: e.ServiceFeeCategory,
      dataIndex: "serviceFeeCategory",
      sorter: (a, b) => a.serviceFeeCategory - b.serviceFeeCategory,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 200,
    },

    {
      title: e.IncorpNumber,
      dataIndex: "incorporationNumber",
      sorter: (a, b) => a.incorpNumber - b.incorpNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: e.IncorpDate,
      dataIndex: "incorporationDate",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: e.IncorpBatch,
      dataIndex: "incorpBatch",
      sorter: (a, b) => a.incorpBatch - b.incorpBatch,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: e.RiskLevel,
      dataIndex: "riskLevel",
      sorter: (a, b) => a.riskLevel - b.riskLevel,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={true ? "approved" : "rejected"}
          statusText={true ? "Low" : "High"}
        />
      ),
    },
    {
      title: e.Status,
      dataIndex: "status",
      filterType: "select",
      filterOptions: [
        {
          value: "Active",
          label: "Active",
        },
        {
          value: "Inactive",
          label: "Inactive",
        },
      ],
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={status == "active" ? "approved" : "rejected"}
          statusText={status?.charAt(0)?.toUpperCase() + status?.slice(1)} //
        />
      ),
    },
    // 7-11-2024
    // {
    //   title: "Related Entities",
    //   dataIndex: "relatedEntities",
    //   width: 200,
    //   render: (_, { status }) => (
    //     <div className="custom-form">
    //       <input type="text" placeholder="Enter related entities" />
    //     </div>
    //   ),
    // },
    {
      title: e.Overview,
      dataIndex: "overview",
      // fixed: "right",
      // width: 150,
      render: (_, { _id, masterType }) => (
        <Link
          to={`/app/entity-overview/list?type=${masterType}&id=${_id}`}
          className="action-btn icon-btn"
        >
          <i className="far fa-eye"></i>
        </Link>
      ),
    },
    {
      title: e.Edit,
      dataIndex: "edit",
      // fixed: "right",
      // width: 150,
      render: (_, { _id, masterType }) => (
        <Link
          to={`/app/entity-overview/edit?type=${masterType}&id=${_id}`}
          className="action-btn icon-btn"
        >
          <i className="far fa-eye"></i>
        </Link>
      ),
    },
  ];
  userDetails.role == "admin" ||
    (userDetails.role == "super-admin" &&
      entityColumns.push({
        title: "Delete",
        render: (_, { _id }) => (
          <div className="d-flex gap-2 align-items-center justify-content-end">
            <button
              className="action-btn delete-btn"
              onClick={() => deleteOnClick(_id, "entity")}
            >
              <i className="far fa-trash-alt"></i>
            </button>
          </div>
        ),
      }));
  const masterFileColumns = [
    {
      title: m.MasterfileCode,
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.code.localeCompare(b.code),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: m.MasterfileName,
      dataIndex: "name",
      filterType: "text",
      //sorter: (a, b) => a.name - b.name,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: m.MasterfileType,
      dataIndex: "type",
      filterType: "text",
      // sorter: (a, b) => a.type - b.type,
      sorter: (a, b) => a.type.localeCompare(b.type),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      // render: (_, { status }) => (
      //     <Select
      //         className="custom-select"
      //         placeholder="Select type"
      //         value={status}
      //         style={{
      //             width: 160
      //         }}
      //         options={[
      //             {
      //                 value: 'bvibc',
      //                 label: 'BVIBC'
      //             },
      //             {
      //                 value: 'trust',
      //                 label: 'Trust'
      //             },
      //             {
      //                 value: 'generalPartnership',
      //                 label: 'General Partnership'
      //             },
      //             {
      //                 value: 'limitedPartnership',
      //                 label: 'Limited Partnership'
      //             },
      //             {
      //                 value: 'foundation',
      //                 label: 'Foundation'
      //             },
      //         ]}
      //     />
      // ),
    },
    {
      title: m.MasterfileStatus,
      dataIndex: "status",
      filterType: "text",
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={status == "active" ? "approved" : "rejected"}
          statusText={status?.charAt(0)?.toUpperCase() + status?.slice(1)}
        />
      ),
    },
    {
      title: m.DateCreated,
      dataIndex: "createdAt",
      filterType: "date",
      /// sorter: (a, b) => a.incorpDate - b.incorpDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,

      key: "createdAt",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      //  sortOrder: sortedInfo.columnKey === "date" && sortedInfo.order,
      render: (text) => moment(text).format("YYYY-MM-DD hh:mm A"),
    },
    {
      title: m.CreatedBy,
      dataIndex: "createdBy",
      filterType: "text",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: m.Overview,
      dataIndex: "overview",
      // fixed: "right",
      // width: 150,
      render: (_, { _id, masterType }) => (
        <Link
          to={
            masterType == "entity"
              ? `/app/entity-overview/list?type=${masterType}&id=${_id}`
              : `/app/master-file-overview/?type=${masterType}&id=${_id}`
          }
          className="action-btn icon-btn"
        >
          <i className="far fa-eye"></i>
        </Link>
      ),
    },
  ];

  const [tableColumn, setTableColumn] = useState([]);

  const clientColumns = [
    {
      title: c.MasterfileName,
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: c.MasterfileCode,
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: c.MasterfileType,
      dataIndex: "type",
      filterType: "text",
      sorter: (a, b) => a.clientType - b.clientType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: c.ParentClientName,
      dataIndex: "parentClientName",
      filterType: "text",
      sorter: (a, b) => a.parentClientName - b.parentClientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: c.ParentClientCode,
      dataIndex: "pCode",
      filterType: "text",
      sorter: (a, b) => a.parentClientCode - b.parentClientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // {
    //   title: "Service Fee Category",
    //   dataIndex: "serviceFeeCategory",
    //   sorter: (a, b) => a.serviceFeeCategory - b.serviceFeeCategory,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    //   width: 200,
    // },
    {
      title: c.Jurisdiction,
      dataIndex: "jurisdiction",
      sorter: (a, b) => a.jurisdiction - b.jurisdiction,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // {
    //   title: "Incorp Number",
    //   dataIndex: "incorpNumber",
    //   filterType: "text",
    //   sorter: (a, b) => a.incorpNumber - b.incorpNumber,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    // },
    // {
    //   title: "Incorp Date",
    //   dataIndex: "createdAt",
    //   filterType: "date",
    //   sorter: (a, b) => a.incorpDate - b.incorpDate,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    // },
    // {
    //   title: "Risk Level",
    //   dataIndex: "riskLevel",
    //   sorter: (a, b) => a.riskLevel - b.riskLevel,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    //   render: (_, { status }) => (
    //     <TagZ
    //       status={true ? "approved" : "rejected"}
    //       statusText={true ? "Low" : "High"}
    //     />
    //     // <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={"green"}>
    //     //   Low
    //     // </Tag>
    //   ),
    // },
    {
      title: c.ParentClientStatus,
      dataIndex: "status",
      filterType: "select",
      filterOptions: [
        {
          value: "approved",
          label: "Approved",
        },
        {
          value: "in-progress",
          label: "In Progress",
        },
      ],
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={status ? "approved" : "rejected"}
          statusText={status?.charAt(0)?.toUpperCase() + status?.slice(1)}
        />
      ),
    },
    {
      title: c.Overview,
      dataIndex: "overview",
      // fixed: "right",
      // width: 150,
      render: (_, { _id, masterType }) => (
        <Link
          to={`/app/master-file-overview/?type=${masterType}&id=${_id}`}
          className="action-btn icon-btn"
        >
          <i className="far fa-eye"></i>
        </Link>
      ),
    },
    {
      title: "EntityCount",
      dataIndex: "entityCount",
      // fixed: "right",
      // width: 150,
      render: (_, { _id, entityCount, masterType, name, code }) => (
        <Link
          to={`/app/client-details/?id=${_id}&masterType=${masterType}&name=${name}&code=${code}`}
          className="action-btn icon-btn"
        >
          <i>{entityCount}</i>
        </Link>
      ),
    },
  ];
  userDetails.role == "admin" ||
    (userDetails.role == "super-admin" &&
      clientColumns.push({
        title: "Delete",
        render: (_, { _id }) => (
          <div className="d-flex gap-2 align-items-center justify-content-end">
            <button
              className="action-btn delete-btn"
              onClick={() => deleteOnClick(_id, "client")}
            >
              <i className="far fa-trash-alt"></i>
            </button>
          </div>
        ),
      }));
  const parentClientColumns = [
    {
      title: p.MasterfileName,
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.parentClientName - b.parentClientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: p.MasterfileCode,
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.parentClientCode - b.parentClientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: p.MasterfileType,
      dataIndex: "type",
      filterType: "text",
      sorter: (a, b) => a.parentClientType - b.parentClientType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // {
    //   title: "Service Fee Category",
    //   dataIndex: "serviceFeeCategory",
    //   sorter: (a, b) => a.serviceFeeCategory - b.serviceFeeCategory,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    //   width: 200,
    // },
    {
      title: p.Jurisdiction,
      dataIndex: "jurisdiction",
      sorter: (a, b) => a.jurisdiction - b.jurisdiction,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: p.AdminManager,
      dataIndex: "adminManager",
      sorter: (a, b) => a.adminManager - b.adminManager,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: p.Administrator,
      dataIndex: "administrator",
      sorter: (a, b) => a.administrator - b.administrator,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: p.RelationshipManager,
      dataIndex: "relationshipManager",
      sorter: (a, b) => a.relationshipManager - b.relationshipManager,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: p.Overview,
      dataIndex: "overview",
      // fixed: "right",
      // width: 150,
      render: (_, { _id, masterType }) => (
        <Link
          to={`/app/master-file-overview/?type=${masterType}&id=${_id}`}
          className="action-btn icon-btn"
        >
          <i className="far fa-eye"></i>
        </Link>
      ),
    },
    {
      title: "EntityCount",
      dataIndex: "entityCount",
      // fixed: "right",
      // width: 150,
      render: (_, { _id, entityCount, masterType, name, code }) => (
        <Link
          to={`/app/client-details/?id=${_id}&masterType=${masterType}&name=${name}&code=${code}`}
          className="action-btn icon-btn"
        >
          <i>{entityCount}</i>
        </Link>
      ),
    },
  ];
  userDetails.role == "admin" ||
    (userDetails.role == "super-admin" &&
      parentClientColumns.push({
        title: "Delete",
        render: (_, { _id }) => (
          <div className="d-flex gap-2 align-items-center justify-content-end">
            <button
              className="action-btn delete-btn"
              onClick={() => deleteOnClick(_id, "parent-client")}
            >
              <i className="far fa-trash-alt"></i>
            </button>
          </div>
        ),
      }));
  const introducerClientColumns = [
    {
      title: ic.MasterfileName,
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: ic.MasterfileCode,
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: ic.MasterfileType,
      dataIndex: "type",
      filterType: "text",
      sorter: (a, b) => a.clientType - b.clientType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    // {
    //   title: "Service Fee Category",
    //   dataIndex: "serviceFeeCategory",
    //   sorter: (a, b) => a.serviceFeeCategory - b.serviceFeeCategory,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    //   width: 200,
    // },
    {
      title: ic.Jurisdiction,
      dataIndex: "jurisdiction",
      sorter: (a, b) => a.jurisdiction - b.jurisdiction,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // {
    //   title: "Incorp Number",
    //   dataIndex: "incorpNumber",
    //   filterType: "text",
    //   sorter: (a, b) => a.incorpNumber - b.incorpNumber,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    // },
    // {
    //   title: "Incorp Date",
    //   dataIndex: "createdAt",
    //   filterType: "date",
    //   sorter: (a, b) => a.incorpDate - b.incorpDate,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    // },
    // {
    //   title: "Risk Level",
    //   dataIndex: "riskLevel",
    //   sorter: (a, b) => a.riskLevel - b.riskLevel,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    //   render: (_, { status }) => (
    //     <TagZ
    //       status={true ? "approved" : "rejected"}
    //       statusText={true ? "Low" : "High"}
    //     />
    //   ),
    // },
    {
      title: ic.Status,
      dataIndex: "status",
      filterType: "select",
      filterOptions: [
        {
          value: "approved",
          label: "Approved",
        },
        {
          value: "in-progress",
          label: "In Progress",
        },
      ],
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={status ? "approved" : "rejected"}
          statusText={status?.charAt(0)?.toUpperCase() + status?.slice(1)}
        />
      ),
    },
    {
      title: ic.Overview,
      dataIndex: "overview",
      // fixed: "right",
      // width: 150,
      render: (_, { _id, masterType }) => (
        <Link
          to={`/app/master-file-overview/?type=${masterType}&id=${_id}`}
          className="action-btn icon-btn"
        >
          <i className="far fa-eye"></i>
        </Link>
      ),
    },
    {
      title: "EntityCount",
      dataIndex: "entityCount",
      // fixed: "right",
      // width: 150,
      render: (_, { _id, entityCount, masterType, name, code }) => (
        <Link
          to={`/app/client-details/?id=${_id}&masterType=${masterType}&name=${name}&code=${code}`}
          className="action-btn icon-btn"
        >
          <i>{entityCount}</i>
        </Link>
      ),
    },
  ];
  userDetails.role == "admin" ||
    (userDetails.role == "super-admin" &&
      introducerClientColumns.push({
        title: "Delete",
        render: (_, { _id }) => (
          <div className="d-flex gap-2 align-items-center justify-content-end">
            <button
              className="action-btn delete-btn"
              onClick={() => deleteOnClick(_id, "introducer-client")}
            >
              <i className="far fa-trash-alt"></i>
            </button>
          </div>
        ),
      }));
  const endUserClientColumns = [
    {
      title: euc.MasterfileName,
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: euc.MasterfileCode,
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: euc.EndUserClientType,
      dataIndex: "type",
      filterType: "text",
      sorter: (a, b) => a.clientType - b.clientType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // {
    //   title: "Parent Client Code",
    //   dataIndex: "pCode",
    //   filterType: "text",
    //   sorter: (a, b) => a.parentClientCode - b.parentClientCode,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    // },
    // {
    //   title: "Service Fee Category",
    //   dataIndex: "serviceFeeCategory",
    //   sorter: (a, b) => a.serviceFeeCategory - b.serviceFeeCategory,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    //   width: 200,
    // },
    {
      title: euc.Jurisdiction,
      dataIndex: "jurisdiction",
      sorter: (a, b) => a.jurisdiction - b.jurisdiction,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // {
    //   title: "Incorp Number",
    //   dataIndex: "incorpNumber",

    //   sorter: (a, b) => a.incorpNumber - b.incorpNumber,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    // },
    // {
    //   title: "Incorp Date",
    //   dataIndex: "createdAt",
    //   filterType: "date",
    //   sorter: (a, b) => a.incorpDate - b.incorpDate,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    // },
    // {
    //   title: "Risk Level",
    //   dataIndex: "riskLevel",
    //   sorter: (a, b) => a.riskLevel - b.riskLevel,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    //   render: (_, { status }) => (
    //     <TagZ
    //       status={true ? "approved" : "rejected"}
    //       statusText={true ? "Low" : "High"}
    //     />
    //   ),
    // },
    {
      title: euc.EndUserStatus,
      dataIndex: "status",
      filterType: "select",
      filterOptions: [
        {
          value: "approved",
          label: "Approved",
        },
        {
          value: "in-progress",
          label: "In Progress",
        },
      ],
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={status ? "approved" : "rejected"}
          statusText={status?.charAt(0)?.toUpperCase() + status?.slice(1)}
        />
      ),
    },
    {
      title: euc.Overview,
      dataIndex: "overview",
      // fixed: "right",
      // width: 150,
      render: (_, { _id, masterType }) => (
        <Link
          to={`/app/master-file-overview/?type=${masterType}&id=${_id}`}
          className="action-btn icon-btn"
        >
          <i className="far fa-eye"></i>
        </Link>
      ),
    },
    {
      title: "EntityCount",
      dataIndex: "entityCount",
      // fixed: "right",
      // width: 150,
      render: (_, { _id, entityCount, masterType, name, code }) => (
        <Link
          to={`/app/client-details/?id=${_id}&masterType=${masterType}&name=${name}&code=${code}`}
          className="action-btn icon-btn"
        >
          <i>{entityCount}</i>
        </Link>
      ),
    },
  ];
  userDetails.role == "admin" ||
    (userDetails.role == "super-admin" &&
      endUserClientColumns.push({
        title: "Delete",
        render: (_, { _id }) => (
          <div className="d-flex gap-2 align-items-center justify-content-end">
            <button
              className="action-btn delete-btn"
              onClick={() => deleteOnClick(_id, "end-user")}
            >
              <i className="far fa-trash-alt"></i>
            </button>
          </div>
        ),
      }));
  const individualColumns = [
    {
      title: ind.MasterFileCode,
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.individualCode - b.individualCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: ind.Title,
      dataIndex: "title",
      filterType: "text",
      sorter: (a, b) => a.title - b.title,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: ind.FirstName,
      dataIndex: "firstName",
      filterType: "text",
      sorter: (a, b) => a.firstName - b.firstName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: ind.MiddleName,
      dataIndex: "middleName",
      filterType: "text",
      sorter: (a, b) => a.middleName - b.middleName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 200,
    },
    {
      title: ind.LastName,
      dataIndex: "lastName",
      filterType: "text",
      sorter: (a, b) => a.lastName - b.lastName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: ind.DOB,
      dataIndex: "DOB",
      filterType: "date",
      sorter: (a, b) => a.dateOfBirth - b.dateOfBirth,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: ind.COB,
      dataIndex: "COB",
      filterType: "text",
      sorter: (a, b) => a.countryOfBirth - b.countryOfBirth,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: ind.Nationality,
      dataIndex: "nationality",
      filterType: "text",
      sorter: (a, b) => a.nationality - b.nationality,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: ind.TaxInfo,
      dataIndex: "taxInfo",
      filterType: "text",
      sorter: (a, b) => a.taxInfoNumber - b.taxInfoNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: ind.Address,
      dataIndex: "address",
      sorter: (a, b) => a.address - b.address,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: ind.ContactNo,
      dataIndex: "contactNo",
      filterType: "text",
      sorter: (a, b) => a.contactNumber - b.contactNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: ind.Email,
      dataIndex: "email",
      filterType: "text",
      sorter: (a, b) => a.emailAddress - b.emailAddress,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: ind.RiskLevel,
      dataIndex: "riskLevel",
      sorter: (a, b) => a.riskLevel - b.riskLevel,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={true ? "approved" : "rejected"}
          statusText={true ? "Low" : "High"}
        />
      ),
    },
    {
      title: ind.Pep,
      dataIndex: "pep",
      sorter: (a, b) => a.pep - b.pep,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={true ? "approved" : "rejected"}
          statusText={true ? "Yes" : "No"}
        />
      ),
    },
    {
      title: ind.Sanction,
      dataIndex: "sanction",
      sorter: (a, b) => a.sanction - b.sanction,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={false ? "approved" : "rejected"}
          statusText={false ? "Yes" : "No"}
        />
      ),
    },
    {
      title: ind.Overview,
      dataIndex: "overview",
      // fixed: "right",
      // width: 150,
      render: (_, { _id, masterType }) => (
        <Link
          to={`/app/master-file-overview/?type=${masterType}&id=${_id}`}
          className="action-btn icon-btn"
        >
          <i className="far fa-eye"></i>
        </Link>
      ),
    },
  ];
  userDetails.role == "admin" ||
    (userDetails.role == "super-admin" &&
      individualColumns.push({
        title: "Delete",
        render: (_, { _id }) => (
          <div className="d-flex gap-2 align-items-center justify-content-end">
            <button
              className="action-btn delete-btn"
              onClick={() => deleteOnClick(_id, "individual")}
            >
              <i className="far fa-trash-alt"></i>
            </button>
          </div>
        ),
      }));
  const corporateEntityColumns = [
    {
      title: corp.MasterfileCode,
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.corporateEntityCode - b.corporateEntityCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: corp.MasterfileName,
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.corporateEntityName - b.corporateEntityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: corp.MasterfileType,
      dataIndex: "type",
      sorter: (a, b) => a.corporateEntityType - b.corporateEntityType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: corp.incorporationDate,
      dataIndex: "incorporationDate",
      filterType: "date",
      sorter: (a, b) => a.incorporationDate - b.incorporationDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: corp.incorporationNumber,
      dataIndex: "incorporationNumber",
      filterType: "text",
      sorter: (a, b) => a.incorporationNumber - b.incorporationNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: corp.incorporationCountry,
      dataIndex: "incorporationCountry",
      sorter: (a, b) => a.countryOfIncorporation - b.countryOfIncorporation,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: corp.busGeography,
      dataIndex: "busGeography",
      sorter: (a, b) => a.geographyOfBusiness - b.geographyOfBusiness,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: corp.busNature,
      dataIndex: "busNature",
      sorter: (a, b) => a.natureOfBusiness - b.natureOfBusiness,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: corp.busDesc,
      dataIndex: "busDesc",
      filterType: "text",
      sorter: (a, b) => a.natureOfBusiness - b.natureOfBusiness,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: corp.corpInfoNo,
      dataIndex: "corpInfoNo",
      filterType: "text",
      sorter: (a, b) => a.taxInfoNumber - b.taxInfoNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: corp.address,
      dataIndex: "address",
      filterType: "text",
      sorter: (a, b) => a.address - b.address,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: corp.contactNumber,
      dataIndex: "contactNumber",
      filterType: "text",
      sorter: (a, b) => a.contactNumber - b.contactNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: corp.emailAddress,
      dataIndex: "emailAddress",
      filterType: "text",
      sorter: (a, b) => a.emailAddress - b.emailAddress,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: corp.overview,
      dataIndex: "overview",
      // fixed: "right",
      // width: 150,
      render: (_, { _id, masterType }) => (
        <Link
          to={`/app/master-file-overview/?type=${masterType}&id=${_id}`}
          className="action-btn icon-btn"
        >
          <i className="far fa-eye"></i>
        </Link>
      ),
    },
  ];
  (userDetails.role == "admin" || userDetails.role == "super-admin") &&
    corporateEntityColumns.push({
      title: "Delete",
      render: (_, { _id }) => (
        <div className="d-flex gap-2 align-items-center justify-content-end">
          <button
            className="action-btn delete-btn"
            onClick={() => deleteOnClick(_id, "cop-entity")}
          >
            <i className="far fa-trash-alt"></i>
          </button>
        </div>
      ),
    });
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record?.name === "Disabled User",
      // Column configuration not to be checked
      name: record?.name,
    }),
  };

  // const onExportClick = async () => {
  //   let params = { ...exportParams };
  //   let headersArray = [];
  //   if (dataView == "allData") {
  //     params.exportAll = true;
  //   }
  //   if (!action) {
  //     headersArray = [
  //       {
  //         header: "Masterfile Code",
  //         name: "code",
  //         required: true,
  //       },
  //       {
  //         header: "Masterfile Name",
  //         name: "name",
  //         required: true,
  //       },
  //       {
  //         header: "Masterfile Type",
  //         name: "type",
  //         required: true,
  //       },
  //       {
  //         header: "Masterfile Status",
  //         name: "status",
  //         required: true,
  //       },
  //       {
  //         header: "Date Created",
  //         name: "createdAt",
  //         required: true,
  //       },
  //       {
  //         header: "Created By",
  //         name: "createdBy",
  //         required: true,
  //       },
  //     ];
  //   } else if (action == "client") {
  //     headersArray = [
  //       {
  //         header: "Masterfile Name",
  //         name: "name",
  //         required: true,
  //       },
  //       {
  //         header: "Client Code",
  //         name: "code",
  //         required: true,
  //       },
  //       {
  //         header: "Client Type",
  //         name: "type",
  //         required: true,
  //       },
  //       {
  //         header: "Parent Client Name",
  //         name: "parentClientName",
  //         required: true,
  //       },
  //       {
  //         header: "Parent Client Code",
  //         name: "pCode",
  //         required: true,
  //       },
  //       {
  //         header: "Service Fee Category",
  //         name: "serviceFeeCategory",
  //         required: true,
  //       },
  //       {
  //         header: "Jurisdiction",
  //         name: "jurisdiction",
  //         required: true,
  //       },
  //       {
  //         header: "Incorp Number",
  //         name: "incorpNumber",
  //         required: true,
  //       },
  //       {
  //         header: "Incorp Date",
  //         name: "createdAt",
  //         required: true,
  //       },
  //       {
  //         header: "Risk Level",
  //         name: "riskLevel",
  //         required: true,
  //       },
  //       {
  //         header: "Parent Client Status",
  //         name: "status",
  //         required: true,
  //       },
  //     ];
  //   } else if (action == "parent-client") {
  //     const headersArray = [
  //       {
  //         header: "Parent Client Name",
  //         name: "name",
  //         required: true,
  //       },
  //       {
  //         header: "Parent Client Code",
  //         name: "code",
  //         required: true,
  //       },
  //       {
  //         header: "Parent Client Type",
  //         name: "type",
  //         required: true,
  //       },
  //       {
  //         header: "Service Fee Category",
  //         name: "serviceFeeCategory",
  //         required: true,
  //       },
  //       {
  //         header: "Jurisdiction",
  //         name: "jurisdiction",
  //         required: true,
  //       },
  //       {
  //         header: "Admin Manager",
  //         name: "adminManager",
  //         required: true,
  //       },
  //       {
  //         header: "Administrator",
  //         name: "administrator",
  //         required: true,
  //       },
  //       {
  //         header: "Relationship Manager",
  //         name: "relationshipManager",
  //         required: true,
  //       },
  //       {
  //         header: "Overview",
  //         name: "overview",
  //         required: true,
  //       },
  //     ];
  //   } else if (action == "introducerClient") {
  //     headersArray = [
  //       {
  //         header: "Masterfile Name",
  //         name: "name",
  //         required: true,
  //       },
  //       {
  //         header: "Masterfile Code",
  //         name: "code",
  //         required: true,
  //       },
  //       {
  //         header: "Masterfile Type",
  //         name: "type",
  //         required: true,
  //       },
  //       {
  //         header: "Service Fee Category",
  //         name: "serviceFeeCategory",
  //         required: true,
  //       },
  //       {
  //         header: "Jurisdiction",
  //         name: "jurisdiction",
  //         required: true,
  //       },
  //       {
  //         header: "Incorp Number",
  //         name: "incorpNumber",
  //         required: true,
  //       },
  //       {
  //         header: "Incorp Date",
  //         name: "createdAt",
  //         required: true,
  //       },
  //       {
  //         header: "Risk Level",
  //         name: "riskLevel",
  //         required: true,
  //       },
  //       {
  //         header: "Status",
  //         name: "status",
  //         required: true,
  //       },
  //     ];
  //   } else if (action == "end-user") {
  //     headersArray = [
  //       {
  //         header: "Masterfile Name",
  //         name: "name",
  //         required: true,
  //       },
  //       {
  //         header: "Masterfile Code",
  //         name: "code",
  //         required: true,
  //       },
  //       {
  //         header: "End User Client Type",
  //         name: "type",
  //         required: true,
  //       },
  //       {
  //         header: "Parent Client Code",
  //         name: "pCode",
  //         required: true,
  //       },
  //       {
  //         header: "Service Fee Category",
  //         name: "serviceFeeCategory",
  //         required: true,
  //       },
  //       {
  //         header: "Jurisdiction",
  //         name: "jurisdiction",
  //         required: true,
  //       },
  //       {
  //         header: "Incorp Number",
  //         name: "incorpNumber",
  //         required: true,
  //       },
  //       {
  //         header: "Incorp Date",
  //         name: "createdAt",
  //         required: true,
  //       },
  //       {
  //         header: "Risk Level",
  //         name: "riskLevel",
  //         required: true,
  //       },
  //       {
  //         header: "End User Status",
  //         name: "status",
  //         required: true,
  //       },
  //     ];
  //   } else if (action == "individual") {
  //     headersArray = [
  //       {
  //         header: "MasterFile Code",
  //         name: "code",
  //         required: true,
  //       },
  //       {
  //         header: "Title",
  //         name: "title",
  //         required: true,
  //       },
  //       {
  //         header: "First Name",
  //         name: "firstName",
  //         required: true,
  //       },
  //       {
  //         header: "Middle Name",
  //         name: "middleName",
  //         required: true,
  //       },
  //       {
  //         header: "Last Name",
  //         name: "lastName",
  //         required: true,
  //       },
  //       {
  //         header: "Date of Birth",
  //         name: "DOB",
  //         required: true,
  //       },
  //       {
  //         header: "Country of Birth",
  //         name: "COB",
  //         required: true,
  //       },
  //       {
  //         header: "Nationality",
  //         name: "nationality",
  //         required: true,
  //       },
  //       {
  //         header: "Tax Info Number",
  //         name: "taxInfo",
  //         required: true,
  //       },
  //       {
  //         header: "Address",
  //         name: "address",
  //         required: true,
  //       },
  //       {
  //         header: "Contact Number",
  //         name: "contactNo",
  //         required: true,
  //       },
  //       {
  //         header: "Email Address",
  //         name: "email",
  //         required: true,
  //       },
  //       {
  //         header: "Risk Level",
  //         name: "riskLevel",
  //         required: true,
  //       },
  //       {
  //         header: "Pep",
  //         name: "pep",
  //         required: true,
  //       },
  //       {
  //         header: "Sanction",
  //         name: "sanction",
  //         required: true,
  //       },
  //     ];
  //   } else if (action == "entity") {
  //     headersArray = [
  //       {
  //         header: "Entity Name",
  //         name: "name",
  //         required: true,
  //       },
  //       {
  //         header: "Entity Code",
  //         name: "code",
  //         required: true,
  //       },
  //       {
  //         header: "Entity Type",
  //         name: "type",
  //         required: true,
  //       },
  //       {
  //         header: "Masterfile Name",
  //         name: "clientName",
  //         required: true,
  //       },
  //       {
  //         header: "Masterfile Code",
  //         name: "clientCode",
  //         required: true,
  //       },
  //       {
  //         header: "Client Type",
  //         name: "clientType",
  //         required: true,
  //       },
  //       {
  //         header: "Parent Client Name",
  //         name: "parentClientName",
  //         required: true,
  //       },
  //       {
  //         header: "Parent Client Code",
  //         name: "parentClientCode",
  //         required: true,
  //       },
  //       {
  //         header: "Service Fee Category",
  //         name: "serviceFeeCategory",
  //         required: true,
  //       },
  //       {
  //         header: "Jurisdiction",
  //         name: "jurisdiction",
  //         required: true,
  //       },
  //       {
  //         header: "Incorp Number",
  //         name: "incorpNumber",
  //         required: true,
  //       },
  //       {
  //         header: "Incorp Date",
  //         name: "incorpDate",
  //         required: true,
  //       },
  //       {
  //         header: "Incorp Batch",
  //         name: "incorpBatch",
  //         required: true,
  //       },
  //       {
  //         header: "Risk Level",
  //         name: "riskLevel",
  //         required: true,
  //       },
  //       {
  //         header: "Status",
  //         name: "status",
  //         required: true,
  //       },
  //       {
  //         header: "Related Entities",
  //         name: "relatedEntities",
  //         required: true,
  //       },
  //     ];
  //   } else if (action == "corporateEntity") {
  //     headersArray = [
  //       {
  //         header: "Corporate Entity Code",
  //         name: "code",
  //         required: true,
  //       },
  //       {
  //         header: "Corporate Entity Name",
  //         name: "name",
  //         required: true,
  //       },
  //       {
  //         header: "Corporate Entity Type",
  //         name: "type",
  //         required: true,
  //       },
  //       {
  //         header: "Incorporation Date",
  //         name: "incorporationDate",
  //         required: true,
  //       },
  //       {
  //         header: "Incorporation Number",
  //         name: "incorporationNumber",
  //         required: true,
  //       },
  //       {
  //         header: "Country Of Incorporation",
  //         name: "incorporationCountry",
  //         required: true,
  //       },
  //       {
  //         header: "Geography of Business",
  //         name: "busGeography",
  //         required: true,
  //       },
  //       {
  //         header: "Nature of Business",
  //         name: "busNature",
  //         required: true,
  //       },
  //       {
  //         header: "Description of Business",
  //         name: "busDesc",
  //         required: true,
  //       },
  //       {
  //         header: "Corporate Tax Info Number",
  //         name: "corpInfoNo",
  //         required: true,
  //       },
  //       {
  //         header: "Address",
  //         name: "address",
  //         required: true,
  //       },
  //       {
  //         header: "Contact Number",
  //         name: "contactNumber",
  //         required: true,
  //       },
  //       {
  //         header: "Email Address",
  //         name: "emailAddress",
  //         required: true,
  //       },
  //     ];
  //   }
  //   const capitalizeRows = (rows) => {
  //     return rows?.map((row) => {
  //       return {
  //         ...row,
  //         type: row.type
  //           ? capitalizeFirstLetter(row.type)
  //           : capitalizeFirstLetter(row.masterType),
  //       };
  //     });
  //   };
  //   let query = convertQueryParams(params);
  //   let payload = {
  //     method: "GET",
  //     url: `/client?${query}`,
  //   };
  //   let data = await fetchApi(payload).then((res) => res?.data);
  //   let tableData = capitalizeRows(data.rows);
  //   if (tableData) {
  //     let exportName =
  //       dataView == "allData" ? "master-search-data-export" : action;

  //     masterFileDownload(
  //       tableData,
  //       `${exportName}-${new Date()}`,
  //       headersArray
  //     );
  //   }
  // };

  const onExportClick = async () => {
    let params = { ...exportParams, exportAll: dataView === "allData" };

    const headersMap = {
      default: [
        { header: "Masterfile Code", name: "code", required: true },
        { header: "Masterfile Name", name: "name", required: true },
        { header: "Masterfile Type", name: "type", required: true },
        { header: "Masterfile Status", name: "status", required: true },
        { header: "Date Created", name: "createdAt", required: true },
        { header: "Created By", name: "createdBy", required: true },
      ],
      client: [
        { header: "Masterfile Name", name: "name", required: true },
        { header: "Client Code", name: "code", required: true },
        { header: "Client Type", name: "type", required: true },
        {
          header: "Parent Client Name",
          name: "parentClientName",
          required: true,
        },
        { header: "Parent Client Code", name: "pCode", required: true },
        {
          header: "Service Fee Category",
          name: "serviceFeeCategory",
          required: true,
        },
        { header: "Jurisdiction", name: "jurisdiction", required: true },
        { header: "Incorp Number", name: "incorpNumber", required: true },
        { header: "Incorp Date", name: "createdAt", required: true },
        { header: "Risk Level", name: "riskLevel", required: true },
        { header: "Parent Client Status", name: "status", required: true },
      ],
      "parent-client": [
        { header: "Parent Client Name", name: "name", required: true },
        { header: "Parent Client Code", name: "code", required: true },
        { header: "Parent Client Type", name: "type", required: true },
        {
          header: "Service Fee Category",
          name: "serviceFeeCategory",
          required: true,
        },
        { header: "Jurisdiction", name: "jurisdiction", required: true },
        { header: "Admin Manager", name: "adminManager", required: true },
        { header: "Administrator", name: "administrator", required: true },
        {
          header: "Relationship Manager",
          name: "relationshipManager",
          required: true,
        },
        { header: "Overview", name: "overview", required: true },
      ],
      introducerClient: [
        { header: "Masterfile Name", name: "name", required: true },
        { header: "Masterfile Code", name: "code", required: true },
        { header: "Masterfile Type", name: "type", required: true },
        {
          header: "Service Fee Category",
          name: "serviceFeeCategory",
          required: true,
        },
        { header: "Jurisdiction", name: "jurisdiction", required: true },
        { header: "Incorp Number", name: "incorpNumber", required: true },
        { header: "Incorp Date", name: "createdAt", required: true },
        { header: "Risk Level", name: "riskLevel", required: true },
        { header: "Status", name: "status", required: true },
      ],
      endUser: [
        { header: "Masterfile Name", name: "name", required: true },
        { header: "Masterfile Code", name: "code", required: true },
        { header: "End User Client Type", name: "type", required: true },
        { header: "Parent Client Code", name: "pCode", required: true },
        {
          header: "Service Fee Category",
          name: "serviceFeeCategory",
          required: true,
        },
        { header: "Jurisdiction", name: "jurisdiction", required: true },
        { header: "Incorp Number", name: "incorpNumber", required: true },
        { header: "Incorp Date", name: "createdAt", required: true },
        { header: "Risk Level", name: "riskLevel", required: true },
        { header: "End User Status", name: "status", required: true },
      ],
      individual: [
        { header: "MasterFile Code", name: "code", required: true },
        { header: "Title", name: "title", required: true },
        { header: "First Name", name: "firstName", required: true },
        { header: "Middle Name", name: "middleName", required: true },
        { header: "Last Name", name: "lastName", required: true },
        { header: "Date of Birth", name: "DOB", required: true },
        { header: "Country of Birth", name: "COB", required: true },
        { header: "Nationality", name: "nationality", required: true },
        { header: "Tax Info Number", name: "taxInfo", required: true },
        { header: "Address", name: "address", required: true },
        { header: "Contact Number", name: "contactNo", required: true },
        { header: "Email Address", name: "email", required: true },
        { header: "Risk Level", name: "riskLevel", required: true },
        { header: "Pep", name: "pep", required: true },
        { header: "Sanction", name: "sanction", required: true },
      ],
      entity: [
        { header: "Entity Name", name: "name", required: true },
        { header: "Entity Code", name: "code", required: true },
        { header: "Entity Type", name: "type", required: true },
        { header: "Masterfile Name", name: "clientName", required: true },
        { header: "Masterfile Code", name: "clientCode", required: true },
        { header: "Client Type", name: "clientType", required: true },
        {
          header: "Parent Client Name",
          name: "parentClientName",
          required: true,
        },
        {
          header: "Parent Client Code",
          name: "parentClientCode",
          required: true,
        },
        {
          header: "Service Fee Category",
          name: "serviceFeeCategory",
          required: true,
        },
        { header: "Jurisdiction", name: "jurisdiction", required: true },
        { header: "Incorp Number", name: "incorpNumber", required: true },
        { header: "Incorp Date", name: "incorpDate", required: true },
        { header: "Incorp Batch", name: "incorpBatch", required: true },
        { header: "Risk Level", name: "riskLevel", required: true },
        { header: "Status", name: "status", required: true },
        { header: "Related Entities", name: "relatedEntities", required: true },
      ],
      corporateEntity: [
        { header: "Corporate Entity Code", name: "code", required: true },
        { header: "Corporate Entity Name", name: "name", required: true },
        { header: "Corporate Entity Type", name: "type", required: true },
        {
          header: "Incorporation Date",
          name: "incorporationDate",
          required: true,
        },
        {
          header: "Incorporation Number",
          name: "incorporationNumber",
          required: true,
        },
        {
          header: "Country Of Incorporation",
          name: "incorporationCountry",
          required: true,
        },
        {
          header: "Geography of Business",
          name: "busGeography",
          required: true,
        },
        { header: "Nature of Business", name: "busNature", required: true },
        { header: "Description of Business", name: "busDesc", required: true },
        {
          header: "Corporate Tax Info Number",
          name: "corpInfoNo",
          required: true,
        },
        { header: "Address", name: "address", required: true },
        { header: "Contact Number", name: "contactNumber", required: true },
        { header: "Email Address", name: "emailAddress", required: true },
      ],
    };

    // Get headers based on action or fallback to default
    const headersArray = headersMap[action] || headersMap.default;

    const capitalizeRows = (rows) => {
      return rows?.map((row) => ({
        ...row,
        type: row.type
          ? capitalizeFirstLetter(row.type)
          : capitalizeFirstLetter(row.masterType),
      }));
    };

    let query = convertQueryParams(params);
    let payload = { method: "GET", url: `/client?${query}` };
    let data = await fetchApi(payload).then((res) => res?.data);
    let tableData = capitalizeRows(data?.rows);

    if (tableData) {
      let exportName =
        dataView === "allData" ? "master-search-data-export" : action;
      masterFileDownload(
        tableData,
        `${exportName}-${new Date()}`,
        headersArray
      );
    }
  };

  const getPagination = async (current, pageSize) => {
    setLoading(true);

    let params = { ...queryParams };
    const filter = encodeURIComponent(JSON.stringify(filters));
    params._start = (current - 1) * pageSize;
    params._limit = pageSize;
    if (filters) {
      params.filters = filter;
    }
    if (action == "client") {
      //   params.masterType = "client"
      dispatch(getClient(params));
    } else if (action == "introducerClient") {
      dispatch(getIntroducerClient(params));
    } else if (action == "parent-client") {
      dispatch(getParentClient(params));
    } else if (action == "end-user") {
      dispatch(getEndUserClient(params));
    } else if (action == "entity") {
      dispatch(getEntity(params));
    } else if (action == "corporateEntity") {
      dispatch(getCorporateEntity(params));
    } else if (action == "individual") {
      dispatch(getIndividual(params));
    } else {
      params.isAll = true;
      let query = convertQueryParams(params);
      let payload = {
        method: "GET",
        url: `/client?${query}`,
      };
      let data = await fetchApi(payload).then((res) => {
        return res?.data;
      });
      const modifiedRows = data?.rows.map((row) => {
        return {
          ...row,
          type: row.type
            ? capitalizeFirstLetter(row.type)
            : capitalizeFirstLetter(row.masterType),
        };
      });
      setTotalCount(data.totalCount);
      setTableData(modifiedRows);
    }
    setQueryParams(params);
    setLoading(false);
  };

  const handleChange = (value) => {
    let tColumn = [];
    let eShow = false;
    switch (value) {
      case "client":
        tColumn = clientColumns;
        break;
      case "introducerClient":
        tColumn = introducerClientColumns;
        break;
      case "parent-client":
        tColumn = parentClientColumns;
        break;
      case "end-user":
        tColumn = endUserClientColumns;
        break;
      case "entity":
        tColumn = entityColumns;
        eShow = true;
        break;
      case "individual":
        tColumn = individualColumns;
        break;
      case "corporateEntity":
        tColumn = corporateEntityColumns;
        break;
      default:
        tColumn = masterFileColumns;
        eShow = false;
        break;
    }

    handleRefresh(value);

    setTableColumn(tColumn);
    setEntityShow(eShow);
  };
  const entityTypeOnChange = (value) => {
    let params = { ...queryParams };
    params.type = value;
    if (action == "entity") {
      dispatch(getEntity(params));
    }
    setQueryParams(params);
  };

  const getFilter = async (value) => {
    setLoading(true);

    let params = { ...queryParams };

    const masterTypeMap = {
      client: "client",
      introducerClient: "introducer-client",
      "parent-client": "parent-client",
      "end-user": "end-user",
      entity: "entity",
      corporateEntity: "cop-entity",
      individual: "individual",
    };

    const filters = encodeURIComponent(JSON.stringify(value));
    params.filters = filters;
    params.isAll = true;
    //  params.masterType = action;
    if (masterTypeMap[action]) {
      params.masterType = masterTypeMap[action];
    }
    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/client?${query}`,
    };
    let data = [];
    data = await fetchApi(payload).then((res) => {
      return res?.data;
    });
    const modifiedRows = data?.rows.map((row) => {
      return {
        ...row,
        type: row.type
          ? capitalizeFirstLetter(row.type)
          : capitalizeFirstLetter(row.masterType),
      };
    });
    setTotalCount(data.totalCount);
    setTableData(modifiedRows);
    setQueryParams(params);
    setFilters(value);
    setLoading(false);
  };
  const getProgress = async (value) => {
    setLoading(true);

    let params = { ...queryParams };
    if (params.type) params.types = params.type;
    params.status = value;
    params.isAll = true;
    const masterTypeMap = {
      client: "client",
      introducerClient: "introducer-client",
      "parent-client": "parent-client",
      "end-user": "end-user",
      entity: "entity",
      corporateEntity: "cop-entity",
      individual: "individual",
    };

    if (masterTypeMap[action]) {
      params.masterType = masterTypeMap[action];
    }

    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/client?${query}`,
    };

    let data = await fetchApi(payload).then((res) => res?.data);
    const modifiedRows = data?.rows.map((row) => {
      return {
        ...row,
        type: row?.type
          ? capitalizeFirstLetter(row?.type)
          : capitalizeFirstLetter(row?.masterType),
      };
    });
    setTotalCount(data.totalCount);
    setTableData(modifiedRows);
    setQueryParams(params);
    setLoading(false);
  };

  const labels = [
    {
      key: "Active",
      label: (
        <>
          <img src={ApprovedIcon} alt="" /> {t.active}
        </>
      ),
    },
    {
      key: "Inactive",
      label: (
        <>
          <img src={InProgressIcon} alt="" /> {t.inactive}
        </>
      ),
    },
  ];
  const handleCancel = () => {
    setExportParams({});
    setDataView("currentData");
    setIsExportModal(false);
  };
  const handleDataViewChange = (e) => {
    let params = { ...exportParams, ...queryParams };
    setDataView(e.target.value);
    setExportParams(params);
  };
  const allOptions = [
    { value: "client", label: "Client" },
    { value: "introducerClient", label: "Introducer Client" },
    { value: "parent-client", label: "Parent Client" },
    { value: "end-user", label: "End User Client" },
    { value: "entity", label: "Entity" },
    { value: "individual", label: "Individual" },
    { value: "corporateEntity", label: "Corporate Entity" },
  ];
  const masterTypeMap = {
    client: "Client",
    introducerClient: "Introducer Client",
    "parent-client": "Parent Client",
    "end-user": "End User Client",
    entity: "Entity",
    corporateEntity: "Corporate Entity",
    individual: "Individual",
  };

  return (
    <div>
      <div className="page-header">
        <h1>{t.masterSearch}</h1>
        <Link to={"/app/master-file-creation"} className="primary-btn">
          {t.createMasterFile}
        </Link>
      </div>
      <div className="page-content">
        {/* <h6 className="primary-title mb-1 mb-md-4">Search Criteria</h6> */}
        <div className="custom-form mb-2 mb-md-3">
          <div className="row justify-content-between align-items-end">
            <div className="col-md-2">
              <label htmlFor="">{t.masterType}</label>
              <Select
                className="custom-select w-100 ptb-1"
                placeholder={t.Selecttype}
                onChange={handleChange}
                allowClear
                value={action}
                options={[
                  {
                    value: "client",
                    label: o.Client,
                  },
                  {
                    value: "introducerClient",
                    label: o.IntroducerClient,
                  },
                  {
                    value: "parent-client",
                    label: o.ParentClient,
                  },
                  {
                    value: "end-user",
                    label: o.EndUserClient,
                  },
                  {
                    value: "entity",
                    label: o.Entity,
                  },
                  {
                    value: "individual",
                    label: o.Individual,
                  },
                  {
                    value: "corporateEntity",
                    label: o.CorporateEntity,
                  },
                ]}
              />
            </div>

            <div className="col-md-3">
              <div className="d-flex justify-content-end align-items-end gap-3">
                {entityShow && (
                  <div className="w-100">
                    <label htmlFor="">{t.entityType}</label>
                    <Select
                      className="custom-select w-100 ptb-1"
                      placeholder="Select type"
                      onChange={entityTypeOnChange}
                      options={[
                        {
                          value: "BVIBC",
                          label: o.BVIBC,
                        },
                        {
                          value: "Trust",
                          label: o.trust,
                        },
                        {
                          value: "generalPartnership",
                          label: o.generalPartnership,
                        },
                        {
                          value: "limitedPartnership",
                          label: o.limitedPartnership,
                        },
                        {
                          value: "foundation",
                          label: o.Foundation,
                        },
                        {
                          value: "",
                          label: o.Blank,
                        },
                      ]}
                    />
                  </div>
                )}
                <button
                  className="primary-btn mb-1"
                  onClick={() => setIsExportModal(true)}
                >
                  <i className="fal fa-file-download"></i> {t.export}
                </button>
              </div>
            </div>
            {/* <div className="col-md-2">
              <button className="primary-btn mb-1">
                <i className="fal fa-search"></i> Search
              </button>
            </div> */}
          </div>
        </div>
        <div className="d-flex justify-content-between mb-3">
          <TabZ
            labels={labels}
            defaultActive={"Active"}
            customClass={"table-tabs"}
            onChange={getProgress}
          />
          <div className="d-flex justify-content-end mb-3">
            <TableFilterZ tableCol={tableColumn} onChange={getFilter} />
          </div>
        </div>

        <Table
          className="custom-table"
          loading={
            action == "client"
              ? client?.isLoading
              : action == "corporateEntity"
              ? corporateEntity?.isLoading
              : action == "individual"
              ? individual?.isLoading
              : action == "entity"
              ? entity?.isLoading
              : action == "introducerClient"
              ? introducer?.isLoading
              : action == "end-user"
              ? endUser?.isLoading
              : action == "parent-client"
              ? parent?.isLoading
              : false || loading
          }
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={tableColumn}
          rowKey={"_id"}
          scroll={{
            x:
              action == "client"
                ? 700
                : action == "corporateEntity"
                ? 3000
                : action == "individual"
                ? 3000
                : action == "entity"
                ? 3000
                : action == "introducerClient"
                ? 400
                : action == "end-user"
                ? 400
                : action == "parent-client"
                ? 400
                : 400,
          }}
          dataSource={tableData}
          pagination={{
            className: "custom-pagination",
            defaultCurrent: 1,
            pageSize: queryParams?._limit,
            total:
              action == "client"
                ? client?.data?.totalCount
                : action == "corporateEntity"
                ? corporateEntity?.data?.totalCount
                : action == "individual"
                ? individual?.data?.totalCount
                : action == "entity"
                ? entity?.data?.totalCount
                : action == "introducerClient"
                ? introducer?.data?.totalCount
                : action == "end-user"
                ? endUser?.data?.totalCount
                : action == "parent-client"
                ? parent?.data?.totalCount
                : totalCount,

            onChange: getPagination,
            itemRender: customPagination,
          }}
        />
      </div>
      <Modal
        title={"Export"}
        className="custom-modal-2"
        open={isExportModal}
        width={600}
        onOk={onExportClick}
        okText="Submit"
        onCancel={handleCancel}
      >
        {!action ? (
          <div>
            <div className="col-md-12 custom-form">
              <label htmlFor="">{t.masterType}</label>
              <Select
                className="custom-select w-100 ptb-1"
                placeholder="Select type"
                onChange={masterTypeOnChange}
                options={allOptions}
                mode="multiple"
              />
            </div>
            <div className="mt-3">
              <Radio.Group
                className="custom-radio"
                onChange={handleDataViewChange}
                value={dataView}
              >
                <Radio value="currentData">{t.currentData}</Radio>
                <Radio value="allData">{t.allData}</Radio>
              </Radio.Group>
            </div>
          </div>
        ) : (
          <>
            {" "}
            <div className="mt-3">
              <label>{t.selectedMasterType}:</label>&nbsp;
              {masterTypeMap[action]}
            </div>
            <div className="mt-3">
              <Radio.Group onChange={handleDataViewChange} value={dataView}>
                <Radio value="currentData">{t.currentData}</Radio>
                <Radio value="allData">{t.allData}</Radio>
              </Radio.Group>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
}
