import { useRef } from "react";
import { Select } from "antd";
import { useSelector } from "react-redux";

import Jurisdiction from "../../components/Jurisdiction";
import FormZ from "../../components/FormZ";

import { sendNotify, getOptions } from "../../helper";

export default function Client() {
  const { data, isLoading } = useSelector((state) => state.country);

  const clientFormRef = useRef();

  let clientFormSchema = [
    {
      name: "clientName",
      value: "",
      type: "text",
      label: "Masterfile Name",
      placeholder: "Enter masterfile name",
    },
    {
      name: "clientCode",
      value: "",
      type: "text",
      label: "Client Code",
      placeholder: "Enter client code",
    },
    {
      name: "clientType",
      value: "",
      type: "select",
      label: "Client Type",
      placeholder: "Enter client type",
      options: [
        {
          value: "IntroducerClient",
          label: "Introducer Client",
        },
        {
          value: "endUserClient",
          label: "End User Client",
        },
      ],
    },
    {
      name: "parentClientName",
      value: "",
      type: "text",
      label: "Parent Client Name",
      placeholder: "Enter parent client name",
    },
    {
      name: "parentClientCode",
      value: "",
      type: "text",
      label: "Parent Client Code",
      placeholder: "Enter parent client code",
    },
    {
      name: "clientGroup",
      value: "",
      type: "text",
      label: "Client Group",
      placeholder: "Enter client group",
    },
    {
      name: "jurisdiction",
      value: "",
      type: "select",
      label: "Jurisdiction",
      placeholder: "Select jurisdiction",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
    },
    {
      name: "billingCategory",
      value: "",
      type: "text",
      label: "Billing Category",
      placeholder: "Enter billing category",
    },
    {
      customElement: (
        <h6 className="sub-title mt-3 mt-0 mb-4">Administrator</h6>
      ),
      customClass: "col-md-12",
    },
    {
      name: "administratorName",
      value: "",
      type: "text",
      label: "Administrator Name",
      placeholder: "Enter administrator name",
    },
    {
      name: "administratorCode",
      value: "",
      type: "text",
      label: "Administrator Code",
      placeholder: "Enter administrator code",
    },
    {
      customElement: (
        <h6 className="sub-title mt-3 mt-0 mb-4">Admin Manager</h6>
      ),
      customClass: "col-md-12",
    },
    {
      name: "adminManagerName",
      value: "",
      type: "text",
      label: "Admin Manager Name",
      placeholder: "Enter admin manager name",
    },
    {
      name: "adminManagerCode",
      value: "",
      type: "text",
      label: "Admin Manager Code",
      placeholder: "Enter admin manager code",
    },
    {
      customElement: (
        <h6 className="sub-title mt-3 mt-0 mb-4">Related Entities</h6>
      ),
      customClass: "col-md-12",
    },
    {
      name: "entityName",
      value: "",
      type: "text",
      label: "Entity Name",
      placeholder: "Enter entity name",
    },
    {
      name: "entityType",
      value: "",
      type: "select",
      label: "Entity Type",
      placeholder: "Select entity type",
      options: [
        {
          value: "BVIBC",
          label: "BVIBC",
        },
        {
          value: "trust",
          label: "Trust",
        },
        {
          value: "foundation",
          label: "Foundation",
        },
        {
          value: "general-partnership",
          label: "General Partnership",
        },
        {
          value: "limited-partnership",
          label: "Limited Partnership",
        },
      ],
    },
    {
      name: "entityCode",
      value: "",
      type: "text",
      label: "Entity Code",
      placeholder: "Enter entity code",
    },
    {
      customElement: <div className="col-md-3"></div>,
    },
    {
      name: "entityName",
      value: "",
      type: "",
      label: "Entity Name",
      placeholder: "Enter entity name",
    },
    {
      name: "entityType",
      value: "",
      type: "select",
      label: "Entity Type",
      placeholder: "Select entity type",
      options: [
        {
          value: "BVIBC",
          label: "BVIBC",
        },
        {
          value: "trust",
          label: "Trust",
        },
        {
          value: "foundation",
          label: "Foundation",
        },
        {
          value: "general-partnership",
          label: "General Partnership",
        },
        {
          value: "limited-partnership",
          label: "Limited Partnership",
        },
      ],
    },
    {
      name: "entityCode",
      value: "",
      type: "text",
      label: "Entity Code",
      placeholder: "Enter entity code",
    },
    {
      customElement: <div className="col-md-3"></div>,
    },
    {
      name: "entityName",
      value: "",
      type: "",
      label: "Entity Name",
      placeholder: "Enter entity name",
    },
    {
      name: "entityType",
      value: "",
      type: "select",
      label: "Entity Type",
      placeholder: "Select entity type",
      options: [
        {
          value: "BVIBC",
          label: "BVIBC",
        },
        {
          value: "trust",
          label: "Trust",
        },
        {
          value: "foundation",
          label: "Foundation",
        },
        {
          value: "general-partnership",
          label: "General Partnership",
        },
        {
          value: "limited-partnership",
          label: "Limited Partnership",
        },
      ],
    },
    {
      name: "entityCode",
      value: "",
      type: "text",
      label: "Entity Code",
      placeholder: "Enter entity code",
    },
    {
      customElement: <div className="col-md-3"></div>,
    },
  ];

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <div>
      <div className="page-header">
        <h1>Client</h1>
        <div className="d-flex gap-3">
          <button className="outline-btn">
            <i className="fal fa-cloud-upload"></i> Import
          </button>
          <button className="primary-btn">
            <i className="fal fa-plus"></i> Add Client
          </button>
        </div>
      </div>
      <div className="page-content">
        <div className="custom-form">
          <h6 className="sub-title mb-4">Client Information</h6>
          <FormZ
            ref={clientFormRef}
            formSchema={clientFormSchema}
            formClass="row mb-3 mb-md-4 gy-3"
            childClass="col-md-3 mb-0"
            labelClass="mb-1"
            inputClass="w-100"
          />
          <button className="primary-btn">
            <i className="fal fa-plus"></i> Add New Related Entity
          </button>
        </div>
      </div>
    </div>
  );
}
