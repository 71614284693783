import React, { useState, useEffect } from "react";
import { Table, Modal, Select, Tooltip, Tag } from "antd";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import AdminAvatarImg from "../../assets/img/admin-avatar.png";
import UserAvatarImg from "../../assets/img/user-avatar.png";
import SampleDoc from "../../assets/pdf/sample.pdf";
import { getOnboarding } from "../../store/onboarding/onboardingSlice";

import {
  Each,
  formatBytes,
  focusOn,
  fetchApi,
  customPagination,
  downloadAsCSV,
  sendNotify,
} from "../../helper";

import UploadZ from "../../components/UploadZ";
import TagZ from "../../components/TagZ";
import TableSortArrows from "../../components/tableSortArrows";

export default function ReviewListing() {
  const dispatch = useDispatch();
  const onboarding = useSelector((state) => state.onboarding);

  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });

  const [commentModal, setCommentModal] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = useState([
    {
      from: "admin",
      name: "Hariharan",
      time: "12:45 PM",
      message: "Hi, welcome to SimpleChat! Go ahead and send me a message.",
    },
    {
      from: "client",
      name: "Guru",
      time: "12:47 PM",
      message: "You can change your name in JS section!",
    },
    {
      from: "admin",
      name: "Hariharan",
      time: "12:48 PM",
      message: "Yeah sure... Thank you",
    },
  ]);
  const [data, setData] = useState([]);
  const [selectRows, setSelectRows] = useState([]);

  const [requestModal, setRequestModal] = useState(false);
  useEffect(() => {
    dispatch(getOnboarding(queryParams));
  }, []);
  useEffect(() => {
    updateData();
  }, [onboarding]);

  const updateDocument = async (e, data) => {
    let file = e.target.files[0];
    if (!file) return;

    try {
      let bodyFormData = new FormData();
      bodyFormData.append("file", file);
      bodyFormData.append("folder", "onboarding");

      let uploadPayload = {
        method: "POST",
        url: `/file`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      };

      let uploadResponse = await fetchApi(uploadPayload);

      let onboardingPayload = {
        method: "GET",
        url: `/onboarding?id=${data.id}`,
      };

      let onboardingResponse = await fetchApi(onboardingPayload);
      let finded = onboardingResponse?.data?.rows?.[0];

      if (!finded) {
        sendNotify("error", "Onboarding record not found.");
        return;
      }

      let documents = finded?.documents || [];
      let type = file.name.substring(file.name.lastIndexOf(".") + 1);
      let obj = {
        document: "",
        name: file.name,
        size: file.size,
        type: type,
        url: uploadResponse.data.url,
      };

      documents.push(obj);

      let updatePayload = {
        method: "PUT",
        url: `/onboarding/update/${finded._id}`,
        data: { documents },
      };

      let updateResponse = await fetchApi(updatePayload);

      if (updateResponse?.status === 200) {
        sendNotify("success", "Document uploaded successfully.");
      } else {
        sendNotify("error", "Failed to update onboarding record.");
      }
    } catch (error) {
      sendNotify("error", error?.message || "An error occurred.");
    }
  };

  const updateData = async () => {
    if (onboarding?.data?.rows?.length) {
      let cat = onboarding?.data?.rows;
      let data = cat.map((a, index) => {
        let obj = {
          key: index,
          id: a._id,
          ref: a.code,
          // name: "Task #1",
          pid: a.pid,
          type: a.type,
          status: "O",
          createdAt: "07/11/2023 04:56PM",
          createdBy: "John Doe",
          entityName: a?.relationshipManager?.entityName,
          //entityCode: "9012",
          requestorID: a?.clientDetails?.code,
          reviewStatus: a.reviewStatus,
          processor: "Hari",
        };
        return obj;
      });
      setData(data);
    }
  };
  const columns = [
    {
      title: "Task Ref",
      dataIndex: "ref",
      sorter: (a, b) => a.ref - b.ref,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // {
    //   title: "Task Name",
    //   dataIndex: "name",
    //   sorter: (a, b) => a.name - b.name,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    // },
    {
      title: "Task Type",
      dataIndex: "type",
      sorter: (a, b) => a.type - b.type,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Created Date and Time",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      sorter: (a, b) => a.createdBy - b.createdBy,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Name",
      dataIndex: "entityName",
      key: "entityName",
      sorter: (a, b) => a.entityName - b.entityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // {
    //   title: "Entity Code",
    //   dataIndex: "entityCode",
    //   key: "entityCode",
    //   sorter: (a, b) => a.entityCode - b.entityCode,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    // },
    {
      title: "Requestor ID",
      dataIndex: "requestorID",
      sorter: (a, b) => a.requestorID - b.requestorID,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Review Status",
      dataIndex: "reviewStatus",
      sorter: (a, b) => a.reviewStatus - b.reviewStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { reviewStatus }) => (
        <Select
          className="custom-select"
          placeholder="Select type"
          value={reviewStatus}
          style={{
            width: 130,
          }}
          options={[
            {
              value: "in-progress",
              label: "In Progress",
            },
            {
              value: "completed",
              label: "Completed",
            },
            {
              value: "pending-info",
              label: "Pending Info",
            },
          ]}
        />
      ),
    },
    {
      title: "Approval Status",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => {
        let text;
        let color;
        switch (status) {
          case "O":
            text = "On Going";
            color = "in-progress";
            break;
          case "A":
            text = "Approved";
            color = "approved";
            break;
          case "R":
            text = "Rejected";
            color = "rejected";
            break;
        }
        return (
          <TagZ status={color} statusText={text} />
          // <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={color}>
          //   {text}
          // </Tag>
        );
        // <Select
        //   className="custom-select"
        //   placeholder="Select type"
        //   value={status}
        //   style={{
        //     width: 160,
        //   }}
        //   options={[
        //     {
        //       value: "in-progress",
        //       label: "In Progress",
        //     },
        //     {
        //       value: "approved",
        //       label: "Approved",
        //     },
        //     {
        //       value: "rejected",
        //       label: "Rejected",
        //     },
        //     {
        //       value: "pending",
        //       label: "Pending",
        //     },
        //   ]}
        // />
      },
    },
    {
      title: "Comments",
      dataIndex: "comments",
      sorter: (a, b) => a.comments - b.comments,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, data) => (
        <button
          className="action-btn icon-btn"
          onClick={() => setCommentModal(true)}
        >
          <i className="far fa-comment-exclamation"></i>
        </button>
      ),
    },
    {
      title: "MLRO",
      dataIndex: "processor",
      sorter: (a, b) => a.processor - b.processor,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Document",
      dataIndex: "ref",
      sorter: (a, b) => a.ref - b.ref,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, id) => (
        <div className="table-upload">
          <label htmlFor="doc" className="outline-btn py-2">
            Upload
          </label>

          <input
            type="file"
            name="doc"
            id="doc"
            onChange={(e) => updateDocument(e, id)}
          />
        </div>
      ),
    },
    {
      title: "Actions",
      // fixed: "right",

      //last

      width: 220,
      render: (_, { status, requestType, id }) => (
        <Link
          to={
            requestType == "Name Check"
              ? `/app/create-new-transfer-in/${id}`
              : `/app/send-to-approval?id=${id}`
          }
          className="d-flex justify-content-between action-btn w-100"
        >
          {status == "A" ? "Ready to Incorporate" : "View Request"} &nbsp;{" "}
          <i className="far fa-long-arrow-right me-0"></i>
        </Link>
      ),
    },
  ];

  // const data = [
  //   {
  //     key: "1",
  //     id: "1",
  //     ref: "#task001",
  //     name: "Task #1",
  //     type: "Active",
  //     status: "O",
  //     createdAt: "07/11/2023 04:56PM",
  //     createdBy: "John Doe",
  //     entityName: "Trust",
  //     entityCode: "9012",
  //     requestorID: "2343",
  //     reviewStatus: "completed",
  //     processor: "Hari",
  //   },
  //   {
  //     key: "2",
  //     id: "2",
  //     ref: "#task002",
  //     name: "Task #2",
  //     type: "Active",
  //     status: "A",
  //     createdAt: "07/11/2023 04:56PM",
  //     createdBy: "John Doe",
  //     entityName: "Trust",
  //     entityCode: "9012",
  //     requestorID: "2343",
  //     reviewStatus: "pending-info",
  //     processor: "Hari",
  //   },
  //   {
  //     key: "3",
  //     id: "3",
  //     ref: "#task003",
  //     name: "Task #3",
  //     type: "Active",
  //     status: "R",
  //     createdAt: "07/11/2023 04:56PM",
  //     createdBy: "John Doe",
  //     entityName: "Trust",
  //     entityCode: "9012",
  //     requestorID: "2343",
  //     reviewStatus: "in-progress",
  //     processor: "Hari",
  //   },
  // ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleOk = () => {
    setCommentModal(false);
  };

  const handleCancel = () => {
    setCommentModal(false);
  };

  const sendComment = () => {
    let arr = [...comments];

    let comment = {
      from: "client",
      name: "Guru",
      time: "12:49 PM",
      message: newComment,
    };

    arr.push(comment);
    setComments(arr);
    setNewComment("");
    focusOn("comment" + (comments.length - 1));
  };

  const documents = [
    {
      url: SampleDoc,
      name: "Inaugural Resolution",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Appointment of First Director",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Resolution of Share Allotment",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Corporate Seal Draft",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Directors Consent Letter",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Customized M&AA",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "UBO Source of Funds Declaration",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Incorporation Application Form",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "AML Verification Guidelines",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Transfer-In Form for BVIBC",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Written Confirmations-Virtual Assets",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Terms Of Business Agreement",
      size: 293333,
      type: "pdf",
      base64: "",
    },
  ];

  function downloadData() {
    if (selectRows?.length != 0) {
      let download = selectRows.map((item, i) => {
        return {
          "Task Ref": item?.ref,
          "Task Type": item?.type,
          "Created Date and Time": item?.createdAt,
          "Created By": item?.createdBy,
          "Entity Name": item?.entityName ?? "N/A",
          "Requestor ID": item?.requestorID ?? "N/A",
          "Review Status": item?.reviewStatus ?? "N/A",
          "Approval Status":
            item?.status == "O"
              ? "On Going"
              : item?.status == "A"
              ? "Approved"
              : item?.status == "R"
              ? "Rejected"
              : "N/A",
          MLRO: item?.processor,
        };
      });
      downloadAsCSV(download, "MLRO Sign Off Dashboard");
    }
  }
  const sendToLeadonClick = async () => {
    let url = `/app/mlro-approver-dashboard`;
    if (selectRows.length) {
      let updatePayload = {
        method: "PUT",
        url: `/onboarding/updateStatus`,
        data: { ids: selectRows.map((a) => a.id), taskStatus: "lead" },
      };

      fetchApi(updatePayload)
        .then((res) => {
          sendNotify("success", res?.data?.message);
        })
        .catch((error) => {
          sendNotify("error", error?.message);
        });
    } else {
      setTimeout(() => {
        window.location.href = url;
      }, 1000);
    }
  };
  const navigatoronClick = () => {
    console.log(selectRows, "selectRows");

    if (selectRows.length == 1) {
      let url = `/app/send-to-approval?id=${selectRows[0].pid}`;

      sendNotify("success", "Now this is going to be sent for approval.");
      setTimeout(() => {
        window.location.href = url;
      }, 1000);
    } else if (selectRows.length > 1) {
      sendNotify("error", "Select a maximum of one item.");
    } else {
      sendNotify("error", "Please select at least one.");
    }
  };
  return (
    <div>
      <div className="page-header">
        <h1>Lead/Manager Approver Dashboard</h1>
        <button className="primary-btn" onClick={sendToLeadonClick}>
          Send to MLRO Sign Off Dashboard
        </button>
      </div>
      <div className="page-content">
        <div className="mb-md-3 d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center">
          <h2 className="primary-title mb-3 mb-md-0">
            Lead/Manager Approval Status
          </h2>
          <div className="d-flex gap-3 flex-column flex-md-row">
            <button
              className="outline-btn"
              onClick={downloadData}
              disabled={selectRows?.length === 0}
            >
              <i className="far fa-arrow-to-bottom"></i> Download Selected
            </button>
            <button className="outline-btn" onClick={navigatoronClick}>
              <i className="far fa-paper-plane"></i> Send to Onboard
            </button>
          </div>
        </div>
        <Table
          className="custom-table"
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={columns}
          dataSource={data}
          scroll={{ x: 2500 }}
          pagination={{
            className: "custom-pagination",
            itemRender: customPagination,
          }}
        />
        <Modal
          title={"View Request"}
          className="custom-modal"
          open={requestModal}
          width={1000}
          onOk={() => setRequestModal(false)}
          okText="Ok"
          onCancel={() => setRequestModal(false)}
        >
          <div className="row gy-3">
            <Each
              array={documents}
              render={(item) => (
                <div className="custom-form col-md-4">
                  <label>{item.name}</label>
                  <UploadZ file={item} downloadOnly={true} />
                </div>
              )}
            />
          </div>
        </Modal>
        <Modal
          title={"Comment"}
          className="custom-modal"
          open={commentModal}
          width={800}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={false}
        >
          <section className="msger w-100">
            <main className="msger-chat">
              <h6 className="msger-date">Today</h6>
              <Each
                array={comments}
                render={(item, i) => (
                  <div
                    id={`comment${i}`}
                    className={`msg ${
                      item?.from == "admin" ? "left-msg" : "right-msg"
                    }`}
                  >
                    <div className="msg-img">
                      <img
                        src={
                          item?.from == "admin" ? AdminAvatarImg : UserAvatarImg
                        }
                      />
                    </div>
                    <div className="msg-bubble">
                      <div className="msg-info">
                        <div className="msg-info-name">{item?.name}</div>
                        <div className="msg-info-time">{item?.time}</div>
                      </div>
                      <div className="msg-text">{item?.message}</div>
                    </div>
                  </div>
                )}
              />
            </main>

            <div className="d-flex gap-2 mt-3 custom-form">
              <input
                type="text"
                className="w-100"
                placeholder="Enter your message..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && sendComment()}
              />
              <button
                type="submit"
                className={`primary-btn`}
                disabled={!newComment}
                onClick={sendComment}
              >
                <i className="fal fa-paper-plane"></i>
                Send
              </button>
            </div>
          </section>
        </Modal>
      </div>
    </div>
  );
}
