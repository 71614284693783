import React, { useState, useRef } from "react";
import { Select, Checkbox } from "antd";

import moment from "moment";
import * as XLSX from "xlsx";
import FormZ from "../components/FormZ";
import TagZ from "../components/TagZ";
import TableSortArrows from "../components/tableSortArrows";
import MasterFileListing from "../components/MasterFileListing";
import {
  Each,
  fetchApi,
  isArrayEmpty,
  getOptions,
  convertQueryParams,
} from "../helper";
var fileDownload = require("js-file-download");

export default function AuditTrail() {
  const [items, setItems] = useState([]);
  const [draggingItem, setDraggingItem] = useState(null);
  const [action, setAction] = useState(null);

  const filterFormRef = useRef();
  const [entityArray, setEntityArray] = useState([]);
  const [masterObjArray, setMasterObjArray] = useState([]);
  const [formData, setFormData] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [count, setCount] = useState(0);
  const [masterData, setMasterData] = useState([]);
  const [plainOptions, satPlainOptions] = useState([]);
  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });

  const searchOnChange = async () => {
    const options = filterFormRef.current.getPayload();
    let params = {};
    if (options.code) params.ids = options?.code;
    if (options.status)
      params.status = options?.status ? options.status : "Active";
    if (options.name) params.name = options.name;
    if (action) params.masterType = action;
    params.exportAll = true;
    params.isAll = true;
    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/client?${query}`,
    };
    let data = await fetchApi(payload).then((res) => {
      setCount(res?.data?.count);
      return res?.data?.rows;
    });
    setMasterData(data);
  };

  const entityOnChange = async (value) => {
    let params = { code: value };

    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/entity?${query}`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res.data.rows;
    });

    let list = data.map((d) => {
      let obj = {
        value: d?.code,
        label: d?.code,
      };
      return obj;
    });
    setMasterObjArray(list);
  };

  const codeOnChange = async (value) => {
    let { entityCode } = value;

    if (entityCode) {
      let payload = {
        method: "GET",
        url: `/entity?_start=0&_limit=1&code=${entityCode}`,
      };
      let data = await fetchApi(payload).then((res) => {
        return res.data.rows;
      });

      setEntityArray(data);
    }
    setFormData(value);
  };

  let filterFormSchema = [
    {
      name: "code",
      value: "",
      type: "select",
      label: "Master Code",
      placeholder: "Select master code",
      mode: "multiple",
      inputClass: "ptb-1",
      showSearch: true,
      allowClear: true,
      maxTagCount: "responsive",
    },
    {
      name: "name",
      value: "",
      type: "text",
      label: "Master Name",
      placeholder: "Enter master name",
    },

    {
      name: "status",
      value: "",
      type: "select",
      label: "Master File Status",
      placeholder: "Select master File status",
      options: [
        {
          value: "Active",
          label: "Active",
        },
        {
          value: "Inactive",
          label: "Inactive",
        },
      ],
    },
    {
      label: "",
      customElement: (
        <button className="primary-btn mb-1" onClick={searchOnChange}>
          <i className="fal fa-search"></i> Initiate Search
        </button>
      ),
    },
    {
      customClass: "col-md-3",
      label: "Total Data / Filter Data",
      customElement: (
        <div className="input-field w-100">
          <div className="custom-badge">{`${totalCount} / ${count}`}</div>
        </div>
      ),
    },
  ];

  const clientColumns = [
    {
      title: "Masterfile Name",
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Code",
      dataIndex: "code",
      filterType: "select",
      filterOptions: getOptions(masterObjArray),
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Client Type",
      dataIndex: "type",
      filterType: "text",
      sorter: (a, b) => a.clientType - b.clientType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Name",
      dataIndex: "parentClientName",
      filterType: "text",
      sorter: (a, b) => a.parentClientName - b.parentClientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Code",
      dataIndex: "pCode",
      filterType: "text",
      sorter: (a, b) => a.parentClientCode - b.parentClientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Service Fee Category",
      dataIndex: "serviceFeeCategory",
      sorter: (a, b) => a.serviceFeeCategory - b.serviceFeeCategory,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 200,
    },
    {
      title: "Jurisdiction",
      dataIndex: "jurisdiction",
      sorter: (a, b) => a.jurisdiction - b.jurisdiction,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Number",
      dataIndex: "incorpNumber",
      filterType: "text",
      sorter: (a, b) => a.incorpNumber - b.incorpNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Task",
      dataIndex: "task",
      sorter: (a, b) => a.task - b.task,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Date",
      dataIndex: "createdAt",
      filterType: "date",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Risk Level",
      dataIndex: "riskLevel",
      sorter: (a, b) => a.riskLevel - b.riskLevel,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={true ? "approved" : "rejected"}
          statusText={true ? "Low" : "High"}
        />
      ),
    },
    {
      title: "Parent Client Status",
      dataIndex: "status",
      filterType: "select",
      filterOptions: [
        {
          value: "approved",
          label: "Approved",
        },
        {
          value: "in-progress",
          label: "In Progress",
        },
      ],
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={status ? "approved" : "rejected"}
          statusText={status.charAt(0).toUpperCase() + status.slice(1)}
        />
      ),
    },
  ];

  const parentClientColumns = [
    {
      title: "Masterfile Name",
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.parentClientName - b.parentClientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Code",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.parentClientCode - b.parentClientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Type",
      dataIndex: "type",
      filterType: "text",
      sorter: (a, b) => a.parentClientType - b.parentClientType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Service Fee Category",
      dataIndex: "serviceFeeCategory",
      sorter: (a, b) => a.serviceFeeCategory - b.serviceFeeCategory,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 200,
    },
    {
      title: "Jurisdiction",
      dataIndex: "jurisdiction",
      sorter: (a, b) => a.jurisdiction - b.jurisdiction,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Admin Manager",
      dataIndex: "adminManager",
      sorter: (a, b) => a.adminManager - b.adminManager,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Administrator",
      dataIndex: "administrator",
      sorter: (a, b) => a.administrator - b.administrator,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Relationship Manager",
      dataIndex: "relationshipManager",
      sorter: (a, b) => a.relationshipManager - b.relationshipManager,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];
  const introducerClientColumns = [
    {
      title: "Masterfile Name",
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Code",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Introducer Client Type",
      dataIndex: "type",
      filterType: "text",
      sorter: (a, b) => a.clientType - b.clientType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Service Fee Category",
      dataIndex: "serviceFeeCategory",
      sorter: (a, b) => a.serviceFeeCategory - b.serviceFeeCategory,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 200,
    },
    {
      title: "Jurisdiction",
      dataIndex: "jurisdiction",
      sorter: (a, b) => a.jurisdiction - b.jurisdiction,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Number",
      dataIndex: "incorpNumber",
      filterType: "text",
      sorter: (a, b) => a.incorpNumber - b.incorpNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Date",
      dataIndex: "createdAt",
      filterType: "date",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Risk Level",
      dataIndex: "riskLevel",
      sorter: (a, b) => a.riskLevel - b.riskLevel,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={true ? "approved" : "rejected"}
          statusText={true ? "Low" : "High"}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      filterType: "select",
      filterOptions: [
        {
          value: "approved",
          label: "Approved",
        },
        {
          value: "in-progress",
          label: "In Progress",
        },
      ],
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={status ? "approved" : "rejected"}
          statusText={status.charAt(0).toUpperCase() + status.slice(1)}
        />
      ),
    },
  ];
  const endUserClientColumns = [
    {
      title: "Masterfile Name",
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Code",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "End User Client Type",
      dataIndex: "type",
      filterType: "text",
      sorter: (a, b) => a.clientType - b.clientType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Code",
      dataIndex: "pCode",
      filterType: "text",
      sorter: (a, b) => a.parentClientCode - b.parentClientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Service Fee Category",
      dataIndex: "serviceFeeCategory",
      sorter: (a, b) => a.serviceFeeCategory - b.serviceFeeCategory,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 200,
    },
    {
      title: "Jurisdiction",
      dataIndex: "jurisdiction",
      sorter: (a, b) => a.jurisdiction - b.jurisdiction,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Number",
      dataIndex: "incorpNumber",

      sorter: (a, b) => a.incorpNumber - b.incorpNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Date",
      dataIndex: "createdAt",
      filterType: "date",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Risk Level",
      dataIndex: "riskLevel",
      sorter: (a, b) => a.riskLevel - b.riskLevel,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={true ? "approved" : "rejected"}
          statusText={true ? "Low" : "High"}
        />
      ),
    },
    {
      title: "End User Status",
      dataIndex: "status",
      filterType: "select",
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={status ? "approved" : "rejected"}
          statusText={status.charAt(0).toUpperCase() + status.slice(1)}
        />
      ),
    },
  ];

  const individualColumns = [
    {
      title: "MasterFile Code",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.individualCode - b.individualCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Title",
      dataIndex: "title",
      filterType: "text",
      sorter: (a, b) => a.title - b.title,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      filterType: "text",
      sorter: (a, b) => a.firstName - b.firstName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Middle Name",
      dataIndex: "middleName",
      filterType: "text",
      sorter: (a, b) => a.middleName - b.middleName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 200,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      filterType: "text",
      sorter: (a, b) => a.lastName - b.lastName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Date of Birth",
      dataIndex: "DOB",
      filterType: "date",
      sorter: (a, b) => a.dateOfBirth - b.dateOfBirth,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Country of Birth",
      dataIndex: "COB",
      sorter: (a, b) => a.countryOfBirth - b.countryOfBirth,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Nationality",
      dataIndex: "nationality",
      sorter: (a, b) => a.nationality - b.nationality,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Tax Info Number",
      dataIndex: "taxInfo",
      filterType: "text",
      sorter: (a, b) => a.taxInfoNumber - b.taxInfoNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Address",
      dataIndex: "address",
      sorter: (a, b) => a.address - b.address,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Contact Number",
      dataIndex: "contactNo",
      filterType: "text",
      sorter: (a, b) => a.contactNumber - b.contactNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Email Address",
      dataIndex: "email",
      filterType: "text",
      sorter: (a, b) => a.emailAddress - b.emailAddress,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Risk Level",
      dataIndex: "riskLevel",
      sorter: (a, b) => a.riskLevel - b.riskLevel,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={true ? "approved" : "rejected"}
          statusText={true ? "Low" : "High"}
        />
      ),
    },
    {
      title: "Pep",
      dataIndex: "pep",
      sorter: (a, b) => a.pep - b.pep,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={true ? "approved" : "rejected"}
          statusText={true ? "Yes" : "No"}
        />
      ),
    },
    {
      title: "Sanction",
      dataIndex: "sanction",
      sorter: (a, b) => a.sanction - b.sanction,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={false ? "approved" : "rejected"}
          statusText={false ? "Yes" : "No"}
        />
      ),
    },
  ];

  const corporateEntityColumns = [
    {
      title: "Corporate Entity Code",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.corporateEntityCode - b.corporateEntityCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Corporate Entity Name",
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.corporateEntityName - b.corporateEntityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Corporate Entity Type",
      dataIndex: "type",
      sorter: (a, b) => a.corporateEntityType - b.corporateEntityType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorporation Date",
      dataIndex: "incorporationDate",
      filterType: "date",
      sorter: (a, b) => a.incorporationDate - b.incorporationDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "incorporationNumber",
      dataIndex: "incorporationNumber",
      filterType: "text",
      sorter: (a, b) => a.incorporationNumber - b.incorporationNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Country Of Incorporation",
      dataIndex: "incorporationCountry",
      sorter: (a, b) => a.countryOfIncorporation - b.countryOfIncorporation,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Geography of business",
      dataIndex: "busGeography",
      sorter: (a, b) => a.geographyOfBusiness - b.geographyOfBusiness,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Nature of Business",
      dataIndex: "busNature",
      sorter: (a, b) => a.natureOfBusiness - b.natureOfBusiness,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Description of Business",
      dataIndex: "busDesc",
      filterType: "text",
      sorter: (a, b) => a.natureOfBusiness - b.natureOfBusiness,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Corporate Tax Info Number",
      dataIndex: "corpInfoNo",
      filterType: "text",
      sorter: (a, b) => a.taxInfoNumber - b.taxInfoNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Address",
      dataIndex: "address",
      filterType: "text",
      sorter: (a, b) => a.address - b.address,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      filterType: "text",
      sorter: (a, b) => a.contactNumber - b.contactNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Email Address",
      dataIndex: "emailAddress",
      filterType: "text",
      sorter: (a, b) => a.emailAddress - b.emailAddress,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];
  const entityColumns = [
    {
      title: "Entity Name",
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.entityName - b.entityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Code",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.entityCode - b.entityCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Type",
      dataIndex: "type",
      filterType: "text",
      sorter: (a, b) => a.entityType - b.entityType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Name",
      dataIndex: "clientName",
      filterType: "text",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Code",
      dataIndex: "clientCode",
      filterType: "text",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Client Type",
      dataIndex: "clientType",
      filterType: "text",
      sorter: (a, b) => a.clientType - b.clientType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Name",
      dataIndex: "parentClientName",
      filterType: "text",
      sorter: (a, b) => a.parentClientName - b.parentClientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Code",
      dataIndex: "parentClientCode",
      filterType: "text",
      sorter: (a, b) => a.parentClientCode - b.parentClientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Service Fee Category",
      dataIndex: "serviceFeeCategory",
      sorter: (a, b) => a.serviceFeeCategory - b.serviceFeeCategory,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 200,
    },
    {
      title: "Jurisdiction",
      dataIndex: "jurisdiction",
      sorter: (a, b) => a.jurisdiction - b.jurisdiction,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Number",
      dataIndex: "incorpNumber",
      sorter: (a, b) => a.incorpNumber - b.incorpNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Date",
      dataIndex: "incorpDate",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Batch",
      dataIndex: "incorpBatch",
      sorter: (a, b) => a.incorpBatch - b.incorpBatch,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Risk Level",
      dataIndex: "riskLevel",
      sorter: (a, b) => a.riskLevel - b.riskLevel,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={true ? "approved" : "rejected"}
          statusText={true ? "Low" : "High"}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      filterType: "select",
      filterOptions: [
        {
          value: "Active",
          label: "Active",
        },
        {
          value: "Inactive",
          label: "Inactive",
        },
      ],
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={status == "active" ? "approved" : "rejected"}
          statusText={status?.charAt(0)?.toUpperCase() + status?.slice(1)} //
        />
      ),
    },

    {
      title: "Related Entities",
      dataIndex: "relatedEntities",
      width: 200,
      render: (_, { status }) => (
        <div className="custom-form">
          <input type="text" placeholder="Enter related entities" />
        </div>
      ),
    },
  ];
  const plainClientOptions = [
    "Client Name",
    "Client Type",
    "Client Code",
    "Client Status",
    "MasterType",
    "Email",
    "Contact No",
    "Jurisdiction",
    "Parent Client Code",
    "Relationship Manager",
    "Administrator",
    "CreatedAt",
    "CreatedBy",
    "IncorpNumber",
    "Fee Type",
  ];
  const plainIntroducerClient = [
    "Introducer Client Name",
    "Introducer Client Type",
    "Introducer Client Code",
    "Introducer Client Status",
    "MasterType",
    "Email",
    "Contact No",
    "Jurisdiction",
    "Relationship Manager",
    "Administrator",
    "CreatedAt",
    "Status",
    "CreatedBy",
    "IncorpNumber",
    "Fee Type",
  ];
  const plainParentClient = [
    "Parent Client Name",
    "Parent Client Type",
    "Parent Client Code",
    "Parent Client Status",
    "MasterType",
    "Email",
    "Contact No",
    "Jurisdiction",
    "Relationship Manager",
    "Administrator",
    "AdminManager",
    "CreatedAt",
    "Status",
    "CreatedBy",
    "IncorpNumber",
    "Fee Type",
  ];
  const plainEntityOptions = [
    "Entity Name",
    "Entity Type",
    "Entity Code",
    "MasterType",
    "Entity Status",
    "Email",
    "Contact No",
    "Jurisdiction",
    "PCode",
    "Client Name",
    "Client Code",
    "Client Type",
    "Incorporation Number",
    "Incorporation Date",
    "RM Name",
    "Administrator",
    "AdminManager",
    "CreatedAt",
    "IncorpNumber",
    "AuthorisedShares",
    "FinancialRecordsAddress",
    "FinancialRecordsDetails",
    "PrimaryContact",
    "RelatedEntities",
  ];
  const plainEndUserClient = [
    "EndUser Client Name",
    "EndUser Client Type",
    "EndUser Client Code",
    "EndUser Client Status",
    "MasterType",
    "Email",
    "Contact No",
    "pCode",
    "Jurisdiction",
    "Relationship Manager",
    "Administrator",
    "AdminManager",
    "CreatedAt",
    "Status",
    "CreatedBy",
    "IncorpNumber",
    "Fee Type",
  ];
  const plainIndividualOptions = [
    "Individual Code",
    "Title",
    "FirstName",
    "MiddleName",
    "LastName",
    "DOB",
    "COB",
    "Nationality",
    "PassportNo",
    "PassportIssue",
    "PassportExp",
    "IdNo",
    "IdIssue",
    "IdExp",
    "AssociateBus",
    "RelatedEntities",
    "ResidentialAddress",
    "ServiceAddress",
    "Details",
    "CreatedAt",
    "Status",
    "MasterType",
    "CreatedBy",
  ];
  const plainCorporateEntity = [
    "Corporate Entity Code",
    "Corporate Entity Name",
    "Type",
    "IncorporationDate",
    "IncorporationNumber",
    "IncorporationCountry",
    "BusGeography",
    "BusNature",
    "BusDesc",
    "CorpInfoNo",
    "RelatedEntityCode",
    "IdExp",
    "Details",
    "CreatedAt",
    "Status",
    "MasterType",
    "CreatedBy",
  ];

  const checkAll = (e) => {
    setItems(e.target.checked ? plainOptions : []);
  };

  const onChange = (checkedValues) => {
    setItems(checkedValues);
  };

  const handleDragStart = (e, item) => {
    setDraggingItem(item);
    e.dataTransfer.setData("text/plain", "");
  };

  const handleDragEnd = () => {
    setDraggingItem(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetItem) => {
    // let obj = { ...list };
    // const { draggingItem, items } = obj;

    if (!draggingItem) return;

    const currentIndex = items.indexOf(draggingItem);
    const targetIndex = items.indexOf(targetItem);

    if (currentIndex !== -1 && targetIndex !== -1) {
      items.splice(currentIndex, 1);
      items.splice(targetIndex, 0, draggingItem);
      setItems(items);
    }
  };

  const removeItem = (index) => {
    let arr = [...items];
    arr.splice(index, 1);
    setItems(arr);
  };

  const commonFields = (a, index) => ({
    sno: index + 1,
    _id: a._id,
    code: a.code,
    type: a.type,
    name: a.name,
    masterType: a.masterType,
    email: a.email,
    contactNo: a.contactNo,
    jurisdiction: a.jurisdiction,
    relationshipManager: a.relationshipManager,
    administrator: a.administrator,
    adminManager: a.adminManager,
    createdAt: a.createdAt,
    status: a.status,
    createdBy: a.createdBy,
    incorpNumber: a.incorpNumber,
    feeType: a.feeType,
  });

  const handleExcelExport = async () => {
    let masterTypes = [...new Set(masterData.map((item) => item.masterType))];
    let data = [];

    const processData = (masterType, customFields) => {
      return masterData.map((a, index) => ({
        ...commonFields(a, index),
        ...customFields(a),
      }));
    };

    if (masterTypes.includes("client")) {
      data = processData("client", (a) => ({
        pCode: a.pCode,
      }));
    } else if (masterTypes.includes("introducer-client")) {
      data = processData("introducer-client", () => ({}));
    } else if (masterTypes.includes("parent-client")) {
      data = processData("parent-client", (a) => ({
        pCode: a.pCode,
      }));
    } else if (masterTypes.includes("end-user")) {
      data = processData("end-user", (a) => ({
        pCode: a.pCode,
      }));
    } else if (masterTypes.includes("entity")) {
      data = processData("entity", (a) => ({
        pCode: a.pCode,
        clientName: "dfgsdg",
        clientCode: "zxcZXXCcZ",
        clientType: "intermediary",
        incorporationDate: "2024-04-21T18:30:00.000Z",
        incorporationNumber: "9876543",
        authorisedShares: a.authorisedShares,
        financialRecordsAddress: `Address : ${a?.financialRecords?.address?.line_1} ,${a?.financialRecords?.address?.line_2} ,${a?.financialRecords?.address?.line_3}`,
        financialRecordsDetails: a.financialRecords.details.map((f) => ({
          title: f?.data?.title,
          recordHolder: f?.data?.recordHolder,
          mobile: f?.data?.mobile,
          email: f?.data?.email,
          status: f?.data?.status,
        })),
        primaryContact: a.primaryContact.map((p) => ({
          name: p.data.name,
          mobile: p.data.mobile,
          email: p.data.email,
          status: p.data.status,
        })),
        relatedEntities: "",
      }));
    } else if (masterTypes.includes("individual")) {
      data = processData("individual", (a) => ({
        title: a.title,
        firstName: a.firstName,
        middleName: a.middleName,
        lastName: a.lastName,
        DOB: moment(a.DOB).format("DD/MM/YYYY"),
        COB: a.COB,
        nationality: a.nationality,
        passportNo: a.passportNo,
        passportIssue: moment(a.passportIssue).format("DD/MM/YYYY"),
        passportExp: moment(a.passportExp).format("DD/MM/YYYY"),
        idNo: a.idIssue,
        idIssue: moment(a.idIssue).format("DD/MM/YYYY"),
        idExp: moment(a.idIssue).format("DD/MM/YYYY"),
        associateBus: a.associateBus,
        relatedEntities: a.relatedEntities,
        residentialAddress: a.residentialAddress,
        serviceAddress: a.serviceAddress,
        details: {
          pep: a.details.pep,
          sanction: a.details.sanction,
        },
      }));
    } else if (masterTypes.includes("corporate-entity")) {
      data = processData("corporate-entity", (a) => ({
        incorporationDate: a.incorporationDate,
        incorporationNumber: a.incorporationNumber,
        incorporationCountry: a.incorporationCountry,
        busNature: a.busNature,
        busDesc: a.busDesc,
        corpInfoNo: a.corpInfoNo,
        relatedEntityCode: a.relatedEntityCode,
        details: {
          address: {
            line_1: a.details.address.line_1,
            line_2: a.details.address.line_2,
            line_3: a.details.address.line_3,
            country: a.details.address.country,
          },
        },
      }));
    }

    const renamedData = renameHeaders(data, items);
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [Object.keys(renamedData[0])]);
    XLSX.utils.sheet_add_json(ws, renamedData, {
      origin: "A2",
      skipHeader: true,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { type: "array", cellStyles: true });
    const finalData = new Blob([excelBuffer]);

    fileDownload(finalData, `${new Date().toISOString()}-audit-trail.xlsx`);
  };

  const masterTypeMappings = {
    client: {
      "Client Name": "name",
      "Client Type": "type",
      "Client Code": "code",
      "Client Status": "status",
      MasterType: "masterType",
      Email: "email",
      "Contact No": "contactNo",
      Jurisdiction: "jurisdiction",
      "Parent Client Code": "parentClientCode",
      "Relationship Manager": "relationshipManager",
      Administrator: "administrator",
      CreatedAt: "createdAt",
      CreatedBy: "createdBy",
      IncorpNumber: "incorpNumber",
      "Fee Type": "feeType",
    },
    "introducer-client": {
      "Introducer Client Name": "name",
      "Introducer Client Type": "type",
      "Introducer Client Code": "code",
      "Introducer Client Status": "status",
      MasterType: "masterType",
      Email: "email",
      "Contact No": "contactNo",
      Jurisdiction: "jurisdiction",
      "Relationship Manager": "relationshipManager",
      Administrator: "administrator",
      "Created At": "createdAt",
      "Created By": "createdBy",
      "Incorporation Number": "incorpNumber",
      "Fee Type": "feeType",
    },
    "parent-client": {
      "Parent Client Name": "name",
      "Parent Client Type": "type",
      "Parent Client Code": "code",
      "Parent Client Status": "status",
      MasterType: "masterType",
      Email: "email",
      "Contact No": "contactNo",
      Jurisdiction: "jurisdiction",
      "Relationship Manager": "relationshipManager",
      Administrator: "administrator",
      "Admin Manager": "adminManager",
      "Created At": "createdAt",
      "Created By": "createdBy",
      "Incorporation Number": "incorpNumber",
      "Fee Type": "feeType",
    },

    entity: {
      "Entity Name": "name",
      "Entity Type": "type",
      "Entity Code": "code",
      MasterType: "masterType",
      "Entity Status": "status",
      Email: "email",
      "Contact No": "contactNo",
      Jurisdiction: "jurisdiction",
      PCode: "pCode",
      "Client Name": "clientName",
      "Client Code": "clientCode",
      "Client Type": "clientType",
      "Incorporation Number": "incorporationNumber",
      "Incorporation Date": "incorporationDate",
      "RM Name": "relationshipManager",
      Administrator: "administrator",
      AdminManager: "adminManager",
      CreatedAt: "createdAt",
      IncorpNumber: "incorpNumber",
      AuthorisedShares: "authorisedShares",
      FinancialRecordsAddress: "financialRecordsAddress",
      FinancialRecordsDetails: "financialRecordsDetails",
      PrimaryContact: "primaryContact",
      RelatedEntities: "relatedEntities",
    },
    "end-user": {
      "EndUser Client Name": "name",
      "EndUser Client Type": "type",
      "EndUser Client Code": "code",
      "EndUser Client Status": "",
      MasterType: "masterType",
      Email: "email",
      "Contact No": "contactNo",
      PCode: "pCode",
      Jurisdiction: "jurisdiction",
      "Relationship Manager": "relationshipManager",
      Administrator: "administrator",
      AdminManager: "adminManager",
      CreatedAt: "createdAt",
      Status: "status",
      CreatedBy: "createdBy",
      IncorpNumber: "incorpNumber",
      "Fee Type": "feeType",
    },
    individual: {
      "Individual Code": "code",
      Title: "title",
      FirstName: "firstName",
      MiddleName: "middleName",
      LastName: "lastName",
      DOB: "DOB",
      COB: "COB",
      Nationality: "nationality",
      PassportNo: "passportNo",
      PassportIssue: "passportIssue",
      PassportExp: "passportExp",
      IdNo: "idNo",
      IdIssue: "idIssue",
      IdExp: "idExp",
      AssociateBus: "associateBus",
      RelatedEntities: "relatedEntities",
      ResidentialAddress: "residentialAddress",
      ServiceAddress: "serviceAddress",
      Details: "details",
      CreatedAt: "createdAt",
      Status: "status",
      MasterType: "masterType",
      CreatedBy: "createdBy",
    },

    "corporate-entity": {
      "Corporate Entity Code": "code",
      "Corporate Entity Name": "name",
      Type: "type",
      IncorporationDate: "incorporationDate",
      IncorporationNumber: "incorporationNumber",
      IncorporationCountry: "incorporationCountry",
      BusGeography: "busGeography",
      BusNature: "busNature",
      BusDesc: "busDesc",
      CorpInfoNo: "corpInfoNo",
      RelatedEntityCode: "relatedEntityCode",
      IdExp: "idExp",
      Details: "details",
      CreatedAt: "createdAt",
      Status: "status",
      MasterType: "masterType",
      CreatedBy: "createdBy",
    },
  };
  const renameHeaders = (dataArray, headerItems) => {
    return dataArray.map((item) => {
      const renamedItem = {};
      const masterTypeMap = masterTypeMappings[item.masterType.toLowerCase()];

      if (masterTypeMap) {
        Object.keys(masterTypeMap).forEach((header) => {
          if (headerItems.includes(header)) {
            renamedItem[header] = item[masterTypeMap[header]];
          }
        });
      }

      return renamedItem;
    });
  };
  const handleRefresh = async (value) => {
    let params = { ...queryParams };
    params.isAll = true;
    params.options = true;
    if (value == "client") {
      params.masterType = "client";
    } else if (value == "introducerClient") {
      value = "introducer-client";
      params.masterType = "introducer-client";
    } else if (value == "parent-client") {
      params.masterType = "parent-client";
    } else if (value == "end-user") {
      params.masterType = "end-user";
    } else if (value == "entity") {
      params.masterType = "entity";
    } else if (value == "corporateEntity") {
      value = "corporate-entity";
      params.masterType = "cop-entity";
    } else if (value == "individual") {
      params.masterType = "individual";
    }
    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/client?${query}`,
    };
    let data = await fetchApi(payload).then((res) => {
      setTotalCount(res?.data?.totalCount);
      return res?.data?.rows;
    });

    setMasterObjArray(data);
    setQueryParams(params);

    setAction(value);
  };
  const filterByType = async (value) => {
    const optionsMap = {
      entity: plainEntityOptions,
      client: plainClientOptions,
      introducerClient: plainIntroducerClient,
      "parent-client": plainParentClient,
      "end-user": plainEndUserClient,
      individual: plainIndividualOptions,
      corporateEntity: plainCorporateEntity,
    };
    if (value && value.length === 0) {
      const allOptions = Object.values(optionsMap).flat();
      satPlainOptions(allOptions);
    } else {
      const selectedOptions = optionsMap?.[value];
      if (selectedOptions) {
        satPlainOptions(selectedOptions);
      }
    }
  };
  const handleChange = async (value) => {
    let tColumn = [];
    let eShow = false;
    switch (value) {
      case "client":
        tColumn = clientColumns;

        break;
      case "introducerClient":
        tColumn = introducerClientColumns;

        break;
      case "parent-client":
        tColumn = parentClientColumns;

        break;
      case "end-user":
        tColumn = endUserClientColumns;

        break;
      case "entity":
        tColumn = entityColumns;

        eShow = true;
        break;
      case "individual":
        tColumn = individualColumns;

        break;
      case "corporateEntity":
        tColumn = corporateEntityColumns;
        break;
      default:
        tColumn = entityColumns;

        eShow = false;
        break;
    }

    handleRefresh(value);
    filterByType(value);
  };
  return (
    <div>
      <div className="page-header">
        <h1>Audit Trail</h1>
      </div>
      <div className="page-content">
        <h6 className="primary-title mb-1 mb-md-4">Search</h6>
        <MasterFileListing />

        <h6 className="primary-title mb-1 mb-md-4">Reporting</h6>

        <div className="custom-form mb-2 mb-md-3">
          <div className="row align-items-end">
            <div className="col-md-2">
              <label htmlFor="">Masterfile type</label>
              <Select
                className="custom-select w-100 ptb-1"
                placeholder="Select type"
                onChange={handleChange}
                allowClear
                value={action}
                options={[
                  {
                    value: "client",
                    label: "Client",
                  },
                  {
                    value: "introducerClient",
                    label: "Introducer Client",
                  },
                  {
                    value: "parent-client",
                    label: "Parent Client",
                  },
                  {
                    value: "end-user",
                    label: "End User Client",
                  },
                  {
                    value: "entity",
                    label: "Entity",
                  },
                  {
                    value: "individual",
                    label: "Individual",
                  },
                  {
                    value: "corporateEntity",
                    label: "Corporate Entity",
                  },
                ]}
              />
            </div>
          </div>
        </div>

        <FormZ
          formClass="row custom-form mb-2 mb-md-4 align-items-end gy-3"
          childClass="col-md-2 mb-0"
          labelClass="mb-1"
          inputClass="w-100"
          formSchema={filterFormSchema}
          ref={filterFormRef}
          formData={formData}
          formOptions={{ code: masterObjArray }}
          onKeyUp={(e) => entityOnChange(e.target.value)}
          onChange={codeOnChange}
        />
        <div className="row g-0">
          <div className="col-md-3">
            <div className="check-list">
              <h2 className="list-title">Select Columns:</h2>
              <div className="scroll-500 p-3">
                <Checkbox onChange={checkAll} className="custom-checkbox mb-2">
                  All
                </Checkbox>
                <Checkbox.Group
                  options={plainOptions}
                  value={items}
                  onChange={onChange}
                  className="custom-checkbox"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="selected-list pt-0">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h2 className="list-title mb-0 border-0 p-0">
                  Rearrange column if needed:
                </h2>
                <button
                  className="primary-btn py-2"
                  disabled={isArrayEmpty(items)}
                  onClick={handleExcelExport}
                >
                  Export
                </button>
              </div>
              <div className="scroll-500 pe-2">
                <Each
                  array={items}
                  render={(item, index) => (
                    <div
                      key={index}
                      className={`item ${
                        item === draggingItem ? "dragging" : ""
                      }`}
                      draggable="true"
                      onDragStart={(e) => handleDragStart(e, item)}
                      onDragEnd={handleDragEnd}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDrop(e, item)}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center gap-3">
                          <span>
                            <i className="far fa-ellipsis-v"></i>
                            <i className="far fa-ellipsis-v"></i>
                          </span>
                          <span>{item}</span>
                        </div>
                        <button
                          className="remove-btn"
                          onClick={() => removeItem(index)}
                        >
                          <i className="fal fa-times"></i>
                        </button>
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
