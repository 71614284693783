import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Table, Select, Checkbox } from "antd";

import { Each, focusOn, getQuery, fetchApi, sendNotify } from "../../helper";
import UploadZ from "../../components/UploadZ";

import FormZ from "../../components/FormZ";
import TableSortArrows from "../../components/tableSortArrows";

import CorporateKYC from "./components/corporate-kyc";
import IndividualKYC from "./components/individual-kyc";
import RelatedEntityKYC from "./components/relatedEntity-kyc";

export default function Review() {
  let query = getQuery();
  const directorFormRef = useRef([]);
  const shareholderFormRef = useRef([]);
  const uboFormRef = useRef([]);
  const [directorChecked, setDirectorChecked] = useState(false);
  const [reserveChecked, setReserveChecked] = useState(false);
  const [sanction, setSanction] = useState("Y");
  const [pep, setPep] = useState("Y");
  const [clearDocuments, setClearDocuments] = useState(false);

  const [corporateKYC, setCorporateKYC] = useState([]);
  const [individualKYC, setIndividualKYC] = useState([]);
  const [relatedEntityKYC, setRelatedEntityKYC] = useState([]);

  const [checkValue, setCheckValue] = useState({});
  const [forms, setForms] = useState({
    individuals: [1],
    directors: [1],
    shareholders: [1],
    ubo: [1],
  });
  const [data, setData] = useState([]);
  const [onboardingData, setOnboardingData] = useState({});
  const [upload, setUpload] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [pepText, setPepText] = useState("");
  const [sanctionText, setSanctionText] = useState("");
  const [individualCustom, setIndividualCustom] = useState([]);
  const [shareholderChecked, setShareholderChecked] = useState(false);
  const [nomineeChecked, setNomineeChecked] = useState(false);
  const [masterId, setMasterId] = useState("");
  const [selectedFiles, setSelectedFiles] = useState("");

  useEffect(() => {
    if (query.id) {
      getTaskData(query.id);
    }
  }, []);

  const getTaskData = async (id) => {
    let payload = {
      method: "get",
      url: `/incorporation?_id=${id}`,
    };
    let dataArray = [];
    let data = [];
    fetchApi(payload)
      .then(async (res) => {
        data = res?.data?.rows[0];
        dataArray = [
          {
            key: data._id,
            id: data._id,
            serviceType: data.type,
            serviceRef: data.code,
            taskType: "COGS",
            reqID: "2346",
            entityName: data.entityName,
            entityCode: data.masterCode,
            clientName: "Sample",
            clientCode: "8022",
            clientType: "Introducer Client",
            date: data.createdAt,
            assignee: data.requested.name,
          },
        ];
        setMasterId(data.masterId);
        setData(dataArray);
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });

    let onboardingpayload = {
      method: "get",
      url: `/onboarding?pid=${id}`,
    };

    fetchApi(onboardingpayload)
      .then(async (res) => {
        setOnboardingData(res?.data?.rows[0]);
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };

  const addNewIndividualDoc = () => {
    let arr = [...individualCustom];
    let index = arr.length + 1;
    arr.push(`Enter Document Name ${index}`);
    setIndividualCustom(arr);
  };

  const removeIndividualDoc = (index) => {
    let arr = [...individualCustom];
    arr.splice(index, 1);
    setIndividualCustom(arr);
  };
  const removeUploadOnIndex = (index, docName) => {
    let arr = [...selectedFiles];
    arr.splice(index, 1);
    removeDocument(docName);
    setSelectedFiles(arr);
  };

  function removeDocument(docName) {
    if (docName) {
      let allDoc = { ...documents };
      let doc = allDoc;
      let index = doc?.findIndex((d) => d?.document == docName);
      if (index != -1) {
        doc.splice(index, 1);
        allDoc = doc;
        setDocuments(allDoc);
      }
    }
  }
  const getLabelName = (value, index) => {
    let arr = [...individualCustom];
    arr[index] = value;
    setIndividualCustom(arr);
  };

  function storeDocuments(file) {
    let doc = [...documents];
    doc.push(file);
    console.log(doc);
    setDocuments(doc);
  }
  function storeManyDocuments(file) {
    setDocuments((prevDocs) => {
      const updatedDocs = [
        ...prevDocs,
        ...(Array.isArray(file) ? file : [file]),
      ];
      console.log(updatedDocs, "Updated Documents");
      return updatedDocs;
    });
  }
  const handleChange = (value) => {
    setSanction(value);
  };

  const handleChangePep = (value) => {
    setPep(value);
  };

  const columns = [
    {
      title: "Service Ref",
      dataIndex: "serviceRef",
      sorter: (a, b) => a.serviceRef - b.serviceRef,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Service Type",
      dataIndex: "serviceType",
      sorter: (a, b) => a.serviceType - b.serviceType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Task Type",
      dataIndex: "taskType",
      sorter: (a, b) => a.taskType - b.taskType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // {
    //   title: "Requestor ID",
    //   dataIndex: "reqID",
    //   sorter: (a, b) => a.reqID - b.reqID,
    // sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    // },
    {
      title: "Entity Name",
      dataIndex: "entityName",
      sorter: (a, b) => a.entityName - b.entityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      sorter: (a, b) => a.entityCode - b.entityCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Client Code",
      dataIndex: "clientCode",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Client Type",
      dataIndex: "clientType",
      sorter: (a, b) => a.clientType - b.clientType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Request Date",
      dataIndex: "date",
      sorter: (a, b) => a.date - b.date,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Assignee",
      dataIndex: "assignee",
      sorter: (a, b) => a.assignee - b.assignee,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const addNewUpload = () => {
    let arr = [...upload];
    arr.push(arr.length + 1);
    setUpload(arr);
  };

  const removeUpload = (index) => {
    let arr = [...upload];
    arr.splice(index, 1);
    setUpload(arr);
  };

  const addNewCol = (key, { focus }) => {
    let obj = { ...forms };
    let arr = [...obj[key]];
    arr.push(arr.length + 1);
    obj[key] = arr;
    setForms(obj);

    if (focus) {
      focusOn(key + (arr.length - 1));
    }
  };

  const removeNewCol = (key, index) => {
    let obj = { ...forms };
    let arr = [...obj[key]];
    arr.splice(index, 1);
    obj[key] = arr;
    setForms(obj);
  };
  console.log(onboardingData, "onboardingData");

  const onSubmit = () => {
    let url = `/app/lead-manager-approver-dashboard`;
    if (!onboardingData?._id) {
      let data = {};
      data.masterId = masterId;
      data.pid = query.id;
      data.type = "Approval Req";
      data.corporate = corporateKYC;
      data.relatedEntity = relatedEntityKYC;
      data.individual = individualKYC;
      data.statutory = documents;

      const payload = {
        method: "POST",
        url: `/onboarding`,
        data,
      };

      fetchApi(payload)
        .then((res) => {
          if (res.status == 201) {
            setIndividualKYC([]);
            setCorporateKYC([]);
            setDocuments([]);
            setRelatedEntityKYC([]);
            sendNotify("success", res?.message);
            setTimeout(() => {
              window.location.href = url;
            }, 1000);
          }
        })
        .catch((error) => {
          sendNotify("error", error?.message);
        });
    } else {
      setTimeout(() => {
        window.location.href = url;
      }, 1000);
    }
  };
  const selectBefore = (
    <Select
      className="custom-select w-100"
      placeholder="Select"
      style={{
        width: 60,
      }}
      defaultValue={"Y"}
      onChange={handleChange}
      options={[
        {
          value: "Y",
          label: "Yes",
        },
        {
          value: "N",
          label: "No",
        },
      ]}
    />
  );

  const [individualDocs, setIndividualDocs] = useState([
    "Passport / ID",
    "Address Proof",
  ]);

  const directorDocs = [
    "Consent to Act",
    "Resolution of Appointment",
    "Resolution of Resignation",
    "Letter of Resignation",
  ];

  const shareholderDocs = [
    "Resolution of Share Allotment",
    "Resolution of Share Transfer",
  ];

  const uboDocs = ["Resolution of Shareholding", "Source of Wealth/Founds"];

  let pocDocs = ["Contact Details"];

  let nomineeDocs = ["Nominee Agreement"];

  const statutoryDocs = [
    "Client Information Sheet",
    "Sample Document",
    "Appointment of First Director(s)",
    "Initial Share Allotment",
    "Corporate Seal",
    //"Other",
  ];

  const reserveDirectorDocs = [
    "Passport / ID",
    "Address Proof",
    "Resolutions",
    "Other",
  ];

  const corporateDocs = [
    "Incorporation Certificate",
    "M&AA",
    "Register Of Directors",
    "Share Registry/Listed or regulated proof",
    "ROD",
    "Seal",
    "Source Of Wealth/Funds",
    "Resolution of Share Allotment",
    "Resolution of Appointment as Director",
  ];

  let formSchema = [
    {
      name: "masterId",
      value: "",
      type: "select",
      label: "Masterfile Code",
      placeholder: "Enter masterfile code",
      customClass: "col-md-2",
      showSearch: true,
    },
    {
      name: "title",
      value: "",
      type: "select",
      label: "Salutation",
      placeholder: "Select salutation",
      options: [
        {
          value: "mr",
          label: "Mr",
        },
        {
          value: "mrs",
          label: "Mrs",
        },
        {
          value: "miss",
          label: "Miss",
        },
        {
          value: "ms",
          label: "Ms",
        },
        {
          value: "dr",
          label: "Dr",
        },
        {
          value: "rev",
          label: "Rev",
        },
      ],
      disabled: true,
    },
    {
      name: "fullName",
      value: "",
      type: "text",
      label: "Full Name",
      placeholder: "Enter full name",
      customClass: "col-md-2",
      disabled: true,
    },
    {
      name: "riskLevel",
      value: "low",
      type: "select",
      label: "Risk Level",
      placeholder: "Select risk level",
      options: [
        {
          value: "low",
          label: "Low",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "high",
          label: "High",
        },
      ],
      disabled: true,
    },
    {
      customClass: pep == "Y" ? "col-md-3" : "col-lg-1",
      label: "PEP",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${pep == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={pep}
            onChange={handleChangePep}
            disabled={true}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {pep == "Y" && (
            <input
              type="text"
              value={pepText}
              disabled={true}
              className="w-100"
              placeholder="Enter pep"
            />
          )}
        </div>
      ),
    },
    {
      customClass: sanction == "Y" ? "col-md-3" : "col-lg-1",
      label: "Sanction",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${sanction == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={sanction}
            disabled={true}
            onChange={handleChange}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {sanction == "Y" && (
            <input
              type="text"
              disabled={true}
              value={sanctionText}
              className="w-100"
              placeholder="Enter sanction"
            />
          )}
        </div>
      ),
    },
  ];

  const getFormData = () => {
    console.log(directorFormRef.current[0].getPayload());
  };

  function removeArrItem(values, arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let index = 0; index < values.length; index++) {
        if (arr[i] === values[index]) {
          arr.splice(i, 1);
        }
      }
    }
  }

  const addNewChange = (values, checkStatus) => {
    let arr = [...individualDocs];
    let status = { ...checkValue };
    status["UBOs"] = checkStatus;

    if (checkStatus) {
      arr.push(...values);
    } else {
      removeArrItem(values, arr);
    }
    setCheckValue(status);
    setIndividualDocs(arr);
  };

  const addNewRow = (values, checkStatus, name) => {
    let arr = [...individualDocs];
    let status = { ...checkValue };
    status[name] = checkStatus;
    if (checkStatus) {
      removeArrItem(values, arr);
    }

    if (name == "directors") {
      if (checkStatus) {
        arr.push(...values);
        setDirectorChecked(true);
        setReserveChecked(false);
      } else {
        removeArrItem(values, arr);
        setDirectorChecked(false);
      }
    }
    if (name == "reserve") {
      if (checkStatus) {
        arr.push(...values);
        setReserveChecked(true);
        setDirectorChecked(false);
      } else {
        removeArrItem(values, arr);
        setReserveChecked(false);
      }
    }
    setCheckValue(status);
    setIndividualDocs(arr);
  };

  const addNewCustom = (values, name, checkStatus) => {
    let arr = [...individualDocs];
    let status = { ...checkValue };
    status[name] = checkStatus;
    if (name == "shareholders") {
      if (checkStatus) {
        arr.push(...values);
        setShareholderChecked(true);
        setNomineeChecked(false);
        removeArrItem(nomineeDocs, arr);
      } else {
        removeArrItem(values, arr);
        setShareholderChecked(false);
      }
    }

    if (name == "nominee") {
      if (checkStatus) {
        arr.push(...values);
        setNomineeChecked(true);
        setShareholderChecked(false);
        removeArrItem(shareholderDocs, arr);
      } else {
        removeArrItem(values, arr);
        setNomineeChecked(false);
      }
    }
    setCheckValue(status);
    setIndividualDocs(arr);
  };

  return (
    <div>
      <div className="page-header">
        <h1 className="page-title mb-3 mb-md-0">Send to Approval</h1>
        {/* <Link to={"/app/lead-manager-approver-dashboard"} className="primary-btn">
          Request MLRO Approval
        </Link> */}
        <button className="primary-btn" onClick={onSubmit}>
          Request MLRO Approval
        </button>
      </div>
      <div className="page-content">
        {/* <h6 className='sub-title mb-4'>Assign</h6>
            <div className='mb-5'>
                <label htmlFor="" className="me-3 mb-1">Assign to</label>
                <div className="d-flex">
                    <Select
                        className="custom-select mw-300 mw-sm-65"
                        placeholder="Select"
                        onChange={handleChange}
                        options={[]}
                    />
                    <button className='primary-btn ms-3'>Submit</button>
                </div>
            </div> */}
        <Table
          className="custom-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
        {/* jai */}
        {/* <div className="mt-4">
          {forms.individuals.map((a, index) => {
            return (
              <div>
                <div className="d-flex my-4 gap-3 align-items-center">
                  <h4 className="primary-title">Individuals</h4>
                  {index === 0 && (<button
                    className="primary-btn"
                    onClick={() => addNewCol("individuals", { focus: true })}
                  >
                    <i className="fal fa-plus-circle"></i> Add New
                  </button>)}
                  {index !== 0 && (
                    <button
                      className="outline-btn red-btn"
                      onClick={() => removeNewCol("individuals", index)}
                    >
                      <i className="fal fa-trash-alt"></i> Remove
                    </button>
                  )}
                  <Checkbox
                    className="custom-checkbox"
                    checked={directorChecked && !reserveChecked}
                    onChange={(e) =>
                      addNewRow(directorDocs, e.target.checked, "directors")
                    }
                  >
                    Directors
                  </Checkbox>
                  <Checkbox
                    className="custom-checkbox"
                    checked={shareholderChecked && !nomineeChecked}
                    onChange={(e) =>
                      addNewCustom(shareholderDocs, "shareholders", e.target.checked)
                    }
                  >
                    Shareholders
                  </Checkbox>
                  <Checkbox
                    className="custom-checkbox"
                    onChange={(e) => addNewChange(uboDocs, e.target.checked)}
                  >
                    UBOs
                  </Checkbox>
                 
                  <Checkbox
                    className="custom-checkbox"
                    checked={nomineeChecked && !shareholderChecked}
                    onChange={(e) =>
                      addNewCustom(nomineeDocs, "nominee", e.target.checked)
                    }
                  >
                    Nominee Shareholder
                  </Checkbox>
                  <Checkbox
                    className="custom-checkbox"
                    checked={reserveChecked && !directorChecked}
                    onChange={(e) =>
                      addNewRow(directorDocs, e.target.checked, "reserve")
                    }
                  >
                    Reserve Director
                  </Checkbox>
                </div>
              
                <div className="custom-form my-4" id={`individuals${index}`}>
                  <div className="d-flex gap-3 mb-3 align-items-center">
                    <h3 className="sub-title">
                      Individual {index + 1} &nbsp;
                      <i className="fal fa-question-circle"></i>
                    </h3>
                  
                  </div>
                  <FormZ
                    ref={(el) => (directorFormRef.current[index] = el)}
                    formSchema={formSchema}
                    onChange={handleOnChange}
                    onKeyUp={(e) => individualOnChange(e.target.value)}
                    formOptions={{ masterId: individualArray }}
                    formData={fileArray[index]}
                    formClass="row mt-3 mb-4"
                    childClass="col-md-1 mb-0"
                    labelClass="mb-1"
                    inputClass="w-100"
                  />
                  <div className="row gy-2 gx-4">
                    <Each
                      array={individualDocs}
                      render={(item) => (
                        <div className="col-md-3">
                          <label htmlFor={item}>{item}</label>
                          <UploadZ
                            onStoreFile={(data) => storeDocuments(data, index)}
                            fileName={item}
                          />
                        </div>
                      )}
                    />
                    {individualCustom.length > 0 && (
                      <Each
                        array={individualCustom}
                        render={(item, i) => (
                          <div className="col-md-3">
                            <div className="d-flex justify-content-between align-items-center mb-1px">
                              <label
                                contentEditable={true}
                                key={i}
                                id={i}
                                onBlur={(e) =>
                                  getLabelName(e.target.innerHTML, i)
                                }
                                className="mb-0"
                                suppressContentEditableWarning={true}
                              >
                                {item}
                              </label>
                              <button
                                className="del-upload-btn"
                                onClick={() => removeIndividualDoc(i)}
                              >
                                <i className="far fa-trash-alt"></i>
                              </button>
                            </div>
                            <UploadZ
                              onStoreFile={(data) => storeDocuments(data, index)}
                              fileName={item}
                            />
                          </div>
                        )}
                      />
                    )}
                    <div className="col-md-3 mb-4">
                      <label htmlFor="">&nbsp;</label>
                      <div
                        className="add-new-upload"
                        onClick={addNewIndividualDoc}
                      >
                        <span className="icon">
                          <i className="fal fa-plus-circle"></i>
                        </span>
                        <h6>Add New Upload</h6>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            );
          })}
        </div> */}
        {/* jai */}
        {/* <div>
        <div className="d-flex gap-3 align-items-center">
          <h4 className="primary-title">Shareholders</h4>
          <button
            className="primary-btn"
            onClick={() => addNewCol("shareholders", { focus: true })}
          >
            <i className="fal fa-plus-circle"></i> Add New
          </button>
        </div>
        <Each
          array={forms.shareholders}
          render={(shareholder, i) => (
            <div className="custom-form my-4" id={`shareholders${i}`}>
              <div className="d-flex gap-3 mb-3 align-items-center">
                <h3 className="sub-title">
                  Shareholder 0{i + 1} &nbsp;
                  <i className="fal fa-question-circle"></i>
                </h3>
                {i !== 0 && (
                  <button
                    className="outline-btn red-btn"
                    onClick={() => removeNewCol("shareholders", i)}
                  >
                    <i className="fal fa-trash-alt"></i> Remove
                  </button>
                )}
              </div>
              <FormZ
                ref={(el) => (shareholderFormRef.current[i] = el)}
                formSchema={formSchema}
                formClass="row mt-3 mb-4"
                childClass="col-md-2 mb-0"
                labelClass="mb-1"
                inputClass="w-100"
              />
              <div className="col-md-9">
                <div className="row gy-2 gx-4">
                  <Each
                    array={shareholderDocs}
                    render={(item) => (
                      <div className="col-md-4">
                        <label htmlFor={item}>{item}</label>
                        <UploadZ onStoreFile={(data) => storeDocuments(data, index)} fileName={item} />
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          )}
        />
      </div>
      <div>
        <div className="d-flex gap-3 align-items-center">
          <h4 className="primary-title">UBOs</h4>
          <button
            className="primary-btn"
            onClick={() => addNewCol("ubo", { focus: true })}
          >
            <i className="fal fa-plus-circle"></i> Add New
          </button>
        </div>
        <Each
          array={forms.ubo}
          render={(ubo, i) => (
            <div className="custom-form my-4" id={`ubo${i}`}>
              <div className="d-flex gap-3 mb-3 align-items-center">
                <h3 className="sub-title">
                  UBO 0{i + 1} &nbsp;
                  <i className="fal fa-question-circle"></i>
                </h3>
                {i !== 0 && (
                  <button
                    className="outline-btn red-btn"
                    onClick={() => removeNewCol("ubo", i)}
                  >
                    <i className="fal fa-trash-alt"></i> Remove
                  </button>
                )}
              </div>
              <FormZ
                ref={(el) => (uboFormRef.current[i] = el)}
                formSchema={formSchema}
                formClass="row mt-3 mb-4"
                childClass="col-md-2 mb-0"
                labelClass="mb-1"
                inputClass="w-100"
              />
              <div className="col-md-9">
                <div className="row gy-2 gx-4">
                  <Each
                    array={uboDocs}
                    render={(item) => (
                      <div className="col-md-4">
                        <label htmlFor={item}>{item}</label>
                        <UploadZ onStoreFile={(data) => storeDocuments(data, index)} fileName={item} />
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          )}
        />
      </div> */}

        {/* <div>
        <h2 className="primary-title">Risk Assessment</h2>
        <div className="custom-form my-4">
          <div className="row gy-2 gx-4">
            <div className="col-md-3">
              <label htmlFor="RAF">RAF</label>
              <UploadZ onStoreFile={(data) => storeDocuments(data, index)} fileName={"RAF"} />
            </div>
            {upload.length > 0 && (
              <Each
                array={upload}
                render={(item, i) => (
                  <div className="col-md-3">
                    <label contentEditable={true} id={i} className="me-3">
                      Enter document name
                    </label>
                    <UploadZ onStoreFile={(data) => storeDocuments(data, index)} fileName={item} />
                  </div>
                )}
              />
            )}
            <div className="col-md-3 mb-4">
              <label htmlFor="">&nbsp;</label>
              <div className="add-new-upload" onClick={addNewUpload}>
                <span className="icon">
                  <i className="fal fa-plus-circle"></i>
                </span>
                <h6>Add New Upload</h6>
              </div>
            </div>
          </div>
        </div>
      </div> */}
        {/* <div>
        <h2 className="primary-title">Reserve Director Appointments</h2>
        <div className="custom-form my-4">
          <div className="row gy-2 gx-4">
            <Each
              array={reserveDirectorDocs}
              render={(item) => (
                <div className="col-md-3">
                  <label htmlFor={item}>{item}</label>
                  <UploadZ onStoreFile={(data) => storeDocuments(data, index)} fileName={item} />
                </div>
              )}
            />
          </div>
        </div>
      </div> */}
        <IndividualKYC setIndividualKYC={setIndividualKYC} />
        <CorporateKYC setCorporateKYC={setCorporateKYC} />
        <RelatedEntityKYC setRelatedEntityKYC={setRelatedEntityKYC} />

        <div className="mt-4">
          <h2 className="primary-title">Statutory Documents</h2>
          <div className="custom-form my-4">
            <div className="row gy-2 gx-4">
              <Each
                array={statutoryDocs}
                render={(item) => (
                  <div className="col-md-3">
                    <label htmlFor={item}>{item}</label>
                    <UploadZ onStoreFile={storeDocuments} fileName={item} />
                  </div>
                )}
              />
              {selectedFiles.length > 0 && (
                <>
                  {/* {selectedFiles.map((file, i) => ( */}
                  <Each
                    array={selectedFiles}
                    render={(item, i) => (
                      <div className="col-md-3">
                        <div className="position-relative">
                          <div className="d-flex justify-content-between align-items-center mb-1px">
                            <label
                              contentEditable={true}
                              key={i}
                              id={i}
                              onBlur={(e) =>
                                getLabelName(e.target.innerHTML, i)
                              }
                              className="mb-0"
                              suppressContentEditableWarning={true}
                            >
                              {item.name}
                            </label>
                            <button
                              className="del-upload-btn"
                              onClick={() => removeUploadOnIndex(i, item)}
                            >
                              <i className="far fa-trash-alt"></i>
                            </button>
                          </div>
                          <UploadZ
                            onStoreFile={storeDocuments}
                            onDeleteFile={removeDocument}
                            file={item}
                            clearUpload={clearDocuments}
                            fileName={item.name}
                          />{" "}
                        </div>
                      </div>
                    )}
                  />
                </>
              )}
              <Each
                array={upload}
                render={(item, i) => (
                  <div className="col-md-3">
                    <div className="position-relative">
                      <div className="d-flex justify-content-between align-items-center mb-1px">
                        <label
                          contentEditable={true}
                          key={i}
                          id={i}
                          onBlur={(e) => getLabelName(e.target.innerHTML, i)}
                          className="mb-0"
                          suppressContentEditableWarning={true}
                        >
                          {item}
                        </label>
                        <button
                          className="del-upload-btn"
                          onClick={() => removeUpload(i, item)}
                        >
                          <i className="far fa-trash-alt"></i>
                        </button>
                      </div>
                      <UploadZ
                        onStoreFile={storeManyDocuments}
                        onDeleteFile={removeDocument}
                        clearUpload={clearDocuments}
                        // file={documents["basicDoc"].find(
                        //   (d) => d.document == item
                        // )}
                        isMultiple={true}
                        fileName={item}
                        setSelectedFiles={setSelectedFiles}
                      />
                    </div>
                  </div>
                )}
              />
              <div className="col-md-3 mb-4">
                <label htmlFor="">&nbsp;</label>
                <div className="add-new-upload" onClick={addNewUpload}>
                  <span className="icon">
                    <i className="fal fa-plus-circle"></i>
                  </span>
                  <h6>Add New Upload</h6>
                  {/* <p>Click to create new upload field</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
