import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import FormZ from "../../components/FormZ";
import { getBusinessPartners } from "../../store/businessPartners/businessPartnersSlice";
import {
  getOptions,
  fetchApi,
  getQuery,
  timeDiff,
  sendNotify,
} from "../../helper";
export default function Crud() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const businessPartners = useSelector((state) => state.businessPartners);
  const [editMode, setEditMode] = useState(false);
  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });
  const [formData, setFormData] = useState({});
  const [data, setDate] = useState([]);
  const bpFormRef = useRef();

  useEffect(() => {
    if (id) {
      let params = { ...queryParams };
      params.id = id;
      dispatch(getBusinessPartners(params));
      setEditMode(true);
    }
  }, [id]);

  useEffect(() => {
    handleRefresh();
  }, [businessPartners]);

  const handleRefresh = async () => {
    if (businessPartners?.data?.rows) {
      let finded = businessPartners?.data?.rows;
      let data = finded.map((a, index) => {
        let obj = {
          id: a._id,
          referenceID: a.code,
          userName: a.userName,
          email: a.email,
          phoneNumber: a.phoneNumber,
          status: a.status,
        };
        setFormData(obj);
        return obj;
      });
      setDate(data);
    }
  };

  let bpFormSchema = [
    {
      name: "userName",
      value: editMode ? "john@northlark.com" : "",
      type: "text",
      label: "Business Partner Name",
      placeholder: "Enter business partner name",
      customClass: "col-md-10",
      required: true,
      disabled: editMode,
    },
    {
      name: "email",
      value: editMode ? "john@northlark.com" : "",
      type: "email",
      label: "Email",
      placeholder: "Enter email address",
      required: true,
      disabled: editMode,
    },
    {
      name: "phoneNumber",
      value: editMode ? "+1 (420) 9027 6393" : "",
      type: "type",
      label: "Phone Number",
      placeholder: "Enter phone number",
      customClass: "hide-arrows",
      required: true,
      disabled: editMode,
    },
    {
      name: "status",
      value: "",
      type: "select",
      label: "Status",
      placeholder: "Select status",
      options: [
        {
          value: "Active",
          label: "Active",
        },
        {
          value: "Inactive",
          label: "Inactive",
        },
      ],
      disabled: editMode ? false : true,
    },
  ];

  const onSubmit = () => {
    const valid = bpFormRef.current.validForm();
    if (!valid) {
      sendNotify("error", "Some fields are missing!");
      return;
    }
    let date = moment().format("YYYYMMDD");
    const options = bpFormRef.current.getPayload();
    if (!editMode) options.dataBaseName = `${options.userName}${date}`;
    if (!editMode) options.role = "admin";
    if (!editMode) options.organization = ["admin"];
    if (!editMode) options.password = `${options.userName}@123`;

    const payload = {
      method: editMode ? "PUT" : "POST",
      url: editMode ? `/admin/update/${id}` : `/admin/create`,
      data: options,
    };

    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message);

        if (!id) {
          setTimeout(() => {
            // window.location.href = `/app/business-partners`;
          }, 1000);
        }
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };
  return (
    <div>
      <div className="page-header">
        <div>
          <h1 className="mb-1">{editMode ? "Edit" : "Add"} Business Partner</h1>
          <p className="mb-0">
            {editMode ? "Edit" : "Add"} a new business partner profile.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="row">
          <div className="custom-form col-md-8">
            <FormZ
              formClass="row gy-4 mb-4"
              childClass="col-md-5 mb-0"
              labelClass="mb-1"
              inputClass="w-100"
              formSchema={bpFormSchema}
              formData={formData}
              ref={bpFormRef}
            />
            <hr />
            <div className="d-flex justify-content-end gap-3 mt-4">
              <Link to={"/app/business-partners"} className="secondary-btn">
                Cancel
              </Link>
              {/* {editMode ? ( */}
              <button className="primary-btn" onClick={onSubmit}>
                {editMode ? "Archive" : "Add Business Partner"}
              </button>
              {/* ) : (
                <button className="primary-btn">Add Business Partner</button>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
