import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Select, DatePicker, Checkbox, Popover, TreeSelect } from "antd";
import moment from "moment";
import { getIndividualDetails } from "../../store/individualDetails/individualDetailsSlice";
import { getCopEntityDetails } from "../../store/copEntityDetails/copEntityDetailsSlice";
import { getEntity } from "../../store/entity/entitySlice";
import countries from "../../helper/countries.json";
import {
  sendNotify,
  getOptions,
  focusOn,
  Each,
  fetchApi,
  convertQueryParams,
  getQuery,
} from "../../helper";
const { SHOW_PARENT } = TreeSelect;
export default function EntityOverview(props) {
  let { iframe } = props;
  let query = getQuery();
  const dispatch = useDispatch();
  const entity = useSelector((state) => state.entity);
  const individualDetails = useSelector((state) => state.individualDetails);
  const copEntityDetails = useSelector((state) => state.copEntityDetails);
  const [countriesOption, setCountriesOption] = useState([]);
  const [historyShow, setHistoryShow] = useState([]);
  const [uboHistory, setUboHistory] = useState(false);
  const [formData, setFormData] = useState({});
  const [customContact, setCustomContact] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [cddInformation, setCDDinformation] = useState({});
  const [locReg, setlocReg] = useState({});
  const [locFinancial, setLocFinancial] = useState({});
  const [customFinancial, setCustomFinancial] = useState([]);
  const [reminderCount, setReminderCount] = useState([0]);
  const [bossFilingCount, setBossFilingCount] = useState([0]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [value, setValue] = useState([]);
  useEffect(() => {
    let params = { enId: query.id };
    dispatch(getEntity({ id: query.id }));
    dispatch(getIndividualDetails({ enId: query.id }));
    dispatch(getCopEntityDetails({ enId: query.id }));
    convertToOptions();
  }, []);

  useEffect(() => {
    getDataHandleRefresh();
  }, [entity, individualDetails, copEntityDetails]);
  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  const tProps = {
    treeData: countriesOption,
    value,
    className: "custom-select w-100 ptb-1",
    treeCheckable: true,
    showSearch: false,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: "Select geography of business",
    allowClear: true,
    disabled: true,
  };
  const convertToOptions = () => {
    let options = [
      {
        title: "Worldwide",
        value: "0",
        key: "0",
        children: [],
      },
    ];

    Object.keys(countries).forEach(function (key, index) {
      let continents = {
        title: key,
        value: `0-${index}`,
        key: `0-${index}`,
        children: [],
      };
      countries[key].forEach((country, i) => {
        let cat = {
          title: country?.name,
          value: `0-${index}-${i}`,
          key: `0-${index}-${i}`,
        };
        continents.children.push(cat);
      });

      options[0]?.children.push(continents);
    });

    setCountriesOption(options);
  };
  const getDataHandleRefresh = async () => {
    if (entity?.data?.rows) {
      setFormData(entity?.data?.rows[0]);
      setSelectedId(entity?.data?.rows[0]._id);
      setCustomContact(entity?.data?.rows[0]?.primaryContact ?? {});
      setlocReg(entity?.data?.rows[0]?.registers?.address ?? {});
      setLocFinancial(entity?.data?.rows[0]?.financialRecords?.address ?? {});
      setCustomFinancial(
        entity?.data?.rows[0]?.financialRecords?.details ?? {}
      );
      setCDDinformation(entity?.data?.rows[0]?.cddInformation ?? {});
      setValue(entity?.data?.rows[0]?.busGeography);
      setReminderCount(entity?.data?.rows[0]?.cddInformation.reminderDate);
      setBossFilingCount(entity?.data?.rows[0]?.cddInformation.filingDate);
    }
    // let dataArray = []
    // let count = 'EN00005'
    // await asyncForEach(dataArray, async (oneDoc, i) => {

    //   const match = count.match(/(\d+)$/);
    //   if (match) {
    //     const numericPart = match[0];
    //     const incrementedNumeric = parseInt(numericPart, 10) + 1;
    //     const newNumericPart = String(incrementedNumeric).padStart(numericPart.length, '0');
    //     count = count.slice(0, -numericPart.length) + newNumericPart;

    //     oneDoc["code"] = count
    //   }
    // })
  };

  const toggleHistory = (index) => {
    let arr = [...historyShow];
    let item = arr.findIndex((hs) => hs === index);
    if (item !== -1) {
      arr.splice(item, 1);
    } else {
      arr.push(index);
    }
    setHistoryShow(arr);
  };

  let directors = [1, 2, 3];
  let shareholders = [1, 2, 3];
  let ubo = [1];

  const columns = [
    {
      title: "Share Certificate Number",
      dataIndex: "shareNumber",
      sorter: (a, b) => a.shareNumber - b.shareNumber,
    },
    {
      title: "Number of Shares",
      dataIndex: "noShares",
      sorter: (a, b) => a.noShares - b.noShares,
    },
    {
      title: "Share Type",
      dataIndex: "shareType",
      sorter: (a, b) => a.shareType - b.shareType,
    },
    {
      title: (
        <div className="d-flex align-items-center">
          Issued Date&nbsp;<i className="fal fa-question-circle"></i>
        </div>
      ),
      dataIndex: "issuedDate",
      sorter: (a, b) => a.issuedDate - b.issuedDate,
    },
    {
      title: "Total No. of Shares held",
      dataIndex: "sharesHeld",
      sorter: (a, b) => a.sharesHeld - b.sharesHeld,
    },
    {
      title: (
        <div className="d-flex align-items-center">
          Transfer Date&nbsp;<i className="fal fa-question-circle"></i>
        </div>
      ),
      dataIndex: "transferDate",
      sorter: (a, b) => a.transferDate - b.transferDate,
    },
    {
      title: "Transfer Amount",
      dataIndex: "transferAmount",
      sorter: (a, b) => a.transferAmount - b.transferAmount,
    },
    {
      title: "Par value",
      dataIndex: "parValue",
      sorter: (a, b) => a.parValue - b.parValue,
    },
    {
      title: "Date Ceased to be a Member",
      dataIndex: "dateCeased",
      sorter: (a, b) => a.dateCeased - b.dateCeased,
    },
    {
      title: "",
      render: (_, { id }) => (
        <div className="d-flex gap-2 align-items-center justify-content-end">
          <button className="action-btn delete-btn">
            <i className="far fa-trash-alt"></i>
          </button>
          <button className="action-btn">
            <i className="far fa-pencil"></i>
          </button>
        </div>
      ),
    },
  ];
  const tableData = [
    {
      key: "1",
      id: "1",
      shareNumber: 55,
      noShares: 100,
      shareType: "Type 1",
      issuedDate: "11-12-2023",
      sharesHeld: "02",
      transferDate: "12-12-2023",
      transferAmount: 500,
      parValue: 100,
      dateCeased: "Sample",
    },
    {
      key: "2",
      id: "2",
      shareNumber: 56,
      noShares: 500,
      shareType: "Type 2",
      issuedDate: "13-12-2023",
      sharesHeld: "05",
      transferDate: "14-12-2023",
      transferAmount: 800,
      parValue: 100,
      dateCeased: "Sample",
    },
    {
      key: "3",
      id: "3",
      shareNumber: 57,
      noShares: 250,
      shareType: "Type 1",
      issuedDate: "15-12-2023",
      sharesHeld: "05",
      transferDate: "17-12-2023",
      transferAmount: 400,
      parValue: 100,
      dateCeased: "Sample",
    },
    {
      key: "4",
      id: "4",
      shareNumber: 58,
      noShares: 120,
      shareType: "Type 2",
      issuedDate: "18-12-2023",
      sharesHeld: "05",
      transferDate: "19-12-2023",
      transferAmount: 1500,
      parValue: 100,
      dateCeased: "Sample",
    },
  ];

  const categories = [
    { name: "Standard Company", value: "standard" },
    { name: "Continued Company", value: "continued" },
    { name: "Re-registered Company", value: "re-registered" },
  ];

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleChangeCategory = () => {
    setSelectedCategory(null); // Reset selected category
  };

  const renderSubOptions = () => (
    <div className="certificate-links">
      <p>
        Selected Category:{" "}
        <strong>
          {categories.find((cat) => cat.value === selectedCategory)?.name}
        </strong>
      </p>
      <Link
        to={`/app/certificate-of-incumbency?entityId=${query.id}&category=${selectedCategory}&withCharges=true`}
      >
        With register of charges
      </Link>
      <Link
        to={`/app/certificate-of-incumbency?entityId=${query.id}&category=${selectedCategory}&withCharges=false`}
      >
        Without register of charges
      </Link>
      <Link
        to={`/app/certificate-of-incumbency?entityId=${query.id}&category=${selectedCategory}&customCOI=true`}
      >
        Custom COI
      </Link>

      <button className="change-category-btn" onClick={handleChangeCategory}>
        Change Category
      </button>
    </div>
  );

  const renderMainOptions = () => (
    <div className="main-categories">
      {categories.map((category) => (
        <button
          key={category.value}
          onClick={() => handleCategoryClick(category.value)}
          className="main-category-btn"
        >
          {category.name}
        </button>
      ))}
    </div>
  );

  return (
    <div>
      {!iframe && (
        <div className="page-header">
          <h1 className="page-title mb-3 mb-md-0">Entity Overview</h1>
          <Link to={"/app/incorporation-pack"} className="primary-btn">
            Export
          </Link>
        </div>
      )}
      <Link
        to={`/app/organization-structure?id=${query.id ?? selectedId}`}
        className="primary-btn ms-auto"
      >
        <i class="far fa-folder-tree"></i>
        Organization Structure
      </Link>
      <div className="page-content">
        <div className="row">
          <div className="col-lg-9">
            <h4 className="primary-title mb-4">Entity</h4>
            <div className="row overview-listing border-box mb-5">
              {formData?.name && (
                <div className="col-md-4 mb-4 ">
                  <label htmlFor="">Entity Name:</label>
                  <p className="box-style">{formData.name}</p>
                </div>
              )}
              {formData?.type && (
                <div className="col-md-4 mb-4">
                  <label htmlFor="">Entity Type:</label>
                  <p className="box-style">{formData.type}</p>
                </div>
              )}
              {formData?.code && (
                <div className="col-md-4 mb-4">
                  <label htmlFor="">Entity Code:</label>
                  <p className="box-style">{formData?.code}</p>
                </div>
              )}
              {formData?.status && (
                <div className="col-md-4 mb-4">
                  <label htmlFor="">Entity Status:</label>
                  <p className="box-style">{formData.status}</p>
                </div>
              )}

              {formData?.clientName && (
                <div className="col-md-4 mb-4">
                  <label htmlFor="">Client Masterfile Name:</label>
                  <p className="box-style">{formData.clientName}</p>
                </div>
              )}

              {formData?.clientCode && (
                <div className="col-md-4 mb-4">
                  <label htmlFor="">Client Code:</label>
                  <p className="box-style">{formData.clientCode}</p>
                </div>
              )}
              {formData?.clientType && (
                <div className="col-md-4 mb-4">
                  <label htmlFor="">Client Type:</label>
                  <p className="box-style">{formData.clientType}</p>
                </div>
              )}
              {formData?.clientStatus && (
                <div className="col-md-4 mb-4">
                  <label htmlFor="">Client Status:</label>
                  <p className="box-style">{formData.clientStatus}</p>
                </div>
              )}
              {formData?.pClientName && (
                <div className="col-md-4 mb-4">
                  <label htmlFor="">Parent Client Name:</label>
                  <p className="box-style">{formData.pClientName}</p>
                </div>
              )}
              {formData?.pClientCode && (
                <div className="col-md-4 mb-4">
                  <label htmlFor="">Parent Client Code:</label>
                  <p className="box-style">{formData.pClientCode}</p>
                </div>
              )}
              {formData?.pClientStatus && (
                <div className="col-md-4 mb-4">
                  <label htmlFor="">Parent Client Status:</label>
                  <p className="box-style">{formData.pClientStatus}</p>
                </div>
              )}
              {
                <div className="col-md-4 mb-4">
                  <label htmlFor="">Incorporation Number:</label>
                  <p className="box-style">{formData?.incorporationNumber}</p>
                </div>
              }
              {formData?.busGeography?.length && (
                <div className="col-md-4 mb-4">
                  <label htmlFor="">Jurisdiction:</label>
                  <p className="box-style">{}</p>
                </div>
              )}
              {
                <div className="col-md-4 mb-4">
                  <label htmlFor="">Incorporated Date:</label>
                  <p className="box-style">
                    {moment(formData.incorporationDate).format("DD-MMM-YYYY")}
                  </p>
                </div>
              }
              {formData?.group && (
                <div className="col-md-4 mb-4">
                  <label htmlFor="">Entity Grouping:</label>
                  <p className="box-style">Random</p>
                </div>
              )}
              {formData?.relatedEntities?.length ? (
                <div className="col-md-4 mb-4">
                  <label htmlFor="">Related Entities:</label>
                  <p className="box-style">{formData.relatedEntities}</p>
                </div>
              ) : null}
              {formData?.YearOfEndDate ? (
                <div className="col-md-4 mb-4">
                  <label htmlFor="">Year Of End Date:</label>
                  <p className="box-style">
                    {moment(formData.YearOfEndDate).format("DD-MMM-YYYY")}
                  </p>
                </div>
              ) : null}
              {/* <div className="col-md-4 mb-4">
              <label htmlFor="">Composite Risk Level:</label>
              <p className="box-style">High</p>
            </div> */}
              {/* <div className="col-md-4 mb-4">
              <label htmlFor="">Corporate Entity:</label>
              <p className="box-style">Yes</p>
            </div> */}
            </div>
            <h4 className="primary-title  mb-4">{`Primary Contact (${customContact?.length})`}</h4>

            <div className="row overview-listing border-box mb-5">
              {customContact.map((a, i) => {
                return (
                  <>
                    <div className="col-md-4 mb-4">
                      <label htmlFor="">Primary Contact Name:</label>
                      <p className="box-style">{a.data?.name}</p>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label htmlFor="">Primary Contact Email:</label>
                      <p className="box-style">{a.data?.email}</p>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label htmlFor="">Primary Contact Number:</label>
                      <p className="box-style">{a.data?.mobile}</p>
                    </div>
                  </>
                );
              })}
            </div>
            <h4 className="primary-title mb-4">Location of Registers</h4>
            {/*<div className="overview-listing mb-5">
              <div className="mb-4">
                <label htmlFor="">Address Line 01</label>
                <p>{locReg.line_1}</p>
              </div>
              <div className="mb-4">
                <label htmlFor="">Address Line 02</label>
                <p>{locReg.line_2}</p>
              </div>
              <div className="mb-4">
                <label htmlFor="">Address Line 03</label>
                <p>{locReg.line_3}</p>
              </div>
            </div> */}

            <div className="row overview-listing border-box mb-5">
              <div className="col-md-4 mb-4">
                <label htmlFor="">Address Line 01:</label>
                <p className="box-style">{locReg.line_1}</p>
              </div>
              <div className="col-md-4 mb-4">
                <label htmlFor="">Address Line 02:</label>
                <p className="box-style">{locReg.line_2}</p>
              </div>
              <div className="col-md-4 mb-4">
                <label htmlFor="">Address Line 03:</label>
                <p className="box-style">{locReg.line_3}</p>
              </div>
            </div>

            <h4 className="primary-title mb-4">
              {" "}
              Location of Financial Records{" "}
            </h4>

            {/* <div className="overview-listing">
              <div className="mb-4">
                <label htmlFor="">Address Line 01</label>
                <p>{locFinancial.line_1}</p>
              </div>
              <div className="mb-4">
                <label htmlFor="">Address Line 02</label>
                <p>{locFinancial.line_2}</p>
              </div>
              <div className="mb-4">
                <label htmlFor="">Address Line 03</label>
                <p>{locFinancial.line_3}</p>
              </div>
            </div> */}
            <div className="border-box">
              <div className="row overview-listing  mb-5">
                <div className="col-md-4 mb-4">
                  <label htmlFor="">Address Line 01:</label>
                  <p className="box-style">{locFinancial.line_1}</p>
                </div>
                <div className="col-md-4 mb-4">
                  <label htmlFor="">Address Line 02:</label>
                  <p className="box-style">{locFinancial.line_2}</p>
                </div>
                <div className="col-md-4 mb-4">
                  <label htmlFor="">Address Line 03:</label>
                  <p className="box-style">{locFinancial.line_3}</p>
                </div>
              </div>

              <h4 className="primary-title mb-4">
                {" "}
                {`Financial Record Holder (${customFinancial?.length})`}{" "}
              </h4>
              {customFinancial?.map((a, i) => {
                return (
                  <div className="row overview-listing border-box mb-5">
                    <div className="col-md-6 mb-4">
                      <label htmlFor="">Title</label>
                      <p className="box-style">{a.data?.title}</p>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label htmlFor="">Name of Financial Record Holder</label>
                      <p className="box-style">{a.data.recordHolder}</p>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label htmlFor="">Email:</label>
                      <p className="box-style">{a.data.email}</p>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label htmlFor="">Primary Contact Number:</label>
                      <p className="box-style">{a.data.mobile}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* <h4 className="primary-title mb-4">Location of Registers</h4>
          <div className="overview-listing mb-5">
            <div className="mb-4">
              <label htmlFor="">Address Line 01</label>
              <p>{locReg.line_1}</p>
            </div>
            <div className="mb-4">
              <label htmlFor="">Address Line 02</label>
              <p>{locReg.line_2}</p>
            </div>
            <div className="mb-4">
              <label htmlFor="">Address Line 03</label>
              <p>{locReg.line_3}</p>
            </div>
          </div> */}
          {/* <h4 className="primary-title mb-4">Location of Financial Records</h4>
          <div className="overview-listing">
            <div className="mb-4">
              <label htmlFor="">Address Line 01</label>
              <p>{locFinancial.line_1}</p>
            </div>
            <div className="mb-4">
              <label htmlFor="">Address Line 02</label>
              <p>{locFinancial.line_2}</p>
            </div>
            <div className="mb-4">
              <label htmlFor="">Address Line 03</label>
              <p>{locFinancial.line_3}</p>
            </div>
          </div> */}
        </div>
        {/* {customFinancial?.map((a, i) => {
          return (
            <div className="row overview-listing mb-5">
              <div className="col-md-3 mb-4">
                <label htmlFor="">Title</label>
                <p className="box-style">{a.data.title}</p>
              </div>
              <div className="col-md-3 mb-4">
                <label htmlFor="">Name of Financial Record Holder</label>
                <p className="box-style">{a.data.recordHolder}</p>
              </div>
              <div className="col-md-3 mb-4">
                <label htmlFor="">Email:</label>
                <p className="box-style">{a.data.email}</p>
              </div>
              <div className="col-md-3 mb-4">
                <label htmlFor="">Primary Contact Number:</label>
                <p className="box-style">{a.data.mobile}</p>
              </div>
            </div>
          );
        })} */}

        <h4 className="primary-title mt-4 mb-4">
          Individuals ({individualDetails?.data?.rows?.length})
        </h4>
        {individualDetails
          ? individualDetails?.data?.rows?.map((a, i) => {
              return (
                <>
                  <div className="border-box">
                    <div className="d-flex my-4 gap-3 align-items-center">
                      <Checkbox
                        className="custom-checkbox"
                        disabled
                        checked={a.checkedList.directors ?? false}
                      >
                        Directors
                      </Checkbox>
                      <Checkbox
                        className="custom-checkbox"
                        disabled
                        checked={a.checkedList.shareholders ?? false}
                      >
                        Shareholders
                      </Checkbox>
                      <Checkbox
                        className="custom-checkbox"
                        disabled
                        checked={a.checkedList.ubo ?? false}
                      >
                        UBOs
                      </Checkbox>
                      {/* <Checkbox className="custom-checkbox" disabled>
              POC
            </Checkbox> */}
                      <Checkbox
                        className="custom-checkbox"
                        disabled
                        checked={a.checkedList.nominee ?? false}
                      >
                        Nominee Shareholder
                      </Checkbox>
                      <Checkbox
                        className="custom-checkbox"
                        disabled
                        checked={a.checkedList.reserve ?? false}
                      >
                        Reserve Director
                      </Checkbox>
                    </div>

                    <div key={i}>
                      <h3 className="sub-title mb-3">
                        Individual 0{i + 1} &nbsp;
                        <i className="fal fa-question-circle"></i>
                      </h3>
                      <div className="row overview-listing mb-2">
                        {a.individualArray?.code && (
                          <div className="col-md-3 mb-4">
                            <label htmlFor="">
                              Individual Masterfile Code:
                            </label>
                            <p className="box-style">
                              {a.individualArray.code}
                            </p>
                          </div>
                        )}
                        {
                          <div className="col-md-3 mb-4">
                            <label htmlFor="">Salutation:</label>
                            <p className="box-style">
                              {a.individualArray?.title}
                            </p>
                          </div>
                        }
                        {a.individualArray?.firstName && (
                          <div className="col-md-3 mb-4">
                            <label htmlFor="">First Name:</label>
                            <p className="box-style">
                              {a.individualArray.firstName}
                            </p>
                          </div>
                        )}
                        {a.individualArray?.middleName && (
                          <div className="col-md-3 mb-4">
                            <label htmlFor="">Middle Name:</label>
                            <p className="box-style">
                              {a.individualArray.middleName}
                            </p>
                          </div>
                        )}
                        {a.individualArray?.lastName && (
                          <div className="col-md-3 mb-4">
                            <label htmlFor="">Last Name:</label>
                            <p className="box-style">
                              {a.individualArray.lastName}
                            </p>
                          </div>
                        )}
                        {a.individualArray?.DOB && (
                          <div className="col-md-3 mb-4">
                            <label htmlFor="">Date of Birth:</label>
                            <p className="box-style">
                              {moment(a.individualArray.DOB).format(
                                "DD-MMM-YYYY"
                              )}
                            </p>
                          </div>
                        )}
                        {a.individualArray?.COB && (
                          <div className="col-md-3 mb-4">
                            <label htmlFor="">Country of Birth: </label>
                            <p className="box-style">{a.individualArray.COB}</p>
                          </div>
                        )}
                        {a.individualArray?.nationality && (
                          <div className="col-md-3 mb-4">
                            <label htmlFor="">Nationality:</label>
                            <p className="box-style">
                              {a.individualArray.nationality}
                            </p>
                          </div>
                        )}
                        {a.individualArray?.details?.riskRating && (
                          <div className="col-md-3 mb-4">
                            <label htmlFor="">Risk Level</label>
                            <p className="box-style">
                              {a.individualArray?.details?.riskRating}
                            </p>
                          </div>
                        )}
                        {a.individualArray?.details?.pep && (
                          <div className="col-md-3 mb-4">
                            <label htmlFor="">PEP</label>
                            <p className="box-style">{`${
                              a.individualArray?.details.pep == "Y"
                                ? "Yes"
                                : "NO"
                            }`}</p>
                          </div>
                        )}
                        {a.individualArray?.taxInfo && (
                          <div className="col-md-3 mb-4">
                            <label htmlFor="">Tax Info Number </label>
                            <p className="box-style">
                              {a.individualArray.taxInfo}
                            </p>
                          </div>
                        )}

                        {a.individualArray?.passportNo && (
                          <div className="col-md-3 mb-4">
                            <label htmlFor="">Passport Number:</label>
                            <p className="box-style">
                              {a.individualArray.passportNo}
                            </p>
                          </div>
                        )}
                        {a.individualArray?.passportExp && (
                          <div className="col-md-3 mb-4">
                            <label htmlFor="">Expiry Date</label>
                            <p className="box-style">
                              {a.individualArray.passportExp}
                            </p>
                          </div>
                        )}
                        {a?.appointment && (
                          <div className="col-md-3 mb-4">
                            <label htmlFor="">Date of Appointment: </label>
                            <p className="box-style">
                              {moment(a.appointment).format("DD-MMM-YYYY")}
                            </p>
                          </div>
                        )}
                        {a?.resignation && (
                          <div className="col-md-3 mb-4">
                            <label htmlFor="">Date of Resignation: </label>
                            <p className="box-style">
                              {moment(a.resignation).format("DD-MMM-YYYY")}
                            </p>
                          </div>
                        )}
                        {(a.checkedList.shareholders ||
                          a.checkedList.nominee) && (
                          <>
                            <div className="col-md-3 mb-4">
                              <label htmlFor="">No. of Shares Held: </label>
                              <p className="box-style">2600</p>
                            </div>
                            <div className="col-md-3 mb-4">
                              <label htmlFor="">Share entry: </label>
                              <p className="box-style">Share Transfer</p>
                            </div>
                          </>
                        )}
                      </div>
                      {(a.checkedList.shareholders ||
                        a.checkedList.nominee) && (
                        <div>
                          <h6>Transferor (Sender)</h6>
                          <div className="row overview-listing gy-3 mb-4">
                            <div className="col-md-3">
                              <label htmlFor="">Master file Code:</label>
                              <p className="box-style">EN00012</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">Master file Name:</label>
                              <p className="box-style">Sample</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">
                                Authorised Share Capital:
                              </label>
                              <p className="box-style">N/A</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">
                                Number of shares transferred:
                              </label>
                              <p className="box-style">550</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">From:</label>
                              <p className="box-style">22-10-2024</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">To:</label>
                              <p className="box-style">23-10-2024</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">Share type:</label>
                              <p className="box-style">N/A</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">Par Value:</label>
                              <p className="box-style">200</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">Effective date:</label>
                              <p className="box-style">10-12-2024</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">Total Shares held:</label>
                              <p className="box-style">2000</p>
                            </div>

                            <div className="col-md-3">
                              <label htmlFor="">Ownership percentage:</label>
                              <p className="box-style">30%</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">Certificate Number:</label>
                              <p className="box-style">ECE02823</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">Date of entry:</label>
                              <p className="box-style">22-10-2024</p>
                            </div>
                          </div>
                          <h6>Transferee (Receiver)</h6>
                          <div className="row overview-listing gy-3 mb-4">
                            <div className="col-md-3">
                              <label htmlFor="">Master file Code:</label>
                              <p className="box-style">EN00012</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">Master file Name:</label>
                              <p className="box-style">Sample</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">
                                Authorised Share Capital:
                              </label>
                              <p className="box-style">N/A</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">
                                Number of shares received:
                              </label>
                              <p className="box-style">550</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">From:</label>
                              <p className="box-style">22-10-2024</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">To:</label>
                              <p className="box-style">23-10-2024</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">Effective date:</label>
                              <p className="box-style">10-12-2024</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">Total Shares held:</label>
                              <p className="box-style">2000</p>
                            </div>

                            <div className="col-md-3">
                              <label htmlFor="">Ownership percentage:</label>
                              <p className="box-style">30%</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">Certificate Number:</label>
                              <p className="box-style">ECE02823</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">Date of entry:</label>
                              <p className="box-style">22-10-2024</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <span>&nbsp; </span>
                </>
              );
            })
          : []}
        <h4 className="primary-title mt-4 mb-4">
          Corporate Entity ({copEntityDetails?.data?.rows?.length})
        </h4>
        {copEntityDetails
          ? copEntityDetails?.data?.rows?.map((a, i) => {
              return (
                <>
                  <div className="border-box">
                    <div className="d-flex my-4 gap-3 align-items-center">
                      <Checkbox
                        className="custom-checkbox"
                        disabled
                        checked={a.checkedList.directors ?? false}
                      >
                        Directors
                      </Checkbox>
                      <Checkbox
                        className="custom-checkbox"
                        disabled
                        checked={a.checkedList.shareholders ?? false}
                      >
                        Shareholders
                      </Checkbox>
                      <Checkbox
                        className="custom-checkbox"
                        disabled
                        checked={a.checkedList.ubo ?? false}
                      >
                        UBOs
                      </Checkbox>
                      {/* <Checkbox className="custom-checkbox" disabled>
              POC
            </Checkbox> */}
                      <Checkbox
                        className="custom-checkbox"
                        disabled
                        checked={a.checkedList.nominee ?? false}
                      >
                        Nominee Shareholder
                      </Checkbox>
                      <Checkbox
                        className="custom-checkbox"
                        disabled
                        checked={a.checkedList.reserve ?? false}
                      >
                        Reserve Director
                      </Checkbox>
                    </div>
                    <div key={i}>
                      <h3 className="sub-title mb-3">
                        Corporate Entity 0{i + 1} &nbsp;
                        <i className="fal fa-question-circle"></i>
                      </h3>
                      <div className="row overview-listing mb-4">
                        {a.copEntityArray?.code && (
                          <div className="col-md-3 mb-4">
                            <label htmlFor="">
                              Individual Masterfile Code:
                            </label>
                            <p className="box-style">{a.copEntityArray.code}</p>
                          </div>
                        )}

                        {a.copEntityArray?.name && (
                          <div className="col-md-3 mb-4">
                            <label htmlFor="">Name:</label>
                            <p className="box-style">{a.copEntityArray.name}</p>
                          </div>
                        )}
                        {a.copEntityArray?.type && (
                          <div className="col-md-3 mb-4">
                            <label htmlFor="">Type:</label>
                            <p className="box-style">{a.copEntityArray.type}</p>
                          </div>
                        )}
                        {a.copEntityArray?.incorporationDate && (
                          <div className="col-md-3 mb-4">
                            <label htmlFor="">Incorporation Date:</label>
                            <p className="box-style">
                              {moment(
                                a.copEntityArray.incorporationDate
                              ).format("DD-MMM-YYYY")}
                            </p>
                          </div>
                        )}

                        {a.copEntityArray?.incorporationCountry && (
                          <div className="col-md-3 mb-4">
                            <label htmlFor="">Incorporation Country:</label>
                            <p className="box-style">
                              {a.copEntityArray.incorporationCountry}
                            </p>
                          </div>
                        )}

                        {a.copEntityArray?.incorporationNumber && (
                          <div className="col-md-3 mb-4">
                            <label htmlFor="">Incorporation Number </label>
                            <p className="box-style">
                              {a.copEntityArray.incorporationNumber}
                            </p>
                          </div>
                        )}

                        {a.copEntityArray?.busNature && (
                          <div className="col-md-3 mb-4">
                            <label htmlFor="">Business Nature</label>
                            <p className="box-style">
                              {a.copEntityArray.busNature}
                            </p>
                          </div>
                        )}

                        {(a.checkedList.shareholders ||
                          a.checkedList.nominee) && (
                          <>
                            <div className="col-md-3 mb-4">
                              <label htmlFor="">No. of Shares Held: </label>
                              <p className="box-style">2600</p>
                            </div>
                            <div className="col-md-3 mb-4">
                              <label htmlFor="">Share entry: </label>
                              <p className="box-style">New Allocation</p>
                            </div>
                          </>
                        )}
                      </div>
                      {(a.checkedList.shareholders ||
                        a.checkedList.nominee) && (
                        <div>
                          <h6>New Allocation</h6>
                          <div className="row overview-listing gy-3 mb-4">
                            <div className="col-md-3">
                              <label htmlFor="">Master file Code:</label>
                              <p className="box-style">EN00012</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">Master file Name:</label>
                              <p className="box-style">Sample</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">
                                Authorised Share Capital:
                              </label>
                              <p className="box-style">N/A</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">
                                Number of shares allocated:
                              </label>
                              <p className="box-style">550</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">From:</label>
                              <p className="box-style">22-10-2024</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">To:</label>
                              <p className="box-style">23-10-2024</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">Share type:</label>
                              <p className="box-style">N/A</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">Par Value:</label>
                              <p className="box-style">200</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">Effective date:</label>
                              <p className="box-style">10-12-2024</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">Total Shares held:</label>
                              <p className="box-style">2000</p>
                            </div>

                            <div className="col-md-3">
                              <label htmlFor="">Ownership percentage:</label>
                              <p className="box-style">30%</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">Certificate Number:</label>
                              <p className="box-style">ECE02823</p>
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="">Date of entry:</label>
                              <p className="box-style">22-10-2024</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              );
            })
          : []}
        {/* <h4 className="primary-title mb-4">Shareholders</h4>
        {shareholders.map((a, i) => {
          return (
            <div key={i} className="mb-4">
              <h3 className="sub-title mb-4">
                Shareholder 0{a} &nbsp;
                <i className="fal fa-question-circle"></i>
              </h3>
              <div className="row overview-listing">
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Individual Masterfile Code:</label>
                  <p>S002</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Salutation:</label>
                  <p>Mr.</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">First Name:</label>
                  <p>Jacob</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Middle Name:</label>
                  <p>Smith</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Last Name:</label>
                  <p>Jackson</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Date of Birth:</label>
                  <p>23-08-1986</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Country of Birth: </label>
                  <p>Sri Lanka</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Nationality:</label>
                  <p>Sri Lankan</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Passport Number:</label>
                  <p>021545879654</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Expiry Date</label>
                  <p>12-12-2028</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">No. of Shares Held</label>
                  <p>1000</p>
                </div>
                <div className="col-md-3 mb-4"></div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Risk Level</label>
                  <p>Low</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">PEP</label>
                  <p>Yes</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Tax Info Number </label>
                  <p>04563218</p>
                </div>
                <div className="col-md-3 mb-4"></div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h6
                  className="hyper-link mb-0"
                  onClick={() => toggleHistory(a)}
                >
                  Detailed History{" "}
                  <i
                    className={`fas fa-chevron-down ${
                      historyShow.findIndex((hs) => hs === a) !== -1
                        ? "fa-rotate-180"
                        : ""
                    }`}
                  ></i>
                </h6>
                {historyShow.findIndex((hs) => hs === a) !== -1 ? (
                  <button className="primary-btn ms-auto">Bulk Export</button>
                ) : (
                  <span className="py-2">&nbsp;</span>
                )}
              </div>
              {historyShow.findIndex((hs) => hs === a) !== -1 ? (
                <Table
                  className="custom-table-light mt-md-1"
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                />
              ) : (
                <></>
              )}
            </div>
          );
        })}
        <h4 className="primary-title mb-4">UBO</h4>
        {ubo.map((a, i) => {
          return (
            <div key={i} className="mb-4">
              <h3 className="sub-title mb-4">
                UBO 0{a} &nbsp;
                <i className="fal fa-question-circle"></i>
              </h3>
              <div className="row overview-listing">
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Individual Masterfile Code:</label>
                  <p>U002</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Salutation:</label>
                  <p>Mr.</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">First Name:</label>
                  <p>Jacob</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Middle Name:</label>
                  <p>Smith</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Last Name:</label>
                  <p>Jackson</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Date of Birth:</label>
                  <p>23-08-1986</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Country of Birth: </label>
                  <p>Sri Lanka</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Nationality:</label>
                  <p>Sri Lankan</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Passport Number:</label>
                  <p>021545879654</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Expiry Date</label>
                  <p>12-12-2028</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Ownership Pecentage:</label>
                  <p>49%</p>
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Total Shares Held:</label>
                  <p>1000</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Risk Level</label>
                  <p>Low</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">PEP</label>
                  <p>Yes</p>
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="">Tax Info Number</label>
                  <p>04563218</p>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h6
                  className="hyper-link mb-0"
                  onClick={() => setUboHistory(!uboHistory)}
                >
                  Detailed History{" "}
                  <i
                    className={`fas fa-chevron-down ${
                      uboHistory ? "fa-rotate-180" : ""
                    }`}
                  ></i>
                </h6>
                {uboHistory ? (
                  <button className="primary-btn ms-auto">Bulk Export</button>
                ) : (
                  <span className="py-2">&nbsp;</span>
                )}
              </div>
              {uboHistory ? (
                <Table
                  className="custom-table-light mt-md-1"
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                />
              ) : (
                <></>
              )}
            </div>
          );
        })} */}

        <h4 className="primary-title mt-4 mb-4">CDD Information</h4>
        <div className="overview-listing mb-4 mb-md-5">
          <div className="mb-4">
            <label htmlFor="" className="me-3">
              Source of Wealth
            </label>
            <p className="box-style"> {cddInformation.wealth}</p>
          </div>
          <div className="mb-4">
            <label htmlFor="" className="me-3">
              Source of Funds
            </label>
            <p className="box-style">{cddInformation.funds}</p>
          </div>
          <div className="mb-4">
            <label htmlFor="" className="me-3">
              Purpose of Business
            </label>
            <p className="box-style">{cddInformation.business}</p>
          </div>
          <div className="row">
            {value ? (
              <div className="col-md-3">
                <div className="mb-4">
                  <label htmlFor="" className="me-3 mb-1">
                    Geography of Business
                  </label>
                  <p className="box-style">
                    <TreeSelect {...tProps} placement="topLeft" />
                  </p>
                </div>
              </div>
            ) : null}
            <div className="col-md-3 mb-4">
              <label htmlFor="" className="me-3 mb-1">
                Business Activity
              </label>
              <p className="box-style">{cddInformation.activity}</p>
            </div>
            {/* <div className="col-md-3">
              <div className="mb-4">
                <label htmlFor="" className="me-3 mb-1">
                  Business Risk
                </label>
                <p>Low</p>
              </div>
            </div> */}
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="mb-4">
                <label htmlFor="" className="mb-1">
                  Entity Last Reviewed Date
                </label>
                <p className="box-style">
                  {moment(cddInformation.lastReviewed).format("DD-MMM-YYYY")}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-4">
                <label htmlFor="" className="mb-1">
                  Entity Next Review Date
                </label>
                <p className="box-style">
                  {moment(cddInformation.nextReviewed).format("DD-MMM-YYYY")}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-4">
                <label htmlFor="" className="mb-1">
                  Overall Entity Risk Level
                </label>
                <p className="box-style">Low</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 mb-4">
              <label htmlFor="" className="me-3 mb-1">
                Reminder
              </label>
              <p className="box-style">2</p>
            </div>
            {/* <div className="col-md-3">
              <div className="mb-4">
                <label htmlFor="" className="me-3 mb-1">
                  Industry
                </label>
                <p>Sample</p>
              </div>
            </div> */}
          </div>
          <div className="row">
            {reminderCount &&
              reminderCount.map((a, i) => {
                return (
                  <div className="col-md-3">
                    <div className="mb-4">
                      <label htmlFor="" className="mb-1">
                        Reminder {i + 1} Date
                      </label>
                      <p className="box-style">
                        {moment(a.data).format("DD-MMM-YYYY")}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="row">
            {bossFilingCount &&
              bossFilingCount.map((a, i) => {
                return (
                  <div className="col-md-3">
                    <div className="mb-4">
                      <label htmlFor="" className="mb-1">
                        Boss Filing {i + 1} Date
                      </label>
                      <p className="box-style">
                        {moment(a.data).format("DD-MMM-YYYY")}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
          {!iframe && (
            <div className="d-flex mt-4 gap-3">
              {/* <Popover
                className="custom-popover"
                placement="top"
               
                content={
                  <div className="certificate-links">
                    <Link
                      to={`/app/certificate-of-incumbency?entityId=${query.id}`}
                    >
                      With register of charges
                    </Link>
                    <Link
                      to={`/app/certificate-of-incumbency?entityId=${query.id}`}
                    >
                      Without register of charges
                    </Link>
                  </div>
                }
              >
                <button className="primary-btn">Generate COI</button>
              </Popover> */}
              <Popover
                className="custom-popover"
                placement="top"
                content={
                  selectedCategory
                    ? renderSubOptions() // Show sub-options with selected category
                    : renderMainOptions() // Show main categories to select from
                }
              >
                <button
                  className="primary-btn"
                  aria-label="Generate Certificate of Incumbency"
                >
                  Generate COI
                </button>
              </Popover>
              <button className="outline-btn">Request COGS</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
