import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Select } from "antd";
import moment from "moment/moment";
import {
  generateRandom,
  getQuery,
  fetchApi,
  getOptions,
  convertQueryParams,
} from "../../helper";

import Client from "./components/client";
import IntroducerClient from "./components/introducer-client";
import ParentClient from "./components/parent-client";
import EndUserClient from "./components/end-user-client";
import Entity from "./components/entity";
import Individual from "../../components/IndividualAnalysis";
import CorporateEntity from "../../components/CorporateEntity";

import { getClient } from "../../store/client/clientSlice";
import { getIntroducerClient } from "../../store/introducerClient/introducerClientSlice";
import { getParentClient } from "../../store/parentClient/parentClientSlice";
import { getEndUserClient } from "../../store/endUserClient/endUserClientSlice";
import { getEntity } from "../../store/entity/entitySlice";
import { getCorporateEntity } from "../../store/corporateEntity/corporateEntitySlice";
import { getIndividual } from "../../store/individual/individualSlice";

//{selectedMFT }
export default function Creation() {
  let query = getQuery();
  const client = useSelector((state) => state.client);
  const parent = useSelector((state) => state.parentClient);
  const endUser = useSelector((state) => state.endUserClient);
  const entity = useSelector((state) => state.entity);
  const corporateEntity = useSelector((state) => state.corporateEntity);
  const individual = useSelector((state) => state.individual);
  const introducer = useSelector((state) => state.introducerClient);

  const [clientClicked, setClientClicked] = useState(false);
  const [parentClicked, setParentClicked] = useState(false);
  const [endUserClicked, setEndUserClicked] = useState(false);
  const [entityClicked, setEntityClicked] = useState(false);
  const [copEntityClicked, setCopEntityClicked] = useState(false);
  const [individualClicked, setIndividualClicked] = useState(false);
  const [introducerClientClicked, setIntroducerClientClicked] = useState(false);
  const [selectedPage, setselectedPage] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [feeCategoryArray, setFeeCategoryArray] = useState([]);
  const [feeSubCategoryArray, setFeeSubCategoryArray] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    handleRefresh(query.type);
  }, []);

  const handleButtonClick = () => {
    setEditMode(!editMode);
    console.log(selectedPage, "rxtytul");
    if (selectedPage == "client") {
      setClientClicked(true);
    } else if (selectedPage == "introducer-client") {
      setIntroducerClientClicked(true);
    } else if (selectedPage == "parent-client") {
      setParentClicked(true);
    } else if (selectedPage == "end-user-client") {
      setEndUserClicked(true);
    } else if (selectedPage == "entity") {
      setEntityClicked(true);
    } else if (selectedPage == "corporate-entity") {
      setCopEntityClicked(true);
    } else {
      setIndividualClicked(true);
    }
  };

  const handleRefresh = async (value) => {
    let params = { id: query.id };
    if (value == "client") {
      dispatch(getClient(params));
    } else if (value == "introducer-client") {
      dispatch(getIntroducerClient(params));
    } else if (value == "parent-client") {
      dispatch(getParentClient(params));
    } else if (value == "end-user") {
      dispatch(getEndUserClient(params));
    } else if (value == "entity") {
      dispatch(getEntity(params));
    } else if (value == "cop-entity") {
      dispatch(getCorporateEntity(params));
    } else {
      dispatch(getIndividual(params));
    }
    let payload = {
      method: "GET",
      url: `/feeCategory?_start=0&_limit=1000`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res.data.rows;
    });
    setFeeCategoryArray(data);
  };
  useEffect(() => {
    getData(query.type);
  }, [
    client,
    parent,
    endUser,
    entity,
    corporateEntity,
    individual,
    introducer,
  ]);

  const getData = async (value) => {
    if (value == "client") {
      setTableData(client?.data?.rows[0]);
    } else if (value == "introducer-client") {
      setTableData(introducer?.data?.rows[0]);
    } else if (value == "parent-client") {
      setTableData(parent?.data?.rows[0]);
    } else if (value == "end-user") {
      setTableData(endUser?.data?.rows[0]);
    } else if (value == "entity") {
      setTableData(entity?.data?.rows[0]);
    } else if (value == "cop-entity") {
      setTableData(corporateEntity?.data?.rows[0]);
    } else if (value == "individual") {
      setTableData(individual?.data?.rows[0]);
    }
  };

  let list = feeCategoryArray.map((d) => {
    let obj = {
      value: d?._id,
      label: d?.name,
    };
    return obj;
  });

  useEffect(() => {
    if (tableData?.feeCat && tableData?.feeType) {
      getFeeSubCategory(tableData.feeCat, tableData.feeType);
    }
  }, [tableData?.feeCat, tableData?.feeType]);
  const getFeeSubCategory = async (catId, type) => {
    if (catId) {
      let queryParams = { catId: catId };
      let query = convertQueryParams(queryParams);
      let obj = {
        method: "GET",
        url: `/feeCategory/sub/?${query}`,
      };

      let subData = await fetchApi(obj).then((res) => {
        return res.data.data;
      });

      let sublist = subData
        ? subData?.map((d) => {
            let obj = {
              value: d?._id,
              label: d?.catType,
            };
            return obj;
          })
        : [];
      setFeeSubCategoryArray(sublist);
    }
  };

  const getFeeSubCategories = (subCatIds) => {
    if (feeSubCategoryArray && subCatIds) {
      return subCatIds
        ?.map((id) => {
          const sub = feeSubCategoryArray?.find(
            (option) => option.value === id
          );
          return sub?.label || "Not Found";
        })
        .join(", ");
    } else {
      return "Not Found";
    }
  };
  const getRelatedEntity = (subCatIds) => {
    const params = { _start: 0, _limit: 1000 };

    if (!entity?.data?.rows) {
      dispatch(getEntity(params));
      return "Not Found";
    }

    if (entity?.data?.rows && subCatIds) {
      const sublist = entity?.data?.rows?.map((d) => ({
        value: d._id,
        label: d.code,
      }));

      return subCatIds
        ?.map(
          (id) =>
            sublist.find((option) => option.value === id)?.label || "Not Found"
        )
        .join(", ");
    }

    return "Not Found";
  };

  return (
    <div>
      <div className="page-header">
        <Link to={"/app/master-search"}>
          <h1 className="d-flex align-items-center gap-2">
            <i className="fal fa-long-arrow-left"></i> Master File Overview
          </h1>
        </Link>
        {
          <button
            onClick={() => setIsUpdate(!isUpdate)}
            className="primary-btn"
          >
            {isUpdate ? `View` : "Modify"}
          </button>
        }
      </div>

      <div className="page-content">
        {query.type == "client" && isUpdate == true ? (
          <Client
            clientClicked={clientClicked}
            setselectedPage={setselectedPage}
            setClientClicked={setClientClicked}
            selectedDate={tableData}
          />
        ) : query.type == "client" && isUpdate == false ? (
          tableData && (
            <div className="custom-form">
              <h2 className="sub-title mb-4">{`Master File View: Client`}</h2>

              <div className="row">
                <div className="col-lg-9">
                  <div className="row overview-listing border-box mb-5">
                    {[
                      { label: "Masterfile Code", value: tableData.code },
                      { label: "Masterfile Name", value: tableData.name },
                      { label: "Client Type", value: tableData.type },
                      { label: "Introducer Code", value: tableData.pCode },
                      { label: "Status", value: tableData.status },
                      {
                        label: "Relationship Manager",
                        value: tableData.relationshipManager,
                      },
                      {
                        label: "Administrator",
                        value: tableData.administrator,
                      },
                      { label: "Admin Manager", value: tableData.adminManager },
                      { label: "Jurisdiction", value: tableData.jurisdiction },
                      { label: "Email", value: tableData.email },
                      { label: "Contact Number", value: tableData.contactNo },
                      { label: "Date of Creation", value: tableData.createdAt },
                    ].map((field, index) => (
                      <div className="col-md-4 mb-4" key={index}>
                        <label>{field.label}:</label>
                        <p className="box-style">{field.value}</p>
                      </div>
                    ))}
                    <div className="col-md-4 mb-4">
                      <label>Fee Schedule Name:</label>
                      <p className="box-style">
                        {getOptions(list).find(
                          (option) => option.value === tableData.feeCat
                        )?.label || "Not Found"}
                      </p>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label>Fee Type:</label>
                      <p className="box-style">{tableData.feeType}</p>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label>Fee Sub Category:</label>
                      <p className="box-style">
                        {getFeeSubCategories(tableData.subCat)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <></>
        )}
        {query.type == "introducer-client" && isUpdate == true ? (
          <IntroducerClient
            introducerClientClicked={introducerClientClicked}
            setselectedPage={setselectedPage}
            setIntroducerClientClicked={setIntroducerClientClicked}
            selectedDate={tableData}
          />
        ) : query.type == "introducer-client" && isUpdate == false ? (
          tableData && (
            <div className="custom-form">
              <h2 className="sub-title mb-4">{`Master File View: Introducer Client`}</h2>
              <div className="row">
                <div className="col-lg-9">
                  <div className="row overview-listing border-box mb-5">
                    {[
                      {
                        value: tableData.code,
                        label: "Master File Code",
                      },
                      {
                        value: tableData.name,
                        label: "Master File Name",
                      },
                      {
                        value: tableData.type,
                        label: "Introducer Client Type",
                      },
                      {
                        value: tableData.status,
                        label: "Master File Status",
                      },
                      {
                        value: tableData.relationshipManager,
                        label: "Relationship Manager",
                      },
                      {
                        value: tableData.administrator,
                        label: "Administrator",
                      },
                      { value: tableData.adminManager, label: "Admin Manager" },
                      { value: tableData.jurisdiction, label: "Jurisdiction" },
                      { value: tableData.email, label: "Email" },
                      { value: tableData.contactNo, label: "Contact Number" },
                      { value: tableData.createdAt, label: "Date of Creation" },
                    ].map((field, index) => (
                      <div className="col-md-4 mb-4" key={index}>
                        <label>{field.label}:</label>
                        {field.isDropdown ? (
                          <select className="box-style" disabled>
                            {field.options.map((option, idx) => (
                              <option key={idx} value={option.value}>
                                {option.value}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <p className="box-style">{field.value}</p>
                        )}
                      </div>
                    ))}

                    <div className="col-md-4 mb-4">
                      <label>Fee Schedule Name:</label>
                      <p className="box-style">
                        {getOptions(list).find(
                          (option) => option.value === tableData.feeCat
                        )?.label || "Not Found"}
                      </p>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label>Fee Type:</label>
                      <p className="box-style">{tableData.feeType}</p>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label>Fee Sub Category:</label>
                      <p className="box-style">
                        {getFeeSubCategories(tableData.subCat)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <></>
        )}
        {query.type == "parent-client" && isUpdate == true ? (
          <ParentClient
            parentClicked={parentClicked}
            setselectedPage={setselectedPage}
            setParentClicked={setParentClicked}
            selectedDate={tableData}
          />
        ) : query.type == "parent-client" && isUpdate == false ? (
          tableData && (
            <div className="custom-form">
              <h2 className="sub-title mb-4">{`Master File View: Parent Client`}</h2>
              <div className="row">
                <div className="col-lg-9">
                  <div className="row overview-listing border-box mb-5">
                    {[
                      {
                        value: tableData.code,
                        label: "Master File Code",
                      },
                      {
                        value: tableData.name,
                        label: "Master File Name",
                      },
                      {
                        value: tableData.type,
                        label: "Parent Client Type",
                      },
                      {
                        value: tableData.status,
                        label: "Master File Status",
                      },
                      {
                        value: tableData.relationshipManager,
                        label: "Relationship Manager",
                      },
                      {
                        value: tableData.administrator,
                        label: "Administrator",
                      },
                      { value: tableData.adminManager, label: "Admin Manager" },
                      { value: tableData.jurisdiction, label: "Jurisdiction" },
                      { value: tableData.email, label: "Email" },
                      { value: tableData.contactNo, label: "Contact Number" },
                      { value: tableData.createdAt, label: "Date of Creation" },
                    ].map((field, index) => (
                      <div className="col-md-4 mb-4" key={index}>
                        <label>{field.label}:</label>
                        {field.isDropdown ? (
                          <select className="box-style" disabled>
                            {field.options.map((option, idx) => (
                              <option key={idx} value={option.value}>
                                {option.value}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <p className="box-style">{field.value}</p>
                        )}
                      </div>
                    ))}

                    <div className="col-md-4 mb-4">
                      <label>Fee Schedule Name:</label>
                      <p className="box-style">
                        {getOptions(list).find(
                          (option) => option.value === tableData.feeCat
                        )?.label || "Not Found"}
                      </p>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label>Fee Type:</label>
                      <p className="box-style">
                        {tableData.feeType ? tableData.feeType : "Not Found"}
                      </p>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label>Fee Sub Category:</label>
                      <p className="box-style">
                        {getFeeSubCategories(tableData.subCat)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <></>
        )}
        {query.type == "end-user" && isUpdate == true ? (
          <EndUserClient
            endUserClicked={endUserClicked}
            setselectedPage={setselectedPage}
            setEndUserClicked={setEndUserClicked}
            selectedDate={tableData}
          />
        ) : query.type == "end-user" && isUpdate == false ? (
          tableData && (
            <div className="custom-form">
              <h2 className="sub-title mb-4">{`Master File View: End User Client`}</h2>
              <div className="row">
                <div className="col-lg-9">
                  <div className="row overview-listing border-box mb-5">
                    {[
                      {
                        value: tableData.code,
                        label: "Master File Code",
                      },
                      {
                        value: tableData.name,
                        label: "Master File Name",
                      },
                      {
                        value: tableData.type,
                        label: "End User Client Type",
                      },
                      {
                        value: tableData.status,
                        label: "Master File Status",
                      },
                      {
                        value: tableData.relationshipManager,
                        label: "Relationship Manager",
                      },
                      {
                        value: tableData.administrator,
                        label: "Administrator",
                      },
                      { value: tableData.adminManager, label: "Admin Manager" },
                      { value: tableData.jurisdiction, label: "Jurisdiction" },
                      { value: tableData.email, label: "Email" },
                      { value: tableData.contactNo, label: "Contact Number" },
                      { value: tableData.createdAt, label: "Date of Creation" },
                    ].map((field, index) => (
                      <div className="col-md-4 mb-4" key={index}>
                        <label>{field.label}:</label>
                        {field.isDropdown ? (
                          <select className="box-style" disabled>
                            {field.options.map((option, idx) => (
                              <option key={idx} value={option.value}>
                                {option.value}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <p className="box-style">{field.value}</p>
                        )}
                      </div>
                    ))}

                    <div className="col-md-4 mb-4">
                      <label>Fee Schedule Name:</label>
                      <p className="box-style">
                        {getOptions(list).find(
                          (option) => option.value === tableData.feeCat
                        )?.label || "Not Found"}
                      </p>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label>Fee Type:</label>
                      <p className="box-style">
                        {tableData.feeType ? tableData.feeType : "Not Found"}
                      </p>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label>Fee Sub Category:</label>
                      <p className="box-style">
                        {getFeeSubCategories(tableData.subCat)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <></>
        )}
        {query.type == "entity" && (
          <Entity
            entityClicked={entityClicked}
            setselectedPage={setselectedPage}
            setEntityClicked={setEntityClicked}
            selectedDate={tableData}
          />
        )}
        {query.type == "individual" && isUpdate == true ? (
          <div>
            <h2 className="sub-title mb-4">{`Master File Update: Individual`}</h2>

            <Individual
              fileCreation={true}
              individualClicked={individualClicked}
              setselectedPage={setselectedPage}
              setIndividualClicked={setIndividualClicked}
              selectedDateId={query.id}
            />
          </div>
        ) : query.type == "individual" && isUpdate == false ? (
          tableData && (
            <div className="custom-form">
              <h2 className="sub-title mb-4">{`Master File View: Individual`}</h2>
              <div className="row">
                <div className="col-lg-9">
                  <div className="row overview-listing border-box mb-5">
                    {[
                      {
                        value: tableData.code,
                        label: "Master Code",
                      },
                      {
                        value: tableData.title,
                        label: "Title",
                      },
                      {
                        value: tableData.status,
                        label: "Individual Status",
                      },
                      {
                        value: tableData.firstName,
                        label: "First Name",
                      },
                      {
                        value: tableData.middleName,
                        label: "Middle Name",
                      },
                      {
                        value: tableData.lastName,
                        label: "Last Name",
                      },
                      {
                        value: moment(tableData.DOB).format("YYYY-MM-DD"),
                        label: "Date of Birth",
                      },
                      { value: tableData.COB, label: "Country of Birth" },
                      { value: tableData.nationality, label: "Nationality" },
                      { value: tableData.passportNo, label: "Passport Number" },
                      {
                        value: tableData.passportIssue,
                        label: "Issue Date",
                      },
                      {
                        value: tableData.passportExp,
                        label: "Expiry Date",
                      },
                      { value: tableData.idNo, label: "ID Number" },
                      {
                        value: tableData.idIssue,
                        label: "Issue Date",
                      },
                      {
                        value: tableData.idExp,
                        label: "Expiry Date",
                      },
                      {
                        value: tableData.taxInfo,
                        label: "Tax Info Number",
                      },
                      {
                        value: tableData.email,
                        label: "Email",
                      },
                      {
                        value: tableData.contactNo,
                        label: "Contact Number",
                      },
                      {
                        value: tableData.associateBus,
                        label: "Associate business",
                      },
                      {
                        value: tableData.relatedEntityCode,
                        label: "Related Entities",
                      },
                      {
                        value: tableData.createdAt,
                        label: "Date of Creation",
                      },
                    ].map((field, index) => (
                      <div className="col-md-4 mb-4" key={index}>
                        <label>{field.label}:</label>
                        {field.isDropdown ? (
                          <select className="box-style" disabled>
                            {field.options.map((option, idx) => (
                              <option key={idx} value={option.value}>
                                {option.value}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <p className="box-style">{field.value}</p>
                        )}
                      </div>
                    ))}
                    <div className="col-md-4 mb-4">
                      <label>Service Address:</label>
                      <p className="box-style">{tableData.serviceAddress}</p>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label>Residential Address:</label>
                      <p className="box-style">
                        {tableData.residentialAddress}
                      </p>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label>Risk Rating:</label>
                      <p className="box-style">
                        {tableData?.details?.riskRating}
                      </p>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label>PEP:</label>
                      <p className="box-style">
                        {tableData?.details?.pep == "Y" ? "Yes" : "No"}
                      </p>
                    </div>
                    {tableData?.details?.pep == "Y" ? (
                      <div className="col-md-4 mb-4">
                        <label> PEP Text:</label>
                        <p className="box-style">
                          {tableData?.details?.pepText}
                        </p>
                      </div>
                    ) : null}
                    <div className="col-md-4 mb-4">
                      <label>Sanction:</label>
                      <p className="box-style">
                        {tableData?.details?.sanctionText == "Y" ? "Yes" : "No"}
                      </p>
                    </div>
                    {tableData?.details?.sanctionText == "Y" ? (
                      <div className="col-md-4 mb-4">
                        <label> Sanction Text:</label>
                        <p className="box-style">
                          {tableData?.details?.sanctionText}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <></>
        )}

        {query.type == "cop-entity" && isUpdate == true ? (
          <div>
            <h2 className="sub-title mb-4">
              Master File Creation: Corporate Entity
            </h2>
            <CorporateEntity
              fileCreation={true}
              copEntityClicked={copEntityClicked}
              setselectedPage={setselectedPage}
              setCopEntityClicked={setCopEntityClicked}
              selectedDateId={query.id}
            />
          </div>
        ) : query.type == "cop-entity" && isUpdate == false ? (
          tableData && (
            <div className="custom-form">
              <h2 className="sub-title mb-4">{`Master File View: Corporate Entity`}</h2>
              <div className="row">
                <div className="col-lg-9">
                  <div className="row overview-listing border-box mb-5">
                    {[
                      {
                        value: tableData.code,
                        label: "Master Code",
                      },
                      {
                        value: tableData.name,
                        label: "Master File Name",
                      },
                      {
                        value: tableData.type,
                        label: "Corporate Entity Type",
                        isDropdown: true,
                        options: [
                          {
                            value: "BVIBC",
                            label: "BVIBC",
                          },
                          {
                            value: "trust",
                            label: "Trust",
                          },
                          {
                            value: "foundation",
                            label: "Foundation",
                          },
                          {
                            value: "general-partnership",
                            label: "General Partnership",
                          },
                          {
                            value: "limited-partnership",
                            label: "Limited Partnership",
                          },
                        ],
                      },

                      {
                        value: tableData.status,
                        label: "Corporate Entity Status",
                      },
                      {
                        value: moment(tableData.incorporationDate).format(
                          "YYYY-MM-DD"
                        ),
                        label: "Incorporation Date",
                      },
                      {
                        value: tableData.incorporationNumber,
                        label: "Incorporation Number",
                      },
                      {
                        value: tableData.incorporationCountry,
                        label: "Country Of Incorporation",
                      },
                      // {
                      //   value: tableData.busNature,
                      //   label: "Geography of business",
                      // },
                      {
                        value: tableData.busNature,
                        label: "Nature of Business",
                      },
                      {
                        value: tableData.busDesc,
                        label: "Description of Business",
                      },
                      {
                        value: tableData.corpInfoNo,
                        label: "Corporate Tax Info Number",
                      },
                      // {
                      //   value: tableData.relatedEntityCode,
                      //   label: "Related Entity Code",
                      // },
                      {
                        value: moment(tableData.createdAt).format("YYYY-MM-DD"),
                        label: "Date of Creation",
                      },

                      {
                        value: tableData.associateBus,
                        label: "Associate business",
                      },

                      {
                        value: tableData.createdAt,
                        label: "Date of Creation",
                      },
                      {
                        value: tableData?.details?.address?.line_1,
                        label: "Address Line 01",
                      },
                      {
                        value: tableData?.details?.address?.line_2,
                        label: "Address Line 02",
                      },
                      {
                        value: tableData?.details?.address?.line_3,
                        label: "Address Line 03",
                      },
                      {
                        value: tableData?.details?.address?.country,
                        label: "Country",
                      },
                      {
                        value: tableData?.details?.address?.zipCode,
                        label: "Post/Zip code",
                      },
                    ].map((field, index) => (
                      <div className="col-md-4 mb-4" key={index}>
                        <label>{field.label}:</label>
                        {field.isDropdown ? (
                          <select className="box-style" disabled>
                            {field.options.map((option, idx) => (
                              <option key={idx} value={option.value}>
                                {option.value}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <p className="box-style">{field.value}</p>
                        )}
                      </div>
                    ))}
                    {/* <div className="col-md-4 mb-4">
                      <label>Related Entity:</label>
                      <p className="box-style">
                        {getRelatedEntity(tableData.relatedEntityCode)}
                      </p>
                    </div> */}
                    <div className="col-md-4 mb-4">
                      <label>Service Address:</label>
                      <p className="box-style">{tableData.serviceAddress}</p>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label>Residential Address:</label>
                      <p className="box-style">
                        {tableData.residentialAddress}
                      </p>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label>Risk Rating:</label>
                      <p className="box-style">
                        {tableData?.details?.riskRating}
                      </p>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label>PEP:</label>
                      <p className="box-style">
                        {tableData?.details?.pep == "Y" ? "Yes" : "No"}
                      </p>
                    </div>
                    {tableData?.details?.pep == "Y" ? (
                      <div className="col-md-4 mb-4">
                        <label> PEP Text:</label>
                        <p className="box-style">
                          {tableData?.details?.pepText}
                        </p>
                      </div>
                    ) : null}
                    <div className="col-md-4 mb-4">
                      <label>Sanction:</label>
                      <p className="box-style">
                        {tableData?.details?.sanctionText == "Y" ? "Yes" : "No"}
                      </p>
                    </div>
                    {tableData?.details?.sanctionText == "Y" ? (
                      <div className="col-md-4 mb-4">
                        <label> Sanction Text:</label>
                        <p className="box-style">
                          {tableData?.details?.sanctionText}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <></>
        )}
        {isUpdate ? (
          <button onClick={handleButtonClick} className="primary-btn">
            Update
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
