import React from "react";
import CorporateEntity from "../components/CorporateEntity";

export default function CorporateEntityAnalysis() {
  return (
    <div>
      <div className="page-header">
        <h1>Corporate Entity Analysis</h1>
      </div>
      <div className="page-content">
        <CorporateEntity actionShow={true} />
      </div>
    </div>
  );
}
