import { useState } from "react";
import { Link } from "react-router-dom";
import { Table, Tag, Select, Modal, Checkbox } from "antd";

import { Each, customPagination } from "../helper";

import UploadZ from "../components/UploadZ";
import TagZ from "../components/TagZ";
import TableSortArrows from "../components/tableSortArrows";

import SampleDoc from "../assets/pdf/sample.pdf";
import SampleDoc2 from "../assets/pdf/sample2.pdf";

export default function PendingRequests() {
  const [resubmitModal, setResubmitModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [docModal, setDocModal] = useState(false);
  const [uploadedDocModal, setUploadedDocModal] = useState(false);

  function storeDocuments(file) {
    let doc = [...documents];
    doc.push(file);

    setDocuments(doc);
  }

  const columns = [
    {
      title: "Request Ref",
      dataIndex: "ref",
      sorter: (a, b) => a.ref - b.ref,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Request type",
      dataIndex: "type",
      sorter: (a, b) => a.type - b.type,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Request Status",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => {
        let currStatus;
        switch (status) {
          case "Pending":
            currStatus = "new";
            break;
          case "In progress":
            currStatus = "in-progress";
            break;
          case "Resubmitted":
            currStatus = "resubmitted";
            break;
          case "Completed":
            currStatus = "approved";
            break;
        }
        return <TagZ status={currStatus} statusText={status} />;
      },
    },
    {
      title: "Origin Date",
      dataIndex: "originDate",
      sorter: (a, b) => a.originDate - b.originDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Pending Content",
      dataIndex: "pendingContent",
      sorter: (a, b) => a.pendingContent - b.pendingContent,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { id }) => (
        <Link to={"/app/relationship-manager"} className="action-btn icon-btn">
          <i className="fal fa-eye"></i>
        </Link>
      ),
    },
    {
      title: "Resubmission Content",
      dataIndex: "resubmission",
      sorter: (a, b) => a.resubmission - b.resubmission,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { id }) => (
        <button
          className="action-btn icon-btn"
          onClick={() => setResubmitModal(true)}
        >
          <i className="fal fa-eye"></i>
        </button>
      ),
    },
    {
      title: "Resubmission Status",
      dataIndex: "resubmitStatus",
      sorter: (a, b) => a.resubmitStatus - b.resubmitStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { resubmitStatus }) => {
        let status;
        switch (resubmitStatus) {
          case "Pending":
            status = "new";
            break;
          case "In progress":
            status = "in-progress";
            break;
          case "Resubmitted":
            status = "resubmitted";
            break;
          case "Completed":
            status = "approved";
            break;
        }
        return <TagZ status={status} statusText={resubmitStatus} />;
      },
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (_, { id }) => (
    //     <div className="d-flex justify-content-end align-items-center gap-3">
    //     <button className="action-btn">Resubmit</button>
    //         <Link to={`/app/onboarding-team/download/${id}`} className='action-btn'>View Request &nbsp; <i className="far fa-long-arrow-right me-0"></i></Link>
    //     </div>
    //   ),
    // },
  ];

  const data = [
    {
      key: "1",
      id: "1",
      ref: "Request #1",
      type: "Sample",
      status: "Pending",
      originDate: "18-02-2024",
      resubmitStatus: "Pending",
    },
    {
      key: "2",
      id: "2",
      ref: "Request #2",
      type: "Sample",
      status: "In progress",
      originDate: "19-02-2024",
      resubmitStatus: "In progress",
    },
    {
      key: "3",
      id: "3",
      ref: "Request #3",
      type: "Sample",
      status: "Completed",
      originDate: "20-02-2024",
      resubmitStatus: "Resubmitted",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const directors = [
    "Passport / ID",
    "Address Proof",
    "Consent to Act",
    "Resolution of Appointment",
    "Resolution of Resignation",
  ];

  const shareholders = [
    "Passport / ID",
    "Address Proof",
    "Consent to Act",
    "Resolution of Share Transfer",
    "Nominee Agreement",
    "Other",
  ];

  const ubos = [
    "Passport / ID",
    "Address Proof",
    "Resolution of Shareholding",
    "Other",
  ];

  const handleOk = () => {
    setDocModal(false);
    setUploadedDocModal(false);
  };
  const handleCancel = () => {
    setDocModal(false);
    setUploadedDocModal(false);
  };

  return (
    <div>
      <div className="page-header">
        <h1>Pending Information</h1>
        {/* <button className="primary-btn">Resubmit All</button> */}
      </div>
      <div className="page-content">
        <Table
          className="custom-table"
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={columns}
          dataSource={data}
          pagination={{
            className: "custom-pagination",
            itemRender: customPagination,
          }}
        />
        <Modal
          title={"Resubmission Content"}
          className="custom-modal"
          open={resubmitModal}
          width={800}
          onOk={() => setResubmitModal(false)}
          okText="Save & Close"
          onCancel={() => setResubmitModal(false)}
        >
          <div className="resubmission my-4">
            <h4 className="mb-3">Individual 01</h4>
            <div className="resubmission-content mb-4">
              <h6>Passport / ID</h6>
              <p>
                <span>Comment: </span>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Aliquid, debitis!
              </p>
              <div className="d-flex gap-2">
                <button
                  className="outline-btn"
                  onClick={() => setDocModal(true)}
                >
                  <i className="fal fa-eye"></i> Submitted Document
                </button>
                <label htmlFor="file" className="outline-btn">
                  <input type="file" name="file" id="file" className="d-none" />
                  <i className="fal fa-arrow-to-top"></i> Upload New Document
                </label>
                <button className="primary-btn justify-content-center" disabled>
                  Resubmit
                </button>
              </div>
            </div>
            <div className="resubmission-content mb-4">
              <h6>Address Proof</h6>
              <p>
                <span>Comment: </span>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Aliquid, debitis!
              </p>
              <div className="d-flex gap-2">
                <button
                  className="outline-btn"
                  onClick={() => setDocModal(true)}
                >
                  <i className="fal fa-eye"></i> Submitted Document
                </button>
                <button
                  className="outline-btn"
                  onClick={() => setUploadedDocModal(true)}
                >
                  <i className="fal fa-eye"></i> Uploaded Document
                </button>
                <button className="outline-btn red-btn">
                  <i className="fal fa-trash-alt"></i>
                </button>
                <button className="primary-btn justify-content-center">
                  Resubmit
                </button>
              </div>
            </div>
            <div className="resubmission-content mb-4">
              <h6>Consent to Act</h6>
              <p>
                <span>Comment: </span>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Aliquid, debitis!
              </p>
              <div className="d-flex gap-2">
                <button
                  className="outline-btn"
                  onClick={() => setDocModal(true)}
                >
                  <i className="fal fa-eye"></i> Submitted Document
                </button>
                <label htmlFor="file" className="outline-btn">
                  <input type="file" name="file" id="file" className="d-none" />
                  <i className="fal fa-arrow-to-top"></i> Upload New Document
                </label>
                <button className="primary-btn justify-content-center" disabled>
                  Resubmit
                </button>
              </div>
            </div>
          </div>
          <div className="resubmission my-4">
            <h4 className="mb-3">Individual 02</h4>
            <div className="resubmission-content mb-4">
              <h6>Passport / ID</h6>
              <p>
                <span>Comment: </span>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Aliquid, debitis!
              </p>
              <div className="d-flex gap-2">
                <button
                  className="outline-btn"
                  onClick={() => setDocModal(true)}
                >
                  <i className="fal fa-eye"></i> Submitted Document
                </button>
                <label htmlFor="file" className="outline-btn">
                  <input type="file" name="file" id="file" className="d-none" />
                  <i className="fal fa-arrow-to-top"></i> Upload New Document
                </label>
                <button className="primary-btn justify-content-center" disabled>
                  Resubmit
                </button>
              </div>
            </div>
            <div className="resubmission-content mb-4">
              <h6>Address Proof</h6>
              <p>
                <span>Comment: </span>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Aliquid, debitis!
              </p>
              <div className="d-flex gap-2">
                <button
                  className="outline-btn"
                  onClick={() => setDocModal(true)}
                >
                  <i className="fal fa-eye"></i> Submitted Document
                </button>
                <label htmlFor="file" className="outline-btn">
                  <input type="file" name="file" id="file" className="d-none" />
                  <i className="fal fa-arrow-to-top"></i> Upload New Document
                </label>
                <button className="primary-btn justify-content-center" disabled>
                  Resubmit
                </button>
              </div>
            </div>
            <div className="resubmission-content mb-4">
              <h6>No. of Shares Held</h6>
              <p>
                <span>Comment: </span>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Aliquid, debitis!
              </p>
              <div className="d-flex gap-2">
                <button
                  className="outline-btn"
                  onClick={() => setDocModal(true)}
                >
                  <i className="fal fa-eye"></i> Submitted Document
                </button>
                <label htmlFor="file" className="outline-btn">
                  <input type="file" name="file" id="file" className="d-none" />
                  <i className="fal fa-arrow-to-top"></i> Upload New Document
                </label>
                <button className="primary-btn justify-content-center" disabled>
                  Resubmit
                </button>
              </div>
            </div>
          </div>
          <div className="resubmission my-4">
            <h4 className="mb-3">Corporate Entity</h4>
            <div className="resubmission-content mb-4">
              <h6>Country Of Incorporation</h6>
              <p>
                <span>Comment: </span>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Aliquid, debitis!
              </p>
              <div className="d-flex gap-2">
                <button
                  className="outline-btn"
                  onClick={() => setDocModal(true)}
                >
                  <i className="fal fa-eye"></i> Submitted Document
                </button>
                <label htmlFor="file" className="outline-btn">
                  <input type="file" name="file" id="file" className="d-none" />
                  <i className="fal fa-arrow-to-top"></i> Upload New Document
                </label>
                <button className="primary-btn justify-content-center" disabled>
                  Resubmit
                </button>
              </div>
            </div>
            <div className="resubmission-content mb-4">
              <h6>Related Entity Code</h6>
              <p>
                <span>Comment: </span>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Aliquid, debitis!
              </p>
              <div className="d-flex gap-2">
                <button
                  className="outline-btn"
                  onClick={() => setDocModal(true)}
                >
                  <i className="fal fa-eye"></i> Submitted Document
                </button>
                <label htmlFor="file" className="outline-btn">
                  <input type="file" name="file" id="file" className="d-none" />
                  <i className="fal fa-arrow-to-top"></i> Upload New Document
                </label>
                <button className="primary-btn justify-content-center" disabled>
                  Resubmit
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          title={"Submitted Document"}
          className="custom-modal"
          open={docModal}
          width={1000}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="document-view">
            <iframe
              src={SampleDoc}
              frameBorder="0"
              height="100%"
              width="100%"
            ></iframe>
          </div>
        </Modal>
        <Modal
          title={"Uploaded Document"}
          className="custom-modal"
          open={uploadedDocModal}
          width={1000}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="document-view">
            <iframe
              src={SampleDoc2}
              frameBorder="0"
              height="100%"
              width="100%"
            ></iframe>
          </div>
        </Modal>
      </div>
    </div>
  );
}
