import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Table } from "antd";
import dayjs from "dayjs";
import {
  getOptions,
  fetchApi,
  getQuery,
  timeDiff,
  sendNotify,
} from "../../helper";
import { Link } from "react-router-dom";
import { getFeeCategory } from "../../store/feeSchedule/feeScheduleSlice";

import FormZ from "../../components/FormZ";
import TableSortArrows from "../../components/tableSortArrows";

export default function FeeSchedule() {
  let queryId = getQuery();
  const [editMode, setEditMode] = useState(false);
  const [clientArray, setClientArray] = useState([]);
  const feeCategory = useSelector((state) => state.feeCategory);
  const [shares, setShares] = useState([]);
  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });
  const searchFormRef = useRef();
  const [tableData, setTableData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    let params = { ...queryParams };
    params.id = queryId?.id;
    dispatch(getFeeCategory(params));
  }, []);

  useEffect(() => {
    getClient();
  }, [feeCategory]);

  const upDateonClick = async (value) => {
    setEditMode(!editMode);
    if (value == "Update") {
      if (searchFormRef.current.validForm()) {
        let options = searchFormRef.current.getPayload();

        let obj = {
          clientId: options.clientCode,
          name: options.feeScheduleName,
          requestType: options.paymentCategory,
          fee: shares,
        };

        let payload = {
          method: "PUT",
          url: `/feeCategory/update/${queryId?.id}`,
          data: obj,
        };
        let url = `/app/fee-schedule`;

        fetchApi(payload)
          .then((res) => {
            sendNotify("success", res?.message ? res?.message : "success");
            setTimeout(() => {
              // window.location.href = url;
            }, 500);
          })
          .catch((error) => {
            sendNotify("error", error?.message);
          });
      } else {
        sendNotify("error", "Please enter at least one name");
      }
    } else {
    }
  };

  const getClient = async () => {
    let payload = {
      method: "GET",
      url: `/client?_start=0&_limit=1000&types=endUser%2CIntermediary`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res?.data?.rows;
    });
    setClientArray(data);
    const tableData = feeCategory?.data?.rows?.[0];
    if (tableData) {
      setShares(tableData?.fee);
      //    setShares([
      //             {
      //                 task: "Incorporation of a company",
      //                 type: "Pre-incrop",
      //                 code: "0001",
      //                 standardUpTo: 0.0,
      //                 standardAbove: 0.0,
      //                 discountedUpTo: 0.0,
      //                 discountedAbove: 0.0,
      //             },
      // {
      //     task: "Certificate of Incumbency",
      //     type: "Pre-incrop",
      //     code: "0002",
      //     standardUpTo: 0.0,
      //     standardAbove: 0.0,
      //     discountedUpTo: 0.0,
      //     discountedAbove: 0.0,
      // },
      // {
      //     task: "Good Standing",
      //     type: "Pre-incrop",
      //     code: "0003",
      //     standardUpTo: 0.0,
      //     standardAbove: 0.0,
      //     discountedUpTo: 0.0,
      //     discountedAbove: 0.0,
      // },
      // {
      //     task: "Change of Director",
      //     type: "Pre-incrop",
      //     code: "0004",
      //     standardUpTo: 0.0,
      //     standardAbove: 0.0,
      //     discountedUpTo: 0.0,
      //     discountedAbove: 0.0,
      // },
      // {
      //     task: "Change of Shareholders",
      //     type: "Pre-incrop",
      //     code: "0005",
      //     standardUpTo: 0.0,
      //     standardAbove: 0.0,
      //     discountedUpTo: 0.0,
      //     discountedAbove: 0.0,
      // },
      // {
      //     task: "Alteration to M&A",
      //     type: "Pre-incrop",
      //     code: "0006",
      //     standardUpTo: 0.0,
      //     standardAbove: 0.0,
      //     discountedUpTo: 0.0,
      //     discountedAbove: 0.0,
      // },
      // {
      //     task: "M&A Name Change",
      //     type: "Pre-incrop",
      //     code: "0007",
      //     standardUpTo: 0.0,
      //     standardAbove: 0.0,
      //     discountedUpTo: 0.0,
      //     discountedAbove: 0.0,
      // },
      // {
      //     task: "Notarization of a documents",
      //     type: "Pre-incrop",
      //     code: "0008",
      //     standardUpTo: 0.0,
      //     standardAbove: 0.0,
      //     discountedUpTo: 0.0,
      //     discountedAbove: 0.0,
      // },
      // {
      //     task: "Apostille of a document",
      //     type: "Pre-incrop",
      //     code: "0009",
      //     standardUpTo: 0.0,
      //     standardAbove: 0.0,
      //     discountedUpTo: 0.0,
      //     discountedAbove: 0.0,
      // },
      // {
      //     task: "Drafting Resolution",
      //     type: "Pre-incrop",
      //     code: "0010",
      //     standardUpTo: 0.0,
      //     standardAbove: 0.0,
      //     discountedUpTo: 0.0,
      //     discountedAbove: 0.0,
      // },
      // {
      //     task: "Request to provide Corporate documents certified",
      //     type: "Pre-incrop",
      //     code: "0011",
      //     standardUpTo: 0.0,
      //     standardAbove: 0.0,
      //     discountedUpTo: 0.0,
      //     discountedAbove: 0.0,
      // },
      // {
      //     task: "Resignation of a company",
      //     type: "Pre-incrop",
      //     code: "0012",
      //     standardUpTo: 0.0,
      //     standardAbove: 0.0,
      //     discountedUpTo: 0.0,
      //     discountedAbove: 0.0,
      // }
      // ])
      setTableData(tableData);
    }
  };
  let list = clientArray?.map((d) => {
    let obj = {
      value: d?._id,
      label: d?.code,
    };
    return obj;
  });
  let searchFormSchema = [
    {
      name: "endUserClient",
      value: "",
      type: "select",
      label: "End User Client/Introducer Client",
      placeholder: "Select end user client",
      showSearch: true,
      options: getOptions(list),
      disabled: true,
    },
    {
      name: "feeScheduleName",
      value: "",
      type: "text",
      label: "Fee Schedule Name",
      placeholder: "Select schedule name",
      disabled: true,
    },
    {
      name: "paymentCategory",
      value: "",
      type: "select",
      label: "Payment Category",
      placeholder: "Select payment category",
      disabled: true,
      options: [
        {
          value: "Pre-Paid",
          label: "Pre-paid",
        },
        {
          value: "Post-Paid",
          label: "Post-paid",
        },
      ],
    },
  ];

  const getShareChange = (index, key, value) => {
    let arr = [...shares];
    let obj = { ...arr[index] };
    obj[key] = value;
    arr[index] = obj;
    setShares(arr);
  };

  const columns = [
    {
      title: "Request Type",
      dataIndex: "reqType",
      sorter: (a, b) => a.reqType - b.reqType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Task",
      dataIndex: "task",
      sorter: (a, b) => a.task - b.task,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Service Code",
      dataIndex: "serviceCode",
      sorter: (a, b) => a.serviceCode - b.serviceCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    tableData?.standard?.checked
      ? {
          title: "Standard Fee",

          children: [
            {
              title: <div className="text-center">Up to 50,000 Shares</div>,
              dataIndex: "standardUpTo",
              key: "standardUpTo",
              sorter: (a, b) => a.standardUpTo - b.standardUpTo,
              sortIcon: ({ sortOrder }) => (
                <TableSortArrows sorted={sortOrder} />
              ),
              render: (_, { id, standardUpTo }) =>
                editMode ? (
                  <input
                    type="number"
                    className="w-75"
                    value={shares[id].standardUpTo}
                    onChange={(e) =>
                      getShareChange(id, "standardUpTo", e.target.value)
                    }
                  />
                ) : (
                  `$ ${standardUpTo}`
                ),
            },
            {
              title: <div className="text-center">Above 50,000 Shares</div>,
              dataIndex: "standardAbove",
              key: "standardAbove",
              sorter: (a, b) => a.standardAbove - b.standardAbove,
              sortIcon: ({ sortOrder }) => (
                <TableSortArrows sorted={sortOrder} />
              ),
              render: (_, { id, standardAbove }) =>
                editMode ? (
                  <input
                    type="number"
                    className="w-75"
                    value={shares[id].standardAbove}
                    onChange={(e) =>
                      getShareChange(id, "standardAbove", e.target.value)
                    }
                  />
                ) : (
                  `$ ${standardAbove}`
                ),
            },
          ],
        }
      : {},
    tableData?.discounted?.checked
      ? {
          title: "Discounted Fee",

          children: [
            {
              title: <div className="text-center">Up to 50,000 Shares</div>,
              dataIndex: "discountedUpTo",
              key: "discountedUpTo",
              sorter: (a, b) => a.discountedUpTo - b.discountedUpTo,
              sortIcon: ({ sortOrder }) => (
                <TableSortArrows sorted={sortOrder} />
              ),
              render: (_, { id, discountedUpTo }) =>
                editMode ? (
                  <input
                    type="number"
                    className="w-75"
                    value={shares[id].discountedUpTo}
                    onChange={(e) =>
                      getShareChange(id, "discountedUpTo", e.target.value)
                    }
                  />
                ) : (
                  `$ ${discountedUpTo}`
                ),
            },
            {
              title: <div className="text-center">Above 50,000 Shares</div>,
              dataIndex: "discountedAbove",
              key: "discountedAbove",
              sorter: (a, b) => a.discountedAbove - b.discountedAbove,
              sortIcon: ({ sortOrder }) => (
                <TableSortArrows sorted={sortOrder} />
              ),
              render: (_, { id, discountedAbove }) =>
                editMode ? (
                  <input
                    type="number"
                    className="w-75"
                    value={shares[id].discountedAbove}
                    onChange={(e) =>
                      getShareChange(id, "discountedAbove", e.target.value)
                    }
                  />
                ) : (
                  `$ ${discountedAbove}`
                ),
            },
          ],
        }
      : {},
    tableData?.highRisk?.checked
      ? {
          title: "High Risk Fee",

          children: [
            {
              title: <div className="text-center">Up to 50,000 Shares</div>,
              dataIndex: "highRiskUpTo",
              key: "highRiskUpTo",
              sorter: (a, b) => a.highRiskUpTo - b.highRiskUpTo,
              sortIcon: ({ sortOrder }) => (
                <TableSortArrows sorted={sortOrder} />
              ),
              render: (_, { id, highRiskUpTo }) =>
                editMode ? (
                  <input
                    type="number"
                    className="w-75"
                    value={shares[id].highRiskUpTo}
                    onChange={(e) =>
                      getShareChange(id, "highRiskUpTo", e.target.value)
                    }
                  />
                ) : (
                  `$ ${highRiskUpTo}`
                ),
            },
            {
              title: <div className="text-center">Above 50,000 Shares</div>,
              dataIndex: "highRiskAbove",
              key: "highRiskAbove",
              sorter: (a, b) => a.highRiskAbove - b.highRiskAbove,
              sortIcon: ({ sortOrder }) => (
                <TableSortArrows sorted={sortOrder} />
              ),
              render: (_, { id, highRiskAbove }) =>
                editMode ? (
                  <input
                    type="number"
                    className="w-75"
                    value={shares[id].highRiskAbove}
                    onChange={(e) =>
                      getShareChange(id, "highRiskAbove", e.target.value)
                    }
                  />
                ) : (
                  `$ ${highRiskAbove}`
                ),
            },
          ],
        }
      : {},
    tableData?.standardPlus?.checked
      ? {
          title: "standardPlus Fee",

          children: [
            {
              title: <div className="text-center">Up to 50,000 Shares</div>,
              dataIndex: "standardPlusUpTo",
              key: "standardPlusUpTo",
              sorter: (a, b) => a.standardPlusUpTo - b.standardPlusUpTo,
              sortIcon: ({ sortOrder }) => (
                <TableSortArrows sorted={sortOrder} />
              ),
              render: (_, { id, standardPlusUpTo }) =>
                editMode ? (
                  <input
                    type="number"
                    className="w-75"
                    value={shares[id].standardPlusUpTo}
                    onChange={(e) =>
                      getShareChange(id, "standardPlusUpTo", e.target.value)
                    }
                  />
                ) : (
                  `$ ${standardPlusUpTo}`
                ),
            },
            {
              title: <div className="text-center">Above 50,000 Shares</div>,
              dataIndex: "standardPlusAbove",
              key: "standardPlusAbove",
              sorter: (a, b) => a.standardPlusAbove - b.standardPlusAbove,
              sortIcon: ({ sortOrder }) => (
                <TableSortArrows sorted={sortOrder} />
              ),
              render: (_, { id, standardPlusAbove }) =>
                editMode ? (
                  <input
                    type="number"
                    className="w-75"
                    value={shares[id].standardPlusAbove}
                    onChange={(e) =>
                      getShareChange(id, "standardPlusAbove", e.target.value)
                    }
                  />
                ) : (
                  `$ ${standardPlusAbove}`
                ),
            },
          ],
        }
      : {},
  ];

  let data = shares?.map((share, index) => {
    let type = share.sPlus?.[0]?.value ? share.sPlus?.[0]?.value : false;
    return {
      key: (index + 1).toString(),
      id: index,
      reqType: share.type,
      task: share.task,
      serviceCode: share.code,
      isSplus: type,
      ...share,
    };
  });

  return (
    <div>
      <div className="page-header">
        <h1>Fee Schedule</h1>
        <Link to={"/app/fee-schedule"} className="primary-btn">
          <i className="fal fa-backward"></i> Back to list
        </Link>
      </div>

      <div className="page-content">
        <FormZ
          formClass="row custom-form mb-2 mb-md-5 align-items-end gy-3"
          childClass="col-md-2 mb-0"
          labelClass="mb-1"
          inputClass="w-100"
          formSchema={searchFormSchema}
          formData={{
            endUserClient: tableData && tableData.clientId,
            feeScheduleName: tableData && tableData.name,
            paymentCategory: tableData && tableData.requestType,
          }}
          ref={searchFormRef}
        />
        <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4">
          <h1 className="primary-title mb-0">
            Introducer Client/End User Client
          </h1>
          {/* <div className="d-flex gap-4">
                    <h6>Fee Category</h6>
                    <h6>Post Paid/Pre Paid</h6>
                </div>
                 */}
        </div>

        <div className="table-box custom-form">
          <Table
            className="custom-table"
            columns={columns}
            dataSource={data}
            scroll={{
              y: 240,
              x: 2000,
            }}
            pagination={false}
          />
          <div className="d-flex justify-content-between align-items-center mt-md-4">
            <p>
              Last Changed By:{" "}
              <span>{tableData && tableData?.requestedBy?.name}</span>
            </p>
            <p>
              Last Changed Date:{" "}
              <span>
                {timeDiff(
                  tableData && tableData.updatedAt,
                  "hh:mm a - DD/MM/YYYY"
                )}
              </span>
            </p>
            <p>
              Initial Onboarding Date:{" "}
              <span>
                {timeDiff(
                  tableData && tableData.createdAt,
                  "hh:mm a - DD/MM/YYYY"
                )}
              </span>
            </p>
            <button
              className={editMode ? "primary-btn" : "outline-btn"}
              onClick={() => upDateonClick(editMode ? "Update" : "Modify")} //setEditMode(!editMode)
            >
              {editMode ? "Update" : "Modify"}
            </button>
            {/* 
                    <button
                        className={editMode ? "primary-btn" : "outline-btn"}
                        onClick={() => setEditMode(!editMode)}
                    >
                        {editMode ? "Update" : "Modify"}
                    </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
