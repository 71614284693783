import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Select, DatePicker } from "antd";
import dayjs from "dayjs";
import filter from "../helper/translations/filter.json";
import { languagesArray } from "../store/language/languageSlice";

export default function TableFilterZ({ tableCol, onChange }) {
  const { language } = useSelector((state) => state.language);
  const [filters, setFilters] = useState([{}]);
  const [filterCount, setFilterCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selctedLanguage, setSelctedLanguage] = useState("");

  useEffect(() => {
    let index = languagesArray.find((tc) => tc.value == language);
    setSelctedLanguage(index.value);
  }, [language]);
  let t = filter.translations[selctedLanguage] || filter.translations.en;

  const updateFilterCount = () => {
    let arr = [];
    filters.map((f, i) => {
      if (f?.options) {
        arr[i] = 1;
      } else {
        arr[i] = 0;
      }
    });
    let count = arr.reduce((c, a) => c + a, 0);
    setFilterCount(count);
  };

  const addFilter = () => {
    let arr = [...filters];
    arr.push({});
    setFilters(arr);
  };

  const deleteFilter = (index) => {
    let arr = [...filters];
    if (arr.length == 1) {
      arr = [{}];
    } else {
      arr.splice(index, 1);
    }
    setFilters(arr);
  };

  const clearAllFilter = () => {
    setFilters([{}]);
  };

  const updateFilter = (index, key, value) => {
    let arr = [...filters];

    if (arr[index][key] !== undefined) {
      arr[index][key] = value;
    } else {
      arr[index] = {
        filterBy: "",
        is: true,
        options: null,
      };
      arr[index][key] = value;
    }

    setFilters(arr);
  };

  const applyFilters = () => {
    updateFilterCount();
    if (onChange) {
      onChange(filters);
    }
  };

  return (
    <div className="table-filter-z">
      <button className="table-filter-z-button" onClick={() => setIsOpen(true)}>
        <i className="fal fa-sliders-h"></i>
        {filterCount < 1 ? t.Filter : `${filterCount} ${t.FiltersApplied}`}
      </button>
      <div className={`table-filter-z-popup ${isOpen ? "open" : ""}`}>
        <h2>{t.Filters}</h2>
        <button className="close-filter-btn" onClick={() => setIsOpen(false)}>
          <i className="fal fa-times"></i>
        </button>
        <div className="table-filter-z-content">
          {filters.map((filter, i) => {
            return (
              <FilterRow
                key={i}
                index={i}
                tableCol={tableCol}
                data={filter}
                deleteFilter={deleteFilter}
                updateFilter={updateFilter}
              />
            );
          })}
        </div>
        <div className="d-flex justify-content-between mt-4 pt-2">
          <div className="d-flex gap-3">
            <button className="add-filter-btn" onClick={addFilter}>
              <i className="fal fa-plus"></i> {t.AddFilter}
            </button>
            <button className="clear-filter-btn" onClick={clearAllFilter}>
              {t.ClearAll}
            </button>
          </div>
          <button className="apply-filter-btn" onClick={applyFilters}>
            {t.Apply}
          </button>
        </div>
      </div>
    </div>
  );
}

function FilterRow({ index, tableCol, data, deleteFilter, updateFilter }) {
  const [column, setColumn] = useState([]);

  useEffect(() => {
    let arr = [];
    if (tableCol?.length > 0) {
      tableCol
        .filter((f) => f.filterType !== undefined)
        .map((tc) => {
          let obj = {};
          obj["label"] = tc["title"];
          obj["value"] = tc["dataIndex"];
          arr.push(obj);
        });
    }
    setColumn(arr);
  }, [tableCol]);

  const getOptionField = (key) => {
    let find = tableCol.find((tc) => tc.dataIndex == key);

    switch (find?.filterType) {
      case "select":
        return (
          <Select
            showSearch={true}
            allowClear={true}
            value={data?.options}
            className={`custom-select w-200`}
            placeholder={"Select option"}
            onChange={(value) => updateFilter(index, "options", value)}
            options={find?.filterOptions}
          />
        );
      case "date":
        return (
          <DatePicker
            className="custom-datepicker w-200"
            format="DD-MM-YYYY"
            placeholder={"Select Date"}
            value={data?.options ? dayjs(data?.options, "DD-MM-YYYY") : ""}
            onChange={(date, dateString) =>
              updateFilter(index, "options", dateString)
            }
          />
        );
      case "text":
        return (
          <div className="custom-form w-200">
            <input
              type="text"
              className="w-100"
              placeholder="Search"
              value={data?.options ?? ""}
              onChange={(e) => updateFilter(index, "options", e.target.value)}
            />
          </div>
        );
    }
  };

  const removeFilter = () => {
    deleteFilter(index);
  };

  return (
    <div className="d-flex gap-3 align-items-center">
      <Select
        className={"custom-select w-200"}
        placeholder="Filter by"
        value={data?.filterBy}
        onChange={(value) => updateFilter(index, "filterBy", value)}
        options={column}
      />
      {data?.filterBy && (
        <>
          <Select
            className={"custom-select w-100px"}
            value={data?.is ?? true}
            placeholder="Select"
            onChange={(value) => updateFilter(index, "is", value)}
            options={[
              {
                value: true,
                label: "Is",
              },
              {
                value: false,
                label: "Is Not",
              },
            ]}
          />
          {getOptionField(data?.filterBy)}
          <button className="filter-delete-btn" onClick={removeFilter}>
            <i className="far fa-trash"></i>
          </button>
        </>
      )}
    </div>
  );
}
