import { useState, useEffect, useRef } from "react";
import { Table, Select, Switch, Checkbox, Modal } from "antd";

import { useSelector, useDispatch } from "react-redux";

import { getOrganizationStructure } from "../../../store/organizationStructure/organizationStructureSlice";
import { getIndividualDetails } from "../../../store/individualDetails/individualDetailsSlice";

import FormZ from "../../../components/FormZ";

import {
  sendNotify,
  getOptions,
  fetchApi,
  convertQueryParams,
  getQuery,
} from "../../../helper";

export default function IndividualForm(props) {
  const { data, isLoading } = useSelector((state) => state.country);
  const individualDetails = useSelector((state) => state.individualDetails);
  let { isLogged, userDetails } = useSelector((state) => state.auth);
  const organizationStructure = useSelector(
    (state) => state.organizationStructure
  );

  const individualFormRef = useRef([]);
  const senderFormRef = useRef(null);
  const receiverFormRef = useRef(null);
  const allocationFormRef = useRef(null);

  let query = getQuery();

  const dispatch = useDispatch();

  const [sanction, setSanction] = useState("Y");
  const [pep, setPep] = useState("Y");
  const [pepText, setPepText] = useState("");
  const [sanctionText, setSanctionText] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkedList, setCheckedList] = useState({
    shareholders: false,
    ubo: false,
    nominee: false,
  });

  const [historyShow, setHistoryShow] = useState([]);
  const [directorChecked, setDirectorChecked] = useState(false);
  const [reserveChecked, setReserveChecked] = useState(false);
  const [shareholderChecked, setShareholderChecked] = useState(false);
  const [uboChecked, setUboChecked] = useState(false);
  const [nomineeChecked, setNomineeChecked] = useState(false);
  const [openindividual, setOpenindividual] = useState(false);
  const [individualShow, setIndividualShow] = useState(false);
  const [objData, setObjData] = useState({});
  const [formData, setFormData] = useState({});
  const [individualArray, setIndividualArray] = useState([]);
  const [individualData, setIndividualData] = useState([]);

  const [selectedId, setSelectedId] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 5 });
  const [individualOptions, setIndividualOptions] = useState({});
  const [senderFormData, setSenderFormData] = useState({});
  const [receiveFormData, setReceiveFormData] = useState({});
  const [receiverOptions, setReceiverOptions] = useState({});
  const [receiverArray, setReceiverArray] = useState([]);
  const [shareForm, setShareForm] = useState({
    show: false,
    type: "",
  });

  let { index, name, addNewCol, removeNewCol, entityData, id } = props;

  const handleChange = (value) => {
    setSanction(value);
  };

  useEffect(() => {
    if (id || query.id) {
      let params = { enId: id ? id : query.id };
      dispatch(getIndividualDetails(params));
      dispatch(getOrganizationStructure(params));
      handleRefresh();
    }
  }, [id]);

  useEffect(() => {
    if (id || query.id) {
      neworganizationStructure();
    }
  }, [individualDetails, organizationStructure]);
  const handleRefresh = async () => {
    const fetchData = async (url) => {
      const payload = { method: "GET", url };
      return fetchApi(payload).then((res) => res?.data?.rows);
    };
    const params = convertQueryParams({
      isAll: true,
      masterType: "individual",
      options: true,
    });
    const individual = await fetchData(`/client?${params}`);
    handleindividualChange({});
    setIndividualArray(individual);
  };
  const neworganizationStructure = async () => {
    if (organizationStructure?.data?.rows?.length) {
    } else {
      let obj = {
        master: true,
        enId: id ? id : query.id,
      };
      let payload = {
        method: "POST",
        url: `/organizationStructure`,
        data: obj,
      };

      fetchApi(payload)
        .then((res) => {
          if (res.status == 201 && res?.data?.length) {
            dispatch(getOrganizationStructure(queryParams));
          }
        })
        .catch((error) => {});
    }
  };

  const handleChangePep = (value) => {
    setPep(value);
  };

  const [individualFormSchema, setIndividualFormSchema] = useState([
    {
      name: "code",
      value: "",
      type: "select",
      label: "Masterfile Code",
      placeholder: "Enter masterfile code",
      //  options: [],
      loading: isLoading,
      showSearch: true,
    },
    {
      name: "title",
      value: "",
      type: "select",
      label: "Salutation",
      placeholder: "Enter salutation",
      //disabled: true,
      options: [
        {
          value: "mr",
          label: "Mr",
        },
        {
          value: "mrs",
          label: "Mrs",
        },
        {
          value: "miss",
          label: "Miss",
        },
        {
          value: "ms",
          label: "Ms",
        },
        {
          value: "dr",
          label: "Dr",
        },
        {
          value: "rev",
          label: "Rev",
        },
      ],
    },
    {
      name: "firstName",
      value: "",
      type: "select",
      label: "First Name",
      showSearch: true,
      // disabled: true,
      placeholder: "Enter first name",
    },
    {
      name: "middleName",
      value: "",
      type: "select",
      label: "Middle Name",
      showSearch: true,
      placeholder: "Enter Middle name",
      // disabled: true,
    },
    {
      name: "lastName",
      showSearch: true,
      value: "",
      type: "select",
      label: "Last Name",
      placeholder: "Enter last name",
      //disabled: true,
    },
    {
      name: "DOB",
      value: "", //
      type: "date",
      label: "Date of Birth",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
      //   disabled: true,
    },
    {
      name: "COB",
      value: "",
      type: "select",
      label: "Country of Birth",
      placeholder: "Select country of birth",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
      disabled: true,
    },
    {
      name: "nationality",
      value: "",
      type: "select",
      label: "Nationality",
      placeholder: "Select nationality",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
      disabled: true,
    },
    {
      name: "taxInfo",
      value: "",
      type: "text",
      label: "Tax Info Number",
      placeholder: "Enter tax info number",
      disabled: true,
    },
    {
      name: "passportNo",
      value: "",
      type: "text",
      label: "Passport Number",
      placeholder: "Enter passport number",
      disabled: true,
    },
    {
      name: "passportExp",
      value: "", //dayjs("12-12-2028", "DD/MM/YYYY")
      type: "date",
      label: "Expiry Date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
      disabled: true,
    },
    {
      name: "riskRating",
      value: "",
      type: "select",
      label: "Risk Level",
      placeholder: "Select risk level",
      disabled: true,
      options: [
        {
          value: "low",
          label: "Low",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "high",
          label: "High",
        },
      ],
    },
    {
      // name: "pep",
      // value: "Y",
      // type: "select",
      // label: "PEP",
      // placeholder: "Enter pep",
      // options: [
      //   {
      //     value: "Y",
      //     label: "Yes",
      //   },
      //   {
      //     value: "N",
      //     label: "No",
      //   },
      // ],
      customClass: pep == "Y" ? "col-md-3" : "col-lg-1",
      label: "PEP",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${pep == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={pep}
            disabled={true}
            onChange={handleChangePep}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {pep == "Y" && (
            <input
              type="text"
              disabled={true}
              value={pepText}
              className="w-100"
              placeholder="Enter pep"
            />
          )}
        </div>
      ),
    },
    {
      customClass: sanction == "Y" ? "col-md-3" : "col-lg-1",
      label: "Sanction",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${sanction == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={sanction}
            disabled={true}
            onChange={handleChange}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {sanction == "Y" && (
            <input
              type="text"
              className="w-100"
              disabled={true}
              value={sanctionText}
              placeholder="Enter sanction"
            />
          )}
        </div>
      ),
    },
    {
      name: "contactDetails",
      value: "",
      type: "number",
      label: "Contact Details",
      placeholder: "Enter contact details",
      disabled: true,
    },
    {
      name: "email",
      value: "",
      type: "email",
      label: "Email Address",
      placeholder: "Enter email address",
      disabled: true,
    },
  ]);

  const onChangeHandler = (setIdentifierState, event) => {
    setIdentifierState(event.target.value);
  };
  const individualDeleteOnClick = async (id, index) => {
    setSelectedIndex(individualDetails.data.totalCount - index);
    setSelectedId(id);

    let payload = {};

    payload.method = "DELETE";
    payload.url = `/individualDetails?id=${id}`;

    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message);
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };
  const individualcolumns = [
    {
      title: "Individual Code",
      dataIndex: "individualCode",
      sorter: (a, b) => a.individualName - b.individualName,
    },
    {
      title: "Individual Name",
      dataIndex: "individualName",
      sorter: (a, b) => a.noShares - b.noShares,
    },
    {
      title: "Select Type",
      dataIndex: "shareType",
      sorter: (a, b) => a.shareType - b.shareType,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      format: "YYYY-MM-DD",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
    },
  ];

  //userDetails.role == "admin" &&
  individualcolumns.push({
    title: "Delete / Update",
    render: (_, { _id }, index) => (
      <div className="d-flex gap-2 align-items-center justify-content-end">
        <button
          className="action-btn delete-btn"
          onClick={() => individualDeleteOnClick(_id, index)}
        >
          <i className="far fa-trash-alt"></i>
        </button>
        <button
          className="action-btn"
          onClick={() => individualEditModalOnClick(_id, index)}
        >
          <i className="far fa-pencil"></i>
        </button>
      </div>
    ),
  });

  let directorFormSchema = [
    {
      name: "dateAppointment",
      value: "",
      type: "date",
      label: "Date of Appointment",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
    {
      name: "dateResignation",
      value: "",
      type: "date",
      label: "Date of Resignation",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
  ];

  let shareholderFormSchema = [
    {
      name: "sharesHeld",
      value: "",
      type: "number",
      label: "No. of Shares Held",
      placeholder: "Enter no. of shares held",
    },
    {
      name: "shareEntry",
      value: null,
      type: "select",
      options: [
        {
          value: "Share Transfer",
          label: "Share Transfer",
        },
        {
          value: "New Allocation",
          label: "New Allocation",
        },
      ],
      label: "Add share entry",
      placeholder: "Select",
    },
  ];

  let uboFormSchema = [
    {
      name: "totalSharesHeld",
      value: "",
      type: "number",
      label: "Total Shares Held",
      placeholder: "Enter total shares held",
    },
    {
      name: "ownershipPercentage",
      value: "",
      type: "text",
      label: "Ownership Percentage",
      placeholder: "Enter ownership percentage",
      disabled: true,
    },
  ];

  let nomineeShareholder = [
    {
      name: "nomineeSharesHeld",
      value: "",
      type: "number",
      label: "No. of Shares Held",
      placeholder: "Enter no. of shares held",
    },
    {
      name: "nomineeAgreementDate",
      value: "",
      type: "date",
      label: "Nominee Agreement Date",
      placeholder: "Enter nominee agreement Date",
    },
    {
      name: "nomineeShareEntry",
      value: null,
      type: "select",
      options: [
        {
          value: "Share Transfer",
          label: "Share Transfer",
        },
        {
          value: "New Allocation",
          label: "New Allocation",
        },
      ],
      label: "Add share entry",
      placeholder: "Select",
    },
  ];

  function removeArrItem(values, arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let index = 0; index < values.length; index++) {
        if (arr[i]?.name === values[index]?.name) {
          arr.splice(i, 1);
        }
      }
    }
  }
  const senderOnChange = async (value, fields) => {
    let receiveData = { ...receiveFormData };
    receiveData.createdAt = new Date();

    if (fields == "numberOfShares") {
      let noShares = value.numberOfShares ?? 0;
      if (noShares <= formData.totalSharesHeld) {
        receiveData.numberOfShares = value.numberOfShares;
        if (noShares) {
          value.totalShares =
            formData.totalSharesHeld - noShares == 0
              ? 0
              : formData.totalSharesHeld - noShares;

          //  percentage;

          let percentage =
            (Number(noShares) / Number(formData.totalSharesHeld)) * 100;
          value.percentage = `${Math.round(percentage * 100) / 100} %`;
        }
      } else {
        sendNotify(
          "error",
          "Requested shares exceed the Individual's allocated total."
        );
      }
    }
    if (fields == "shareCapital") {
      receiveData.shareCapital = value.shareCapital;
    }
    if (fields == "totalShares") {
      receiveData.totalShares = value.totalShares;
    }
    if (fields == "effectiveDate") {
      receiveData.effectiveDate = value.effectiveDate;
    }
    if (fields == "to") {
      receiveData.to = value.to;
    }
    if (fields == "from") {
      receiveData.from = value.from;
    }

    setSenderFormData(value);
    setReceiveFormData(receiveData);
  };
  const receiverOnChange = async (value, fields) => {
    if (fields == "code") {
      let find = receiverArray.find((e) => e._id == value.code);

      let data = {
        masterId: find._id,
        name: find.name ?? find.firstName,
        masterType: find.masterType,
      };
      setReceiveFormData((prev) => ({
        ...prev,
        ...data,
      }));
    }
    if (fields == "name") {
      let find = receiverArray.find((e) => e.firstName == value.name);
      let data = {
        masterId: find?._id,
        name: find.name ?? find.firstName,
        masterType: find.masterType,
      };
      setReceiveFormData((prev) => ({
        ...prev,
        ...data,
      }));
    }
    setReceiveFormData(value);
  };
  console.log(receiveFormData, "asadfghjkl");
  const addNewChange = (values, checkStatus, name) => {
    setUboChecked(checkStatus);
    updateChecked("ubo", checkStatus);

    setNomineeChecked(false);

    let arr = [...individualFormSchema];
    removeArrItem(nomineeShareholder, arr);
    if (checkStatus) {
      arr.push(...values);
    } else {
      removeArrItem(values, arr);
    }
    setIndividualFormSchema(arr);
  };

  const addNewRow = (values, checkStatus, name) => {
    updateChecked(name, checkStatus);
    let arr = [...individualFormSchema];

    if (checkStatus) {
      removeArrItem(values, arr);
    }

    if (name == "directors") {
      if (checkStatus) {
        arr.push(...values);
        setDirectorChecked(true);
        setReserveChecked(false);
      } else {
        removeArrItem(values, arr);
        setDirectorChecked(false);
      }
    }
    if (name == "reserve") {
      if (checkStatus) {
        arr.push(...values);
        setReserveChecked(true);
        setDirectorChecked(false);
      } else {
        removeArrItem(values, arr);
        setReserveChecked(false);
      }
    }
    setIndividualFormSchema(arr);
  };

  const addNewCustom = (values, name, checkStatus) => {
    let arr = [...individualFormSchema];
    let individualData = { ...formData };

    updateChecked(name, checkStatus);

    if (name == "shareholders") {
      if (checkStatus) {
        arr.push(...values);
        setShareholderChecked(true);
        setNomineeChecked(false);
        removeArrItem(nomineeShareholder, arr);
      } else {
        setShareForm({
          show: false,
          type: "",
        });
        setFormData({
          ...individualData,
          shareEntry: null,
          nomineeShareEntry: null,
        });
        removeArrItem(values, arr);
        setShareholderChecked(false);
      }
    }

    if (name == "nominee") {
      if (checkStatus) {
        arr.push(...values);
        setNomineeChecked(true);
        setShareholderChecked(false);
        setUboChecked(false);
        removeArrItem(shareholderFormSchema, arr);
        removeArrItem(uboFormSchema, arr);
      } else {
        setShareForm({
          show: false,
          type: "",
        });
        setFormData({
          ...individualData,
          shareEntry: null,
          nomineeShareEntry: null,
        });
        removeArrItem(values, arr);
        setNomineeChecked(false);
      }
    }
    setIndividualFormSchema(arr);
  };

  function updateChecked(key, checked) {
    let obj = { ...checkedList };
    if (key == "nominee" && checked) {
      obj["shareholders"] = false;
    }
    if (key == "shareholders" && checked) {
      obj["nominee"] = false;
    }
    obj[key] = checked;
    setCheckedList(obj);
  }

  const toggleHistory = (index) => {
    let arr = [...historyShow];
    let item = arr.findIndex((hs) => hs === index);
    if (item !== -1) {
      arr.splice(item, 1);
    } else {
      arr.push(index);
    }
    setHistoryShow(arr);
  };

  const senderForm = [
    {
      name: "code",
      value: "",
      type: "text",
      label: "Master file Code",
      placeholder: "Enter master file code",
      disabled: true,
    },
    {
      name: "name",
      value: "",
      type: "text",
      label: "Master file Name",
      placeholder: "Enter master file Name",
      disabled: true,
    },
    {
      name: "shareCapital",
      value: "",
      type: "text",
      label: "Authorised Share Capital",
      placeholder: "Enter authorised share Capital",
    },
    {
      name: "numberOfShares",
      value: "",
      type: "number",
      label: "Number of shares transferred",
      placeholder: "Enter number of shares transferred",
    },
    {
      name: "from",
      value: "",
      type: "date",
      label: "From",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
    {
      name: "to",
      value: "",
      type: "date",
      label: "To",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
    {
      name: "shareType",
      type: "select",
      options: [
        {
          value: "Share type 1",
          label: "Share type 1",
        },
        {
          value: "Share type 2",
          label: "Share type 2",
        },
      ],
      label: "Share type",
      placeholder: "Select",
    },
    {
      name: "parValue",
      value: "",
      type: "number",
      label: "Par Value",
      placeholder: "Enter par value",
    },
    {
      name: "effectiveDate",
      value: "",
      type: "date",
      label: "Effective date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
    {
      name: "totalShares",
      value: "",
      type: "number",
      label: "Total Shares held",
      placeholder: "Enter total shares held",
    },
    {
      name: "percentage",
      value: "",
      type: "text",
      label: "Ownership percentage",
      placeholder: "Enter ownership percentage",
    },
    {
      name: "certificateNumber",
      value: "",
      type: "text",
      label: "Certificate Number",
      placeholder: "Enter certificate number",
    },
    {
      name: "createdAt",
      value: "",
      type: "date",
      label: "Date of entry",
      placeholder: "Enter Date (YYYY-DD-MM)",
      format: "YYYY-DD-MM",
      disabled: true,
    },
  ];

  const receiverForm = [
    {
      name: "code",
      value: "",
      type: "select",
      label: "Master file Code",
      placeholder: "Enter master file code",
    },
    {
      name: "name",
      value: "",
      type: "select",
      label: "Master file Name",
      placeholder: "Enter master file Name",
    },
    {
      name: "shareCapital",
      value: "",
      type: "text",
      label: "Authorised Share Capital",
      placeholder: "Enter authorised share Capital",
    },
    {
      name: "numberOfShares",
      value: "",
      type: "number",
      label: "Number of shares received",
      placeholder: "Enter Number of shares received",
    },
    {
      name: "from",
      value: "",
      type: "date",
      label: "From",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
    {
      name: "to",
      value: "",
      type: "date",
      label: "To",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
    {
      name: "effectiveDate",
      value: "",
      type: "date",
      label: "Effective date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
    {
      name: "totalShares",
      value: "",
      type: "number",
      label: "Total Shares held",
      placeholder: "Enter total shares held",
    },
    {
      name: "percentage",
      value: "",
      type: "text",
      label: "Ownership percentage",
      placeholder: "Enter ownership percentage",
    },
    {
      name: "certificateNumber",
      value: "",
      type: "text",
      label: "Certificate Number",
      placeholder: "Enter certificate number",
    },
    {
      name: "createdAt",
      value: "",
      type: "date",
      label: "Date of entry",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
  ];

  const allocationForm = [
    {
      name: "code",
      value: "",
      type: "text",
      label: "Master file Code",
      placeholder: "Enter master file code",
    },
    {
      name: "name",
      value: "",
      type: "text",
      label: "Master file Name",
      placeholder: "Enter master file Name",
    },
    {
      name: "shareCapital",
      value: "",
      type: "text",
      label: "Authorised Share Capital",
      placeholder: "Enter authorised share Capital",
    },
    {
      name: "numberOfShares",
      value: "",
      type: "number",
      label: "Number of shares allocated",
      placeholder: "Enter number of shares allocated",
    },
    {
      name: "from",
      value: "",
      type: "date",
      label: "From",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
    {
      name: "to",
      value: "",
      type: "date",
      label: "To",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
    {
      name: "shareType",
      type: "select",
      options: [
        {
          value: "Share type 1",
          label: "Share type 1",
        },
        {
          value: "Share type 2",
          label: "Share type 2",
        },
      ],
      label: "Share type",
      placeholder: "Select",
    },
    {
      name: "parValue",
      value: "",
      type: "number",
      label: "Par Value",
      placeholder: "Enter par value",
    },
    {
      name: "effectiveDate",
      value: "",
      type: "date",
      label: "Effective date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
    {
      name: "totalShares",
      value: "",
      type: "number",
      label: "Total Shares held",
      placeholder: "Enter total shares held",
    },
    {
      name: "percentage",
      value: "",
      type: "text",
      label: "Ownership percentage",
      placeholder: "Enter ownership percentage",
    },
    {
      name: "certificateNumber",
      value: "",
      type: "text",
      label: "Certificate Number",
      placeholder: "Enter certificate number",
    },
    {
      name: "createdAt",
      value: "",
      type: "date",
      label: "Date of entry",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
  ];
  const findOneIndividual = async (value) => {
    setLoading(true);
    let ctr = {};
    if (value.code) {
      if (value.code.startsWith("INV")) {
        ctr.code = value.code;
      } else {
        ctr.id = value.code;
      }
    } else {
      ctr = value;
    }

    let params = convertQueryParams(ctr);
    let payload = {
      method: "GET",
      url: `/individual?${params}`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res?.data?.rows?.[0];
    });
    if (data._id) {
      setFormData((prev) => ({
        ...prev,
        ...data,
      }));
    }
  };
  const getReceiverList = async () => {
    const fetchData = async (url) => {
      const payload = { method: "GET", url };
      return fetchApi(payload).then((res) => res.data.rows);
    };
    const params = convertQueryParams({
      isAll: true,
      alltype: ["individual", "cop-entity"],
      options: true,
    });
    const receiverList = await fetchData(`/client?${params}`);

    const paramsName = convertQueryParams({
      isAll: true,
      alltype: ["individual", "cop-entity"],
      exportAll: true,
    });
    const receiverName = await fetchData(`/client?${paramsName}`);
    setReceiverArray(receiverName);
    let namelist = receiverName
      ? receiverName?.map((d) => {
          let obj = {
            value: d?.firstName ?? d?.name,

            label: d?.name
              ? d?.name
              : ` ${
                  d?.firstName && d?.lastName
                    ? `${d?.firstName} ${
                        d?.middleName && d?.middleName !== "."
                          ? d?.middleName + " "
                          : ""
                      }${d?.lastName}`
                    : ""
                }`,
          };
          return obj;
        })
      : [];

    let data = {
      name: namelist,
      code: receiverList,
    };
    setReceiverOptions(data);
  };
  const individualOnChange = async (value, field) => {
    let obj = { ...shareForm };
    let individualData = { ...formData };

    if (field == "shareEntry" || field == "nomineeShareEntry") {
      setFormData({
        ...individualData,
        shareEntry: value[field],
        nomineeShareEntry: value[field],
      });
      obj["show"] = true;
      if (value[field] == "Share Transfer") {
        obj["type"] = "transfer";
        setShareForm(obj);
      }
      if (value[field] == "New Allocation") {
        obj["type"] = "new";
        setShareForm(obj);
      }

      setSenderFormData({
        name: ` ${
          formData.firstName && formData.lastName
            ? `${formData.firstName} ${
                formData.middleName && formData.middleName !== "."
                  ? formData.middleName + " "
                  : ""
              }${formData.lastName}`
            : ""
        }`,
        code: formData.code,
        createdAt: new Date(),
      });
      getReceiverList();
    }
    if (
      !["title", "nationality", "code", "middleName", "lastName"].includes(
        field
      )
    ) {
      let data = { ...objData };
      if (
        data.totalSharesHeld !== value.totalSharesHeld ||
        !data.totalSharesHeld
      ) {
        let noShares = value.totalSharesHeld ?? 0;
        if (noShares <= entityData?.authorisedShares) {
          let percentage =
            (Number(value.totalSharesHeld ? value.totalSharesHeld : 0) /
              Number(entityData?.authorisedShares)) *
            100;
          value.ownershipPercentage = `${Math.round(percentage * 100) / 100} %`;
          value._id = formData?._id ?? "";
          value.totalSharesHeld = value.totalSharesHeld;
          setFormData((prev) => ({
            ...prev,
            ...value,
          }));
        } else {
          sendNotify(
            "error",
            "Requested shares exceed the entity's allocated total."
          );
        }
      }
    } else {
      let {
        totalSharesHeld,
        code,
        firstName,
        lastName,
        middleName,
        nationality,
        title,
      } = value;

      let param = {};
      if (field == "code" && !totalSharesHeld) {
        findOneIndividual({ code: code });

        if (code.startsWith("INV")) {
          param.code = code;
        } else {
          param.id = code;
        }
      }

      if (field == "firstName") {
        findOneIndividual({ firstName: firstName });
        param.firstName = firstName;
      }
      if (field == "lastName") {
        findOneIndividual({ lastName: lastName });
        param.lastName = lastName;
      }
      if (field == "middleName") {
        findOneIndividual({ middleName: middleName });
        param.middleName = middleName;
      }
      if (field == "nationality") {
        param.nationality = nationality;
      }
      if (field == "title") {
        findOneIndividual({ title: title });
        param.title = title;
      }

      //handleindividualChange(param);

      setObjData(value);
    }
  };

  const shareTableColumn = [
    {
      title: "Action type",
      dataIndex: "type",
      sorter: (a, b) => a.type - b.type,
    },
    {
      title: "Master file Code",
      dataIndex: "code",
      sorter: (a, b) => a.code - b.code,
    },
    {
      title: "Master file Name",
      dataIndex: "name",
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: "Authorised Share Capital",
      dataIndex: "shareCapital",
      sorter: (a, b) => a.shareCapital - b.shareCapital,
    },
    {
      title: "From",
      dataIndex: "from",
      sorter: (a, b) => a.from - b.from,
    },
    {
      title: "To",
      dataIndex: "to",
      sorter: (a, b) => a.to - b.to,
    },
    {
      title: "Par Value",
      dataIndex: "parValue",
      sorter: (a, b) => a.parValue - b.parValue,
    },
    {
      title: "Effective date",
      dataIndex: "effectiveDate",
      sorter: (a, b) => a.effectiveDate - b.effectiveDate,
      format: "YYYY-MM-DD",
    },
    {
      title: "Total Shares held",
      dataIndex: "totalShares",
      sorter: (a, b) => a.totalShares - b.totalShares,
    },
    {
      title: "Ownership percentage",
      dataIndex: "percentage",
      sorter: (a, b) => a.percentage - b.percentage,
    },
    {
      title: "Certificate Number",
      dataIndex: "certificateNumber",
      sorter: (a, b) => a.certificateNumber - b.certificateNumber,
    },
    {
      title: "Date of entry",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    {
      title: "Action",
      render: (_, { _id }, index) => (
        <button className="primary-btn">View</button>
      ),
    },
  ];

  const shareTableData = [
    {
      type: "Share Transfer",
      code: "INV000001",
      name: "Vaheeshan",
      shareCapital: "N/A",
      numberOfShares: 200,
      from: "20-10-2022",
      to: "20-10-2024",
      parValue: "N/A",
      effectiveDate: "20-10-2026",
      totalShares: 800,
      percentage: 20,
      certificateNumber: "FJC038932",
      createdAt: "30-10-2024",
    },
    {
      type: "Share Transfer",
      code: "INV000001",
      name: "Vaheeshan",
      shareCapital: "N/A",
      numberOfShares: 200,
      from: "20-10-2022",
      to: "20-10-2024",
      parValue: "N/A",
      effectiveDate: "20-10-2026",
      totalShares: 800,
      percentage: 20,
      certificateNumber: "FJC038932",
      createdAt: "30-10-2024",
    },
    {
      type: "New Allocation",
      code: "INV000001",
      name: "Vaheeshan",
      shareCapital: "N/A",
      numberOfShares: 200,
      from: "20-10-2022",
      to: "20-10-2024",
      parValue: "N/A",
      effectiveDate: "20-10-2026",
      totalShares: 800,
      percentage: 20,
      certificateNumber: "FJC038932",
      createdAt: "30-10-2024",
    },
  ];

  const handleindividualChange = async (value) => {
    let query = convertQueryParams(value);

    let payload = {
      method: "get",
      url: `/individual?${query}`,
    };
    let individualData = await fetchApi(payload).then((res) => {
      return res?.data;
    });

    if (individualData?.rows.length) {
      const firstName = individualData?.rows.map((d) => ({
        value: d.firstName,
        label: d.firstName,
      }));
      const middleName = individualData?.rows.map((d) => ({
        value: d.middleName,
        label: d.middleName,
      }));
      const lastName = individualData?.rows.map((d) => ({
        value: d.lastName,
        label: d.lastName,
      }));
      let data = {
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
      };
      setIndividualOptions(data);
    } else {
      setFormData({});
    }
    if (individualData?.rows.length) {
      setFormData((prev) => ({
        ...prev,
        ...individualData?.rows[0],
      }));
    }
  };

  const saveOnClick = () => {
    saveData(selectedId);
  };

  const saveData = async () => {
    let transaction = {};
    if (formData.shareEntry == "Share Transfer") {
      let receiverFormSchema = receiverFormRef.current.getPayload();
      let senderFormSchema = senderFormRef.current.getPayload();

      transaction = {
        receiverformFields: {
          masterId: receiverFormSchema.masterId,
          masterType: receiverFormSchema.masterType,
          shareCapital: receiverFormSchema.shareCapital,
          numberOfShares: receiverFormSchema.numberOfShares,
          from: receiverFormSchema.from,
          to: receiverFormSchema.to,
          effectiveDate: receiverFormSchema.effectiveDate,
          totalShares: receiverFormSchema.totalShares,
          percentage: receiverFormSchema.percentage,
          certificateNumber: receiverFormSchema.certificateNumber,
        },
        senderFormFields: {
          masterId: formData.invId,
          masterType: "individual",
          shareCapital: senderFormSchema.shareCapital,
          numberOfShares: senderFormSchema.numberOfShares,
          from: senderFormSchema.from,
          to: senderFormSchema.to,
          effectiveDate: senderFormSchema.effectiveDate,
          totalShares: senderFormSchema.totalShares,
          percentage: senderFormSchema.percentage,
          certificateNumber: senderFormSchema.certificateNumber,
          shareType: senderFormSchema.shareType,
          parValue: senderFormSchema.parValue,
        },
      };
    } else if (formData.shareEntry == "New Allocation") {
      let allocationFormSchema = allocationFormRef.current.getPayload();
      transaction = {
        senderFormFields: {
          masterId: "",
          masterType: "",
          shareCapital: allocationFormSchema.shareCapital,
          numberOfShares: allocationFormSchema.numberOfShares,
          from: allocationFormSchema.from,
          to: allocationFormSchema.to,
          effectiveDate: allocationFormSchema.effectiveDate,
          totalShares: allocationFormSchema.totalShares,
          percentage: allocationFormSchema.percentage,
          certificateNumber: allocationFormSchema.certificateNumber,
          shareType: allocationFormSchema.shareType,
          parValue: allocationFormSchema.parValue,
        },
      };
      // (transaction.allocationFormSchema = allocationFormSchema);
    }

    const prepareData = () => ({
      invId: formData._id,
      enId: entityData._id,
      resignation: formData.dateResignation,
      appointment: formData.dateAppointment,
      noOfShares: formData.sharesHeld || formData.nomineeSharesHeld,
      totalShares: formData.totalSharesHeld,
      ownershipPer: formData.ownershipPercentage,
      agreementDate: formData.nomineeAgreementDate,
      shareEntry: formData.shareEntry,
      checkedList: checkedList,
      transaction: transaction,
    });

    const handleCheckedList = () => {
      const organizationArray = organizationStructure.data.rows.filter(
        (a) => a.pid == null
      );
      const fullName = `${formData.title} ${formData.firstName} ${
        formData.middleName || ""
      } ${formData.lastName}`
        .replace(/\s+/g, " ")
        .trim();

      const createObj = (role, shares, percentage = null) => {
        const roleData = organizationArray.find((i) => i.role === role);
        return roleData
          ? {
              name: fullName,
              pid: roleData._id,
              color: roleData.color,
              role: "Individual",
              pType: role,
              shares,
              percentage,
            }
          : null;
      };

      const objArray = [];
      if (checkedList.directors || checkedList.reserve) {
        objArray.push({
          ...createObj("Director"),
          reserveDirector: checkedList.reserve,
        });
      }
      if (checkedList.shareholders || checkedList.nominee) {
        const sharePercentage = `${Math.round(
          (Number(formData.sharesHeld) / Number(entityData?.authorisedShares)) *
            100
        )}%`;
        objArray.push(
          createObj("Shareholder", formData.sharesHeld, sharePercentage)
        );
      }
      if (checkedList.ubo) {
        objArray.push(
          createObj(
            "UBO",
            formData.totalSharesHeld,
            formData.ownershipPercentage
          )
        );
      }

      return objArray.filter(Boolean); // Filter out any null values
    };

    const handleApiPost = async (url, data) => {
      try {
        const response = await fetchApi({ method: "POST", url, data });
        return response;
      } catch (error) {
        sendNotify("error", error?.message);
        throw error;
      }
    };
    const handleApiPut = async (url, data) => {
      try {
        const response = await fetchApi({ method: "PUT", url, data });
        return response;
      } catch (error) {
        sendNotify("error", error?.message);
        throw error;
      }
    };
    const processRows = async (objArray) => {
      for (const obj of objArray) {
        await handleApiPost(`/organizationStructure`, obj);
      }
    };

    if (formData._id) {
      const data = prepareData();
      if (Object.values(checkedList).some(Boolean)) {
        try {
          const response = selectedId
            ? await handleApiPut(
                `/individualDetails/update/${selectedId}`,
                data
              )
            : await handleApiPost(`/individualDetails`, data);
          sendNotify("success", response?.message);
          closeModalOnClick();
          if (organizationStructure?.data?.rows?.length) {
            const objArray = handleCheckedList();
            await processRows(objArray);
          } else {
            const params = { enId: id || query.id };
            dispatch(getIndividualDetails(params));
            dispatch(getOrganizationStructure(params));
          }
        } catch (error) {
          sendNotify("error", error?.message);
        }

        const params = { enId: id || query.id };
        dispatch(getIndividualDetails(params));
      } else {
        sendNotify("error", "Please enter at least one name");
      }
    } else {
      sendNotify("error", "Please enter at least one name");
    }
  };

  const individualDropDownOnClick = async (value) => {
    let params = {};
    params.isAll = true;
    params.masterType = "individual";
    params.code = value;
    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/client?${query}`,
    };
    let clientData = await fetchApi(payload).then((res) => {
      return res?.data;
    });
    setIndividualData(clientData.rows);
    let list = clientData.rows.map((d) => {
      let obj = {
        value: d?._id,
        label: d?.code,
      };
      return obj;
    });

    setIndividualArray(list);
  };
  const openIndividualModal = (value) => {
    setIndividualShow(value);
  };
  const individualModalOnClick = () => {
    setSelectedId("");
    setUboChecked(false);
    setDirectorChecked(false);
    setReserveChecked(false);
    setShareholderChecked(false);
    setNomineeChecked(false);
    setFormData({});
    setOpenindividual(true);
  };
  const closeModalOnClick = () => {
    setSelectedId("");
    setObjData({});
    setUboChecked(false);
    setDirectorChecked(false);
    setReserveChecked(false);
    setShareholderChecked(false);
    setNomineeChecked(false);
    setFormData({});
    setOpenindividual(false);
    setCheckedList({
      directors: false,
      shareholders: false,
      ubo: false,
      nominee: false,
      reserve: false,
    });
  };
  const getPagination = (current, pageSize) => {
    let params = { ...queryParams };
    params._start = (current - 1) * pageSize;
    params.enId = id ? id : query.id;
    dispatch(getIndividualDetails(params));
    setQueryParams(params);
  };

  const individualEditModalOnClick = async (id, index) => {
    // setSelectedIndex(index + 1);
    setSelectedIndex(individualDetails.data.totalCount - index);
    setSelectedId(id);

    const details = individualDetails.data.rows.find((e) => e._id === id);
    if (!details) return;

    const { shareholders, ubo, nominee, directors, reserve } =
      details.checkedList;

    const params = {
      isAll: true,
      masterType: "individual",
      code: details.individualCode,
    };
    const query = convertQueryParams(params);
    const payload = {
      method: "GET",
      url: `/client?${query}`,
    };

    try {
      const clientData = await fetchApi(payload).then(
        (res) => res?.data?.rows || []
      );

      setIndividualData(clientData);
      setIndividualArray(
        clientData.map((d) => ({ value: d?._id, label: d?.code }))
      );

      const finded = clientData.find((e) => e._id === details.individualId);
      if (finded) {
        const newItem = {
          ...finded,
          pep: finded.details.pep,
          sanction: finded.details.sanction,
          pepText: finded.details.pepText,
          sanctionText: finded.details.sanctionText,
          riskRating: finded.details.riskRating,
          address: { ...finded.details.address },
        };
        setFormData(newItem);
        setObjData(newItem);
      }

      setUboChecked(ubo);
      setDirectorChecked(directors);
      setReserveChecked(reserve);
      setShareholderChecked(shareholders);
      setNomineeChecked(nominee);
      setOpenindividual(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const clearForm = () => {
    setFormData({});
    setObjData({});
    setCheckedList({
      shareholders: false,
      ubo: false,
      nominee: false,
    });
  };

  return (
    <>
      <div className="d-flex py-4 gap-3 align-items-center">
        <Switch
          className="custom-switch"
          onChange={(value) => openIndividualModal(value)}
        />
        <h4 className="primary-title">Individual</h4>
      </div>
      {individualShow ? (
        <div key={index} id={`individual${index}`}>
          <button
            className="primary-btn ms-auto mb-3"
            onClick={individualModalOnClick}
          >
            <i className="fal fa-plus-circle"></i>Add New
          </button>
          <div className="border-box">
            <Table
              className="custom-table-light mt-md-1 mb-3"
              columns={individualcolumns}
              dataSource={individualDetails?.data?.rows}
              //  pagination={false}
              scroll={{ x: 1200 }}
              pagination={{
                className: "custom-pagination",
                defaultCurrent: 1,
                pageSize: queryParams?._limit,
                total: individualDetails?.data?.totalCount,
                onChange: getPagination,
              }}
            />
            <Modal
              title={selectedId ? "Edit Individual " : "Add New Individual"}
              okText={selectedId ? "Update" : "Save"}
              className="custom-modal"
              open={openindividual}
              centered={true}
              width={1100}
              onOk={saveOnClick}
              onCancel={closeModalOnClick}
              // footer={false}
            >
              <div className="d-flex gap-3 mb-3 align-items-center">
                <div className="d-flex gap-3 mb-3 align-items-center">
                  <h3 className="sub-title">
                    {!selectedId
                      ? ` Individual 0${individualDetails?.data?.count + 1}`
                      : `Individual 0${selectedIndex}`}{" "}
                    &nbsp;
                    <i className="fal fa-question-circle"></i>
                  </h3>
                  <Checkbox
                    className="custom-checkbox"
                    checked={directorChecked && !reserveChecked}
                    onChange={(e) =>
                      addNewRow(
                        directorFormSchema,
                        e.target.checked,
                        "directors"
                      )
                    }
                    disabled={JSON.stringify(objData) == "{}"}
                  >
                    Directors
                  </Checkbox>
                  <Checkbox
                    className="custom-checkbox"
                    checked={shareholderChecked && !nomineeChecked}
                    onChange={(e) =>
                      addNewCustom(
                        shareholderFormSchema,
                        "shareholders",
                        e.target.checked
                      )
                    }
                    disabled={JSON.stringify(objData) == "{}"}
                  >
                    Shareholders
                  </Checkbox>
                  <Checkbox
                    className="custom-checkbox"
                    onChange={(e) =>
                      addNewChange(uboFormSchema, e.target.checked, "ubo")
                    }
                    checked={uboChecked}
                    disabled={JSON.stringify(objData) == "{}"}
                  >
                    UBOs
                  </Checkbox>
                  <Checkbox
                    className="custom-checkbox"
                    checked={nomineeChecked && !shareholderChecked}
                    onChange={(e) =>
                      addNewCustom(
                        nomineeShareholder,
                        "nominee",
                        e.target.checked
                      )
                    }
                    // disabled={objData.code ? false : true}
                    disabled={JSON.stringify(objData) == "{}"}
                  >
                    Nominee Shareholder
                  </Checkbox>
                  <Checkbox
                    className="custom-checkbox"
                    checked={reserveChecked && !directorChecked}
                    onChange={(e) =>
                      addNewRow(directorFormSchema, e.target.checked, "reserve")
                    }
                    disabled={JSON.stringify(objData) == "{}"}
                  >
                    Reserve Director
                  </Checkbox>
                </div>
                <button className="primary-btn" onClick={clearForm}>
                  Clear Form
                </button>
              </div>
              <FormZ
                ref={(el) => (individualFormRef.current[index] = el)}
                formSchema={individualFormSchema}
                formData={formData}
                formOptions={{ code: individualArray, ...individualOptions }}
                onChange={individualOnChange}
                onKeyUp={(e) => individualDropDownOnClick(e.target.value)}
                formClass="row custom-form mb-3 gy-3 gx-4"
                childClass="col-md-3 mb-0"
                labelClass="mb-1"
                inputClass="w-100"
              />
              {shareForm["show"] && (
                <div>
                  {shareForm["type"] == "transfer" ? (
                    <div>
                      <div className="primary-title mb-2">
                        Transferor (Sender)
                      </div>
                      <FormZ
                        ref={senderFormRef}
                        formSchema={senderForm}
                        onChange={senderOnChange}
                        formData={senderFormData}
                        formClass="row custom-form mb-3 gy-3 gx-4"
                        childClass="col-md-3 mb-0"
                        labelClass="mb-1"
                        inputClass="w-100"
                      />
                      <div className="primary-title mb-2">
                        Transferee (Receiver)
                      </div>
                      <FormZ
                        ref={receiverFormRef}
                        formSchema={receiverForm}
                        onChange={receiverOnChange}
                        formOptions={receiverOptions}
                        formData={receiveFormData}
                        formClass="row custom-form mb-3 gy-3 gx-4"
                        childClass="col-md-3 mb-0"
                        labelClass="mb-1"
                        inputClass="w-100"
                      />
                    </div>
                  ) : (
                    <div>
                      <div className="primary-title mb-2">New Allocation</div>
                      <FormZ
                        ref={allocationFormRef}
                        formSchema={allocationForm}
                        // formData={formData}
                        formClass="row custom-form mb-3 gy-3 gx-4"
                        childClass="col-md-3 mb-0"
                        labelClass="mb-1"
                        inputClass="w-100"
                      />
                    </div>
                  )}
                  <div className="primary-title mb-2">Detailed History</div>
                  <Table
                    className="custom-table-light mt-3 mb-3"
                    columns={shareTableColumn}
                    dataSource={shareTableData}
                    scroll={{ x: 2000 }}
                    pagination={{
                      className: "custom-pagination",
                    }}
                  />
                </div>
              )}
            </Modal>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
