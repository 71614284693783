import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover, Modal, Checkbox } from "antd";
import OrgChart from "react-orgchart";
import "react-orgchart/index.css";
import { getEntity } from "../store/entity/entitySlice";
import { getOrganizationStructure } from "../store/organizationStructure/organizationStructureSlice";
import {
  sendNotify,
  getOptions,
  fetchApi,
  generateRandom,
  convertQueryParams,
  getQuery,
} from "../helper";

import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";

import FormZ from "../components/FormZ";
import { Each } from "../helper";

const structure = {
  role: (
    <div className="d-flex justify-content-between w-100">
      <i className="far fa-compress-alt"></i>
      <span>1000 shares</span>
    </div>
  ),
  name: (
    <div>
      <div className="comp-tag mb-2">
        <i className="far fa-building"></i> &nbsp;Primary Company
      </div>
      <p>Sky Vision Ltd</p>
    </div>
  ),
  primaryCompany: true,
  children: [
    {
      role: "Director",
      firstChild: true,
      children: [
        {
          role: "Individual",
          name: "Barry Smith",
          child: true,
        },
        {
          role: "Individual",
          name: "Michael Phelps",
          child: true,
          type: "RD",
          percentage: 80,
        },
        {
          role: "Corporate",
          name: "XYZ Ltd",
          child: true,
          color: 1,
          children: [
            {
              role: "Director",
              firstChild: true,
              color: 1,
              children: [
                {
                  role: "Individual",
                  name: "William Smith",
                  child: true,
                  color: 1,
                },
              ],
            },
            {
              role: "Shareholder",
              firstChild: true,
              color: 1,
              children: [
                {
                  role: "Joint Surviasdfs",
                  name: "Barry Smith \n John Smith \n Alex Smith \n Logan Smith",
                  child: true,
                  color: 1,
                  shares: 700,
                  percentage: 20,
                },
                {
                  role: "Individual",
                  name: "Hozier Williams",
                  child: true,
                  color: 1,
                  shares: 700,
                  percentage: 20,
                },
              ],
            },
            {
              role: "UBO",
              firstChild: true,
              color: 1,
              children: [
                {
                  role: "Individual",
                  name: "William Smith",
                  child: true,
                  color: 1,
                  shares: 700,
                  percentage: 100,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      role: "Shareholder",
      firstChild: true,
      children: [
        {
          role: "Individual",
          name: "William Smith",
          child: true,
          shares: 700,
          percentage: 80,
          type: "NSH",
        },
      ],
    },
    {
      role: "UBO",
      firstChild: true,
      children: [
        {
          role: "Individual",
          name: "Barry Smith",
          child: true,
          shares: 700,
          percentage: 80,
        },
        {
          role: "Corporate",
          name: "XYZ Ltd",
          child: true,
          color: 2,
          shares: 700,
          percentage: 80,
          children: [
            {
              role: "Director",
              firstChild: true,
              color: 2,
              children: [
                {
                  role: "Individual",
                  name: "William Smith",
                  child: true,
                  color: 2,
                },
              ],
            },
            {
              role: "Shareholder",
              firstChild: true,
              color: 2,
              children: [
                {
                  role: "Individual",
                  name: "William Smith",
                  child: true,
                  color: 2,
                  shares: 700,
                  percentage: 20,
                },
                {
                  role: "Individual",
                  name: "Hozier Williams",
                  child: true,
                  color: 2,
                  shares: 700,
                  percentage: 20,
                },
              ],
            },
            {
              role: "UBO",
              firstChild: true,
              color: 2,
              children: [
                {
                  role: "Individual",
                  name: "William Smith",
                  child: true,
                  color: 2,
                  shares: 700,
                  percentage: 100,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const Controls = () => {
  const { zoomIn, zoomOut, resetTransform, centerView } = useControls();

  useEffect(() => {
    centerView();
  }, []);

  return (
    <div className="control-tools d-flex justify-content-end gap-3">
      <button onClick={() => zoomIn()}>
        <i className="fal fa-search-plus"></i>
      </button>
      <button onClick={() => zoomOut()}>
        <i className="fal fa-search-minus"></i>
      </button>
      <button onClick={() => resetTransform()}>
        <i className="fal fa-times-circle"></i>
      </button>
      <button onClick={() => centerView()}>
        <i className="fal fa-arrows"></i>
      </button>
    </div>
  );
};

export default function OrganizationStructure() {
  const formDirRef = useRef();
  const formShaRef = useRef();
  const formUboRef = useRef();
  let query = getQuery();
  const survivorFormRef = useRef();
  const dispatch = useDispatch();
  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });
  const organizationStructure = useSelector(
    (state) => state.organizationStructure
  );
  const entity = useSelector((state) => state.entity);
  let { isLogged, userDetails } = useSelector((state) => state.auth);
  const [data, setDtata] = useState([]);
  const [totalShares, setTotalShares] = useState(50000);
  const [entityData, setEntityData] = useState({});
  //const [structure, setStructure] = useState({});
  useEffect(() => {
    dispatch(getEntity({ _start: 0, _limit: 1, id: query.id }));
    dispatch(getOrganizationStructure({ enId: query.id }));
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [organizationStructure]);

  const handleRefresh = async () => {
    if (organizationStructure?.data?.rows?.length) {
      setDtata(organizationStructure?.data?.rows);
    } else {
      let obj = {
        master: true,
      };
      let payload = {
        method: "POST",
        url: `/organizationStructure`,
        data: obj,
      };

      fetchApi(payload)
        .then((res) => {
          if (res.status == 201 && res?.data?.length) {
            // dispatch(getOrganizationStructure(queryParams));
          }

          // formDirRef.current.clearForm();
        })
        .catch((error) => {});
    }
    setEntityData(entity?.data?.rows?.[0]);
  };

  const structure = {
    role: (
      <div className="d-flex justify-content-between w-100">
        <i className="far fa-compress-alt"></i>
        <span>{entity?.data?.rows[0].authorisedShares}</span>
      </div>
    ),
    name: (
      <div>
        <div className="comp-tag mb-2">
          <i className="far fa-building"></i> &nbsp;{" "}
          {entity?.data?.rows[0].code}
        </div>
        <p>{entity?.data?.rows[0].name}</p>
      </div>
    ),
    primaryCompany: true,
    //children:organizationStructure?.data?.rows
    children: data,
  };

  let formDirSchema = [
    {
      name: "name",
      value: "",
      type: "text",
      label: "Name",
      placeholder: "Enter the name",
    },
    {
      name: "reserveDirector",
      value: "",
      type: "switch",
      label: "Reserve director",
    },
  ];

  let formShaSchema = [
    {
      name: "name",
      value: "",
      type: "text",
      label: "Name",
      placeholder: "Enter the name",
    },
    {
      label: "Shareholder Type",
      customElement: (
        <div className="d-flex align-items-center gap-3 mt-3">
          <Checkbox className="custom-checkbox">Direct Shareholder</Checkbox>
          <Checkbox className="custom-checkbox">Nominee Shareholder</Checkbox>
        </div>
      ),
    },
    {
      name: "shares",
      value: 0,
      type: "number",
      label: "Number of Shares",
      placeholder: "Enter number of shares",
    },
  ];

  let formUboSchema = [
    {
      name: "name",
      value: "",
      type: "text",
      label: "Name",
      placeholder: "Enter the name",
    },
    {
      name: "shares",
      value: 0,
      type: "number",
      label: "Number of Shares",
      placeholder: "Enter number of shares",
    },
  ];

  const initialState = {
    inDirector: {
      name: "Add Individual Director",
      ref: formDirRef,
      form: formDirSchema,
      cat: "Individual",
    },
    inShareholder: {
      name: "Add Individual Shareholder",
      ref: formShaRef,
      form: formShaSchema,
      cat: "Individual",
    },
    inUBO: {
      name: "Add Individual UBO",
      ref: formUboRef,
      form: formUboSchema,
      cat: "Individual",
    },
    coDirector: {
      name: "Add Corporate Entity Director",
      ref: formDirRef,
      form: formDirSchema,
      cat: "Corporate Entity",
    },
    coShareholder: {
      name: "Add Corporate Entity Shareholder",
      ref: formShaRef,
      form: formShaSchema,
      cat: "Corporate Entity",
    },
    coUBO: {
      name: "Add Corporate Entity UBO",
      ref: formUboRef,
      form: formUboSchema,
      cat: "Corporate Entity",
    },
  };

  const [modalState, setModalState] = useState(false);
  const [survivorModal, setSurvivorModal] = useState(false);
  const [survivorForms, setSurvivorForms] = useState([0]);
  const [currModal, setCurrModal] = useState(false);
  const [formToggle, setFormToggle] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedrole, setSelectedRole] = useState("");
  const [formData, setFormData] = useState([]);
  const [percentage, setPercentage] = useState(0);

  const openModal = (cat, id, role, color, survivor) => {
    try {
      setSelectedId(id);
      setSelectedColor(color);
      setSelectedRole(role);
      if (survivor) {
        setSurvivorModal(true);
      } else {
        setModalState(true);
      }
      setCurrModal(cat + role);
    } catch (e) {}
  };

  const handleOk = (name) => {
    // const valid = formRef.current.validForm();
    const options1 = formDirRef?.current?.getPayload();
    const options2 = formShaRef?.current?.getPayload();
    const options3 = formUboRef?.current?.getPayload();
    const options4 = survivorFormRef?.current?.getPayload();

    let data = {};
    if (options1) {
      data = formDirRef.current.getPayload();
    } else if (options2) {
      data = formShaRef?.current?.getPayload();
    } else if (options3) {
      data = formUboRef?.current?.getPayload();
    } else if (options4) {
      data = survivorFormRef?.current?.getPayload();
    }
    data.pid = selectedId;
    data.role = name;
    data.pType = selectedrole;
    data.percentage = percentage;
    data.color = selectedColor;
    let payload = {
      method: "POST",
      url: `/organizationStructure`,
      data,
    };

    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message);
        // dispatch(getEntity({ _start: 0, _limit: 1, id: query.id }));
        dispatch(getOrganizationStructure({ enId: query.id }));
        // formDirRef.current.clearForm();
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });

    setModalState(false);
    setSurvivorModal(false);
    setCurrModal("");
    setFormToggle([]);
    setSurvivorForms([0]);
  };
  const handleCancel = () => {
    setModalState(false);
    setSurvivorModal(false);
    setCurrModal("");
    setFormToggle([]);
    setSurvivorForms([0]);
  };

  const collapseForm = (index) => {
    let arr = [...formToggle];
    let find = arr.findIndex((a) => a == index);
    if (find != -1) {
      arr.splice(index, 1);
    } else {
      arr.push(index);
    }
    setFormToggle(arr);
  };

  const addShareholder = () => {
    let arr = [...survivorForms];
    let last = arr.at(-1);
    arr.push(last++);
    setSurvivorForms(arr);
  };
  const getOnUpdate = async (value) => {
    let { name, shares } = value;

    let percentage = (Number(shares) / Number(totalShares)) * 100;

    setPercentage(percentage);

    setFormData(value);
  };

  return (
    <div>
      <div className="page-header w-100">
        <h1>Organization Structure</h1>
      </div>
      <div className="page-content p-0">
        <div className="structure-chart-wrapper">
          <TransformWrapper initialScale={0.5}>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <div>
                <Controls />
                <TransformComponent wrapperClass="w-100">
                  <OrgChart
                    tree={structure}
                    NodeComponent={({ node }) => {
                      return (
                        <div
                          className={`chart-node ${
                            node?.color
                              ? `chart-node-bg-${node?.color}`
                              : "chart-node-bg-purple"
                          }`}
                        >
                          <div className="chart-header">
                            {node?.child ? (
                              <div className="d-flex w-100 justify-content-between gap-1 align-items-center">
                                <button
                                  onClick={() =>
                                    openModal(
                                      node.role == "Individual" ? "in" : "co",
                                      node._id,
                                      node.pType,
                                      node.color
                                    )
                                  }
                                >
                                  <i className="fal fa-edit"></i>
                                </button>
                                {node?.shares && <p>{node?.shares} shares</p>}
                              </div>
                            ) : (
                              node?.role && (
                                <>
                                  <p
                                    className={
                                      node?.primaryCompany ? "w-100" : ""
                                    }
                                  >
                                    {node?.role}
                                  </p>
                                  {node?.firstChild && (
                                    <Popover
                                      content={
                                        <div className="chart-select">
                                          <p
                                            className="chart-option"
                                            onClick={() =>
                                              openModal(
                                                "in",
                                                node._id,
                                                node.role,
                                                node.color
                                              )
                                            }
                                          >
                                            <i className="far fa-bars"></i> Add
                                            Individual
                                          </p>
                                          <p
                                            className="chart-option"
                                            onClick={() =>
                                              openModal(
                                                "co",
                                                node._id,
                                                node.role,
                                                node.color
                                              )
                                            }
                                          >
                                            <i className="far fa-bars"></i> Add
                                            Corporate Entity
                                          </p>
                                          <p
                                            className="chart-option"
                                            onClick={() =>
                                              openModal(
                                                "in",
                                                node._id,
                                                node.role,
                                                node.color,
                                                true
                                              )
                                            }
                                          >
                                            <i className="far fa-bars"></i> Add
                                            Joint Survivorship
                                          </p>
                                        </div>
                                      }
                                      trigger="click"
                                      className="custom-class"
                                    >
                                      <button>
                                        <i className="fal fa-plus"></i>
                                      </button>
                                    </Popover>
                                  )}
                                </>
                              )
                            )}
                          </div>
                          {node?.name && (
                            <div className="chart-body">
                              <p>
                                {node?.child ? (
                                  <div>
                                    <div className="d-flex w-100 justify-content-between gap-1 align-items-center mb-2">
                                      <div className="comp-tag">
                                        {node?.role == "Corporate" ? (
                                          <i className="far fa-building"></i>
                                        ) : node?.role == "Individual" ? (
                                          <i className="far fa-user-circle"></i>
                                        ) : (
                                          <i className="far fa-th-large"></i>
                                        )}
                                        &nbsp;&nbsp;{node?.role}
                                      </div>
                                      {node?.percentage && (
                                        <div className="comp-tag ptb-1">
                                          {node?.percentage}%
                                        </div>
                                      )}
                                    </div>
                                    <div className="d-flex w-100 justify-content-between gap-1 align-items-center">
                                      <p>{node?.name}</p>
                                      {node?.type && (
                                        <div className="comp-tag ptb-1">
                                          {node?.type}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  node?.name
                                )}
                              </p>
                            </div>
                          )}
                        </div>
                      );
                    }}
                  />
                </TransformComponent>
              </div>
            )}
          </TransformWrapper>
        </div>
      </div>
      <Modal
        title={initialState[currModal]?.name}
        open={modalState}
        className="custom-modal-2"
        onOk={() => handleOk(initialState[currModal]?.cat)}
        okText="Add"
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <FormZ
          ref={initialState[currModal]?.ref}
          formSchema={initialState[currModal]?.form}
          formData={formData}
          onChange={getOnUpdate}
        />
        {(currModal == "inShareholder" ||
          currModal == "inUBO" ||
          currModal == "coShareholder" ||
          currModal == "coUBO") && (
          <div className="custom-form">
            <div className="">
              <label htmlFor="" className="mb-2">
                Percentage of Shares
              </label>
              <div className="custom-badge py-1">{`${percentage} %`}</div>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        title={"Add Joint Survivorship Shareholders"}
        open={survivorModal}
        className="custom-modal-2"
        onOk={() => handleOk("Joint Survivorship")}
        okText="Add"
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div className="d-flex flex-column gap-3">
          <Each
            array={survivorForms}
            render={(item, index) => (
              <div
                className={`custom-box collapse-form ${
                  formToggle.includes(0) ? "closed" : ""
                }`}
              >
                <div className="d-flex justify-content-between">
                  <span className="light-badge">
                    <i className="fas fa-user-circle"></i> Shareholder{" "}
                    {index + 1}
                  </span>
                  <button
                    className="collapse-arrow"
                    onClick={() => collapseForm(0)}
                  >
                    <i
                      className={`fal fa-chevron-up ${
                        formToggle.includes(0) ? "fa-rotate-180" : ""
                      }`}
                    ></i>
                  </button>
                </div>

                <div className="form-content mt-3">
                  <FormZ
                    ref={survivorFormRef}
                    formSchema={formShaSchema}
                    formData={formData}
                    onChange={getOnUpdate}
                  />
                  <div className="custom-form">
                    <div className="">
                      <label htmlFor="" className="mb-2">
                        Percentage of Shares
                      </label>
                      <div className="custom-badge py-1">0%</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          />
        </div>
        <button className="secondary-btn mt-4" onClick={addShareholder}>
          <i className="fal fa-plus"></i> Add Shareholder
        </button>
      </Modal>
    </div>
  );
}
