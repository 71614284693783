import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Modal, Tag } from "antd";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

import { Each } from "../helper";
import UploadZ from "../components/UploadZ";
import TableSortArrows from "../components/tableSortArrows";
import TagZ from "../components/TagZ";
import TableFilterZ from "../components/TableFilterZ";
import SampleDoc from "../assets/pdf/sample.pdf";
import { getIncorpSubmission } from "../store/incorpSubmission/incorpSubmissionSlice";
import {
  sendNotify,
  getOptions,
  fetchApi,
  timeDiff,
  generateRandom,
  getQuery,
  customPagination,
} from "../helper";

export default function IncorporationFeedback() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const incorpSubmission = useSelector((state) => state.incorpSubmission);
  const [documents, setDocuments] = useState([]);
  const [uploadDocModal, setUploadDocModal] = useState(false);
  const [viewDocModal, setViewDocModal] = useState(false);
  const [docMode, setDocMode] = useState("Update");
  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });
  const [data, setDate] = useState([]);
  const [filters, setFilters] = useState(null);
  const [selectedId, setSelectedId] = useState([]);
  useEffect(() => {
    dispatch(getIncorpSubmission(queryParams));
  }, []);
  useEffect(() => {
    handleRefresh();
  }, [incorpSubmission]);

  const handleRefresh = async () => {
    if (incorpSubmission?.data?.rows) {
      let finded = incorpSubmission?.data?.rows;
      let data = finded.map((a, index) => {
        let obj = {
          key: index,
          _id: a._id,
          entityName: a?.entity?.name,
          entityType: a?.entity?.type,
          entityCode: a?.entityCode,
          incorpDate: `${moment(a.createdAt).format(
            "DD-MMM-YYYY"
          )} - ${timeDiff(a.createdAt)}`,
          code: a.code,
          incorpStatus: "",
          registryDate: `${moment(a.updatedAt).format(
            "DD-MMM-YYYY"
          )} - ${timeDiff(a.updatedAt)}`,
          reviewedBy: a.requestedBy.name,
          approvedDate: "",
          approver: "",
        };
        return obj;
      });
      setDate(data);
    }
  };

  const columns = [
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      filterType: "text",
      sorter: (a, b) => a.entityCode.localeCompare(b.entityCode),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Name",
      dataIndex: "entityName",
      filterType: "text",
      sorter: (a, b) => a?.entityName?.localeCompare(b?.entityName),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Type",
      dataIndex: "entityType",
      filterType: "text",
      sorter: (a, b) => a.entityType.localeCompare(b.entityType),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Incorp Date",
      dataIndex: "incorpDate",
      filterType: "date",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Number",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.code - b.code,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Status",
      dataIndex: "incorpStatus",
      sorter: (a, b) => a.incorpStatus - b.incorpStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { incorpStatus }) => (
        <TagZ
          status={incorpStatus ? "approved" : "in-progress"}
          statusText={incorpStatus ? "Active" : "Pending"}
        />
      ),
    },
    {
      title: "Registry Date & Time",
      dataIndex: "registryDate",
      sorter: (a, b) => a.registryDate - b.registryDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Reviewed By",
      dataIndex: "reviewedBy",
      sorter: (a, b) => a.reviewedBy - b.reviewedBy,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Approved Date",
      dataIndex: "approvedDate",
      sorter: (a, b) => a.approvedDate - b.approvedDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Approver",
      dataIndex: "approver",
      sorter: (a, b) => a.approver - b.approver,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Actions",
      dataIndex: "incorpPack",
      render: (_, { data, _id }) => (
        <div className="d-flex gap-3">
          <button
            className="action-btn icon-btn py-2"
            onClick={() => setUploadDocModal(true)}
          >
            <i className="fal fa-eye"></i>
          </button>
          <button className="outline-btn py-2">Submit</button>
          <button className="outline-btn py-2">Feedback</button>
          <button
            className="outline-btn py-2"
            onClick={() => (setViewDocModal(true), setDocMode("Update"))}
          >
            Incorp Doc Draft
          </button>
          <button
            className="outline-btn py-2"
            onClick={() => (setViewDocModal(true), setDocMode("View"))}
          >
            Executed From Client
          </button>
          <button
            className="primary-btn py-2"
            onClick={() => onClickOnboard(_id)}
          >
            Onboard
          </button>
        </div>
      ),
    },
  ];
  const onClickOnboard = (id) => {
    let selectedId = [id];
    let alldata = incorpSubmission.data.rows;
    let filterData = alldata.filter((a) => selectedId.includes(a._id));
    let data = filterData.map((a, index) => {
      let obj = {
        pid: a._id,
        entityId: a.entityId,
        entityCode: a.entityCode,
      };
      return obj;
    });
    let payload = {
      method: "post",
      url: `/newBusinessOnboard`,
      data,
    };
    fetchApi(payload)
      .then((res) => {
        if (res.message) {
          sendNotify("success", res?.data?.message);
        } else if (res?.error) {
          sendNotify("error", res?.error?.response?.data?.message?.message);
        }
        setTimeout(() => {
          window.location.href = `/app/onboarding-team/review-upload`;
        }, 1000);
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedId(selectedRowKeys);

      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  function storeDocuments(file) {
    let doc = [...documents];
    doc.push(file);

    setDocuments(doc);
  }

  const uploadDocs = [
    "Certificate of Incorporation",
    "M&AA",
    "Seal",
    "VIRRGIN Report",
    "COGS",
  ];

  const [customDocs, setCustomDocs] = useState([]);

  const addNewUpload = () => {
    let arr = [...customDocs];
    let index = arr.length + 1;
    arr.push(`Enter Document Name ${index}`);
    setCustomDocs(arr);
  };

  const removeUpload = (index) => {
    let arr = [...customDocs];
    arr.splice(index, 1);
    setCustomDocs(arr);
  };

  const getLabelName = (value, index) => {
    let arr = [...customDocs];
    arr[index] = value;
    setCustomDocs(arr);
  };

  const handleOk = () => {
    setUploadDocModal(false);
    setViewDocModal(false);
  };
  const handleCancel = () => {
    setUploadDocModal(false);
    setViewDocModal(false);
  };

  const sampDocuments = {
    url: SampleDoc,
    name: "Sample Document.pdf",
    size: "200 KB",
    type: "pdf",
    base64: "",
  };

  function redirectToUpload() {
    navigate("/app/relationship-manager?mode=edit");
  }
  const getPagination = async (current, pageSize) => {
    let params = { ...queryParams };
    const filter = encodeURIComponent(JSON.stringify(filters));

    params._start = (current - 1) * pageSize;
    params._limit = pageSize;
    if (filters) {
      params.filters = filter;
    }
    dispatch(getIncorpSubmission(params));
    setQueryParams(params);
  };

  const getFilter = async (value) => {
    let params = { ...queryParams };
    const filters = encodeURIComponent(JSON.stringify(value));
    params.filters = filters;
    dispatch(getIncorpSubmission(params));
    setQueryParams(params);
    setFilters(value);
  };
  const onClickSubmit = () => {
    let alldata = incorpSubmission.data.rows;
    let filterData = alldata.filter((a) => selectedId.includes(a._id));

    let data = filterData.map((a, index) => {
      let obj = {
        pid: a._id,
        entityId: a.entityId,
        entityCode: a.entityCode,
      };
      return obj;
    });
    let payload = {
      method: "post",
      url: `/newBusinessOnboard`,
      data,
    };
    fetchApi(payload)
      .then((res) => {
        if (res.message) {
          sendNotify("success", res?.data.message);
        } else if (res.error) {
          sendNotify("error", res.error.response.data.message.message);
        }
        setTimeout(() => {
          window.location.href = `/app/onboarding-team/review-upload`;
        }, 1000);
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };
  return (
    <div>
      <div className="page-header">
        <h1>Incorporation Feedback</h1>
        {/* <Link to={"/app/onboarding-team/review-upload"} className="primary-btn">
          Complete and Submit
        </Link> */}
        <button
          className="primary-btn"
          disabled={!selectedId.length}
          onClick={() => onClickSubmit()}
        >
          Complete and Submit
        </button>
      </div>
      <div className="d-flex justify-content-end mb-3">
        <TableFilterZ tableCol={columns} onChange={getFilter} />
      </div>
      <div className="page-content">
        <Table
          className="custom-table"
          loading={incorpSubmission?.isLoading}
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={columns}
          rowKey={"_id"}
          scroll={{ x: 2100 }}
          dataSource={data}
          pagination={{
            className: "custom-pagination",
            defaultCurrent: 1,
            pageSize: queryParams?._limit,
            total: incorpSubmission?.data?.totalCount,
            onChange: getPagination,
            itemRender: customPagination,
          }}
        />

        <Modal
          title={"Upload Documents"}
          className="custom-modal"
          open={uploadDocModal}
          width={1000}
          onOk={handleOk}
          okText="Upload"
          onCancel={handleCancel}
        >
          <div className="custom-form">
            <div className="row gy-2 gx-4">
              <Each
                array={uploadDocs}
                render={(item) => (
                  <div className="col-md-4">
                    <label htmlFor={item}>{item}</label>
                    <UploadZ onStoreFile={storeDocuments} fileName={item} />
                  </div>
                )}
              />
              <Each
                array={customDocs}
                warning={false}
                render={(item, i) => (
                  <div className="col-md-4">
                    <div className="d-flex justify-content-between align-items-center mb-1px">
                      <label
                        contentEditable={true}
                        key={i}
                        id={i}
                        onBlur={(e) => getLabelName(e.target.innerHTML, i)}
                        className="mb-0"
                        suppressContentEditableWarning={true}
                      >
                        {item}
                      </label>
                      <button
                        className="del-upload-btn"
                        onClick={() => removeUpload(i)}
                      >
                        <i className="far fa-trash-alt"></i>
                      </button>
                    </div>
                    <UploadZ onStoreFile={storeDocuments} fileName={item} />
                  </div>
                )}
              />
              <div className="col-md-4">
                <label htmlFor="">&nbsp;</label>
                <div className="add-new-upload" onClick={addNewUpload}>
                  <span className="icon">
                    <i className="fal fa-plus-circle"></i>
                  </span>
                  <h6>Add New Upload</h6>
                  {/* <p>Click to create new upload field</p> */}
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          title={`${docMode} Documents`}
          className="custom-modal"
          open={viewDocModal}
          width={1000}
          onOk={docMode == "Update" ? redirectToUpload : handleOk}
          okText="Send"
          onCancel={handleCancel}
        >
          <div className="custom-form">
            <div className="row gy-2 gx-4">
              <Each
                array={uploadDocs}
                render={(item) => (
                  <div className="col-md-4">
                    <label htmlFor={item}>{item}</label>
                    <UploadZ
                      file={sampDocuments}
                      onStoreFile={storeDocuments}
                      fileName={item}
                      downloadOnly={docMode == "View"}
                    />
                  </div>
                )}
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
