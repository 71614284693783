import { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Table, Tag, Select, Modal, Checkbox, Spin } from "antd";
import moment from "moment";

import {
  Each,
  customPagination,
  downloadBase64,
  downloadFile,
  fetchApi,
  formatBytes,
  sendNotify,
} from "../helper";

import UploadZ from "../components/UploadZ";
import TagZ from "../components/TagZ";
import TableSortArrows from "../components/tableSortArrows";

import { getPendingRequests } from "../store/pendingRequests/pendingRequestsSlice";

export default function PendingRequests() {
  const dispatch = useDispatch();

  const pendingRequests = useSelector((state) => state.pendingRequests);

  const [query, setQuery] = useState({
    _limit: 10,
    _start: 0,
  });
  const [resubmitModal, setResubmitModal] = useState({
    open: false,
    data: null,
  });
  const [documents, setDocuments] = useState([]);
  const [docModal, setDocModal] = useState({ file: null, open: false });
  const [reqType, setReqType] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadedDocModal, setUploadedDocModal] = useState(false);
  const [uploadedDoc, setUploadedDoc] = useState({});

  useEffect(() => {
    dispatch(getPendingRequests(query));
  }, [query]);

  const columns = [
    {
      title: "Request Ref",
      dataIndex: "code",
      sorter: (a, b) => a.code - b.code,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Request type",
      dataIndex: "serviceType",
      sorter: (a, b) => a.serviceType - b.serviceType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Request Status",
      dataIndex: "resubmissionStatus",
      sorter: (a, b) => a.resubmissionStatus - b.resubmissionStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { resubmissionStatus }) => {
        let currStatus;
        switch (resubmissionStatus) {
          case "Pending":
            currStatus = "in-progress";
            break;
          case "In progress":
            currStatus = "in-progress";
            break;
          case "Resubmitted":
            currStatus = "resubmitted";
            break;
          case "Completed":
            currStatus = "approved";
            break;
        }
        return <TagZ status={currStatus} statusText={resubmissionStatus} />;
      },
    },
    {
      title: "Origin Date",
      dataIndex: "resubmissionOriginAt",
      sorter: (a, b) =>
        moment(a.resubmissionOriginAt).unix() -
        moment(b.resubmissionOriginAt).unix(),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (resubmissionOriginAt) =>
        moment(resubmissionOriginAt).format("YYYY-MM-DD hh:mm A"),
    },
    {
      title: "Pending Content",
      dataIndex: "pendingContent",
      sorter: (a, b) => a.pendingContent - b.pendingContent,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { id }) => (
        <Link to={"/app/incorporation-request"} className="action-btn icon-btn">
          <i className="fal fa-eye"></i>
        </Link>
      ),
    },
    {
      title: "Resubmission Content",
      dataIndex: "resubmission",
      sorter: (a, b) => a.resubmission - b.resubmission,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, data) => (
        <button
          className="action-btn icon-btn"
          disabled={data?.resubmissionStatus == "Resubmitted"}
          onClick={() => openResubmitModal(data)}
        >
          <i className="fal fa-eye"></i>
        </button>
      ),
    },
    {
      title: "Resubmission Status",
      dataIndex: "resubmissionStatus",
      sorter: (a, b) => a.resubmissionStatus - b.resubmissionStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { resubmissionStatus }) => {
        let status;
        switch (resubmissionStatus) {
          case "Pending":
            status = "in-progress";
            break;
          case "In progress":
            status = "in-progress";
            break;
          case "Resubmitted":
            status = "resubmitted";
            break;
          case "Completed":
            status = "approved";
            break;
        }
        return <TagZ status={status} statusText={resubmissionStatus} />;
      },
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (_, { id }) => (
    //     <div className="d-flex justify-content-end align-items-center gap-3">
    //     <button className="action-btn">Resubmit</button>
    //         <Link to={`/app/document-review/${id}`} className='action-btn'>View Request &nbsp; <i className="far fa-long-arrow-right me-0"></i></Link>
    //     </div>
    //   ),
    // },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleOk = () => {
    setDocModal(false);
    setUploadedDocModal(false);
  };
  const handleCancel = () => {
    setDocModal(false);
    setUploadedDocModal(false);
  };

  const getPagination = (current, pageSize) => {
    let params = { ...query };
    params._start = (current - 1) * pageSize;
    params._limit = pageSize;
    dispatch(getPendingRequests(params));
    setQuery(params);
  };

  const openResubmitModal = (data) => {
    let type = data?.individual?.name ? "individual" : "corporate";
    setDocuments(type ? data?.[type]?.documents : data?.documents);
    setReqType(type);
    setResubmitModal({ data, open: true });
  };

  const uploadDocument = async (file) => {
    if (file) {
      if (file.size > 10485760) {
        setError(true);
        sendNotify(
          "error",
          "File is too big! Upload a file smaller than 10MB."
        );
      } else {
        let type = file.name.substring(file.name.lastIndexOf(".") + 1);
        const regex = new RegExp("(pdf|docx|doc|xls|xlsx|csv)$", "i");

        if (regex.test(type)) {
          setLoading(true);

          const bodyFormData = new FormData();
          bodyFormData.append("file", file);
          bodyFormData.append("folder", "test");

          let payload = {
            method: "POST",
            url: `/file`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
          };

          await fetchApi(payload)
            .then((res) => {
              let doc = {
                document: uploadedDoc?.document,
                name: file?.name,
                size: file?.size,
                type: type,
                url: res?.data?.url,
                isApprove: false,
                rejected: true,
                new: true,
              };

              let docs = [...documents];
              docs[uploadedDoc?.index] = doc;
              setDocuments(docs);

              sendNotify(
                "success",
                res.message || "File uploaded successfully."
              );
              // dispatch(getPendingRequests(query));
              setLoading(false);
            })
            .catch((error) => {
              setLoading(false);
              console.error(error, "onStoreFile-error");
              sendNotify("error", error.message || "File upload failed.");
            });
        } else {
          setError(true);
          sendNotify(
            "error",
            "Only PDF, DOC, DOCX, XLS, XLSX, or CSV files are supported."
          );
        }
      }
    }
  };

  const viewUploadedDoc = (document) => {
    let file = documents?.find((dc) => dc.document === document);

    viewDocument(file);
  };

  const deleteDocument = (document) => {
    let docs = [...documents];
    let index = documents?.findIndex((dc) => dc.document === document);

    let oldDocs = [...resubmitModal.data?.documents];
    let oldDoc = oldDocs.find((od) => od.document === document);

    docs[index] = oldDoc;
    setDocuments(docs);
  };

  const viewDocument = async (file) => {
    if (file) {
      setLoading(true);

      let payload = {
        method: "POST",
        url: `/file/get`,
        data: { url: file?.url },
      };

      await fetchApi(payload)
        .then((res) => {
          if (file?.type === "pdf") {
            setDocModal({ open: true, file: res?.data.base64Url });
          } else {
            downloadFile(
              `data:application/${file?.type};base64,${res?.data.base64Url}`,
              file?.name
            );
          }
          // sendNotify("success", res.message || "File download successfully.");
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error, "get file error");
          sendNotify("error", error.message || "File download failed.");
        });
    }
  };

  const resubmit = async (file) => {
    setLoading(true);

    let oldDocs = [...resubmitModal.data?.documents];
    let allDocs = [];

    if (file) {
      let index = oldDocs.findIndex((od) => od.document === file.document);
      if (index !== -1) {
        oldDocs[index] = file;
      }
    } else {
      oldDocs.map((od, index) => {
        if (documents[index]) {
          if (od.document === documents[index].document) {
            allDocs.push(documents[index]);
          } else {
            allDocs.push(od);
          }
        } else {
          allDocs.push(od);
        }
      });
    }

    let data = {
      resubmissionStatus: "Resubmitted",
    };

    if (reqType) {
      data[reqType] = {
        ...resubmitModal.data?.[reqType],
        documents: file ? oldDocs : allDocs,
      };
    } else {
      data["documents"] = file ? oldDocs : allDocs;
    }
    let payload = {
      method: "PUT",
      url: `/serviceRequest/update/${resubmitModal.data?._id}`,
      data,
    };
    await fetchApi(payload)
      .then((res) => {
        setLoading(false);
        sendNotify("success", "Resubmit successfully.");
        dispatch(getPendingRequests(query));

        if (file) {
          deleteDocument(file?.document);
        } else {
          setResubmitModal({ data: null, open: false });
          setDocuments([]);
        }
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };

  return (
    <div>
      <div className="page-header">
        <h1>Pending Information</h1>
        {/* <button className="primary-btn">Resubmit All</button> */}
      </div>
      <div className="page-content">
        <Table
          // rowKey={"code"}
          loading={pendingRequests?.isLoading}
          className="custom-table"
          // rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={columns}
          dataSource={pendingRequests?.data?.rows}
          pagination={{
            className: "custom-pagination",
            defaultCurrent: 1,
            pageSize: query?._limit,
            total: pendingRequests?.data?.totalCount,
            onChange: getPagination,
            itemRender: customPagination,
          }}
        />
        <Modal
          title={"Resubmission Content"}
          className="custom-modal"
          open={resubmitModal.open}
          width={800}
          onOk={() => resubmit()}
          okText="Save & Close"
          onCancel={() => setResubmitModal({ data: null, open: false })}
        >
          <Spin spinning={loading}>
            {resubmitModal?.data?.individual?.name ||
            resubmitModal?.data?.corporate?.name ? (
              <>
                {resubmitModal?.data?.individual?.name && (
                  <ResubmitDocument
                    name={"Individual"}
                    comment={
                      resubmitModal?.data?.resubmissionComment?.appointment
                    }
                    documents={documents}
                    resubmit={resubmit}
                    viewUploadedDoc={viewUploadedDoc}
                    deleteDocument={deleteDocument}
                    viewDocument={viewDocument}
                    setUploadedDoc={setUploadedDoc}
                    uploadDocument={uploadDocument}
                  />
                )}
                {resubmitModal?.data?.corporate?.name && (
                  <ResubmitDocument
                    name={"Corporate"}
                    comment={
                      resubmitModal?.data?.resubmissionComment?.appointment
                    }
                    documents={documents}
                    resubmit={resubmit}
                    viewUploadedDoc={viewUploadedDoc}
                    deleteDocument={deleteDocument}
                    viewDocument={viewDocument}
                    setUploadedDoc={setUploadedDoc}
                    uploadDocument={uploadDocument}
                  />
                )}
              </>
            ) : (
              <ResubmitDocument
                name={"Documents"}
                comment={resubmitModal?.data?.resubmissionComment?.appointment}
                documents={documents}
                resubmit={resubmit}
                viewUploadedDoc={viewUploadedDoc}
                deleteDocument={deleteDocument}
                viewDocument={viewDocument}
                setUploadedDoc={setUploadedDoc}
                uploadDocument={uploadDocument}
              />
            )}
          </Spin>
        </Modal>
        <Modal
          title={"Submitted Document"}
          className="custom-modal"
          open={docModal.open}
          width={1000}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="document-view">
            <iframe
              src={`data:application/pdf;base64,${docModal?.file}`}
              frameBorder="0"
              height="100%"
              width="100%"
            ></iframe>
          </div>
        </Modal>
      </div>
    </div>
  );
}

function ResubmitDocument({
  name,
  comment,
  documents,
  resubmit,
  viewUploadedDoc,
  deleteDocument,
  viewDocument,
  setUploadedDoc,
  uploadDocument,
}) {
  return (
    <div className="resubmission my-4">
      <h4 className="mb-3">{name}</h4>
      <div className="resubmission-content mb-4">
        <p className="mb-3">
          <span>Comment: </span>
          {comment}
        </p>
        {documents
          ?.filter((doc) => doc.rejected == true)
          ?.map((doc, index) => {
            return (
              <div className="mb-3" key={index}>
                <h6>{doc?.document}</h6>

                <div>
                  {doc?.new ? (
                    <div className="d-flex gap-2">
                      <button
                        className="outline-btn"
                        onClick={() => viewUploadedDoc(doc?.document)}
                      >
                        <i className="fal fa-eye"></i> Uploaded Document
                      </button>
                      <button
                        className="outline-btn red-btn"
                        onClick={() => deleteDocument(doc?.document)}
                      >
                        <i className="fal fa-trash-alt"></i>
                      </button>
                      <button
                        className="primary-btn justify-content-center"
                        onClick={() => resubmit(doc)}
                      >
                        Resubmit
                      </button>
                    </div>
                  ) : (
                    <div className="d-flex gap-2">
                      <button
                        className="outline-btn"
                        onClick={() => viewDocument(doc)}
                      >
                        <i className="fal fa-eye"></i> Submitted Document
                      </button>
                      <label
                        htmlFor={`file-${index}`}
                        className="outline-btn"
                        onClick={() =>
                          setUploadedDoc({
                            index,
                            document: doc?.document,
                          })
                        }
                      >
                        <input
                          hidden={true}
                          type="file"
                          name={`file-${index}`}
                          id={`file-${index}`}
                          className="d-none"
                          accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          onChange={(e) => uploadDocument(e.target.files[0])}
                        />
                        <i className="fal fa-arrow-to-top"></i> Upload New
                        Document
                      </label>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
