import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchApi, convertQueryParams } from "../../helper";

export const getSignature = createAsyncThunk("getSignature", async (params) => {
  let query = convertQueryParams(params);

  let payload = {
    method: "get",
    url: `/signature?${query}`,
  };

  let res = await fetchApi(payload).then((res) => {
    return res.data;
  });
  return res;
});

const incorpSlice = createSlice({
  name: "getSignature",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getSignature.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getSignature.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });

    builder.addCase(getSignature.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export default incorpSlice.reducer;
