import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

import { Modal } from "antd";
import { jwtDecode } from "jwt-decode";
import { sendNotify, fetchApi } from "../../helper";
import FormZ from "../../components/FormZ";

const { success, confirm } = Modal;

export default function ResetPassword() {
  const navigate = useNavigate();

  const formRef = useRef();
  const contactFormRef = useRef();

  const [customError, setCustomError] = useState({});
  const [formData, setFormData] = useState({});
  const [passValid, setPassValid] = useState({});
  const [loading, setLoading] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [expirationTime, setExpirationTime] = useState(null);
  const query = new URLSearchParams(useLocation().search);
  const [timeLeft, setTimeLeft] = useState({});
  const token = query.get("token");

  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode(token);

        calculateTimeLeft(decoded);

        const timer = setInterval(() => {
          calculateTimeLeft(decoded);
        }, 1000);

        return () => clearInterval(timer);
      } catch (error) {
        console.log("Invalid token");
      }
    }
  }, [token]);

  const calculateTimeLeft = (decoded) => {
    if (decoded) {
      const now = new Date().getTime();
      const expirationTime = decoded.exp * 1000;
      const distance = expirationTime - now;
      let timeLeft = {};

      if (distance > 0) {
        timeLeft = {
          minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((distance % (1000 * 60)) / 1000),
        };
      } else {
        timeLeft = { expired: true };
      }
      setFormData({ email: decoded.email });
      setTimeLeft(timeLeft);
    }
  };

  let formSchema = [
    {
      name: "email",
      value: "",
      type: "email",
      label: "Email",
      placeholder: "Enter email",
      required: true,
      disabled: true,
    },
    {
      name: "password",
      value: "",
      type: "password",
      label: "Password",
      placeholder: "Enter password",
      required: true,
      autoComplete: false,
      validation: true,
    },
    {
      customElement: (
        <ul
          className={`pass-validation ${formData["password"] ? "pass-in" : ""}`}
        >
          <li className={passValid["length"] ? "valid" : "not-valid"}>
            <i
              className={`fas ${
                passValid["length"] ? "fa-check-circle" : "fa-times-circle"
              }`}
            ></i>
            Password is more than 8 characters
          </li>
          <li className={passValid["upper"] ? "valid" : "not-valid"}>
            <i
              className={`fas ${
                passValid["upper"] ? "fa-check-circle" : "fa-times-circle"
              }`}
            ></i>
            Upper and lowercase letters
          </li>
          <li className={passValid["number"] ? "valid" : "not-valid"}>
            <i
              className={`fas ${
                passValid["number"] ? "fa-check-circle" : "fa-times-circle"
              }`}
            ></i>
            At least one number
          </li>
          <li className={passValid["special"] ? "valid" : "not-valid"}>
            <i
              className={`fas ${
                passValid["special"] ? "fa-check-circle" : "fa-times-circle"
              }`}
            ></i>
            At least one special character
          </li>
        </ul>
      ),
    },
    {
      name: "confirmPassword",
      value: "",
      type: "password",
      label: "Confirm Password",
      validation: false,
      placeholder: "Enter confirm password",
      required: true,
    },
  ];

  let contactFormSchema = [
    {
      name: "clientCode",
      value: "",
      type: "text",
      label: "Client Code",
      placeholder: "Enter client code",
      required: true,
    },
    {
      name: "clientName",
      value: "",
      type: "text",
      label: "Masterfile Name",
      placeholder: "Enter masterfile name",
    },
    {
      name: "information",
      value: "",
      type: "textarea",
      label: "More Information",
      placeholder: "Leave a comment...",
    },
  ];

  const getFormData = (data, field) => {
    let { password } = data;
    let valid = { ...passValid };

    valid["upper"] = /[A-Z]/?.test(password);
    valid["number"] = /[0-9]/?.test(password);
    valid["special"] = /[(@!#\$%\^\&*\)\(+=._-]/?.test(password);
    valid["length"] = password?.length > 8;

    setPassValid(valid);
    setFormData(data);
  };

  const onSubmit = () => {
    let passCheck = Object.values(passValid).every((item) => item);

    if (formRef.current.validForm() && passCheck) {
      // Reset successfully
      const options = formRef.current.getPayload();

      let obj = formRef.current.getPayload();
      obj.token = token;
      let payload = {
        method: "post",
        url: "/admin/reset-password",
        data: obj,
      };
      fetchApi(payload).then((response) => {
        if (response.status == 200) {
          success({
            title: <h5>Password Reset Successful</h5>,
            content: (
              <p>
                Your password has been updated successfully. You can now log in
                with your new password.
              </p>
            ),
            className: "custom-confirm",
            centered: true,
            closable: true,
            okText: "Login",
            onOk() {
              navigate("/login");
              // console.log("Cancel");
            },
            onCancel() {
              console.log("Cancel");
            },
          });
        } else {
          // Error while reset password
          confirm({
            title: <h5>Password Reset Failed</h5>,
            icon: (
              <div className="modal-icon">
                <i className="fas fa-times-circle color-red"></i>
              </div>
            ),
            content: (
              <p>
                We encountered an issue resetting your password. Please try
                again or contact support for assistance.
              </p>
            ),
            open: false,
            className: "custom-confirm",
            centered: true,
            closable: false,
            okText: "Retry",
            cancelText: "Contact Support",
            onOk() {
              navigate("/reset-password");
            },
            onCancel() {
              setContactModal(true);
            },
          });
        }

        // if (response.error) {
        //   sendNotify("error", response.error?.response?.data?.message);
        // } else {
        //   sendNotify("success", response.message);
        // }
      });
    } else {
      if (!passCheck) {
        sendNotify("error", "Invalid password format");
      } else {
        sendNotify("error", "Some fields are missing!");
      }
    }
  };

  const handleOk = () => {
    setContactModal(false);
  };

  const handleCancel = () => {
    setContactModal(false);
  };

  return (
    <>
      {!timeLeft.expired ? (
        <div className="auth-form">
          <div
            className={`alert ${
              timeLeft.expired ? "alert-danger" : "alert-primary"
            } text-center`}
          >
            <i className="fas fa-clock fa-2x mb-2"></i>
            <div className="countdown-timer">
              {timeLeft.expired ? (
                <span>EXPIRED</span>
              ) : (
                <span>
                  {timeLeft.minutes}m {timeLeft.seconds}s
                </span>
              )}
            </div>
          </div>
          <FormZ
            formSchema={formSchema}
            formData={formData}
            ref={formRef}
            onChange={getFormData}
            customValidation={customError}
            labelClass={"mb-0"}
          />
          <button
            className={`primary-btn mt-5 auth-btn ${
              loading ? "btn-loading" : ""
            }`}
            onClick={onSubmit}
          >
            {loading ? <i className="far fa-spinner-third fa-spin"></i> : ""}
            Change Password
          </button>

          <Modal
            title={"Contact Support"}
            className="custom-modal-2"
            open={contactModal}
            width={600}
            onOk={handleOk}
            okText="Submit"
            onCancel={handleCancel}
          >
            <FormZ
              ref={contactFormRef}
              formSchema={contactFormSchema}
              formClass="custom-form"
            />
          </Modal>
        </div>
      ) : (
        <>
          <div className="auth-form">
            <div className={`alert  alert-danger  text-center`}>
              <i className="fas fa-clock fa-2x mb-2"></i>
              <div className="countdown-timer">
                <span>EXPIRED</span>
              </div>
            </div>
            <Link className="primary-btn mt-5 auth-btn" to={"/"}>
              Back
            </Link>
          </div>
        </>
      )}
    </>
  );
}
