import React, { useState, useEffect, useRef } from "react";
import { Table, Modal } from "antd";

import moment from "moment/moment";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
import html2pdf from "html2pdf.js";
import COI_PDF from "../assets/pdf/certificate-of-incumbency.pdf";

import { sendNotify, getQuery, fetchApi } from "../helper";
import AddSignature from "../components/AddSignature";

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

const Controls = (props) => {
  const { zoomIn, zoomOut, resetTransform, centerView } = useControls();

  const downloadPDF = () => {
    if (props.signature) {
      props.generatePdf();
    } else {
      sendNotify(
        "error",
        "Signature missing. Please provide a valid signature to continue."
      );
    }
    //downloadFile(COI_PDF, "certificate-of-incumbency");
  };

  return (
    <div className="control-tools d-flex gap-3">
      <button onClick={() => zoomIn()}>
        <i className="far fa-search-plus"></i>
      </button>
      <button onClick={() => zoomOut()}>
        <i className="far fa-search-minus"></i>
      </button>
      <button onClick={downloadPDF}>
        <i className="far fa-arrow-to-bottom"></i>
      </button>
      {/* <button onClick={() => resetTransform()}>
        <i className="fal fa-times-circle"></i>
      </button>
      <button onClick={() => centerView()}>
        <i className="fal fa-arrows"></i>
      </button> */}
    </div>
  );
};

export default function CertificateOfIncumbency() {
  let query = getQuery();
  const [signModal, setSignModal] = useState(false);
  const [value, setvalue] = useState(false);
  const [copEntityCategories, setCopEntityCategories] = useState({});
  const [individualCategories, setIndividualCategories] = useState({});
  const [shareholderData, setShareholderData] = useState([]);
  const [directorData, setDirectorData] = useState([]);
  const [uboData, setUboData] = useState([]);
  const pdfRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    if (!query) return;

    const fetchData = async (url) => {
      let payload = { method: "GET", url };
      return fetchApi(payload).then((res) => res?.data?.rows || []);
    };

    const serviceData = await fetchData(`/serviceRequest?id=${query.id}`);

    const entityId = query?.id
      ? serviceData[0]?.entityId
      : query?.entityId
      ? query?.entityId
      : query?.id;

    const individualDetails = await fetchData(
      `/individualDetails?enId=${entityId}&all=true`
    );
    const copEntityDetails = await fetchData(
      `/copEntityDetails?enId=${entityId}&all=true`
    );

    const processDocuments = (docs) => {
      const categories = {
        directors: [],
        shareholders: [],
        ubo: [],
      };

      docs.forEach((doc) => {
        if (doc?.checkedList?.directors || doc?.checkedList?.reserve)
          categories.directors.push(doc);
        if (doc?.checkedList?.nominee || doc?.checkedList?.shareholders)
          categories.shareholders.push(doc);
        if (doc?.checkedList?.ubo) categories.ubo.push(doc);
      });

      return categories;
    };

    const processCategory = async (categoryDocs, entityType) => {
      const allData = {
        allDirectors: [],
        allShareholders: [],
        allUbo: [],
      };

      const mapToObj = (oneDoc, index, type) => ({
        key: index,
        id: oneDoc._id,
        type,
        name:
          type === "individual"
            ? `${oneDoc?.individualArray?.firstName} ${oneDoc?.individualArray?.middleName} ${oneDoc?.individualArray?.lastName}`
            : oneDoc.copEntityName,
        total: oneDoc.totalShares ? oneDoc.noOfShares : "",
        percentage: oneDoc.ownershipPer
          ? `${parseFloat(oneDoc.ownershipPer).toFixed(2)}%`
          : null,
        date: oneDoc.appointment
          ? moment(oneDoc.appointment).format("DD-MMM-YYYY")
          : null,
      });

      const { directors, shareholders, ubo } = categoryDocs;

      await asyncForEach(directors, async (oneDoc, index) => {
        allData.allDirectors.push(mapToObj(oneDoc, index, entityType));
      });

      await asyncForEach(shareholders, async (oneDoc, index) => {
        allData.allShareholders.push(mapToObj(oneDoc, index, entityType));
      });

      await asyncForEach(ubo, async (oneDoc, index) => {
        allData.allUbo.push(mapToObj(oneDoc, index, entityType));
      });

      return allData;
    };

    const individual = processDocuments(individualDetails);
    const copEntity = processDocuments(copEntityDetails);
    console.log(individual, individual, "rsytuio;l");
    const individualData = await processCategory(individual, "individual");
    const copEntityData = await processCategory(copEntity, "copEntity");
    const { allDirectors, allShareholders, allUbo } = {
      allDirectors: [
        ...individualData.allDirectors,
        ...copEntityData.allDirectors,
      ],
      allShareholders: [
        ...individualData.allShareholders,
        ...copEntityData.allShareholders,
      ],
      allUbo: [...individualData.allUbo, ...copEntityData.allUbo],
    };

    console.log(allDirectors, allShareholders, allUbo, "categories");

    setIndividualCategories(individual);
    setCopEntityCategories(copEntity);
    setDirectorData(allDirectors);
    setShareholderData(allShareholders);
    setUboData(allUbo);
  };

  const directorCol = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Appointment Date",
      dataIndex: "date",
    },
  ];

  // const directorData = [
  //   {
  //     key: "1",
  //     id: "1",
  //     name: "Mr. John Doe",
  //     date: "28 May 2024",
  //   },
  //   {
  //     key: "1",
  //     id: "1",
  //     name: "Mr. John Doe",
  //     date: "28 May 2024",
  //   },
  //   {
  //     key: "1",
  //     id: "1",
  //     name: "Mr. John Doe",
  //     date: "28 May 2024",
  //   },
  // ];

  const shareholderCol = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Total Shares Held",
      dataIndex: "total",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
    },
  ];

  // const shareholderData = [
  //   {
  //     key: "1",
  //     id: "1",
  //     name: "Mrs. Sophia",
  //     total: "25,000",
  //     percentage: "50%",
  //   },
  //   {
  //     key: "1",
  //     id: "1",
  //     name: "Mrs. Sophia",
  //     total: "15,000",
  //     percentage: "30%",
  //   },
  //   {
  //     key: "1",
  //     id: "1",
  //     name: "Mrs. Sophia",
  //     total: "10,000",
  //     percentage: "20%",
  //   },
  // ];
  // const downloadPDF = async () => {
  //   const data = pdfRef.current;
  //   const specificDate = moment(Date.now()).format("YYYY-MM-DD:HH:mm:ss");
  //   const options = {
  //     margin: 0.5,
  //     filename: `CorporateEntityAnalysisReport-${specificDate}.pdf`,
  //     image: { type: "jpeg", quality: 0.98 },
  //     html2canvas: { dpi: 192, letterRendering: true },
  //     jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
  //     pagebreak: { mode: ["avoid-all", "css", "legacy"] },
  //   };

  //   try {
  //     let doc = {};
  //     const pdfBlob = await html2pdf()
  //       .from(data)
  //       .set(options)
  //       // .outputPdf("blob");
  //       downloadFile(pdfBlob)
  //

  //   } catch (error) {

  //
  //   }
  // }

  const generatePdf = () => {
    const element = pdfRef.current;
    const opt = {
      margin: 0.5,
      filename: "certificate-of-incumbency.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 4, logging: true, dpi: 192, letterRendering: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    html2pdf().from(element).set(opt).save();
    return opt;
  };

  const upDateSignature = () => {
    Modal.confirm({
      title: "Confirmation Required",
      content: "Please confirm if you would like to update your signature.",
      okText: "Yes",
      onOk: () => cleardata(),
      cancelText: "No",
    });
  };
  const cleardata = () => {
    setvalue(false);
  };
  return (
    <div>
      <div className="page-header">
        <h1>Certificate of Incumbency</h1>
        <button className="primary-btn">Execute and Send to client</button>
      </div>
      <div className="page-content">
        <div className="pdf-zoom-wrapper">
          <TransformWrapper initialScale={1} centerOnInit>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <div>
                <Controls
                  generatePdf={generatePdf}
                  signature={`data:image/png;base64, ${value.url}`}
                />
                <TransformComponent wrapperClass="w-100 pdf-transform">
                  <div className="custom-pdf-wrapper" ref={pdfRef}>
                    <h2>Certificate of Incumbency</h2>
                    <span className="custom-badge-light">Sample Entity</span>
                    <p>
                      Incorporated in the British Virgin Islands{" "}
                      <span className="custom-badge-light">026739289</span>
                    </p>
                    <hr />
                    <p>
                      I, <span className="custom-badge-light">John Doe</span>{" "}
                      the undersigned, being the Director of{" "}
                      <span className="custom-badge-light">Sample Entity</span>{" "}
                      a company incorporated in the British Virgin Islands with
                      company number{" "}
                      <span className="custom-badge-light">026739289</span> do
                      hereby certify as follows:
                    </p>
                    <h6>1. Directors</h6>
                    <p>
                      As of the date hereof, the following persons/entities are
                      the duly appointed directors of the company:
                    </p>
                    <Table
                      className="pdf-table"
                      columns={directorCol}
                      dataSource={directorData}
                      pagination={false}
                    />
                    <br />
                    <h6>2. Shareholders</h6>
                    <p>
                      As of the date hereof, the following persons/entities are
                      the duly appointed directors of the company:
                    </p>
                    <Table
                      className="pdf-table"
                      columns={shareholderCol}
                      dataSource={shareholderData}
                      pagination={false}
                    />
                    <br />
                    <p>
                      <b>IN WITNESS WHEREOF,</b> I have hereunto set my hand and
                      affixed the seal of the company this day.
                    </p>
                    {value?.url ? (
                      <div
                        className="signature-wrapper w-50"
                        onClick={upDateSignature}
                      >
                        {" "}
                        <img
                          src={`data:image/png;base64, ${value.url}`}
                          alt="Signature"
                        />{" "}
                      </div>
                    ) : (
                      <div className="signature-content">
                        {/* <b>[Signature]</b> */}
                        <div className="add-signature-badge">Add Signature</div>
                        <div
                          className="signature-wrapper"
                          onClick={() => setSignModal(true)}
                        >
                          <span className="icon">
                            <i className="fal fa-signature"></i>
                          </span>
                          <label htmlFor="" className="custom-label req">
                            Click here to sign
                          </label>
                        </div>
                      </div>
                    )}

                    <p>
                      <b>{value?.fullName ? value.fullName : ""} </b> <br />{" "}
                      {value?.jobTitle ? value.jobTitle : ""}
                    </p>
                  </div>
                </TransformComponent>
              </div>
            )}
          </TransformWrapper>
        </div>
      </div>
      <AddSignature
        open={signModal}
        setvalue={setvalue}
        onOk={() => setSignModal(false)}
        onCancel={() => setSignModal(false)}
      />
    </div>
  );
}
