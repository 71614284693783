import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  Select,
  DatePicker,
  Checkbox,
  Switch,
  Spin,
  Modal,
  TreeSelect,
} from "antd";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { getEntity } from "../../store/entity/entitySlice";

import FormZ from "../../components/FormZ";
import CorporateEntity from "./components/corporate-entity";
import Individual from "./components/individual";
import DetailedHistory from "./components/detailed-history";
import RegisterOfCharges from "./components/register-of-charges";
import {
  sendNotify,
  getOptions,
  focusOn,
  Each,
  fetchApi,
  convertQueryParams,
  getQuery,
} from "../../helper";

import dayjs from "dayjs";

import EntityOverviewList from "./list";

import countries from "../../helper/countries.json";

const { SHOW_PARENT } = TreeSelect;

export default function EntityOverview() {
  let query = getQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector((state) => state.country);
  const entity = useSelector((state) => state.entity);

  const [reminderCount, setReminderCount] = useState([0]);
  const [bossFilingCount, setBossFilingCount] = useState([0]);
  const [viewModal, setViewModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [objData, setObjData] = useState({});
  const [countriesOption, setCountriesOption] = useState([]);
  const [value, setValue] = useState([]);
  const [feeCategoryArray, setFeeCategoryArray] = useState([]);
  const [feeSubCategoryArray, setFeeSubCategoryArray] = useState([]);
  const [bossFilingDate, setBossFilingDate] = useState([]);
  const [reminderDate, setReminderDate] = useState([]);
  const [entityArray, setEntityArray] = useState([]);
  const [entityName, setEntityName] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [loading, setLoading] = useState(false);

  const [customContact, setCustomContact] = useState([
    {
      id: "CC1",
      data: {
        name: "",
        mobile: "",
        email: "",
      },
    },
  ]);
  const [cddInformation, setCDDinformation] = useState({});
  const [locReg, setlocReg] = useState({});
  const [locFinancial, setLocFinancial] = useState({});
  const [customFinancial, setCustomFinancial] = useState([
    {
      id: "FD1",
      data: {
        title: "",
        recordHolder: "",
        email: "",
        mobile: "",
      },
    },
  ]);

  useEffect(() => {
    let params = { enId: query.id, _start: 0, _limit: 1 };
    if (query.id) {
      dispatch(getEntity({ id: query.id }));
    }
    convertToOptions();
    handleRefresh();
  }, []);

  useEffect(() => {
    getDataHandleRefresh();
  }, [entity]);

  const getFeeSubCategory = async (catId, type) => {
    if (catId) {
      let queryParams = { catId: catId };
      let query = convertQueryParams(queryParams);
      let obj = {
        method: "GET",
        url: `/feeCategory/sub/?${query}`,
      };

      let subData = await fetchApi(obj).then((res) => {
        return res.data.data;
      });
      if (subData) {
        let sublist = subData
          ? subData?.map((d) => {
              let obj = {
                value: d?._id,
                label: d?.catType,
              };
              return obj;
            })
          : [];
        setFeeSubCategoryArray(sublist);
      }
    }
  };
  const getDataHandleRefresh = () => {
    setLoading(true);
    if (entity?.data?.rows) {
      console.log(entity?.data?.rows[0], "asdfghjkldfghjk");
      setFormData(entity?.data?.rows[0]);
      setSelectedId(query?.id ? query.id : entity?.data?.rows[0]?._id);
      setCustomContact(entity?.data?.rows[0]?.primaryContact ?? {});
      setlocReg(entity?.data?.rows[0]?.registers?.address ?? {});
      setLocFinancial(entity?.data?.rows[0]?.financialRecords?.address ?? {});
      setCustomFinancial(
        entity?.data?.rows[0]?.financialRecords?.details ?? {}
      );
      setCDDinformation(entity?.data?.rows[0]?.cddInformation ?? {});

      setReminderCount(entity?.data?.rows[0]?.cddInformation?.reminderDate);
      setBossFilingCount(entity?.data?.rows[0]?.cddInformation?.filingDate);
    }
    setLoading(false);
  };

  const convertToOptions = () => {
    let options = [
      {
        title: "Worldwide",
        value: "0",
        key: "0",
        children: [],
      },
    ];

    Object.keys(countries).forEach(function (key, index) {
      let continents = {
        title: key,
        value: `0-${index}`,
        key: `0-${index}`,
        children: [],
      };
      countries[key].forEach((country, i) => {
        let cat = {
          title: country?.name,
          value: `0-${index}-${i}`,
          key: `0-${index}-${i}`,
        };
        continents.children.push(cat);
      });

      options[0]?.children.push(continents);
    });

    setCountriesOption(options);
  };

  const onChange = (newValue) => {
    setValue(newValue);
  };

  const handleRefresh = async () => {
    let payload = {
      method: "GET",
      url: `/feeCategory?_start=0&_limit=1000`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res.data.rows;
    });
    setFeeCategoryArray(data);

    let params = {};
    params.isAll = true;
    params.masterType = "entity";
    params.options = true;
    let query = convertQueryParams(params);
    let entPayload = {
      method: "GET",
      url: `/client?${query}`,
    };
    let clientlist = await fetchApi(entPayload).then((res) => {
      return res?.data?.rows;
    });

    let paramsName = {};
    paramsName.isAll = true;
    paramsName.masterType = "entity";
    paramsName.exportAll = true;

    let queryparams = convertQueryParams(paramsName);
    let entNamePayload = {
      method: "GET",
      url: `/client?${queryparams}`,
    };
    let clientName = await fetchApi(entNamePayload).then((res) => {
      return res?.data?.rows;
    });

    let namelist = data
      ? clientName?.map((d) => {
          let obj = {
            value: d?.name,
            label: d?.name,
          };
          return obj;
        })
      : [];
    setEntityName(namelist);
    setEntityArray(clientlist);
  };

  const findOneEntity = async (value) => {
    setLoading(true);
    let ctr = {};
    if (value.code) {
      if (value.code.startsWith("EN")) {
        ctr.code = value.code;
      } else {
        ctr.id = value.code;
      }
    } else {
      ctr = value;
    }

    let params = convertQueryParams(ctr);
    let payload = {
      method: "GET",
      url: `/entity?${params}`,
    };
    let entity = await fetchApi(payload).then((res) => {
      return res?.data?.rows;
    });

    console.log(entity, "zdsfghjk");

    if (entity?.length) {
      console.log(entity?.[0], "asdfghjkldfghjk");
      setFormData(entity?.[0]);
      setSelectedId(query?.id ? query.id : entity?.[0]?._id);
      setCustomContact(entity?.[0]?.primaryContact ?? {});
      setlocReg(entity?.[0]?.registers?.address ?? {});
      setLocFinancial(entity?.[0]?.financialRecords?.address ?? {});
      setCustomFinancial(entity?.[0]?.financialRecords?.details ?? {});
      setCDDinformation(entity?.[0]?.cddInformation ?? {});
      setReminderCount(entity?.[0]?.cddInformation?.reminderDate);
      setBossFilingCount(entity?.[0]?.cddInformation?.filingDate);
    }
    if (entity?.[0].feeCat) {
      getFeeSubCategory(entity?.[0].feeCat, entity?.[0].feeType);
    }
    setLoading(false);
  };

  const handleOnChange = async (value, fname) => {
    let data = { ...objData };
    setFormData(value);
    let { feeCat, feeType, code, name } = value;
    console.log(name, code, "xbnmsfdghj");
    if (fname == "code") {
      findOneEntity({ code: code });
      setSelectedId(code);
    }
    if (fname == "name") {
      findOneEntity({ name: name });
      // dispatch(getEntity({ name: name }));

      // let findId = entityArray.find((a) => a.label == name);
      // setSelectedId(findId.value);
    }
    console.log(value, "aasfdhyjukl");

    // if (feeCat && feeType) {
    //   console.log(value, "aasfdhyjukl-1");
    //   let params = { catId: feeCat };
    //   console.log(params, "dfghjgklsf");
    //   let query = convertQueryParams(params);
    //   let payload = {
    //     method: "GET",
    //     url: `/feeCategory/sub/?${query}`,
    //   };
    //   let data = await fetchApi(payload).then((res) => {
    //     return res.data.data;
    //   });

    //   let sublist = data
    //     ? data?.map((d) => {
    //         let obj = {
    //           value: d?._id,
    //           label: d?.catType,
    //         };
    //         return obj;
    //       })
    //     : [];

    //   console.log(sublist, "sublist");
    //   setFeeSubCategoryArray(sublist);
    // }

    setObjData(value);
  };
  const locationOfRegistersOnChange = async (value, name) => {
    setlocReg(value);
  };
  const locationOfFinancialOnChange = async (value, name) => {
    setLocFinancial(value);
  };
  const cddInformationOnChange = async (value, name) => {
    setCDDinformation(value);
  };
  let list = feeCategoryArray.map((d) => {
    let obj = {
      value: d?._id,
      label: d?.name,
    };
    return obj;
  });

  const tProps = {
    treeData: countriesOption,
    value,
    onChange,
    className: "custom-select w-100 ptb-1",
    treeCheckable: true,
    showSearch: false,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: "Select geography of business",
    allowClear: true,
  };

  const [forms, setForms] = useState({
    individual: [1],
    directors: [1],
    shareholders: [1],
    ubo: [1],
    financialRecord: [],
  });
  const getReminderCount = (value) => {
    let arr = [];

    for (var i = 0; i < value; i++) {
      arr.push(i);
    }
    setReminderCount(arr);
  };

  const getBossFilingCount = (value) => {
    let arr = [];

    for (var i = 0; i < value; i++) {
      arr.push(i);
    }
    setBossFilingCount(arr);
  };

  const addNewCol = (key, { focus }) => {
    let obj = { ...forms };
    let arr = [...obj[key]];
    arr.push(arr.length + 1);
    obj[key] = arr;
    setForms(obj);
    console.log(focus);
    if (focus) {
      focusOn(key + (arr.length - 1));
    }
  };

  const removeNewCol = (key, index) => {
    let obj = { ...forms };
    let arr = [...obj[key]];
    arr.splice(index, 1);
    obj[key] = arr;
    setForms(obj);
  };

  const entityFormRef = useRef();
  const primaryContactFormRef = useRef();
  const customContactFormRef = useRef([]);
  const customFinancialFormRef = useRef([]);
  const locationRegistersFormRef = useRef();
  const locationFinancialFormRef = useRef();
  const contactFormRef = useRef();
  const cddInformationFormRef = useRef();

  let entityFormSchema = [
    {
      name: "code",
      value: "",
      type: "select",
      label: "Entity Code",
      placeholder: "Enter entity code",
      showSearch: true,
    },
    {
      name: "name",
      value: "",
      type: "select",
      label: "Entity Name",
      placeholder: "Enter entity name",
      showSearch: true,
      //  disabled: true,
    },
    {
      name: "type",
      value: "trust",
      type: "select",
      label: "Entity Type",
      placeholder: "Select entity type",
      options: [
        {
          value: "BVIBC",
          label: "BVIBC",
        },
        {
          value: "trust",
          label: "Trust",
        },
        {
          value: "foundation",
          label: "Foundation",
        },
        {
          value: "general-partnership",
          label: "General Partnership",
        },
        {
          value: "limited-partnership",
          label: "Limited Partnership",
        },
      ],
      disabled: true,
    },

    {
      name: "status",
      value: "active",
      type: "select",
      label: "Entity Status",
      placeholder: "Select entity status",
      options: [
        {
          value: "active",
          label: "Active",
        },
        {
          value: "inactive",
          label: "Inactive",
        },
        {
          value: "closing",
          label: "Closing",
        },
        {
          value: "closed",
          label: "Closed",
        },
        {
          value: "transferringOut",
          label: "Transferring Out",
        },
        {
          value: "prospect",
          label: "Prospect",
        },
        {
          value: "transferringIn",
          label: "Transferring In",
        },
        {
          value: "liquidated",
          label: "Liquidated",
        },
        {
          value: "struckOff",
          label: "Struck Off",
        },
        {
          value: "resigned",
          label: "Resigned",
        },
        {
          value: "continuationOut",
          label: "Continuation Out",
        },
        {
          value: "continuationIn",
          label: "Continuation In",
        },
      ],
    },
    // {
    //   name: "feeScheduleName",
    //   value: "Sample",
    //   type: "text",
    //   label: "Fee Schedule Name",
    //   placeholder: "Enter fee schedule name",
    // },
    // {
    //   name: "feeScheduleSubType",
    //   value: ["s1", "d1"],
    //   type: "select",
    //   label: "Fee Schedule Sub Type",
    //   placeholder: "Enter fee schedule sub type",
    //   options: [
    //     {
    //       value: "s1",
    //       label: "S1",
    //     },
    //     {
    //       value: "s2",
    //       label: "S2",
    //     },
    //     {
    //       value: "d1",
    //       label: "D1",
    //     },
    //     {
    //       value: "d2",
    //       label: "D2",
    //     },
    //   ],
    //   mode: "multiple",
    //   inputClass: "ptb-1",
    //   allowClear: true,
    // },
    // {
    //   name: "paymentCategory",
    //   value: "prePaid",
    //   type: "select",
    //   label: "Payment Category",
    //   placeholder: "Enter payment category",
    //   options: [
    //     {
    //       value: "Pre-Paid",
    //       label: "Pre-paid",
    //     },
    //     {
    //       value: "Post-Paid",
    //       label: "Post-paid",
    //     },
    //   ],
    // },
    {
      name: "feeCat",
      value: "",
      type: "select",
      label: "Fee Schedule Name",
      placeholder: "Select Fee Schedule Name",
      options: getOptions(list),
      loading: isLoading,
      disabled: true,
    },
    {
      name: "feeType",
      value: "",
      type: "select",
      label: "Fee Type",
      placeholder: "Select fee type",
      options: [
        {
          value: "Pre-Paid",
          label: "Pre-paid",
        },
        {
          value: "Post-Paid",
          label: "Post-paid",
        },
      ],
      disabled: true,
    },
    {
      name: "subCat",
      value: "",
      type: "select",
      label: "Fee Sub Category",
      placeholder: "Select fee sub-category",
      // mode: "multiple",
      inputClass: "ptb-1",
      allowClear: true,
      disabled: true,
    },

    {
      name: "clientName",
      value: "John Doe",
      type: "text",
      label: "Client Name",
      placeholder: "Enter client name",
      disabled: true,
    },
    {
      name: "clientCode",
      value: "C040",
      type: "text",
      label: "Client Code",
      placeholder: "Enter client code",
      disabled: true,
    },
    {
      name: "clientType",
      value: "Trust",
      type: "select",
      label: "Client Type",
      placeholder: "Enter client type",
      options: [
        {
          value: "IntroducerClient",
          label: "Introducer Client",
        },
        {
          value: "endUserClient",
          label: "End User Client",
        },
      ],
      disabled: true,
    },
    {
      name: "clientStatus",
      value: "active",
      type: "select",
      label: "Client Status",
      placeholder: "Select client status",
      options: [
        {
          value: "active",
          label: "Active",
        },
        {
          value: "inactive",
          label: "Inactive",
        },
      ],
      disabled: true,
    },
    {
      name: "parentClientCode",
      value: "John Wick",
      type: "text",
      label: "Parent Client Code",
      placeholder: "Enter parent client code",
      disabled: true,
    },
    {
      name: "parentClientName",
      value: "PC020",
      type: "text",
      label: "Parent Client Name",
      placeholder: "Enter parent client name",
      disabled: true,
    },
    {
      name: "parentClientStatus",
      value: "active",
      type: "select",
      label: "Parent Client Status",
      placeholder: "Select parent client status",
      options: [
        {
          value: "active",
          label: "Active",
        },
        {
          value: "inactive",
          label: "Inactive",
        },
      ],
      disabled: true,
    },
    {
      name: "incorporationNumber",
      value: "04638274",
      type: "text",
      label: "Incorporation Number",
      placeholder: "Enter incorporation number",
      disabled: true,
    },
    {
      name: "jurisdiction",
      value: "BVI",
      type: "select",
      label: "Jurisdiction",
      placeholder: "Select jurisdiction",
      options: getOptions(data),
      loading: isLoading,
      disabled: true,
    },
    {
      name: "incorporationDate",
      value: dayjs("22/12/2023", "DD/MM/YYYY"),
      type: "date",
      label: "Incorporation Date",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
      disabled: true,
    },
    // {
    //   name: "entityGrouping",
    //   value: "Random",
    //   type: "text",
    //   label: "Entity Grouping",
    //   placeholder: "Enter entity grouping",
    // },
    {
      name: "authorisedShares",
      value: "",
      type: "number",
      label: "Authorised Shares",
      placeholder: "Enter Authorised Shares",
      customClass: "hide-arrows",
      disabled: true,
    },
    // {
    //   name: "relatedEntities",
    //   value: "E002, E003",
    //   type: "text",
    //   label: "Related Entities",
    //   placeholder: "Enter related entities",
    //   disabled: true,
    // },
    {
      name: "relationshipManager",
      value: "",
      type: "text",
      label: "Relationship Manager",
      placeholder: "Enter Relationship Manager",
    },
    {
      name: "YearOfEndDate",
      value: "",
      type: "date",
      label: "Year Of End Date",
      placeholder: "Enter Date (DD/MM/YYYY)",
      //format: "DD/MM/YYYY",
    },
    // {
    //   name: "compositeRiskLevel",
    //   value: "high",
    //   type: "select",
    //   label: "Composite Risk Level",
    //   placeholder: "Select composite risk level",
    //   options: [
    //     {
    //       value: "low",
    //       label: "Low",
    //     },
    //     {
    //       value: "medium",
    //       label: "Medium",
    //     },
    //     {
    //       value: "high",
    //       label: "High",
    //     },
    //   ],
    // },
    // {
    //   name: "corporateEntity",
    //   value: "yes",
    //   type: "select",
    //   label: "Corporate Entity",
    //   placeholder: "Select corporate entity",
    //   options: [
    //     {
    //       value: "yes",
    //       label: "Yes",
    //     },
    //     {
    //       value: "no",
    //       label: "No",
    //     },
    //   ],
    // },
  ];

  let customContactFormSchema = [
    {
      name: "name",
      value: "",
      type: "text",
      label: "Primary Contact Name",
      placeholder: "Enter primary contact name",
    },
    {
      name: "email",
      value: "",
      type: "email",
      label: "Primary Contact Email",
      placeholder: "Enter primary contact email",
    },
    {
      name: "mobile",
      value: "",
      type: "text",
      label: "Primary Contact Number",
      placeholder: "Enter primary contact",
    },
  ];

  let locationRegistersFormSchema = [
    {
      name: "line_1",
      value: "",
      type: "textarea",
      label: "Address Line 01",
      placeholder: "Enter address here...",
    },
    {
      name: "line_2",
      value: "",
      type: "textarea",
      label: "Address Line 02",
      placeholder: "Enter address here...",
    },
    {
      name: "line_3",
      value: "",
      type: "textarea",
      label: "Address Line 03",
      placeholder: "Enter address here...",
    },
  ];

  let locationFinancialFormSchema = [
    {
      name: "line_1",
      value: "",
      type: "textarea",
      label: "Address Line 01",
      placeholder: "Enter address here...",
    },
    {
      name: "line_2",
      value: "",
      type: "textarea",
      label: "Address Line 02",
      placeholder: "Enter address here...",
    },
    {
      name: "line_3",
      value: "",
      type: "textarea",
      label: "Address Line 03",
      placeholder: "Enter address here...",
    },
  ];

  let contactFormSchema = [
    {
      name: "title",
      value: "",
      type: "select",
      label: "Title",
      placeholder: "Select title",
      options: [
        {
          value: "mr",
          label: "Mr",
        },
        {
          value: "mrs",
          label: "Mrs",
        },
        {
          value: "miss",
          label: "Miss",
        },
        {
          value: "ms",
          label: "Ms",
        },
        {
          value: "dr",
          label: "Dr",
        },
        {
          value: "rev",
          label: "Rev",
        },
      ],
      customClass: "col-md-2",
    },
    {
      name: "recordHolder",
      value: "",
      type: "text",
      label: "Name of Financial Record Holder",
      placeholder: "Enter name",
    },
    {
      name: "email",
      value: "",
      type: "email",
      label: "Email",
      placeholder: "Enter email",
    },
    {
      name: "mobile",
      value: "",
      type: "text",
      label: "Number",
      placeholder: "Enter number",
    },
    // {
    //   customElement: (
    //     <button
    //       className="primary-btn mb-15 justify-content-center w-70"
    //       onClick={() => addNewCol("financialRecord", { focus: false })}
    //     >
    //       <i className="fal fa-plus-circle"></i> Add New
    //     </button>
    //   ),
    // },
  ];

  let cddInformationFormSchema = [
    {
      name: "wealth",
      value: "",
      type: "textarea",
      label: "Source of Wealth",
      placeholder: "Enter here...",
      customClass: "col-md-10",
    },
    {
      name: "funds",
      value: "",
      type: "textarea",
      label: "Source of Funds",
      placeholder: "Enter here...",
      customClass: "col-md-10",
    },
    {
      name: "business",
      value: "",
      type: "textarea",
      label: "Purpose of Business",
      placeholder: "Enter here...",
      customClass: "col-md-10",
    },
    {
      label: "Geography of Business",
      customElement: <TreeSelect {...tProps} placement="topLeft" />,
    },
    {
      name: "activity",
      value: "",
      type: "select",
      label: "Business Activity",
      placeholder: "Select",
      options: [
        {
          value: "manufacturing",
          label: "Manufacturing",
        },
        {
          value: "retail",
          label: "Retail",
        },
        {
          value: "wholesale",
          label: "Wholesale",
        },
        {
          value: "services",
          label: "Services",
        },
        {
          value: "agriculture",
          label: "Agriculture",
        },
        {
          value: "realEstate",
          label: "Real Estate",
        },
        {
          value: "construction",
          label: "Construction",
        },
        {
          value: "transportation",
          label: "Transportation",
        },
        {
          value: "healthcare",
          label: "Healthcare",
        },
        {
          value: "education",
          label: "Education",
        },
        {
          value: "financialServices",
          label: "Financial Services",
        },
        {
          value: "informationTechnology",
          label: "Information Technology",
        },
        {
          value: "entertainment",
          label: "Entertainment",
        },
        {
          value: "hospitality",
          label: "Hospitality",
        },
        {
          value: "utilities",
          label: "Utilities",
        },
        {
          value: "miningAndDrilling",
          label: "Mining and Drilling",
        },
        {
          value: "rAndD",
          label: "R&D",
        },
        {
          value: "nonProfit",
          label: "Non-Profit",
        },
        {
          value: "eCommerce",
          label: "E-commerce",
        },
        {
          value: "freelancing",
          label: "Freelancing",
        },
        {
          value: "gambling",
          label: "Gambling",
        },
        {
          value: "other",
          label: "Other",
        },
      ],
    },
    {
      name: "description",
      value: "",
      type: "text",
      label: "Description of Business",
      placeholder: "Enter description of your business",
    },

    {
      name: "",
      value: "",
      type: "",
      label: "",
      customElement: <></>,
    },
    {
      name: "lastReviewed",
      value: "",
      type: "date",
      label: "Entity Last Reviewed Date",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "nextReviewed",
      value: "",
      type: "date",
      label: "Entity Next Reviewed Date",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "riskRating",
      value: "",
      type: "select",
      label: "Overall Entity Risk Level",
      placeholder: "Select risk level",
      options: [
        {
          value: "low",
          label: "Low",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "high",
          label: "High",
        },
      ],
    },
  ];

  const dropDownOnClick = async (value) => {
    let params = {};
    params.isAll = true;
    params.masterType = "entity";
    params.code = value;
    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/client?${query}`,
    };
    let clientData = await fetchApi(payload).then((res) => {
      return res?.data;
    });
    // setIndividualData(clientData.rows)
    let list = clientData.rows.map((d) => {
      let obj = {
        value: d?._id,
        label: d?.code,
      };
      return obj;
    });

    setEntityArray(list);
  };

  const onUpdateClick = () => {
    setViewModal(true);
  };

  const handleOk = () => {
    setViewModal(false);
    navigate("/app/entity-overview/list");
  };

  const handleCancel = () => {
    setViewModal(false);
  };

  const addNewContact = () => {
    let arr = [...customContact];
    arr.push({ id: `CC${arr.length + 1}` });
    setCustomContact(arr);
  };

  const deleteCustomContact = (item) => {
    let arr = [...customContact];
    let index = arr.findIndex((cc) => cc.id == item);
    arr.splice(index, 1);
    setCustomContact(arr);
  };

  function getFormOnChange(value, item, index) {
    let arr = [...customContact];
    //let index = arr.findIndex((cc) => cc.id == item);
    arr[index] = {
      id: item,
      data: value,
    };
    setCustomContact(arr);
  }

  const addNewFinancial = () => {
    let arr = [...customFinancial];
    arr.push({ id: `FD${arr.length + 1}` });
    setCustomFinancial(arr);
  };

  const deleteCustomFinancial = (item) => {
    let arr = [...customFinancial];
    let index = arr.findIndex((cc) => cc.id == item);
    arr.splice(index, 1);
    setCustomFinancial(arr);
  };

  function getFinancialOnChange(value, item, index) {
    let arr = [...customFinancial];
    // let index = arr.findIndex((cc) => cc.id == item);
    arr[index] = {
      id: item,
      data: value,
    };
    console.log(arr);
    setCustomFinancial(arr);
  }

  const filingonChange = (value, index) => {
    let information = { ...cddInformation };
    if (!Array.isArray(information.filingDate)) {
      information.filingDate = [];
    }
    information.filingDate.push({ no: index + 1, data: value ? value : "" });
    information.filing = information.filingDate.length;
    setCDDinformation(information);
  };

  const reminderChange = (value, index) => {
    let information = { ...cddInformation };

    if (!Array.isArray(information.reminderDate)) {
      information.reminderDate = [];
    }
    const updatedReminderDate = [...information.reminderDate];
    updatedReminderDate[index] = {
      ...updatedReminderDate[index],
      data: value ? value.toISOString() : "",
    };

    information.reminderDate = updatedReminderDate;
    information.reminder = updatedReminderDate.length;

    setCDDinformation(information);
  };
  const onSubmit = async () => {
    try {
      const data = {
        ...formData,
        ...(customContact && { primaryContact: customContact }),
        ...(locReg && { registers: { address: locReg } }),
        ...(locFinancial && {
          financialRecords: {
            ...(customFinancial
              ? { address: locFinancial, details: customFinancial }
              : { address: locFinancial }),
          },
        }),
        ...(customFinancial &&
          !locFinancial && { financialRecords: { details: customFinancial } }),
        ...(cddInformation && { cddInformation: cddInformation }),
      };

      const payload = {
        method: "PUT",
        url: `/entity/update/${selectedId}`,
        data,
      };

      const res = await fetchApi(payload);
      sendNotify("success", res?.message);
    } catch (error) {
      console.error(error);
      sendNotify("error", error?.message);
    }
  };

  return (
    <div>
      <div className="page-header">
        <h1>Entity Overview</h1>
        {/* <Link to={"/app/entity-overview/list"} className="primary-btn">
          Update
        </Link> */}
        <Link
          to={`/app/organization-structure?id=${query.id ?? selectedId}`}
          className="primary-btn ms-auto"
        >
          <i class="far fa-folder-tree"></i>
          Organization Structure
        </Link>
      </div>
      <Spin spinning={loading}>
        <div className="page-content">
          <div className="row">
            <div className="col-lg-9">
              <h4 className="primary-title mb-4">Entity</h4>
              <FormZ
                ref={entityFormRef}
                formSchema={entityFormSchema}
                formData={formData}
                onChange={handleOnChange}
                onKeyUp={(e) => dropDownOnClick(e.target.value)}
                formOptions={{
                  subCat: feeSubCategoryArray,
                  code: entityArray,
                  name: entityName,
                }}
                formClass="row custom-form mb-4 mb-md-5 gy-3 gx-4"
                childClass="col-md-4 mb-0"
                labelClass="mb-1"
                inputClass="w-100"
              />
              <div className="mb-4">
                <h4 className=" primary-title">Primary Contact</h4>
                <div className="mt-3 border-box mb-4">
                  <Each
                    array={customContact}
                    render={(item, index) => (
                      <div
                        className="row align-items-end my-3 gap-ms-2"
                        key={index}
                      >
                        <div className="col-md-9">
                          <FormZ
                            ref={(el) =>
                              (customContactFormRef.current[index] = el)
                            }
                            formSchema={customContactFormSchema}
                            formData={customContact[index]?.data}
                            formClass="row custom-form gy-3"
                            onChange={(value) =>
                              getFormOnChange(value, item?.id, index)
                            }
                            childClass="col-md-4 mb-0"
                            labelClass="mb-1"
                            inputClass="w-100"
                          />
                        </div>
                        <div className="col-md-3">
                          <div className="d-flex gap-3">
                            <button
                              className="outline-btn red-btn"
                              onClick={() => deleteCustomContact(item?.id)}
                              disabled={index == 0 && customContact.length == 1}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  />
                  <div className="d-flex gap-3">
                    <button className="primary-btn mb-15" onClick={onSubmit}>
                      Update
                    </button>
                    <button className="primary-btn" onClick={addNewContact}>
                      <i className="fal fa-plus"></i> Add New Contact
                    </button>
                  </div>
                </div>
              </div>
              <h4 className="primary-title mb-4">Location of Registers</h4>
              <div className="border-box mb-4">
                <FormZ
                  ref={locationRegistersFormRef}
                  formSchema={locationRegistersFormSchema}
                  formData={locReg}
                  onChange={locationOfRegistersOnChange}
                  //onChange={(e) => handleOnChange(e, "locationOfRegisters")}
                  formClass="custom-form mb-5"
                  labelClass="mb-1"
                  inputClass="w-100"
                />
              </div>
              <h4 className="primary-title mb-4">
                Location of Financial Records
              </h4>
              <div className="border-box">
                <FormZ
                  ref={locationFinancialFormRef}
                  formSchema={locationFinancialFormSchema}
                  formData={locFinancial}
                  // onChange={(e) => handleOnChange(e, "locationOfFinancial")}
                  onChange={locationOfFinancialOnChange}
                  formClass="custom-form"
                  labelClass="mb-1"
                  inputClass="w-100"
                />

                <div className="mb-3">
                  <Each
                    array={customFinancial}
                    render={(item, index) => (
                      <div
                        className="row mt-3 align-items-center gap-ms-2"
                        key={index}
                      >
                        <div className="col-md-11">
                          <FormZ
                            ref={(el) =>
                              (customFinancialFormRef.current[index] = el)
                            }
                            formSchema={contactFormSchema}
                            formData={customFinancial[index]?.data}
                            formClass="row custom-form gy-3"
                            onChange={(value) =>
                              getFinancialOnChange(value, item?.id, index)
                            }
                            childClass="col-md-3"
                            labelClass="mb-1"
                            inputClass="w-100"
                          />
                        </div>
                        <div className="col-md-1">
                          <div className="d-flex gap-3">
                            <button
                              className="outline-btn red-btn"
                              onClick={() => deleteCustomFinancial(item?.id)}
                              disabled={
                                index == 0 && customFinancial.length == 1
                              }
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  />
                  <div className="d-flex gap-3">
                    <button className="primary-btn mb-15" onClick={onSubmit}>
                      Update
                    </button>
                    <button className="primary-btn" onClick={addNewFinancial}>
                      <i className="fal fa-plus"></i> Add New Contact
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex my-4 gap-3 align-items-center">
              <h4 className="primary-title">Individuals</h4>
            </div>
            {selectedId &&
              forms.individual.map((a, i) => {
                return (
                  <Individual
                    entityData={formData}
                    index={i}
                    name={a}
                    addNewCol={addNewCol}
                    removeNewCol={removeNewCol}
                    id={selectedId}
                  />
                );
              })}
            {selectedId && (
              <CorporateEntity
                data={data}
                isLoading={isLoading}
                entityData={formData}
                id={selectedId}
              />
            )}
            {selectedId &&
              forms.individual.map((a, i) => {
                return (
                  <DetailedHistory
                    entityData={formData}
                    name={a}
                    id={selectedId}
                  />
                );
              })}
            {selectedId && <RegisterOfCharges selectedId={selectedId} />}

            <h4 className="primary-title mb-4">CDD Information</h4>
            <div className="border-box mb-4">
              <FormZ
                ref={cddInformationFormRef}
                formSchema={cddInformationFormSchema}
                formData={cddInformation}
                //onChange={(e) => handleOnChange(e, "cddInformation")}
                onChange={cddInformationOnChange}
                formClass="custom-form row gy-2"
                childClass="col-md-3"
                labelClass="mb-1"
                inputClass="w-100"
              />
              <div className="custom-form row mt-0 mb-4 gy-2">
                <div className="col-md-3">
                  <div className="input-box w-100">
                    <label htmlFor="" className="mb-1">
                      Reminder
                    </label>

                    <Select
                      className="custom-select w-100"
                      placeholder="Select"
                      defaultValue={cddInformation?.reminder ?? 1}
                      onChange={getReminderCount}
                      options={[
                        {
                          value: 1,
                          label: 1,
                        },
                        {
                          value: 2,
                          label: 2,
                        },
                        {
                          value: 3,
                          label: 3,
                        },
                        {
                          value: 4,
                          label: 4,
                        },
                      ]}
                    />
                  </div>
                  {!reminderCount
                    ? reminderCount?.map((reminder, index) => {
                        return (
                          <div className="w-100 mb-4" key={index}>
                            <label htmlFor="" className="mb-1">
                              Reminder {reminder + 1} Date
                            </label>
                            <DatePicker
                              className="custom-datepicker w-100"
                              placeholder="Enter Date (DD/MM/YYYY)"
                              format={"YYYY-MM-DD"}
                              onChange={(value) => reminderChange(value, index)}
                            />
                          </div>
                        );
                      })
                    : reminderCount
                    ? reminderCount.map((a, index) => {
                        const dateMoment = moment(a.data);
                        const isValidDate = dateMoment?.isValid();
                        return (
                          <div className="w-100 mb-4" key={index}>
                            <label htmlFor="" className="mb-1">
                              Reminder {index + 1} Date
                            </label>
                            <DatePicker
                              className="custom-datepicker w-100"
                              placeholder="Enter Date (DD/MM/YYYY)"
                              value={isValidDate ? dateMoment : null}
                              format={"YYYY-MM-DD"}
                              onChange={(e) => reminderChange(e, index)}
                            />
                          </div>
                        );
                      })
                    : []}
                </div>
                <div className="col-md-3">
                  <div className="input-box w-100">
                    <label htmlFor="" className="mb-1">
                      Boss Filing Date
                    </label>
                    <Select
                      className="custom-select w-100"
                      placeholder="Select"
                      defaultValue={cddInformation.filing ?? 1}
                      onChange={getBossFilingCount}
                      options={[
                        {
                          value: 1,
                          label: 1,
                        },
                        {
                          value: 2,
                          label: 2,
                        },
                        {
                          value: 3,
                          label: 3,
                        },
                        {
                          value: 4,
                          label: 4,
                        },
                      ]}
                    />
                  </div>
                  {!bossFilingCount
                    ? bossFilingCount?.map((reminder, index) => {
                        return (
                          <div className="w-100 mb-4" key={index}>
                            <label htmlFor="" className="mb-1">
                              Boss Filing {index + 1} Date
                            </label>
                            <DatePicker
                              className="custom-datepicker w-100"
                              placeholder="Enter Date (DD/MM/YYYY)"
                              format={"DD/MM/YYYY"}
                              onChange={(value) => filingonChange(value, index)}
                            />
                          </div>
                        );
                      })
                    : bossFilingCount
                    ? bossFilingCount?.map((a, index) => {
                        const dateMoment = moment(a.data);
                        const isValidDate = dateMoment?.isValid();
                        return (
                          <div className="w-100 mb-4" key={index}>
                            <label htmlFor="" className="mb-1">
                              Boss Filing {index + 1} Date
                            </label>
                            <DatePicker
                              className="custom-datepicker w-100"
                              placeholder="Enter Date (DD/MM/YYYY)"
                              value={isValidDate ? dateMoment : null}
                              format={"YYYY-MM-DD"}
                              onChange={(value) => filingonChange(value, index)}
                            />
                          </div>
                        );
                      })
                    : []}
                </div>
                <div className="col-md-12">
                  <button className="primary-btn" onClick={onSubmit}>
                    Update
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div className="row">
                <div className="col-md-11"></div>
                <div className="col-md-1">
                  <div className="d-flex gap-3">
                    {/* <button className="primary-btn" onClick={onUpdateClick}>
                  Update
                </button> */}
                    <button className="primary-btn" onClick={onSubmit}>
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            title={"Entity Overview"}
            className="custom-modal"
            open={viewModal}
            centered={true}
            width={1000}
            onOk={handleOk}
            okText="Confirm Changes"
            onCancel={handleCancel}
          >
            <EntityOverviewList iframe={true} />
          </Modal>
        </div>
      </Spin>
    </div>
  );
}
