import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Checkbox } from "antd";

import SampleDoc from "../assets/pdf/sample.pdf";

import UploadZ from "../components/UploadZ";

import { Each } from "../helper";

const document = [
  {
    url: SampleDoc,
    file: "Other",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: true,
  },
  {
    url: SampleDoc,
    file: "Other",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
  {
    url: SampleDoc,
    file: "Other",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
];

export default function Other() {
  const [docs, setDocs] = useState(document);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="page-header">
        <Link to={"/app/active-requests"}>
          <h1 className="d-flex align-items-center gap-2">
            <i className="fal fa-long-arrow-left"></i> Other
          </h1>
        </Link>
        <button className="primary-btn">Update Entity Overview</button>
      </div>
      <div className="page-content">
        <div className="custom-form row gy-4 mb-5">
          <div className="col-md-3">
            <label htmlFor="">Entity Code</label>
            <span className="light-badge">38202</span>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Entity Name</label>
            <span className="light-badge">Sample Entity</span>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Client Code</label>
            <span className="light-badge">38202</span>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Client Name</label>
            <span className="light-badge">Sample Client</span>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Service Type(s)</label>
            <div className="d-flex gap-2 flex-wrap">
              <span className="light-badge">Reinstatement</span>
              <span className="light-badge">Document Certifications</span>
            </div>
          </div>
        </div>
        <div className="row custom-form gy-2 gx-4">
          <Each
            array={docs}
            render={(item, index) => (
              <div className="col-md-3">
                <div>
                  <label htmlFor="">{item?.file}</label>
                  <UploadZ file={item} />
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
}
