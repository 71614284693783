import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, DatePicker, Modal, Table, Button, Tabs } from "antd";
import socketIOClient from "socket.io-client";
import Jurisdiction from "../components/Jurisdiction";
import FormZ from "../components/FormZ";
import { getEntity } from "../store/entity/entitySlice";
import { getIndividual } from "../store/individual/individualSlice";
import moment from "moment/moment";
import html2pdf from "html2pdf.js";

import {
  sendNotify,
  getOptions,
  fetchApi,
  generateRandom,
  convertQueryParams,
  formatBytes,
  getQuery,
  timeDiff,
} from "../helper";

import SampleDoc from "../assets/pdf/sample.pdf";

import TableSortArrows from "./tableSortArrows";

const socket = socketIOClient(
  process.env.REACT_APP_API_URL.split("/api", 1)[0]
);
async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export default function IndividualAnalysis(props) {
  let {
    actionShow,
    fileCreation,
    setIndividualClicked,
    setselectedPage,
    individualClicked,
    selectedDateId,
  } = props;
  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  const { data, isLoading } = useSelector((state) => state.country);
  const entity = useSelector((state) => state.entity);
  const individual = useSelector((state) => state.individual);
  const [schema1, setSchema1] = useState({});
  const [schema2, setSchema2] = useState({});
  const [schema3, setSchema3] = useState({});
  const [latDoc, setLatDoc] = useState("");

  const [masterId, setMasterId] = useState({});

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [doc, setDoc] = useState("");
  const [sanction, setSanction] = useState("Y");
  const [pep, setPep] = useState("Y");
  const [pepText, sePepText] = useState("");
  const [sanctionText, setSanctionText] = useState("");

  const [openHistory, setOpenHistory] = useState(false);
  const [openDocument, setOpenDocument] = useState(false);
  const [openAnalysisResult, setOpenAnalysisResult] = useState(false);
  const [openRes, setOpenRes] = useState(false);
  const [webSearch, setWebSearch] = useState(false);

  const [webSearchReport, setWebSearchReport] = useState({});
  const [nameCheck, setNameCheck] = useState("");
  const [searchId, setSearchId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [clientObjArray, setClientObjArray] = useState([]);
  const [clientArray, setClientArray] = useState([]);
  const [selectedCatId, setSelectedCatId] = useState("");
  const [nationalityList, setNationalityList] = useState([]);

  const [firstName, setFirstName] = useState([]);
  const [middleName, setMiddleName] = useState([]);
  const [lastName, setLastName] = useState([]);

  const formRef = useRef();
  const adsFormRef = useRef();
  const riskFormRef = useRef();

  useEffect(() => {
    dispatch(getEntity({ _start: 0, _limit: 100 }));
    dispatch(getIndividual({ _start: 0, _limit: 100 }));
    setSanction(fileCreation ? "N" : "Y");
    setPep(fileCreation ? "N" : "Y");

    if (!selectedDateId) {
      updateMastercode();
      configureSocket();
    } else {
      selectedDates();
    }
  }, []);
  useEffect(() => {
    individualCodeOnchange();
  }, [entity, individual]);

  const individualCodeOnchange = async () => {
    const fetchData = async (url) => {
      const payload = { method: "GET", url };
      return fetchApi(payload).then((res) => res.data.rows);
    };

    const params = convertQueryParams({
      isAll: true,
      masterType: "individual",
      options: true,
    });
    const paramsName = convertQueryParams({
      isAll: true,
      masterType: "individual",
      exportAll: true,
    });

    const corpEntity = await fetchData(`/client?${params}`);
    const corpEntityName = await fetchData(`/client?${paramsName}`);

    let firstName = corpEntityName
      ? corpEntityName?.map((d) => {
          let obj = {
            value: d?.firstName,
            label: d?.firstName,
          };
          return obj;
        })
      : [];
    let middleName = corpEntityName
      ? corpEntityName?.map((d) => {
          let obj = {
            value: d?.middleName,
            label: d?.middleName,
          };
          return obj;
        })
      : [];
    let lastName = corpEntityName
      ? corpEntityName?.map((d) => {
          let obj = {
            value: d?.lastName,
            label: d?.lastName,
          };
          return obj;
        })
      : [];
    setFirstName(firstName);
    setMiddleName(middleName);
    setLastName(lastName);

    setClientObjArray(corpEntity);

    const nationality = data
      ? data?.map((d) => ({
          value: d.name,
          label: d.nationality,
        }))
      : [];
    setNationalityList(nationality);
  };

  const handleChange = (value) => {
    setSanction(value);
  };
  const selectedDates = async () => {
    let payload = {
      method: "GET",
      url: `/individual?_start=0&_limit=1&id=${selectedDateId}`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res.data.rows[0];
    });

    const tableArray = data?.searchReport?.map((c, index) => {
      return c.reportDoc.map((e, index) => {
        let obj = {
          id: index + 1,
          date: timeDiff(e.updatedBy.date, "DD/MM/YYYY - hh:mm a"),
          raf: e.url,
          analysisReport: e.document,
          updatedBy: e.updatedBy.name,
        };
        return obj;
      });
    });

    setTableData(tableArray);
    let val,
      val2,
      val3 = {};
    if (data) {
      setSelectedCatId(data._id);

      val = {
        _id: data._id,
        code: data.code,
        title: data.title,
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        DOB: data.DOB,
        COB: data.COB,
        nationality: data.nationality,
        passportNo: data.passportNo,
        passportIssue: data.passportIssue,
        passportExp: data.passportExp,
        idNo: data.idNo,
        idIssue: data.idIssue,
        idExp: data.idExp,
        status: data.status,
        taxInfo: data.taxInfo,
        associateBus: data.associateBus,
        relatedEntities: null,
        contactNo: data.contactNo,
        email: data.email,
        createdAt: data.createdAt,
      };
      val2 = {
        residentialAddress: data.residentialAddress,
        serviceAddress: data.serviceAddress,
        line_1: data?.details?.address?.line_1,
        line_2: data?.details?.address?.line_2,
        line_3: data?.details?.address?.line_3,
        zipCode: data?.details?.address?.zipCode,
        country: data?.details?.address?.country,
      };
      val3 = {
        riskRating: data?.details?.riskRating,
        pep: data?.details?.pep,
        pepText: data?.details?.pepText,
        sanction: data?.details?.sanction,
        sanctionText: data?.details?.sanctionText,
      };
    }
    sePepText(data?.details?.sanction);
    setSanctionText(data?.details?.sanctionText);
    setSchema1(val);
    setSchema2(val2);
    setSchema3(val3);
  };

  const fetchLiveUrl = async (url) => {
    try {
      const data = { url: url };
      const obj = {
        method: "POST",
        url: `/file/get`,
        data: data,
      };
      // Assuming fetchApi returns a promise
      return await fetchApi(obj);
    } catch (error) {
      console.error("Error fetching live URL:", error);
      throw error;
    }
  };
  const analysisResult = async (url) => {
    const res = await fetchLiveUrl(url);
    setLatDoc(res.data);
  };
  const onCancelWebSearch = () => {
    setWebSearchReport({});
    setWebSearch(false);
  };
  const updateLiveurl = async (data) => {
    const searchReport = data?.searchReport || [];
    const processedData = await Promise.all(
      searchReport.map(async (c) => {
        const processedReportDoc = await Promise.all(
          c.reportDoc.map(async (e, index) => {
            try {
              const date = timeDiff(e.updatedBy.date, "DD/MM/YYYY - hh:mm a");
              const res = await fetchLiveUrl(e.url);
              analysisResult(e.url);

              return {
                id: index + 1,
                date: date,
                raf: res.data,
                url: e.url,
                analysisReport: e.document,
                updatedBy: e.updatedBy.name,
                liveUrl: res.data,
              };
            } catch (error) {
              console.error("Error processing report document:", error);
              return null;
            }
          })
        );
        return processedReportDoc.filter((doc) => doc !== null);
      })
    );

    setTableData(processedData);
  };
  const findOneIndividual = async (value) => {
    setLoading(true);
    let ctr = {};
    if (value.code) {
      if (value.code.startsWith("INV")) {
        ctr.code = value.code;
      } else {
        ctr.id = value.code;
      }
    } else {
      ctr = value;
    }

    let params = convertQueryParams(ctr);
    let payload = {
      method: "GET",
      url: `/individual?${params}`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res?.data?.rows?.[0];
    });
    updateLiveurl(data);
    let val,
      val2,
      val3 = {};
    if (data) {
      setSelectedCatId(data._id);

      val = {
        _id: data._id,
        code: data.code,
        title: data.title,
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        DOB: data.DOB,
        COB: data.COB,
        nationality: data.nationality,
        passportNo: data.passportNo,
        passportIssue: data.passportIssue,
        passportExp: data.passportExp,
        idNo: data.idNo,
        idIssue: data.idIssue,
        idExp: data.idExp,
        taxInfo: data.taxInfo,
        status: data.status,
        associateBus: data.associateBus,
        relatedEntities: null,
        contactNo: data.contactNo,
        email: data.email,
        createdAt: data.createdAt,
      };
      val2 = {
        residentialAddress: data?.residentialAddress,
        serviceAddress: data?.serviceAddress,
        line_1: data?.details?.address?.line_1,
        line_2: data?.details?.address?.line_2,
        line_3: data?.details?.address?.line_3,
        zipCode: data?.details?.address?.zipCode,
        country: data?.details?.address?.country,
      };
      val3 = {
        riskRating: data?.details?.riskRating,
        pep: data?.details?.pep,
        pepText: data?.details?.pepText,
        sanction: data?.details?.sanction,
        sanctionText: data?.details?.sanctionText,
      };
    }
    sePepText(data?.details?.sanction);
    setSanctionText(data?.details?.sanctionText);
    setSchema1(val);
    setSchema2(val2);
    setSchema3(val3);
  };
  const codeOnChange = async (value, name) => {
    if (!selectedDateId) {
      setSchema1(value);

      if (!setselectedPage) {
        let { code, firstName, lastName, middleName } = value;
        if (name === "code") {
          findOneIndividual({ code: code });
        }
        if (name === "name") {
          findOneIndividual({ name: name });
        }

        if (name === "firstName") {
          let obj = { firstName: firstName };
          //   fetchNameList(obj?.firstName ? obj : "");
          if (obj) {
            findOneIndividual(obj);
          }
        }
        if (name === "lastName") {
          let obj = { lastName: lastName };
          //  fetchNameList(obj?.lastName ? obj : "");
          if (obj) {
            findOneIndividual(obj);
          }
        }
        if (name === "middleName") {
          let obj = { middleName: middleName };
          //  fetchNameList(obj?.middleName ? obj : "");
          if (obj) {
            findOneIndividual(obj);
          }
        }
      } else {
        let options2 = adsFormRef.current.getPayload();
        let options3 = riskFormRef.current.getPayload();
        setSchema2(options2);
        setSchema3(options3);
      }
    }
  };
  const fetchNameList = async (value) => {
    let params = convertQueryParams(value);
    let payload = {
      method: "GET",
      url: `/individual?${params}`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res?.data?.rows;
    });

    let firstName = data
      ? data?.map((d) => {
          let obj = {
            value: d?.firstName,
            label: d?.firstName,
          };
          return obj;
        })
      : [];
    let middleName = data
      ? data?.map((d) => {
          let obj = {
            value: d?.middleName,
            label: d?.middleName,
          };
          return obj;
        })
      : [];
    let lastName = data
      ? data?.map((d) => {
          let obj = {
            value: d?.lastName,
            label: d?.lastName,
          };
          return obj;
        })
      : [];
    setFirstName(firstName);
    setMiddleName(middleName);
    setLastName(lastName);
  };
  const clientOnChange = async (value) => {
    let params = { code: value };

    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/individual?${query}`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res.data.rows;
    });
    setClientArray(data);
    let list = data.map((d) => {
      let obj = {
        value: d?.code,
        label: d?.code,
      };
      return obj;
    });
    setClientObjArray(list);
  };

  const onChangeHandler = (setIdentifierState, event) => {
    setIdentifierState(event.target.value);
  };

  const handleChangePep = (value) => {
    setPep(value);
  };

  const configureSocket = () => {
    // socket.on("connection", () => {
    //   console.log(" Connected backend");
    // });
    socket.on("counter", (counter) => {
      if (counter.update === true) {
        updateMastercode();
      }
    });
    return () => socket.off("counter");
  };

  const setdocumeny = (value) => {
    setDoc(value);
    setOpenDocument(true);
  };

  const updateMastercode = async () => {
    if (setselectedPage) {
      let payload = {
        method: "GET",
        url: `/admin/masterCode?name=individual`,
      };
      let data = await fetchApi(payload).then((res) => {
        return res?.data?.data;
      });
      let originalString = data?.seq;
      let numericPart = originalString?.match(/\d+$/)[0];
      let incrementedNumeric = parseInt(numericPart, 10) + 1;
      let newNumericPart = String(incrementedNumeric).padStart(
        numericPart?.length,
        "0"
      );
      let newString = originalString?.replace(/\d+$/, newNumericPart);
      setMasterId(newString);
      setSchema1({
        code: newString ? newString : "INV00001",
        status: "active",
        createdAt: new Date(),
      });
    }
  };

  let formSchema = [
    {
      name: "code",
      value: "",
      type: setselectedPage ? "text" : "select",
      label: "MasterFile Code",
      placeholder: "Enter masterFile code",
      disabled: setselectedPage ? true : false,
      loading: isLoading,
      showSearch: true,
      allowClear: true,
    },
    {
      name: "title",
      value: "",
      type: "select",
      label: "Title",
      placeholder: "Enter title",
      required: true,
      options: [
        {
          value: "mr",
          label: "Mr",
        },
        {
          value: "mrs",
          label: "Mrs",
        },
        {
          value: "miss",
          label: "Miss",
        },
        {
          value: "ms",
          label: "Ms",
        },
        {
          value: "dr",
          label: "Dr",
        },
        {
          value: "rev",
          label: "Rev",
        },
      ],
    },
    {
      name: "status",
      value: "",
      type: "select",
      label: "Individual Status",
      placeholder: "Select individual status",
      options: [
        {
          value: "active",
          label: "Active",
        },
        {
          value: "inactive",
          label: "Inactive",
        },
        {
          value: "closing",
          label: "Closing",
        },
        {
          value: "closed",
          label: "Closed",
        },
        {
          value: "transferringOut",
          label: "Transferring Out",
        },
        {
          value: "prospect",
          label: "Prospect",
        },
        {
          value: "transferringIn",
          label: "Transferring In",
        },
        {
          value: "liquidated",
          label: "Liquidated",
        },
        {
          value: "struckOff",
          label: "Struck Off",
        },
      ],
      disabled: selectedDateId ? false : true,
    },
    // {
    //   name: "individualRole",
    //   value: "",
    //   type: "select",
    //   label: "Individual Role",
    //   placeholder: "Enter client type",
    //   inputClass: "ptb-1",
    //   options: [
    //     {
    //       value: "director",
    //       label: "Director",
    //     },
    //     {
    //       value: "shareholder",
    //       label: "Shareholder",
    //     },
    //     {
    //       value: "ubo",
    //       label: "UBO",
    //     },
    //     {
    //       value: "poc",
    //       label: "POC",
    //     },
    //     {
    //       value: "trustee",
    //       label: "Trustee",
    //     },
    //     {
    //       value: "settlor",
    //       label: "Settlor",
    //     },
    //     {
    //       value: "beneficiary",
    //       label: "Beneficiary",
    //     },
    //     {
    //       value: "frHolder",
    //       label: "FR Holder",
    //     },
    //     {
    //       value: "reserveDirector",
    //       label: "Reserve Director",
    //     },
    //     {
    //       value: "nomineeShareholder",
    //       label: "Nominee Shareholder",
    //     },
    //   ],
    //   mode: "multiple",
    //   showSearch: true,
    //   allowClear: true,
    // },

    // {
    //   customElement: <div className="col-md-4"></div>,
    // },
    {
      name: "firstName",
      value: "",
      type: setselectedPage ? "text" : "select",
      label: "First Name",
      placeholder: "Enter first name",
      required: true,
      showSearch: setselectedPage ? false : true,
      allowClear: true,
    },
    {
      name: "middleName",
      value: "",
      type: setselectedPage ? "text" : "select",
      label: "Middle Name",
      placeholder: "Enter Middle name",
      showSearch: setselectedPage ? false : true,
      allowClear: true,
    },
    {
      name: "lastName",
      value: "",
      type: setselectedPage ? "text" : "select",
      label: "Last Name",
      placeholder: "Enter last name",
      required: true,
      showSearch: setselectedPage ? false : true,
      allowClear: true,
    },

    {
      name: "DOB",
      value: "",
      type: "date",
      label: "Date of Birth",
      placeholder: "Enter Date (YYYY/MM/DD)",
      // format: "DD/MM/YYYY",
      required: true,
    },
    {
      name: "COB",
      value: "",
      type: "select",
      label: "Country of Birth",
      placeholder: "Select country of birth",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
      required: true,
    },
    {
      name: "nationality",
      value: "",
      type: "select",
      label: "Nationality",
      placeholder: "Select nationality",
      options: getOptions(nationalityList),
      loading: isLoading,
      showSearch: true,
      required: true,
    },
    {
      name: "passportNo",
      value: "",
      type: "text",
      label: "Passport Number",
      placeholder: "Enter passport number",
      // required: true,
    },
    {
      name: "passportIssue",
      value: "",
      type: "date",
      label: "Issue Date",
      placeholder: "Enter Date (YYYY/MM/DD)",
      //   format: "DD/MM/YYYY",
      // required: true,
    },
    {
      name: "passportExp",
      value: "",
      type: "date",
      label: "Expiry Date",
      placeholder: "Enter Date (YYYY/MM/DD)",
      //    format: "DD/MM/YYYY",
      // required: true,
    },
    {
      name: "idNo",
      value: "",
      type: "text",
      label: "ID Number",
      placeholder: "Enter id number",
      // required: true,
    },
    {
      name: "idIssue",
      value: "",
      type: "date",
      label: "Issue Date",
      placeholder: "Enter Date (YYYY/MM/DD)",
      //  format: "DD/MM/YYYY",
      // required: true,
    },
    {
      name: "idExp",
      value: "",
      type: "date",
      label: "Expiry Date",
      placeholder: "Enter Date (YYYY/MM/DD)",
      // format: "DD/MM/YYYY",
      // required: true,
    },
    {
      name: "taxInfo",
      value: "",
      type: "text",
      label: "Tax Info Number",
      placeholder: "Enter tax info number",
    },
    {
      name: "email",
      value: "",
      type: "email",
      label: "Email",
      placeholder: "Enter email address",
      required: true,
    },
    {
      name: "contactNo",
      value: "",
      type: "number",
      label: "Contact Number",
      placeholder: "Enter contact number",
      customClass: "hide-arrows",
      required: true,
    },
    {
      name: "associateBus",
      value: "",
      type: "text",
      label: "Associate business",
      placeholder: "Enter associate business",
    },
    {
      name: "relatedEntities",
      value: "",
      type: "text",
      label: "Related Entities",
      placeholder: "Enter related entities",
      disabled: true,
    },
    {
      name: "createdAt",
      value: "",
      type: "date",
      label: "Date of Creation",
      placeholder: "Select date",
    },
    // {
    //   name: "riskRating",
    //   value: "low",
    //   type: "select",
    //   label: "Risk Rating",
    //   placeholder: "Select risk level",
    //   options: [
    //     {
    //       value: "low",
    //       label: "Low",
    //     },
    //     {
    //       value: "medium",
    //       label: "Medium",
    //     },
    //     {
    //       value: "high",
    //       label: "High",
    //     },
    //   ],
    // },
  ];

  let adsFormSchema = [
    {
      name: "serviceAddress",
      value: "",
      type: "textarea",
      label: "Service Address",
      placeholder: "Enter service address",
      // required: true,
    },
    {
      name: "residentialAddress",
      value: "",
      type: "textarea",
      label: "Residential Address",
      placeholder: "Enter residential address",
      // required: true,
    },
    // {
    //   label: "",
    //   customElement: <></>,
    // },
    // {
    //   name: "country",
    //   value: "",
    //   type: "select",
    //   label: "Country",
    //   placeholder: "Select country",
    //   options: getOptions(data),
    //   loading: isLoading,
    //   showSearch: true,
    //   labelClass: "mb-1",
    //   customClass: "col-md-3 flex-column align-items-start",
    // },
    // {
    //   name: "zipCode",
    //   value: "",
    //   type: "number",
    //   label: "Post/Zip code",
    //   labelClass: "mb-1",
    //   placeholder: "Enter post/zip code",
    //   customClass: "col-md-3 flex-column align-items-start",
    // },
  ];

  let riskFormSchema = [
    {
      name: "riskRating",
      value: "low",
      type: "select",
      label: "Risk Rating",
      placeholder: "Select risk level",
      options: [
        {
          value: "low",
          label: "Low",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "high",
          label: "High",
        },
      ],
    },
    {
      // name: "pep",
      // value: "",
      // type: "select",
      // label: "PEP",
      // placeholder: "Enter pep",
      // options: [
      //   {
      //     value: "Y",
      //     label: "Yes",
      //   },
      //   {
      //     value: "N",
      //     label: "No",
      //   },
      // ],
      customClass: pep == "Y" ? "col-md-3" : "col-lg-1",
      label: "PEP",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${pep == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={pep}
            disabled={fileCreation}
            onChange={handleChangePep}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {pep == "Y" && (
            <input
              type="text"
              value={pepText}
              onChange={onChangeHandler.bind(null, sePepText)}
              className="w-100"
              placeholder="Enter pep"
            />
          )}
        </div>
      ),
    },
    {
      customClass: sanction == "Y" ? "col-md-3" : "col-lg-1",
      label: "Sanction",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${sanction == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={sanction}
            disabled={fileCreation}
            onChange={handleChange}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {sanction == "Y" && (
            <input
              type="text"
              className="w-100"
              value={sanctionText}
              onChange={onChangeHandler.bind(null, setSanctionText)}
              placeholder="Enter sanction"
            />
          )}
        </div>
      ),
    },
    {
      customClass: "col-lg-2",
      customElement: fileCreation ? (
        <div></div>
      ) : (
        <div className="d-flex gap-4 mb-1">
          <button
            className="outline-btn"
            disabled={latDoc ? false : true}
            onClick={() => setOpenHistory(true)}
          >
            History
          </button>
          <button
            className="primary-btn"
            disabled={selectedCatId == "" ? true : false}
            onClick={() => saveOnClick()}
          >
            Confirm
          </button>
        </div>
      ),
    },
  ];
  const webSearchonClick = async () => {
    if (
      formRef?.current?.validForm() ||
      riskFormRef?.current?.validForm() ||
      adsFormRef?.current?.validForm()
    ) {
      let options1 = formRef?.current?.getPayload();
      let options2 = adsFormRef?.current?.getPayload();
      let options3 = riskFormRef?.current?.getPayload();

      let { firstName, middleName, lastName } = options1;
      let gender = "";
      let fullName =
        firstName && lastName
          ? `${firstName} ${
              middleName && middleName !== "." ? middleName + " " : ""
            }${lastName}`
          : "";
      if (options1.title === "mr") {
        gender = "Male";
      } else if (
        options1.title === "mrs" ||
        options1.title === "miss" ||
        options1.title === "ms"
      ) {
        gender = "Female";
      } else if (options1.title === "dr" || options1.title === "rev") {
        gender = "";
      }

      let data = {
        fullName: fullName,
        code: options1.code,
        title: options1.title,
        gender: gender,
        firstName: options1.firstName,
        middleName: options1.middleName,
        lastName: options1.lastName,
        DOB: options1.DOB,
        COB: options1.COB,
        nationality: options1.nationality,
        passportNo: options1.passportNo,
        passportIssue: options1.passportIssue,
        passportExp: options1.passportExp,
        idNo: options1.idNo,
        idIssue: options1.idIssue,
        idExp: options1.idExp,
        status: options1.status,
        taxInfo: options1.taxInfo,
        residentialAddress: options2.residentialAddress,
        serviceAddress: options2.serviceAddress,
        associateBus: options1.associateBus,
        relatedEntities: null,
        contactNo: options1.contactNo,
        email: options1.email,
        details: {
          address: {
            line_1: options2?.line_1,
            line_2: options2?.line_2,
            line_3: options2?.line_3,
            zipCode: options2?.zipCode,
            country: options2?.country,
          },
          riskRating: options3.riskRating,
          pep: pep,
          pepText: pepText,
          sanction: sanction,
          sanctionText: sanctionText,
        },
        createdAt: options1.createdAt,
      };

      let payload = {
        method: "POST",
        url: `/nameCheck/searchReport`,
        data: data,
      };

      fetchApi(payload)
        .then((res) => {
          const name = res?.data[0];
          const findData = findURLs(res?.data);

          const output = {
            name: name,
            URLs: findData,
          };

          setWebSearchReport(output);
          setWebSearch(true);
          sendNotify("success", res?.message);
        })
        .catch((error) => {
          console.error("Error generating report:", error);
          sendNotify("error", error?.message);
        });
    } else {
      sendNotify("error", "Please enter at least one name");
    }
  };

  function findURLs(arr) {
    let URLs = [];
    for (const element of arr) {
      if (typeof element === "string" && element.startsWith("http")) {
        URLs.push(element);
      } else if (Array.isArray(element)) {
        URLs = URLs.concat(findURLs(element));
      }
    }
    return URLs;
  }
  const changeTab = (tab) => {};
  useEffect(() => {
    if (individualClicked) {
      onSubmit(selectedDateId);
    }
  }, [individualClicked]);

  // const generatePDF = async (data) => {
  //   const specificDate = moment(Date.now()).format("YYYY-MM-DD:HH:mm:ss");
  //   const options = {
  //     margin: 0.5,
  //     filename: `IndividualAnalysisReport-${specificDate}.pdf`,
  //     image: { type: "jpeg", quality: 0.98 },
  //     html2canvas: { dpi: 192, letterRendering: true },
  //     jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
  //     pagebreak: { mode: ["avoid-all", "css", "legacy"] },
  //   };

  //   try {
  //     let doc = {};
  //     const pdfBlob = await html2pdf()
  //       .from(data)
  //       .set(options)
  //       .outputPdf("blob");
  //     var bodyFormData = new FormData();
  //     bodyFormData.append("file", pdfBlob);
  //     bodyFormData.append("folder", "test");

  //     const payload = {
  //       method: "POST",
  //       url: `/file`,
  //       data: bodyFormData,
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     };

  //     const response = await fetchApi(payload)
  //       .then((res) => {
  //         doc["name"] = schema1.code;
  //         doc["code"] = schema1.code;
  //         doc["masterId"] = schema1._id;
  //         doc["masterType"] = "individualAnalysis";
  //         doc["reportDoc"] = {
  //           url: res?.data.url,
  //           document: payload?.data?.filename,
  //           size: pdfBlob.size,
  //           type: "pdf",
  //         };
  //       })
  //       .catch((error) => {
  //         setLoading(false);
  //         sendNotify("error", error?.message);
  //       });
  //     let reportPayload = {
  //       method: "POST",
  //       url: `/searchReport`,
  //       data: doc,
  //     };

  //     fetchApi(reportPayload)
  //       .then((res) => {
  //         sendNotify("success", res?.message);
  //       })
  //       .catch((error) => {
  //         console.error("Error generating report:", error);
  //         sendNotify("error", error?.message);
  //       });
  //     //};
  //   } catch (error) {
  //     console.error("Error generating PDF:", error);
  //     sendNotify("error", error?.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const storeReport = async () => {
    if (searchId) {
      let payload = {
        method: "GET",
        url: `/nameCheck/storeReport/${searchId}`,
      };
      let data = await fetchApi(payload).then((res) => {
        return res?.data?.data;
      });

      if (data) {
        const specificDate = moment(Date.now()).format("YYYY-MM-DD:HH:mm:ss");
        const options = {
          margin: 0.5,
          filename: `IndividualAnalysisReport-${specificDate}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { dpi: 192, letterRendering: true },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
          pagebreak: { mode: ["avoid-all", "css", "legacy"] },
        };

        try {
          let doc = {};
          const pdfBlob = await html2pdf()
            .from(data)
            .set(options)
            .outputPdf("blob");
          var bodyFormData = new FormData();
          bodyFormData.append("file", pdfBlob);
          bodyFormData.append("folder", "test");

          const payload = {
            method: "POST",
            url: `/file`,
            data: bodyFormData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };

          const response = await fetchApi(payload)
            .then((res) => {
              doc["name"] = schema1.code;
              doc["code"] = schema1.code;
              doc["masterId"] = schema1._id;
              doc["masterType"] = "individualAnalysis";
              doc["reportDoc"] = {
                url: res?.data.url,
                document: payload?.data?.filename,
                size: pdfBlob.size,
                type: "pdf",
              };
            })
            .catch((error) => {
              setLoading(false);
              sendNotify("error", error?.message);
            });
          let reportPayload = {
            method: "POST",
            url: `/searchReport`,
            data: doc,
          };

          fetchApi(reportPayload)
            .then((res) => {
              sendNotify("success", res?.message);
            })
            .catch((error) => {
              console.error("Error generating report:", error);
              sendNotify("error", error?.message);
            });
          //     //};
        } catch (error) {
          //     console.error("Error generating PDF:", error);
          //     sendNotify("error", error?.message);
          //   } finally {
          //     setLoading(false);
          //   }
        }
      } else {
      }
    }
  };
  const saveOnClick = () => {
    onSubmit(selectedCatId);
  };
  const onSubmit = (selectedCatId) => {
    if (
      formRef.current.validForm() ||
      riskFormRef.current.validForm() ||
      adsFormRef.current.validForm()
    ) {
      let options1 = formRef.current.getPayload();
      let options2 = adsFormRef.current.getPayload();
      let options3 = riskFormRef.current.getPayload();

      let data = {
        code: options1.code,
        title: options1.title,
        firstName: options1.firstName,
        middleName: options1.middleName,
        lastName: options1.lastName,
        DOB: options1.DOB,
        COB: options1.COB,
        nationality: options1.nationality,
        passportNo: options1.passportNo,
        passportIssue: options1.passportIssue,
        passportExp: options1.passportExp,
        idNo: options1.idNo,
        idIssue: options1.idIssue,
        idExp: options1.idExp,
        status: options1.status,
        taxInfo: options1.taxInfo,
        associateBus: options1.associateBus,
        residentialAddress: options2.residentialAddress,
        serviceAddress: options2.serviceAddress,
        relatedEntities: null,
        contactNo: options1.contactNo,
        email: options1.email,
        details: {
          address: {
            line_1: options2?.line_1,
            line_2: options2?.line_2,
            line_3: options2?.line_3,
            zipCode: options2?.zipCode,
            country: options2?.country,
          },
          riskRating: options3.riskRating,
          pep: pep,
          sanction: sanction,
          pepText: pepText,
          sanctionText: sanctionText,
        },
        createdAt: options1.createdAt,
      };
      data.masterType = "individual";
      data.nic = "INV";

      let payload = {};
      if (selectedCatId) {
        payload.method = "PUT";
        payload.url = `/individual/update/${selectedCatId}`;
        payload.data = data;

        fetchApi(payload)
          .then((res) => {
            sendNotify("success", res?.message);
          })
          .catch((error) => {
            sendNotify("error", error?.message);
          });
      } else {
        let url = `/app/master-search?action=individual`;
        payload.method = "POST";
        payload.url = `/individual`;
        payload.data = data;
        fetchApi(payload)
          .then((res) => {
            sendNotify("success", res?.message);
            setIndividualClicked(false);
            setTimeout(() => {
              //   window.location.href = url;
            }, 1000);
          })
          .catch((error) => {
            sendNotify("error", error?.message);
            setIndividualClicked(false);
          });
      }
    } else {
      sendNotify("error", "Please enter at least one name");
    }
  };

  const checkAnalyzeValidity = () => {
    if (
      formRef?.current?.validForm() ||
      riskFormRef?.current?.validForm() ||
      adsFormRef?.current?.validForm()
    ) {
      let options1 = formRef?.current?.getPayload();
      let options2 = adsFormRef?.current?.getPayload();
      let options3 = riskFormRef?.current?.getPayload();

      let { firstName, middleName, lastName } = options1;
      let gender = "";
      let fullName =
        firstName && lastName
          ? `${firstName} ${
              middleName && middleName !== "." ? middleName + " " : ""
            }${lastName}`
          : "";
      if (options1.title === "mr") {
        gender = "Male";
      } else if (
        options1.title === "mrs" ||
        options1.title === "miss" ||
        options1.title === "ms"
      ) {
        gender = "Female";
      } else if (options1.title === "dr" || options1.title === "rev") {
        gender = "";
      }

      let data = {
        fullName: fullName,
        code: options1.code,
        title: options1.title,
        gender: gender,
        firstName: options1.firstName,
        middleName: options1.middleName,
        lastName: options1.lastName,
        DOB: options1.DOB,
        COB: options1.COB,
        nationality: options1.nationality,
        passportNo: options1.passportNo,
        passportIssue: options1.passportIssue,
        passportExp: options1.passportExp,
        idNo: options1.idNo,
        idIssue: options1.idIssue,
        idExp: options1.idExp,
        status: options1.status,
        taxInfo: options1.taxInfo,
        residentialAddress: options2.residentialAddress,
        serviceAddress: options2.serviceAddress,
        associateBus: options1.associateBus,
        relatedEntities: null,
        contactNo: options1.contactNo,
        email: options1.email,
        details: {
          address: {
            line_1: options2?.line_1,
            line_2: options2?.line_2,
            line_3: options2?.line_3,
            zipCode: options2?.zipCode,
            country: options2?.country,
          },
          riskRating: options3.riskRating,
          pep: pep,
          pepText: pepText,
          sanction: sanction,
          sanctionText: sanctionText,
        },
        createdAt: options1.createdAt,
      };
      data.masterType = "analysis";
      let payload = {
        method: "POST",
        url: `/nameCheck/individual/search-initiate`,
        data: data,
      };

      let url = `/app/pre-incorp-resolution`;

      fetchApi(payload)
        .then((res) => {
          if (res?.data?.data && res?.data?.success) {
            sendNotify("success", res?.message);
            setNameCheck(res?.data?.data);
            setSearchId(res?.data?.searchId);
            setOpenRes(true);
          } else {
            sendNotify("error", res?.message);
          }
          // let data = await fetchApi(payload).then((res) => {
          //   return res?.data?.data
          // });
          // setTimeout(() => {
          //   window.location.href = url;
          // }, 1000);
        })
        .catch((error) => {
          sendNotify("error", error?.message);
        });
    } else {
      sendNotify("error", "Please enter at least one name");
    }
  };

  if (setselectedPage) {
    setselectedPage("individual");
  }
  const tableColumns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date - b.date,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "RAF",
      dataIndex: "raf",
      sorter: (a, b) => a.raf - b.raf,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { raf }) => {
        return (
          <button className="outline-btn py-1" onClick={() => setdocumeny(raf)}>
            <i className="fal fa-eye"></i>
          </button>
        );
      },
    },
    {
      title: "Analysis Report",
      dataIndex: "analysisReport",
      sorter: (a, b) => a.analysisReport - b.analysisReport,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { raf }) => {
        return (
          <button className="outline-btn py-1" onClick={() => setdocumeny(raf)}>
            <i className="fal fa-eye"></i>
          </button>
        );
      },
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
      sorter: (a, b) => a.updatedBy - b.updatedBy,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  // const tableData = [
  //   {
  //     id: "001",
  //     date: "12/04/2024 - 03:24PM",
  //     raf: "",
  //     analysisReport: "Sample",
  //     updatedBy: "Guru",
  //   },
  //   {
  //     id: "002",
  //     date: "11/04/2024 - 02:53PM",
  //     raf: "",
  //     analysisReport: "Sample",
  //     updatedBy: "Guru",
  //   },
  //   {
  //     id: "003",
  //     date: "10/04/2024 - 10:01AM",
  //     raf: "",
  //     analysisReport: "Sample",
  //     updatedBy: "Guru",
  //   },
  //   {
  //     id: "004",
  //     date: "10/04/2024 - 09:24AM",
  //     raf: "",
  //     analysisReport: "Sample",
  //     updatedBy: "Guru",
  //   },
  //   {
  //     id: "005",
  //     date: "05/04/2024 - 11:12AM",
  //     raf: "",
  //     analysisReport: "Sample",
  //     updatedBy: "Guru",
  //   },
  // ];

  return (
    <div>
      <FormZ
        formSchema={formSchema}
        formData={schema1}
        onKeyUp={(e) => clientOnChange(e.target.value)}
        onChange={codeOnChange}
        formOptions={{
          code: clientObjArray,
          middleName: middleName,
          lastName: lastName,
          firstName: firstName,
        }}
        ref={formRef}
        formClass="custom-form row col-md-9 mb-3 mb-md-4 gy-3 gx-4"
        childClass="col-md-4 mb-0"
        labelClass="mb-1"
        inputClass="w-100"
      />
      <FormZ
        formSchema={adsFormSchema}
        formData={schema2}
        ref={adsFormRef}
        formClass="custom-form row col-md-9 mb-3 mb-md-4 gy-3 gx-4"
        childClass="col-md-4 mb-0"
        labelClass="mb-1"
        inputClass="w-100"
      />
      <FormZ
        formSchema={riskFormSchema}
        ref={riskFormRef}
        formData={schema3}
        formClass="custom-form row mb-md-5 gy-3 align-items-end"
        childClass="col-md-3 mb-0"
        labelClass="mb-1"
        inputClass="w-100"
        disabled={fileCreation ? true : false}
      />
      {actionShow && (
        <div>
          <div className="d-flex flex-wrap gap-3 mt-3 mb-5 mb-md-0">
            <button
              className="primary-btn"
              onClick={() => checkAnalyzeValidity("reservation/confirmation")}
            >
              Analyze
            </button>
            <button className="outline-btn" onClick={() => webSearchonClick()}>
              Web Search
            </button>
            <button
              className="outline-btn"
              disabled={latDoc ? false : true}
              onClick={() => setOpenAnalysisResult(true)}
            >
              Analysis Result
            </button>
            {/* <button className="outline-btn">Store Result</button> */}
          </div>
          <div className="d-flex flex-wrap gap-3 mt-3">
            <button className="primary-btn">RAF</button>
            <button className="outline-btn">Approve RAF</button>
            {/* <button className="outline-btn">Store RAF</button> */}
          </div>
        </div>
      )}
      <Modal
        title={"History"}
        className="custom-modal"
        open={openHistory}
        width={800}
        onOk={() => setOpenHistory(false)}
        okText="Submit"
        onCancel={() => setOpenHistory(false)}
      >
        <div>
          <Table
            className="custom-table"
            columns={tableColumns}
            dataSource={tableData[0]}
            pagination={false}
          />
        </div>
      </Modal>

      <Modal
        title={"Analysis Result"}
        className="custom-modal"
        open={openAnalysisResult}
        width={1000}
        onOk={() => setOpenAnalysisResult(false)}
        okText="Submit"
        onCancel={() => setOpenAnalysisResult(false)}
      >
        <div className="document-view">
          <iframe
            src={`data:application/pdf;base64,${latDoc}`}
            frameBorder="0"
            height="100%"
            width="100%"
          ></iframe>
        </div>
        {/* <div>
          <Table
            className="custom-table"
            columns={tableColumns}
            dataSource={tableData[0]}
            pagination={false}
          />
        </div> */}
      </Modal>
      <Modal
        title={"Raf Document"}
        className="custom-modal"
        open={openDocument}
        width={1000}
        onOk={() => setOpenDocument(false)}
        onCancel={() => setOpenDocument(false)}
      >
        <div className="document-view">
          <iframe
            src={`data:application/pdf;base64,${doc}`}
            frameBorder="0"
            height="100%"
            width="100%"
          ></iframe>
        </div>
      </Modal>

      <Modal
        title={"Individual Analysis Report"}
        className="custom-modal"
        open={openRes}
        width={1000}
        onOk={false}
        onCancel={() => setOpenRes(false)}
        footer={false}
      >
        <div className="document-view" id="modal-content">
          <iframe
            title="HTML Content"
            width="100%"
            height="100%"
            srcDoc={nameCheck}
            frameBorder="0"
          >
            {" "}
          </iframe>
        </div>
        {/* <button className="primary-btn" onClick={() => generatePDF(nameCheck)}>
          Upload PDF
        </button> */}
        <button
          className="primary-btn"
          onClick={() => storeReport("nameCheck")}
        >
          Store Report
        </button>
      </Modal>

      <Modal
        title={"Web Search Report"}
        className="custom-modal"
        open={webSearch}
        width={1000}
        onOk={false}
        onCancel={onCancelWebSearch}
        footer={false}
      >
        <>
          {webSearchReport &&
          webSearchReport.URLs &&
          webSearchReport.URLs.length > 0 ? (
            <Tabs
              defaultActiveKey={String(webSearchReport.URLs[0])}
              onChange={changeTab}
            >
              {webSearchReport.URLs.map((a, i) => (
                <TabPane
                  tab={
                    <p className="active-green">
                      {`Portal - ${String(1 + i)}`}{" "}
                    </p>
                  }
                  key={String(i)}
                >
                  <iframe
                    src={a}
                    title={`URL ${i}`}
                    width="100%"
                    height="500px"
                    style={{ border: "none" }}
                  />
                </TabPane>
              ))}
            </Tabs>
          ) : (
            <p>No URLs available</p>
          )}
        </>
      </Modal>
    </div>
  );
}
