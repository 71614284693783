import { useState, useEffect, useRef } from "react";
import { Table, Select, Switch, Checkbox, Popover, Modal, Spin } from "antd";
import moment from "moment/moment";
import { useSelector, useDispatch } from "react-redux";
import { languagesArray } from "../../../store/language/languageSlice";

import { getOrganizationStructure } from "../../../store/organizationStructure/organizationStructureSlice";
import { getIndividualDetails } from "../../../store/individualDetails/individualDetailsSlice";
import { getCopEntityDetails } from "../../../store/copEntityDetails/copEntityDetailsSlice";
import { getRelatedEntityDetails } from "../../../store/relatedEntityDetails/relatedEntityDetailsSlice";

import MasterFileCreation from "../../../helper/translations/MasterFileCreation.json";

import FormZ from "../../../components/FormZ";

import {
  sendNotify,
  getOptions,
  fetchApi,
  convertQueryParams,
  getQuery,
} from "../../../helper";
import {} from "../../../helper";

import Currency from "../../../helper/currency.json";
async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}
export default function IndividualForm(props) {
  const { language } = useSelector((state) => state.language);

  const { data, isLoading } = useSelector((state) => state.country);
  const individualDetails = useSelector((state) => state.individualDetails);
  const relatedEntityDetails = useSelector(
    (state) => state.relatedEntityDetails
  );
  const copEntityDetails = useSelector((state) => state.copEntityDetails);

  const organizationStructure = useSelector(
    (state) => state.organizationStructure
  );

  const individualFormRef = useRef([]);
  const senderFormRef = useRef(null);
  const receiverFormRef = useRef(null);
  const allocationFormRef = useRef(null);

  let query = getQuery();

  const dispatch = useDispatch();

  const [sanction, setSanction] = useState("Y");
  const [pep, setPep] = useState("Y");
  const [pepText, setPepText] = useState("");
  const [sanctionText, setSanctionText] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkedList, setCheckedList] = useState({
    directors: false,
    shareholders: false,
    ubo: false,
    nominee: false,
    reserve: false,
    secretary: false,
    president: false,
  });
  const [tableLoading, setTableLoading] = useState(false);
  const [directorChecked, setDirectorChecked] = useState(false);
  const [reserveChecked, setReserveChecked] = useState(false);
  const [presidentChecked, setPresidentChecked] = useState(false);
  const [secretaryChecked, setSecretaryChecked] = useState(false);
  const [shareholderChecked, setShareholderChecked] = useState(false);
  const [uboChecked, setUboChecked] = useState(false);
  const [nomineeChecked, setNomineeChecked] = useState(false);
  const [openIndividual, setOpenIndividual] = useState(false);
  const [individualShow, setIndividualShow] = useState(false);
  const [objData, setObjData] = useState({});
  const [formData, setFormData] = useState({});
  const [individualArray, setIndividualArray] = useState([]);
  const [fullNameArray, setFullNameArray] = useState([]);
  const [selctedLanguage, setSelctedLanguage] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [queryParams, setQueryParams] = useState({
    _start: 0,
    _limit: 5,
    all: true,
  });
  const [senderFormData, setSenderFormData] = useState({});
  const [receiveFormData, setReceiveFormData] = useState({});
  const [receiverOptions, setReceiverOptions] = useState({});
  const [allocationFormData, setAllocationFormData] = useState({});
  const [receiverArray, setReceiverArray] = useState([]);
  const [findId, setFindId] = useState(null);
  const [shareTableData, setShareTableData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [currency, setCurrency] = useState([]);
  const [JSNewAllocation, setJSNewAllocation] = useState([0]);
  const [JSreceiver, setJSreceiver] = useState([0]);
  const [shareForm, setShareForm] = useState({
    show: false,
    type: "",
  });

  useEffect(() => {
    let index = languagesArray.find((tc) => tc.value == language);
    setSelctedLanguage(index.value);
  }, [language]);
  let m =
    MasterFileCreation.individual[selctedLanguage] ||
    MasterFileCreation.individual.en;

  let { index, entityData, id } = props;

  const handleChange = (value) => {
    setSanction(value);
  };
  useEffect(() => {
    if (individualShow) {
      setTableLoading(true);
      if (id || query.id) {
        let params = {
          ...queryParams,
          enId: id ? id : query.id,
        };
        dispatch(getIndividualDetails(params));
        dispatch(getRelatedEntityDetails(params));
        dispatch(getCopEntityDetails(params));
        dispatch(getOrganizationStructure(params));
        handleRefresh();
      }
    }
  }, [id, individualShow]);

  useEffect(() => {
    if (individualShow) {
      if (id || query.id) {
        neworganizationStructure();
        // handleUpdateValue();
      }
    }
  }, [
    individualDetails,
    organizationStructure,
    copEntityDetails,
    relatedEntityDetails,
  ]);
  // const handleUpdateValue = async () => {
  //   let individualShares = 0;
  //   let relatedEntityShares = 0;
  //   let corporateEntityShares = 0;

  //   if (individualDetails?.data?.rows) {
  //     for (const oneDoc of individualDetails.data.rows) {
  //       individualShares += oneDoc.noOfShares ? Number(oneDoc.noOfShares) : 0;
  //     }
  //   }

  //   if (relatedEntityDetails?.data?.rows) {
  //     for (const oneDoc of relatedEntityDetails.data.rows) {
  //       relatedEntityShares += oneDoc.noOfShares
  //         ? Number(oneDoc.noOfShares)
  //         : 0;
  //     }
  //   }

  //   if (copEntityDetails?.data?.rows) {
  //     for (const oneDoc of copEntityDetails.data.rows) {
  //       corporateEntityShares += oneDoc.noOfShares
  //         ? Number(oneDoc.noOfShares)
  //         : 0;
  //     }
  //   }

  //   const shares = {
  //     individualShares,
  //     relatedEntityShares,
  //     corporateEntityShares,
  //   };

  //   const totalShares = Object.values(shares).reduce(
  //     (sum, value) => sum + value,
  //     0
  //   );
  //   const issuedSharesPercentage =
  //     (totalShares / Number(entityData?.authorisedShares)) * 100;

  //   setTotalIssuedShares({
  //     totalIssuedShares: totalShares,
  //     issuedSharesPercentage: issuedSharesPercentage.toFixed(2) + "%",
  //   });
  // };
  const handleRefresh = async () => {
    const fetchData = async (url) => {
      const payload = { method: "GET", url };
      return fetchApi(payload).then((res) => res?.data?.rows);
    };
    const params = convertQueryParams({
      isAll: true,
      masterType: "individual",
      options: true,
    });
    const paramsName = convertQueryParams({
      isAll: true,
      masterType: "individual",
      exportAll: true,
    });

    const individual = await fetchData(`/client?${params}`);
    const individualName = await fetchData(`/client?${paramsName}`);
    let fullName = individualName
      ? individualName?.map((d) => {
          let obj = {
            value: d?._id,
            label: [d.firstName, d.middleName, d.lastName]
              .filter(Boolean)
              .join(" "),
          };
          return obj;
        })
      : [];
    let currency = Currency.map((a) => {
      let obj = {
        value: `${a.symbol}`,
        label: `${a.name} - ${a.code} - ${a.symbol}`,
      };
      return obj;
    });

    setCurrency(currency);
    setFullNameArray(fullName);
    handleindividualChange({});
    setIndividualArray(individual);
    setTableLoading(false);
  };
  const neworganizationStructure = async () => {
    if (organizationStructure?.data?.rows?.length) {
    } else {
      let obj = {
        master: true,
        enId: id ? id : query.id,
      };
      let payload = {
        method: "POST",
        url: `/organizationStructure`,
        data: obj,
      };

      fetchApi(payload)
        .then((res) => {
          if (res.status == 201 && res?.data?.length) {
            dispatch(getOrganizationStructure(queryParams));
          }
        })
        .catch((error) => {});
    }
  };

  const handleChangePep = (value) => {
    setPep(value);
  };

  const [individualFormSchema, setIndividualFormSchema] = useState([
    {
      name: "code",
      value: "",
      type: "select",
      label: "Masterfile Code",
      placeholder: "Enter masterfile code",
      disabled: true,
      loading: isLoading,
      showSearch: true,
    },
    {
      name: "title",
      value: "",
      type: "select",
      label: "Salutation",
      placeholder: "Enter salutation",
      disabled: true,
      options: [
        {
          value: "mr",
          label: "Mr",
        },
        {
          value: "mrs",
          label: "Mrs",
        },
        {
          value: "miss",
          label: "Miss",
        },
        {
          value: "ms",
          label: "Ms",
        },
        {
          value: "dr",
          label: "Dr",
        },
        {
          value: "rev",
          label: "Rev",
        },
      ],
    },
    {
      name: "firstName",
      value: "",
      type: "select",
      label: "First Name",
      showSearch: true,
      disabled: true,
      placeholder: "Enter first name",
    },
    {
      name: "middleName",
      value: "",
      type: "select",
      label: "Middle Name",
      showSearch: true,
      placeholder: "Enter Middle name",
      disabled: true,
    },
    {
      name: "lastName",
      showSearch: true,
      value: "",
      type: "select",
      label: "Last Name",
      placeholder: "Enter last name",
      disabled: true,
    },
    {
      name: "DOB",
      value: "", //
      type: "date",
      label: "Date of Birth",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
      disabled: true,
    },
    {
      name: "COB",
      value: "",
      type: "select",
      label: "Country of Birth",
      placeholder: "Select country of birth",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
      disabled: true,
    },
    {
      name: "nationality",
      value: "",
      type: "select",
      label: "Nationality",
      placeholder: "Select nationality",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
      disabled: true,
    },
    {
      name: "taxInfo",
      value: "",
      type: "text",
      label: "Tax Info Number",
      placeholder: "Enter tax info number",
      disabled: true,
    },
    {
      name: "passportNo",
      value: "",
      type: "text",
      label: "Passport Number",
      placeholder: "Enter passport number",
      disabled: true,
    },
    {
      name: "passportExp",
      value: "", //dayjs("12-12-2028", "DD/MM/YYYY")
      type: "date",
      label: "Expiry Date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
      disabled: true,
    },
    {
      name: "riskRating",
      value: "",
      type: "select",
      label: "Risk Level",
      placeholder: "Select risk level",
      disabled: true,
      options: [
        {
          value: "low",
          label: "Low",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "high",
          label: "High",
        },
      ],
    },
    {
      customClass: pep == "Y" ? "col-md-3" : "col-lg-1",
      label: "PEP",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${pep == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={pep}
            disabled={true}
            onChange={handleChangePep}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {pep == "Y" && (
            <input
              type="text"
              disabled={true}
              value={pepText}
              className="w-100"
              placeholder="Enter pep"
            />
          )}
        </div>
      ),
    },
    {
      customClass: sanction == "Y" ? "col-md-3" : "col-lg-1",
      label: "Sanction",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${sanction == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={sanction}
            disabled={true}
            onChange={handleChange}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {sanction == "Y" && (
            <input
              type="text"
              className="w-100"
              disabled={true}
              value={sanctionText}
              placeholder="Enter sanction"
            />
          )}
        </div>
      ),
    },
    {
      name: "contactDetails",
      value: "",
      type: "number",
      label: "Contact Details",
      placeholder: "Enter contact details",
      disabled: true,
    },
    {
      name: "email",
      value: "",
      type: "email",
      label: "Email Address",
      placeholder: "Enter email address",
      disabled: true,
    },
  ]);

  const categories = [
    { name: "Share Transfer", value: "Share Transfer", type: "main" },
    { name: "New Allocation", value: "New Allocation", type: "main" },
    { name: "Direct Entry ", value: "Direct Entry", type: "sub" },
    { name: "Joint Survivorship", value: "Joint Survivorship", type: "sub" },
  ];

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    getReceiverList();
  };
  const handleChangeCategory = () => {
    setSelectedCategory(null);
    setSelectedSubCategory(null);
  };
  const handleSubCategoryClick = (category) => {
    setSelectedSubCategory(category);
    let obj = { ...shareForm };
    let individualData = { ...formData };
    obj["show"] = true;
    if (selectedCategory == "Share Transfer") {
      if (category == "Direct Entry") {
        obj["type"] = "transfer";
      } else {
      }
      setFormData({
        ...individualData,
        shareEntry: "Share Transfer",
        nomineeShareEntry: false,
      });
      setShareForm(obj);
    }
    if (selectedCategory == "New Allocation") {
      if (category == "Direct Entry") {
        obj["type"] = "new";
      }
      setFormData({
        ...individualData,
        shareEntry: "New Allocation",
        nomineeShareEntry: false,
      });
      setShareForm(obj);
    }
  };

  const renderSubOptions = () => (
    <div className="certificate-links">
      <p>
        Selected Category:{" "}
        <strong>
          {categories.find((cat) => cat.value === selectedCategory)?.name}
        </strong>
      </p>

      {categories
        .filter((a) => a.type == "sub")
        .map((category) => (
          <button
            key={category.value}
            onClick={() => handleSubCategoryClick(category.value)}
            className="main-category-btn"
          >
            {category.name}
          </button>
        ))}

      <button className="change-category-btn" onClick={handleChangeCategory}>
        Change Type
      </button>
    </div>
  );

  const renderMainOptions = () => (
    <div className="main-categories">
      {categories
        .filter((a) => a.type == "main")
        .map((category) => (
          <button
            key={category.value}
            onClick={() => handleCategoryClick(category.value)}
            className="main-category-btn"
          >
            {category.name}
          </button>
        ))}
    </div>
  );
  const individualDeleteOnClick = async (id, index) => {
    setSelectedIndex(individualDetails.data.totalCount - index);
    setSelectedId(id);

    let payload = {};

    payload.method = "DELETE";
    payload.url = `/individualDetails?id=${id}`;

    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message);
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };
  const individualcolumns = [
    {
      title: "Individual Code",
      dataIndex: "individualCode",
      sorter: (a, b) => a.individualName - b.individualName,
    },
    {
      title: "Individual Name",
      dataIndex: "individualName",
      sorter: (a, b) => a.noShares - b.noShares,
    },
    {
      title: "Select Type",
      dataIndex: "shareType",
      sorter: (a, b) => a.shareType - b.shareType,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      format: "YYYY-MM-DD",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
    },
  ];

  individualcolumns.push({
    title: "Delete / Update",
    render: (_, { _id }, index) => (
      <div className="d-flex gap-2 align-items-center">
        <button
          className="action-btn delete-btn"
          onClick={() => individualDeleteOnClick(_id, index)}
        >
          <i className="far fa-trash-alt"></i>
        </button>
        <button
          className="action-btn"
          onClick={() => individualEditModalOnClick(_id, index)}
        >
          <i className="far fa-pencil"></i>
        </button>
      </div>
    ),
  });

  let directorFormSchema = [
    {
      name: "dateAppointment",
      value: "",
      type: "date",
      label: "Date of Appointment",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
    {
      name: "dateResignation",
      value: "",
      type: "date",
      label: "Date of Resignation",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
  ];

  let shareholderFormSchema = [
    {
      name: "sharesHeld",
      value: "",
      type: "number",
      label: "No. of Shares Held",
      placeholder: "Enter no. of shares held",
    },
    {
      name: "shareEntry",
      value: null,
      type: "select",
      options: [
        {
          value: "Share Transfer",
          label: "Share Transfer",
        },
        {
          value: "New Allocation",
          label: "New Allocation",
        },
      ],
      label: "Add share entry",
      placeholder: "Select",
    },
  ];

  let uboFormSchema = [
    {
      name: "totalSharesHeld",
      value: "",
      type: "number",
      label: "Total Shares Held",
      placeholder: "Enter total shares held",
    },
    {
      name: "ownershipPercentage",
      value: "",
      type: "text",
      label: "Ownership Percentage",
      placeholder: "Enter ownership percentage",
      disabled: true,
    },
  ];

  let nomineeShareholder = [
    {
      name: "nomineeSharesHeld",
      value: "",
      type: "number",
      label: "No. of Shares Held",
      placeholder: "Enter no. of shares held",
    },
    {
      name: "nomineeAgreementDate",
      value: "",
      type: "date",
      label: "Nominee Agreement Date",
      placeholder: "Enter nominee agreement Date",
    },
    {
      name: "nomineeShareEntry",
      value: null,
      type: "select",
      options: [
        {
          value: "Share Transfer",
          label: "Share Transfer",
        },
        {
          value: "New Allocation",
          label: "New Allocation",
        },
      ],
      label: "Add share entry",
      placeholder: "Select",
    },
  ];

  function removeArrItem(values, arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let index = 0; index < values.length; index++) {
        if (arr[i]?.name === values[index]?.name) {
          arr.splice(i, 1);
        }
      }
    }
  }
  const senderOnChange = async (value, fields) => {
    let receiveData = { ...receiveFormData };
    receiveData.createdAt = new Date();

    if (fields == "numberOfShares") {
      let noShares = value.numberOfShares ?? 0;

      if (Number(noShares) <= Number(formData.sharesHeld)) {
        receiveData.numberOfShares = Number(value.numberOfShares);
        if (noShares) {
          value.totalShares =
            Number(formData.sharesHeld) - Number(noShares) == 0
              ? 0
              : Number(formData.sharesHeld) - Number(noShares);

          let percentage =
            (Number(noShares) / Number(formData.sharesHeld) +
              Number(entityData?.totalIssuedShares)) *
            100;
          value.percentage = `${Math.round(Number(percentage) * 100) / 100} %`;
        }
      } else {
        // 10-01-2025
        sendNotify(
          "error",
          "Requested shares exceed the Individual's allocated total."
        );
      }
    }

    receiveData.shareCapital = entityData?.authorisedShares;

    if (fields == "totalShares") {
      receiveData.totalShares = value.totalShares;
    }
    if (fields == "effectiveDate") {
      receiveData.effectiveDate = value.effectiveDate;
    }
    if (fields == "to") {
      receiveData.to = value.to;
    }
    if (fields == "from") {
      receiveData.from = value.from;
    }

    setSenderFormData(value);
    setReceiveFormData(receiveData);
  };
  const receiverOnChange = async (value, fields) => {
    if (!value || !fields) {
      return;
    }

    if (fields === "masterId" || fields === "name") {
      let find = null;

      if (fields === "name") {
        find = receiverArray.find((e) => e.name === value?.name);
        if (!find) {
          find = receiverArray.find((e) => e.firstName === value?.name);
        }
      } else {
        find = receiverArray.find((e) => e._id === value.masterId);
      }

      if (find) {
        // totalShares;

        let findedDetails = [];
        if (find.masterType == "individual") {
          findedDetails = individualDetails?.data?.rows?.filter(
            (f) => f.invId == find._id
          );
        } else if (find.masterType == "cop-entity") {
          findedDetails = copEntityDetails?.data?.rows?.filter(
            (f) => f.copId == find._id
          );
        } else if (find.masterType == "entity") {
          findedDetails = relatedEntityDetails?.data?.rows?.filter(
            (f) => f.relEntity == find._id
          );
        }
        let noOfShares = 0;
        await asyncForEach(findedDetails, async (oneDoc) => {
          if (oneDoc.noOfShares) {
            noOfShares += Number(oneDoc.noOfShares);
          }
        });
        let totalShares = noOfShares + value.numberOfShares;

        const data = {
          masterId: find._id,
          name: find.name ?? find.firstName,
          masterType: find.masterType,
          totalShares: noOfShares + value.numberOfShares,
        };
        if (totalShares) {
          let percentage =
            (Number(totalShares) / Number(formData.sharesHeld) +
              Number(entityData.totalIssuedShares)) *
            100;
          data.percentage = `${Math.round(Number(percentage) * 100) / 100} %`;
        }

        setReceiveFormData((prev) => ({
          ...prev,
          ...data,
        }));
      }
    }
    if (fields == "numberOfShares") {
      let noShares = value.numberOfShares ?? 0;

      if (Number(noShares) <= Number(senderFormData.sharesHeld)) {
        if (noShares) {
          value.totalShares =
            Number(senderFormData.sharesHeld) - Number(noShares) == 0
              ? 0
              : Number(senderFormData.sharesHeld) - Number(noShares);

          //  percentage;

          let percentage =
            (Number(noShares) / Number(senderFormData.sharesHeld)) * 100;
          value.percentage = `${Math.round(Number(percentage) * 100) / 100} %`;
        }
      } else {
        // 10-01-2025
        // sendNotify(
        //   "error",
        //   "Requested shares exceed the Individual's allocated total."
        // );
      }
    }
  };
  const allocationOnChange = async (value, fields) => {
    if (!value || !fields) {
      return;
    }

    if (fields === "masterId" || fields === "name") {
      let find = null;

      if (fields === "name") {
        find = receiverArray.find((e) => e.name === value?.name);
        if (!find) {
          find = receiverArray.find((e) => e.firstName === value?.name);
        }
      } else {
        find = receiverArray.find((e) => e._id === value.masterId);
      }

      if (find) {
        const data = {
          masterId: find._id,
          name: find.name ?? find.firstName,
          masterType: find.masterType,
        };
        setAllocationFormData((prev) => ({
          ...prev,
          ...data,
        }));
      }
    }
  };
  const addNewChange = (values, checkStatus, name) => {
    setUboChecked(checkStatus);
    updateChecked("ubo", checkStatus);

    setNomineeChecked(false);

    let arr = [...individualFormSchema];
    removeArrItem(nomineeShareholder, arr);
    if (checkStatus) {
      arr.push(...values);
    } else {
      removeArrItem(values, arr);
    }
    setIndividualFormSchema(arr);
  };

  const addNewRow = (values, checkStatus, name) => {
    updateChecked(name, checkStatus);
    let arr = [...individualFormSchema];

    if (checkStatus) {
      removeArrItem(values, arr);
    }

    if (name == "directors") {
      if (checkStatus) {
        arr.push(...values);
        setDirectorChecked(true);
        setReserveChecked(false);
        setSecretaryChecked(false);
        setPresidentChecked(false);
      } else {
        removeArrItem(values, arr);
        setDirectorChecked(false);
      }
    }
    if (name == "reserve") {
      if (checkStatus) {
        arr.push(...values);
        setReserveChecked(true);
        setDirectorChecked(false);
        setSecretaryChecked(false);
        setPresidentChecked(false);
      } else {
        removeArrItem(values, arr);
        setReserveChecked(false);
      }
    }
    if (name == "president") {
      if (checkStatus) {
        arr.push(...values);
        setReserveChecked(false);
        setDirectorChecked(false);
        setSecretaryChecked(false);
        setPresidentChecked(true);
      } else {
        removeArrItem(values, arr);
        setPresidentChecked(false);
      }
    }
    if (name == "secretary") {
      if (checkStatus) {
        arr.push(...values);
        setReserveChecked(false);
        setDirectorChecked(false);
        setSecretaryChecked(true);
        setPresidentChecked(false);
      } else {
        removeArrItem(values, arr);
        setSecretaryChecked(false);
      }
    }
    setIndividualFormSchema(arr);
  };

  const addNewCustom = (values, name, checkStatus) => {
    let arr = [...individualFormSchema];
    let individualData = { ...formData };

    updateChecked(name, checkStatus);

    if (name == "shareholders") {
      if (checkStatus) {
        arr.push(...values);
        setShareholderChecked(true);
        setNomineeChecked(false);
        removeArrItem(nomineeShareholder, arr);
      } else {
        setShareForm({
          show: false,
          type: "",
        });
        setFormData({
          ...individualData,
          shareEntry: null,
          nomineeShareEntry: null,
        });
        removeArrItem(values, arr);
        setShareholderChecked(false);
      }
    }

    if (name == "nominee") {
      if (checkStatus) {
        arr.push(...values);
        setNomineeChecked(true);
        setShareholderChecked(false);
        setUboChecked(false);
        removeArrItem(shareholderFormSchema, arr);
        removeArrItem(uboFormSchema, arr);
      } else {
        setShareForm({
          show: false,
          type: "",
        });
        setFormData({
          ...individualData,
          shareEntry: null,
          nomineeShareEntry: null,
        });
        removeArrItem(values, arr);
        setNomineeChecked(false);
      }
    }
    setIndividualFormSchema(arr);
  };

  const addDetails = () => {
    setJSNewAllocation((prev) => [
      ...prev,
      {
        masterId: null,
        name: null,
      },
    ]);
  };
  const addDetailsJSr = () => {
    setJSreceiver((prev) => [
      ...prev,
      {
        masterId: null,
        name: null,
      },
    ]);
  };
  function updateChecked(key, checked) {
    setCheckedList((prevCheckedList) => {
      const updatedCheckedList = { ...prevCheckedList };

      if (key === "nominee" && checked) {
        updatedCheckedList["shareholders"] = false;
      }
      if (key === "shareholders" && checked) {
        updatedCheckedList["nominee"] = false;
      }

      updatedCheckedList[key] = checked;

      return updatedCheckedList;
    });
  }

  const senderForm = [
    {
      name: "masterId",
      value: "",
      type: "text",
      label: "Master file Code",
      placeholder: "Enter master file code",
      disabled: true,
    },
    {
      name: "name",
      value: "",
      type: "text",
      label: "Master file Name",
      placeholder: "Enter master file Name",
      disabled: true,
    },
    {
      name: "shareCapital",
      value: "",
      type: "text",
      label: "Authorised Share Capital",
      placeholder: "Enter authorised share Capital",
      disabled: true,
    },
    {
      name: "numberOfShares",
      value: "",
      type: "number",
      label: "Number of shares transferred",
      placeholder: "Enter number of shares transferred",
    },
    {
      name: "from",
      value: "",
      type: "text",
      label: "From",
      //  placeholder: "Enter Date (YYYY-MM-DD)",
      //  format: "YYYY-MM-DD",
    },
    {
      name: "to",
      value: "",
      type: "text",
      label: "To",
      // placeholder: "Enter Date (YYYY-MM-DD)",
      // format: "YYYY-MM-DD",
    },
    {
      name: "shareType",
      type: "select",
      options: [
        {
          value: "Ordinary Shares",
          label: "Ordinary Shares",
        },
        {
          value: "Preference Shares",
          label: "Preference Shares",
        },
        {
          value: "Non-Voting Shares",
          label: "Non-Voting Shares",
        },
        {
          value: "Shares Without Par Value",
          label: "Shares Without Par Value",
        },
      ],
      label: "Share type",
      placeholder: "Select",
    },
    {
      name: "parValue",
      value: "",
      type: "number",
      label: "Par Value",
      placeholder: "Enter par value",
    },
    {
      name: "currency",
      value: "",
      type: "select",
      showSearch: true,
      options: currency,
      label: "Currency",
      placeholder: "Enter currency",
    },

    {
      name: "effectiveDate",
      value: "",
      type: "date",
      label: "Effective date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
      upcoming: true,
    },
    {
      name: "ceasedDate",
      value: "",
      type: "date",
      label: "Ceased date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
    {
      name: "totalShares",
      value: "",
      type: "number",
      label: "Total Shares held",
      placeholder: "Enter total shares held",
    },
    {
      name: "percentage",
      value: "",
      type: "text",
      label: "Ownership Percentage",
      placeholder: "Enter ownership percentage",
      disabled: true,
    },
    {
      name: "certificateNumber",
      value: "",
      type: "text",
      label: "Certificate Number",
      placeholder: "Enter certificate number",
    },
    {
      name: "createdAt",
      value: "",
      type: "date",
      label: "Date of entry",
      placeholder: "Enter Date (YYYY-DD-MM)",
      format: "YYYY-DD-MM",
      disabled: true,
    },
    {
      name: "comment",
      value: "",
      type: "textarea",
      label: "Comment",
      placeholder: "Enter comment/details",
      customClass: "col-md-10",
    },
  ];

  const receiverForm = [
    {
      name: "masterId",
      value: "",
      type: "select",
      label: "Master file Code",
      placeholder: "Enter master file code",
    },
    {
      name: "name",
      value: "",
      type: "select",
      label: "Master file Name",
      placeholder: "Enter master file Name",
    },
    {
      name: "shareCapital",
      value: "",
      type: "text",
      label: "Authorised Share Capital",
      placeholder: "Enter authorised share Capital",
      disabled: true,
    },
    {
      name: "numberOfShares",
      value: "",
      type: "number",
      label: "Number of shares received",
      placeholder: "Enter Number of shares received",
      disabled: true,
    },
    {
      name: "from",
      value: "",
      type: "text",
      label: "From",
      //  placeholder: "Enter Date (YYYY-MM-DD)",
      // format: "YYYY-MM-DD",
      disabled: true,
    },
    {
      name: "to",
      value: "",
      type: "text",
      label: "To",
      // placeholder: "Enter Date (YYYY-MM-DD)",
      // format: "YYYY-MM-DD",
      disabled: true,
    },
    {
      name: "effectiveDate",
      value: "",
      type: "date",
      label: "Effective date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
      upcoming: true,
    },
    {
      name: "totalShares",
      value: "",
      type: "number",
      label: "Total Shares held",
      placeholder: "Enter total shares held",
      upcoming: true,
      disabled: true,
    },
    {
      name: "percentage",
      value: "",
      type: "text",
      label: "Ownership Percentage",
      placeholder: "Enter ownership percentage",
      disabled: true,
    },
    {
      name: "certificateNumber",
      value: "",
      type: "text",
      label: "Certificate Number",
      placeholder: "Enter certificate number",
    },
    {
      name: "createdAt",
      value: "",
      type: "date",
      label: "Date of entry",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
      disabled: true,
    },
  ];

  const allocationForm = [
    {
      name: "masterId",
      value: "",
      type: "select",
      label: "Master file Code",
      placeholder: "Enter master file code",
      disabled: true,
    },
    {
      name: "name",
      value: "",
      type: "select",
      label: "Master file Name",
      placeholder: "Enter master file Name",
      disabled: true,
    },
    {
      name: "shareCapital",
      value: "",
      type: "text",
      label: "Authorised Share Capital",
      placeholder: "Enter authorised share Capital",
      disabled: true,
    },
    {
      name: "numberOfShares",
      value: "",
      type: "number",
      label: "Number of shares allocated",
      placeholder: "Enter number of shares allocated",
    },
    {
      name: "from",
      value: "",
      type: "text",
      label: "From",
      placeholder: "Enter number of From",
    },
    {
      name: "to",
      value: "",
      type: "text",
      label: "To",
      placeholder: "Enter number of To",
    },
    {
      name: "shareType",
      type: "select",
      options: [
        {
          value: "Ordinary Shares",
          label: "Ordinary Shares",
        },
        {
          value: "Preference Shares",
          label: "Preference Shares",
        },
        {
          value: "Non-Voting Shares",
          label: "Non-Voting Shares",
        },
        {
          value: "Shares Without Par Value",
          label: "Shares Without Par Value",
        },
      ],
      label: "Share type",
      placeholder: "Select",
    },
    {
      name: "parValue",
      value: "",
      type: "number",
      label: "Par Value",
      placeholder: "Enter par value",
    },
    {
      name: "currency",
      value: "",
      type: "select",
      showSearch: true,
      options: currency,
      label: "Currency",
      placeholder: "Enter currency",
    },

    {
      name: "effectiveDate",
      value: "",
      type: "date",
      label: "Effective date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
      upcoming: true,
    },
    {
      name: "ceasedDate",
      value: "",
      type: "date",
      label: "Ceased date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },

    {
      name: "totalShares",
      value: "",
      type: "number",
      label: "Total Shares held",
      placeholder: "Enter total shares held",
      disabled: true,
    },
    {
      name: "percentage",
      value: "",
      type: "text",
      label: "Ownership Percentage",
      placeholder: "Enter ownership percentage",
      disabled: true,
    },
    {
      name: "certificateNumber",
      value: "",
      type: "text",
      label: "Certificate Number",
      placeholder: "Enter certificate number",
    },

    {
      name: "createdAt",
      value: "",
      type: "date",
      label: "Date of entry",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
    {
      name: "comment",
      value: "",
      type: "textarea",
      label: "Comment",
      placeholder: "Enter comment/details",
      customClass: "col-md-10",
    },
  ];

  const JSallocationForm = [
    {
      name: "shareCapital",
      value: "",
      type: "text",
      label: "Authorised Share Capital",
      placeholder: "Enter authorised share Capital",
      disabled: true,
    },
    {
      name: "numberOfShares",
      value: "",
      type: "number",
      label: "Number of shares allocated",
      placeholder: "Enter number of shares allocated",
    },
    {
      name: "from",
      value: "",
      type: "text",
      label: "From",
      placeholder: "Enter number of From",
    },
    {
      name: "to",
      value: "",
      type: "text",
      label: "To",
      placeholder: "Enter number of To",
    },
    {
      name: "shareType",
      type: "select",
      options: [
        {
          value: "Ordinary Shares",
          label: "Ordinary Shares",
        },
        {
          value: "Preference Shares",
          label: "Preference Shares",
        },
        {
          value: "Non-Voting Shares",
          label: "Non-Voting Shares",
        },
        {
          value: "Shares Without Par Value",
          label: "Shares Without Par Value",
        },
      ],
      label: "Share type",
      placeholder: "Select",
    },
    {
      name: "parValue",
      value: "",
      type: "number",
      label: "Par Value",
      placeholder: "Enter par value",
    },
    {
      name: "currency",
      value: "",
      type: "select",
      showSearch: true,
      options: currency,
      label: "Currency",
      placeholder: "Enter currency",
    },

    {
      name: "effectiveDate",
      value: "",
      type: "date",
      label: "Effective date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
      upcoming: true,
    },
    {
      name: "ceasedDate",
      value: "",
      type: "date",
      label: "Ceased date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },

    {
      name: "totalShares",
      value: "",
      type: "number",
      label: "Total Shares held",
      placeholder: "Enter total shares held",
      disabled: true,
    },
    {
      name: "percentage",
      value: "",
      type: "text",
      label: "Ownership Percentage",
      placeholder: "Enter ownership percentage",
      disabled: true,
    },
    {
      name: "certificateNumber",
      value: "",
      type: "text",
      label: "Certificate Number",
      placeholder: "Enter certificate number",
    },

    {
      name: "createdAt",
      value: "",
      type: "date",
      label: "Date of entry",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
    },
    {
      name: "comment",
      value: "",
      type: "textarea",
      label: "Comment",
      placeholder: "Enter comment/details",
      customClass: "col-md-10",
    },
  ];
  const JSreceiverForm = [
    {
      name: "shareCapital",
      value: "",
      type: "text",
      label: "Authorised Share Capital",
      placeholder: "Enter authorised share Capital",
      disabled: true,
    },
    {
      name: "numberOfShares",
      value: "",
      type: "number",
      label: "Number of shares received",
      placeholder: "Enter Number of shares received",
      disabled: true,
    },
    {
      name: "from",
      value: "",
      type: "text",
      label: "From",
      //  placeholder: "Enter Date (YYYY-MM-DD)",
      // format: "YYYY-MM-DD",
      disabled: true,
    },
    {
      name: "to",
      value: "",
      type: "text",
      label: "To",
      // placeholder: "Enter Date (YYYY-MM-DD)",
      // format: "YYYY-MM-DD",
      disabled: true,
    },
    {
      name: "effectiveDate",
      value: "",
      type: "date",
      label: "Effective date",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
      upcoming: true,
    },
    {
      name: "totalShares",
      value: "",
      type: "number",
      label: "Total Shares held",
      placeholder: "Enter total shares held",
      upcoming: true,
      disabled: true,
    },
    {
      name: "percentage",
      value: "",
      type: "text",
      label: "Ownership Percentage",
      placeholder: "Enter ownership percentage",
      disabled: true,
    },
    {
      name: "certificateNumber",
      value: "",
      type: "text",
      label: "Certificate Number",
      placeholder: "Enter certificate number",
    },
    {
      name: "createdAt",
      value: "",
      type: "date",
      label: "Date of entry",
      placeholder: "Enter Date (YYYY-MM-DD)",
      format: "YYYY-MM-DD",
      disabled: true,
    },
  ];
  const findOneIndividual = async (value) => {
    setLoading(true);
    let ctr = {};
    if (value.code) {
      if (value.code.startsWith("INV")) {
        ctr.code = value.code;
      } else {
        ctr.id = value.code;
      }
    } else {
      ctr = value;
    }

    let params = convertQueryParams(ctr);
    let payload = {
      method: "GET",
      url: `/individual?${params}`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res?.data?.rows?.[0];
    });
    if (data._id) {
      setFormData((prev) => ({
        ...prev,
        ...data,
      }));
    }
    setLoading(false);
  };

  const getReceiverList = async () => {
    const fetchData = async (url) => {
      const payload = { method: "GET", url };
      return fetchApi(payload).then((res) => res?.data?.rows);
    };
    const params = convertQueryParams({
      isAll: true,
      alltype: ["individual", "cop-entity"],
      options: true,
    });
    const receiverList = await fetchData(`/client?${params}`);

    const paramsName = convertQueryParams({
      isAll: true,
      alltype: ["individual", "cop-entity"],
      exportAll: true,
    });
    const receiverName = await fetchData(`/client?${paramsName}`);
    setReceiverArray(receiverName);
    let namelist = receiverName
      ? receiverName?.map((d) => {
          let obj = {
            value: d?.firstName ?? d?.name,

            label: d?.name
              ? d?.name
              : ` ${
                  d?.firstName && d?.lastName
                    ? `${d?.firstName} ${
                        d?.middleName && d?.middleName !== "."
                          ? d?.middleName + " "
                          : ""
                      }${d?.lastName}`
                    : ""
                }`,
          };
          return obj;
        })
      : [];
    let nameAndIdlist = receiverName
      ? receiverName?.map((d) => {
          let obj = {
            value: d?._id,

            label: d?.name
              ? d?.name
              : ` ${
                  d?.firstName && d?.lastName
                    ? `${d?.firstName} ${
                        d?.middleName && d?.middleName !== "."
                          ? d?.middleName + " "
                          : ""
                      }${d?.lastName}`
                    : ""
                }`,
          };
          return obj;
        })
      : [];
    let data = {
      name: namelist,
      masterId: receiverList,
      nameAndIdlist: nameAndIdlist,
    };
    setReceiverOptions(data);
  };
  const individualOnChange = async (value, field) => {
    let obj = { ...shareForm };
    let individualData = { ...formData };

    if (
      field == "shareEntry" ||
      field == "nomineeShareEntry" ||
      field == "sharesHeld"
    ) {
      setFormData({
        ...individualData,
        shareEntry: value[field],
        nomineeShareEntry: value[field],
      });
      obj["show"] = true;
      if (value[field] == "Share Transfer") {
        obj["type"] = "transfer";
        setShareForm(obj);
      }
      if (value[field] == "New Allocation") {
        obj["type"] = "new";
        setShareForm(obj);
      }

      setSenderFormData({
        name: ` ${
          formData.firstName && formData.lastName
            ? `${formData.firstName} ${
                formData.middleName && formData.middleName !== "."
                  ? formData.middleName + " "
                  : ""
              }${formData.lastName}`
            : ""
        }`,
        shareCapital: entityData?.authorisedShares,
        masterId: formData._id,
        createdAt: new Date(),
      });

      let percentage =
        (Number(value.sharesHeld) /
          (Number(value.sharesHeld) + Number(entityData?.totalIssuedShares))) *
        100;
      percentage = `${Math.round(Number(percentage))} %`;
      setAllocationFormData({
        name: ` ${
          formData.firstName && formData.lastName
            ? `${formData.firstName} ${
                formData.middleName && formData.middleName !== "."
                  ? formData.middleName + " "
                  : ""
              }${formData.lastName}`
            : ""
        }`,
        shareCapital: entityData?.authorisedShares,
        totalShares: value.sharesHeld,
        numberOfShares: value.sharesHeld,
        percentage: percentage,
        masterId: formData._id,
        createdAt: new Date(),
      });
      getReceiverList();
    }
    if (
      !["title", "nationality", "code", "middleName", "lastName"].includes(
        field
      )
    ) {
      let data = { ...objData };
      if (
        data.totalSharesHeld !== value.totalSharesHeld ||
        !data.totalSharesHeld
      ) {
        const balancesheet =
          Number(entityData?.authorisedShares ?? 0) -
          Number(entityData?.totalIssuedShares ?? 0);

        console.log(balancesheet, "balancesheet");

        const noShares = Number(value.sharesHeld ?? 0);

        if (noShares <= balancesheet) {
          const totalSharesHeld = Number(value.totalSharesHeld ?? 0);
          const currentSharesHeld = Number(formData.sharesHeld ?? 0);
          const totalIssued = Number(entityData?.totalIssuedShares ?? 0);

          const ownershipPercentage =
            (totalSharesHeld / (currentSharesHeld + totalIssued)) * 100;

          const percentage =
            (currentSharesHeld / (currentSharesHeld + totalIssued)) * 100;

          const updatedValues = {
            ...value,
            _id: formData?._id ?? "",
            totalSharesHeld,
            ownershipPercentage: `${
              Math.round(ownershipPercentage * 100) / 100
            }`,
            percentage: `${Math.round(percentage)}`,
          };

          setFormData((prev) => ({
            ...prev,
            ...updatedValues,
          }));
        } else {
          setFormData((prev) => ({
            ...prev,
            ...value,
          }));

          sendNotify(
            "error",
            `Insufficient available shares. Only ${balancesheet} shares remain from the entity’s authorized share capital.`
          );
        }
      }
    } else {
      let {
        totalSharesHeld,
        code,
        firstName,
        lastName,
        middleName,
        nationality,
        title,
      } = value;

      let param = {};
      if (field == "code" && !totalSharesHeld) {
        findOneIndividual({ code: code });

        if (code.startsWith("INV")) {
          param.code = code;
        } else {
          param.id = code;
        }
      }

      if (field == "firstName") {
        findOneIndividual({ firstName: firstName });
        param.firstName = firstName;
      }
      if (field == "lastName") {
        findOneIndividual({ lastName: lastName });
        param.lastName = lastName;
      }
      if (field == "middleName") {
        findOneIndividual({ middleName: middleName });
        param.middleName = middleName;
      }
      if (field == "nationality") {
        param.nationality = nationality;
      }
      if (field == "title") {
        findOneIndividual({ title: title });
        param.title = title;
      }

      //handleindividualChange(param);

      setObjData(value);
    }
  };

  const shareTableColumn = [
    {
      title: "Action type",
      dataIndex: "transactionType",
      sorter: (a, b) => a.transactionType - b.transactionType,
    },
    {
      title: "Master file Code",
      dataIndex: "senderMasterId",
      sorter: (a, b) => a.code - b.code,
    },
    {
      title: "Share Type",
      dataIndex: "shareType",
      sorter: (a, b) => a.shareType - b.shareType,
    },
    {
      title: "Authorised Share Capital",
      dataIndex: "sharesHeld",
      sorter: (a, b) => a.shareCapital - b.shareCapital,
    },
    {
      title: "From",
      dataIndex: "from",
      sorter: (a, b) => a.from - b.from,
    },
    {
      title: "To",
      dataIndex: "to",
      sorter: (a, b) => a.to - b.to,
    },
    {
      title: "Par Value",
      dataIndex: "parValue",
      sorter: (a, b) => a.parValue - b.parValue,
    },
    {
      title: "Effective date",
      dataIndex: "effectiveDate",
      sorter: (a, b) => a.effectiveDate - b.effectiveDate,
      format: "YYYY-MM-DD",
    },
    {
      title: "Total Shares held",
      dataIndex: "sharesHeld",
      sorter: (a, b) => a.totalShares - b.totalShares,
    },
    {
      title: "Ownership Percentage",
      dataIndex: "percentage",
      sorter: (a, b) => a.percentage - b.percentage,
    },
    {
      title: "Certificate Number",
      dataIndex: "shareNumber",
      sorter: (a, b) => a.shareNumber - b.shareNumber,
    },
    {
      title: "Date of entry",
      dataIndex: "createdAt1",
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    {
      title: "Comment",
      render: (_, { _id, comment }, index) => (
        <Popover
          className="custom-popover"
          placement="rightBottom"
          content={<p className="mb-0">{comment}</p>}
        >
          <button className="primary-btn">View</button>
        </Popover>
      ),
    },
  ];

  const handleindividualChange = async (value) => {
    let query = convertQueryParams(value);

    let payload = {
      method: "get",
      url: `/individual?${query}`,
    };
    let individualData = await fetchApi(payload).then((res) => {
      return res?.data;
    });

    if (individualData?.rows.length) {
      setFormData((prev) => ({
        ...prev,
        ...individualData?.rows[0],
      }));
    }
  };

  const saveOnClick = () => {
    saveData(selectedId);
  };

  const saveData = async () => {
    let sharesHeld = formData.sharesHeld || formData.nomineeSharesHeld;
    const balancesheet =
      Number(entityData?.authorisedShares ?? 0) -
      Number(entityData?.totalIssuedShares ?? 0);

    const noShares = Number(sharesHeld ?? 0);

    if (noShares <= balancesheet) {
      let transaction = {};
      if (selectedSubCategory == "Direct Entry") {
        if (formData.shareEntry == "Share Transfer") {
          let receiverFormSchema = receiverFormRef.current.getPayload();
          let senderFormSchema = senderFormRef.current.getPayload();
          if (receiverFormSchema.masterId) {
            transaction = {
              receiverformFields: [
                {
                  masterId: receiverFormSchema.masterId,
                  masterType: receiveFormData.masterType,
                  shareCapital: receiverFormSchema.shareCapital,
                  numberOfShares: receiverFormSchema.numberOfShares,
                  from: receiverFormSchema.from,
                  to: receiverFormSchema.to,
                  effectiveDate: receiverFormSchema.effectiveDate,
                  totalShares: receiverFormSchema.totalShares,
                  percentage: receiverFormSchema.percentage,
                  certificateNumber: receiverFormSchema.certificateNumber,
                },
              ],
              senderFormFields: {
                masterId: senderFormSchema.masterId,
                masterType: "individual",
                shareCapital: senderFormSchema.shareCapital,
                numberOfShares: senderFormSchema.numberOfShares,
                from: senderFormSchema.from,
                to: senderFormSchema.to,
                effectiveDate: senderFormSchema.effectiveDate,
                totalShares: senderFormSchema.totalShares,
                percentage: senderFormSchema.percentage,
                certificateNumber: senderFormSchema.certificateNumber,
                shareType: senderFormSchema.shareType,
                parValue: senderFormSchema.parValue,
                comment: senderFormSchema.comment,
                currency: senderFormSchema.currency,
                ceasedDate: senderFormSchema.ceasedDate,
              },
            };
          } else {
            sendNotify("error", "plz select receiver.");
          }
        } else if (formData.shareEntry == "New Allocation") {
          let allocationFormSchema = allocationFormRef.current.getPayload();
          transaction = {
            allocationformFields: [
              {
                masterId: allocationFormSchema.masterId,
                masterType: "individual",
                shareCapital: allocationFormSchema.shareCapital,
                numberOfShares: allocationFormSchema.numberOfShares,
                from: allocationFormSchema.from,
                to: allocationFormSchema.to,
                effectiveDate: allocationFormSchema.effectiveDate,
                totalShares: allocationFormSchema.totalShares,
                percentage: allocationFormSchema.percentage,
                certificateNumber: allocationFormSchema.certificateNumber,
                shareType: allocationFormSchema.shareType,
                parValue: allocationFormSchema.parValue,
                comment: allocationFormSchema.comment,
                currency: allocationFormSchema.currency,
                ceasedDate: allocationFormSchema.ceasedDate,
              },
            ],
          };
          // (transaction.allocationFormSchema = allocationFormSchema);
        }
      } else {
        const masterType = new Map(
          receiverArray.map((a) => [a._id, a.masterType])
        );
        if (formData.shareEntry == "Share Transfer") {
          let receiverFormSchema = receiverFormRef.current.getPayload();
          let senderFormSchema = senderFormRef.current.getPayload();

          if (JSreceiver.length) {
            let receiverdata = JSreceiver.map((a) => {
              return {
                masterId: a.masterId,
                masterType: masterType.get(a.masterId),
                shareCapital: receiverFormSchema.shareCapital,
                numberOfShares: receiverFormSchema.numberOfShares,
                from: receiverFormSchema.from,
                to: receiverFormSchema.to,
                effectiveDate: receiverFormSchema.effectiveDate,
                totalShares: receiverFormSchema.totalShares,
                percentage: receiverFormSchema.percentage,
                certificateNumber: receiverFormSchema.certificateNumber,
              };
            });

            transaction = {
              receiverformFields: receiverdata,
              senderFormFields: {
                masterId: senderFormSchema.masterId,
                masterType: "individual",
                shareCapital: senderFormSchema.shareCapital,
                numberOfShares: senderFormSchema.numberOfShares,
                from: senderFormSchema.from,
                to: senderFormSchema.to,
                effectiveDate: senderFormSchema.effectiveDate,
                totalShares: senderFormSchema.totalShares,
                percentage: senderFormSchema.percentage,
                certificateNumber: senderFormSchema.certificateNumber,
                shareType: senderFormSchema.shareType,
                parValue: senderFormSchema.parValue,
                comment: senderFormSchema.comment,
                currency: senderFormSchema.currency,
                ceasedDate: senderFormSchema.ceasedDate,
              },
            };
          } else {
            sendNotify("error", "plz select receiver00");
          }
        } else if (formData.shareEntry == "New Allocation") {
          let allocationFormSchema = allocationFormRef.current.getPayload();

          let allocation = JSNewAllocation.map((a) => {
            return {
              masterId: a.masterId,
              masterType: masterType.get(a.masterId),
              shareCapital: allocationFormSchema.shareCapital,
              numberOfShares: allocationFormSchema.numberOfShares,
              from: allocationFormSchema.from,
              to: allocationFormSchema.to,
              effectiveDate: allocationFormSchema.effectiveDate,
              totalShares: allocationFormSchema.totalShares,
              percentage: allocationFormSchema.percentage,
              certificateNumber: allocationFormSchema.certificateNumber,
              shareType: allocationFormSchema.shareType,
              parValue: allocationFormSchema.parValue,
              comment: allocationFormSchema.comment,
              currency: allocationFormSchema.currency,
              ceasedDate: allocationFormSchema.ceasedDate,
            };
          });

          transaction = {
            allocationformFields: allocation,
          };
        }
      }
      const prepareData = () => ({
        invId: formData._id,
        enId: entityData._id,
        resignation: formData.dateResignation,

        appointment: formData.dateAppointment
          ? moment(new Date(formData.dateAppointment)).format(
              "YYYY-MM-DD:HH:mm:ss"
            )
          : "",
        noOfShares: formData.sharesHeld || formData.nomineeSharesHeld,
        totalShares: formData.totalSharesHeld,
        ownershipPer: formData.ownershipPercentage,
        agreementDate: formData.nomineeAgreementDate,
        shareEntry: formData.shareEntry,
        subType: selectedSubCategory,
        checkedList: checkedList,
        transaction: transaction,
      });

      const handleCheckedList = () => {
        const organizationArray = organizationStructure.data.rows.filter(
          (a) => a.pid == null
        );
        const fullName = `${formData.title} ${formData.firstName} ${
          formData.middleName || ""
        } ${formData.lastName}`
          .replace(/\s+/g, " ")
          .trim();

        const createObj = (role, shares, percentage = null) => {
          const roleData = organizationArray.find((i) => i.role === role);
          return roleData
            ? {
                name: fullName,
                pid: roleData._id,
                color: roleData.color,
                role: "Individual",
                pType: role,
                shares,
                percentage,
              }
            : null;
        };

        const objArray = [];
        if (checkedList.directors || checkedList.reserve) {
          objArray.push({
            ...createObj("Director"),
            reserveDirector: checkedList.reserve,
          });
        }
        if (checkedList.shareholders || checkedList.nominee) {
          const sharePercentage = `${Math.round(
            (Number(formData.sharesHeld) /
              (Number(formData.sharesHeld) +
                Number(entityData?.totalIssuedShares))) *
              100
          )}%`;
          objArray.push(
            createObj("Shareholder", formData.sharesHeld, sharePercentage)
          );
        }
        if (checkedList.ubo) {
          objArray.push(
            createObj(
              "UBO",
              formData.totalSharesHeld,
              formData.ownershipPercentage
            )
          );
        }

        return objArray.filter(Boolean); // Filter out any null values
      };

      const handleApiPost = async (url, data) => {
        try {
          const response = await fetchApi({ method: "POST", url, data });
          return response;
        } catch (error) {
          sendNotify("error", error?.message);
          throw error;
        }
      };
      const handleApiPut = async (url, data) => {
        try {
          const response = await fetchApi({ method: "PUT", url, data });
          return response;
        } catch (error) {
          sendNotify("error", error?.message);
          throw error;
        }
      };
      const processRows = async (objArray) => {
        for (const obj of objArray) {
          await handleApiPost(`/organizationStructure`, obj);
        }
      };

      if (formData._id) {
        const data = prepareData();
        if (Object.values(checkedList).some(Boolean)) {
          try {
            const response = selectedId
              ? await handleApiPut(
                  `/individualDetails/update/${selectedId}`,
                  data
                )
              : await handleApiPost(`/individualDetails`, data);
            if (response?.status == 208) {
              sendNotify("error", response?.message);
            } else {
              sendNotify("success", response?.message);
              closeModalOnClick();
              setCheckedList({
                directors: false,
                shareholders: false,
                ubo: false,
                nominee: false,
                reserve: false,
              });
            }
            if (organizationStructure?.data?.rows?.length) {
              const objArray = handleCheckedList();
              await processRows(objArray);
            } else {
              const params = {
                ...queryParams,
                enId: id || query.id,
              };
              dispatch(getIndividualDetails(params));
              dispatch(getOrganizationStructure(params));
            }
          } catch (error) {
            sendNotify("error", error?.message);
          }

          const params = { ...queryParams, enId: id || query.id };
          dispatch(getIndividualDetails(params));
        } else {
          sendNotify("error", "Please enter at least one name");
        }
      } else {
        sendNotify("error", "Please enter at least one name");
      }
    } else {
      sendNotify(
        "error",
        `Insufficient available shares. Only ${balancesheet} shares remain from the entity’s authorized share capital.`
      );
    }
  };

  const individualDropDownOnClick = async (value) => {
    let params = {};
    params.isAll = true;
    params.masterType = "individual";
    params.code = value;
    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/client?${query}`,
    };
    let clientData = await fetchApi(payload).then((res) => {
      return res?.data;
    });
    let list = clientData.rows.map((d) => {
      let obj = {
        value: d?._id,
        label: d?.code,
      };
      return obj;
    });

    setIndividualArray(list);
  };
  const openIndividualModal = (value) => {
    setIndividualShow(value);
  };
  const individualModalOnClick = () => {
    setSelectedId("");
    setUboChecked(false);
    setDirectorChecked(false);
    setReserveChecked(false);
    setSecretaryChecked(false);
    setPresidentChecked(false);
    setShareholderChecked(false);
    setNomineeChecked(false);
    setFormData({});
    setOpenIndividual(true);
  };
  const closeModalOnClick = () => {
    setFindId(null);
    setSelectedId("");
    setObjData({});
    setUboChecked(false);
    setDirectorChecked(false);
    setReserveChecked(false);
    setSecretaryChecked(false);
    setPresidentChecked(false);
    setShareholderChecked(false);
    setNomineeChecked(false);
    setFormData({});
    setOpenIndividual(false);
    setCheckedList({
      directors: false,
      shareholders: false,
      ubo: false,
      nominee: false,
      reserve: false,
    });
    setShareForm({
      show: false,
      type: "",
    });
    clearFormRef();
    clearArrItem();
  };
  const getPagination = (current, pageSize) => {
    setTableLoading(true);
    let params = { ...queryParams };
    params._start = (current - 1) * pageSize;
    params._limit = pageSize;
    params.enId = id ? id : query.id;
    dispatch(getIndividualDetails(params));
    setQueryParams(params);
    setTableLoading(false);
  };
  const handleRefreshTransaction = async (data) => {
    try {
      const fetchData = async (url) => {
        const payload = { method: "GET", url };
        const response = await fetchApi(payload);
        return response?.data?.rows || [];
      };

      const clientParams = convertQueryParams({
        isAll: true,
        options: true,
      });
      const clientData = await fetchData(`/client?${clientParams}`);

      const clientDataMap = new Map(
        clientData.map((item) => [item.value, item.label])
      );

      const transformedResponse = {
        rows: data.map((row) => ({
          ...row,
          senderMasterId: row.senderForm?.length
            ? clientDataMap.get(row.senderForm[0]?.masterId)
            : row.allocationForm?.length
            ? clientDataMap.get(row.allocationForm[0]?.masterId)
            : null,

          shareNumber: row.senderForm?.length
            ? row.senderForm[0]?.certificateNumber
            : row.receiverForm?.length
            ? row.receiverForm[0]?.certificateNumber
            : row.allocationForm?.length
            ? row.allocationForm[0]?.certificateNumber
            : null,

          noShares: row.senderForm?.length
            ? row.senderForm[0]?.numberOfShares
            : row.receiverForm?.length
            ? row.receiverForm[0]?.numberOfShares
            : row.allocationForm?.length
            ? row.allocationForm[0]?.numberOfShares
            : null,

          sharesHeld: row.senderForm?.length
            ? row.senderForm[0]?.shareCapital
            : row.receiverForm?.length
            ? row.receiverForm[0]?.shareCapital
            : row.allocationForm?.length
            ? row.allocationForm[0]?.shareCapital
            : null,

          parValue: row.senderForm?.length
            ? row.senderForm[0]?.parValue
            : row.receiverForm?.length
            ? row.receiverForm[0]?.parValue
            : row.allocationForm?.length
            ? row.allocationForm[0]?.parValue
            : null,

          ceasedDate: row.senderForm?.length
            ? moment(new Date(row.senderForm[0]?.ceasedDate)).format(
                "YYYY-MM-DD"
              )
            : row.receiverForm?.length
            ? moment(new Date(row.receiverForm[0]?.ceasedDate)).format(
                "YYYY-MM-DD"
              )
            : row.allocationForm?.length
            ? moment(new Date(row.allocationForm[0]?.ceasedDate)).format(
                "YYYY-MM-DD"
              )
            : null,
          createdAt1: moment(new Date(row.createdAt)).format("YYYY-MM-DD"),
          effectiveDate: row.senderForm?.length
            ? moment(new Date(row.senderForm[0]?.effectiveDate)).format(
                "YYYY-MM-DD"
              )
            : row.receiverForm?.length
            ? moment(new Date(row.receiverForm[0]?.effectiveDate)).format(
                "YYYY-MM-DD"
              )
            : row.allocationForm?.length
            ? moment(new Date(row.allocationForm[0]?.effectiveDate)).format(
                "YYYY-MM-DD"
              )
            : null,

          totalShares: row.senderForm?.length
            ? row.senderForm[0]?.totalShares
            : row.receiverForm?.length
            ? row.receiverForm[0]?.totalShares
            : row.allocationForm?.length
            ? row.allocationForm[0]?.totalShares
            : null,

          percentage: row.senderForm?.length
            ? row.senderForm[0]?.percentage
            : row.receiverForm?.length
            ? row.receiverForm[0]?.percentage
            : row.allocationForm?.length
            ? row.allocationForm[0]?.percentage
            : null,

          comment: row.allocationForm?.length
            ? row.allocationForm[0]?.comment
            : null,

          currency: row.allocationForm?.length
            ? row.allocationForm[0]?.currency
            : null,
          from: row.senderForm?.length
            ? row.senderForm[0]?.from
            : row.receiverForm?.length
            ? row.receiverForm[0]?.from
            : row.allocationForm?.length
            ? row.allocationForm[0]?.from
            : null,
          to: row.senderForm?.length
            ? row.senderForm[0]?.to
            : row.receiverForm?.length
            ? row.receiverForm[0]?.to
            : row.allocationForm?.length
            ? row.allocationForm[0]?.to
            : null,
          shareType: row.senderForm?.length
            ? row.senderForm[0]?.shareType
            : row.receiverForm?.length
            ? row.receiverForm[0]?.shareType
            : row.allocationForm?.length
            ? row.allocationForm[0]?.shareType
            : null,

          combinedForm: {
            sender: row.senderForm || [],
            receiver: row.receiverForm || [],
            allocation: row.allocationForm || [],
          },
        })),
      };
      setShareTableData(transformedResponse.rows);
    } catch (error) {}
  };
  const individualEditModalOnClick = async (id, index) => {
    // setSelectedIndex(index + 1);
    setSelectedIndex(individualDetails.data.totalCount - index);
    setSelectedId(id);

    const details = individualDetails.data.rows.find((e) => e._id === id);
    if (!details) return;

    const params = {
      isAll: true,
      masterType: "individual",
      code: details.individualCode,
    };
    const query = convertQueryParams(params);
    const payload = {
      method: "GET",
      url: `/client?${query}`,
    };

    try {
      const clientData = await fetchApi(payload).then(
        (res) => res?.data?.rows || []
      );
      let arr = [...individualFormSchema];
      setIndividualArray(
        clientData.map((d) => ({ value: d?._id, label: d?.code }))
      );

      const finded = clientData.find((e) => e._id === details.individualId);
      const {
        shareholders,
        ubo,
        nominee,
        directors,
        reserve,
        president,
        secretary,
      } = details.checkedList;

      if (finded) {
        const newItem = {
          ...finded,
          pep: finded.details.pep,
          sanction: finded.details.sanction,
          pepText: finded.details.pepText,
          sanctionText: finded.details.sanctionText,
          riskRating: finded.details.riskRating,
          address: { ...finded.details.address },
          sharesHeld: details.noOfShares,
          dateResignation: details.resignation,
          dateAppointment: moment(new Date(details.appointment)).format(
            "YYYY-MM-DD:HH:mm:ss"
          ),
          totalSharesHeld: finded.relatedDetailData?.[0].totalShares,
          ownershipPercentage: finded.relatedDetailData?.[0].ownershipPer,
        };
        setSanctionText(finded.details.sanctionText);
        setPepText(finded.details.pepText);
        setFormData(newItem);
        setObjData(newItem);
      }
      setCheckedList(details.checkedList);
      setUboChecked(ubo);
      setDirectorChecked(directors);
      setReserveChecked(reserve);
      setShareholderChecked(shareholders);
      setNomineeChecked(nominee);
      setSecretaryChecked(secretary);
      setPresidentChecked(president);
      setOpenIndividual(true);

      if (details.transaction) {
        handleRefreshTransaction(details.transaction);
      }
      if (directors) {
        addNewRow(directorFormSchema, directors, "directors");
        arr.push(...directorFormSchema);
      }
      if (reserve) {
        addNewRow(directorFormSchema, reserve, "reserve");
        arr.push(...directorFormSchema);
      }
      if (ubo) {
        addNewChange(uboFormSchema, ubo, "ubo");
        arr.push(...uboFormSchema);
      }
      if (shareholders) {
        addNewCustom(shareholderFormSchema, "shareholders", shareholders);
        arr.push(...shareholderFormSchema);
      }
      if (nominee) {
        addNewCustom(nomineeShareholder, "nominee", nominee);
        arr.push(...nomineeShareholder);
      }
      setIndividualFormSchema(arr);
    } catch (error) {
      console.log(error);
    }
  };
  const clearForm = () => {
    setFindId(null);
    setFormData({});
    setObjData({});
    setUboChecked(false);
    setDirectorChecked(false);
    setReserveChecked(false);
    setSecretaryChecked(false);
    setPresidentChecked(false);
    setShareholderChecked(false);
    setNomineeChecked(false);
    setShareForm({
      show: false,
      type: "",
    });
    setCheckedList({
      directors: false,
      shareholders: false,
      ubo: false,
      nominee: false,
      reserve: false,
    });
    clearFormRef();
    clearArrItem();
  };
  const searchChange = (value) => {
    if (value) {
      let ctr = {};
      ctr.id = value;
      findOneIndividual(ctr);
      setFindId(value);
      setObjData(value);
    } else {
      setFindId(null);
    }
  };
  const clearArrItem = () => {
    let arr = [...individualFormSchema];
    const schemas = [
      nomineeShareholder,
      directorFormSchema,
      shareholderFormSchema,
      uboFormSchema,
    ];

    schemas.forEach((schema) => removeArrItem(schema, arr));
    setIndividualFormSchema(arr);
  };
  const clearFormRef = () => {
    try {
      if (receiverFormRef.current) receiverFormRef?.current?.clearForm();
      if (allocationFormRef.current) allocationFormRef?.current?.clearForm();
      if (senderFormRef.current) senderFormRef?.current?.clearForm();
      if (individualFormRef.current) individualFormRef?.current?.clearForm();
    } catch (err) {
      return err;
    }
  };

  const removeUpload = (index) => {
    setJSNewAllocation((prev) => prev.filter((_, i) => i !== index));
  };
  const removeUploadJsr = (index) => {
    setJSreceiver((prev) => prev.filter((_, i) => i !== index));
  };
  return (
    <>
      <div className="d-flex py-4 gap-3 align-items-center">
        <Switch
          className="custom-switch"
          onChange={(value) => openIndividualModal(value)}
        />
        <h4 className="primary-title">Individual</h4>
      </div>
      {individualShow ? (
        <div key={index} id={`individual${index}`}>
          <button
            className="primary-btn ms-auto mb-3"
            onClick={individualModalOnClick}
          >
            <i className="fal fa-plus-circle"></i>Add New
          </button>
          <div className="border-box">
            <Spin spinning={tableLoading}>
              <Table
                className="custom-table-light mt-md-1 mb-3"
                columns={individualcolumns}
                dataSource={individualDetails?.data?.rows}
                //  pagination={false}
                scroll={{ x: 1200 }}
                pagination={{
                  className: "custom-pagination",
                  defaultCurrent: 1,
                  pageSize: queryParams?._limit,
                  total: individualDetails?.data?.totalCount,
                  onChange: getPagination,
                }}
              />
            </Spin>
            <Modal
              title={selectedId ? "Edit Individual " : "Add New Individual"}
              okText={selectedId ? "Update" : "Save"}
              className="custom-modal"
              open={openIndividual}
              centered={true}
              width={1100}
              onOk={saveOnClick}
              onCancel={closeModalOnClick}
              // footer={false}
            >
              {!selectedId ? (
                <div className="custom-form row mb-3 mb-md-4 gy-3">
                  <div className="d-flex gap-2">
                    <div className="mt-4 w-100">
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <h4 className="primary-title ">Individual</h4>
                        <button className="primary-btn" onClick={clearForm}>
                          Clear Form
                        </button>
                      </div>
                      <div className="search-bar d-flex gap-2 p-3 bg-light shadow-sm rounded">
                        <Select
                          className="custom-select w-100"
                          placeholder={m.code || "Select Code"}
                          value={findId}
                          onChange={(value) => searchChange(value)}
                          options={individualArray}
                          showSearch={true}
                          allowClear={true}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        />
                        &nbsp;
                        <Select
                          className="custom-select w-100"
                          placeholder={m.name || "Select Name"}
                          value={findId}
                          onChange={(value) => searchChange(value)}
                          options={fullNameArray}
                          showSearch={true}
                          allowClear={true}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="d-flex gap-3 mb-3 align-items-center">
                <div className="d-flex gap-3 mb-3 align-items-center">
                  <h3 className="sub-title">
                    {!selectedId
                      ? ` Individual 0${individualDetails?.data?.count + 1}`
                      : `Individual 0${selectedIndex}`}{" "}
                    &nbsp;
                    <i className="fal fa-question-circle"></i>
                  </h3>

                  <Checkbox
                    className="custom-checkbox"
                    checked={
                      directorChecked &&
                      !reserveChecked &&
                      !presidentChecked &&
                      !secretaryChecked
                    }
                    onChange={(e) =>
                      addNewRow(
                        directorFormSchema,
                        e.target.checked,
                        "directors"
                      )
                    }
                    disabled={JSON.stringify(objData) == "{}"}
                  >
                    Directors
                  </Checkbox>
                  <Checkbox
                    className="custom-checkbox"
                    checked={shareholderChecked && !nomineeChecked}
                    onChange={(e) =>
                      addNewCustom(
                        shareholderFormSchema,
                        "shareholders",
                        e.target.checked
                      )
                    }
                    disabled={JSON.stringify(objData) == "{}"}
                  >
                    Shareholders
                  </Checkbox>
                  <Checkbox
                    className="custom-checkbox"
                    onChange={(e) =>
                      addNewChange(uboFormSchema, e.target.checked, "ubo")
                    }
                    checked={uboChecked}
                    disabled={JSON.stringify(objData) == "{}"}
                  >
                    UBOs
                  </Checkbox>
                  <Checkbox
                    className="custom-checkbox"
                    checked={nomineeChecked && !shareholderChecked}
                    onChange={(e) =>
                      addNewCustom(
                        nomineeShareholder,
                        "nominee",
                        e.target.checked
                      )
                    }
                    // disabled={objData.code ? false : true}
                    disabled={JSON.stringify(objData) == "{}"}
                  >
                    Nominee Shareholder
                  </Checkbox>
                  <Checkbox
                    className="custom-checkbox"
                    checked={
                      !directorChecked &&
                      reserveChecked &&
                      !presidentChecked &&
                      !secretaryChecked
                    }
                    onChange={(e) =>
                      addNewRow(directorFormSchema, e.target.checked, "reserve")
                    }
                    disabled={JSON.stringify(objData) == "{}"}
                  >
                    Reserve Director
                  </Checkbox>
                  <Checkbox
                    className="custom-checkbox"
                    checked={
                      !directorChecked &&
                      !reserveChecked &&
                      presidentChecked &&
                      !secretaryChecked
                    }
                    onChange={(e) =>
                      addNewRow(
                        directorFormSchema,
                        e.target.checked,
                        "president"
                      )
                    }
                    disabled={JSON.stringify(objData) == "{}"}
                  >
                    President
                  </Checkbox>
                  <Checkbox
                    className="custom-checkbox"
                    checked={
                      !directorChecked &&
                      !reserveChecked &&
                      !presidentChecked &&
                      secretaryChecked
                    }
                    onChange={(e) =>
                      addNewRow(
                        directorFormSchema,
                        e.target.checked,
                        "secretary"
                      )
                    }
                    disabled={JSON.stringify(objData) == "{}"}
                  >
                    Secretary
                  </Checkbox>
                </div>
              </div>
              <Spin spinning={loading}>
                <FormZ
                  ref={(el) => (individualFormRef.current[index] = el)}
                  formSchema={individualFormSchema}
                  formData={formData}
                  // formOptions={{ code: individualArray, ...individualOptions }}
                  onChange={individualOnChange}
                  onKeyUp={(e) => individualDropDownOnClick(e.target.value)}
                  formClass="row custom-form mb-3 gy-3 gx-4"
                  childClass="col-md-3 mb-0"
                  labelClass="mb-1"
                  inputClass="w-100"
                />
                {shareholderChecked || nomineeChecked ? (
                  <Popover
                    className="custom-popover"
                    placement="top"
                    content={
                      selectedCategory
                        ? renderSubOptions()
                        : renderMainOptions()
                    }
                  >
                    <button className="primary-btn" aria-label="Share Entry">
                      Share Entry
                    </button>
                  </Popover>
                ) : null}
                {selectedSubCategory == "Direct Entry"
                  ? shareForm["show"] && (
                      <div>
                        {shareForm["type"] == "transfer" ? (
                          <div className="mt-2">
                            <div className="primary-title mb-2">
                              Transferor (Sender)
                            </div>
                            <FormZ
                              ref={senderFormRef}
                              formSchema={senderForm}
                              onChange={senderOnChange}
                              formData={senderFormData}
                              formClass="row custom-form mb-3 gy-3 gx-4"
                              childClass="col-md-3 mb-0"
                              labelClass="mb-1"
                              inputClass="w-100"
                            />
                            <div className="primary-title mb-2">
                              Transferee (Receiver)
                            </div>
                            <FormZ
                              ref={receiverFormRef}
                              formSchema={receiverForm}
                              onChange={receiverOnChange}
                              formOptions={receiverOptions}
                              formData={receiveFormData}
                              formClass="row custom-form mb-3 gy-3 gx-4"
                              childClass="col-md-3 mb-0"
                              labelClass="mb-1"
                              inputClass="w-100"
                            />
                          </div>
                        ) : (
                          <div>
                            <div className="primary-title mb-2">
                              New Allocation
                            </div>
                            <FormZ
                              ref={allocationFormRef}
                              formSchema={allocationForm}
                              onChange={allocationOnChange}
                              formData={allocationFormData}
                              formOptions={receiverOptions}
                              formClass="row custom-form mb-3 gy-3 gx-4"
                              childClass="col-md-3 mb-0"
                              labelClass="mb-1"
                              inputClass="w-100"
                            />
                          </div>
                        )}
                        {selectedId ? (
                          <>
                            <div className="primary-title mb-2">
                              Detailed History
                            </div>
                            <Table
                              className="custom-table-light mt-3 mb-3"
                              columns={shareTableColumn}
                              dataSource={shareTableData}
                              scroll={{ x: 2000 }}
                              pagination={{
                                className: "custom-pagination",
                              }}
                            />
                          </>
                        ) : null}
                      </div>
                    )
                  : shareForm["show"] && (
                      <div>
                        {shareForm["type"] == "transfer" ? (
                          <div className="mt-2">
                            <div className="primary-title mb-2">
                              Transferor (Sender)
                            </div>
                            <FormZ
                              ref={senderFormRef}
                              formSchema={senderForm}
                              onChange={senderOnChange}
                              formData={senderFormData}
                              formClass="row custom-form mb-3 gy-3 gx-4"
                              childClass="col-md-3 mb-0"
                              labelClass="mb-1"
                              inputClass="w-100"
                            />
                            <div className="primary-title mb-2">
                              Transferee (Receiver)
                            </div>
                            <button
                              className="primary-btn mb-3"
                              onClick={addDetailsJSr}
                            >
                              <i className="fal fa-plus"></i> Add New
                            </button>

                            {JSreceiver.map((item, index) => (
                              <div
                                key={index}
                                className="row custom-form my-3 gy-3 gx-4 custom-border position-relative"
                              >
                                <div className="col-md-3 my-0">
                                  <label className="mb-1">
                                    Master File Code
                                  </label>
                                  <div className="d-flex gap-2">
                                    <Select
                                      className="custom-select w-100"
                                      placeholder="Master file Code"
                                      value={item.masterId}
                                      onChange={(selected) => {
                                        const matchingName =
                                          receiverOptions.name.find(
                                            (option) =>
                                              option.value === selected
                                          );
                                        const updatedList = [...JSreceiver];
                                        updatedList[index] = {
                                          ...updatedList[index],
                                          masterId: selected,
                                          name: matchingName
                                            ? matchingName.label
                                            : "", // Auto-fill Name
                                        };
                                        setJSreceiver(updatedList);
                                      }}
                                      options={receiverOptions.masterId}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3 my-0">
                                  <label className="mb-1">
                                    Master file Name
                                  </label>
                                  <div className="d-flex gap-2">
                                    <Select
                                      className="custom-select w-100"
                                      placeholder="Master file Name"
                                      value={item.name}
                                      onChange={(selected) => {
                                        const matchingMasterId =
                                          receiverOptions.masterId.find(
                                            (option) =>
                                              option.value === selected
                                          );
                                        const updatedList = [...JSreceiver];
                                        updatedList[index] = {
                                          ...updatedList[index],
                                          name: selected,
                                          masterId: matchingMasterId
                                            ? matchingMasterId.value
                                            : "", // Auto-fill Code
                                        };
                                        setJSreceiver(updatedList);
                                      }}
                                      options={receiverOptions.nameAndIdlist}
                                    />
                                  </div>
                                </div>
                                <button
                                  className="del-upload-btn align-top-right mt-0"
                                  onClick={() => removeUploadJsr(index)}
                                  type="button"
                                >
                                  <i className="far fa-trash-alt"></i>
                                </button>
                              </div>
                            ))}
                            <FormZ
                              ref={receiverFormRef}
                              formSchema={JSreceiverForm}
                              onChange={receiverOnChange}
                              formOptions={receiverOptions}
                              formData={receiveFormData}
                              formClass="row custom-form mb-3 gy-3 gx-4"
                              childClass="col-md-3 mb-0"
                              labelClass="mb-1"
                              inputClass="w-100"
                            />
                          </div>
                        ) : (
                          <div>
                            <div className="primary-title my-2">
                              New Allocation
                            </div>
                            <button
                              className="primary-btn mb-3"
                              onClick={addDetails}
                            >
                              <i className="fal fa-plus"></i> Add New
                            </button>

                            {JSNewAllocation.map((item, index) => (
                              <div
                                key={index}
                                className="row custom-form my-3 gy-3 gx-4 custom-border position-relative"
                              >
                                <div className="col-md-3 my-0">
                                  <label className="mb-1">
                                    Master File Code
                                  </label>
                                  <div className="d-flex gap-2">
                                    <Select
                                      className="custom-select w-100"
                                      placeholder="Master file Code"
                                      value={item.masterId}
                                      onChange={(selected) => {
                                        const matchingName =
                                          receiverOptions.name.find(
                                            (option) =>
                                              option.value === selected
                                          );
                                        const updatedList = [
                                          ...JSNewAllocation,
                                        ];
                                        updatedList[index] = {
                                          ...updatedList[index],
                                          masterId: selected,
                                          name: matchingName
                                            ? matchingName.label
                                            : "", // Auto-fill Name
                                        };
                                        setJSNewAllocation(updatedList);
                                      }}
                                      options={receiverOptions.masterId}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3 my-0">
                                  <label className="mb-1">
                                    Master file Name
                                  </label>
                                  <div className="d-flex gap-2">
                                    <Select
                                      className="custom-select w-100"
                                      placeholder="Master file Name"
                                      value={item.name}
                                      onChange={(selected) => {
                                        const matchingMasterId =
                                          receiverOptions.masterId.find(
                                            (option) =>
                                              option.value === selected
                                          );
                                        const updatedList = [
                                          ...JSNewAllocation,
                                        ];
                                        updatedList[index] = {
                                          ...updatedList[index],
                                          name: selected,
                                          masterId: matchingMasterId
                                            ? matchingMasterId.value
                                            : "",
                                        };
                                        setJSNewAllocation(updatedList);
                                      }}
                                      options={receiverOptions.nameAndIdlist}
                                    />
                                  </div>
                                </div>
                                <button
                                  className="del-upload-btn align-top-right mt-0"
                                  onClick={() => removeUpload(index)}
                                  type="button"
                                >
                                  <i className="far fa-trash-alt"></i>
                                </button>
                              </div>
                            ))}
                            <FormZ
                              ref={allocationFormRef}
                              formSchema={JSallocationForm}
                              onChange={allocationOnChange}
                              formData={allocationFormData}
                              formOptions={receiverOptions}
                              formClass="row custom-form mb-3 gy-3 gx-4"
                              childClass="col-md-3 mb-0"
                              labelClass="mb-1"
                              inputClass="w-100"
                            />
                          </div>
                        )}
                        {selectedId ? (
                          <>
                            <div className="primary-title mb-2">
                              Detailed History
                            </div>
                            <Table
                              className="custom-table-light mt-3 mb-3"
                              columns={shareTableColumn}
                              dataSource={shareTableData}
                              scroll={{ x: 2000 }}
                              pagination={{
                                className: "custom-pagination",
                              }}
                            />
                          </>
                        ) : null}
                      </div>
                    )}
              </Spin>
            </Modal>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
