import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import axios from "axios";
import { FloatButton, Popover, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";

import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
// import Footer from './Footer'

import { getCountry } from "../../store/country/countrySlice";

import NotificationBtn from "../../components/NotificationBtn";

export default function Index() {
  let token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);

  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCountry());
  }, []);

  return (
    <div>
      <div className="base-layout">
        <Sidebar />
        <div className="main">
          <Navbar />
          <div className="page-wrapper">
            <Outlet />
          </div>
          {/* <Footer /> */}
        </div>
      </div>
      <FloatButton.BackTop />
    </div>
  );
}
