import React, { useState, useEffect } from "react";
import { Table, Checkbox, Select, Tabs } from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { downloadAsCSV, customPagination, fetchApi } from "../helper";

import TableSortArrows from "../components/tableSortArrows";

import { getManageRoles } from "../store/manageRoles/manageRolesSlice";

export default function Reminders() {
  const dispatch = useDispatch();

  const [query, setQuery] = useState({
    _limit: 10,
    _start: 0,
  });

  const manageRoles = useSelector((state) => state.manageRoles);

  const [roleData, setRoleData] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    dispatch(getManageRoles(query));
  }, []);

  const role = [
    { label: "Entity Point of Contact", value: "point-of-contact" },
    { label: "Analyst", value: "analyst" },
    { label: "Admin", value: "admin" },
    { label: "MLRO", value: "mlro" },
    { label: "Lead / Manager", value: "lead" },
    { label: "End User Client", value: "end-user" },
    { label: "Introducer Client", value: "introducer-client" },
    { label: "Client", value: "client" },
  ];

  useEffect(() => {
    let data = manageRoles?.data?.rows;
    let arr = [];

    data?.map((d, i) => {
      arr.push({
        key: i,
        label: role.find((r) => r.value == d?.title)?.label,
      });
    });

    setRoleData(data);
    setTabs(arr);
  }, [manageRoles]);

  useEffect(() => {
    onTabChange(0);
  }, [roleData]);

  const column = [
    {
      title: "Roles",
      dataIndex: "roles",
      sorter: (a, b) => a.roles.localeCompare(b.roles),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "View",
      dataIndex: "view",
      align: "center",
      render: (_, { view }) => <Checkbox onChange={onChange}></Checkbox>,
    },
    {
      title: "Create",
      dataIndex: "create",
      align: "center",
      render: (_, { create }) => <Checkbox onChange={onChange}></Checkbox>,
    },
    {
      title: "Edit",
      dataIndex: "edit",
      align: "center",
      render: (_, { edit }) => <Checkbox onChange={onChange}></Checkbox>,
    },
    {
      title: "Delete",
      dataIndex: "del",
      align: "center",
      render: (_, { del }) => <Checkbox onChange={onChange}></Checkbox>,
    },
  ];

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const onTabChange = (key) => {
    let raw = roleData?.[key]?.accessList ?? [];
    let arr = [];
    if (raw.length > 0) {
      raw.map((t, i) => {
        arr.push({
          key: i,
          roles: t,
          view: false,
          create: false,
          edit: false,
          del: false,
        });
      });
    }
    setTableData(arr);
  };

  return (
    <div>
      <div className="page-header">
        <h1>Access Management</h1>
      </div>
      <div className="page-content">
        <div className="d-flex justify-content-between flex-column flex-md-row align-items-md-center align-items-start mb-2">
          <Tabs
            defaultActiveKey="1"
            items={tabs}
            className="custom-tabs"
            onChange={onTabChange}
          />
          <button className="primary-btn">Save</button>
        </div>
        <Table
          className="custom-table w-50 mb-3"
          columns={column}
          dataSource={tableData}
          pagination={false}
        />
      </div>
    </div>
  );
}
