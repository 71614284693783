import { Children } from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { notification, Steps } from "antd";
import axios from "axios";
import moment from "moment/moment";

import { store } from "../store/configureStore";
import { checkLogged } from "../store/auth/authSlice";
import NoData from "../components/NoData";
import Loading from "../components/Loading";

export function generateRandom() {
  return Math.random().toString(36).slice(2) + Date.now().toString(36);
}

export function percentageOf(value, total) {
  return ((value / total) * 100).toFixed(0);
}
export const getSubdomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split(".");

  if (parts.length > 1) {
    return parts.slice(0, -1).join(".");
  }
  return null;
};
export const isArrayEmpty = (array) => {
  let bool = array.length == 0 ? true : false;
  return bool;
};

export const isObjectEmpty = (object, key) => {
  let bool = !Object.values(object).every(
    (x) => x[key] !== null || x[key] !== ""
  );
  return bool;
};

export const fetchApi = async (payload) => {
  const response = await axios(payload)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 403) {
        sendNotify("error", "Token was expired!");
        localStorage.removeItem(process.env.REACT_APP_JWT_TOKEN);
        store.dispatch(checkLogged());
      }
      return { error: error };
    });
  return response;
};

export const focusOn = (id) => {
  setTimeout(() => {
    if (document.getElementById(id) !== null) {
      document.getElementById(id).scrollIntoView();
    }
  }, 100);
};

export const sendNotify = (type, content) => {
  notification.open({
    type: type,
    message: content,
    className: "custom-notification",
  });
};

export const downloadFile = (path, filename) => {
  var link = document.createElement("a");
  link.href = path;
  link.download = filename;
  link.dispatchEvent(new MouseEvent("click"));
};
export const downloadBase64File = (base64Data, fileName, fileType) => {
  const link = document.createElement("a");
  link.href = `data:${fileType};base64,${base64Data}`;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export function formatBytes(bytes, decimals = 0) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const downloadBase64 = (base64String, filename) => {
  const bytes = atob(base64String);
  const arrayBuffer = new ArrayBuffer(bytes.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < bytes.length; i++) {
    uint8Array[i] = bytes.charCodeAt(i);
  }

  const workbook = XLSX.read(uint8Array, { type: "array" });
  const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([wbout], { type: "application/octet-stream" });

  saveAs(blob, filename);
};
export const workflowChart = (items, current) => {
  return <Steps current={current} className="custom-steps" items={items} />;
};
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export const downloadAsCSV = (apiData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(apiData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  saveAs(data, fileName + fileExtension);
};

export const masterFileDownload = (apiData, fileName, headersArray) => {
  // Filter headersArray to include only required fields
  const requiredHeaders = headersArray.filter((header) => header.required);

  // Map apiData to use only required fields
  const formattedData = apiData.map((row) => {
    const newRow = {};
    requiredHeaders.forEach((header) => {
      newRow[header.header] = row[header.name] || "";
    });
    return newRow;
  });

  // Create worksheet and workbook
  const ws = XLSX.utils.json_to_sheet(formattedData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  // Write to Excel format
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Save file
  saveAs(data, `${fileName}.xlsx`);
};

export const Each = ({ loading, loadingStyle, render, array, warning }) =>
  loading ? (
    <Loading type={loadingStyle} />
  ) : (
    Children.toArray(
      array?.length > 0 ? (
        array?.map((item, index) => render(item, index))
      ) : !warning ? (
        <></>
      ) : (
        <NoData />
      )
    )
  );

// export const Show = (props) => {
//   let when = null;
//   let otherwise = null;

//   Children.forEach(props?.children, children => {
//     if (children?.props?.isTrue === undefined) {
//       otherwise == children;
//     } else if (!when && children?.props?.isTrue === true) {
//       when = children;
//     }
//   });

//   return when || otherwise;
// };

// Show.When = ({ isTrue, children }) => isTrue && children;
// Show.Else = ({ render, children }) => render || children;

export function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url?.replace("-", "+").replace("_", "/");
  let data = getAtob(base64) ? getAtob(base64) : null;
  let details = {};
  details = { ...JSON.parse(data) };
  return details;
}

export function getAtob(data) {
  try {
    return window.atob(data);
  } catch (e) {
    return false;
  }
}

export function createEmptyArray(size) {
  var x = [];
  for (var i = 0; i < size; ++i) {
    x[i] = i;
  }
  return x;
}

// export function getOptions(data) {
//   let arr = [];
//   let obj = {};
//   data?.map((option) => {
//     obj["label"] = option?.name ? `${option?.name} - ${option?.code}` : `${option?.code}`;
//     obj["value"] = option?.code;
//     arr.push(obj);
//     obj = {};
//   });
//   return arr;
// }

export function copyThat(value) {
  navigator.clipboard.writeText(value);
}

export function getOptions(data) {
  let arr = [];
  let obj = {};
  data?.map((option) => {
    obj["label"] = option?.name
      ? `${option?.name} - ${option?.code}`
      : option.label;
    obj["value"] = option?.code ?? option.value;
    arr.push(obj);
    obj = {};
  });
  return arr;
}

export function arrayToOption(data) {
  let arr = [];
  let obj = {};
  data?.map((option) => {
    obj["label"] = option;
    obj["value"] = option;
    arr.push(obj);
    obj = {};
  });
  return arr;
}

export function convertQueryParams(obj) {
  return Object.entries(obj)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(`${key}`)}=${encodeURIComponent(value)}`
    )
    .join("&");
}

export function showCount(count) {
  return count || count == 0 ? (count > 9 ? count : "0" + count) : "00";
}

export function getQuery() {
  let urlQuery =
    typeof window !== "undefined" ? window.location.search.split("?")[1] : null;
  let query = {};
  if (urlQuery) {
    if (urlQuery.includes("&")) {
      let params = urlQuery.split("&");
      for (let i = 0; i < params.length; i++) {
        query[params[i].split("=")[0]] = params[i].split("=")[1];
      }
    } else {
      query[urlQuery.split("=")[0]] = urlQuery.split("=")[1];
    }
  }
  return query;
}
export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};
export const customPagination = (_, type, originalElement) => {
  if (type === "prev") {
    return (
      <a>
        <i className="far fa-arrow-left"></i>&nbsp; Previous
      </a>
    );
  }
  if (type === "next") {
    return (
      <a>
        Next &nbsp;<i className="far fa-arrow-right"></i>
      </a>
    );
  }
  return originalElement;
};

export function eachChatTextTime(time) {
  return moment(time).format("hh:mm a");
}

export function chatTime(time) {
  let postedTimeStr = "";
  if (moment().isSame(time, "day")) {
    postedTimeStr = moment(time).format("hh:mm a"); //format("HH:mm")
  } else {
    postedTimeStr = moment(time).format("DD/MMM/YYYY"); //format("DD/MMM/YYYY HH:mm")
  }
  return postedTimeStr;
}

export function timeDiff(time, format) {
  let postedTimeStr = "";
  let postedTime = moment().diff(moment(time), "minutes");

  if (postedTime < 1) {
    postedTimeStr = `just now`;
  } else if (postedTime < 60) {
    postedTimeStr = `${postedTime} min ago`;
  } else {
    postedTimeStr = moment(time).format(format ?? "hh:mm a"); //format("HH:mm")
  }
  return postedTimeStr;
}

export function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function checkEmpty(value) {
  return value ? (value !== "" ? value : "N/A") : "N/A";
}

export function arrayToStr(value) {
  return value?.length > 0 ? value.join(", ") : "N/A";
}

export const dateOptions = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 11,
    label: "11",
  },
  {
    value: 12,
    label: "12",
  },
  {
    value: 13,
    label: "13",
  },
  {
    value: 14,
    label: "14",
  },
  {
    value: 15,
    label: "15",
  },
  {
    value: 16,
    label: "16",
  },
  {
    value: 17,
    label: "17",
  },
  {
    value: 18,
    label: "18",
  },
  {
    value: 19,
    label: "19",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 21,
    label: "21",
  },
  {
    value: 22,
    label: "22",
  },
  {
    value: 23,
    label: "23",
  },
  {
    value: 24,
    label: "24",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 26,
    label: "26",
  },
  {
    value: 27,
    label: "27",
  },
  {
    value: 28,
    label: "28",
  },
  {
    value: 29,
    label: "29",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 31,
    label: "31",
  },
];
export const sharedOnCell = (_, index) => {
  if (index === 0) {
    return {
      colSpan: 0,
    };
  }
  return {};
};
export const monthOptions = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
];

export function calculateLevelOfRisk(overallRiskScore) {
  const RiskLevel = {
    LOW: "LOW",
    MEDIUM: "MEDIUM",
    HIGH: "HIGH",
  };

  const score = parseInt(overallRiskScore, 10);
  try {
    if (score >= 0 && score <= 40) {
      return RiskLevel.LOW;
    } else if (score >= 41 && score <= 70) {
      return RiskLevel.MEDIUM;
    } else if (score >= 71 && score <= 100) {
      return RiskLevel.HIGH;
    }
  } catch (error) {
    console.error("Error in calculateLevelOfRisk:", error.message);
    return "Unknown";
  }
}

export const postIncorpType = [
  {
    value: "COI",
    label: "Certificate of Incumbency (COI)",
  },
  {
    value: "COGS",
    label: "Certificate of Good Standing (COGS)",
  },
  {
    value: "ROD",
    label: "Register of Directors",
  },
  {
    value: "SR",
    label: "Share Registry",
  },
  {
    value: "COD",
    label: "Change Of Director (COD)",
  },
  {
    value: "COS",
    label: "Change of Shareholder (COSH)",
  },
  {
    value: "COA",
    label: "Change of Client / Administration (COA)",
  },
  {
    value: "COC",
    label: "Change of Contact Point (COC)",
  },
  {
    value: "TO",
    label: "Transfer Out (TO)",
  },
  {
    value: "CO",
    label: "Continuation Out (CO)",
  },
  {
    value: "MAA",
    label: "Alteration to the Memorandum & Articles of Association",
  },
  {
    value: "MNC",
    label: "M&AA Name Change",
  },
  {
    value: "NOD",
    label: "Notarization of Documents",
  },
  {
    value: "AOD",
    label: "Apostille of Documents",
  },
  {
    value: "ARMA",
    label: "Amended and Restated Memorandum & Articles",
  },
  { value: "Apostilles", label: "Apostilles" },
  {
    value: "CGS",
    label: "Certificate of Good Standing",
  },
  {
    value: "RCAD",
    label: "Registry Certified Copy of Any Document",
  },
  {
    value: "RUD",
    label: "Registry Uncertified or Duplicate Copy of Any Document",
  },
  {
    value: "RPEC",
    label: "Registry Physical Copy of any Electronic Certificate",
  },
  { value: "CI", label: "Certificate of Incumbency" },
  { value: "CD", label: "Corporate Documents (Internal Certification)" },
  {
    value: "CCPD",
    label: "CCP Certified or Duplicate copies of documents",
  },
  {
    value: "ARD",
    label: "Amendments to Register of Directors",
  },
  {
    value: "ARM",
    label:
      "Amendments to Register of Members & Issuing New Share Certificate(s)",
  },
  { value: "Continuations", label: "Continuations" },
  { value: "DR", label: "Drafting Resolutions" },
  {
    value: "FRMC",
    label: "Filing Register of Mortgage & Charges",
  },
  {
    value: "FAR",
    label: "Filing Appointment of RO/RA",
  },
  { value: "FTO", label: "Filing Transfer Out" },
  { value: "IA", label: "Indemnity Agreements" },
  { value: "Notarizations", label: "Notarizations" },
  { value: "PA", label: "Powers of Attorney" },
  {
    value: "RSC",
    label: "Reactivation of struckoff companies",
  },
  {
    value: "RNR",
    label: "Registration of Notice of Resignation as Registered Agent",
  },
  {
    value: "FRRA",
    label: "Filing notice of Recission as Registered Agent",
  },
  {
    value: "RFC",
    label: "Registration as a Foreign Company in the BVI",
  },
  {
    value: "FNFC",
    label: "Filing Notice of Foreign Company Ceasing to Do Business in the BVI",
  },
  {
    value: "EFF",
    label: "Expedite Fee for Fast Track Due Diligence and Incorporation",
  },
  {
    value: "SL",
    label: "Solvent Liquidation",
  },
  {
    value: "other",
    label: "Other",
  },
];

// ('.ppt' '.pptx' '.doc', '.docx', '.xls', '.xlsx'):-
// src = "https://view.officeapps.live.com/op/embed.aspx?src=(Link)";
// Sample Doc: https://file-examples.com/storage/fe0e2ce82f660c1579f31b4/2017/02/file-sample_100kB.doc
// Sample Excel: https://file-examples.com/storage/fe0e2ce82f660c1579f31b4/2017/02/file_example_XLS_10.xls
// Sample PPT: https://file-examples.com/storage/fe0e2ce82f660c1579f31b4/2017/08/file_example_PPT_250kB.ppt

export const registerOfCharges = {
  descriptionOfCharge:
    "Description of charge (including, if the charge is a charge created by the company, the date of its creation or, if the charge is a charge existing on property acquired by the company, the date on which the property was acquired)",
  liabilitySecured: "Description of the liability secured by the charge",
  propertyCharged: "Description of the property charged",
  trusteeOrChargee:
    "Name and address of the trustee for the security or, if there is no such trustee, the name and address of the chargee",
  holderOfCharge:
    "Unless the charge is a security to bearer, the name and address of the holder of the charge",
  prohibitionsOrRestrictions:
    "Details of any prohibition or restriction, if any, contained in the instrument creating the charge on the power of the company to create any future charge ranking in priority to or equally with the charge",
  dateOfDischarge: "Date of Discharge of Charge",
};
