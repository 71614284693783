import React, { useState, useEffect, useRef } from "react";
import { Table, Modal } from "antd";
import FormZ from "../components/FormZ";

const RiskTable = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});

  const formRef = useRef();

  const dataSource = [
    {
      key: "1",
      riskLevel: "High",
      riskReviewFrequency: "6 (Months)",
      reminderCount: 2,
      reminderStartsAfter: 4,
      recurringTime: 2,
      reminderFrequency: "Month",
    },
    {
      key: "2",
      riskLevel: "Low",
      riskReviewFrequency: "N/A",
      reminderCount: "N/A",
      reminderStartsAfter: "N/A",
      recurringTime: "N/A",
      reminderFrequency: "N/A",
    },
    {
      key: "3",
      riskLevel: "Medium",
      riskReviewFrequency: "N/A",
      reminderCount: "N/A",
      reminderStartsAfter: "N/A",
      recurringTime: "N/A",
      reminderFrequency: "N/A",
    },
  ];

  // Define the columns for the table
  const columns = [
    {
      title: "Risk Level",
      dataIndex: "riskLevel",
      key: "riskLevel",
    },
    {
      title: "Risk Review Frequency",
      dataIndex: "riskReviewFrequency",
      key: "riskReviewFrequency",
    },
    {
      title: "Reminder Count",
      dataIndex: "reminderCount",
      key: "reminderCount",
    },
    {
      title: "Reminder Starts After",
      dataIndex: "reminderStartsAfter",
      key: "reminderStartsAfter",
    },
    {
      title: "Recurring Time",
      dataIndex: "recurringTime",
      key: "recurringTime",
    },
    {
      title: "Reminder Frequency",
      dataIndex: "reminderFrequency",
      key: "reminderFrequency",
    },
  ];
  let formSchema = [
    {
      name: "riskLevel",
      value: "",
      type: "select",
      label: "Risk Level",
      placeholder: "Enter Risk Level type",
      options: [
        {
          value: "High",
          label: "High",
        },
        {
          value: "Medium",
          label: "Medium",
        },
        {
          value: "Low",
          label: "Low",
        },
      ],
    },
    {
      name: "riskReviewFrequency",
      value: "",
      type: "text",
      label: "Risk Review Frequency",
      placeholder: "Enter Risk Review Frequency",
      required: true,
    },
    {
      name: "reminderCount",
      value: "",
      type: "number",
      label: "Reminder Count",
      placeholder: "Enter Reminder Count",
      required: true,
    },
    {
      name: "reminderStartsAfter",
      value: "",
      type: "number",
      label: "Reminder Starts After",
      placeholder: "Enter Reminder Starts After",
      required: true,
    },
    {
      name: "incorporationDate",
      value: "",
      type: "date",
      label: "Incorporation Date",
      placeholder: "Enter Date (DD/MM/YYYY)",
      // format: "DD/MM/YYYY",
      required: true,
    },

    {
      name: "reminderFrequency",
      value: "",
      type: "select",
      label: "Reminder Frequency",
      placeholder: "Enter Reminder Frequency",
      options: [
        {
          value: "Day",
          label: "Day",
        },
        {
          value: "Week",
          label: "Week",
        },
        {
          value: "Month",
          label: "Month",
        },
        {
          value: "Year",
          label: "Year",
        },
      ],
    },

    // recurringTime: {
    //   type: Number, // If this refers to the count of time units (e.g., 2 for 2 weeks or months)
    //   required: true
    // },
  ];
  const handleCancel = async () => {
    setShowModal(false);
  };
  const handleOk = async () => {};
  return (
    <div>
      <div className="page-header">
        <div>
          <h1 className="mb-1">Risk Review</h1>
          <p>Risk Level Review and Reminder Setup</p>
        </div>
      </div>

      <div className="mt-4">
        <button
          className="primary-btn ms-auto py-2"
          onClick={() => setShowModal(true)}
        >
          <i className="fal fa-plus"></i>
          Add
        </button>
        <Table
          className="custom-table my-3"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        />
      </div>
      <Modal
        title={"Add"}
        className="custom-modal"
        open={showModal}
        width={1000}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
          <FormZ
            formClass="row gy-3 mt-2 mb-4"
            childClass="col-md-5 mb-0"
            formSchema={formSchema}
            ref={formRef}
            formData={formData}
            labelClass="mb-1"
            inputClass="w-100"
          />
        </div>
      </Modal>
    </div>
  );
};

export default RiskTable;
