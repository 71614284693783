import React, { useState, useRef } from "react";
import { Checkbox, Select } from "antd";
import UploadZ from "../../../components/UploadZ";
import FormZ from "../../../components/FormZ";
import { Each, focusOn, fetchApi, convertQueryParams } from "../../../helper";

const CorporateKYC = ({ setCorporateKYC }) => {
  const formRef = useRef([]);
  const [documents, setDocuments] = useState([]);
  const [individuals, setIndividuals] = useState([1]);
  const [checkValue, setCheckValue] = useState([]);
  const [docList, setDocList] = useState([
    "Incorporation Certificate",
    "M&AA",
    "Share Registry",
    "ROD",
    "Seal",
  ]);
  const [sanction, setSanction] = useState("Y");
  const [sanctionText, setSanctionText] = useState("");
  const [formData, setFormData] = useState([]);
  const [dataArray, setDataArray] = useState([]);
  const [corporateArray, setCorporateArray] = useState([]);
  const [cKyc, setCKyc] = useState([1]);
  const directorDocs = [
    "Consent to Act as Director",
    "Resolution of Appointment",
    "Letter of Resignation",
    "Resolution of Resignation",
    // "Register Of Directors",
  ];

  const shareholderDocs = [
    "Resolution of Share Allotment",
    "Resolution of Share Transfer",
    "Share Transfer Agreement",
  ];

  const uboDocs = [
    "UBO source of funds document",
    "Source of Wealth Proof",
    "Resolution of Shareholding",
  ];

  const poc = ["Contact Details"];

  const nomineeDocs = ["Nominee Agreement"];

  const addNewIndividual = () => {
    setIndividuals([...individuals, individuals.length + 1]);
  };

  const handleOnChange = async (value) => {
    try {
      const { masterId } = value;
      if (masterId) {
        const payload = {
          method: "GET",
          url: `/cop-entity?_start=0&_limit=1&id=${masterId}`,
        };
        const copData = await fetchApi(payload).then((res) => res.data.rows[0]);

        if (copData) {
          const data = {
            ...formData,
            ...value,
            fullName: copData.name,
            listed: copData.details.listed,
            riskLevel: copData.details.riskRating,
          };
          setSanction(copData.details.sanction);
          setSanctionText(copData.details.sanctionText);
          setDataArray([...dataArray, data]);
          setFormData(data);
        }
      }
    } catch (error) {
      console.error("Error fetching copData:", error);
    }
  };

  const corporateOnChange = async (value) => {
    try {
      const params = { code: value };
      const query = convertQueryParams(params);
      const payload = {
        method: "GET",
        url: `/cop-entity?${query}`,
      };
      let data = await fetchApi(payload).then((res) => res.data.rows);

      let list = data.map((d) => ({
        value: d?._id,
        label: d?.code,
      }));
      setCorporateArray(list);
    } catch (error) {
      console.error("Error fetching corporate data:", error);
    }
  };
  const removeArrItem = (values, arr) => {
    return arr.filter((item) => !values.includes(item));
  };
  const updateStatusEntry = (status, index, property, value) => {
    const entry = status.find((item) => item.index === index);
    if (entry) {
      entry[property] = value;
    } else {
      status.push({ index, [property]: value });
    }
  };
  const handleCheckboxChange = (values, checkStatus, name, index) => {
    let arr = [...docList];
    let status = [...checkValue];
    let val = { [name]: checkStatus, index: index };
    status.push(val);
    const uniqueStatus = new Map();
    status.forEach((entry) => {
      if (!uniqueStatus.has(entry.index)) {
        uniqueStatus.set(entry.index, { index: entry.index });
      }
      uniqueStatus.set(entry.index, {
        ...uniqueStatus.get(entry.index),
        ...entry,
      });
    });
    status = Array.from(uniqueStatus.values());

    if (checkStatus) {
      arr = removeArrItem(values, arr);
    }

    if (name === "directors" || name === "reserve") {
      if (checkStatus) {
        arr.push(...values);
        updateStatusEntry(
          status,
          index,
          name === "directors" ? "reserve" : "directors",
          false
        );
        updateStatusEntry(status, index, name, true);
      } else {
        arr = removeArrItem(values, arr);
        updateStatusEntry(status, index, name, false);
      }
    } else if (name === "shareholders" || name === "nominee") {
      if (checkStatus) {
        arr.push(...values);
        updateStatusEntry(
          status,
          index,
          name === "shareholders" ? "nominee" : "shareholders",
          false
        );
        updateStatusEntry(status, index, name, true);
        arr = removeArrItem(
          name === "shareholders" ? nomineeDocs : shareholderDocs,
          arr
        );
      } else {
        arr = removeArrItem(values, arr);
        updateStatusEntry(status, index, name, false);
      }
    } else {
      if (checkStatus) {
        arr.push(...values);
      } else {
        arr = removeArrItem(values, arr);
      }
    }
    setCheckValue(status);
    setDocList(arr);
  };
  const addNewCol = ({ focus }) => {
    setCKyc([...cKyc, cKyc.length + 1]);
    if (focus) focusOn("item" + (cKyc.length - 1));
  };

  const removeNewCol = (index) => {
    setCKyc(cKyc.filter((_, i) => i !== index));
  };

  const addNewChange = (values, checkStatus) => {
    const updatedDocList = checkStatus
      ? [...docList, ...values]
      : docList.filter((item) => !values.includes(item));
    setDocList(updatedDocList);
    setFormData({ ...formData, doc: updatedDocList });
  };

  const storeDocuments = (file, index) => {
    const updatedDocuments = [...documents, { file, index }];
    setDocuments(updatedDocuments);
    setCorporateKYC([...dataArray, { ...formData, doc: updatedDocuments }]);
  };

  const handleChange = (value) => {
    setSanction(value);
  };

  let formSchema = [
    {
      name: "masterId",
      value: "",
      type: "select",
      label: "Masterfile Code",
      placeholder: "Enter masterfile code",
      customClass: "col-md-2",
      showSearch: true,
    },
    {
      name: "fullName",
      value: "",
      type: "text",
      label: "Full Name",
      placeholder: "Enter full name",
      customClass: "col-md-2",
      disabled: true,
    },
    {
      name: "listed",
      value: "",
      type: "select",
      label: "Listed or Regulated",
      placeholder: "Select listed or regulated",
      options: [
        { value: "Y", label: "Yes" },
        { value: "N", label: "No" },
      ],
      customClass: "col-md-2",
      disabled: true,
    },
    {
      name: "riskLevel",
      value: "low",
      type: "select",
      label: "Risk Level",
      placeholder: "Select risk level",
      options: [
        { value: "low", label: "Low" },
        { value: "medium", label: "Medium" },
        { value: "high", label: "High" },
      ],
      customClass: "col-md-2",
      disabled: true,
    },
    {
      customClass: sanction === "Y" ? "col-md-3" : "col-lg-1",
      label: "Sanction",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${sanction === "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={sanction}
            onChange={handleChange}
            disabled={true}
            options={[
              { value: "Y", label: "Yes" },
              { value: "N", label: "No" },
            ]}
          />
          {sanction === "Y" && (
            <input
              type="text"
              disabled={true}
              value={sanctionText}
              className="w-100"
              placeholder="Enter sanction"
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      {cKyc.map((_, index) => (
        <div key={index}>
          <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-md-4 mb-4">
            <h2 className="primary-title mb-3 mb-md-0">Corporate Entity</h2>
            {index === 0 ? (
              <button
                className="primary-btn"
                onClick={() => addNewCol({ focus: true })}
              >
                <i className="fal fa-plus-circle"></i> Add New
              </button>
            ) : (
              <button
                className="outline-btn red-btn"
                onClick={() => removeNewCol(index)}
              >
                <i className="fal fa-trash-alt"></i> Remove
              </button>
            )}
            {/* <Checkbox
              className="custom-checkbox"
              checked={
                index === 0 && !docList.includes("Incorporation Certificate")
              }
              onChange={(e) =>
                addNewChange(["Incorporation Certificate"], e.target.checked)
              }
            >
              Directors
            </Checkbox> */}

            <Checkbox
              className="custom-checkbox"
              checked={
                checkValue && checkValue?.[index]?.directors
                  ? checkValue[index].directors && !checkValue[index].reserve
                  : false
              }
              onChange={(e) =>
                handleCheckboxChange(
                  directorDocs,
                  e.target.checked,
                  "directors",
                  index
                )
              }
            >
              Directors
            </Checkbox>
            <Checkbox
              className="custom-checkbox"
              checked={
                checkValue && checkValue?.[index]?.shareholders
                  ? checkValue[index].shareholders && !checkValue[index].nominee
                  : false
              }
              onChange={(e) =>
                handleCheckboxChange(
                  shareholderDocs,
                  e.target.checked,
                  "shareholders",
                  index
                )
              }
            >
              Shareholders
            </Checkbox>
            <Checkbox
              className="custom-checkbox"
              onChange={(e) =>
                handleCheckboxChange(uboDocs, e.target.checked, "ubo", index)
              }
            >
              UBOs
            </Checkbox>
            <Checkbox
              className="custom-checkbox"
              checked={
                checkValue && checkValue?.[index]?.nominee
                  ? checkValue[index].nominee && !checkValue[index].shareholders
                  : false
              }
              onChange={(e) =>
                handleCheckboxChange(
                  nomineeDocs,
                  e.target.checked,
                  "nominee",
                  index
                )
              }
            >
              Nominee Shareholder
            </Checkbox>
            <Checkbox
              className="custom-checkbox"
              checked={
                checkValue && checkValue?.[index]?.reserve
                  ? !checkValue[index].directors && checkValue[index].reserve
                  : false
              }
              onChange={(e) =>
                handleCheckboxChange(
                  directorDocs,
                  e.target.checked,
                  "reserve",
                  index
                )
              }
            >
              Reserve Director
            </Checkbox>
          </div>
          <div className="custom-form my-4" id={`item${index}`}>
            <div className="d-flex gap-3 mb-3 align-items-center">
              <h3 className="sub-title">
                Corporate Entity {index + 1} &nbsp;
                <i className="fal fa-question-circle"></i>
              </h3>
            </div>
            <FormZ
              ref={(el) => (formRef.current[index] = el)}
              formSchema={formSchema}
              onChange={handleOnChange}
              onKeyUp={(e) => corporateOnChange(e.target.value)}
              formOptions={{ masterId: corporateArray }}
              formData={dataArray[index]}
              formClass="row mt-3 mb-4"
              childClass="col-md-1 mb-0"
              labelClass="mb-1"
              inputClass="w-100"
            />
            <div className="row gy-2 gx-4">
              {docList.map((item, i) => (
                <div key={i} className="col-md-3">
                  <label htmlFor={item}>{item}</label>
                  <UploadZ
                    onStoreFile={(data) => storeDocuments(data, index)}
                    fileName={item}
                  />
                </div>
              ))}
              {/* Render custom uploads if needed */}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CorporateKYC;
