import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Radio, Checkbox, Switch } from "antd";

import SampleDoc from "../assets/pdf/sample.pdf";

import UploadZ from "../components/UploadZ";
import TableSortArrows from "../components/tableSortArrows";
import TagZ from "../components/TagZ";

import { Each } from "../helper";

const document = [
  {
    url: SampleDoc,
    file: "Resolution of M&AA Name Change",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
];

export default function MaaNameChange() {
  const [docs, setDocs] = useState(document);
  const [role, setRole] = useState("client");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const clientTableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name - b.name,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Availability Status",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={status ? "approved" : "rejected"}
          statusText={status ? "Available" : "Unavailable"}
        />
      ),
    },
    {
      title: "Reservation",
      dataIndex: "reservation",
      sorter: (a, b) => a.reservation - b.reservation,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status, reservation }) => {
        if (status) {
          return (
            <Radio.Group value={reservation}>
              <Radio className="custom-radio" value={"O"}>
                Ordinary
              </Radio>
              <Radio className="custom-radio" value={"P"}>
                Paid
              </Radio>
            </Radio.Group>
          );
        } else {
          return <>N/A</>;
        }
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, { status }) => (
        <button
          className={`primary-btn py-2 ${false ? "completed-btn" : ""}`}
          disabled={!status}
        >
          {false ? "Reserved" : "Reserve"}
        </button>
      ),
    },
  ];

  const clientTableData = [
    {
      name: "First sample name",
      status: false,
      reservation: "O",
    },
    {
      name: "Second sample name",
      status: true,
      reservation: "P",
    },
    {
      name: "Third sample name",
      status: true,
      reservation: "O",
    },
  ];

  const adminTableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name - b.name,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Available",
      dataIndex: "available",
      render: (_, { status }) => <Switch className="custom-switch" />,
    },
  ];

  const adminTableData = [
    {
      name: "First sample name",
      status: false,
    },
    {
      name: "Second sample name",
      status: true,
    },
    {
      name: "Third sample name",
      status: true,
    },
  ];

  const onChangeApproval = (file, bool) => {
    let arr = [...docs];
    let index = arr.findIndex((doc) => doc.file == file);
    arr[index].isApproved = bool;
    setDocs(arr);
  };

  return (
    <div>
      <div className="page-header">
        <Link to={"/app/active-requests"}>
          <h1 className="d-flex align-items-center gap-2">
            <i className="fal fa-long-arrow-left"></i> M&AA Name Change
          </h1>
        </Link>
        <div className="d-flex gap-3">
          {role == "admin" && (
            <>
              <button className="outline-btn">Check Mailbox</button>
              <button className="primary-btn">Send to Client</button>
            </>
          )}
          <button className="primary-btn" disabled={true}>
            Submit
          </button>
        </div>
      </div>
      <div className="page-content">
        <div className="custom-form row gy-4 mb-5">
          <div className="col-md-3">
            <label htmlFor="">Entity Code</label>
            <span className="light-badge">38202</span>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Entity Name</label>
            <span className="light-badge">Sample Entity</span>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Client Code</label>
            <span className="light-badge">38202</span>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Client Name</label>
            <span className="light-badge">Sample Client</span>
          </div>
        </div>
        <h2 className="form-title mb-4">Name Availability</h2>
        <Table
          className={`custom-table mb-4 ${role == "admin" ? "w-50" : "w-75"}`}
          columns={role == "admin" ? adminTableColumns : clientTableColumns}
          dataSource={role == "admin" ? adminTableData : clientTableData}
          pagination={false}
        />
        <div className="row gy-2 gx-4">
          <Each
            array={docs}
            render={(item, index) => (
              <div className="col-md-3">
                <div>
                  <Checkbox className="custom-checkbox">{item?.file}</Checkbox>
                  <UploadZ
                    file={item}
                    downloadOnly={true}
                    showApprove={true}
                    onFileApprove={onChangeApproval}
                    showTranslation={true}
                    showExtraction={true}
                  />
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
}
